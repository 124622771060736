import { LATE_STATUS } from '@kritik/utils/creation/lateCreation';
import * as CreationStatusUtil from '@kritik/utils/creation/status';
import { FILTERS } from 'components/CreationList/constant';

export class FilterItemList {
  filter: any;
  itemList: any;
  constructor(itemList: any, filter: any) {
    this.itemList = itemList;
    this.filter = filter;
    const filteredList = this.filterList();
    return filteredList;
  }

  filterList() {
    switch (this.filter) {
      case FILTERS.SHOW_ALL: {
        return this.itemList;
      }
      case FILTERS.MISSED_CREATIONS: {
        return this.missed();
      }
      case FILTERS.LATE_CREATIONS: {
        return this.late();
      }
      case FILTERS.OVERDUE_CREATIONS: {
        return this.overdue();
      }
      case FILTERS.NOT_GRADED: {
        return this.notGraded();
      }
      case FILTERS.DISPUTES: {
        return this.disputed();
      }
      case FILTERS.FLAGS: {
        return this.flagged();
      }
      default: {
        return this.itemList;
      }
    }
  }

  missed() {
    return this.itemList.filter((item: any) => {
      if (item.creation) {
        return CreationStatusUtil.wasCreationMissed(item.creation);
      }
      return true;
    });
  }

  late() {
    const list = this.itemList.filter((item: any) => {
      if (item.creation) {
        return (
          CreationStatusUtil.wasCreationSubmittedLate(item.creation) ||
          CreationStatusUtil.wasCreationSubmittedInGracePeriod(item.creation)
        );
      }
      return false;
    });
    return list.sort((item: any) => {
      if (
        CreationStatusUtil.wasCreationSubmittedLate(item.creation) &&
        item.creation?.lateSubmission?.status === LATE_STATUS.PENDING
      ) {
        return -1;
      }
      return 1;
    });
  }

  overdue() {
    return this.itemList.filter((item: any) => {
      if (item.creation && item.activity) {
        return CreationStatusUtil.isCreationOverdue(item.activity, item.creation);
      }
      return false;
    });
  }

  notGraded() {
    return this.itemList.filter((item: any) => {
      if (item.creation) {
        return CreationStatusUtil.isCreationNotGraded(item.creation);
      }
      return false;
    });
  }

  disputed() {
    return this.itemList.filter((item: any) => {
      if (item.creation) {
        return CreationStatusUtil.hasBeenDisputed(item.creation);
      }
      return false;
    });
  }

  flagged() {
    return this.itemList.filter((item: any) => {
      if (item.creation) {
        return item.creation.scores.find((score: any) => {
          return CreationStatusUtil.isCommentFlagged(score);
        });
      }
      return false;
    });
  }
}
