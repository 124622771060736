import { Group, Student, User } from '@kritik/types.generated';
import * as _ from 'lodash-es';

export const isMatch = (
  { name, email, groupName }: { name?: string; email?: string; groupName?: string },
  searchParams: string
) => {
  const re = new RegExp(_.escapeRegExp(searchParams), 'i');
  return re.test(name) || re.test(email) || (groupName && re.test(groupName));
};

export const searchGroupList = (groups: Group[], searchParam: string) => {
  if (!searchParam) {
    return groups;
  }

  return _.filter(groups, (group) => {
    const memberIsMatch = group.members.some((member) => {
      return isMatch(
        {
          name: member?.user?.profile?.name,
          email: member?.user?.email,
        },
        searchParam
      );
    });
    const groupNameIsMatch = isMatch(
      {
        groupName: group?.name,
      },
      searchParam
    );
    return groupNameIsMatch || memberIsMatch;
  });
};

export function searchUserList<T extends User | Student>(data: T[], searchParam: string, fromStudent = false) {
  if (!searchParam) {
    return data;
  }

  if (fromStudent) {
    return _.filter(data, (item) => {
      return isMatch(
        {
          email: item?.user?.email,
          name: item?.user?.profile?.name,
        },
        searchParam
      );
    });
  }
  return _.filter(data, (item) => {
    return isMatch(
      {
        email: item?.email,
        name: item?.profile?.name,
      },
      searchParam
    );
  });
}
