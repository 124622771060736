import classNames from 'classnames';

const TYPES = {
  ATTENTION: 'attention',
  DEFAULT: 'default',
};

const FormFieldInfo = (props: any) => {
  if (!props.label && !props.children) {
    return null;
  }
  const classes = classNames('kritik-form-field__info', {
    'kritik-form-field__info--attention': props.type === TYPES.ATTENTION,
  });
  return (
    <div className={classes}>
      {props.label}
      {props.children}
    </div>
  );
};

FormFieldInfo.defaultProps = {
  type: 'default',
};

export default FormFieldInfo;
