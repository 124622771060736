import { getStudentTopic } from '@kritik/utils/activity';
import { isDraft } from '@kritik/utils/stage';
import { TranslatedText } from 'components/TranslatedText';
import { useUserRoleInCourse } from 'hooks/course';
import { connect } from 'react-redux';
import { exportStudentTopics } from 'redux/topic';
import { selectCurrentActivity } from 'selectors/activity';

function MultiTopic({ activity, exportStudentTopics, user }: any) {
  const { isInstructorInCourse } = useUserRoleInCourse();

  const handleClickDownload = () => {
    exportStudentTopics(activity);
  };

  if (isInstructorInCourse) {
    return (
      <p>
        <TranslatedText i18nKey="Multitopic.Assignment" values={{ topics: activity.topics.length }} />
        {!isDraft({ assignment: activity }) && (
          <>
            {' '}
            You can download <a onClick={handleClickDownload}>the student topic assignment list</a>.
          </>
        )}
      </p>
    );
  }
  const assignedTopic = getStudentTopic(activity, user);
  return (
    <div className="activity-overview__topic-display">{assignedTopic && `Topic ${(assignedTopic as any).name}`}</div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    activity: selectCurrentActivity(state),
    user: state.user,
  };
};

export default connect(mapStateToProps, {
  exportStudentTopics,
})(MultiTopic);
