import FOFSlider from 'components/Creation/Feedback/FOFSlider';
import HtmlContent from 'components/General/HtmlContent';
import { TranslatedText } from 'components/TranslatedText';
import Button from 'components/buttons/Button';
import { localize } from 'locales';

const displayFeedback = ({ evaluation, showBreakdown, onEdit, isEditable = false, showTitle = true }: any) => {
  const renderTitle = () => {
    if (showTitle) {
      return (
        <h3 className="header-3">
          <TranslatedText i18nKey="Creation.Feedback.View.EvaluationFeedback" />
        </h3>
      );
    }
  };

  const renderFeedbackComment = (
    <div className="feedback section" data-testid="feedback-comment">
      <div className="title">
        <TranslatedText i18nKey="Creation.Feedback.View.FeedbackComment" />
      </div>
      <HtmlContent
        content={evaluation.feedbackOnFeedback.comment || localize({ message: 'Creation.Feedback.View.NoComment' })}
      />
    </div>
  );

  return (
    <div className="fof-view__container">
      <div className="fof-view__submission-container">
        {renderTitle()}
        <div className="submit-fof-container">
          <div className="fof-display section">
            <div className="fof-graph-container">
              <FOFSlider
                critical={evaluation.feedbackOnFeedback.critical}
                motivational={evaluation.feedbackOnFeedback.motivational}
                criticalChange={showBreakdown ? evaluation.feedbackSkillsChange.critical : null}
                motivationalChange={showBreakdown ? evaluation.feedbackSkillsChange.motivational : null}
                disabled
                studentId={evaluation.student?._id || evaluation.student}
              />
            </div>
          </div>
          {renderFeedbackComment}
          {isEditable && (
            <Button data-testid="edit-feedback" type="primary" onClick={onEdit}>
              <TranslatedText i18nKey="Creation.Feedback.View.Edit" />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default displayFeedback;
