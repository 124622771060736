import { Activity, Creation } from '@kritik/types.generated';
import Tooltip from '@material-ui/core/Tooltip';
import DoneIcon from '@material-ui/icons/Done';
import classNames from 'classnames';
import { MouseEvent } from 'react';

const STATES = {
  WARNING: 'warning',
  RESOLVED: 'resolved',
  SUCCESS: 'success',
  COMMENT: 'comment',
};
const CardNotification = (props: {
  status: string;
  label: string | JSX.Element;
  count?: number;
  icon: JSX.Element;
  onClick?: () => void;
  assignment?: Activity;
  creation?: Creation;
}) => {
  const classes = classNames('activity-card-notification', {
    'activity-card-notification--warning': props.status === STATES.WARNING,
    'activity-card-notification--success': props.status === STATES.SUCCESS,
    'activity-card-notification--resolved': props.status === STATES.RESOLVED,
    'activity-card-notification--comment': props.status === STATES.COMMENT,
  });

  const renderSuccessIcon = () => {
    if (props.status !== STATES.SUCCESS) {
      return null;
    }
    return (
      <div className="activity-card-notification__success-icon">
        <DoneIcon fontSize="inherit" />
      </div>
    );
  };

  const handleClick = (e: MouseEvent) => {
    e.stopPropagation();
    props.onClick?.();
  };

  const displayedCount = props.count > 99 ? '99+' : props.count;
  return (
    <Tooltip title={props.label} placement="top">
      <div className={classes} onClick={handleClick}>
        {renderSuccessIcon()}
        <div className="activity-card-notification__label">
          {Boolean(props.count) && <div className="activity-card-notification__count">{displayedCount}</div>}
          <div className="activity-card-notification__icon">{props.icon}</div>
        </div>
      </div>
    </Tooltip>
  );
};

export default CardNotification;
