import { isGroupPresentationActivity, isPresentationActivity } from '@kritik/utils/activity';
import {
  getNumEvaluationsCompleted,
  getNumEvaluationsReceived,
  getNumEvaluationsToBeReceived,
  getNumEvaluationsToDo,
} from '@kritik/utils/creation/evaluation';
import * as statusUtil from '@kritik/utils/stage';
import { Tooltip } from '@material-ui/core';
import Clock from '@material-ui/icons/AccessTime';
import StatusLabels from 'components/CreationList/StatusLabels';
import { TranslatedText } from 'components/TranslatedText';
import { localize } from 'locales';
import { Activity, Evaluation, LateEvaluation, Student } from 'old-common/types.generated';
import { connect } from 'react-redux';
import { selectIsShowEvalsCompleted } from 'redux/creationTable/select';

type EvaluationCellProps = {
  activity: Activity;
  creation: Creation;
  student: Student;
  lateEvaluations: Evaluation & { lateEvaluation: LateEvaluation }[];
  isShowEvalsCompleted: boolean;
};

const EvaluationCell = (props: EvaluationCellProps) => {
  const studentId = props.student ? props.student._id : '';
  const { lateEvaluations }: { lateEvaluations: Evaluation & { lateEvaluation: LateEvaluation }[] } = props;
  const isGroupView = Boolean(props.creation.group && !studentId);

  const evaluationStage = statusUtil.getEvaluationStage(props.activity);

  const pendingLateEvaluations = lateEvaluations.filter((e) => e.lateEvaluation?.status === 'Pending').length;
  const acceptedLateEvaluations = lateEvaluations.filter((e) => e.lateEvaluation?.status === 'Accepted').length;
  const rejectedLateEvaluations = lateEvaluations.filter((e) => e.lateEvaluation?.status === 'Rejected').length;

  if (
    isGroupPresentationActivity(props.activity) &&
    isGroupView &&
    props.activity.participationSettings?.evaluatorType === 'specificGroups' &&
    (statusUtil.isCreate({ assignment: props.activity }) || statusUtil.isInCreationGracePeriod(props.activity))
  ) {
    const isGroupEvaluating = (props.activity.participationSettings.evaluatorGroups || []).includes(
      props.creation.group
    );
    if (isGroupEvaluating) {
      return <StatusLabels label={localize({ message: 'Activity.StudentTable.Cell.NotStarted' })} status="no-bg" />;
    } else {
      return <StatusLabels label={localize({ message: 'Activity.StudentTable.Cell.NotRequired' })} status="no-bg" />;
    }
  }

  if (isGroupPresentationActivity(props.activity) && isGroupView && statusUtil.isEvaluateOrLater(props.activity)) {
    const groupEvalsAssigned = Object.values(props.creation.groupEvalsAssigned || {}).reduce(
      (acc: number, val: number) => acc + val,
      0
    );
    const groupEvalsCompleted = Object.values(props.creation.groupEvalsCompleted || {}).reduce(
      (acc: number, val: number) => acc + val,
      0
    );
    const label =
      groupEvalsAssigned === 0
        ? localize({ message: 'Activity.StudentTable.Cell.NotRequired' })
        : groupEvalsAssigned === groupEvalsCompleted
          ? localize({ message: 'Activity.StudentTable.Cell.Complete' })
          : `${groupEvalsCompleted}/${groupEvalsAssigned}`;
    return <StatusLabels label={label} status="unavailable" />;
  }

  if (
    isPresentationActivity(props.activity) &&
    !statusUtil.studentParticipatesInStageFromInstructorView({
      stage: evaluationStage,
      studentId,
    })
  ) {
    return (
      <StatusLabels label={localize({ message: 'Activity.StudentTable.Cell.NotRequired' })} status="unavailable" />
    );
  }

  if (
    !statusUtil.isEvaluate({ assignment: props.activity }) &&
    !statusUtil.isInEvaluationGracePeriod(props.activity) &&
    !statusUtil.isFeedbackOrLater({ assignment: props.activity })
  ) {
    return <StatusLabels label={localize({ message: 'Activity.StudentTable.Cell.NotStarted' })} status="unavailable" />;
  }
  if (statusUtil.isEvaluateOrLater(props.activity) && !props.creation) {
    return <StatusLabels label={localize({ message: 'Activity.StudentTable.Cell.NoneAssigned' })} />;
  }

  const numEvalsAssigned = getNumEvaluationsToDo(props.creation, studentId);
  const numEvalsCompleted = getNumEvaluationsCompleted(props.creation, studentId);
  const numEvalsToReceive = getNumEvaluationsToBeReceived(props.creation);
  const numEvalsReceived = getNumEvaluationsReceived(props.creation);

  if (numEvalsAssigned === 0 && props.isShowEvalsCompleted) {
    return <StatusLabels label={localize({ message: 'Activity.StudentTable.Cell.NoneAssigned' })} />;
  }

  if (numEvalsToReceive === 0 && !props.isShowEvalsCompleted) {
    return <StatusLabels label={localize({ message: 'Activity.StudentTable.Cell.NoneAssigned' })} />;
  }

  if (numEvalsAssigned === numEvalsCompleted && props.isShowEvalsCompleted) {
    return (
      <>
        <div className="evaluations-label-container-complete">
          {lateEvaluations.length > 0 && (
            <LateEvaluationLabel
              pendingLateEvaluations={pendingLateEvaluations}
              acceptedLateEvaluations={acceptedLateEvaluations}
              rejectedLateEvaluations={rejectedLateEvaluations}
            />
          )}
          <StatusLabels label={localize({ message: 'Activity.StudentTable.Cell.Complete' })} status="no-bg" />
        </div>
      </>
    );
  }

  if (statusUtil.isFeedbackOrLater({ assignment: props.activity }) && props.isShowEvalsCompleted) {
    const missedEvaluations = numEvalsAssigned - numEvalsCompleted - rejectedLateEvaluations;

    const showMissedEvaluationsLabel =
      !props.activity.acceptLateEvaluations || statusUtil.isGradingOrLater(props.activity);

    return (
      <>
        <div className="evaluations-label-container">
          {lateEvaluations.length > 0 && (
            <LateEvaluationLabel
              pendingLateEvaluations={pendingLateEvaluations}
              acceptedLateEvaluations={acceptedLateEvaluations}
              rejectedLateEvaluations={rejectedLateEvaluations}
            />
          )}
          <span>
            {numEvalsCompleted}/{numEvalsAssigned}
          </span>
        </div>
        {missedEvaluations > 0 && showMissedEvaluationsLabel && (
          <StatusLabels
            label={localize({
              message: 'Activity.StudentTable.Cell.CountMissed',
              options: { count: missedEvaluations },
            })}
            status="danger"
          />
        )}
      </>
    );
  } else if (props.isShowEvalsCompleted) {
    return (
      <>
        {numEvalsCompleted}/{numEvalsAssigned}
      </>
    );
  }
  return (
    <>
      {numEvalsReceived}/{numEvalsToReceive}
    </>
  );
};

const LateEvaluationLabel = ({
  acceptedLateEvaluations,
  pendingLateEvaluations,
  rejectedLateEvaluations,
}: {
  acceptedLateEvaluations: number;
  pendingLateEvaluations: number;
  rejectedLateEvaluations: number;
}) => {
  return (
    <Tooltip
      title={
        <LateEvaluationsInfo
          acceptedLateEvaluations={acceptedLateEvaluations}
          pendingLateEvaluations={pendingLateEvaluations}
          rejectedLateEvaluations={rejectedLateEvaluations}
        />
      }
      placement="top"
      arrow
    >
      <div
        className={pendingLateEvaluations > 0 ? 'pending-late-evaluation-icon' : 'late-evaluation-icon'}
        data-testid="late-evaluations-information"
      >
        <Clock fontSize="small" />
      </div>
    </Tooltip>
  );
};

EvaluationCell.defaultProps = {
  lateEvaluations: [],
};

const LateEvaluationsInfo = ({
  acceptedLateEvaluations,
  pendingLateEvaluations,
  rejectedLateEvaluations,
}: {
  acceptedLateEvaluations: number;
  pendingLateEvaluations: number;
  rejectedLateEvaluations: number;
}) => {
  return (
    <div>
      {pendingLateEvaluations > 0 && (
        <div>
          <TranslatedText
            i18nKey="Evaluation.LateEvaluationsInfo.PendingEvaluations"
            values={{ count: pendingLateEvaluations }}
          />
        </div>
      )}
      {acceptedLateEvaluations > 0 && (
        <div>
          <TranslatedText
            i18nKey="Evaluation.LateEvaluationsInfo.AcceptedEvaluations"
            values={{ count: acceptedLateEvaluations }}
          />
        </div>
      )}
      {rejectedLateEvaluations > 0 && (
        <div>
          <TranslatedText
            i18nKey="Evaluation.LateEvaluationsInfo.RejectedEvaluations"
            values={{ count: rejectedLateEvaluations }}
          />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    isShowEvalsCompleted: selectIsShowEvalsCompleted(state),
  };
};

export default connect(mapStateToProps)(EvaluationCell);
