import * as _ from 'lodash-es';
import { getCreationScoreFromHistory } from 'utils/student';

import { CreationScoreUtil } from '@kritik/utils/grade';
import { isFinalized } from '@kritik/utils/stage';
import { getStudent, getStudentCreation } from './student';

export function sortByCreationScore(itemList: any, ascending: any, activity: any) {
  return _.orderBy(
    itemList,
    [
      function (item) {
        const student = getStudent(item);
        if (isFinalized({ assignment: activity })) {
          return getCreationScoreFromHistory(student, activity._id);
        }
        const creation = getStudentCreation(item);
        return CreationScoreUtil.calcAvgCreationScore({
          creation,
          rubric: activity.rubric,
          startingScore: activity.startingScore,
          lateCreationPenaltyPercentage: activity.lateCreationPenaltyPercentage,
        });
      },
    ],
    [ascending ? 'asc' : 'desc']
  );
}
