import CourseContainer from 'containers/Course';
import { localize } from 'locales';
import Page from 'pages/Page';
import { Component } from 'react';
import { connect } from 'react-redux';

class CoursePage extends Component {
  getMetaData() {
    return {
      title: this.pageTitle(),
      meta: this.pageMeta(),
      link: this.pageLink(),
      script: this.pageScript(),
    };
  }

  pageTitle = () => {
    return localize({
      message: 'Course.Info.PageTitle',
    });
  };

  pageMeta = () => {
    return [];
  };

  pageLink = () => {
    return [];
  };

  pageScript = () => {
    return [];
  };

  render() {
    return (
      <Page {...this.getMetaData()}>
        <CourseContainer {...this.props} />
      </Page>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps, {})(CoursePage);
