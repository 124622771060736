import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import Button from 'components/buttons/Button';
import React from 'react';

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Toast = (props: any) => {
  const renderCloseIcon = () => {
    if (!props.onClose) {
      return null;
    }
    return (
      <Button type="hidden" className="toast__close-icon" onClick={props.onClose}>
        <i className="fas fa-times" />
      </Button>
    );
  };
  return (
    <Dialog
      open={props.isOpen}
      // @ts-expect-error TS(2322) FIXME: Type 'ForwardRefExoticComponent<RefAttributes<unkn... Remove this comment to see the full error message
      TransitionComponent={Transition}
      keepMounted
      onClose={props.onClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      {renderCloseIcon()}
      {props.children}
    </Dialog>
  );
};

Toast.defaultProps = {
  children: null,
  onClose: null,
  isOpen: null,
};

export default Toast;
