import { fileRestrictionStatus } from '@kritik/constants/activity';
import { ReadOnlyField } from 'components/ActivityEdit/AdvancedOptions/Field';
import { TranslatedText } from 'components/TranslatedText';
import { useForm } from 'react-final-form';

const ReadOnly = () => {
  const form = useForm();

  const { requireFile, restrictFileTypes } = form.getState().values;
  if (restrictFileTypes === fileRestrictionStatus.ALL) {
    return null;
  }
  if (requireFile) {
    return (
      <ReadOnlyField>
        <TranslatedText i18nKey="Activity.RequireAttachmentField.ReadOnly.Required" />
      </ReadOnlyField>
    );
  }
  return (
    <ReadOnlyField>
      <TranslatedText i18nKey="Activity.RequireAttachmentField.ReadOnly.Optional" />
    </ReadOnlyField>
  );
};

export default ReadOnly;
