import Selection from 'components/CreationList/Menu/Select';
import { SORT_OPTIONS } from 'components/CreationList/constant';
import SORT_ARROWS from 'images/sort-arrows';
import { localize } from 'locales';
import { useState } from 'react';
import { connect } from 'react-redux';
import { setIsShowEvalsCompleted, setSortedColumn } from 'redux/creationTable/actions';
import {
  selectCreationTableColumns,
  selectCreationTableListBy,
  selectCreationTableViewType,
  selectIsShowEvalsCompleted,
  selectSortedColumn,
} from 'redux/creationTable/select';
import { selectCurrentActivity } from 'selectors/activity';
import { getSortOptions } from './utils';

const getSelectedOptionFromSortedColumn = (options: any, sortedColumn: any, isShowEvalsCompleted: any) => {
  if (!sortedColumn) {
    return (Object as any).values(options)[0].value;
  }
  let columnHeader = sortedColumn.header;
  if (columnHeader === SORT_OPTIONS.EVALUATE) {
    if (isShowEvalsCompleted) {
      columnHeader = SORT_OPTIONS.EVALUATIONS_GIVEN;
    } else {
      columnHeader = SORT_OPTIONS.EVALUATIONS_RECEIVED;
    }
  }

  if (!options[columnHeader]) {
    return (Object as any).values(options)[0].value;
  }

  return options[columnHeader].value;
};

function ArrowButton({ ascending, onClick }: any) {
  let arrowIcon;
  if (ascending) {
    arrowIcon = <img alt="sort ascending" src={SORT_ARROWS.ASCENDING} onClick={onClick} />;
  } else {
    arrowIcon = <img alt="sort descending" src={SORT_ARROWS.DESCENDING} onClick={onClick} />;
  }
  return <div className="creation-list-sort-by__order-icon">{arrowIcon}</div>;
}

function SortBy({
  sortedColumn,
  creationTableColumns,
  setSortedColumn,
  setIsShowEvalsCompleted,
  isShowEvalsCompleted,
  tableViewType,
  activity,
  listBy,
}: any) {
  const options = getSortOptions(tableViewType, listBy, activity);
  let ascending = true;
  if (sortedColumn) {
    ascending = sortedColumn.sorting.isAscending;
  }
  const [selectedValue, setSelectedValue] = useState(
    getSelectedOptionFromSortedColumn(options, sortedColumn, isShowEvalsCompleted)
  );
  const handleEvaluateSortTypeChange = (sortOption: any) => {
    if (sortOption === SORT_OPTIONS.EVALUATIONS_GIVEN) {
      setIsShowEvalsCompleted(true);
    }
    if (sortOption === SORT_OPTIONS.EVALUATIONS_RECEIVED) {
      setIsShowEvalsCompleted(false);
    }
  };

  const modifyEvalColumnHeader = (sortOption: any) => {
    let _header = sortOption;
    if (_header === SORT_OPTIONS.EVALUATIONS_GIVEN || _header === SORT_OPTIONS.EVALUATIONS_RECEIVED) {
      _header = SORT_OPTIONS.EVALUATE;
    }
    return _header;
  };
  const handleSelectChange = (event: any) => {
    let header = event.target.value;
    handleEvaluateSortTypeChange(header);
    header = modifyEvalColumnHeader(header);
    setSelectedValue(event.target.value);
    const indexToSort = creationTableColumns.findIndex((col: any) => {
      return col.header === header;
    });
    setSortedColumn(creationTableColumns, indexToSort);
  };

  const handleClickArrowBtn = () => {
    // this action auto invert the isAscending state
    const header = modifyEvalColumnHeader(selectedValue);
    const indexToSort = creationTableColumns.findIndex((col: any) => {
      return col.header === header;
    });
    setSortedColumn(creationTableColumns, indexToSort);
  };

  return (
    <Selection
      label={localize({ message: 'Creations.Menu.SortBy' })}
      value={selectedValue}
      onChange={handleSelectChange}
      options={Object.values(options)}
      icon={<ArrowButton ascending={ascending} onClick={handleClickArrowBtn} />}
    />
  );
}

const mapStateToProps = (state: any) => {
  return {
    sortedColumn: selectSortedColumn(state),
    creationTableColumns: selectCreationTableColumns(state),
    tableViewType: selectCreationTableViewType(state),
    listBy: selectCreationTableListBy(state),
    activity: selectCurrentActivity(state),
    isShowEvalsCompleted: selectIsShowEvalsCompleted(state),
  };
};

export default connect(mapStateToProps, { setSortedColumn, setIsShowEvalsCompleted })(SortBy);
