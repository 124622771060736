import { schema } from 'normalizr';

import student from 'schemas/student';
import user from 'schemas/user';

const course = new schema.Entity(
  'courses',
  {
    students: [student],
    user,
  },
  { idAttribute: '_id' }
);

export default course;
