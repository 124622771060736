import { ACTIVITY_TYPES, DEFAULT_WEIGHT_CALIBRATION } from '@kritik/constants/activity';
import * as ActivityUtil from '@kritik/utils/activity';
import { FIELD_NAME as removeStudentFromActivityAfterMissedCreation } from 'components/ActivityEdit/AdvancedOptions/Fields/MissedCreation/constants';
import { FIELD_NAME as NUM_EVALS_TO_ASSIGN } from 'components/ActivityEdit/AdvancedOptions/Fields/NumEvalsToAssign/constants';
import {
  IS_MULTIPLE_TOPICS,
  NUMBER_OF_TOPICS,
  FIELD_NAME as TOPICS,
} from 'components/ActivityEdit/AdvancedOptions/Fields/Topics/constants';
import WeightField from 'components/ActivityEdit/AdvancedOptions/Fields/Weight';
import { FIELD_NAME as WEIGHT } from 'components/ActivityEdit/AdvancedOptions/Fields/Weight/constants';
import { getInitialAdvOptionValues } from 'components/ActivityEdit/AdvancedOptions/utils';
import SOURCE from 'components/ActivityEdit/Calibration/CalibrationSource/constants';
import CalibrationForm from 'components/ActivityEdit/Calibration/Form';
import { FIELD_NAME as INSTRUCTIONS } from 'components/ActivityEdit/Details/Fields/Instructions/constants';
import { FIELD_NAME as OBJECTIVE } from 'components/ActivityEdit/Details/Fields/Objective/constants';
import { FIELD_NAME as TITLE } from 'components/ActivityEdit/Details/Fields/Title/constants';
import { FIELD_NAME as RUBRIC } from 'components/ActivityEdit/Details/Rubric/constants';
import ErrorMsg from 'components/ActivityEdit/ErrorMsg';
import localUtil from 'components/ActivityEdit/util';
import { TranslatedText } from 'components/TranslatedText';
import { Button } from 'components/buttons';
import FormSubmitButtons from 'components/core/form/SubmitButtons';
import { localize } from 'locales';
import { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { getFinalizedActivitiesWithoutCalibrations, selectCalibrationOptions } from 'redux/activityEdit';
import { listCreations } from 'redux/creation';
import { getAssignment } from 'selectors/activity';
import { getCourse } from 'selectors/course';
import { ACTIVITY_TEMPLATE } from './constants.js';

const getCalibrationActivityDataFromForm = (values: any) => {
  return {
    activityType: values.activityType,
    files: values.files,
    [TITLE]: values[TITLE],
    [OBJECTIVE]: values[OBJECTIVE],
    [INSTRUCTIONS]: values[INSTRUCTIONS],
    numericGrading: values.isNumericGrading,
    [RUBRIC]: values[RUBRIC],
    [NUM_EVALS_TO_ASSIGN]: values[NUM_EVALS_TO_ASSIGN],
    isCalibrationActivity: values.isCalibrationActivity,
    calibrationCreations: values.calibrationCreations,
    fileExtensionsAllowed: localUtil.getFileExtentionsAllowed(values),
    [TOPICS]: values[TOPICS],
    [IS_MULTIPLE_TOPICS]: values[IS_MULTIPLE_TOPICS],
    [NUMBER_OF_TOPICS]: values[NUMBER_OF_TOPICS],
    [removeStudentFromActivityAfterMissedCreation]: false,
  };
};

const FormWrapper = (props: any) => {
  const [calibrationSource, setCalibrationSource] = useState(null);

  useEffect(() => {
    if (props.isEdit || props.isDuplicating) {
      props.listCreations({
        course: props.course._id,
        calibration: 1,
      });
    }
  }, []);
  const getInitialFormValues = () => {
    let values = {};
    const { activity } = props;
    if (props.isEdit || props.isDuplicating) {
      values = {
        activityType: activity.activityType,
        [TITLE]: activity ? activity[TITLE] : '',
        [RUBRIC]: activity.rubric,
        [OBJECTIVE]: activity.objective,
        [INSTRUCTIONS]: activity.instructions,
        files: props.activity.files,
        isNumericGrading: ActivityUtil.isNumericGrading(props.activity),
        [WEIGHT]: DEFAULT_WEIGHT_CALIBRATION,
        calibrationCreations: activity.calibrationCreations,
        isCalibrationActivity: activity.isCalibrationActivity,
        calibrationActivity: activity,
        [removeStudentFromActivityAfterMissedCreation]: false,
        enableSimilarityReport: false,
      };
    } else {
      values = {
        activityType: ACTIVITY_TYPES.CALIBRATION,
        [TITLE]: '',
        [RUBRIC]: null,
        [OBJECTIVE]: '',
        [INSTRUCTIONS]: '',
        files: [],
        isNumericGrading: true,
        [WEIGHT]: DEFAULT_WEIGHT_CALIBRATION,
        calibrationCreations: [null, null, null],
        isCalibrationActivity: true,
        calibrationActivity: null,
        [SOURCE.FIELD_NAME]: SOURCE.TYPES.PAST_ACTIVITY,
        [removeStudentFromActivityAfterMissedCreation]: false,
        enableSimilarityReport: false,
      };
    }
    if (calibrationSource === SOURCE.TYPES.NEW_ACTIVITY) {
      values = {
        ...values,
        isCalibrationActivity: null,
        calibrationActivity: null,
        [RUBRIC]: null,
        [OBJECTIVE]: ACTIVITY_TEMPLATE.objective,
        [INSTRUCTIONS]: ACTIVITY_TEMPLATE.instructions,
        [SOURCE.FIELD_NAME]: calibrationSource,
        [removeStudentFromActivityAfterMissedCreation]: false,
      };
    }
    return {
      ...getInitialAdvOptionValues(props.activity, props.course),
      ...values,
      settings: {
        activity: props.activity,
        isDuplicating: props.isDuplicating,
        isEdit: props.isEdit,
      },
    };
  };

  const handleOnSubmit = async (values: any) => {
    const formSettings = localUtil.getFormSettings(values);
    const data = getCalibrationActivityDataFromForm(values);

    return await props.onSubmit({
      data,
      formSettings,
      fileList: values.files,
    });
  };

  const canSubmit = (isSubmitting: any) => {
    return !isSubmitting;
  };

  const renderButtons = ({ values, submitting }: any) => {
    return (
      <FormSubmitButtons errors={<ErrorMsg />}>
        <Button
          type="primary"
          inputType="submit"
          loading={submitting}
          disabled={!canSubmit(submitting)}
          unavailable={!canSubmit(submitting)}
          data-testid="save-activity"
        >
          <TranslatedText i18nKey="ActivityEdit.Calibration.SaveActivity" />
        </Button>
        <Button testid="cancel-editing-calibration-activity" type="secondary" onClick={props.onCancel}>
          <TranslatedText i18nKey="Cancel" />
        </Button>
      </FormSubmitButtons>
    );
  };
  const [formValues, setFormValues] = useState(getInitialFormValues());

  useEffect(() => {
    setFormValues(getInitialFormValues());
  }, [props.activityId, calibrationSource]);

  return (
    <Form
      onSubmit={handleOnSubmit}
      initialValues={formValues}
      mutators={{
        // expect (field, value) args from the mutator
        setValue: ([field, value], state, { changeValue }) => {
          changeValue(state, field, () => {
            return value;
          });
        },
        setCalibrationCreation: (
          [{ marks, content, files, instructorEvaluationNotes, creationId, index }],
          state,
          { changeValue }
        ) => {
          const FIELD = 'calibrationCreations';
          const existingScores = state.fields[FIELD].lastFieldState.value;
          const scoreList = existingScores.map((score: any) => {
            if (score && score._id === creationId) {
              return null;
            }
            return score;
          });
          scoreList[index] = { _id: creationId, marks, content, instructorEvaluationNotes, files };
          changeValue(state, FIELD, () => {
            return scoreList;
          });
        },
      }}
      render={({ handleSubmit, form, submitting, pristine, values }) => {
        props.formRef.current = form;
        return (
          <form onSubmit={handleSubmit}>
            <CalibrationForm
              changeCalibrationSource={(type: any) => {
                return setCalibrationSource(type);
              }}
              canViewMode={props.canViewMode}
              innerRef={props.innerRef}
            />
            <h3>{localize({ message: 'ActivityEdit.Calibration.Options' })}</h3>
            <WeightField />
            {renderButtons({ values, submitting })}
          </form>
        );
      }}
    />
  );
};

const mapStateToProps = (state: any) => {
  return {
    activity: getAssignment(state, state.selected.assignmentId),
    activityId: state.selected.assignmentId,
    course: getCourse(state, state.selected.courseId),
    courseId: state.selected.courseId,
    calibrationCreationOptions: selectCalibrationOptions(state),
    finalizedActivityList: getFinalizedActivitiesWithoutCalibrations(state),
  };
};

export default connect(mapStateToProps, {
  listCreations,
})(FormWrapper);
