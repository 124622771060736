import { isGroupAssignment, isGroupPresentationActivity } from '@kritik/utils/activity';
import * as FormatUtils from '@kritik/utils/format';
import * as ActivityStatusUtil from '@kritik/utils/stage';
import { TranslatedText } from 'components/TranslatedText';
import InlineInformation from 'components/layout/InlineInformation';
import NoticeBoard from 'components/layout/NoticeBoard';
import { localize } from 'locales';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const MissedWork = ({ field }: any) => {
  return (
    <NoticeBoard type="danger" title={localize({ message: 'NoticeBoards.MissedWork.Title', options: { field } })}>
      <TranslatedText i18nKey="NoticeBoards.MissedWork.Content" values={{ field }} />
    </NoticeBoard>
  );
};

export const MissedFeedback = () => {
  return (
    <NoticeBoard type="danger" title={localize({ message: 'NoticeBoards.MissedFeedback.Title' })}>
      <TranslatedText i18nKey="NoticeBoards.MissedFeedback.Content" />
    </NoticeBoard>
  );
};

export const LateEnrollment = ({ stage }: any) => {
  let label = 'EVALUATE';
  if (stage) {
    label = stage;
  }
  return (
    <NoticeBoard type="danger" title={localize({ message: 'NoticeBoards.LateEnrollment.Title' })}>
      <TranslatedText i18nKey="NoticeBoards.LateEnrollment.Content" values={{ label }} />
    </NoticeBoard>
  );
};

export const NoAssignedCreations = () => {
  return (
    <NoticeBoard type="danger" title={localize({ message: 'NoticeBoards.NoAssignedCreations.Title' })}>
      <TranslatedText i18nKey="NoticeBoards.NoAssignedCreations.Content" />
    </NoticeBoard>
  );
};

export function NoAssignedEvaluations() {
  return (
    <NoticeBoard type="danger" title={localize({ message: 'NoticeBoards.NoAssignedEvaluations.Title' })}>
      {localize({
        message: 'Activity.removeStudentFromActivityAfterMissedCreation.Evaluate.StudentNotice',
      })}
    </NoticeBoard>
  );
}

export const NoAssignedFeedback = () => {
  const { t } = useTranslation();
  return (
    <NoticeBoard
      type="caution"
      title={t('Activity.Feedback.NoFeedbackAssignedNotice.Title')}
      testid="no-feedback-assigned-notice"
    >
      {t('Activity.Feedback.NoFeedbackAssignedNotice.Content')}
    </NoticeBoard>
  );
};

export const StartingSoon = ({ stage }: any) => {
  return (
    <NoticeBoard type="information" title={localize({ message: 'NoticeBoards.StartingSoon.Title' })}>
      <TranslatedText i18nKey="NoticeBoards.StartingSoon.Content" values={{ stage }} />
    </NoticeBoard>
  );
};

export const NoGroup = () => {
  return (
    <NoticeBoard
      type="danger"
      title={localize({ message: 'NoticeBoards.NoGroup.Title' })}
      information
      style={{ marginBottom: '20px' }}
      testid="contact-to-join-group-notice"
    >
      <TranslatedText i18nKey="NoticeBoards.NoGroup.Content" />
    </NoticeBoard>
  );
};

export const LateSubmissionWrapper = ({ title, children }: any) => {
  return (
    <NoticeBoard type="caution" style={{ marginBottom: '20px' }} title={title} testid="late-submission-warning">
      {children}
    </NoticeBoard>
  );
};

export function LateSubmissionInstructorNotice({ creationDueDate, submissionDate }: any) {
  const hourStr = FormatUtils.subTimeFromCreationDue(creationDueDate, submissionDate);
  return (
    <LateSubmissionWrapper
      title={localize({
        message: 'NoticeBoards.LateSubmissionInstructor.Title',
        options: { hourStr },
      })}
    >
      <TranslatedText i18nKey="NoticeBoards.LateSubmissionInstructor.Content" />
    </LateSubmissionWrapper>
  );
}

export function GracePeriodCreationStudentNotice({}) {
  return (
    <LateSubmissionWrapper title={localize({ message: 'NoticeBoards.GracePeriodCreationStudent.Title' })}>
      <TranslatedText i18nKey="NoticeBoards.GracePeriodCreationStudent.Content" />
    </LateSubmissionWrapper>
  );
}

export function MissedSubmissionStudentNotice({ creation, activity, user }) {
  const isGroupActivity = isGroupAssignment(activity) || isGroupPresentationActivity(activity);
  const isGroupLeader = user._id === creation.user._id;

  const title = isGroupActivity
    ? isGroupLeader
      ? localize({
          message: 'Activity.Presentation.StudentView.MissedSubmission.Group.Leader.Title',
        })
      : localize({
          message: 'Activity.Presentation.StudentView.MissedSubmission.Group.Member.Title',
        })
    : localize({
        message: 'Activity.Presentation.StudentView.MissedSubmission.Title',
      });

  const content = isGroupActivity
    ? isGroupLeader
      ? localize({
          message: 'Activity.Presentation.StudentView.MissedSubmission.Group.Leader',
        })
      : localize({
          message: 'Activity.Presentation.StudentView.MissedSubmission.Group.Member',
        })
    : localize({
        message: 'Activity.Presentation.StudentView.MissedSubmission',
      });

  return (
    <NoticeBoard type="danger" title={title} data-testid="missed-creation">
      {content}
    </NoticeBoard>
  );
}

export function KSChangeUnavailableNotice({ esEnabled }: any) {
  const scoreType = esEnabled
    ? localize({ message: 'NoticeBoards.KSChangeUnavailable.EvaluationScore' })
    : localize({ message: 'NoticeBoards.KSChangeUnavailable.KritikScore' });

  return (
    <NoticeBoard
      type="information"
      title={localize({
        message: 'NoticeBoards.KSChangeUnavailable.Title',
        options: { scoreType },
      })}
    >
      <TranslatedText i18nKey="NoticeBoards.KSChangeUnavailable.Content" values={{ scoreType }} />
    </NoticeBoard>
  );
}

export function EvaluationScoreCalibrationActivityNotice() {
  return (
    <NoticeBoard type="information" title="">
      <TranslatedText i18nKey="NoticeBoards.EvaluationScoreCalibrationActivity.Content" />
    </NoticeBoard>
  );
}

export const CalibrationGradingNotice = ({ isInstructor }: any) => {
  const word = isInstructor
    ? localize({ message: 'NoticeBoards.CalibrationGrading.Student' })
    : localize({ message: 'NoticeBoards.CalibrationGrading.Your' });
  const link = isInstructor
    ? 'https://help.kritik.io/en/articles/6387297-calibration-activities'
    : 'https://help.kritik.io/en/articles/6388493-calibration-activities';
  return (
    <NoticeBoard type="information" title={localize({ message: 'NoticeBoards.CalibrationGrading.Title' })}>
      <TranslatedText
        i18nKey="NoticeBoards.CalibrationGrading.Content"
        values={{
          word,
          link: `<a target="_blank" href="${link}" rel="noreferrer">${localize({ message: 'NoticeBoards.CalibrationGrading.LinkText' })}</a>`,
        }}
      />
    </NoticeBoard>
  );
};

export const LateSubmissionAvailability = ({ activity }: any) => {
  const statusEndDate = ActivityStatusUtil.getEndOfCreationPeriod(activity);
  return (
    <InlineInformation
      type="danger"
      title={localize({
        message: 'NoticeBoards.LateSubmissionAvailability.Title',
        options: { timeDiff: FormatUtils.displayTimeBetweenDates(statusEndDate, new Date()) },
      })}
      testid="overdue-creation-notice"
    >
      <TranslatedText i18nKey="NoticeBoards.LateSubmissionAvailability.Content" />
    </InlineInformation>
  );
};

export const NoGroupInlineNotice = () => {
  return (
    <InlineInformation type="danger" title={localize({ message: 'NoticeBoards.NoGroupInline.Title' })} information>
      <TranslatedText i18nKey="NoticeBoards.NoGroupInline.Content" />
    </InlineInformation>
  );
};

export const EnrolledAfterCreateStageInGroupActivity = () => {
  const { t } = useTranslation();
  return (
    <InlineInformation type="danger" information testid="enrolled-after-create-stage-group-activity-notice">
      {t('Activity.GroupActivity.EnrolledAfterCreateStage')}{' '}
      {t('Activity.GroupActivity.EnrolledAfterCreateStage.Contact')}
    </InlineInformation>
  );
};

export const EvaluationScoreGroupScoreNotice = () => {
  return (
    <InlineInformation type="information" information>
      <TranslatedText i18nKey="NoticeBoards.EvaluationScoreGroupScore.Content" />
    </InlineInformation>
  );
};

export const FeedbackScoreGroupScoreNotice = () => {
  return (
    <InlineInformation type="information" information>
      <TranslatedText i18nKey="NoticeBoards.FeedbackScoreGroupScore.Content" />
    </InlineInformation>
  );
};

export const EvaluationScoreGroupEvalsNotice = () => {
  return (
    <React.Fragment>
      <h4>
        <TranslatedText i18nKey="NoticeBoards.EvaluationScoreGroupEvals.Title" />
      </h4>
      <InlineInformation type="information" information>
        <TranslatedText i18nKey="NoticeBoards.EvaluationScoreGroupEvals.Content" />
      </InlineInformation>
    </React.Fragment>
  );
};

export const FeedbackScoreGroupFeedbackNotice = () => {
  return (
    <InlineInformation type="information" information>
      <TranslatedText i18nKey="NoticeBoards.FeedbackScoreGroupFeedback.Content" />
    </InlineInformation>
  );
};

export default {
  CalibrationGradingNotice,
  KSChangeUnavailableNotice,
  LateEnrollment,
  GracePeriodCreationStudentNotice,
  LateSubmissionInstructorNotice,
  MissedSubmissionStudentNotice,
  MissedWork,
  MissedFeedback,
  NoAssignedCreations,
  NoAssignedFeedback,
  NoGroup,
  NoGroupInlineNotice,
  StartingSoon,
  EvaluationScoreGroupEvalsNotice,
  EvaluationScoreGroupScoreNotice,
  FeedbackScoreGroupScoreNotice,
  FeedbackScoreGroupFeedbackNotice,
  EvaluationScoreCalibrationActivityNotice,
};
