import validators from 'components/ActivityEdit/Details/Fields/validators';
import FormTextInput from 'components/core/form/TextField';
import { Field } from 'react-final-form';
import { FIELD_LABEL, FIELD_NAME } from './constants';

export const LABEL = {
  value: FIELD_NAME,
  label: FIELD_LABEL,
};

const TitleField = () => {
  return (
    <Field
      required
      name={LABEL.value}
      type="text"
      validate={validators.composeValidators(validators.isRequired, validators.maxLength(160))}
    >
      {({ input, meta }) => {
        return (
          <FormTextInput
            label={LABEL.label}
            isRequired
            {...input}
            error={meta.error && meta.touched && meta.error}
            testid="activity-title"
          />
        );
      }}
    </Field>
  );
};
export default TitleField;
