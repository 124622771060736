import FormField from 'components/core/form/Field';
import FieldInfo from 'components/core/form/FieldInfo';
import ShortenText from 'components/General/ShortenText';
import { localize } from 'locales';

function Preview(props: any) {
  const { rubric } = props;

  let rubricName = '';
  if (!rubric.name || rubric.name.length === 0) {
    rubricName = localize({ message: 'Rubric.Preview.DefaultName' });
  } else {
    rubricName = rubric.name;
  }

  return (
    <FormField>
      <div className="rubric-preview__description">
        <div className="rubric-preview__description-title" data-testid="preview-rubric-title">
          <ShortenText maxLength={60} hideToolTip label={rubricName} />
        </div>
        <FieldInfo label={rubric.description} />
      </div>
    </FormField>
  );
}

export default Preview;
