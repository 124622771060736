import { Popper } from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { TranslatedText } from 'components/TranslatedText';
import Button from 'components/buttons/Button';
import ButtonContainer from 'components/buttons/ButtonContainer';
import RadioGroup from 'components/core/input/Radio';
import InlineInformation from 'components/layout/InlineInformation';
import { localize } from 'locales';
import { useEffect, useState } from 'react';

const useStylesButton = makeStyles({
  root: {
    backgroundColor: 'transparent',
    padding: '4px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    height: '100%',
    width: '100%',
  },
});

const useStylesIcon = makeStyles({
  root: {
    '&:hover': {
      color: '#8E24AA',
    },
  },
});

const SYNC_OPTIONS = {
  READY_TO_SYNC: 'ready-to-sync',
  NO_SYNC: 'not-synced',
};

const radioOptions = [
  {
    value: SYNC_OPTIONS.NO_SYNC,
    label: localize({ message: 'RowMenu.RadioOptions.DontSyncAgain' }),
  },
  {
    value: SYNC_OPTIONS.READY_TO_SYNC,
    label: localize({ message: 'RowMenu.RadioOptions.SyncAgainAndOverwrite' }),
    testid: 'sync-again-overwrite',
  },
];

function RowMenuPopper(props: any) {
  const [radioValue, setRadioValue] = useState(SYNC_OPTIONS.NO_SYNC);

  const handleRadioInputChange = (e: any) => {
    setRadioValue(e.target.value);
  };

  useEffect(() => {
    const status = props.syncStatus === 'synced' ? SYNC_OPTIONS.NO_SYNC : props.syncStatus;
    setRadioValue(status);
  }, [props.isOpen]);

  const renderOverwriteInfo = () => {
    if (radioValue === SYNC_OPTIONS.NO_SYNC) {
      return null;
    }
    return (
      <div className="grade-sync-change__notice">
        <InlineInformation>
          <TranslatedText i18nKey="RowMenu.OverwriteInfo" />
        </InlineInformation>
      </div>
    );
  };

  const handleSyncChangeForActivity = () => {
    if (!props.activityId || !radioValue) {
      return;
    }
    props.onChangeRadioSelection({ activityId: props.activityId, radioValue });
    props.onCancel();
  };
  return (
    <Popper
      className="grade-sync-edit-popup"
      open={props.isOpen || false}
      // @ts-expect-error TS(2322) FIXME: Type '"left-bottom"' is not assignable to type 'Po... Remove this comment to see the full error message
      placement="left-bottom"
      anchorEl={props.anchorEl}
      disablePortal={false}
      modifiers={{
        offset: {
          enabled: true,
          offset: '0, 10',
        },
      }}
      transition
    >
      <ClickAwayListener onClickAway={props.onCancel} mouseEvent="onMouseDown" touchEvent="onTouchStart">
        <div>
          <h5>
            <TranslatedText i18nKey="RowMenu.ChangeSyncing" />
          </h5>
          <div className="grade-sync-change__radio">
            <RadioGroup
              options={radioOptions}
              onChange={handleRadioInputChange}
              value={radioValue}
              legendText="Legend.Text.LMS.GradeSyncChange"
            />
          </div>
          {renderOverwriteInfo()}
          <ButtonContainer>
            <Button type="primary" onClick={handleSyncChangeForActivity} testid="confirm-change-syncing">
              <TranslatedText i18nKey="RowMenu.ChangeSyncing" />
            </Button>
            <Button type="secondary" onClick={props.onCancel}>
              <TranslatedText i18nKey="Cancel" />
            </Button>
          </ButtonContainer>
        </div>
      </ClickAwayListener>
    </Popper>
  );
}

function RowMenu(props: any) {
  const [anchorEl, setAnchorEl] = useState(null);
  const classesButton = useStylesButton();
  const classesIcon = useStylesIcon();

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopup = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      <RowMenuPopper
        anchorEl={anchorEl}
        isOpen={anchorEl}
        onCancel={handleClosePopup}
        activityId={props.activityId}
        onChangeRadioSelection={props.onChangeRadioSelection}
        syncStatus={props.syncStatus}
      />
      <IconButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className={classesButton.root}
        data-testid="grade-sync-menu"
      >
        <MoreVertIcon className={classesIcon.root} />
      </IconButton>
    </div>
  );
}

export default RowMenu;
