import { Component } from 'react';

import LoginOrRegisterContainer from 'containers/LoginOrRegister';
import { I18nKey, localize } from 'locales';
import Page from 'pages/Page';
import { withRouter } from 'utils/withRouter';

class LoginOrRegister extends Component {
  getMetaData() {
    return {
      title: this.pageTitle(),
      meta: this.pageMeta(),
      link: this.pageLink(),
    };
  }

  pageTitle = () => {
    // @ts-expect-error TS(2339) FIXME: Property 'routes' does not exist on type 'Readonly<{... Remove this comment to see the full error message
    const { router } = this.props;
    let message: I18nKey = 'Login';
    if (router.location.pathname.includes('/signup')) {
      message = 'SignUp';
    }
    return localize({ message });
  };

  pageMeta = () => {
    return [];
  };

  pageLink = () => {
    return [];
  };

  render() {
    return (
      <Page {...this.getMetaData()}>
        <LoginOrRegisterContainer {...this.props} />
      </Page>
    );
  }
}

export default withRouter(LoginOrRegister);
