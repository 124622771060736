import { sanitizeEmails } from '@kritik/utils/general';
import Dialog from '@material-ui/core/Dialog';
import { closeGlobalModal } from 'actions/modals';
import { AuthUser } from 'app-types';
import PricingPlan from 'components/RosterManager/StudentInviteModal/PricingPlan';
import { TranslatedText } from 'components/TranslatedText';
import FieldLabel from 'components/core/form/FieldLabel';
import { InlineInformation } from 'components/layout';
import ModalAction from 'components/modals/Components/Action';
import ModalTitle from 'components/modals/Components/Header';
import { inviteStudentsMutation, useFetchCourse } from 'hooks/course';
import { localize } from 'locales';
import { Component } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { getErrorMessageFromResponse } from 'utils/error';
import { trackEvent } from 'utils/userEvents';

export const MODAL_ID = 'student-invite-modal';

type StudentInviteModalState = any;

type Props = {
  authUser: AuthUser;
  emailSuccess: string;
  emailBusy: any;
  modalStatus: any;
  closeGlobalModal: () => void;
  inviteStudents: any;
  course: any;
};
class StudentInviteModalComponent extends Component<Props, StudentInviteModalState> {
  constructor(props: any) {
    super(props);
    this.state = {
      emailList: '',
      isCheckedPriceDisclaimer: false,
      error: '',
    };
  }

  componentDidUpdate(prevProps: {}, prevState: StudentInviteModalState) {
    if (!(prevProps as any).emailSuccess && this.props.emailSuccess && this.props.emailSuccess == 'email-list') {
      setTimeout(() => {
        this.cancel();
      }, 1000);
    }
  }

  onValueChange(ev: any) {
    this.setState({ [ev.target.name]: ev.target.value });
  }

  clearInput() {
    this.setState({
      emailList: '',
      isCheckedPriceDisclaimer: false,
      error: '',
    });
  }

  isOpen() {
    return this.props.modalStatus.options.props.id === MODAL_ID;
  }

  cancel() {
    this.clearInput();
    this.props.closeGlobalModal();
  }

  inviteStudents = () => {
    const emails = sanitizeEmails(this.state.emailList);
    if (!this.props.emailBusy && !this.props.emailSuccess) {
      const { _id, title } = this.props.course;
      this.props.inviteStudents.mutate(
        {
          courseId: _id,
          emails,
          reinvite: false,
        },
        {
          onSuccess: () => {
            trackEvent('Students Invited', this.props.authUser, {
              courseId: _id,
              courseName: title,
            });

            this.clearInput();
            this.props.closeGlobalModal();
            this.props.inviteStudents.reset();
          },
          onError: (error: any) => {
            this.setState({ error: getErrorMessageFromResponse(error) });
          },
        }
      );

      this.setState({ isCheckedPriceDisclaimer: false });
    }
  };

  isFormValid() {
    if (this.state.emailList.length > 0 && (this.props.course.price === 0 || this.state.isCheckedPriceDisclaimer)) {
      return true;
    }
    return false;
  }

  togglePriceDisclaimer() {
    this.setState({ isCheckedPriceDisclaimer: !this.state.isCheckedPriceDisclaimer });
  }

  render() {
    return (
      <Dialog
        className="main-modal"
        aria-labelledby="simple-dialog-title"
        open={Boolean(this.isOpen())}
        maxWidth="sm"
        fullWidth
      >
        <ModalTitle title={localize({ message: 'RosterManager.StudentInviteModal.Title' })} />
        <div className="main-modal__content">
          <div className="mail-modal__item">
            <InlineInformation
              type="information"
              title={localize({ message: 'RosterManager.StudentInviteModal.EnrollmentInfo.Title' })}
            >
              <TranslatedText i18nKey="RosterManager.StudentInviteModal.EnrollmentInfo.Content" />
            </InlineInformation>
          </div>
          {this.state.error && (
            <InlineInformation type="danger" title={this.state.error} testid="invite-students-error" />
          )}
          <div className="main-modal__item">
            <FieldLabel label={localize({ message: 'RosterManager.StudentInviteModal.StudentEmails.Label' })} />
            <label htmlFor="studentEmailList" className="visually-hidden">
              {localize({ message: 'Label.FormControl.InviteStudents' })}
            </label>
            <textarea
              id="studentEmailList"
              data-testid="studentManagement_email-list"
              value={this.state.emailList}
              className="main-modal__textarea"
              name="emailList"
              placeholder={localize({
                message: 'RosterManager.StudentInviteModal.StudentEmails.Placeholder',
              })}
              onChange={(ev) => {
                return this.onValueChange(ev);
              }}
            />
            <p className="main-modal__textarea-hint">
              <TranslatedText i18nKey="RosterManager.StudentInviteModal.StudentEmails.Hint" />
            </p>
          </div>

          <div className="mail-modal__item">
            <PricingPlan
              course={this.props.course}
              institution={this.props.course.user.institution}
              onChange={this.togglePriceDisclaimer.bind(this)}
              isChecked={this.state.isCheckedPriceDisclaimer}
            />
          </div>
        </div>
        <ModalAction
          actions={[
            {
              testid: 'send-invite',
              onClick: this.inviteStudents,
              type: 'primary',
              isLoading: this.props.emailBusy || this.props.inviteStudents.isLoading,
              isSuccess: this.props.emailSuccess || this.props.inviteStudents.isSuccess,
              label: localize({ message: 'RosterManager.StudentInviteModal.SendInviteEmails' }),
              isDisabled: !this.isFormValid() || this.props.emailBusy,
              isUnavailable: !this.isFormValid() || this.props.emailBusy,
            },
            {
              testid: 'cancel-send-invites',
              onClick: this.cancel.bind(this),
              label: localize({ message: 'Cancel' }),
            },
          ]}
        />
      </Dialog>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    modalStatus: state.modal,
    authUser: state.user.authUser,
  };
};

const StudentInviteModal = (props: any) => {
  const { courseId } = useParams();
  const { data: course } = useFetchCourse(courseId);

  const inviteStudents = inviteStudentsMutation(courseId);

  return <StudentInviteModalComponent {...props} inviteStudents={inviteStudents} course={course} />;
};

export default connect(mapStateToProps, { closeGlobalModal })(StudentInviteModal);
