import { TFunction } from 'i18next';
import { I18nKey } from 'locales';
import { Trans } from 'react-i18next';

type TranslatedTextProps = {
  i18nKey: I18nKey;
  t?: TFunction;
  values?: Record<string, string> | { count: number };
};

export function TranslatedText(p: TranslatedTextProps) {
  return (
    <Trans
      t={p.t}
      i18nKey={p.i18nKey as string}
      components={{
        bold: <b />,
        a: <a />,
        p: <p />,
        br: <br />,
      }}
      values={p.values}
      shouldUnescape={true}
    />
  );
}
