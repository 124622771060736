import { isRevealingEvaluators } from '@kritik/utils/course';
import { getInitials } from '@kritik/utils/format';
import { UserRole } from 'app-types';
import classNames from 'classnames';
import AvatarPopover from 'components/layout/UserAvatars/AvatarPopover';
import { useFetchCourse, useUserRoleInCourse } from 'hooks/course';
import ANONYMOUS from 'images/anon-profile.png';
import ksBorder from 'images/ks-ranking';
import { localize } from 'locales';
import { Course, User } from 'old-common/types.generated';
import { useId, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { selectGroupById } from 'redux/group/selectors';
import { getUser } from 'selectors/user';
import { getKritikScoreLevel } from 'utils/student';

// contains image src for each Kritik Score
const rankedAvatar = (ks: any) => {
  const ksLevel = getKritikScoreLevel(ks);
  switch (ksLevel) {
    case 6:
      return ksBorder.LEVEL_6;
    case 5:
      return ksBorder.LEVEL_5;
    case 4:
      return ksBorder.LEVEL_4;
    case 3:
      return ksBorder.LEVEL_3;
    case 2:
      return ksBorder.LEVEL_2;
    case 1:
    default:
      return ksBorder.LEVEL_1;
  }
};

type AvatarDisplayProps = {
  user: any;
  myUser: any;
  size: string;
  style: any;
  kritikScore: number;
  hoverable: boolean;
  group: any;
  showName: boolean;
  course: Course & { userRole: UserRole };
  groupCard: boolean;
  anonymous: boolean;
  showBorder: boolean;
  testid?: string;
};

const AvatarDisplay = (props: AvatarDisplayProps) => {
  const {
    user,
    myUser,
    size,
    style,
    kritikScore,
    hoverable,
    group,
    showName,
    groupCard,
    anonymous,
    showBorder,
    testid,
  } = props;
  const ariaDescriptionId = useId();

  const { courseId } = useParams();
  const { data: course } = useFetchCourse(courseId);

  const [isOpenPopover, setIsOpenPopover] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { isInstructorInCourse } = useUserRoleInCourse();

  if (!user) {
    return null;
  }

  const isCollaborator = course?.collaborators.some((collaborator) => (collaborator as User)._id === user._id);

  const handleHover = (event: any, isOpen: any) => {
    setIsOpenPopover(isOpen);
    if (isOpen) {
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(null);
    }
  };

  let adjustedKS: any;
  if (kritikScore < 0) {
    adjustedKS = 0;
  } else {
    adjustedKS = Math.round(kritikScore);
  }

  const isViewingSelf = user._id == myUser._id;
  const hideGroupMemberInfo = groupCard && !isInstructorInCourse;

  let _hoverable = hoverable;
  if (hideGroupMemberInfo) {
    _hoverable = false;
  }

  const avatarContainerClasses = classNames('avatar-container', {
    hoverable: _hoverable,
  });

  const avatarNameClasses = classNames('avatar-name', {
    'is-me': isViewingSelf,
  });

  const shouldRenderPopup = hoverable && !group && !hideGroupMemberInfo;

  const renderKritikScore = () => {
    if (isInstructorInCourse || !shouldRenderPopup) {
      return null;
    }
    return (
      <div className="score-info" data-testid="grading-power-border">
        {Math.trunc(kritikScore)}
      </div>
    );
  };

  const renderPopup = () => {
    if (!shouldRenderPopup) {
      return null;
    }
    return <AvatarPopover id={ariaDescriptionId} isOpen={isOpenPopover} kritikScore={adjustedKS} anchorEl={anchorEl} />;
  };

  const renderBorder = () => {
    if (group || hideGroupMemberInfo || !showBorder) {
      return null;
    }
    return <img className="border-img" src={rankedAvatar(adjustedKS)} aria-hidden="true" />;
  };

  let userName = localize({ message: 'Anonymous' });

  const shouldNotBeAnonymized =
    isViewingSelf ||
    groupCard ||
    isInstructorInCourse ||
    isCollaborator ||
    anonymous === false ||
    isRevealingEvaluators(course);

  if (shouldNotBeAnonymized) {
    userName = group ? group.name : user.profile.name;
  }

  const renderUserName = () => {
    if (showName) {
      return (
        <span className={avatarNameClasses} data-testid={userName}>
          {userName}
        </span>
      );
    }
    return null;
  };

  const renderUserImage = () => {
    const classes = classNames('profile-img', {
      'profile-img__instructor': isInstructorInCourse,
    });

    return (
      <img
        className={classes}
        src={shouldNotBeAnonymized ? user.profile.picture : ANONYMOUS}
        aria-hidden="true"
        alt={shouldNotBeAnonymized ? user.profile.name : 'Anonymous'}
      />
    );
  };

  const GroupImage = () => {
    return <div className="group-cover">{shouldNotBeAnonymized ? getInitials(group.name) : '?'}</div>;
  };

  // @ts-expect-error TS(2322) FIXME: Type '{ group: any; }' is not assignable to type '... Remove this comment to see the full error message
  const renderImage = group ? <GroupImage group={group} /> : renderUserImage();

  return (
    <div className="avatar-main-container" data-testid="user-avatar">
      {!shouldRenderPopup && (
        <span id={ariaDescriptionId} className="visually-hidden">
          {`${userName} avatar`}
        </span>
      )}
      <div
        aria-describedby={ariaDescriptionId}
        data-testid={testid}
        className={avatarContainerClasses}
        style={{ minWidth: `${size}`, height: `${size}`, width: `${size}`, ...style }}
        onFocus={(e) => {
          if (showBorder) {
            return handleHover(e, true);
          }
        }}
        onBlur={(e) => {
          if (showBorder) {
            return handleHover(e, false);
          }
        }}
        onMouseOver={(e) => {
          if (showBorder) {
            return handleHover(e, true);
          }
        }}
        onMouseLeave={(e) => {
          if (showBorder) {
            return handleHover(e, false);
          }
        }}
      >
        {renderImage}
        {renderKritikScore()}
        {renderBorder()}
      </div>
      {renderUserName()}
      {renderPopup()}
    </div>
  );
};

const mapStateToProps = (state: any, ownProps: any) => {
  let { user, group } = ownProps;
  if (typeof ownProps.user === 'string') {
    user = getUser(ownProps.user, state.entities);
  }
  if (typeof ownProps.group === 'string') {
    group = selectGroupById(group, state);
  }
  return {
    myUser: state.user.authUser,
    user,
    group,
  };
};

AvatarDisplay.defaultProps = {
  size: '60px',
  kritikScore: 0,
  hoverable: true,
  showName: false,
  groupCard: false,
  showBorder: true,
  anonymous: true,
};

export default connect(mapStateToProps)(AvatarDisplay);
