import { isPresentationActivity } from '@kritik/utils/activity';
import * as StatusUtils from '@kritik/utils/stage';
import { Tooltip } from '@material-ui/core';
import { navigateToActivityPage } from 'actions/activity';
import getPaginatedResults from 'actions/pagination/actions';
import Filter from 'components/CreationList/Menu/Filter';
import CreationListSearch from 'components/CreationList/Menu/Search';
import ViewBy from 'components/CreationList/Menu/ViewBy';
import FileExport from 'images/icons/fa/FileExport';
import { localize } from 'locales';
import { connect } from 'react-redux';
import { setCreationTableFilter, setCurrentPage, setTableListBy } from 'redux/creationTable/actions';
import { selectTableSettings } from 'redux/creationTable/select';
import { getAssignment } from 'selectors/activity';
import { getCurrentActivityStudents } from 'selectors/student';
import { assignmentService } from 'services';
import * as XLSX from 'xlsx-republish';

const Menu = (props: any) => {
  const handleFilterChange = (event: any) => {
    props.setCreationTableFilter(event.target.value);
    props.setCurrentPage(1);
    props.navigateToActivityPage({ courseId: props.courseId, assignmentId: props.activity._id });
    props.getPaginatedResults();
  };

  const renderFilters =
    !isPresentationActivity(props.activity) &&
    (StatusUtils.isInCreationGracePeriod(props.activity) || StatusUtils.isEvaluateOrLater(props.activity));

  const handleExport = async () => {
    try {
      const response = await assignmentService().getDisputesByActivity({
        activityId: props.activity._id,
      });
      if (response.data.disputes.length > 0) {
        const wb = XLSX.utils.book_new();
        wb.Props = {
          Author: 'Kritik',
          CreatedDate: new Date(Date.now()),
        };
        wb.SheetNames.push('Disputes');
        const ws = XLSX.utils.json_to_sheet(response.data.disputes);
        wb.Sheets['Disputes'] = ws;
        XLSX.writeFile(wb, `Disputes.xlsx`);
      }
    } catch (err) {
      props.setError('There was an error, please try again later');
    }
  };

  return (
    <div className="creation-table-menu">
      {props.renderSearch && (
        <div className="creation-table-menu__item">
          <CreationListSearch label={localize({ message: 'Search' })} studentList={props.studentList} />
        </div>
      )}
      {renderFilters && (
        <div className="creation-table-menu__item">
          <Filter
            label={localize({ message: 'MiniMenu.FilterBy' })}
            onFilterChange={handleFilterChange}
            disabled={props.disabled}
            testid="filter-by"
          />
        </div>
      )}
      <div className="creation-table-menu__item"></div>
      <div className="creation-table-menu__item">
        <ViewBy />
      </div>
      {props.tableSettings.type === 'Dispute' && props.itemCount > 0 && (
        <div className="creation-table-menu__item export-button">
          <div className="creation-table-menu__field-label"></div>
          <Tooltip title="Export a csv of the student disputes view" placement="top">
            <FileExport alt="Export" className="export-icon" onClick={handleExport} />
          </Tooltip>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    studentList: getCurrentActivityStudents(state),
    tableSettings: selectTableSettings(state),
    activity: getAssignment(state, state.selected.assignmentId),
    courseId: state.selected.courseId,
  };
};

export default connect(mapStateToProps, {
  setTableListBy,
  navigateToActivityPage,
  getPaginatedResults,
  setCreationTableFilter,
  setCurrentPage,
})(Menu);
