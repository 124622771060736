import {
  isCompleted,
  isCreationNotGraded,
  wasCreationMissed,
  wasCreationOnTime,
  wasCreationSubmittedInGracePeriod,
} from '@kritik/utils/creation/status';
import { CreationScoreUtil } from '@kritik/utils/grade';
import { isCreate, isEvaluate, isFeedback, isFinalized, isGrading, isProcessing1 } from '@kritik/utils/stage';
import * as _ from 'lodash-es';

export const sortByCreate = (itemList: any, ascending: any, activity: any) => {
  /**
   * Create stage: 0/1 < complete
   * Grace period: 0/1 < complete < late
   * Evaluate stage: Missed < complete < late
   * Feedback stage:  Missed < complete < !Late <  Not Graded(Complete but not grade) < Prof Graded
   * Grading stage: same as feedback
   * Finalized stage: Missed < complete < !Late < Prof Graded
   */
  let _itemList = [...itemList];

  if (isCreate({ assignment: activity })) {
    _itemList = sortInCreateStage(itemList);
  } else if (isProcessing1({ assignment: activity })) {
    _itemList = sortInGracePeriod(itemList);
  } else if (isEvaluate({ assignment: activity })) {
    _itemList = sortInEvaluateStage(itemList);
  } else if (isFeedback({ assignment: activity })) {
    _itemList = sortInFeedbackStage(itemList);
  } else if (isGrading({ assignment: activity })) {
    _itemList = sortInGradingStage(itemList);
  } else if (isFinalized({ assignment: activity })) {
    _itemList = sortInFinalizedStage(itemList);
  }
  return ascending ? _itemList.reverse() : _itemList;
};

const getCreation = (item: any) => {
  return item.creation;
};

export const sortInCreateStage = (itemList: any) => {
  return _.sortBy(itemList, [
    // complete
    function (item) {
      const creation = getCreation(item);
      if (!creation) {
        return true;
      }
      return !isCompleted(creation);
    },
  ]);
};

export const sortInGracePeriod = (itemList: any) => {
  return _.sortBy(itemList, [
    // late
    function (item) {
      const creation = getCreation(item);
      if (!item.creation) {
        return true;
      }
      return !wasCreationSubmittedInGracePeriod(creation);
    },
    // complete
    function (item) {
      const creation = getCreation(item);
      if (!creation) {
        return true;
      }
      return !wasCreationOnTime(creation);
    },
  ]);
};

export const sortInEvaluateStage = (itemList: any) => {
  return _.sortBy(itemList, [
    // prof graded
    function (item) {
      const creation = getCreation(item);
      return !CreationScoreUtil.isInstructorGraded(creation);
    },
    // late
    function (item) {
      const creation = getCreation(item);
      return !wasCreationSubmittedInGracePeriod(creation);
    },
    // complete
    function (item) {
      const creation = getCreation(item);
      return !wasCreationOnTime(creation);
    },
  ]);
};

export const sortInFeedbackStage = (itemList: any) => {
  return _.orderBy(
    itemList,
    [
      // prof graded
      function (item) {
        const creation = getCreation(item);
        return isCompleted(creation) && CreationScoreUtil.isInstructorGraded(creation);
      },
      // not graded(but complete)
      function (item) {
        const creation = getCreation(item);
        return isCreationNotGraded(creation);
      },
      // late
      function (item) {
        const creation = getCreation(item);
        return wasCreationSubmittedInGracePeriod(creation);
      },
      // complete
      function (item) {
        const creation = getCreation(item);
        return wasCreationOnTime(creation);
      },
      // missed,
      function (item) {
        const creation = getCreation(item);
        return wasCreationMissed(creation);
      },
    ],
    ['desc', 'desc', 'desc', 'desc', 'desc']
  );
};

export const sortInGradingStage = (itemList: any) => {
  return sortInFeedbackStage(itemList);
};

export const sortInFinalizedStage = (itemList: any) => {
  return _.orderBy(
    itemList,
    [
      // prof graded
      function (item) {
        const creation = getCreation(item);
        return (
          isCompleted(creation) &&
          (CreationScoreUtil.isInstructorGraded(creation) || CreationScoreUtil.isProfRevised(creation))
        );
      },
      // late
      function (item) {
        const creation = getCreation(item);
        return wasCreationSubmittedInGracePeriod(creation);
      },
      // complete
      function (item) {
        const creation = getCreation(item);
        return wasCreationOnTime(creation);
      },
      // missed
      function (item) {
        const creation = getCreation(item);
        return wasCreationMissed(creation);
      },
    ],
    ['desc', 'desc', 'desc', 'desc']
  );
};
