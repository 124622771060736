import Header from 'components/Course/MarkingScheme/Header';
import Node from 'components/Course/MarkingScheme/Node';
import Separator from 'components/Course/MarkingScheme/Separator';
import React from 'react';

type categoryObject = {
  title?: string;
  label?: string;
  name?: string;
  description: string;
  value: number;
  popover?: {
    description: string;
    title: string;
    url: string;
  };
  onChange?: (e: React.ChangeEvent) => void;
  testid?: string;
};

type subCategoryListItems = categoryObject & {
  weight: number;
};

type markingSchemeProps = {
  category: categoryObject;
  subCategoryList: Array<subCategoryListItems>;
  label?: string;
  name?: string;
  description: string;
  color: string;
  title: string;
  icon: React.ReactNode;
  isEdit: boolean;
  isPercentage: boolean;
};

const MarkingScheme = (props: markingSchemeProps) => {
  const renderSubCategoryList = () => {
    return props.subCategoryList.map((category: categoryObject, index: number) => {
      return (
        <Separator key={index} hasNext={index < props.subCategoryList.length - 1}>
          <Node
            {...category}
            isEdit={props.isEdit}
            isPercentage={props.isPercentage}
            label={props.subCategoryList[index].label}
            title={props.subCategoryList[index].title}
            forceInputPercentage
          />
        </Separator>
      );
    });
  };

  return (
    <div className="marking-scheme">
      <Header title={props.title} icon={props.icon} description={props.description} color={props.color} />
      <Node
        {...props.category}
        title={props.title}
        isEdit={props.isEdit}
        isPercentage={props.isPercentage}
        label={props.label}
      />
      {renderSubCategoryList()}
    </div>
  );
};

MarkingScheme.defaultProps = {
  subCategoryList: [],
  isEdit: false,
  isPercentage: false,
};

export default MarkingScheme;
