import { ALL_STUDENTS, EXCLUDE_CREATORS, SPECIFIC_GROUPS, SPECIFIC_STUDENTS } from '@kritik/constants/activity';
import { Activity, Group, Student } from '@kritik/types.generated';
import { useQueryClient } from '@tanstack/react-query';
import { GroupSet } from 'app-types';
import { CollapsibleStep } from 'components/CollapsibleStep';
import { Button, ButtonContainer } from 'components/buttons';
import { InlineInformation } from 'components/layout';
import { useAuthUserFromRedux } from 'hooks/user';
import { localize } from 'locales';
import { Form } from 'react-final-form';
import ActivityService from 'services/activity';
import * as ErrorUtils from 'utils/error';
import { trackEvent } from 'utils/userEvents';
import { withRouter } from 'utils/withRouter';
import EvaluatorField from './Fields/EvaluatorField';
import GroupField from './Fields/GroupField';
import { GroupEvaluatorsField } from './Fields/GroupPresentation/GroupEvaluatorsField';
import StudentEvaluatorsField from './Fields/GroupPresentation/StudentEvaluatorsField';
import GroupSetField from './Fields/GroupSetField';

type FormValues = {
  groupSetId: string;
  creatorGroups: (Group['_id'] | Group)[];
  creatorStudents: (Student | Student['_id'])[];
  evaluatorStudents: (Student | Student['_id'])[];
  evaluatorType: string;
  allGroupsSelected?: boolean;
  groupSetMembersIds: string[];
  activityGroupSet: GroupSet;
  groups: Group[];
  evaluatorGroups: (Group | Group['_id'])[];
};

export const GroupPresentationActivity = withRouter(
  ({
    router,
    activity,
    activityGroupSet,
    students,
  }: {
    router: any;
    activity: Activity;
    activityGroupSet: GroupSet;
    students: Student[];
  }) => {
    const { assignmentId, courseId } = router.params;
    const authUser = useAuthUserFromRedux();
    const queryClient = useQueryClient();

    const getInitialFormValues = (): FormValues => {
      if (activity && activityGroupSet) {
        return {
          groupSetId: activityGroupSet._id,
          creatorGroups: activity.participationSettings.creatorGroups,
          creatorStudents: activity.participationSettings.creatorStudents,
          evaluatorStudents: activity.participationSettings.evaluatorStudents,
          evaluatorType: activity.participationSettings.evaluatorType,
          // This field is only used to exclude some options for evaluators radio buttons
          allGroupsSelected:
            activityGroupSet.groups.filter((group) => group.members.length > 0).length ===
            activity.participationSettings?.creatorGroups?.length,
          groupSetMembersIds: activityGroupSet.groups.flatMap((group) => group.members) as string[],
          activityGroupSet,
          groups: activityGroupSet.groups,
          evaluatorGroups: activity.participationSettings.evaluatorGroups,
        };
      }
      return {
        groupSetId: null,
        creatorGroups: [],
        creatorStudents: [],
        evaluatorStudents: [],
        evaluatorType: EXCLUDE_CREATORS,
        // This field is only used to exclude some options for evaluators radio buttons
        allGroupsSelected: false,
        groupSetMembersIds: [],
        activityGroupSet: null,
        groups: [],
        evaluatorGroups: [],
      };
    };

    const handleOnSubmit = async (values: FormValues) => {
      const { groupSetId, creatorGroups, evaluatorType, evaluatorStudents, activityGroupSet, groups, evaluatorGroups } =
        values;
      if (!groupSetId) {
        return { error: 'Activity.EditStudentParticipation.Form.Error.GroupSet' };
      }
      if (creatorGroups?.length === 0) {
        return {
          error: localize({ message: 'Activity.EditStudentParticipation.Form.Error.Groups' }),
        };
      }
      if (creatorGroups.length === 1 && evaluatorType === SPECIFIC_STUDENTS) {
        // check if evaluator student is part of creator group
        const creatorGroup = groups.find((group) => group._id === creatorGroups[0]);
        if (evaluatorStudents.some((student) => creatorGroup.members.includes(student))) {
          return {
            error: localize({
              message: 'Activity.EditStudentParticipation.Form.Error.EvaluatorPartOfCreatorGroup',
            }),
          };
        }
      }
      if (
        evaluatorType !== ALL_STUDENTS &&
        evaluatorType !== EXCLUDE_CREATORS &&
        evaluatorType !== SPECIFIC_STUDENTS &&
        evaluatorType !== SPECIFIC_GROUPS
      ) {
        return { error: 'Activity.EditStudentParticipation.Form.Error.Evaluators' };
      }

      if (evaluatorType === SPECIFIC_STUDENTS && evaluatorStudents.length === 0) {
        return {
          error: localize({ message: 'Activity.EditStudentParticipation.Form.Error.Evaluators' }),
        };
      }

      if (evaluatorType === SPECIFIC_GROUPS && evaluatorGroups.length === 0) {
        return {
          error: localize({ message: 'Activity.EditStudentParticipation.Form.Error.Evaluators' }),
        };
      }

      try {
        await ActivityService().editStudentParticipation({
          activityId: activity._id,
          data: {
            groupSetId,
            creatorGroups,
            evaluatorType,
            evaluatorStudents: evaluatorType === SPECIFIC_STUDENTS ? evaluatorStudents : [],
            evaluatorGroups: evaluatorType === SPECIFIC_GROUPS ? evaluatorGroups : [],
          },
        });
        queryClient.invalidateQueries(['activity', activity._id]);
        trackEvent('Student Participation Page Saved', authUser, {
          activityId: assignmentId,
          instructorEmail: authUser.email,
        });
        router.push(`/course/${courseId}/assignment/${assignmentId}`);
      } catch (err) {
        return { error: ErrorUtils.getErrorMessageFromResponse(err) };
      }
    };

    if (!activity) {
      return null;
    }

    return (
      <Form
        initialValues={getInitialFormValues()}
        onSubmit={handleOnSubmit}
        render={({ handleSubmit, values, submitErrors, submitting }) => {
          const { evaluatorType } = values;
          const disableNextSteps = !Boolean(values.groupSetId);
          const isCreatorGroupsSelected = values.creatorGroups?.length > 0;
          return (
            <form onSubmit={handleSubmit}>
              <CollapsibleStep
                stepIndex={1}
                title={localize({ message: 'Activity.EditStudentParticipation.Group.Step1' })}
              >
                <GroupSetField />
              </CollapsibleStep>
              <CollapsibleStep
                stepIndex={2}
                title={localize({ message: 'Activity.EditStudentParticipation.Group.Step2' })}
                disabled={disableNextSteps}
              >
                <GroupField />
              </CollapsibleStep>
              <CollapsibleStep
                stepIndex={3}
                title={localize({ message: 'Activity.EditStudentParticipation.Group.Step3' })}
                disabled={!isCreatorGroupsSelected || disableNextSteps}
              >
                <EvaluatorField />
                {evaluatorType === 'specificStudents' && <StudentEvaluatorsField students={students} />}
                {evaluatorType === 'specificGroups' && <GroupEvaluatorsField />}
              </CollapsibleStep>
              <CollapsibleStep
                stepIndex={4}
                title={localize({ message: 'Activity.EditStudentParticipation.Group.Step4' })}
                disabled={!isCreatorGroupsSelected || disableNextSteps}
              >
                <InlineInformation
                  title={localize({
                    message: 'Activity.EditStudentParticipation.Group.FeedbackStage.Information.Title',
                  })}
                >
                  {localize({
                    message: 'Activity.EditStudentParticipation.Group.FeedbackStage.Information.Content',
                  })}
                </InlineInformation>
                <div className="participation-feedback-stage-inline">
                  <span>
                    {localize({
                      message:
                        values.evaluatorType === ALL_STUDENTS
                          ? 'Activity.Presentation.Overview.StudentParticipation.Table.AllStudents'
                          : values.evaluatorType === SPECIFIC_STUDENTS
                            ? 'Activity.Presentation.Overview.StudentParticipation.Table.SpecificStudents'
                            : 'Activity.Presentation.Overview.StudentParticipation.Table.ExcludeCreators',
                    })}
                  </span>
                  {localize({
                    message: 'Activity.EditStudentParticipation.Group.FeedbackStage.Participation',
                  })}
                </div>
              </CollapsibleStep>
              {submitErrors?.error && (
                <InlineInformation type="danger" className="submit-participation-error">
                  {localize({
                    message: submitErrors.error,
                  })}
                </InlineInformation>
              )}
              <ButtonContainer className="participation-buttons">
                <Button
                  type="primary"
                  inputType="submit"
                  loading={submitting}
                  testid="save-participation-settings"
                  label={localize({ message: 'Button.Label.SaveStudentParticipation' })}
                >
                  {localize({
                    message: 'Activity.EditStudentParticipation.Group.Form.Save',
                  })}
                </Button>
                <Button
                  type="secondary"
                  onClick={() => {
                    router.push(`/course/${courseId}/assignment/${assignmentId}`);
                  }}
                >
                  {localize({
                    message: 'Activity.EditStudentParticipation.Group.Form.Cancel',
                  })}
                </Button>
              </ButtonContainer>
            </form>
          );
        }}
      />
    );
  }
);
