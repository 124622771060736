import { isCalibrationActivity, isGroupAssignment, isGroupPresentationActivity } from '@kritik/utils/activity';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { navigateToActivityPage, navigateToActivityPageScoreComparison } from 'actions/activity';
import { resetSubmission } from 'actions/select';
import CreationListMiniMenu from 'components/CreationList/Menu/MiniMenu';
import miniTableConstants from 'components/CreationList/MiniTable/constants';
import progressTableConstants from 'components/CreationList/ProgressTable/constants';
import { FilterItemList } from 'components/CreationList/ProgressTable/utils';
import CreationTable from 'components/CreationList/Table';
import { FILTERS, LIST_BY_TYPES, TABLE_TYPE_OPTIONS } from 'components/CreationList/constant';
import CreationTableUtils, { isListByGroup, isProgressView, isScoreComparisonView } from 'components/CreationList/util';
import LoaderOverlay from 'components/Loaders/OverlaySpinner';
import { TranslatedText } from 'components/TranslatedText';
import Button from 'components/buttons/Button';
import {
  getStudentIdsFromTableItemList,
  injectLateEvaluationsToTableItemList,
} from 'containers/assignments/creations/utils';
import { useGetStudentsLateEvaluationsInActivity } from 'hooks/evaluation';
import { localize } from 'locales';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  setColumns,
  setCreationTableActivity,
  setCreationTableFilter,
  setCreationTableSearch,
  setCurrentPage,
  setInitialState,
  setTableListBy,
} from 'redux/creationTable/actions';
import {
  selectCreationTableFilter,
  selectCreationTableListBy,
  selectCreationTableViewType,
  selectIsCreationTableLoading,
  selectSortedColumn,
  selectTableSettings,
} from 'redux/creationTable/select';
import ActivitySelectors from 'selectors/activity';
import { getCourse } from 'selectors/course';
import { default as creationSelector, default as submissionSelector } from 'selectors/creation';
import GroupSelectors from 'selectors/group';
import studentSelector, { getStudent } from 'selectors/student';
import { withRouter } from 'utils/withRouter';
import SubmissionRow from './CreationRow';
import { sortItemList } from './utils';

type SubmissionListState = any;

class SubmissionList extends Component<{}, SubmissionListState> {
  naviModalRef: any;
  constructor(props: {}) {
    super(props);
    this.state = {
      dropdownText: 'Submission Date',
      sortAscending: null,
      loading: (this.props as any).loadingBar.default !== 0,
      naviModalOpen: false,
    };
    this.naviModalRef = React.createRef();
  }

  componentDidMount() {
    if ((this.props as any).tableSettings.activityId !== (this.props as any).assignment._id) {
      const tableType = TABLE_TYPE_OPTIONS.PROGRESS;
      let columns = progressTableConstants.STUDENT_COLUMNS.PEER;
      let listBy = LIST_BY_TYPES.STUDENT;
      if (isCalibrationActivity((this.props as any).assignment)) {
        // @ts-expect-error TS(2322) FIXME: Type '({ header: string; align: string; isSortable... Remove this comment to see the full error message
        columns = progressTableConstants.STUDENT_COLUMNS.CALIBRATION;
      } else if (
        isGroupAssignment((this.props as any).assignment) ||
        isGroupPresentationActivity((this.props as any).assignment)
      ) {
        columns = progressTableConstants.GROUP_COLUMNS.GROUP;
        listBy = LIST_BY_TYPES.GROUP;
      }
      (this.props as any).setInitialState({ columns, type: tableType });
      (this.props as any).setTableListBy(listBy);
      (this.props as any).setCreationTableActivity((this.props as any).assignment._id);
    }
  }

  componentDidUpdate(prevProps: {}, prevState: SubmissionListState) {
    if (prevState.loading === true && (this.props as any).loadingBar.default === 0) {
      this.setState({ loading: false });
    } else if (
      !(this.props as any).dashboardView &&
      prevState.loading === false &&
      (this.props as any).loadingBar.default !== 0
    ) {
      this.setState({ loading: true });
    }

    if ((prevProps as any).creationTableListBy !== (this.props as any).creationTableListBy) {
      if (
        (isGroupAssignment((this.props as any).assignment) ||
          isGroupPresentationActivity((this.props as any).assignment)) &&
        isProgressView((this.props as any).viewType)
      ) {
        const columns = this.generateTableColums((this.props as any).creationTableListBy);
        (this.props as any).setColumns(columns);
      }
    }
    if ((prevProps as any).itemList.length > 0 && (this.props as any).itemList.length > 0) {
      const { creation: prevCreation } = (prevProps as any).itemList[0];
      const { creation: currCreation, student: currStudent } = (this.props as any).itemList[0];
      if (currCreation._id !== prevCreation._id) {
        const _isGroupItem = CreationTableUtils.isGroupItem(
          (this.props as any).itemList[0],
          (this.props as any).creationTableListBy
        );
        (this.props as any).onSelect(currCreation._id, currStudent._id, _isGroupItem);
      }
    }
  }

  generateTableColums = (listBy: any) => {
    if (isListByGroup(listBy)) {
      return progressTableConstants.GROUP_COLUMNS.GROUP;
    }
    return progressTableConstants.STUDENT_COLUMNS.GROUP;
  };

  handleSubmissionSelect = (submissionId: any, studentId: any, isGroupItem: any) => {
    (this.props as any).onSelect(submissionId, studentId, isGroupItem);
  };

  getKritikScore(submission: any) {
    // @ts-expect-error TS(2304) FIXME: Cannot find name 'getNewKS'.
    return getNewKS(getStudent(submission.student._id, (this.props as any).entities));
  }

  toggleNaviMenu = () => {
    this.setState({ naviModalOpen: !this.state.naviModalOpen });
  };

  closeNaviMenu = (e: any) => {
    if (e.target.id === 'creation_list_menu_toggle') {
      return;
    }
    this.setState({ naviModalOpen: false });
  };

  handleBackButtonClick = () => {
    const { type } = (this.props as any).tableSettings;
    (this.props as any).resetSubmission();
    (this.props as any).setCurrentPage(1);
    (this.props as any).setCreationTableFilter(FILTERS.SHOW_ALL);
    (this.props as any).setCreationTableSearch('');

    if (isScoreComparisonView(type)) {
      (this.props as any).navigateToActivityPageScoreComparison({
        courseId: (this.props as any).course._id,
        assignmentId: (this.props as any).assignment._id,
      });
    } else {
      (this.props as any).navigateToActivityPage({
        courseId: (this.props as any).course._id,
        assignmentId: (this.props as any).assignment._id,
      });
    }
  };

  renderNaviMenu() {
    const { listBy } = (this.props as any).tableSettings;
    const header = isListByGroup(listBy) ? localize({ message: 'Groups' }) : localize({ message: 'Students' });

    return (
      <div className="submission-navi-menu-header">
        <div className="submission-navi-menu-left">
          <Button
            type="secondary"
            onClick={this.handleBackButtonClick}
            className="return-to-assignment"
            label={localize({ message: 'Button.Label.Back' })}
          >
            <ArrowBackIcon fontSize="small" style={{ marginBottom: '-5px' }} />
          </Button>
          <p className="header-5">{header}</p>
        </div>
        <span id="creation_list_menu_toggle" className="submission-navi-menu-right" onClick={this.toggleNaviMenu}>
          <TranslatedText i18nKey="Creations.Menu.SortAndFilter" />
        </span>
      </div>
    );
  }

  renderListByMenu() {
    if (
      !(
        isGroupAssignment((this.props as any).assignment) || isGroupPresentationActivity((this.props as any).assignment)
      )
    ) {
      return null;
    }
    const radioOptions = {
      values: Object.keys(LIST_BY_TYPES),
      labels: Object.values(LIST_BY_TYPES),
    };
    return (
      <React.Fragment>
        <fieldset className="fieldset-hidden">
          <legend className="legend-hidden">{localize({ message: 'Legend.Text.CreationList' })}</legend>
          {radioOptions.values.map((el, idx) => {
            return (
              <div>
                <FormControl key={el} component="fieldset">
                  <FormControlLabel
                    label={`${radioOptions.labels[idx]}`}
                    control={
                      <Radio
                        checked={(this.props as any).tableSettings.listBy === radioOptions.labels[idx]}
                        onChange={() => {
                          return (this.props as any).setTableListBy(radioOptions.labels[idx]);
                        }}
                        value={el}
                        color="primary"
                        name={radioOptions.labels[idx]}
                        className="listby-radio"
                      />
                    }
                  />
                </FormControl>
              </div>
            );
          })}
        </fieldset>
      </React.Fragment>
    );
  }

  renderNaviModal() {
    if (!this.state.naviModalOpen) {
      return null;
    }
    return (
      <div className="creation-list-mini__menu">
        <CreationListMiniMenu onClose={this.closeNaviMenu} />
      </div>
    );
  }

  getSortedItemList() {
    // @ts-expect-error TS(2339) FIXME: Property 'itemList' does not exist on type 'Readon... Remove this comment to see the full error message
    const { itemList, assignment, sortedColumn, viewType, course, tableSettings } = this.props;
    const _itemList = new FilterItemList(itemList, (this.props as any).filter);
    const sortedItemList = sortItemList({
      itemList: _itemList,
      sortedColumn,
      viewType,
      activity: assignment,
      course,
      tableSettings,
    });
    return injectLateEvaluationsToTableItemList({
      itemList: sortedItemList,
      lateEvaluationsMap: (this.props as any).lateEvaluationsMap,
    });
  }

  renderSubmissionRows() {
    if ((this.props as any).itemList.length > 0) {
      return (
        <CreationTable
          displayHeader={false}
          rowGenerator={SubmissionRow}
          itemData={{
            handleSubmissionSelect: this.handleSubmissionSelect,
          }}
          rowList={this.getSortedItemList()}
          tableColumns={miniTableConstants.COLUMNS}
          maxRows={8}
        />
      );
    }
    return (
      <span>
        <TranslatedText i18nKey="List.NoSubmissions" />
      </span>
    );
  }

  renderListContent() {
    if (this.state.loading || (this.props as any).isLoadingPaginationData) {
      return (
        <LoaderOverlay
          isOpen={this.state.loading || (this.props as any).isLoadingPaginationData}
          label={localize({ message: 'List.LoadingCreations' })}
        />
      );
    }
    return this.renderSubmissionRows();
  }

  render() {
    if (!(this.props as any).itemList) {
      return null;
    }

    return (
      <div className="submission-list-container">
        {this.renderNaviMenu()}
        {this.renderNaviModal()}
        <div className="submission-list">{this.renderListContent()}</div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  const itemList = CreationTableUtils.generateRowListData()(
    {
      CreationSelectors: creationSelector,
      SubmissionSelectors: submissionSelector,
      GroupSelectors,
      StudentSelectors: studentSelector,
      ActivitySelectors,
    },
    state
  );
  return {
    loadingBar: state.loadingBar,
    user: state.user,
    entities: state.entities,
    itemList,
    submissionId: state.selected.submissionId,
    assignment: ActivitySelectors.getAssignment(state, state.selected.assignmentId),
    tableSettings: selectTableSettings(state),
    sortedColumn: selectSortedColumn(state),
    viewType: selectCreationTableViewType(state),
    creationTableListBy: selectCreationTableListBy(state),
    course: getCourse(state),
    filter: selectCreationTableFilter(state),
    isLoadingPaginationData: selectIsCreationTableLoading(state),
  };
};

const SubmissionListWrapper = (props) => {
  const studentIds = getStudentIdsFromTableItemList(props.itemList);

  const { data: lateEvaluationsMap, isLoading: isLoadingLateEvaluations } = useGetStudentsLateEvaluationsInActivity({
    studentIds,
    activityId: props.assignment.id,
  });

  if (isLoadingLateEvaluations) {
    return null;
  }

  return <SubmissionList {...props} lateEvaluationsMap={lateEvaluationsMap} />;
};

export default withRouter(
  connect(mapStateToProps, {
    navigateToActivityPage,
    navigateToActivityPageScoreComparison,
    resetSubmission,
    setColumns,
    setTableListBy,
    setInitialState,
    setCreationTableActivity,
    setCurrentPage,
    setCreationTableSearch,
    setCreationTableFilter,
  })(SubmissionListWrapper)
);
