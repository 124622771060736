import DefaultPrice from 'containers/Admin/Institution/DefaultCoursePrice';
import Page from 'pages/Page';

function DefaultCoursePrice() {
  return (
    <Page title="Change Default Course Price for Institution" meta={[]} link={[]} script={[]}>
      <DefaultPrice />
    </Page>
  );
}

export default DefaultCoursePrice;
