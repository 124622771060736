import Tooltip from '@material-ui/core/Tooltip';
import classNames from 'classnames';

const STATES = {
  WARNING: 'warning',
  RESOLVED: 'resolved',
  CAUTION: 'caution',
};
const Cell = (props: any) => {
  const classes = classNames('creation-table-issue', {
    'creation-table-issue--warning': props.status === STATES.WARNING,
    'creation-table-issue--resolved': props.status === STATES.RESOLVED,
    'creation-table-issue--caution': props.status === STATES.CAUTION,
  });

  return (
    <Tooltip title={props.label} placement="top">
      <div className={classes} data-testid={props.testid}>
        {props.icon}
      </div>
    </Tooltip>
  );
};

export default Cell;
