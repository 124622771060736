import { Activity, Creation, CreationScore, Student } from '@kritik/types.generated';
import * as assignmentUtil from '@kritik/utils/activity';
import * as evaluationUtil from '@kritik/utils/creation/evaluation';
import * as creationStatusUtil from '@kritik/utils/creation/status';

export class FeedbackScoreUtil {
  static isFOFCompleted(evaluation: CreationScore) {
    const { motivational, critical } = evaluation.feedbackOnFeedback;
    return motivational !== 0 && critical !== 0;
  }

  static numFOFCompleted(
    creation: Creation,
    activity?: Activity,
    studentId: string = null
  ): { toDo: number; completedFOF: number } {
    const allEvaluations =
      activity && assignmentUtil.isSelfEvaluate(activity)
        ? evaluationUtil.filterOutSelfEvaluation(creation)
        : creation.scores;
    // for now we are not assigning feedbacks to an accepted late evaluation
    const evaluations = allEvaluations.filter((e) => !e.lateEvaluation);
    if (studentId) {
      return evaluations.reduce(
        (acc, evaluation) => {
          const { student } = evaluation.feedbackOnFeedback;
          if (student && student.toString() === studentId.toString()) {
            acc.toDo += 1;
            if (this.isFOFCompleted(evaluation)) {
              acc.completedFOF += 1;
            }
            return acc;
          }
          return acc;
        },
        { toDo: 0, completedFOF: 0 }
      );
    }
    return evaluations.reduce(
      (acc, evaluation) => {
        acc.toDo += 1;
        if (this.isFOFCompleted(evaluation)) {
          acc.completedFOF += 1;
        }
        return acc;
      },
      { toDo: 0, completedFOF: 0 }
    );
  }

  static numFOFCompletedForPresentationActivity({
    creations = [],
    studentId = null,
  }: {
    creations: Creation[];
    studentId?: string;
  }): { toDo: number; completedFOF: number } {
    const evaluations = creations
      .map((creation) => {
        return creation.scores;
      })
      .flat();

    if (studentId) {
      return evaluations.reduce(
        (acc, evaluation) => {
          const { student } = evaluation.feedbackOnFeedback;
          if (student && student.toString() === studentId.toString()) {
            acc.toDo += 1;
            if (this.isFOFCompleted(evaluation)) {
              acc.completedFOF += 1;
            }
            return acc;
          }
          return acc;
        },
        { toDo: 0, completedFOF: 0 }
      );
    }
    return evaluations.reduce(
      (acc, evaluation) => {
        acc.toDo += 1;
        if (this.isFOFCompleted(evaluation)) {
          acc.completedFOF += 1;
        }
        return acc;
      },
      { toDo: 0, completedFOF: 0 }
    );
  }

  static getPercentFOFCompleted(creation: Creation, activity: Activity, studentId?: string, creations?: Creation[]) {
    if (creationStatusUtil.wasCreationMissed(creation) && !assignmentUtil.isPresentationActivity(activity)) {
      return 0;
    }
    const { completedFOF, toDo } = assignmentUtil.isPresentationActivity(activity)
      ? this.numFOFCompletedForPresentationActivity({ creations, studentId })
      : this.numFOFCompleted(creation, activity, studentId);
    let progress = 100;
    if (toDo > 0) {
      progress = (completedFOF / toDo) * 100;
    }
    return progress;
  }

  static calcScore({
    creation,
    activity,
    studentId = null,
    creations = [],
  }: {
    creation: Creation;
    activity: Activity;
    studentId?: string;
    creations?: Creation[];
  }) {
    if (!creation) {
      return 0;
    }
    if (this.isFeedbackScoredByTeacher(creation)) {
      return creation.teacherFeedbackScore;
    }
    return this.getPercentFOFCompleted(creation, activity, studentId, creations);
  }

  static isFeedbackScoredByTeacher(creation: Creation) {
    if (creation.teacherFeedbackScore || creation.teacherFeedbackScore === 0) {
      return true;
    }
    return false;
  }

  static getNumFOFReceived(creationsToEvaluate: Creation[], studentId: string) {
    let numFofReceived = 0;
    creationsToEvaluate.forEach((creation) => {
      if (creation.scores) {
        creation.scores.forEach((evaluation) => {
          const evaluationStudentId = (evaluation.student as Student)._id || evaluation.student;
          if (evaluationStudentId.toString() === studentId.toString() && this.isFOFCompleted(evaluation)) {
            numFofReceived += 1;
          }
        });
      }
    });
    return numFofReceived;
  }

  static getNumFOFReceivedForStudentFromMap(studentFeedbackReceivedMap: StudentFeedbackReceivedMap, studentId: string) {
    if (!studentFeedbackReceivedMap) {
      return 0;
    }
    return studentFeedbackReceivedMap[studentId.toString()] || 0;
  }

  static getNumFOFToReceiveForCreation(creation: Creation, activity: Activity = null, studentId?: string) {
    if (!creation) {
      return 0;
    }

    let FOFToReceiveCount = evaluationUtil.getNumEvaluationsCompleted(creation, studentId);

    if (activity && assignmentUtil.isWithinGroupActivity(activity)) {
      const hasSelfEval = creation.scores.length !== evaluationUtil.filterOutSelfEvaluation(creation).length;
      if (hasSelfEval) {
        FOFToReceiveCount -= 1;
      }
    }
    return FOFToReceiveCount;
  }

  static getPercentageFOFReceivedForStudentFromMap(
    studentFeedbackReceivedMap: StudentFeedbackReceivedMap,
    creation: Creation
  ) {
    if (!creation || !creation.student) {
      return 0;
    }
    const studentId: string = (creation.student as Student)._id || (creation.student as string);

    const expected = this.getNumFOFToReceiveForCreation(creation);
    const actual = this.getNumFOFReceivedForStudentFromMap(studentFeedbackReceivedMap, studentId);
    let progress = 0;
    if (expected > 0) {
      progress = (actual / expected) * 100;
    }
    return progress;
  }

  /**
   * @param {*} max - max feedback skills change
   * @param {*} value - motivational or critical score, ex 1,2,3,4
   * @returns {number} - minimun to be 50% of the skills change per category
   */

  static convertFeedbackToPoints(max: number, value: number) {
    const min = max / 2;
    const step = min / 3;
    let score: number;
    switch (value) {
      case 1:
        score = 0;
        break;
      case 2:
        score = step;
        break;
      case 3:
        score = min - step;
        break;
      default:
        score = min;
    }
    return score + min;
  }

  static getFOFChange(score: CreationScore, maxFeedbackScoreChangePerCategory: number) {
    const feedback = score.feedbackOnFeedback;
    let criticalChange = maxFeedbackScoreChangePerCategory;
    let motivationalChange = maxFeedbackScoreChangePerCategory;

    if (feedback.critical && feedback.motivational) {
      criticalChange = FeedbackScoreUtil.convertFeedbackToPoints(maxFeedbackScoreChangePerCategory, feedback.critical);
      motivationalChange = FeedbackScoreUtil.convertFeedbackToPoints(
        maxFeedbackScoreChangePerCategory,
        feedback.motivational
      );
    }
    score.feedbackSkillsChange.critical = criticalChange;
    score.feedbackSkillsChange.motivational = motivationalChange;

    return { criticalChange, motivationalChange };
  }

  static getNumFOFToReceiveForPresentationActivity({
    creations,
    studentId,
  }: {
    creations: Creation[];
    studentId: string;
  }) {
    let numFOFToReceiveForPresentationActivity = 0;
    creations.forEach((creation) => {
      creation.scores.forEach((evaluation) => {
        if (
          ((evaluation.student as Student)._id || (evaluation.student as string)) === studentId &&
          evaluation.feedbackOnFeedback.student
        ) {
          numFOFToReceiveForPresentationActivity += 1;
        }
      });
    });
    return numFOFToReceiveForPresentationActivity;
  }

  static getNumFOFReceivedForPresentationActivity({
    creations,
    studentId,
  }: {
    creations: Creation[];
    studentId: string;
  }) {
    let numFOFReceivedForPresentationActivity = 0;
    creations.forEach((creation) => {
      creation.scores.forEach((evaluation) => {
        if (
          ((evaluation.student as Student)._id || (evaluation.student as string)) === studentId &&
          evaluation.feedbackOnFeedback.student &&
          this.isFOFCompleted(evaluation)
        ) {
          numFOFReceivedForPresentationActivity += 1;
        }
      });
    });
    return numFOFReceivedForPresentationActivity;
  }
}
