const Icon = (props: any) => {
  const width = props.width ? props.width : 23;
  const height = props.height ? props.height : 23;
  return (
    <svg
      width={width}
      height={height}
      // @ts-expect-error TS(2322) FIXME: Type '{ children: Element[]; width: any; height: a... Remove this comment to see the full error message
      class={props.class}
      viewBox="0 0 23 23"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.4519 5.01281C15.4727 2.6483 13.5728 0.714622 11.2083 0.693808C8.84375 0.672994 6.91006 2.57293 6.88925 4.93743C6.86844 7.30194 8.76837 9.23562 11.1329 9.25644C13.4974 9.27725 15.4311 7.37732 15.4519 5.01281Z"
        fill="currentColor"
      />
      <path
        d="M21.736 2.74581C20.7584 2.4424 19.7133 2.98178 19.4099 3.9257L18.4323 6.99341C17.9266 8.51041 16.7804 9.72401 15.2634 10.2634C14.5555 10.5331 13.8139 10.6342 13.0722 10.6342H8.89204C7.13906 10.6342 5.52093 11.5444 4.57701 13.0277L0.363121 19.7699C-0.176258 20.6464 0.0934318 21.7589 0.93621 22.2983C1.23961 22.5005 1.57672 22.568 1.91383 22.568C2.52063 22.568 3.12743 22.2646 3.49826 21.6915L6.73453 16.5337V21.7589C6.73453 22.3994 7.24019 22.9051 7.88071 22.9051H14.4544C15.0949 22.9051 15.6006 22.3994 15.6006 21.7589V14.0053C15.904 13.9379 16.2074 13.8705 16.5108 13.7693C19.1065 12.8254 21.0618 10.7691 21.9045 8.1733L22.8822 5.10558C23.253 4.09425 22.7136 3.04921 21.736 2.74581Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Icon;
