import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { DisplayBoxDivider } from 'components/layout';
import { I18nKey, localize } from 'locales';
import React, { MouseEvent, useState } from 'react';

const useStylesButton = makeStyles({
  root: {
    backgroundColor: 'transparent',
    padding: '4px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    height: '100%',
    width: '100%',
  },
});

const useStylesIcon = makeStyles({
  root: {
    '&:hover': {
      color: '#8E24AA',
    },
  },
});

type Option = {
  label: JSX.Element | string;
  testid?: string;
  action?: () => void;
};
type ElipsesMenuProps = {
  id?: string;
  testid?: string;
  options: Option[];
  className?: string;
  labelI18nKey: I18nKey;
  labelI18nOptions?: any;
};

const ElipsesMenu = (props: ElipsesMenuProps) => {
  const classesButton = useStylesButton();
  const classesIcon = useStylesIcon();
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopup = () => {
    setLoading(false);
    setAnchorEl(null);
  };

  const handleOptionClick = async (option: Option) => {
    setLoading(true);
    await option.action();
    handleClosePopup();
  };

  const renderOptions = (onClick: (option: any) => void) => {
    return props.options.map((option) => {
      return (
        <MenuItem
          key={option.testid}
          data-testid={option.testid}
          onClick={(e) => {
            e.stopPropagation();
            onClick(option);
          }}
          tabIndex={option.label === 'separator' ? -1 : 0}
          disabled={option.label === 'separator' || loading}
          className={props.className}
        >
          {option.label === 'separator' ? (
            <DisplayBoxDivider className="course-card__menu-separator" tabIndex={-1} />
          ) : (
            option.label
          )}
        </MenuItem>
      );
    });
  };

  const renderPopupMenu = () => {
    return (
      <Menu
        id={props.id}
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        onClose={handleClosePopup}
        onClick={handleClosePopup}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {renderOptions(handleOptionClick)}
      </Menu>
    );
  };

  return (
    <React.Fragment>
      <IconButton
        aria-label={localize({
          message: props.labelI18nKey,
          options: props.labelI18nOptions,
        })}
        data-testid={props.testid}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className={classesButton.root}
      >
        <MoreVertIcon className={classesIcon.root} />
      </IconButton>
      {renderPopupMenu()}
    </React.Fragment>
  );
};

export default ElipsesMenu;
