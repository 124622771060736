import { assignmentStatuses } from '@kritik/constants/stage';
import { isGroupAssignment, isWithinGroupActivity } from '@kritik/utils/activity';
import EvaluationGracePeriod from 'components/Assignment/scheduler/EvaluationGracePeriod';
import ScheduleDateInput from 'components/schedule/DateInput';
import DateWrapper from 'containers/Schedule/DateWrapper';
import { localize } from 'locales';
import { connect } from 'react-redux';
import { selectSchedule } from 'redux/schedule/selectors';
import { selectCurrentActivity } from 'selectors/activity';

function EvaluateSchedule({
  minDate,
  evaluationEndDate,
  activeStatus,
  activity,
  handleChange,
  error,
  onCustomTimeChange,
  schedule,
  attention,
  toggleGracePeriod,
  handleIntervalChange,
}: any) {
  const handleDateChange = (date: any) => {
    handleChange(date);
  };
  const getTitle = () => {
    if (isWithinGroupActivity(activity)) {
      return localize({ message: 'Activity.Schedule.Evaluate.WithinGroup' });
    }
    return localize({ message: 'Activity.Schedule.Evaluate' });
  };

  const getDescription = () => {
    if (isWithinGroupActivity(activity)) {
      return (
        <span>During the Evaluate stage students will evaluate their group members' contributions to their group.</span>
      );
    }
    return (
      <span>
        During the Evaluate stage students are assigned creations to evaluate. Final grades will be determined by a
        weighted average of these peer evaluations (or the grade assigned by you).
      </span>
    );
  };

  const getWarning = () => {
    if (isGroupAssignment(activity)) {
      return 'During the Evaluate stage, individual students will evaluate groups. Students will not evaluate their own group.';
    }
    return null;
  };
  return (
    <DateWrapper
      title={getTitle()}
      description={getDescription()}
      dateInput={
        <ScheduleDateInput
          testid="evaluate_due-date"
          label={localize({ message: 'Activity.Schedule.DueDate' })}
          title={getTitle()}
          value={evaluationEndDate}
          onDateChange={handleDateChange}
          minDate={minDate}
          disabled={
            ![
              assignmentStatuses.DRAFT,
              assignmentStatuses.CREATE,
              assignmentStatuses.PROCESSING1,
              assignmentStatuses.EVALUATE,
            ].includes(activeStatus.name)
          }
          error={error}
          customTime={schedule.customTime}
          onCustomTimeChange={onCustomTimeChange}
        />
      }
      warning={getWarning()}
      attention={attention}
    >
      <EvaluationGracePeriod
        selectedGracePeriod={schedule.evaluationGracePeriodInterval / 60}
        isCheckedGracePeriod={!!schedule.evaluationGracePeriodInterval}
        handleCheck={toggleGracePeriod}
        handleSelect={(interval: any) => {
          return handleIntervalChange(interval * 60, 'evaluationGracePeriodInterval');
        }}
        isLocked={
          !(
            [
              assignmentStatuses.DRAFT,
              assignmentStatuses.CREATE,
              assignmentStatuses.PROCESSING1,
              assignmentStatuses.PROCESSING2,
              assignmentStatuses.EVALUATE,
            ] as string[]
          ).includes(activeStatus.name)
        }
        evaluationEndDate={schedule.evaluationEndDate}
      />
    </DateWrapper>
  );
}

const mapStateToProps = (state: any) => {
  return {
    activity: selectCurrentActivity(state),
    schedule: selectSchedule(state),
  };
};

export default connect(mapStateToProps)(EvaluateSchedule);
