import { localize } from 'locales';

export const COLUMNS = [
  { header: localize({ message: 'CreationList.MiniTable.Column.Name' }), align: 'left' },
  { header: localize({ message: 'CreationList.MiniTable.Column.Issues' }), align: 'right' },
  { header: ' ', align: 'right' },
];
export default {
  COLUMNS,
};
