import Switch from '@material-ui/core/Switch';
import { TranslatedText } from 'components/TranslatedText';
import InlineInformation from 'components/layout/InlineInformation';
import { useFetchCourse } from 'hooks/course';
import { updateAutoSyncSettingMutation } from 'hooks/lms';
import { useParams } from 'react-router';

export const AutoSyncToggle = () => {
  const { courseId } = useParams();
  const { data: course } = useFetchCourse(courseId);
  const updateAutoSyncSetting = updateAutoSyncSettingMutation(courseId);

  function toggleAutoSync() {
    updateAutoSyncSetting.mutate({
      courseId: course._id,
      autoRosterSync: !course.lms.autoRosterSync,
    });
  }

  return (
    <>
      <div className="sync-roster-switch-container">
        <Switch
          disabled={updateAutoSyncSetting.isLoading}
          checked={course.lms.autoRosterSync}
          onChange={() => {
            toggleAutoSync();
          }}
          color="primary"
        />
        <div className="sync-roster-switch-container__copy">
          <TranslatedText i18nKey="RosterManager.SyncRosterPopup.AutoSyncToggle.Title" />
          <p className="sync-roster-copy">
            <TranslatedText i18nKey="RosterManager.SyncRosterPopup.AutoSyncToggle.Description" />
          </p>
        </div>
      </div>
      <div className="auto-sync-error">
        {updateAutoSyncSetting.isError && (
          <InlineInformation type="danger">
            <TranslatedText i18nKey="RosterManager.SyncRosterPopup.AutoSyncToggle.ErrorMessage" />
          </InlineInformation>
        )}
      </div>
    </>
  );
};

export default AutoSyncToggle;
