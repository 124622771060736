import * as ActivityUtils from '@kritik/utils/activity';
import * as statusUtil from '@kritik/utils/stage';
import { BetaBadge } from 'components/Badges';
import { Button, ButtonContainer } from 'components/buttons';
import ButtonWithSelect from 'components/buttons/ButtonWithSelect';
import { useIsFeatureFlagEnabled } from 'context/growthbookContext';
import { localize } from 'locales';
import { withRouter } from 'utils/withRouter';
import { ADVANCE_ACTIVITY_STAGE, REVERT_ACTIVITY_STAGE } from '.';
import { initiateAiContentCheck } from './utils';

const AI_CONTENT_CHECK = localize({ message: 'Activity.Details.Action.ContentCheck' });

type MainButton = {
  label: string;
  value: string;
  onClick?: () => void;
  unavailable?: boolean;
  disabled?: boolean;
  type?: 'primary' | 'secondary';
  testid?: string;
};

type SecondaryButton = {
  label: string;
  value: string;
  disabled?: boolean;
  tooltip?: string;
  testid?: string;
};

type Actions = {
  main?: MainButton;
  secondary: SecondaryButton[];
};

const IndividualPresentationActivityButtons = withRouter(
  ({ activity, isScheduled, router, course, openRevertActivityModal, openAdvanceActivityStageModal }: any) => {
    const { courseId } = router.params;
    const activityId = activity._id;
    const exportAiContentCheckEnabled = useIsFeatureFlagEnabled('export_ai_content_report');

    const createStageHasPassed = statusUtil.isEvaluateOrLater(activity);

    const handleActionChange = (e: any) => {
      const option = e.target.value;
      if (option === AI_CONTENT_CHECK) {
        return initiateAiContentCheck(activityId);
      }
      if (option === REVERT_ACTIVITY_STAGE) {
        return openRevertActivityModal();
      }
      if (option === ADVANCE_ACTIVITY_STAGE) {
        return openAdvanceActivityStageModal();
      }

      const path = e.target.value;
      router.push(path);
    };

    const editLink = `/course/${courseId}/assignment/${activityId}/edit-assignment`;
    const editParticipationLink = `/course/${courseId}/assignment/${activityId}/edit-participation`;
    const editScheduleLink = `/course/${courseId}/assignment/${activityId}/presentation-schedule`;

    const getActions = (): Actions => {
      if (statusUtil.isFinalized({ assignment: activity })) {
        const options: Actions = {
          main: {
            label: localize({ message: 'Activity.IndividualPresentation.Overview.EditActivity' }),
            testid: 'individual-presentation-edit-activity',
            value: editLink,
            type: 'secondary',
          },
          secondary: [],
        };
        if (exportAiContentCheckEnabled) {
          options.secondary.push({
            label: AI_CONTENT_CHECK,
            value: AI_CONTENT_CHECK,
            testid: 'ai-content-check',
          });
        }
        return options;
      }

      if (statusUtil.isGrading({ assignment: activity })) {
        const options: Actions = {
          secondary: [
            {
              label: localize({ message: 'Activity.IndividualPresentation.Overview.EditActivity' }),
              value: editLink,
              testid: 'individual-presentation-edit-activity',
            },
            {
              label: localize({
                message: 'Activity.IndividualPresentation.Overview.EditParticipation',
              }),
              value: editParticipationLink,
              disabled: true,
              tooltip: localize({
                message: 'Activity.IndividualPresentation.Overview.NoEditPastCreateStage',
              }),
              testid: 'individual-presentation-edit-participation',
            },
          ],
        };
        if (exportAiContentCheckEnabled) {
          options.secondary.push({
            label: AI_CONTENT_CHECK,
            value: AI_CONTENT_CHECK,
            testid: 'ai-content-check',
          });
        }
        return options;
      }

      if (createStageHasPassed) {
        const options: Actions = {
          main: {
            label: localize({ message: 'Activity.IndividualPresentation.Overview.EditSchedule' }),
            value: editScheduleLink,
            testid: 'individual-presentation-edit-schedule',
          },
          secondary: [
            {
              label: localize({ message: 'Activity.IndividualPresentation.Overview.EditActivity' }),
              value: editLink,
              testid: 'individual-presentation-edit-activity',
            },
            {
              label: localize({
                message: 'Activity.IndividualPresentation.Overview.EditParticipation',
              }),
              value: editParticipationLink,
              disabled: true,
              tooltip: localize({
                message: 'Activity.IndividualPresentation.Overview.NoEditPastCreateStage',
              }),
              testid: 'individual-presentation-edit-participation',
            },
          ],
        };
        if (exportAiContentCheckEnabled) {
          options.secondary.push({
            label: AI_CONTENT_CHECK,
            value: AI_CONTENT_CHECK,
            testid: 'ai-content-check',
          });
        }
        return options;
      }

      if (isScheduled) {
        return {
          main: {
            label: localize({ message: 'Activity.IndividualPresentation.Overview.EditSchedule' }),
            value: editScheduleLink,
            testid: 'individual-presentation-edit-schedule',
          },
          secondary: [
            {
              label: localize({ message: 'Activity.IndividualPresentation.Overview.EditActivity' }),
              value: editLink,
              testid: 'individual-presentation-edit-activity',
            },
            {
              label: localize({
                message: 'Activity.IndividualPresentation.Overview.EditParticipation',
              }),
              value: editParticipationLink,
              testid: 'individual-presentation-edit-participation',
            },
          ],
        };
      }

      if (ActivityUtils.hasIndividualCreators(activity)) {
        return {
          main: {
            label: localize({
              message: 'Activity.IndividualPresentation.Overview.ScheduleActivity',
            }),
            value: editScheduleLink,
            testid: 'individual-presentation-schedule-activity',
          },
          secondary: [
            {
              label: localize({ message: 'Activity.IndividualPresentation.Overview.EditActivity' }),
              value: editLink,
              testid: 'individual-presentation-edit-activity',
            },
            {
              label: localize({
                message: 'Activity.IndividualPresentation.Overview.EditParticipation',
              }),
              value: editParticipationLink,
              testid: 'individual-presentation-edit-participation',
            },
          ],
        };
      }

      if (course?.students?.length > 0) {
        return {
          main: {
            label: localize({
              message: 'Activity.IndividualPresentation.Overview.EditParticipation',
            }),
            value: editParticipationLink,
            testid: 'individual-presentation-edit-participation',
          },
          secondary: [
            {
              label: localize({ message: 'Activity.IndividualPresentation.Overview.EditActivity' }),
              value: editLink,
              testid: 'individual-presentation-edit-activity',
            },
            {
              label: localize({
                message: 'Activity.IndividualPresentation.Overview.ScheduleActivity',
              }),
              value: editScheduleLink,
              disabled: true,
              tooltip: localize({
                message: 'Activity.IndividualPresentation.Overview.SelectBeforeSchedule',
              }),
              testid: 'individual-presentation-schedule-activity',
            },
          ],
        };
      }

      return {
        main: {
          label: localize({ message: 'Activity.IndividualPresentation.Overview.EditActivity' }),
          value: editLink,
          testid: 'individual-presentation-edit-activity',
        },
        secondary: [
          {
            label: localize({
              message: 'Activity.IndividualPresentation.Overview.EditParticipation',
            }),
            value: editParticipationLink,
            disabled: true,
            tooltip: localize({
              message: 'Activity.IndividualPresentation.Overview.StudentsBeforeEditParticipation',
            }),
            testid: 'individual-presentation-edit-participation',
          },
          {
            label: localize({
              message: 'Activity.IndividualPresentation.Overview.ScheduleActivity',
            }),
            value: editScheduleLink,
            disabled: true,
            tooltip: localize({
              message: 'Activity.IndividualPresentation.Overview.SelectBeforeSchedule',
            }),
            testid: 'individual-presentation-schedule-activity',
          },
        ],
      };
    };

    const { secondary, main } = getActions();
    const canAdvanceStage =
      statusUtil.isScheduled(activity) &&
      !statusUtil.isDraft({ assignment: activity }) &&
      !statusUtil.isGradingOrLater(activity);
    const allSecondaryOptions = [
      {
        value: REVERT_ACTIVITY_STAGE,
        disabled: !statusUtil.isCreateOrLater(activity),
        label: (
          <>
            <span>{REVERT_ACTIVITY_STAGE}</span>
            <span style={{ marginLeft: '0.5em' }}>
              <BetaBadge />
            </span>
          </>
        ),
        testid: 'revert-activity-stage',
      },
      canAdvanceStage && {
        value: ADVANCE_ACTIVITY_STAGE,
        label: <span>{ADVANCE_ACTIVITY_STAGE}</span>,
        testid: 'advance-activity-stage',
      },
      ...secondary,
    ];

    return (
      <ButtonContainer align="right">
        {secondary.length > 0 && (
          <ButtonWithSelect
            options={allSecondaryOptions}
            onChange={handleActionChange}
            title={localize({ message: 'Activity.IndividualPresentation.Overview.Actions' })}
            type="secondary"
            position="right"
            testid="presentation-actions"
          />
        )}
        {main && (
          <Button
            type={main.type ?? 'primary'}
            onClick={() => {
              main.value ? router.push(main.value) : main.onClick();
            }}
            disabled={main.disabled}
            unavailable={main.unavailable}
            testid={main.testid}
          >
            {main.label}
          </Button>
        )}
      </ButtonContainer>
    );
  }
);

export default IndividualPresentationActivityButtons;
