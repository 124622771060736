import { Component } from 'react';
import { connect } from 'react-redux';

type AccessibilityMessageState = any;

class AccessibilityMessage extends Component<{}, AccessibilityMessageState> {
  constructor(props: {}) {
    super(props);

    this.state = {
      currentMessage: '',
    };
  }

  render() {
    const { currentMessage } = this.state;
    return (
      <div className="sr-only" role="status" aria-live="polite" aria-atomic="true">
        {currentMessage ? <span>{currentMessage}</span> : ''}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    message: state.accessibilityMessage ? state.accessibilityMessage.message : '',
  };
};

export default connect(mapStateToProps)(AccessibilityMessage);
