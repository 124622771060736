import { TranslatedText } from 'components/TranslatedText';
import FormField from 'components/core/form/Field';
import Select from 'components/core/form/SelectField';
import InlineInformation from 'components/layout/InlineInformation';
import NoticeBoard from 'components/layout/NoticeBoard';
import { localize } from 'locales';
import React from 'react';

const ActivitySelector = (props: any) => {
  const hasfinalizedActivityList = () => {
    return props.finalizedActivityList.length > 0;
  };

  const hasCreations = (activity: any) => {
    return activity.numSubmissions > 0;
  };

  const getSelectionOptions = () => {
    return props.finalizedActivityList
      .map((activity: any) => {
        return {
          label: activity.title,
          value: activity._id,
          disabled: !hasCreations(activity),
          description: !hasCreations(activity) ? localize({ message: 'ActivitySelector.NoCreations' }) : null,
          testid: `select-past-activity-${activity._id}`,
        };
      })
      .sort((a: any, b: any) => {
        if (a.label.toLowerCase() > b.label.toLowerCase()) {
          return 1;
        }
        if (a.label.toLowerCase() < b.label.toLowerCase()) {
          return -1;
        }
        return 0;
      });
  };

  const renderNotEnoughfinalizedActivityList = () => {
    if (!props.course) {
      return null;
    }
    if (props.course && hasfinalizedActivityList()) {
      return null;
    }
    return (
      <React.Fragment>
        <InlineInformation type="warning">
          <TranslatedText i18nKey="ActivitySelector.NotEnoughCompletedActivities" />
        </InlineInformation>
        <NoticeBoard type="caution" title={localize({ message: 'ActivitySelector.CantCreateCalibrationTitle' })}>
          <TranslatedText i18nKey="ActivitySelector.CantCreateCalibrationDescription" />
        </NoticeBoard>
      </React.Fragment>
    );
  };

  return (
    <FormField>
      <Select
        label={localize({ message: 'ActivitySelector.PastActivity' })}
        onChange={props.onChange}
        value={props.selectedCalibrationActivity}
        disabled={!hasfinalizedActivityList()}
        options={getSelectionOptions()}
        placeholder={localize({ message: 'ActivitySelector.SelectPastActivity' })}
        testid="select-past-activity"
      />
      {renderNotEnoughfinalizedActivityList()}
    </FormField>
  );
};

export default ActivitySelector;
