import { InputLabel } from '@material-ui/core';
import classNames from 'classnames';
import { TranslatedText } from 'components/TranslatedText';

const FormFieldLabel = (props: any) => {
  const classes = classNames(
    'kritik-form-field__label',
    {
      'kritik-form-field--light': props.color === 'light',
    },
    props.className
  );
  let required = null;
  if (props.isRequired) {
    const requiredClass = classNames('kritik-form-field__label--required', {
      'kritik-form-field__label--error': props.error,
    });
    required = (
      <>
        <span className={requiredClass}>
          {' '}
          (<TranslatedText i18nKey="FormFieldLabel.Required" />)
        </span>
      </>
    );
  }
  if (!props.label) {
    if (props.menuLabel) {
      return (
        <InputLabel className="visually-hidden" id={props.id}>
          {props.menuLabel}
        </InputLabel>
      );
    }
    return null;
  }
  return (
    <label className={classes} htmlFor={props.id}>
      <span>{props.label}</span>
      {required}
    </label>
  );
};

FormFieldLabel.defaultProps = {
  isRequired: false,
  color: '',
};

export default FormFieldLabel;
