const FieldCompact = (props: any) => {
  return (
    <div className="form-field-compact">
      <div className="form-field-compact__label">{props.label}</div>
      <div className="form-field-compact__input">{props.input}</div>
    </div>
  );
};

FieldCompact.defaultProps = {
  label: '',
  input: '',
};

export default FieldCompact;
