import { useQuery } from '@tanstack/react-query';

import { Activity } from '@kritik/types.generated';
import CourseService from 'services/course';

export function useGetActiveStudents(courseId: string, activity: Activity) {
  return useQuery(
    ['getActiveStudents'],
    async () => {
      const response = await CourseService().getActiveStudents({ courseId });
      return response.data;
    },
    {
      enabled: true,
    }
  );
}
