import { DialogActions } from '@material-ui/core';
import { Button, ButtonContainer } from 'components/buttons';

const ModalAction = (props: any) => {
  const renderButtons = () => {
    return props.actions.map((action: any) => {
      return (
        <Button
          key={action.testid}
          data-testid={action.testid}
          type={action.type || 'secondary'}
          onClick={action.onClick}
          disabled={action.isDisabled}
          unavailable={action.isUnavailable}
          loading={action.isLoading}
          success={action.isSuccess}
          aria-label={action.ariaLabel}
        >
          {action.label}
        </Button>
      );
    });
  };
  return (
    <DialogActions className="kritik-confirm__buttons">
      <ButtonContainer>{renderButtons()}</ButtonContainer>
    </DialogActions>
  );
};

export default ModalAction;
