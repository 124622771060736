import { displayPercent } from '@kritik/utils/format';
import { CreationScoreUtil } from '@kritik/utils/grade';
import { calcMarkFromWeightedScore, calcWeightedScoreFromMark } from '@kritik/utils/rubric';
import { isFinalized, isGradingOrLater } from '@kritik/utils/stage';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { updateAssignmentSubmission } from 'actions/activity';
import { CriterionInfo } from 'components/General/StarRubricMarks';
import { TranslatedText } from 'components/TranslatedText';
import Button from 'components/buttons/Button';
import FormSubmitButtons from 'components/core/form/SubmitButtons';
import NoticeBoard from 'components/layout/NoticeBoard';
import { useAuthUserFromRedux } from 'hooks/user';
import { localize } from 'locales';
import { round } from 'lodash-es';
import { useState } from 'react';
import { connect } from 'react-redux';
import { sanitizeWeightedScore } from 'utils/score';
import { trackEvent } from 'utils/userEvents';
import { withUserRole } from 'utils/withUserRole';

const InstructorCreationScoreEdit = ({
  activity,
  rubric,
  creation,
  onSubmit,
  updateAssignmentSubmission,
  onCancel,
}) => {
  const authUser = useAuthUserFromRedux();
  const creationHasAiScore = creation.aiScores?.length > 0;
  const initializeCreationScore = () => {
    const numOfCriteria = rubric.criteria.length;
    if (creation && CreationScoreUtil.hasCreationScore(creation)) {
      return CreationScoreUtil.getRawCreationScore(creation, rubric);
    } else {
      return new Array(numOfCriteria).fill(0);
    }
  };

  const initializeWeightedCreationScore = (creationScore) => {
    return creationScore.map((mark, i) => {
      const weightedScore = calcWeightedScoreFromMark(mark, i, rubric);
      return round(weightedScore, 2);
    });
  };

  const initialCreationScore = initializeCreationScore();
  const initialWeightedCreationScore = initializeWeightedCreationScore(initialCreationScore);

  const [creationScore, setCreationScore] = useState<number[]>(initialCreationScore);
  const [weightedCreationScore, setWeightedCreationScore] = useState<number[]>(initialWeightedCreationScore);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);

  const getCurrentCreationScore = () => {
    if (creation) {
      return CreationScoreUtil.getRawCreationScore(creation, rubric);
    }
    return creationScore;
  };

  const handleOnSubmit = () => {
    const data: any = {};
    if (!submitting) {
      setSubmitting(true);

      data.marks = creationScore.map((mark: any) => {
        return parseFloat(mark);
      });

      data.submissionId = creation._id;
      data.assignmentId = activity._id;
      data.teacherScore = data.marks;
      updateAssignmentSubmission(data)
        .then(() => {
          return onSubmit();
        })
        .catch((e) => {
          setError(e);
          setSubmitting(false);
        });
    }
  };

  const handleUpdateCriterionScore = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    setError(false);
    let weightedScore = Number(e.target.value);
    weightedScore = sanitizeWeightedScore({ score: weightedScore, index, rubric });
    if (weightedScore < 0) {
      return;
    }
    const _weightedCreationScore = [...weightedCreationScore];
    _weightedCreationScore[index] = weightedScore;

    const _creationScore = [...creationScore];
    let score = calcMarkFromWeightedScore(weightedScore, index, rubric);
    score = round(score, 3);
    _creationScore[index] = score;

    setCreationScore(_creationScore);
    setWeightedCreationScore(_weightedCreationScore);
  };

  const { criteria } = rubric;

  const currentCreationScore = getCurrentCreationScore();

  const showClassAverageColumn = isGradingOrLater(activity) && activity.averageGrade.length > 0;

  const creationScoreChange =
    currentCreationScore.length > 0
      ? CreationScoreUtil.calcAvgCreationScore({
          creation: {
            rawWeightedAvgByCriteria: creationScore,
            teacherScore: [],
            revisedTeacherScore: [],
            scores: creation.scores,
          } as any,
          rubric,
          startingScore: activity.startingScore,
          // We should not account for the late creation penalty percentage when calculating the new score
          lateCreationPenaltyPercentage: 0,
        }) -
        CreationScoreUtil.calcAvgCreationScore({
          creation,
          rubric,
          startingScore: activity.startingScore,
          lateCreationPenaltyPercentage: activity.lateCreationPenaltyPercentage,
        })
      : null;

  const applyAiScoreToCriterion = (index) => {
    const newScore = creation.aiScores.find((score) => score.rubricCriterionIndex === index).score;
    const _creationScore = [...creationScore];
    const _weightedCreationScore = [...weightedCreationScore];

    _creationScore[index] = newScore;
    _weightedCreationScore[index] = round(calcWeightedScoreFromMark(newScore, index, rubric), 2);

    setCreationScore(_creationScore);
    setWeightedCreationScore(_weightedCreationScore);
    trackEvent('Apply AI Score', authUser, {
      activityId: activity._id,
      courseId: activity.course,
    });
  };

  const applyAiScoreToAllCriteria = () => {
    const _creationScore = [...creationScore];
    const _weightedCreationScore = [...weightedCreationScore];

    rubric.criteria.forEach((_, index) => {
      const newScore = creation.aiScores.find((score) => score.rubricCriterionIndex === index).score;

      _creationScore[index] = newScore;
      const newWeightedScore = calcWeightedScoreFromMark(newScore, index, rubric);

      _weightedCreationScore[index] = round(newWeightedScore, 2);
    });
    setCreationScore(_creationScore);
    setWeightedCreationScore(_weightedCreationScore);
    trackEvent('Apply AI Score to All', authUser, {
      activityId: activity._id,
      courseId: activity.course,
    });
  };

  return (
    <>
      <Warnings activity={activity} />
      <table data-testid="creation-score-edit-table" className="creation-score-edit">
        <thead>
          <tr>
            <th id="criterionTitle" style={{ textAlign: 'left' }}>
              <TranslatedText i18nKey="Activity.EditCreationScoreTable.Criterion" />
            </th>
            {creationHasAiScore && (
              <th id="aiScore" style={{ textAlign: 'right' }}>
                <TranslatedText i18nKey="Activity.EditCreationScoreTable.AIScore" />
              </th>
            )}
            {showClassAverageColumn && (
              <th id="classAverage" className="creation-score-edit__class-avg">
                <TranslatedText i18nKey="Activity.EditCreationScoreTable.ClassAvg" />
              </th>
            )}
            <th id="originalScore" className="creation-score-edit__current-score">
              <TranslatedText i18nKey="Activity.EditCreationScoreTable.CurrentScore" />
            </th>
            <th id="newScore" className="creation-score-edit__new-score">
              <TranslatedText i18nKey="Activity.EditCreationScoreTable.NewScore" />
              {creationHasAiScore && (
                <div
                  className="creation-score-edit__apply-ai-score"
                  onClick={() => {
                    applyAiScoreToAllCriteria();
                  }}
                >
                  <TranslatedText i18nKey="Activity.EditCreationScoreTable.ApplyAiScoreToAll" />
                </div>
              )}
            </th>
          </tr>
        </thead>
        <tbody>
          {criteria.map((criterion: any, i: any) => {
            return (
              <tr data-testid={`selected-row-${i}`} key={i} className="creation-score-edit__row">
                <td headers="criterionTitle" tabIndex={0} className="creation-score-edit__criterion-title">
                  <CriterionInfo marks={creationScore} rubric={rubric} criterionIndex={i} />
                </td>
                {creationHasAiScore && (
                  <td headers="aiScore" className="creation-score-edit__ai-score">
                    {calcWeightedScoreFromMark(creation.aiScores[i].score, i, rubric).toFixed(2)}
                  </td>
                )}
                {showClassAverageColumn && (
                  <td headers="classAverage" className="creation-score-edit__class-avg">
                    {calcWeightedScoreFromMark(activity.averageGrade[i], i, rubric).toFixed(2)}
                  </td>
                )}
                <td headers="originalScore" className="creation-score-edit__current-score">
                  <span className="creation-score-edit__score-value">
                    {currentCreationScore[i] >= 0 ? (
                      calcWeightedScoreFromMark(currentCreationScore[i], i, rubric).toFixed(2)
                    ) : (
                      <TranslatedText i18nKey="Activity.EditCreationScoreTable.None" />
                    )}
                  </span>
                </td>
                <td headers="newScore" className="creation-score-edit__new-score">
                  <NewCriterionScoreInput
                    criterionWeight={criterion.weight}
                    criterionIndex={i}
                    weightedCreationScore={weightedCreationScore}
                    handleUpdateCriterionScore={handleUpdateCriterionScore}
                  />
                  {creationHasAiScore && (
                    <div
                      onClick={() => {
                        applyAiScoreToCriterion(i);
                      }}
                      className="creation-score-edit__apply-ai-score"
                    >
                      <TranslatedText i18nKey="Activity.EditCreationScoreTable.ApplyAiScore" />
                    </div>
                  )}
                </td>
              </tr>
            );
          })}
          <tr className="creation-score-edit__row">
            <td headers="criterionTitle" tabIndex={0} className="creation-score-edit__criterion-title">
              <TranslatedText i18nKey="Activity.EditCreationScoreTable.CreationScore" />
            </td>
            {creationHasAiScore && (
              <td headers="aiScore" className="creation-score-edit__ai-score">
                {displayPercent(round(creation.aiScore, 0))}
              </td>
            )}
            {showClassAverageColumn && (
              <td headers="classAverage" className="creation-score-edit__class-avg">
                {displayPercent(
                  CreationScoreUtil.calcClassAverageCreationScore({
                    averageGrade: activity.averageGrade,
                    rubric,
                    startingScore: activity.startingScore,
                  })
                )}
              </td>
            )}
            <td headers="originalScore" className="creation-score-edit__current-score">
              {currentCreationScore.length > 0 ? (
                displayPercent(
                  CreationScoreUtil.calcAvgCreationScore({
                    creation,
                    rubric,
                    startingScore: activity.startingScore,
                    lateCreationPenaltyPercentage: activity.lateCreationPenaltyPercentage,
                  })
                )
              ) : (
                <TranslatedText i18nKey="Activity.EditCreationScoreTable.None" />
              )}
              <br />
              <span className="creation-score-edit__score-label">
                <TranslatedText i18nKey="Activity.EditCreationScoreTable.Original" />
              </span>
            </td>
            <td headers="newScore" className="creation-score-edit__new-score-value">
              {!creationScoreChange ? null : creationScoreChange > 0 ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
              {displayPercent(
                CreationScoreUtil.calcAvgCreationScore({
                  creation: {
                    rawWeightedAvgByCriteria: creationScore,
                    teacherScore: [],
                    revisedTeacherScore: [],
                    scores: creation.scores,
                  } as any,
                  rubric,
                  startingScore: activity.startingScore,
                  // We should not account for the late creation penalty percentage when calculating the new score
                  lateCreationPenaltyPercentage: 0,
                })
              )}
              <br />
              <span className="creation-score-edit__score-label">
                <TranslatedText i18nKey="Activity.EditCreationScoreTable.NewScore" />
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      {error && (
        <NoticeBoard type="danger">
          <TranslatedText i18nKey="Error.SomethingWrongHappened.ContactSupport" />
        </NoticeBoard>
      )}
      <FormSubmitButtons>
        <Button
          type="primary"
          disabled={submitting}
          loading={submitting}
          onClick={handleOnSubmit}
          data-testid="edit-creation-score"
        >
          <TranslatedText i18nKey="Save" />
        </Button>
        <Button type="secondary" onClick={onCancel}>
          <TranslatedText i18nKey="Cancel" />
        </Button>
      </FormSubmitButtons>
    </>
  );
};

const NewCriterionScoreInput = ({
  criterionIndex,
  criterionWeight,
  weightedCreationScore,
  handleUpdateCriterionScore,
}) => {
  const weightedScore = weightedCreationScore[criterionIndex];
  return (
    <>
      <input
        type="number"
        value={weightedScore}
        step="0.1"
        placeholder={weightedScore}
        onChange={(e) => {
          return handleUpdateCriterionScore(e, criterionIndex);
        }}
        className="creation-score-edit__score-input"
        data-testid={`score-editor-${criterionIndex}`}
      />
      <span className="creation-score-edit__score-weigth">{`/${criterionWeight} ${localize({ message: 'Activity.Weight.MultiplePoints' })}`}</span>
    </>
  );
};

const Warnings = ({ activity }) => {
  if (isFinalized({ assignment: activity })) {
    return (
      <NoticeBoard
        type="information"
        title={<TranslatedText i18nKey="Activity.EditCreationScoreTable.Warning.NoImpact.Title" />}
      >
        <TranslatedText i18nKey="Activity.EditCreationScoreTable.Warning.NoImpact.Content" />
      </NoticeBoard>
    );
  }
  return (
    <NoticeBoard
      type="information"
      title={<TranslatedText i18nKey="Activity.EditCreationScoreTable.Warning.InstructorScore.Title" />}
    >
      <TranslatedText i18nKey="Activity.EditCreationScoreTable.Warning.InstructorScore.Content" />
    </NoticeBoard>
  );
};

function mapStateToProps(state: any) {
  return {};
}

export default withUserRole(
  connect(mapStateToProps, {
    updateAssignmentSubmission,
  })(InstructorCreationScoreEdit)
);
