import * as LateSubmissionUtil from '@kritik/utils/creation/lateCreation';
import { getSubmissionLastEditedDate } from '@kritik/utils/creation/status';
import { exactDate } from '@kritik/utils/format';
import HtmlContent from 'components/General/HtmlContent';
import { TranslatedText } from 'components/TranslatedText';
import React from 'react';

export const SubmittedDate = (props: any) => {
  const submissionDate = getSubmissionLastEditedDate(props.creation);
  const formattedSubmissionDate = exactDate(submissionDate);
  return (
    <div>
      <TranslatedText i18nKey="Fields.SubmittedDate" values={{ date: formattedSubmissionDate }} />
    </div>
  );
};

export const LateReason = (props: any) => {
  const reason = LateSubmissionUtil.getReasonForLateSubmission(props.creation);
  return (
    <React.Fragment>
      <div className="late-submission-boards__reason">
        <TranslatedText i18nKey="Fields.LateReason.Title" />
      </div>
      <HtmlContent content={reason} />
    </React.Fragment>
  );
};

export const RejectedDate = (props: any) => {
  const rejectedDate = exactDate(LateSubmissionUtil.getResolvedDate(props.creation));
  return (
    <div>
      <TranslatedText i18nKey="Fields.RejectedDate" values={{ date: rejectedDate }} />
    </div>
  );
};

export const AcceptedDate = (props: any) => {
  const date = exactDate(LateSubmissionUtil.getResolvedDate(props.creation));
  return (
    <div>
      <TranslatedText i18nKey="Fields.AcceptedDate" values={{ date: date }} />
    </div>
  );
};
