import { RANK_DESCRIPTIONS, RANK_NAMES } from '@kritik/constants/kritikScore';
import { Popper } from '@material-ui/core';
import { Rating } from '@material-ui/lab';
import { localize } from 'locales';
import { connect } from 'react-redux';
import { getKritikScoreLevel } from 'utils/student';

type AvatarPopoverProps = {
  isOpen: boolean;
  kritikScore: number;
  anchorEl: HTMLElement;
  id: string;
};
const AvatarPopover = ({ isOpen, kritikScore, anchorEl, id }: AvatarPopoverProps) => {
  const ksLevel = getKritikScoreLevel(kritikScore);
  const getScoreLabel = () => {
    return <div className="avatar-popover__title">{RANK_NAMES[ksLevel - 1]}</div>;
  };
  const getDescription = () => {
    const title = localize({ message: 'AvatarPopover.title' });
    return (
      <div className="avatar-popover__info">
        <div className="avatar-popover__info-title">{title}</div>
        <div className="avatar-popover__info-description">{RANK_DESCRIPTIONS[ksLevel - 1]}</div>
      </div>
    );
  };

  return (
    <Popper
      id={id}
      className="avatar-popover"
      open={isOpen || false}
      placement="bottom-start"
      modifiers={{
        offset: {
          enabled: true,
          offset: '0, 10',
        },
      }}
      anchorEl={anchorEl}
      disablePortal={false}
    >
      {getScoreLabel()}
      <Rating
        className="avatar-popover__stars"
        max={Object.keys(RANK_NAMES).length}
        value={ksLevel}
        precision={1}
        readOnly
      />
      {getDescription()}
    </Popper>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user.authUser,
  };
};

AvatarPopover.defaultProps = {
  isOpen: false,
  kritikScore: 0,
};

export default connect(mapStateToProps)(AvatarPopover);
