import { resolveDispute, resolveLateSubmission } from 'actions/activity';
import CreationOverview from 'components/Creation/CreationOverview';
import { Component } from 'react';
import { connect } from 'react-redux';
import { selectCreationTableListBy } from 'redux/creationTable/select';
import { getAssignment } from 'selectors/activity';
import { getSubmission } from 'selectors/creation';
import { getGroup } from 'selectors/group';
import { getStudent } from 'selectors/student';

const mapStateToProps = (state: any) => {
  return {
    assignment: getAssignment(state, state.selected.assignmentId),
    submission: getSubmission(state, state.selected.submissionId),
    student: getStudent(state.selected.studentId, state.entities),
    creationTableListBy: selectCreationTableListBy(state),
    user: state.user,
    entities: state.entities,
  };
};

type SubmissionViewState = any;

class SubmissionView extends Component<{}, SubmissionViewState> {
  state = {
    group: null,
  };

  componentDidMount() {
    this.setGroupInfo();
  }

  componentDidUpdate(prevProps: {}, prevState: SubmissionViewState) {
    if (!(this.props as any).submission || !(prevProps as any).submission) {
      return;
    }
    if (
      (this.props as any).assignment.isGroupActivity &&
      (this.props as any).submission._id != (prevProps as any).submission._id
    ) {
      this.setGroupInfo();
    }
  }

  setGroupInfo() {
    if ((this.props as any).assignment.isGroupActivity) {
      const group = getGroup((this.props as any).submission.group._id, (this.props as any).entities);
      this.setState({
        group,
      });
    }
  }

  render() {
    // @ts-expect-error TS(2339) FIXME: Property 'submission' does not exist on type 'Read... Remove this comment to see the full error message
    const { submission, assignment, student, creationTableListBy } = this.props;

    return (
      <div className="creation-overview">
        <h2 className="visually-hidden">Submission Overview</h2>
        <div className="creation-overview__body">
          {submission ? (
            <CreationOverview
              user={(this.props as any).user.authUser}
              resolveDispute={(this.props as any).resolveDispute}
              resolveLateSubmission={(this.props as any).resolveLateSubmission}
              submission={submission}
              assignment={assignment}
              student={student}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, {
  resolveDispute,
  resolveLateSubmission,
})(SubmissionView);
