import { PassFailGrade } from 'components/Creation/GradeView';

const PassFailItem = ({ title, creation }: any) => {
  return (
    <div className="display-box-pass-fail-item">
      <div className="display-box-pass-fail-item__title">{title}</div>
      <PassFailGrade creation={creation} />
    </div>
  );
};

export default PassFailItem;
