import { getMarkingSchemeBreakDowns } from '@kritik/utils/course';
import { setDashboardScores } from 'actions/courses';
import { TranslatedText } from 'components/TranslatedText';
import { useUserRoleInCourse } from 'hooks/course';
import { localize } from 'locales';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getPastAssignments } from 'selectors/activity';
import { getCourse } from 'selectors/course';
import { selectDashboardGrades, selectGradeHistories } from 'selectors/grade';
import ScoreCard from './ScoreCard';
import { calculateAvgGradeChanges } from './calcGrades';

const mapStateToProps = (state: any) => {
  return {
    course: getCourse(state),
    avgGrades: selectDashboardGrades(state),
    gradeHistories: selectGradeHistories(state),
    finalizedActivities: getPastAssignments(state, state.selected.courseId),
  };
};

const ActivityListDashboard = ({ gradeHistories, course, avgGrades, setDashboardScores, finalizedActivities }: any) => {
  const [avgGradeChanges, setAvgGradeChanges] = useState({});
  const { isInstructorInCourse } = useUserRoleInCourse();

  useEffect(() => {
    // 1. when score is not calculated - check if all scores are null in store
    // 2. when course changed - select course action chain
    // 3. When finalized an assignment - finalize assignments action chain

    // Add gradeHistories check for fixing case that we need to wait
    // wait for getCourse action to get an individual course with gradeHistories field
    if (course.gradeHistories && finalizedActivities.length) {
      setDashboardScores(course._id);
      setAvgGradeChanges(calculateAvgGradeChanges(gradeHistories, course, finalizedActivities));
    }
  }, [course._id, gradeHistories.length, finalizedActivities.length]);

  const instructorDescriptionTextEvalScore = {
    overallScoreDescription: localize({
      message: 'ActivityListDashboard.Instructor.OverallScoreDescription',
    }),
    overallScoreHeader: localize({
      message: 'ActivityListDashboard.Instructor.OverallScoreHeader',
    }),
    creationScoreDescription: localize({
      message: 'ActivityListDashboard.Instructor.CreationScoreDescription',
    }),
    creationScoreHeader: localize({
      message: 'ActivityListDashboard.Instructor.CreationScoreHeader',
    }),
    evalScoreDescription: localize({
      message: 'ActivityListDashboard.Instructor.EvalScoreDescription',
    }),
    evalScoreHeader: localize({ message: 'ActivityListDashboard.Instructor.EvalScoreHeader' }),
    feedbackScoreDescription: localize({
      message: 'ActivityListDashboard.Instructor.FeedbackScoreDescription',
    }),
    feedbackScoreHeader: localize({
      message: 'ActivityListDashboard.Instructor.FeedbackScoreHeader',
    }),
  };

  const studentDescriptionTextEvalScore = {
    overallScoreHeader: localize({ message: 'ActivityListDashboard.Student.OverallScoreHeader' }),
    overallScoreDescription: localize({
      message: 'ActivityListDashboard.Student.OverallScoreDescription',
    }),
    creationScoreDescription: localize({
      message: 'ActivityListDashboard.Student.CreationScoreDescription',
    }),
    creationScoreHeader: localize({ message: 'ActivityListDashboard.Student.CreationScoreHeader' }),
    evalScoreDescription: localize({
      message: 'ActivityListDashboard.Student.EvalScoreDescription',
    }),
    evalScoreHeader: localize({ message: 'ActivityListDashboard.Student.EvalScoreHeader' }),
    feedbackScoreDescription: localize({
      message: 'ActivityListDashboard.Student.FeedbackScoreDescription',
    }),
    feedbackScoreHeader: localize({ message: 'ActivityListDashboard.Student.FeedbackScoreHeader' }),
  };

  const getScoreBoxDescripton = () => {
    let scoreBoxDescription = {};
    if (isInstructorInCourse) {
      scoreBoxDescription = instructorDescriptionTextEvalScore;
    } else {
      scoreBoxDescription = studentDescriptionTextEvalScore;
    }
    return scoreBoxDescription;
  };

  const description = getScoreBoxDescripton();
  const markingSchemeBreakdowns = getMarkingSchemeBreakDowns(course);

  return (
    <>
      <h2 className="new-header-2">
        <TranslatedText i18nKey="Activity.Dashboard.KritikScore" />
      </h2>
      <div className="activityList-boxes-wrapper">
        <ScoreCard
          score={avgGrades.avgOverallGrade}
          scoreTrend={(avgGradeChanges as any).overall}
          scoreName={(description as any).overallScoreHeader}
          description={(description as any).overallScoreDescription}
          weight={null}
          ariaLabel={localize({ message: 'ActivityListDashboard.AriaLabel.OverallScore' })}
        />
        <ScoreCard
          score={avgGrades.avgCreationGrade}
          scoreTrend={(avgGradeChanges as any).creation}
          scoreName={(description as any).creationScoreHeader}
          description={(description as any).creationScoreDescription}
          weight={markingSchemeBreakdowns.creation}
          ariaLabel={localize({ message: 'ActivityListDashboard.AriaLabel.CreationGrade' })}
        />
        <ScoreCard
          score={avgGrades.avgEvalGrade}
          scoreTrend={(avgGradeChanges as any).evaluation}
          scoreName={(description as any).evalScoreHeader}
          description={(description as any).evalScoreDescription}
          weight={markingSchemeBreakdowns.evaluation}
          ariaLabel={localize({ message: 'ActivityListDashboard.AriaLabel.EvaluationGrade' })}
        />
        <ScoreCard
          score={avgGrades.avgFeedbackGrade}
          scoreTrend={(avgGradeChanges as any).feedback}
          scoreName={(description as any).feedbackScoreHeader}
          description={(description as any).feedbackScoreDescription}
          weight={markingSchemeBreakdowns.feedback}
          ariaLabel={localize({ message: 'ActivityListDashboard.AriaLabel.FeedbackScore' })}
        />
      </div>
    </>
  );
};

export default connect(mapStateToProps, {
  setDashboardScores,
})(ActivityListDashboard);
