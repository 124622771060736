import { isPresentationActivity } from '@kritik/utils/activity';
import {
  createLateSubmission,
  getAssignmentsForLateSubmission,
  getCoursesForLateSubmission,
  getPotentialReviewersForLateSubmission,
} from 'actions/admin';
import { mimeTypes } from 'components/Assignment/constants.js';
import Button from 'components/buttons/Button';
import FormField from 'components/core/form/Field';
import FormMultiSelection from 'components/core/form/MultiSelectionField';
import FormRichTextEditor from 'components/core/form/RichTextEditor';
import FormCheckbox from 'components/core/input/Checkbox';
import AttachmentManager from 'components/layout/AttachmentManager';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormMainError, FormSelection } from '../Form/FormComponents';
import CloseButton from './CloseButton';
import FindAssignmentContainer from './Find/FindAssignmentContainer';

const mapStateToProps = (state: any) => {
  return {
    state,
    courses: state.admin.courses,
    assignments: state.admin.assignments,
    student: state.admin.student,
    numOfEvalsInstructorWants: state.admin.numOfEvalsProfWants,
    potentialReviewers: state.admin.potentialReviewers,
    instructor: state.admin.prof,
    files: state.admin.files,
    content: state.admin.content,
  };
};

const SUBMISSION_STATUS_OPTIONS = [
  { value: 'ON_TIME', label: 'ON TIME (submitted during CREATE stage)' },
  { value: 'GRACE', label: 'GRACE (submitted afer CREATE stage)' },
  { value: 'MISSED', label: 'MISSED (not submitted)' },
];

type LateSubmissionModalState = any;

class LateSubmissionModal extends Component<
  {
    handleClose: () => void;
  },
  LateSubmissionModalState
> {
  state = {
    content: '',
    showContent: false,
    reviewers: [],
    fileList: [],
    course: null,
    assignment: null,
    uploadSize: 0,
    courseOptions: [],
    assignmentOptions: [],
    reviewerOptions: null,
    uploadLoading: false,
    isLoading: false,
    notifyCreator: false,
    notifyInstructor: false,
    notifyReviewers: false,
    submissionStatus: null,
    error: null,
  };

  checkAllFields() {
    return this.state.showContent && this.state.submissionStatus !== null;
  }

  handleFileChange(files: any) {
    const totalSize = files.reduce((sum: any, file: any) => {
      sum += file.size;
      return sum;
    }, 0);

    this.setState({ fileList: files, uploadSize: totalSize });
  }

  getExtensionsAllowed() {
    const formats = {
      allowAudio: 'Audio, ',
      allowExcel: 'Excel, ',
      allowImages: 'Images, ',
      allowPDF: 'PDF, ',
      allowPowerpoint: 'Powerpoint, ',
      allowText: 'Text, ',
      allowVideo: 'Video, ',
      allowWord: 'Word (.docx type only), ',
      allowZip: 'Zip, ',
    };

    const extensionsArray: any = [];

    this.state.assignment.value.fileExtensionsAllowed.forEach((extension: any) => {
      for (const key in mimeTypes) {
        if (mimeTypes[key].includes(extension)) {
          if (!extensionsArray.includes(formats[key])) {
            extensionsArray.push(formats[key]);
          }
          continue;
        }
      }
    });

    const extensions = extensionsArray.join(' ');

    return `Allowed file types: ${extensions.slice(0, extensions.length - 2)}.`;
  }

  handleSubmit = async () => {
    this.setState({ uploadLoading: true });
    // @ts-expect-error TS(2339) FIXME: Property 'createLateSubmission' does not exist on ... Remove this comment to see the full error message
    const { createLateSubmission, student } = this.props;
    const {
      assignment,
      content,
      reviewers,
      fileList,
      notifyCreator,
      notifyInstructor,
      notifyReviewers,
      submissionStatus,
    } = this.state;

    try {
      await createLateSubmission({
        student: student.id,
        assignmentID: assignment.value._id,
        assignmentContent: content,
        reviewers: reviewers
          ? reviewers.map((reviewer) => {
              return (reviewer as any).value;
            })
          : [],
        files: fileList,
        submissionStatus: submissionStatus.value,
        notifications: {
          instructor: notifyInstructor,
          creator: notifyCreator,
          reviewers: notifyReviewers,
        },
      });

      window.location.reload();
    } catch (err) {
      this.setState({
        error: 'Something went wrong. Please contact the dev team.',
        uploadLoading: false,
      });
    }
  };

  getPotentialReviewers = () => {
    let { course, assignment } = this.state;
    this.setState({ showContent: false });
    if (course && assignment) {
      assignment = (assignment as any).value._id;
      (this.props as any)
        .getPotentialReviewersForLateSubmission({
          assignmentID: assignment,
          studentID: (this.props as any).student.id,
        })
        .then(() => {
          // @ts-expect-error TS(2339) FIXME: Property 'potentialReviewers' does not exist on ty... Remove this comment to see the full error message
          const reviewerOptions = this.props.potentialReviewers.map((student: any) => {
            return {
              value: student.studentID,
              label: `${student.email} has done  ${student.numOfEvalsCompleted} / ${student.numOfEvalsAssigned} evaluations`,
            };
          });
          this.setState({
            isLoading: false,
            reviewerOptions,
            // @ts-expect-error TS(2339) FIXME: Property 'files' does not exist on type 'Readonly<... Remove this comment to see the full error message
            fileList: this.props.files,
            // @ts-expect-error TS(2339) FIXME: Property 'content' does not exist on type 'Readonl... Remove this comment to see the full error message
            content: this.props.content,
            showContent: true,
          });
        });
      if ((this.props as any).potentialReviewers) {
        // may be null b/c of race condition with fetching it above
        const reviewerOptions = (this.props as any).potentialReviewers.map((student: any) => {
          return {
            value: student.studentID,
            label: `${student.email} has done  ${student.numOfEvalsCompleted} / ${student.numOfEvalsAssigned} evaluations`,
          };
        });
        this.setState({
          isLoading: false,
          reviewerOptions,
          fileList: (this.props as any).files,
          content: (this.props as any).content,
          showContent: true,
        });
      }
    }
  };

  changeCourse = (course: any) => {
    this.setState({ showContent: false, course });
  };

  changeAssignment = (assignment: any) => {
    this.setState({
      showContent: false,
      assignment,
      submissionStatus: null,
      fileList: assignment && assignment.value.files ? assignment.value.files : [],
    });
  };

  changeShowContent = (bool: any) => {
    this.setState({ showContent: bool });
  };

  setShouldNotifyCreator = (status: any) => {
    this.setState({ notifyCreator: status });
  };

  setShouldNotifyInstructor = (status: any) => {
    this.setState({
      notifyInstructor: status,
    });
  };

  setShouldNotifyReviewers = (status: any) => {
    this.setState({
      notifyReviewers: status,
    });
  };

  render() {
    const { handleClose } = this.props;
    const {
      assignment,
      content,
      reviewers,
      reviewerOptions,
      isLoading,
      error,
      notifyInstructor,
      notifyReviewers,
      notifyCreator,
    } = this.state;
    return (
      <div className="admin-panel-modal">
        <section className="admin-panel-modal__main admin-panel-modal__scrollable">
          <h1>Upload Late Creation</h1>
          <div>
            <FindAssignmentContainer
              course={this.state.course}
              changeCourse={this.changeCourse}
              assignment={this.state.assignment}
              changeAssignment={this.changeAssignment}
              handleVerifyAssignment={this.getPotentialReviewers}
              changeShowContent={this.changeShowContent}
            />
            {this.state.showContent && (
              <React.Fragment>
                <FormRichTextEditor
                  label="Content"
                  value={content}
                  onChange={(newContent: any) => {
                    return this.setState({ content: newContent });
                  }}
                />
                {assignment.value.fileExtensionsAllowed && (
                  <AttachmentManager
                    onFileChange={(newList: any) => {
                      return this.handleFileChange(newList);
                    }}
                    fileList={this.state.fileList}
                    allowedExtensions={
                      assignment && (assignment as any).value.fileExtensionsAllowed
                        ? (assignment as any).value.fileExtensionsAllowed
                        : []
                    }
                  />
                )}

                {assignment &&
                  (assignment as any).value.fileExtensionsAllowed &&
                  (assignment as any).value.fileExtensionsAllowed.length > 0 && (
                    <p style={{ fontStyle: 'italic' }}>{this.getExtensionsAllowed()}</p>
                  )}

                <div style={{ margin: '35px' }} />

                <FormSelection
                  label="Choose submission status"
                  value={this.state.submissionStatus}
                  onChange={(newStatus: any) => {
                    return this.setState({ submissionStatus: newStatus });
                  }}
                  options={SUBMISSION_STATUS_OPTIONS}
                />

                <div style={{ margin: '35px' }} />

                <FormMultiSelection
                  label={
                    isPresentationActivity(assignment?.value)
                      ? 'Choose evaluators'
                      : `Choose evaluators (MAX: ${(this.props as any).numOfEvalsInstructorWants})`
                  }
                  isLoading={isLoading}
                  value={reviewers}
                  onChange={(reviewers: any) => {
                    this.setState({ reviewers }, () => {
                      if (!reviewers) {
                        this.setState({ notifyReviewers: false });
                      }
                    });
                  }}
                  options={reviewerOptions || []}
                />

                <FormField>
                  <FormCheckbox
                    label={`Notify the student (${(this.props as any).student.email})`}
                    isChecked={notifyCreator}
                    onChange={() => {
                      return this.setShouldNotifyCreator(!this.state.notifyCreator);
                    }}
                  />
                </FormField>
                <FormField>
                  <FormCheckbox
                    label={`Notify the instructor (${(this.props as any).instructor.email})`}
                    isChecked={notifyInstructor}
                    onChange={() => {
                      return this.setShouldNotifyInstructor(!this.state.notifyInstructor);
                    }}
                  />
                </FormField>
                <FormField>
                  {reviewers && reviewers.length > 0 && (
                    <FormCheckbox
                      label="Notify the reviewers"
                      isChecked={notifyReviewers}
                      onChange={() => {
                        return this.setShouldNotifyReviewers(!this.state.notifyReviewers);
                      }}
                    />
                  )}
                </FormField>
                <div style={{ margin: '50px 0 20px' }} />
                <Button
                  style={{ marginTop: '30px' }}
                  loading={this.state.uploadLoading}
                  unavailable={!this.checkAllFields() || this.state.uploadLoading}
                  disabled={!this.checkAllFields() || this.state.uploadLoading}
                  type="primary"
                  onClick={this.handleSubmit}
                >
                  Upload
                </Button>
              </React.Fragment>
            )}
            {error && (
              <React.Fragment>
                <FormMainError message={error} isOpen={error} />
                <Button style={{ marginTop: '30px' }} type="primary" onClick={handleClose}>
                  Ok, Got it
                </Button>
              </React.Fragment>
            )}
          </div>
        </section>
        <CloseButton onClose={handleClose} />
      </div>
    );
  }
}

export default connect(mapStateToProps, {
  createLateSubmission,
  getCoursesForLateSubmission,
  getAssignmentsForLateSubmission,
  getPotentialReviewersForLateSubmission,
})(LateSubmissionModal);
