import React from 'react';
import createSvgIcon from '../util/generateIconSVG';

export default createSvgIcon(
  <React.Fragment>
    <circle cx="24" cy="24" r="24" fill="#E3DBF0" />
    <path
      d="M20.7904 24.318C21.0565 22.8991 22.2954 21.8708 23.7391 21.8708H25.1642C26.6078 21.8708 27.8467 22.8991 28.1128 24.318L29.0321 29.2212C29.3783 31.0675 27.9619 32.7741 26.0835 32.7741H22.8197C20.9413 32.7741 19.5249 31.0675 19.8711 29.2212L20.7904 24.318Z"
      fill="#E3DBF0"
      stroke="#302D6C"
      strokeWidth="2"
    />
    <circle cx="24.4516" cy="18.4516" r="4.64516" fill="#E3DBF0" stroke="#302D6C" strokeWidth="2" />
    <path d="M23.9386 13.5762L23.4254 17.4251H19.5764L21.116 14.6026L23.9386 13.5762Z" fill="#302D6C" />
    <path d="M23.9381 13.5762L24.4513 17.4251H29.8398L28.3003 14.6026L23.9381 13.5762Z" fill="#302D6C" />
  </React.Fragment>,
  '0 0 48 48'
);
