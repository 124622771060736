import PanelDisplay from 'components/layout/PanelDisplay';
import useMatchMedia from 'hooks/useMatchMedia';
import { FC, useEffect, useState } from 'react';

type Props = {
  header: React.ReactNode;
  secondaryTopElement?: React.ReactNode;
  body: React.ReactNode;
  testid?: string;
  mediaQuery?: string;
};

const CollapsibleComponent: FC<Props> = ({ header, body, secondaryTopElement, testid, mediaQuery }) => {
  const [isDesktop] = useMatchMedia([`(min-width: ${mediaQuery ? mediaQuery : '769px'})`]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(false);
    if (isDesktop) {
      setOpen(true);
    }
  }, [isDesktop]);

  const renderIcon = open ? <i className="fas fa-chevron-down arrow" /> : <i className="fas fa-chevron-right arrow" />;

  const headerElement = () => (
    <div
      className="collapsible-header"
      onClick={() => {
        if (!isDesktop) {
          setOpen(!open);
        }
      }}
    >
      {header}
      {!isDesktop && renderIcon}
    </div>
  );

  return (
    <div className="collapsible-container" data-testid={testid} role="complementary">
      <>
        {!secondaryTopElement && headerElement()}

        {secondaryTopElement && (
          <PanelDisplay>
            {headerElement()}
            {open && secondaryTopElement ? secondaryTopElement : null}
          </PanelDisplay>
        )}
      </>
      {open && <div className="collapsible-body">{body}</div>}
    </div>
  );
};

export default CollapsibleComponent;
