import Grid from '@material-ui/core/Grid';
import StarRubricMarks from 'components/General/StarRubricMarks';
import { STAR_RUBRIC_MARKS } from 'components/General/constant';
import { TranslatedText } from 'components/TranslatedText';
import React from 'react';
import { connect } from 'react-redux';

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
    course: state.entities.courses[state.selected.courseId],
  };
};

function EvaluationPendingView(props: any) {
  const { rubric } = props;

  return (
    <React.Fragment>
      <Grid container justifyContent="space-between" alignItems="flex-end">
        <Grid item xs={3}>
          <h3 className="header-3">
            <TranslatedText i18nKey="Creation.Evaluate.Pending.Score" />
          </h3>
        </Grid>
        <Grid item xs={3}>
          <div style={{ textAlign: 'right' }}>
            <TranslatedText i18nKey="Creation.Evaluate.Pending.NotScoredYet" />
          </div>
        </Grid>
      </Grid>
      <StarRubricMarks rubric={rubric} type="secondary" precision={1} tableType={STAR_RUBRIC_MARKS.DEFAULT} />
      <div>
        <h4 className="header-4">
          <TranslatedText i18nKey="Creation.Evaluate.Pending.WrittenEvaluation" />
        </h4>
        <p>
          <TranslatedText i18nKey="Creation.Evaluate.Pending.EvaluatorNotCompleted" />
        </p>
      </div>
    </React.Fragment>
  );
}

export default connect(mapStateToProps)(EvaluationPendingView);
