import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { deleteCoupons } from 'actions/admin';
import clsx from 'clsx';
import Button from 'components/buttons/Button';
import { connect } from 'react-redux';

const useToolbarStyles = makeStyles((theme) => {
  return {
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === 'light'
        ? {
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.primary.main,
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      flex: '1 1 100%',
    },
  };
});

const CouponTableToolbar = (props: any) => {
  const classes = useToolbarStyles();
  const { selected } = props;
  const numSelected = selected.length;

  const deleteCoupons = async (couponIds: any) => {
    try {
      await props.deleteCoupons({ couponIds });
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          Coupons
        </Typography>
      )}

      {numSelected > 0 ? (
        <div className="admin-coupon-toolbar-button-row">
          <Button
            type="secondary"
            className="bookstore-button"
            onClick={() => {
              return props.setDisplayBookstoreModal(true);
            }}
          >
            Bookstore Order
          </Button>
          <Button
            type="secondary"
            className="email-students-button"
            onClick={() => {
              return props.setDisplayCouponEmailUsersModal(true);
            }}
          >
            Email Students
          </Button>
          <Button
            type="warning"
            className="delete-coupons-button"
            onClick={() => {
              return deleteCoupons(selected);
            }}
          >
            Delete Coupon
          </Button>
        </div>
      ) : (
        <Button
          type="primary"
          onClick={() => {
            return props.setDisplayCreateCouponModal(true);
          }}
        >
          Add Coupon
        </Button>
      )}
    </Toolbar>
  );
};

export default connect(null, { deleteCoupons })(CouponTableToolbar);
