import { recalculateWeightedAvgs } from 'actions/admin';
import AddInstitutionModal from 'components/Admin/AddInstitutionModal/AddInstitutionModal';
import ChangeInstitutionModal from 'components/Admin/ChangeInstitutionModal/ChangeInstitutionModal';
import GenerateJoinLinkModal from 'components/Admin/GenerateJoinLinkModal/GenerateJoinLinkModal';
import LateSubmissionModal from 'components/Admin/LateCreationModal';
import LateEvaluationModal from 'components/Admin/LateEvalModal/LateEvaluationModal';
import LateFofModal from 'components/Admin/LateFofModal/LateFofModal';
import Button from 'components/buttons/Button';
import { InlineInformation } from 'components/layout';
import PageContainer from 'components/layout/PageContainer';
import { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'router';
import { RouterProp, withRouter } from 'utils/withRouter';

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
  };
};

const LATE_SUBMISSION = 'lateSubmission';
const LATE_EVALUATION = 'lateEvaluation';
const LATE_FOF = 'lateFof';
const GENERATE_JOIN_LINK = 'generateJoinLink';
const ADD_INSTITUTION = 'addInstitution';
const CHANGE_INSTITUTION = 'changeInstitution';

type AdminContainerProps = {
  router: RouterProp;
  [key: string]: any;
};

type AdminContainerState = any;

class AdminContainer extends Component<AdminContainerProps, AdminContainerState> {
  state = {
    error: undefined,
    institutionName: '',
    institutionCurrency: 'cad',
    displayModal: {
      lateSubmission: false,
      lateEvaluation: false,
      lateFof: false,
      generateJoinLink: false,
      addInstitution: false,
    },
  };

  displayModal = (modal: any, bool: any) => {
    this.setState({
      displayModal: {
        ...(this.state as any).showModal,
        [modal]: bool,
      },
    });
  };

  showModal = (modal: any) => {
    this.displayModal(modal, true);
  };

  hideModal = (modal: any) => {
    this.displayModal(modal, false);
  };

  toggleModal = (modal: any) => {
    this.setState({ [modal]: !this.state[modal] });
  };

  handleChange(e: any) {
    this.setState({ [e.currentTarget.name]: e.currentTarget.value });
  }

  handleClickCourseSettings = () => {
    const courseSettingsPage = `/admin/courseSettings`;
    (this.props as any).router.push(courseSettingsPage);
  };

  render() {
    return (
      <PageContainer>
        {this.state.error && <InlineInformation type="danger">{this.state.error}</InlineInformation>}
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          <Button type="secondary" onClick={() => this.props.router.push('/admin/impersonate')}>
            Impersonate User
          </Button>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          <Button type="secondary" onClick={() => this.props.router.push('/admin/activitySettings')}>
            Activity Settings
          </Button>
        </div>
        <div style={{ alignItems: 'center', marginBottom: '10px' }}>
          <Button
            type="secondary"
            onClick={() => {
              return this.showModal(LATE_SUBMISSION);
            }}
          >
            Upload Late Creation
          </Button>
        </div>
        {(this.state as any).showLateSubmission && (
          <LateSubmissionModal
            handleClose={() => {
              return this.hideModal('showLateSubmission');
            }}
          />
        )}
        <div style={{ alignItems: 'center', marginBottom: '10px' }}>
          <Button
            type="secondary"
            onClick={() => {
              return this.showModal(LATE_EVALUATION);
            }}
          >
            Manage Evaluations
          </Button>
        </div>
        <div style={{ marginBottom: '10px' }}>
          <Button
            type="secondary"
            onClick={() => {
              return this.showModal(LATE_FOF);
            }}
          >
            Upload Late Feedback
          </Button>
        </div>
        <div style={{ marginBottom: '10px' }}>
          <Button
            type="secondary"
            onClick={() => {
              return this.showModal(GENERATE_JOIN_LINK);
            }}
          >
            Generate an Invitation Link
          </Button>
        </div>
        <div style={{ marginBottom: '10px' }}>
          <Button type="secondary" onClick={() => this.props.router.push('/admin/pricing')}>
            Course Pricing Panel
          </Button>
        </div>
        <div style={{ marginBottom: '10px' }}>
          <Button type="secondary" onClick={() => this.props.router.push('/admin/course-data')}>
            Course Data per Instructor
          </Button>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          <Button type="secondary" onClick={() => this.props.router.push('/admin/institution/default-course-price')}>
            Change Default Course Price for Institution
          </Button>
        </div>
        <div style={{ marginBottom: '10px' }}>
          <Button
            type="secondary"
            onClick={() => {
              return this.showModal(ADD_INSTITUTION);
            }}
          >
            Add Institution
          </Button>
        </div>
        <div style={{ marginBottom: '10px' }}>
          <Button
            type="secondary"
            onClick={() => {
              return this.showModal(CHANGE_INSTITUTION);
            }}
          >
            Change User Institution
          </Button>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          <Button type="secondary" onClick={this.handleClickCourseSettings}>
            Course Settings
          </Button>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          <Button type="primary" onClick={() => this.props.router.push('/admin/coupons')}>
            Coupon Dashboard
          </Button>
        </div>

        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          <Button
            type="secondary"
            onClick={() => {
              window.location.href = '/admin/users';
            }}
          >
            Users
          </Button>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          <Button type="secondary" onClick={() => this.props.router.push('/admin/users/promote-admin')}>
            Promote User to Admin
          </Button>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          <Button type="secondary" onClick={() => this.props.router.push('/admin/lms-integrations')}>
            LMS Integrations
          </Button>
        </div>
        <div style={{ marginBottom: '10px' }}>
          <Button type="secondary" onClick={() => this.props.router.push('/admin/lms-connection-requests')}>
            LMS Connection Requests Per User
          </Button>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          <Button type="secondary" onClick={() => (this.props as any).router.push('/admin/users/update-user-email')}>
            Update User Email
          </Button>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          <Button type="secondary" onClick={() => this.props.router.push('/admin/users/delete-user')}>
            Delete User
          </Button>
        </div>
        {this.state.displayModal[LATE_SUBMISSION] && (
          <LateSubmissionModal
            handleClose={() => {
              return this.hideModal(LATE_SUBMISSION);
            }}
          />
        )}
        {this.state.displayModal[LATE_EVALUATION] && (
          <LateEvaluationModal
            handleClose={() => {
              return this.hideModal(LATE_EVALUATION);
            }}
          />
        )}
        {this.state.displayModal[LATE_FOF] && (
          <LateFofModal
            handleClose={() => {
              return this.hideModal(LATE_FOF);
            }}
          />
        )}
        {this.state.displayModal[GENERATE_JOIN_LINK] && (
          <GenerateJoinLinkModal
            handleClose={() => {
              return this.hideModal(GENERATE_JOIN_LINK);
            }}
          />
        )}
        {this.state.displayModal[ADD_INSTITUTION] && (
          <AddInstitutionModal
            handleClose={() => {
              return this.hideModal(ADD_INSTITUTION);
            }}
          />
        )}
        {this.state.displayModal[CHANGE_INSTITUTION] && (
          <ChangeInstitutionModal
            handleClose={() => {
              return this.hideModal(CHANGE_INSTITUTION);
            }}
          />
        )}
      </PageContainer>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, {
    recalculateWeightedAvgs,
    push,
  })(AdminContainer)
);
