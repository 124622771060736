import { FILE_UPLOAD_MAX_SIZE_IN_BYTES, fileRestrictionStatus } from '@kritik/constants/activity';
import { assignmentStatuses as STATUSES } from '@kritik/constants/stage';
import * as ActivityUtils from '@kritik/utils/activity';
import { getRestrictedFileStatus, getStudentTopic, isGroupAssignment, isMultipleTopics } from '@kritik/utils/activity';
import * as LateSubmissionUtil from '@kritik/utils/creation/lateCreation';
import * as CreationStatusUtil from '@kritik/utils/creation/status';
import * as generalUtils from '@kritik/utils/general';
import * as ActivityStatusUtil from '@kritik/utils/stage';
import { createAssignmentSubmission } from 'actions/activity';
import DueDateDisplay from 'components/Assignment/DueDateDisplay';
import { mimeTypes } from 'components/Assignment/constants';
import { SubtitleDisplay } from 'components/AssignmentLabels/InfoLabels';
import LoaderOverlay from 'components/Loaders/OverlaySpinner';
import { TranslatedText } from 'components/TranslatedText';
import Button from 'components/buttons/Button';
import Typography from 'components/core/Typography';
import FormContainer from 'components/core/form/Container';
import FormField from 'components/core/form/Field';
import FormRichTextEditor from 'components/core/form/RichTextEditor';
import FormSubmitButtons from 'components/core/form/SubmitButtons';
import FormFieldTitle from 'components/core/form/Title';
import FormCheckbox from 'components/core/input/Checkbox';
import { InlineInformation } from 'components/layout';
import AttachmentManager from 'components/layout/AttachmentManager';
import NoticeBoard from 'components/layout/NoticeBoard';
import Confirm from 'components/modals/ConfirmModal';
import { localize } from 'locales';
import { Component, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { resetUploadStatus } from 'redux/upload';
import { getAssignment } from 'selectors/activity';
import ActivityService from 'services/activity';
import * as localStorage from 'utils/localStorage';

type EditState = any;

const ConfirmModal = ({ onCancel, onConfirm, activityId }) => {
  const [serverActivityStatus, setServerActivityStatus] = useState(null);

  useEffect(() => {
    async function getActivity(activityId: string) {
      const res = await ActivityService().get(activityId);
      setServerActivityStatus(res?.data?.status);
    }

    void getActivity(activityId);
  }, []);

  return (
    <Confirm
      isOpen
      onCancel={onCancel}
      onConfirm={onConfirm}
      cancelButton={localize({ message: 'No' })}
      confirmButton={localize({ message: 'Yes' })}
      testid="confirm-submit"
      title={
        serverActivityStatus === STATUSES.PROCESSING1
          ? localize({ message: 'Creation.IndividualSubmit.Late' })
          : localize({ message: 'Creation.IndividualSubmit' })
      }
    />
  );
};

class Edit extends Component<{}, EditState> {
  localSaving: any;
  constructor(props: {}) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.localSaving = 0;
    this.state = {
      submitting: false,
      content: '',
      reasonForLateSubmission: '',
      fileList: [],
      confirm: false,
      uploadSize: 0,
      editing: false,
      acceptedGroupTerms: false,
    };
  }

  componentDidMount() {
    if ((this.props as any).submission) {
      // @ts-expect-error TS(2339) FIXME: Property 'submission' does not exist on type 'Read... Remove this comment to see the full error message
      const { submission } = this.props;
      this.setState({
        editing: true,
        content: submission.content,
        fileList: submission.files || [],
        uploadSize: submission.files.reduce((size: any, file: any) => (size += file.size), 0),
        reasonForLateSubmission: LateSubmissionUtil.getReasonForLateSubmission(submission) || '',
      });
    }
    const currentContent = localStorage.getText((this.props as any).assignment._id);
    if (currentContent && currentContent.submitted === false) {
      this.setState({ content: currentContent.text });
    }
    this.localSaving = setInterval(() => {
      const content = {
        submitted: false,
        text: this.state.content,
      };
      localStorage.setText((this.props as any).assignment._id, content);
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.localSaving);
  }

  handleFileChange(files: any) {
    const totalSize = files.reduce((sum: any, file: any) => {
      sum += file.size;
      return sum;
    }, 0);

    this.setState({ fileList: files, uploadSize: totalSize });
  }

  onSubmit() {
    if (this.state.submitting) {
      return;
    }

    const data = {
      assignment: (this.props as any).assignment._id,
      content: this.state.content,
      files: this.state.fileList,
    };
    let topic = null;
    if (isMultipleTopics((this.props as any).assignment)) {
      topic = getStudentTopic((this.props as any).assignment, (this.props as any).user);
    }
    if (topic) {
      (data as any).topic = (topic as any)._id;
    }
    if (this.isSubmittingLate()) {
      (data as any).reasonForLateSubmission = this.state.reasonForLateSubmission;
    }

    if (this.state.editing) {
      (data as any).editing = (this.props as any).submission._id;
    }

    (this.props as any)
      .createAssignmentSubmission(data)
      .then(() => {
        this.clearLocalStorage();
        // @ts-expect-error TS(2339) FIXME: Property 'onSubmit' does not exist on type 'Readon... Remove this comment to see the full error message
        this.props.onSubmit();
      })
      .catch((error: any) => {
        if (error.message === 'Content empty.') {
          return this.setState({ submitting: false, error: error.message });
        }
        this.setState({
          submitting: false,
          error: error.message,
        });
      });
  }

  onCancelEdit() {
    this.localStorageOnCancelEdit();
    (this.props as any).resetUploadStatus();
    return (this.props as any).onCancel();
  }

  onChangeInput = (content: any) => {
    this.setState({ content });
  };

  getExtensionsAllowed() {
    const { fileExtensionsAllowed } = (this.props as any).assignment;
    const formats = {
      allowAudio: 'Audio, ',
      allowExcel: 'Excel, ',
      allowImages: 'Images, ',
      allowPDF: 'PDF, ',
      allowPowerpoint: 'Powerpoint, ',
      allowText: 'Text, ',
      allowVideo: 'Video, ',
      allowWord: 'Word (.docx type only), ',
      allowZip: 'Zip, ',
    };
    const extensionsArray: any = [];
    const restrictedFileStatus = getRestrictedFileStatus(fileExtensionsAllowed);
    if (restrictedFileStatus === fileRestrictionStatus.SOMEFILES) {
      (this.props as any).assignment.fileExtensionsAllowed.forEach((extension: any) => {
        for (const key in mimeTypes) {
          if (mimeTypes[key].includes(extension)) {
            if (!extensionsArray.includes(formats[key])) {
              extensionsArray.push(formats[key]);
            }
            continue;
          }
        }
      });
      const extensions = extensionsArray.join(' ');
      return `${localize({ message: 'Creation.AllowedFileTypes' })} ${extensions.slice(0, extensions.length - 2)}.`;
    }
    if (restrictedFileStatus === fileRestrictionStatus.ALL) {
      return <InlineInformation type="attention" title={localize({ message: 'Creation.AttachmentsDisabled' })} />;
    }
  }

  getErrors() {
    if (!this.state.error) {
      return null;
    }
    return <InlineInformation type="danger">{this.state.error}</InlineInformation>;
  }

  localStorageOnCancelEdit = () => {
    localStorage.removeText((this.props as any).assignment._id);
  };

  clearLocalStorage = () => {
    localStorage.removeText((this.props as any).assignment._id);
  };

  toggleFileSelection() {
    document.getElementById('file-upload-input').click();
  }

  isSubmittingLate() {
    return (
      ActivityStatusUtil.isEvaluateOrLater((this.props as any).assignment) &&
      (CreationStatusUtil.isCreationEmpty((this.props as any).submission) ||
        LateSubmissionUtil.isLateSubmissionPending((this.props as any).submission))
    );
  }

  formFilled() {
    const { assignment } = this.props as any;

    if ((this.props as any).assignment.isGroupActivity && !this.state.acceptedGroupTerms) {
      return false;
    }
    if (ActivityUtils.isGroupPresentationActivity(assignment)) {
      return this.state.fileList.length > 0;
    }
    if (this.isSubmittingLate() && this.state.reasonForLateSubmission === '') {
      return false;
    }
    return (
      (this.state.content != '' && this.state.uploadSize <= FILE_UPLOAD_MAX_SIZE_IN_BYTES) ||
      (this.state.uploadSize > 0 && this.state.uploadSize <= FILE_UPLOAD_MAX_SIZE_IN_BYTES)
    );
  }

  renderTopicInfo() {
    // @ts-expect-error TS(2339) FIXME: Property 'assignment' does not exist on type 'Read... Remove this comment to see the full error message
    const { assignment, user } = this.props;
    if (!isMultipleTopics(assignment) || isGroupAssignment(assignment)) {
      return;
    }
    const studentTopic = getStudentTopic(assignment, user);
    if (!studentTopic) {
      return (
        <NoticeBoard title={localize({ message: 'ActivityTopic.Notice' })} type="caution">
          <TranslatedText i18nKey="ActivityTopic.NoneAssigned" />
        </NoticeBoard>
      );
    }
    return (
      <div>
        <SubtitleDisplay text={`Assigned Topic ${(studentTopic as any).name}`} testid="subtitle-display-creation" />
        <div className="creation-editor__topic-display">
          <InlineInformation type="information">
            <TranslatedText i18nKey="ActivityTopic.SeeInstructions" values={{ topic: (studentTopic as any).name }} />
          </InlineInformation>
        </div>
      </div>
    );
  }

  renderLateReason() {
    if (this.isSubmittingLate()) {
      return (
        <FormRichTextEditor
          label={localize({ message: 'LateCreation.Reason.Label' })}
          value={this.state.reasonForLateSubmission}
          onChange={(content: any) => {
            return this.setState({ reasonForLateSubmission: content });
          }}
          testid="late-submission-reason-editor"
        />
      );
    }
    return null;
  }

  getWordCount = () => {
    return generalUtils.getWordCountFromHTML(this.state.content);
  };

  render() {
    const isGroupPresentationActivity = ActivityUtils.isGroupPresentationActivity((this.props as any).assignment);

    const isIndividualPresentationActivity = ActivityUtils.isIndividualPresentationActivity(
      (this.props as any).assignment
    );

    const isPresentationActivity = isGroupPresentationActivity || isIndividualPresentationActivity;
    const renderGroupTerms = ((this.props as any).assignment.isGroupActivity || isGroupPresentationActivity) && (
      <FormField>
        <FormCheckbox
          testid="confirm-submit-for-group"
          onChange={() => {
            return this.setState({ acceptedGroupTerms: !this.state.acceptedGroupTerms });
          }}
          isChecked={this.state.acceptedGroupTerms}
          label={
            <Typography color="light">
              <TranslatedText i18nKey="Creation.GroupSubmit" />
            </Typography>
          }
          type="primary"
        />
      </FormField>
    );

    return (
      <FormContainer>
        <LoaderOverlay color="white" isOpen={this.state.submitting} />
        <FormFieldTitle
          size="lg"
          label={localize({ message: 'Creation.YourCreation' })}
          className="creation-editor__header"
        >
          <div className="creation-editor__header-time">
            <DueDateDisplay assignment={(this.props as any).assignment} isSubmitting />
          </div>
        </FormFieldTitle>
        {this.renderTopicInfo()}
        {!isPresentationActivity && (
          <FormRichTextEditor
            testid="creation-editor"
            value={this.state.content}
            onChange={(content: any) => {
              return this.onChangeInput(content);
            }}
          />
        )}
        {!isPresentationActivity && (
          <p>
            <TranslatedText
              i18nKey="Creation.Feedback.Edit.WordCount"
              values={{ wordCount: this.getWordCount().toString() }}
            />
          </p>
        )}
        {isPresentationActivity && (this.props as any).assignment.requireFile && (
          <InlineInformation
            type="warning"
            testid="required-file-submission-presentation"
            title={localize({
              message: 'Activity.Presentation.StudentView.SubmissionRequired.Title',
            })}
          >
            {localize({ message: 'Activity.Presentation.StudentView.SubmissionRequired' })}
          </InlineInformation>
        )}
        {isPresentationActivity && !(this.props as any).assignment.requireFile && (
          <span className="optional-submission" data-testid="optional-file-submission-presentation">
            {localize({
              message: 'Activity.Presentation.StudentView.SubmissionOptional',
            })}
          </span>
        )}
        <AttachmentManager
          onFileChange={(newList: any) => {
            return this.handleFileChange(newList);
          }}
          fileList={this.state.fileList}
          allowedExtensions={
            getRestrictedFileStatus((this.props as any).assignment.fileExtensionsAllowed) !== fileRestrictionStatus.ALL
              ? (this.props as any).assignment.fileExtensionsAllowed
              : false
          }
        />
        {this.getExtensionsAllowed()}
        {this.renderLateReason()}

        <FormSubmitButtons errors={this.getErrors()}>
          {renderGroupTerms}
          <Button
            type={!this.formFilled() ? '' : 'primary'}
            disabled={!this.formFilled()}
            onClick={() => {
              this.setState({ confirm: true });
            }}
            data-testid="submit-creation"
            className="submit-creation-button"
          >
            {localize({ message: 'SubmitAssignment' })}
          </Button>
          {(this.props as any).submission && !CreationStatusUtil.isCreationEmpty((this.props as any).submission) && (
            <Button
              className={`${(this.props as any).onCancel ? '' : 'hidden'}`}
              type="secondary"
              onClick={() => {
                return this.onCancelEdit();
              }}
              style={{ marginLeft: '5px' }}
            >
              <TranslatedText i18nKey="Cancel" />
            </Button>
          )}
        </FormSubmitButtons>
        {this.state.confirm && (
          <ConfirmModal
            onCancel={() => {
              return this.setState({ confirm: false });
            }}
            onConfirm={() => {
              // @ts-expect-error TS(2345) FIXME: Argument of type 'void' is not assignable to param... Remove this comment to see the full error message
              return this.setState({ submitting: true, confirm: false }, this.onSubmit());
            }}
            activityId={(this.props as any).assignment}
          />
        )}
      </FormContainer>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    assignment: getAssignment(state, state.selected.assignmentId),
    user: state.user,
  };
};

export default connect(mapStateToProps, {
  createAssignmentSubmission,
  resetUploadStatus,
})(Edit);
