import { EVAL_FLAGS } from '@kritik/utils/creation/status';
import { EvaluationScoreUtil } from '@kritik/utils/grade';
import FlagIcon from '@material-ui/icons/Flag';
import { createEvaluationFlag } from 'actions/activity';
import { TranslatedText } from 'components/TranslatedText';
import Button from 'components/buttons/Button';
import Confirm from 'components/modals/ConfirmModal';
import { localize } from 'locales';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { getErrorMessageFromResponse } from 'utils/error';

const NotFlagged = (props: any) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const [error, setError] = useState('');

  const flagComment = () => {
    if (!isSubmitting) {
      setIsSubmitting(true);
      props
        .createEvaluationFlag({
          submissionId: props.creation._id,
          scoreId: props.evaluation._id,
          flag: EVAL_FLAGS.FLAGGED,
        })
        .then(() => {
          setIsSubmitting(false);
          setIsConfirming(false);
        })
        .catch((err) => {
          setIsSubmitting(false);
          setError(getErrorMessageFromResponse(err));
        });
    }
  };

  return (
    <React.Fragment>
      <Button
        type="secondary"
        data-testid="report-evaluation"
        onClick={() => {
          setIsConfirming(true);
        }}
        disabled={EvaluationScoreUtil.isEvaluationRemoved(props.evaluation)}
        className="report-evaluation-button"
      >
        <FlagIcon fontSize="small" /> <TranslatedText i18nKey="Creation.Flag.Report" />
      </Button>
      <Confirm
        isOpen={isConfirming}
        onCancel={() => {
          setIsConfirming(false);
          setError('');
        }}
        onConfirm={() => {
          flagComment();
        }}
        cancelButton={localize({ message: 'Common.No' })}
        confirmButton={localize({ message: 'Common.Yes' })}
        description={<TranslatedText i18nKey="Creation.Flag.Content" />}
        title={<TranslatedText i18nKey="Creation.Flag.Title" />}
        error={error}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state: any) => {
  return {};
};

export default connect(mapStateToProps, {
  createEvaluationFlag,
})(NotFlagged);
