import * as ActivityUtils from '@kritik/utils/activity';
import * as GeneralUtils from '@kritik/utils/general';
import Tooltip from '@material-ui/core/Tooltip';
import HtmlContent from 'components/General/HtmlContent';
import { TranslatedText } from 'components/TranslatedText';
import FileList from 'components/layout/AttachmentManager/FileList';
import { localize } from 'locales';
import { connect } from 'react-redux';
import { getAssignment } from 'selectors/activity';
import GroupSelector from 'selectors/group';
import StudentSelector from 'selectors/student';

function CreationDisplay({ creation, myGroup, activity }: any) {
  return (
    <div data-testid="creation-display">
      <WordCount
        creation={creation}
        myGroup={myGroup}
        isGroupPresentationActivity={ActivityUtils.isGroupPresentationActivity(activity)}
      />
      <FileCount files={creation.files} />
      <HtmlContent content={creation.content} />
      <FileList
        files={creation.files}
        isGroupPresentationActivity={ActivityUtils.isGroupPresentationActivity(activity)}
      />
    </div>
  );
}

const WordCount = ({ creation, myGroup, isGroupPresentationActivity }) => {
  const wordCount = GeneralUtils.getWordCountFromHTML(creation.content);
  const getGroupName = () => {
    if (isGroupPresentationActivity) {
      if (!myGroup || myGroup._id !== creation.group._id) {
        return creation.group?.name || '';
      }
      return myGroup.name;
    }
    if (!myGroup || !creation.group || myGroup._id !== creation.group._id) {
      return '';
    }
    return `${myGroup.name} - `;
  };
  return (
    <Tooltip title={localize({ message: 'Tooltip.WordCount' })} placement="top">
      <span className="submission-view__word-count">
        {isGroupPresentationActivity
          ? getGroupName()
          : localize({
              message: 'CreationDisplay.WordCount.Words',
              options: { count: wordCount, groupName: getGroupName() },
            })}
      </span>
    </Tooltip>
  );
};

const FileCount = ({ files }) => {
  const fileCount = files.length;
  if (fileCount === 0) {
    return <></>;
  }
  return (
    <Tooltip title={localize({ message: 'Tooltip.FileCount' })} placement="top">
      <span className="submission-view__word-count">
        <TranslatedText i18nKey="CreationDisplay.FileCount" values={{ count: fileCount }} />
      </span>
    </Tooltip>
  );
};

const mapStateToProps = (state: any) => {
  const myStudent = StudentSelector.getStudentFromUser(state, state.selected.courseId);
  const activityId = state.selected.assignmentId;
  const myGroup = myStudent && GroupSelector.getGroupOfStudent(state, activityId, myStudent._id);
  return {
    myGroup,
    activity: getAssignment(state, activityId),
  };
};

export default connect(mapStateToProps, {})(CreationDisplay);
