import { AuthUser } from 'app-types';
import CreateActivityAI from 'components/CreateActivityAI';
import { localize } from 'locales';
import Page from 'pages/Page';
import { Component } from 'react';
import { connect } from 'react-redux';
import { selectAuthUser } from 'selectors/user';
import { RouterProp, withRouter } from 'utils/withRouter';

type Props = {
  router: RouterProp;
  authUser: AuthUser;
};
class CreateActivityAIPage extends Component<Props> {
  getMetaData() {
    return {
      title: this.pageTitle(),
      meta: this.pageMeta(),
      link: this.pageLink(),
      script: this.pageScript(),
    };
  }

  pageTitle = () => {
    return localize({ message: 'Activity.CreateWithAi.PageTitle' });
  };

  pageMeta = () => {
    return [];
  };

  pageLink = () => {
    return [];
  };

  pageScript = () => {
    return [];
  };

  render() {
    return (
      <Page {...this.getMetaData()}>
        <CreateActivityAI router={this.props.router} authUser={this.props.authUser} />
      </Page>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    authUser: selectAuthUser(state),
  };
}

export default withRouter(connect(mapStateToProps, {})(CreateActivityAIPage));
