import { TranslatedText } from 'components/TranslatedText';
import Button from 'components/buttons/Button';
import ButtonContainer from 'components/buttons/ButtonContainer';
import ButtonWithSelect from 'components/buttons/ButtonWithSelect';
import { localize } from 'locales';
import { connect } from 'react-redux';
import { setIsEditingTemplate } from 'redux/rubricManager/actions';

const OverviewMenu = (props: any) => {
  const hasDifferentCoreAction = () => {
    return !!props.coreAction;
  };

  const createOptions = () => {
    const options = [
      {
        label: <TranslatedText i18nKey="OverviewMenu.Copy" />,
        value: 'Copy',
        testid: 'copy-rubric',
      },
    ];

    if (!props.isDefaultRubric) {
      options.push({
        label: <TranslatedText i18nKey="OverviewMenu.Delete" />,
        value: 'Delete',
        testid: 'delete-rubric_button',
      });
    }

    if (hasDifferentCoreAction()) {
      options.push({
        label: <TranslatedText i18nKey="OverviewMenu.Edit" />,
        value: 'Edit',
        testid: 'edit-rubric',
      });
    }

    return options;
  };

  const handleActionChange = (e: any) => {
    const selectedAction = e.target.value;
    handleAction(selectedAction);
  };

  const handleAction = (actionKey: any) => {
    switch (actionKey) {
      case 'Delete':
        return props.onDeleteRubric();
      case 'Copy':
        return props.onCopyRubric();
      case 'Edit':
        return props.setIsEditingTemplate(true);
      default:
        return;
    }
  };

  const renderCoreAction = () => {
    if (hasDifferentCoreAction()) {
      return (
        <Button
          type="primary"
          data-testid={props.coreAction.testid}
          onClick={() => props.coreAction.action(props.rubric)}
        >
          {props.coreAction.label}
        </Button>
      );
    }
    return (
      <Button data-testid="edit-rubric" type="primary" onClick={() => handleAction('Edit')} disabled={props.disabled}>
        <TranslatedText i18nKey="OverviewMenu.Edit" />
      </Button>
    );
  };

  return (
    <ButtonContainer>
      <ButtonWithSelect
        options={createOptions()}
        onChange={handleActionChange}
        title={localize({ message: 'OverviewMenu.MoreActions' })}
        disabled={props.disabled}
        type="secondary"
        testid="rubric_see-more-actions"
      />
      {renderCoreAction()}
    </ButtonContainer>
  );
};

const mapStateToProps = (state: any) => {
  return {};
};

OverviewMenu.defaultProps = {
  isDefaultRubric: false,
  disabled: false,
  coreAction: null,
};

export default connect(mapStateToProps, { setIsEditingTemplate })(OverviewMenu);
