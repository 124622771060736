import { CreateCard } from 'components/cards';
import { localize } from 'locales';
import { connect } from 'react-redux';
import { addGroup, selectCurrentGroupSet } from 'redux/groupManager';

function AddGroupCard({ groupSet, addGroup, style }: any) {
  const handleOnClick = () => {
    addGroup(groupSet._id);
  };
  return (
    <div className="add-group-card" style={style}>
      <CreateCard text={localize({ message: 'RosterManager.GroupTab.AddGroup' })} onClick={handleOnClick} />
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    groupSet: selectCurrentGroupSet(state),
  };
};

export default connect(mapStateToProps, { addGroup })(AddGroupCard);
