import { EvaluationScoreUtil, GradeHistoryUtil, OverallScoreUtil, WrittenEvalScoreUtil } from '@kritik/utils/grade';
import * as AssignmentStatusUtils from '@kritik/utils/stage';

export const getPrevKritikScore = (currentScore: any) => {
  if (currentScore >= 6000) {
    return 6000;
  }
  if (currentScore >= 5000) {
    return 5000;
  }
  if (currentScore >= 4000) {
    return 4000;
  }
  if (currentScore >= 3000) {
    return 3000;
  }
  if (currentScore >= 2000) {
    return 2000;
  }
  return 1000;
};

export const getNewKS = (student: any) => {
  if (!student) {
    return 0;
  }
  return student.score;
};

export const getKritikScoreLevel = (kritikScore: any) => {
  if (kritikScore > 6000) {
    return 6;
  }
  if (kritikScore >= 5000) {
    return 5;
  }
  if (kritikScore >= 4000) {
    return 4;
  }
  if (kritikScore >= 3000) {
    return 3;
  }
  if (kritikScore >= 2000) {
    return 2;
  }
  return 1;
};

export const wasStudentEnrolledLate = (assignment: any, student: any) => {
  const latestDate = AssignmentStatusUtils.getEvaluationStage(assignment).startDate;
  const enrollmentDate = student.createdAt;
  const isLateEnrollment = enrollmentDate > latestDate;
  return isLateEnrollment;
};

/**
 * Returns true if student enrolled during or after feedback the feedback stage
 * @param {Object} activity
 * @param {Object} student
 * @returns {Boolean}
 */
export const wasStudentEnrolledInFeedbackStageOrLater = (activity: any, student: any) => {
  const feedbackStartDate = AssignmentStatusUtils.getFeedbackStage(activity).startDate;
  const enrollmentDate = student.createdAt;
  const isEnrolledInFeedbackStage = enrollmentDate > feedbackStartDate;
  return isEnrolledInFeedbackStage;
};

export const getCreationScoreFromHistory = (student: any, activityId: any) => {
  const history = student.gradeHistories;
  if (history && history.length > 0) {
    const gradeHistoryElem = history.find((element: any) => {
      return element.assignment === activityId;
    });
    if (gradeHistoryElem) {
      return GradeHistoryUtil.getCreationScoreFromGradeHistory(gradeHistoryElem);
    }
  }
  return 0;
};

export const getFeedbackScoreFromHistory = (student: any, activityId: any) => {
  const history = student.gradeHistories;
  if (history && history.length > 0) {
    const gradeHistoryElem = history.find((element: any) => {
      return element.assignment === activityId;
    });
    if (gradeHistoryElem) {
      return gradeHistoryElem.feedback;
    }
  }
  return 0;
};

export const getGradingScoreFromHistory = (student: any, activityId: any) => {
  const history = student.gradeHistories;
  if (history && history.length > 0) {
    const gradeHistoryElem = history.find((element: any) => {
      return element.assignment === activityId;
    });
    if (gradeHistoryElem) {
      return gradeHistoryElem.grading;
    }
  }
  return 0;
};

export const getGradingScoresFromHistory = (student: any, activityId: any) => {
  const history = student.gradeHistories;
  const gradeScores = {
    creation: 0,
    writtenEvaluation: 0,
    feedback: 0,
    grading: 0,
  };
  if (history && history.length > 0) {
    const gradeHistoryElem = history.find((element: any) => {
      return element.assignment === activityId;
    });
    if (gradeHistoryElem) {
      gradeScores.creation = GradeHistoryUtil.getCreationScoreFromGradeHistory(gradeHistoryElem);
      gradeScores.writtenEvaluation = gradeHistoryElem.writtenEvaluation;
      gradeScores.feedback = gradeHistoryElem.feedback;
      gradeScores.grading = gradeHistoryElem.grading;
    }
  }
  return gradeScores;
};

export const getEvaluationScoreByActivity = (student: any, activity: any, course: any) => {
  const gradingScore = getGradingScoreFromHistory(student, activity._id);
  // @ts-expect-error TS(2345) FIXME: Argument of type '{ student: any; activityId: any;... Remove this comment to see the full error message
  const writtenEvalScore = WrittenEvalScoreUtil.getFeedbackSkillsChange({
    student,
    activityId: activity._id,
  });
  const evaluationScore = EvaluationScoreUtil.evaluatorOverallScoreSingleActivity(
    gradingScore,
    writtenEvalScore,
    course,
    activity
  );
  return evaluationScore;
};

export const getOverallScoreByActivity = (student: any, activity: any, course: any) => {
  const gradingScores = getGradingScoresFromHistory(student, activity._id) as GradeHistory;
  const overallScore = OverallScoreUtil.calculateOverallScore(gradingScores, course);
  return overallScore;
};

export const getNotificationSettings = (student: any) => {
  return student.notificationSettings;
};
export const getStudentActivityScoreHistory = (scoreHistories: any, activityId: any) => {
  return scoreHistories.find((scoreHistory: any) => {
    return (scoreHistory.assignment || scoreHistory.assignment._id) === activityId;
  });
};
