import { loadingBarReducer } from 'react-redux-loading-bar';
import { combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';
import { responsiveStateReducer } from 'redux-responsive';

import assignment from 'reducers/activity';
import admin from 'reducers/admin';
import async from 'reducers/async';
import course from 'reducers/course';
import creationsToEvaluate from 'reducers/creationsToEvaluate';
import entities from 'reducers/entities';
import home from 'reducers/home';
import message from 'reducers/message';
import modal from 'reducers/modal';
import rubric from 'reducers/rubric';
import selected from 'reducers/selected';
import user from 'reducers/user';
import activity from 'redux/activity/reducer';
import activityEdit from 'redux/activityEdit';
import activityImport from 'redux/activityImport/reducer';
import activityScores from 'redux/activityScores/reducer';
import creation from 'redux/creation';
import creationTable from 'redux/creationTable/reducer';
import edlink from 'redux/edlink';
import evaluation from 'redux/evaluation/reducer';
import gradebook from 'redux/gradebook/reducer';
import group from 'redux/group/reducer';
import groupManager from 'redux/groupManager';
import lms from 'redux/lms';
import rubricManager from 'redux/rubricManager/reducer';
import schedule from 'redux/schedule/reducer';
import spotlight from 'redux/spotlight';
import spotlightTag from 'redux/spotlightTag';
import topic from 'redux/topic';
import upload from 'redux/upload';
import * as types from 'types';

const isFetching = (state = false, action: any) => {
  switch (action.type) {
    case types.CREATE_REQUEST:
      return true;
    case types.REQUEST_SUCCESS:
    case types.REQUEST_FAILURE:
      return false;
    default:
      return state;
  }
};

const accessibilityMessage = (state = { message: '' }, action: any) => {
  switch (action.type) {
    case types.SET_ACCESSIBILITY_MESSAGE:
      if (action.payload && action.payload.message) {
        return action.payload;
      }
      return state;
    default:
      return state;
  }
};

// Combine reducers with routeReducer which keeps track of
// router state
const createRootReducer = () =>
  combineReducers({
    admin,
    accessibilityMessage,
    assignment,
    browser: responsiveStateReducer,
    course,
    creationsToEvaluate,
    edlink,
    entities,
    form: reduxFormReducer,
    home,
    isFetching,
    loadingBar: loadingBarReducer,
    message,
    modal,
    rubric,
    user,
    selected,
    async,
    creation,
    creationTable,
    groupManager,
    activityEdit,
    activityImport,
    lms,
    topic,
    spotlight,
    spotlightTag,
    gradebook,
    upload,
    schedule,
    rubricManager,
    activity,
    activityScores,
    group,
    evaluation,
  });

export default createRootReducer;
