import { updateEntities } from 'actions/entities';
import errorAction from 'actions/error';
import { normalize } from 'normalizr';
import { courseSchema } from 'schemas';
import { courseService } from 'services';

const REMOVE_STUDENT_INVITE = 'REMOVE_STUDENT_INVITE';
const REMOVE_STUDENT_INVITE_SUCCESS = 'REMOVE_STUDENT_INVITE_SUCCESS';
const REMOVE_STUDENT_INVITE_FAILURE = 'REMOVE_STUDENT_INVITE_FAILURE';

export function removeStudentInvite({ courseId, studentEmail }: any) {
  return (dispatch: any, getState: any) => {
    dispatch({ type: REMOVE_STUDENT_INVITE });
    courseService()
      .removeStudentInvite({ courseId, studentEmail })
      .then((res: any) => {
        const normalized = normalize(res.data, courseSchema);
        dispatch(updateEntities(normalized.entities));
        dispatch({ type: REMOVE_STUDENT_INVITE_SUCCESS });
      })
      .catch((err: any) => {
        dispatch(errorAction(REMOVE_STUDENT_INVITE_FAILURE, err));
      });
  };
}
