import InlineInformation from 'components/layout/InlineInformation';
import { localize } from 'locales';

const UpdateEmailNotice = ({ isInstructor }: any) => {
  const title = isInstructor
    ? localize({ message: 'UpdateEmailNotice.Title.Instructor' })
    : localize({ message: 'UpdateEmailNotice.Title.Student' });
  const text = isInstructor
    ? localize({ message: 'UpdateEmailNotice.Text.Instructor' })
    : localize({ message: 'UpdateEmailNotice.Text.Student' });
  return (
    <InlineInformation title={title} className="account-settings__sso">
      {text}
    </InlineInformation>
  );
};

export default UpdateEmailNotice;
