import CARD_IMAGES from 'images/credit-cards';

const AcceptedCreditCards = () => {
  return (
    <div className="accepted-credit-card__container">
      <img className="accepted-credit-card__icon" alt="Mastercard" src={CARD_IMAGES.MASTERCARD} />
      <img className="accepted-credit-card__icon" alt="American Express" src={CARD_IMAGES.AMEX} />
      <img className="accepted-credit-card__icon" alt="Visa" src={CARD_IMAGES.VISA} />
    </div>
  );
};

export default AcceptedCreditCards;
