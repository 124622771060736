import { Card, CardContent, Typography } from '@material-ui/core';
import { clearDuplicateError, createCourse } from 'actions/courses';
import { AuthUser } from 'app-types';
import CreateCourseModal from 'components/Course/CreateCourseModal';
import CourseCard from 'components/Course/List/CourseCard';
import { TranslatedText } from 'components/TranslatedText';
import { CreateCourseAICard } from 'components/cards';
import CreateCard from 'components/cards/CreateCard';
import LoadingCard from 'components/cards/LoadingCard';
import EmptyPlaceholder from 'components/layout/EmptyPlaceholder';
import NoticeBoard from 'components/layout/NoticeBoard';
import { useIsFeatureFlagEnabled } from 'context/growthbookContext';
import { createCourseMutation, useFetchCourses } from 'hooks/course';
import MAIL_IMAGE from 'images/mail-icon.svg';
import { localize } from 'locales';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouterProp } from 'utils/withRouter';

const COURSE_DEFAULTS = {
  department: 'Other',
  courseSettings: { hideAverageGrade: true },
};

type CourseListProps = {
  authUser: AuthUser;
  duplicateLoading: boolean;
  duplicateError: any;
  clearDuplicateError: any;
  createCourse: any;
  router: RouterProp;
};

function CourseList(props: CourseListProps) {
  const [showCourseCreateDialog, setShowCourseCreateDialog] = useState(false);

  if (showCourseCreateDialog !== Boolean(props.router.location.query['create-course-modal'])) {
    setShowCourseCreateDialog(Boolean(props.router.location.query['create-course-modal']));
  }

  const { data: courses } = useFetchCourses();
  const createCourse = createCourseMutation(props.authUser);
  const isAiActivityCreationEnabled = useIsFeatureFlagEnabled('create_activity_ai');
  const canCreateCourse = props.authUser?.permissions?.includes('canCreateCourse');

  useEffect(() => {
    if (createCourse.isSuccess) {
      closeCourseCreateDialog();
      if (isAiActivityCreationEnabled) {
        props.router.push(`/course/${createCourse.data._id}/create-activity-ai`);
      }
    }
  }, [createCourse.isSuccess]);

  const openCourseCreateDialog = () => {
    props.router.push(`/?create-course-modal=true`);
  };

  const closeCourseCreateDialog = () => {
    props.router.push(`/`);
  };

  const handleCourseCreate = (data: any) => {
    createCourse.mutate({ ...data, ...COURSE_DEFAULTS });
  };

  const renderActiveCourses = (activeCourses: any) => {
    if (!canCreateCourse && activeCourses.length === 0) {
      return (
        <div className="course-list__empty-banner">
          <EmptyPlaceholder image={MAIL_IMAGE} title={localize({ message: 'CourseList.EmptyBanner.Title' })}>
            <p>
              <TranslatedText i18nKey="CourseList.EmptyBanner.Description" />
            </p>
            <h6>
              <TranslatedText i18nKey="CourseList.EmptyBanner.Question" />
            </h6>
            <p>
              <TranslatedText i18nKey="CourseList.EmptyBanner.Explanation" />
            </p>
          </EmptyPlaceholder>
        </div>
      );
    }

    return (
      <div className="course-list">
        {activeCourses.map((course: any) => {
          return (
            <div className="course-list_item" key={course._id} data-testid={`course-item-${course._id}`}>
              <CourseCard course={course} />
            </div>
          );
        })}
        {props.duplicateLoading && <LoadingCard />}

        {canCreateCourse && isAiActivityCreationEnabled && <CreateCourseAICard />}
        {canCreateCourse && !isAiActivityCreationEnabled && (
          <CreateCard text={localize({ message: 'CourseList.NewCourse' })} onClick={openCourseCreateDialog} />
        )}
      </div>
    );
  };

  const renderActiveSection = (courses: any) => {
    return (
      <div>
        <h2 className="new-header-2 course-list__header">
          <TranslatedText i18nKey="CourseDashboard.Active" />
        </h2>
        {renderActiveCourses(courses)}
        {props.duplicateError && (
          <NoticeBoard
            title={localize({ message: 'CourseList.Error' })}
            type="danger"
            closeable
            onClose={() => {
              props.clearDuplicateError();
            }}
          >
            {props.duplicateError}
          </NoticeBoard>
        )}
      </div>
    );
  };

  const renderArchivedSection = (archivedCourses: any) => {
    if (archivedCourses.length === 0) {
      return;
    }
    return (
      <div>
        <h2 className="new-header-2 course-list__header" data-testid="archived">
          <TranslatedText i18nKey="CourseDashboard.Archived" />
        </h2>
        <div className="course-list">
          {archivedCourses
            .sort((a: any, b: any) => {
              return new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime();
            })
            .map((course: any) => {
              return (
                <div className="course-list_item" key={course._id}>
                  <CourseCard course={course} />
                </div>
              );
            })}
        </div>
      </div>
    );
  };

  const renderCourseList = () => {
    const archivedCourses: any = [];
    const activeCourses: any = [];

    courses.forEach((course: any) => {
      if (course.completed) {
        archivedCourses.push(course);
      } else {
        activeCourses.push(course);
      }
    });

    return (
      <div className="course-list__wrapper">
        {renderActiveSection(activeCourses)}
        {renderArchivedSection(archivedCourses)}
        {showCourseCreateDialog && (
          <CreateCourseModal
            open={showCourseCreateDialog}
            onSubmit={handleCourseCreate}
            onClose={closeCourseCreateDialog}
          />
        )}
      </div>
    );
  };

  if (!courses) {
    return (
      <Card>
        <CardContent className="text-center">
          <Typography align="center">{localize({ message: 'Post.Loading' })}</Typography>
        </CardContent>
      </Card>
    );
  }

  return <div>{renderCourseList()}</div>;
}

function mapStateToProps(state: any) {
  return {
    authUser: state.user.authUser,
    duplicateLoading: state.course.courseDuplicate.loading,
    duplicateError: state.course.courseDuplicate.error,
  };
}

CourseList.defaultProps = {
  courses: [],
  duplicateLoading: false,
  duplicateError: null,
};

export default connect(mapStateToProps, { clearDuplicateError, createCourse })(CourseList);
