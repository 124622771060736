import { createRestApiClient } from 'utils/createRestApiClient';

const apiEndpoint = import.meta.env.VITE_API_URL;

export default (options = {}) => {
  const client = (options as any).client || createRestApiClient().withConfig({ baseURL: apiEndpoint });
  return {
    createActivity: (params: any) => {
      return client.request({
        method: 'POST',
        url: `/ai/create-activity`,
        data: params,
      });
    },
    createCourse: (params: any) => {
      return client.request({
        method: 'POST',
        url: `/ai/create-course`,
        data: params,
      });
    },
    generateActivityIdeas: (
      params: ai.generate_activity_ideas.POST.Request
    ): Promise<DataResponse<ai.generate_activity_ideas.POST.Response>> => {
      return client.request({
        method: 'POST',
        url: `/ai/generate-activity-ideas`,
        data: params,
      });
    },
    createRubric: (params: any) => {
      return client.request({
        method: 'POST',
        url: `/ai/create-rubric`,
        data: params,
      });
    },
  };
};
