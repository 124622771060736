import classNames from 'classnames';

const OverviewSection = ({ title, children, hideSeparator }: any) => {
  const classes = classNames('activity-overview__section');
  const titleClass = classNames('activity-overview__section-title', {
    'activity-overview__section--seamless': hideSeparator,
  });
  return (
    <div className={classes}>
      <h3 className="visually-hidden">{title}</h3>
      <div className={titleClass}>{title}</div>
      {children}
    </div>
  );
};

OverviewSection.defaultProps = {
  hideSeparator: false,
  children: null,
};

export default OverviewSection;
