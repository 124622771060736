import { TranslatedText } from 'components/TranslatedText';

export const EmailList = ({ added, removed }) => {
  const requireHeading = [added, removed].filter((array) => array.length > 0).length > 1;
  if (!added.length && !removed.length) {
    return null;
  }

  return (
    <div className="sync-roster-modal__email-list">
      {Boolean(added.length) && (
        <>
          {requireHeading && <TranslatedText i18nKey="RosterManager.SyncRosterPopup.EmailList.Adding" />}
          <div className="sync-roster-modal__email-list-section">
            {added.map(({ email }) => (
              <div>{email}</div>
            ))}
          </div>
        </>
      )}
      {Boolean(removed.length) && (
        <>
          {requireHeading && <TranslatedText i18nKey="RosterManager.SyncRosterPopup.EmailList.Removing" />}
          <div className="sync-roster-modal__email-list-section">
            {removed.map(({ email }) => (
              <div>{email}</div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default EmailList;
