import classNames from 'classnames';

const VerticalItem = ({ children }: any) => {
  return <div className="vertical-list__item">{children}</div>;
};

const VerticalList = (props: any) => {
  const classes = classNames('vertical-list', {
    'vertical-list--xs': props.space === 'xs',
    'vertical-list--sm': props.space === 'sm',
    'vertical-list--md': props.space === 'md',
    'vertical-list--default': props.space === 'default',
    'vertical-list--lg': props.space === 'lg',
  });

  return (
    <div className={classes}>
      {props.itemList.map((item: any, index: number) => {
        return <VerticalItem key={index}>{item}</VerticalItem>;
      })}
    </div>
  );
};

VerticalList.defaultProps = {
  itemList: [],
  space: 'sm',
};

export default VerticalList;
