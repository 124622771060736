import { isCalibrationActivity } from '@kritik/utils/activity';
import { EvaluationScoreUtil } from '@kritik/utils/grade';

const getEvaluationScores = ({ gradeHistory, activity, course, student, creationsToEvaluate, creation }: any) => {
  if (!gradeHistory) {
    if (isCalibrationActivity(activity)) {
      const evalScores = EvaluationScoreUtil.calcScore({
        activity,
        course,
        studentId: student._id,
        creationsToEvaluate,
        studentCreation: creation,
      });
      return evalScores;
    }
    return {
      grading: 0,
      writtenEvaluation: 0,
      evaluation: 0,
    };
  }
  const { writtenEvaluation, grading } = gradeHistory;

  const evaluation = EvaluationScoreUtil.evaluatorOverallScoreSingleActivity(
    grading,
    writtenEvaluation,
    course,
    activity
  );

  return {
    grading,
    writtenEvaluation,
    evaluation,
  };
};

export default {
  getEvaluationScores,
};
