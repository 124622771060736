import * as LateSubmissionUtil from '@kritik/utils/creation/lateCreation';
import { TranslatedText } from 'components/TranslatedText';
import ModalAction from 'components/modals/Components/Action';
import ModalContent from 'components/modals/Components/Content';
import ModalTitle from 'components/modals/Components/Header';
import Modal from 'components/modals/Components/Modal';
import { localize } from 'locales';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getNumEligibleEvaluatorsByActivityId } from 'redux/lateCreation';
import CreationSelector from 'selectors/creation';

const ResolutionModal = (props: any) => {
  const [numEligibleEvaluators, setNumEligibleEvaluators] = useState(0);
  const findNumEligibleEvaluators = () => {
    props
      .getNumEligibleEvaluatorsByActivityId({
        activityId: props.activityId,
        creationId: props.creationId,
      })
      .then(({ success }: any) => {
        if (success) {
          setNumEligibleEvaluators(success);
        }
      });
  };

  const isManualGradingOnly = LateSubmissionUtil.isLateSubmissionRejected(props.creation);

  const getActions = () => {
    const actions = [];
    if (!isManualGradingOnly) {
      actions.push({
        label: localize({ message: 'ResolutionModal.AssignEvaluators' }),
        testid: 'assign-evaluators',
        type: 'primary',
        isDisabled: numEligibleEvaluators === 0,
        isUnavailable: numEligibleEvaluators === 0,
        onClick: () => {
          return props.onResolveLateCreation();
        },
      });
    }

    actions.push({
      label: localize({ message: 'ResolutionModal.ScoreManually' }),
      type: 'secondary',
      onClick: () => {
        return props.onScoreManually();
      },
    });

    actions.push({
      label: localize({ message: 'ResolutionModal.Cancel' }),
      type: 'secondary',
      onClick: () => {
        return props.onCancel();
      },
    });

    actions[0].type = 'primary';

    return actions;
  };
  useEffect(() => {
    if (props.isOpen && props.activityId) {
      findNumEligibleEvaluators();
    }
  }, [props.isOpen]);

  const text = {
    title: 'ResolutionModal.Title',
    text_manual_grading: 'ResolutionModal.TextManualGrading',
    text_evaluator_or_manual_grading: 'ResolutionModal.TextEvaluatorOrManualGrading',
  } as const;

  return (
    <Modal isOpen={props.isOpen}>
      <ModalTitle title={<TranslatedText i18nKey={text.title} />} />
      <ModalContent>
        <div className="late-submission-modal__text">
          <TranslatedText
            i18nKey={!isManualGradingOnly ? text.text_evaluator_or_manual_grading : text.text_manual_grading}
          />
        </div>
        {!isManualGradingOnly && (
          <div className="late-submission-modal__evaluators">
            <TranslatedText i18nKey="ResolutionModal.EligibleEvaluators" values={{ count: numEligibleEvaluators }} />
          </div>
        )}
      </ModalContent>
      <ModalAction actions={getActions()} />
    </Modal>
  );
};

ResolutionModal.defaultProps = {
  isOpen: false,
};

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    creation: CreationSelector.getSubmission(state, ownProps.creationId),
  };
};

export default connect(mapStateToProps, { getNumEligibleEvaluatorsByActivityId })(ResolutionModal);
