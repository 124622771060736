import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import * as React from 'react';

export const useStyles = makeStyles({
  root: (props) => ({
    userSelect: 'none',
    width: (props as any).width,
    height: (props as any).height,
    display: 'inline-block',
    fill: (props as any).fill,
    flexShrink: 0,
    fontSize: (props as any).fontSize,
  }),
});

const SvgIcon = React.forwardRef(function SvgIcon(props, ref) {
  const {
    // @ts-expect-error TS(2339) FIXME: Property 'children' does not exist on type '{}'.
    children,
    // @ts-expect-error TS(2339) FIXME: Property 'className' does not exist on type '{}'.
    className,
    // @ts-expect-error TS(2339) FIXME: Property 'component' does not exist on type '{}'.
    component: Component = 'svg',
    // @ts-expect-error TS(2339) FIXME: Property 'fontSize' does not exist on type '{}'.
    fontSize,
    // @ts-expect-error TS(2339) FIXME: Property 'htmlColor' does not exist on type '{}'.
    htmlColor,
    // @ts-expect-error TS(2339) FIXME: Property 'titleAccess' does not exist on type '{}'... Remove this comment to see the full error message
    titleAccess,
    // @ts-expect-error TS(2339) FIXME: Property 'viewBox' does not exist on type '{}'.
    viewBox,
    ...other
  } = props;

  const classes = useStyles(props);

  return (
    <Component
      className={classNames(classes.root, (props as any).className)}
      focusable="false"
      viewBox={viewBox}
      ref={ref}
      {...other}
    >
      {children}
      {titleAccess ? <title>{titleAccess}</title> : null}
    </Component>
  );
});

SvgIcon.defaultProps = {
  // @ts-expect-error TS(2322) FIXME: Type '{ height: string; width: string; fill: strin... Remove this comment to see the full error message
  height: '1em',
  width: '1em',
  fill: 'none',
  viewBox: '0 0 24 24',
  fontSize: '1em',
};

(SvgIcon as any).muiName = 'SvgIcon';

export default SvgIcon;
