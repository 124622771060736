import { denormalize } from 'normalizr';
import { User } from 'old-common/types.generated';
import { userSchema } from 'schemas';
import { selectCurrentCourse } from './course';
import { getStudent } from './student';

export const getUser = (userId: any, entities: any) => {
  return denormalize(userId, userSchema, entities);
};

export const selectTimeZone = (state: any) => {
  return state?.user?.authUser?.institution?.identifier;
};

export const selectIsDifferentOffSet = (state: any) => {
  return state.user.authUser.isDifferentOffSet;
};

export const selectAuthUser = (state: any) => {
  return state.user.authUser;
};

export const selectUserFromStudent = (state: any, studentId: any) => {
  const student = getStudent(studentId, state.entities);
  const userId = student.user._id || student.user;
  const user = getUser(userId, state.entities);
  return user;
};

export const selectCurrentInstructor = (state: any) => {
  const course = selectCurrentCourse(state);
  const userId = (course.user as User)._id || (course.user as string);
  return getUser(userId, state.entities);
};

export default {
  selectTimeZone,
  selectIsDifferentOffSet,
  selectAuthUser,
  selectUserFromStudent,
  getUser,
  selectCurrentInstructor,
};
