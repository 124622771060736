import { GrowthBook, GrowthBookProvider, useFeature } from '@growthbook/growthbook-react';
import { useAuthUserFromRedux } from 'hooks/user';
import React, { useEffect } from 'react';

type FeatureFlagKey =
  | 'create_activity_ai'
  | 'create_rubric_ai'
  | 'export_ai_content_report'
  | 'gpt_zero'
  | 'similarity_report'
  | 'flag_creation';

type GrowthBookContextType = {
  getFeatureFlag: (featureId: FeatureFlagKey) => boolean;
};

const GrowthBookContext = React.createContext<GrowthBookContextType>({} as GrowthBookContextType);

export function GrowthBookProviderMain({ children }: any) {
  const user = useAuthUserFromRedux();
  const growthBookUrl = (window as any).App.config.get('growthBookUrl');
  const currentEnv = (window as any).App.config.get('appEnv');

  const growthbook = new GrowthBook({
    apiHost: new URL(growthBookUrl).origin,
    clientKey: growthBookUrl.slice(growthBookUrl.lastIndexOf('/') + 1),
    enableDevMode: false,
    subscribeToChanges: true,
  });

  useEffect(() => {
    if (!user) {
      return;
    }
    growthbook.init({ streaming: true }).then(() => {
      growthbook.setAttributes({
        email: user.email,
        institutionId: user.institution?._id,
      });
    });
    return () => {
      growthbook.destroy();
    };
  }, [user?.email, user?.institution?._id]);

  const getFeatureFlag = (featureId: FeatureFlagKey) => {
    if (currentEnv === 'test' && featureId === 'create_activity_ai') {
      return false;
    }
    if (currentEnv === 'test') {
      return true;
    }

    return useFeature(featureId).on;
  };

  return (
    <GrowthBookContext.Provider value={{ getFeatureFlag }}>
      <GrowthBookProvider growthbook={growthbook}>{children}</GrowthBookProvider>
    </GrowthBookContext.Provider>
  );
}

/**
 * useIsFeatureFlagEnabled is a custom hook that wraps the useFeature hook from GrowthBook, you can use it anywhere in your app to check if a feature is enabled, or use the WithFeatureFlag component to wrap a component or element that should only be rendered if the feature is enabled.
 * useIsFeatureFlagEnabled and WithFeatureFlag can be use in any functional component. In case you need to use it in a class component, you can use the WithFeatureFlag component and pass the featureId
 * @param featureId - The feature flag key
 * useIsFeatureFlagEnabled returns a boolean value that indicates if the feature is enabled or not
 * WithFeatureFlag returns a ReactNode that will be rendered if the feature is enabled, otherwise it will return null
 * @example const isFeatureEnabled = useIsFeatureFlagEnabled('feature_id');
 * @example <WithFeatureFlag featureId="feature_id"> <div> This will only be rendered if the feature is enabled </div> </WithFeatureFlag>
 */

export const useIsFeatureFlagEnabled = (featureId: FeatureFlagKey) => {
  const context = React.useContext(GrowthBookContext);
  if (context === undefined) {
    throw new Error('useGetFeatureFlag must be used within a GrowthBookProvider');
  }
  return context.getFeatureFlag(featureId);
};

export function WithFeatureFlag({ children, featureId }: { children: React.ReactNode; featureId: FeatureFlagKey }) {
  if (!useIsFeatureFlagEnabled(featureId)) {
    return null;
  }

  return <>{children}</>;
}
