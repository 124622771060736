import { Activity, Course, Creation, Group } from '@kritik/types.generated';
import { simplePercent } from '@kritik/utils/format';
import * as statusUtil from '@kritik/utils/stage';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ActiveStatus } from 'components/AssignmentLabels/ProgressLabels';
import React from 'react';
import { connect } from 'react-redux';
import { selectActivityStatsById } from 'selectors/activity';
import { getCourse } from 'selectors/course';
import { GET_ACTIVITY_STATS } from 'types';
import { getProgress } from './utils';

interface RootState {
  course: Course;
  async: {
    call: string[];
    success: string[];
    error: string[];
  };
}

interface mapStateReturn {
  course: Course;
  assignment?: Activity;
  stats: CombinedStats;
  specificStatus?: string;
  getStatsBusy: boolean;
  getStatsSuccess: boolean;
  getStatsFailure: boolean;
}

type statsCreation = {
  creationsSubmitted: number;
  creationsExpected: number;
  groups: Group[];
};

type statsEvaluate = {
  numEvalsCompleted: number;
  numEvalsExpected: number;
};

type statsFeedback = {
  numFOFCompleted: number;
  numFOFExpected: number;
};

type CombinedStats = statsCreation & statsEvaluate & statsFeedback;

type CombinedProps = mapStateReturn & CombinedStats;

export const CreationProgress = ({
  activityStats,
  assignment,
}: {
  activityStats: statsCreation;
  assignment: Activity;
}) => {
  const progress = getProgress({ activityStats, activity: assignment, statusName: 'Create' });
  return <ActiveStatus content={`${simplePercent(progress)}`} />;
};

export const EvaluateProgress = ({ activityStats }: { activityStats: statsEvaluate }) => {
  const progress = getProgress({ activityStats, statusName: 'Evaluate' });
  return <ActiveStatus content={`${simplePercent(progress)}`} />;
};

export const FeedbackProgress = ({ activityStats }: { activityStats: statsFeedback }) => {
  const progress = getProgress({ activityStats, statusName: 'Feedback' });
  return <ActiveStatus content={`${simplePercent(progress)}`} />;
};

class InstructorProgressLabels extends React.Component<CombinedProps> {
  getProgressByStatus() {
    const { assignment, specificStatus, stats } = this.props;
    if (specificStatus === 'Create') {
      return <CreationProgress activityStats={stats} assignment={assignment} />;
    }
    if (specificStatus === 'Evaluate') {
      return <EvaluateProgress activityStats={stats} />;
    }
    if (specificStatus === 'Feedback') {
      return <FeedbackProgress activityStats={stats} />;
    }
    return null;
  }

  getLabelByStatus() {
    const { assignment, stats } = this.props;
    if (statusUtil.isCreate({ assignment })) {
      return <CreationProgress activityStats={stats} assignment={assignment} />;
    }
    if (statusUtil.isEvaluate({ assignment })) {
      return <EvaluateProgress activityStats={stats} />;
    }
    if (statusUtil.isFeedback({ assignment })) {
      return <FeedbackProgress activityStats={stats} />;
    }
    return null;
  }

  render() {
    if (this.props.getStatsBusy) {
      return <CircularProgress size="20px" />;
    }
    if (!this.props.stats || this.props.getStatsFailure) {
      return null;
    }
    if (this.props.specificStatus) {
      return this.getProgressByStatus();
    }
    return this.getLabelByStatus();
  }
}

const mapStateToProps = (state: RootState, ownProps: Creation): mapStateReturn => {
  return {
    course: getCourse(state),
    stats: selectActivityStatsById(state, (ownProps.assignment as Activity)._id),
    getStatsBusy: state.async[GET_ACTIVITY_STATS].busy,
    getStatsSuccess: state.async[GET_ACTIVITY_STATS].success,
    getStatsFailure: state.async[GET_ACTIVITY_STATS].error,
  };
};

export default connect(mapStateToProps)(InstructorProgressLabels);
