import * as activityUtil from '@kritik/utils/activity';
import { connect } from 'react-redux';
import groupSelector from 'selectors/group';
import studentSelector from 'selectors/student';

export const GroupActivityLabel = ({ student, group, activity }: any) => {
  if (!activityUtil.isGroupAssignment(activity) || !group || !student) {
    return null;
  }
  let label = '';
  if (group) {
    label = group.name;
    if (group.leader == student._id) {
      label += ' - Leader';
    }
  }
  return label;
};

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    group: groupSelector.getGroupOfStudent(state, ownProps.activity._id),
    student: studentSelector.getStudentFromUser(state, state.selected.courseId),
  };
};

export default connect(mapStateToProps)(GroupActivityLabel as any);
