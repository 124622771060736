import { useMutation, useQuery } from '@tanstack/react-query';
import { FileService } from 'services';

export function useUploadFile({
  onSuccess,
  onError,
}: {
  onSuccess?: (data: UploadResponse[]) => void;
  onError?: (data: any) => void;
}) {
  return useMutation({
    mutationFn: async ({ formData, allowedExtensions }: { formData: any; allowedExtensions: string[] }) => {
      const response = await FileService().uploadFiles({
        formData,
        allowedExtensions,
      });
      return response.data;
    },
    onSuccess,
    onError,
  });
}

export function useCreateFile({
  onSuccess,
  onError,
}: {
  onSuccess?: (data: { fileId: string }) => void;
  onError?: (data: any) => void;
}) {
  return useMutation({
    mutationFn: async (params: any) => {
      const response = await FileService().create(params);
      return response.data;
    },
    onSuccess,
    onError,
  });
}

type GetSignedFileUrlResult = {
  inlineUrl: string;
  downloadUrl: string;
};
export function getSignedFileUrl(fileUrl: string) {
  return useQuery(
    ['file', fileUrl],
    async () => {
      const result = await FileService().getByUrl(fileUrl);
      return result.data as GetSignedFileUrlResult;
    },
    { enabled: Boolean(fileUrl) }
  );
}
