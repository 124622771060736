import Grid from '@material-ui/core/Grid';
import Collaborators from 'components/Course/CollaboratorsPanel';
import Students from 'components/Course/StudentsSection';
import HtmlContent from 'components/General/HtmlContent';
import FileList from 'components/layout/AttachmentManager/FileList';
import GridContainer from 'components/layout/GridContainer/index';
import { localize } from 'locales';
import moment from 'moment-timezone';

const DetailSection = (props: any) => {
  const classes = `course-details__section ${props.className ? props.className : ''}`;
  return <div className={classes}>{props.children}</div>;
};

const CourseBody = ({ course, isStudent, user }: any) => {
  const timeZone = moment.utc(new Date()).tz(course.user.institution.identifier);
  const zoneName = timeZone.zoneName();
  const offset = timeZone.format('Z');

  const getCourseDescriptionText = () => {
    const content = course.description || localize({ message: 'CourseDetails.CourseInfo.NoDescription' });
    return <HtmlContent content={content} />;
  };

  const getCourseInfoNodes = () => {
    const courseDate =
      course.startDate && course.endDate
        ? `${moment(course.startDate).format('LL')} - ${moment(course.endDate).format('LL')}`
        : course.startDate
          ? moment(course.startDate).format('LL')
          : course.endDate
            ? moment(course.endDate).format('LL')
            : 'n/a';
    return [
      <div>
        <p className="title">{localize({ message: 'CourseDetails.CourseInfo.Title.Code' })}</p>
        <p className="value">{course.code || 'n/a'}</p>
      </div>,
      <div>
        <p className="title">{localize({ message: 'CourseDetails.CourseInfo.Title.Section' })}</p>
        <p className="value">{course.section || 'n/a'}</p>
      </div>,
      <div>
        <p className="title">{localize({ message: 'CourseDetails.CourseInfo.Title.Term' })}</p>
        <p className="value">{course.term || 'n/a'}</p>
      </div>,
      <div>
        <p className="title">{localize({ message: 'CourseDetails.CourseInfo.Title.Department' })}</p>
        <p className="value">{course.department || 'n/a'}</p>
      </div>,
      <div>
        <p className="title">{localize({ message: 'CourseDetails.CourseInfo.Title.ValidDate' })}</p>
        <p className="value">{courseDate}</p>
      </div>,
      <div>
        <p className="title">{localize({ message: 'CourseDetails.CourseInfo.Title.Timezone' })}</p>
        <p className="value">{`${zoneName} (GMT ${offset})` || 'n/a'}</p>
      </div>,
    ];
  };

  const getCourseFiles = () => {
    if (!course.files.length) {
      return null;
    }
    return (
      <DetailSection>
        <FileList files={course.files} />
      </DetailSection>
    );
  };

  const Details = (
    <div className="course-details__section-margin-top course-details__section-right-separator">
      <DetailSection>
        <div className="course-details__description">{getCourseDescriptionText()}</div>
      </DetailSection>
      <DetailSection className="course-details__section-margin-top">
        <Grid container spacing={3}>
          {getCourseInfoNodes().map((node) => {
            return (
              <Grid item xs={6} className="course-details__info-node">
                {node}
              </Grid>
            );
          })}
        </Grid>
      </DetailSection>
      {getCourseFiles()}
    </div>
  );

  const RightPanel = (
    <div className="course-details__section-margin-top">
      <Collaborators key="collaborator" course={course} isStudent={isStudent} />
      {!isStudent && (
        <Students
          courseId={course._id}
          enrolledStudents={course.students.length}
          pendingStudents={course.approvedStudents.length}
        />
      )}
    </div>
  );

  return (
    <GridContainer
      hasMinimumHeight
      nodes={[Details, RightPanel]}
      className="course-details__section-bottom-separator"
    />
  );
};

export default CourseBody;
