import { getWordCount } from '@kritik/utils/general';
import { TranslatedText } from 'components/TranslatedText';
import { Button, ButtonContainer } from 'components/buttons';
import { PageHeader } from 'components/layout';
import NoticeBoard from 'components/layout/NoticeBoard';
import { useUnresolveCreationSimilarityReport } from 'hooks/plagiarism';
import { NewTab } from 'images/icons/fa';
import moment from 'moment';
import React, { MutableRefObject, forwardRef, useRef } from 'react';
import { LoaderFunctionArgs, Outlet, useLoaderData, useNavigate, useParams, useRevalidator } from 'react-router';
import { creationService } from 'services';
import { formatPartialCourseBreadcrumb } from 'utils/format';

const isElementInViewPort = (element: HTMLDivElement) => {
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

async function loader(args: LoaderFunctionArgs) {
  const { creationId } = args.params;

  const creationResponse = await creationService().getFlaggedCreationWithMatchedSentences({
    creationId,
  });
  return creationResponse.data;
}

type PopulateArrayRefArgs = {
  arrayRef: MutableRefObject<any[]>;
  length: number;
};

function populateArrayRef({ arrayRef, length }: PopulateArrayRefArgs) {
  if (arrayRef.current.length !== length) {
    arrayRef.current = Array(length)
      .fill(null)
      .map((_, i) => arrayRef.current[i] || React.createRef<React.RefObject<HTMLDivElement>>());
  }
}

type SortSentencesByTextOrderArgs = {
  sentences: string[];
  text: string;
};

function sortSentencesByTextOrder({ sentences, text }: SortSentencesByTextOrderArgs) {
  const sentencePositions = sentences.map((sentence) => ({
    sentence,
    position: text.indexOf(sentence),
  }));
  sentencePositions.sort((a, b) => a.position - b.position);
  return sentencePositions.map((item) => item.sentence);
}

export function CreationSimilarityReport({}) {
  const reportRef: React.RefObject<HTMLDivElement> = useRef();
  const matchedSentencesDetailsRefs = useRef([]);
  const highlightedSentencesRefs = useRef([]);
  const revalidator = useRevalidator();

  const { flaggedCreation, matchedSentences, plagiarismResolutionStatus } = useLoaderData() as Awaited<
    ReturnType<typeof loader>
  >;

  const { plainTextContent } = flaggedCreation;

  const unresolveCreationSimilarityReport = useUnresolveCreationSimilarityReport();

  const params = useParams();
  const navigate = useNavigate();

  const uniqueHighlightedSentences = [
    ...sortSentencesByTextOrder({
      sentences: Array.from(new Set(matchedSentences.map((item) => item.flaggedSentence))),
      text: flaggedCreation.plainTextContent,
    }),
  ];

  populateArrayRef({ arrayRef: matchedSentencesDetailsRefs, length: matchedSentences.length });
  populateArrayRef({
    arrayRef: highlightedSentencesRefs,
    length: uniqueHighlightedSentences.length,
  });

  const handleFocusMatchedSentenceDetails = ({ matchSentencesIndexes, highlightedSentenceIndex }) => {
    highlightedSentencesRefs.current.forEach((ref, i) => {
      if (!ref.current) {
        return;
      }
      if (highlightedSentenceIndex === i) {
        ref.current.classList.add('similarity-report__highlighted-text-focused');
      } else {
        ref.current.classList.remove('similarity-report__highlighted-text-focused');
      }
    });

    // scroll into view for the match
    const firstMatchedSentence = matchedSentencesDetailsRefs.current[matchSentencesIndexes[0]];
    if (!isElementInViewPort(firstMatchedSentence.current)) {
      firstMatchedSentence.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

    matchSentencesIndexes.forEach(() => {
      matchedSentencesDetailsRefs.current.forEach((ref, i) => {
        if (!ref.current) {
          return;
        }
        if (matchSentencesIndexes.includes(i)) {
          ref.current.classList.add('similarity-report__match-details-container-focused');
        } else {
          ref.current.classList.remove('similarity-report__match-details-container-focused');
        }
      });
    });
  };

  const handleHighlightedSentence = ({ index, flaggedSentenceIndex }) => {
    matchedSentencesDetailsRefs.current.forEach((ref, i) => {
      if (!ref.current) {
        return;
      }
      if (index === i) {
        ref.current.classList.add('similarity-report__match-details-container-focused');
      } else {
        ref.current.classList.remove('similarity-report__match-details-container-focused');
      }
    });
    highlightedSentencesRefs.current.forEach((ref, i) => {
      if (!ref.current) {
        return;
      }
      if (flaggedSentenceIndex === i) {
        if (!isElementInViewPort(ref.current)) {
          ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
        ref.current.classList.add('similarity-report__highlighted-text-focused');
      } else {
        ref.current.classList.remove('similarity-report__highlighted-text-focused');
      }
    });
  };

  return (
    <div className="page-content-container">
      <Outlet />
      <div className="page-content">
        <PageHeader
          testid="similarity-report-page-title"
          title={<TranslatedText i18nKey="CreationSimilarityReport.PageHeader" />}
        >
          <ButtonContainer>
            <Button
              type="secondary"
              onClick={() => {
                if (Boolean(plagiarismResolutionStatus)) {
                  unresolveCreationSimilarityReport.mutate(flaggedCreation.id, {
                    onSuccess: () => revalidator.revalidate(),
                  });
                } else {
                  navigate('resolve');
                }
              }}
              testid="resolve-similarity"
            >
              <TranslatedText
                i18nKey={
                  Boolean(plagiarismResolutionStatus)
                    ? 'CreationSimilarityReport.Actions.Unresolve'
                    : 'CreationSimilarityReport.Actions.Resolve'
                }
              />
            </Button>
            <Button
              type="secondary"
              onClick={() => navigate(`/course/${params.courseId}/action-items?activityId=${params.assignmentId}`)}
            >
              <TranslatedText i18nKey="CreationSimilarityReport.Actions.BackToActionItems" />
            </Button>
          </ButtonContainer>
        </PageHeader>
        <div className="similarity-report">
          <div className="similarity-report__creation" ref={reportRef}>
            <div>
              <div className="similarity-report__creation-header">
                <p className="similarity-report__creation-title">
                  <TranslatedText
                    i18nKey="CreationSimilarityReport.Content.StudentCreation"
                    values={{ studentName: flaggedCreation.studentName }}
                  />
                  <NewTab
                    className="similarity-report__match-link"
                    onClick={(e) => {
                      e.stopPropagation();
                      const newWindow = window.open(
                        `/course/${flaggedCreation.courseId}/assignment/${flaggedCreation.activityId}/creation/${flaggedCreation.id}?groupView=${flaggedCreation.isGroupActivity}`,
                        '_blank',
                        'noopener,noreferrer'
                      );
                    }}
                  />
                </p>
                <span>
                  {getWordCount(plainTextContent)}{' '}
                  <TranslatedText i18nKey="CreationSimilarityReport.Content.WordCount" />
                </span>
              </div>
              <div className="similarity-report__creation-content">
                <HighlightedText
                  text={plainTextContent}
                  highlights={matchedSentences.map(({ flaggedSentence }) => flaggedSentence)}
                  handleFocusMatchedSentenceDetails={handleFocusMatchedSentenceDetails}
                  highlightedSentencesRefs={highlightedSentencesRefs}
                />
              </div>
            </div>
          </div>
          <div className="similarity-report__output">
            {Boolean(plagiarismResolutionStatus) && (
              <NoticeBoard title="Potential Plagiarism Resolved">
                <TranslatedText
                  i18nKey="CreationSimilarityReport.Resolution.ResolvedBy"
                  values={{
                    name: plagiarismResolutionStatus.resolvedBy,
                    date: moment(plagiarismResolutionStatus.resolvedOn).format('MMM DD yyyy @ hh:mm'),
                  }}
                />

                {plagiarismResolutionStatus.note && (
                  <div style={{ marginTop: '5px' }}>
                    <strong>Internal notes</strong>
                    <div>{plagiarismResolutionStatus?.note}</div>
                  </div>
                )}
              </NoticeBoard>
            )}
            <div className="similarity-report__output-title">
              <TranslatedText i18nKey="CreationSimilarityReport.Content.ContainsSimilarities" />
            </div>
            <div>
              {matchedSentences.map((matchedSentence, index) => {
                return (
                  <MatchedSentenceOverview
                    originalCreation={matchedSentence.originalCreation}
                    key={`${matchedSentence.originalCreation.id}-${index}`}
                  />
                );
              })}
            </div>
            <hr className="similarity-report__separator" />
            <div className="similarity-report__details">
              <div className="similarity-report__details-navigation">
                {matchedSentences.length} <TranslatedText i18nKey="CreationSimilarityReport.Content.Matches" />
              </div>
              {matchedSentences.map((matchedSentence, index) => {
                const flaggedSentenceIndex = uniqueHighlightedSentences.findIndex(
                  (sentence) => sentence === matchedSentence.flaggedSentence
                );
                return (
                  <MatchedSentenceDetails
                    matchedSentence={matchedSentence}
                    key={`${matchedSentence.originalCreation.id}-${index}`}
                    ref={matchedSentencesDetailsRefs.current[index]}
                    handleHighlightedSentence={() => handleHighlightedSentence({ index, flaggedSentenceIndex })}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const HighlightedText = ({ text, highlights, handleFocusMatchedSentenceDetails, highlightedSentencesRefs }) => {
  try {
    let highlightedSentenceIndex = 0;
    const getHighlightedText = ({ text, highlights }) => {
      const regex = new RegExp(`(${highlights.join('|')})`, 'gi');
      const parts = text.split(regex);
      return parts.map((part, index) => {
        const matchSentencesIndexes = highlights
          .map((highlight, i) => {
            if (highlight.includes(part)) {
              return i;
            }
            return -1;
          })
          .filter((x) => x > -1);

        if (matchSentencesIndexes.length > 0) {
          const _index = highlightedSentenceIndex;
          highlightedSentenceIndex += 1;
          return (
            <span
              key={index}
              className="similarity-report__highlighted-text"
              onClick={() =>
                handleFocusMatchedSentenceDetails({
                  matchSentencesIndexes,
                  highlightedSentenceIndex: _index,
                })
              }
              ref={highlightedSentencesRefs.current[_index]}
            >
              {part}
            </span>
          );
        } else {
          return <span key={index}>{part}</span>;
        }
      });
    };

    return <div>{getHighlightedText({ text, highlights })}</div>;
  } catch (error) {
    return <div>{text}</div>;
  }
};

const MatchedSentenceOverview = ({ originalCreation }) => {
  return (
    <div className="similarity-report__match-container">
      <p>{originalCreation.studentName}</p>
      <p className="similarity-report__match-activity">{originalCreation.activityTitle}</p>
      <p className="similarity-report__match-course">{formatPartialCourseBreadcrumb(originalCreation.course)}</p>
      <NewTab
        className="similarity-report__match-link"
        onClick={(e) => {
          const newWindow = window.open(
            `/course/${originalCreation.course.id}/assignment/${originalCreation.activityId}/creation/${originalCreation.id}?groupView=${originalCreation.isGroupActivity}`,
            '_blank',
            'noopener,noreferrer'
          );
        }}
      />
    </div>
  );
};

const MatchedSentenceDetails = forwardRef(
  ({ matchedSentence, handleHighlightedSentence }: any, ref: React.RefObject<HTMLDivElement>) => {
    return (
      <div className="similarity-report__match-details-container" ref={ref} onClick={handleHighlightedSentence}>
        <div className="similarity-report__match-details-header">
          <p>{matchedSentence.originalCreation.studentName}</p>
          <p className="similarity-report__match-details-activity">{matchedSentence.originalCreation.activityTitle}</p>
          <p className="similarity-report__match-details-date">
            {moment(matchedSentence.originalCreation.createdAt).format('MMM DD yyyy @ hh:mm')}
          </p>
        </div>
        <div className="similarity-report__match-original-sentence">{matchedSentence.originalSentence}</div>
        <NewTab
          className="similarity-report__match-link"
          onClick={(e) => {
            e.stopPropagation();
            const newWindow = window.open(
              `/course/${matchedSentence.originalCreation.course.id}/assignment/${matchedSentence.originalCreation.activityId}/creation/${matchedSentence.originalCreation.id}?groupView=${matchedSentence.originalCreation.isGroupActivity}`,
              '_blank',
              'noopener,noreferrer'
            );
          }}
        />
      </div>
    );
  }
);

CreationSimilarityReport.loader = loader;
