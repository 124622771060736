import { Popover, Typography } from '@material-ui/core';
import { AuthUser } from 'app-types';
import { localize } from 'locales';
import { useState } from 'react';
import { connect } from 'react-redux';
import { trackEvent } from 'utils/userEvents';

const apiEndpoint = import.meta.env.VITE_API_URL;

const CommunityLink = ({ authUser }: { authUser: AuthUser }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (e: any) => {
    setAnchorEl(e.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = !!anchorEl;

  const PopoverMessageStyle = {
    padding: '15px',
    width: '200px',
  };

  const handleOnClick = () => {
    trackEvent('Community SSO', authUser);
  };

  return (
    <li role="listitem" style={{ flexGrow: 1 }} className="main-menu-item">
      <div
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <a
          target="_blank"
          className="menu-item"
          href={`${apiEndpoint}/community/login`}
          rel="noreferrer nofollow"
          onClick={handleOnClick}
        >
          <i className="fa fa-bullhorn" />
          <span className="menu-item__label">{localize({ message: 'Navigation.Tabs.FeatureRequests.Label' })}</span>
        </a>
      </div>
      <Popover
        style={{ pointerEvents: 'none' }}
        id="mouse-over-popover"
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography style={PopoverMessageStyle}>
          {localize({ message: 'Navigation.Tabs.FeatureRequests.Tip' })}
        </Typography>
      </Popover>
    </li>
  );
};

const mapStateToProps = (state: any) => {
  return {
    authUser: state.user.authUser,
  };
};

export default connect(mapStateToProps)(CommunityLink);
