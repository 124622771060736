import CreationConstants from '@kritik/constants/creation';
import { tags as TAGS } from '@kritik/constants/tags';
import * as ActivityUtils from '@kritik/utils/activity';
import { isAverageGradeHidden } from '@kritik/utils/course';
import * as FeedbackUtils from '@kritik/utils/creation/feedback';
import { isUserCreationOwner } from '@kritik/utils/creation/general';
import { isScoreViewCompleted, isScoreViewDismissed, isScoreViewReady } from '@kritik/utils/creation/score';
import { wasCreationMissed } from '@kritik/utils/creation/status';
import { CreationScoreUtil } from '@kritik/utils/grade';
import { isFeedback, isFinalized, isGrading, isGradingOrLater } from '@kritik/utils/stage';
import ClassAverageButton from 'components/Creation/CreationOverview/CreationSummary/ClassAverageButton';
import DisputeForm from 'components/Creation/Dispute';
import { NumericGradeView } from 'components/Creation/GradeView';
import StarRubricMarks from 'components/General/StarRubricMarks';
import { STAR_RUBRIC_MARKS } from 'components/General/constant';
import { TranslatedText } from 'components/TranslatedText';
import Button from 'components/buttons/Button';
import Banner from 'components/core/Banner';
import NoticeBoard from 'components/layout/NoticeBoard';
import CELEBRATION_TAG from 'images/spotlight-icons/badge-celebration.svg';
import { localize } from 'locales';
import { Activity, Course, Creation, Evaluation, Institution, Rubric, Student, User } from 'old-common/types.generated';
import { Component } from 'react';
import { connect } from 'react-redux';
import { recordScoreViewed } from 'redux/creation';
import { getCourse } from 'selectors/course';
import { getMySubmission } from 'selectors/creation';
import StudentSelectors from 'selectors/student';
import * as StudentUtils from 'utils/student';
import { getBadgeUrl } from 'utils/tags';

type StudentGradeViewState = {
  viewClassAverage: boolean;
  disputeReason: string;
  isRecordingScoreView: boolean;
  showDisputeMenu: boolean;
};

type StudentGradeViewProps = {
  creation: Creation & { scores: Evaluation[] };
  activity: Activity & { user: User & { institution: Institution } };
  course: Course;
  user: User & { authUser: any; institution: Institution };
  rubric: Rubric;
  showHeader: boolean;
  recordScoreViewed: (creationId: string) => Promise<any>;
  myStudent: Student;
};

class StudentGradeView extends Component<StudentGradeViewProps, StudentGradeViewState> {
  static defaultProps = {
    showHeader: true,
  };

  state = {
    viewClassAverage: false,
    disputeReason: '',
    isRecordingScoreView: false,
    showDisputeMenu: false,
  };

  toggleClassAverage() {
    this.setState({ viewClassAverage: !this.state.viewClassAverage });
  }

  renderDisputeMenu() {
    return (
      <DisputeForm
        isOpen={(this.state as any).showDisputeMenu}
        creation={this.props.creation}
        assignment={this.props.activity}
      />
    );
  }

  renderTeacherReviewedLabel(activity: any) {
    if (
      !ActivityUtils.isCalibrationActivity(activity) &&
      (isGrading({ assignment: activity }) || isFinalized({ assignment: activity })) &&
      (CreationScoreUtil.isInstructorGraded(this.props.creation) ||
        CreationScoreUtil.isProfRevised(this.props.creation))
    ) {
      return (
        <NoticeBoard type="success" title={localize({ message: 'StudentGradeView.TeacherReviewed.Title' })}>
          <TranslatedText i18nKey="StudentGradeView.TeacherReviewed.Content" />
        </NoticeBoard>
      );
    }
  }

  creationHasUnfinishedFeedback() {
    const { creation, activity } = this.props;
    return isFeedback({ assignment: activity }) && !FeedbackUtils.hasFinishedFOF(creation, activity);
  }

  renderHeaderScore() {
    const { creation, activity } = this.props;
    if (this.creationHasUnfinishedFeedback()) {
      return (
        <div data-testid="header-score-waiting" className="student-grade-view__header-score-waiting">
          Waiting...
        </div>
      );
    }
    if (this.isScoreReady()) {
      return (
        <div data-testid="header-score-ready" className="student-grade-view__header-score-waiting">
          Ready
        </div>
      );
    }
    return (
      <div data-testid="header-score-with-scores" className="student-grade-view__header-score">
        <NumericGradeView
          creation={creation}
          rubric={activity.rubric}
          numericGrading={ActivityUtils.isNumericGrading(activity)}
          activity={activity}
          viewAIScore={false}
        />
      </div>
    );
  }

  renderHeader() {
    if (!this.props.showHeader || !this.props.creation) {
      return null;
    }
    return (
      <div className="student-grade-view__header">
        <div>
          <h3>Creation Score</h3>
          {isScoreViewCompleted(this.props.creation) && (
            <ul>
              <li>Your instructor may adjust your score at anytime.</li>
              <li>You will see your evaluation and feedback score when the activity moves to Grading Stage.</li>
            </ul>
          )}
        </div>
        {this.renderHeaderScore()}
      </div>
    );
  }

  renderDisputeButton() {
    const { creation, activity } = this.props;
    if (!creation) {
      return null;
    }
    if (
      isFinalized({ assignment: activity }) ||
      (creation.dispute.status === CreationConstants.disputeStatuses.RESOLVED && !isFeedback({ assignment: activity }))
    ) {
      return null;
    }
    if (creation && !wasCreationMissed(creation) && isUserCreationOwner(creation, this.props.user)) {
      return (
        <Button
          onClick={() => {
            return this.setState({ showDisputeMenu: !(this.state as any).showDisputeMenu });
          }}
          type={this.state.showDisputeMenu ? 'primary' : 'secondary'}
          data-testid="dispute-score"
          aria-expanded={this.state.showDisputeMenu}
        >
          {creation.dispute.status === CreationConstants.disputeStatuses.NO_DISPUTE
            ? localize({ message: 'StudentGradeView.DisputeButton.DisputeScore' })
            : localize({ message: 'StudentGradeView.DisputeButton.ViewDisputeStatus' })}
        </Button>
      );
    }
  }

  renderNoScoreWarning() {
    const { creation } = this.props;
    if (!wasCreationMissed(creation) && !CreationScoreUtil.hasCreationScore(creation)) {
      return <NoticeBoard type="information" title={localize({ message: 'StudentGradeView.NoScore.Title' })} />;
    }
    return null;
  }

  renderButtons() {
    if (this.creationHasUnfinishedFeedback() || this.isScoreReady()) {
      return null;
    }
    const { activity, course } = this.props;
    const shouldRenderClassAvgButton =
      !isAverageGradeHidden(course) && isGradingOrLater(activity) && !ActivityUtils.isWithinGroupActivity(activity);
    return (
      <div className="student-grade-view__btn-container">
        {this.renderDisputeButton()}
        {shouldRenderClassAvgButton && (
          <ClassAverageButton
            onClick={() => {
              return this.toggleClassAverage();
            }}
            viewClassAverage={this.state.viewClassAverage}
            activity={activity}
          />
        )}
      </div>
    );
  }

  isScoreReady = () => {
    const { creation } = this.props;
    return creation && (isScoreViewReady(creation) || isScoreViewDismissed(creation));
  };

  toggleCreationScore = () => {
    if (!this.state.isRecordingScoreView) {
      this.props.recordScoreViewed(this.props.creation._id).then(() => {
        this.setState({ isRecordingScoreView: false });
      });
    }
  };

  renderCompleteFeedbackNotice() {
    if (!this.creationHasUnfinishedFeedback()) {
      return null;
    }
    return (
      <Banner
        className={this.isScoreReady() ? 'complete-feedback-notice' : ''}
        image={
          <div className="complete-feedback-notice__icons">
            <img
              className="complete-feedback-notice__icon-shifted"
              src={getBadgeUrl(TAGS.CRITICAL)}
              alt="critical icon"
            />
            <img className="complete-feedback-notice__icon" src={getBadgeUrl(TAGS.MOTIVATIONAL)} alt="critical icon" />
          </div>
        }
        title={localize({ message: 'StudentGradeView.CompleteFeedback.Title' })}
        description={localize({ message: 'StudentGradeView.CompleteFeedback.Description' })}
      >
        <Button type="secondary" disabled={!this.isScoreReady()} unavailable={!this.isScoreReady()}>
          <TranslatedText i18nKey="StudentGradeView.CompleteFeedback.ShowScore" />
        </Button>
      </Banner>
    );
  }

  renderScoreReadyNotice() {
    return (
      <Banner
        className="complete-feedback-notice"
        image={<img src={CELEBRATION_TAG} aria-hidden="true" />}
        title={localize({ message: 'StudentGradeView.ScoreReady.Title' })}
        description={localize({ message: 'StudentGradeView.ScoreReady.Description' })}
      >
        <Button
          data-testid="feedback-complete-show-score"
          type="secondary"
          onClick={() => {
            return this.toggleCreationScore();
          }}
        >
          <TranslatedText i18nKey="StudentGradeView.ScoreReady.ShowScore" />
        </Button>
      </Banner>
    );
  }

  renderScoreNotice = () => {
    const { creation, activity } = this.props;
    if (!isFeedback({ assignment: activity })) {
      return null;
    }
    if (!FeedbackUtils.hasFinishedFOF(creation, activity)) {
      return this.renderCompleteFeedbackNotice();
    }
    if (this.isScoreReady()) {
      return this.renderScoreReadyNotice();
    }
    return null;
  };

  render() {
    const { rubric, creation, activity } = this.props;

    const inFinalized =
      isFinalized({ assignment: activity }) ||
      !!StudentUtils.getStudentActivityScoreHistory(this.props.myStudent.scoreHistories, activity._id);

    const inGrading = isGrading({ assignment: activity });

    // We are not showing any score until the presentation activity is in Grading or Finalized stage.
    if (ActivityUtils.isPresentationActivity(activity) && !inGrading && !inFinalized) {
      return null;
    }

    const hideRubric = this.creationHasUnfinishedFeedback() || this.isScoreReady();

    return (
      <div className={`student-grade-view `}>
        {this.renderHeader()}
        {!hideRubric && (
          <StarRubricMarks
            tableType={STAR_RUBRIC_MARKS.STUDENT_CREATION_SCORE}
            rubric={rubric}
            marks={creation ? CreationScoreUtil.getRawCreationScore(creation, rubric) : []}
            comparisonMarks={
              this.state.viewClassAverage ? [{ marks: activity.averageGrade, type: 'class-average' }] : null
            }
            type="primary"
            showExactScore
            criteriaModeScoringOverruled={null}
          />
        )}
        {this.renderTeacherReviewedLabel(activity)}
        {this.renderNoScoreWarning()}
        {this.renderScoreNotice()}
        {this.renderDisputeMenu()}
        {this.renderButtons()}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    myStudent: StudentSelectors.getStudentFromUser(state, state.selected.courseId),
    user: state.user,
    course: getCourse(state, state.selected.courseId),
    creation: getMySubmission(state, state.selected.assignmentId),
  };
};

export default connect(mapStateToProps, { recordScoreViewed })(StudentGradeView);
