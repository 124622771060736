import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

function ErrorModal({ open, title, content, handleClose, btnText }: any) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText style={{ color: 'black' }}>{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {/* @ts-expect-error TS(2769) FIXME: No overload matches this call. */}
        <Button onClick={handleClose} type="primary">
          {btnText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ErrorModal.defaultProps = {
  btnText: 'OK',
  open: false,
  title: 'Error',
};

export default ErrorModal;
