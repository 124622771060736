import Search from 'components/core/input/Search';
import { localize } from 'locales';

function GroupSearch({ onSearch }: any) {
  return (
    <div className="group-manager__search">
      <Search onSearch={onSearch} placeholder="Search groups" label={localize({ message: 'GroupManager.Search' })} />
    </div>
  );
}

export default GroupSearch;
