import { isPresentationActivity } from '@kritik/utils/activity';
import { isCreationOverdue } from '@kritik/utils/creation/status';
import { timeUntilDue, timeUntilStart } from '@kritik/utils/format';
import {
  getActiveStatus,
  getNextDate,
  isActionableStatus,
  isFinalized,
  isGrading,
  isInCreationGracePeriod,
  isInEvaluationGracePeriod,
  isScheduled,
} from '@kritik/utils/stage';
import { UserRoleInCourse } from 'app-types';
import { AssignmentDateLabel } from 'components/AssignmentLabels/StatusLabels';
import { localize } from 'locales';
import moment from 'moment-timezone';
import { Activity, Creation } from 'old-common/types.generated';
import React from 'react';
import { connect } from 'react-redux';
import { getMySubmission } from 'selectors/creation';
import { withUserRole } from 'utils/withUserRole';

const LABELS = {
  PENDING: localize({ message: 'Activity.Schedule.Pending' }),
  GRADING: localize({ message: 'Activity.Schedule.Grading' }),
  COMPLETE: localize({ message: 'Activity.Schedule.Complete' }),
  GRADED: localize({ message: 'Activity.Schedule.Graded' }),
  NO_SET_SCHEDULE: localize({ message: 'Activity.Schedule.NoSetSchedule' }),
  OVERDUE: localize({ message: 'Activity.Schedule.Overdue' }),
};

const MIN_HOURS_FOR_TIMER = 12;

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
    submission: getMySubmission(state, state.selected.assignmentId),
  };
};

type DueDateDisplayState = {
  isTimerStarted: boolean;
  hoursLeft: number | null;
};

class DueDateDisplay extends React.Component<
  {
    isSubmitting?: boolean;
    assignment?: Activity & {
      userAssignment: Creation;
    };
    submission?: Creation;
    className?: string;
    userRole: UserRoleInCourse;
  },
  DueDateDisplayState
> {
  interval: any;
  state = {
    isTimerStarted: false,
    hoursLeft: null,
  };

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  startTimer(date: any) {
    this.interval = setInterval(() => {
      return this.calculateHoursLeft(date);
    }, 1000);
  }

  calculateHoursLeft = (date: any) => {
    const now = moment();
    const end = moment(date);
    const hoursLeft = end.diff(now, 'hours');
    this.setState({ hoursLeft });
  };

  calculateTimeLeft = (date: any) => {
    const now = moment();
    const end = moment(date);

    if (now.isSameOrAfter(end)) {
      return LABELS.OVERDUE;
    }

    if (this.state.hoursLeft !== null && this.state.hoursLeft <= MIN_HOURS_FOR_TIMER) {
      const diff = moment.duration(end.diff(now));
      const timer = moment.utc(diff.asMilliseconds()).format('HH[h]:mm[m]');
      return `Due in ${timer}`;
    }

    return timeUntilDue(date);
  };

  getDate = () => {
    const { assignment, submission, isSubmitting } = this.props;
    if (isGrading({ assignment })) {
      return LABELS.PENDING;
    }
    if (isFinalized({ assignment })) {
      return LABELS.COMPLETE;
    }
    if (!isScheduled(assignment)) {
      return LABELS.NO_SET_SCHEDULE;
    }

    const date = getNextDate(assignment);

    if (date) {
      if (!this.state.isTimerStarted) {
        this.setState({ isTimerStarted: true });
        this.startTimer(date);
      }
      if (
        isSubmitting ||
        isCreationOverdue(assignment, submission) ||
        isActionableStatus(assignment) ||
        isInCreationGracePeriod(assignment) ||
        isInEvaluationGracePeriod(assignment)
      ) {
        return this.calculateTimeLeft(date);
      }
      return timeUntilStart(date);
    }
    return LABELS.NO_SET_SCHEDULE;
  };

  render() {
    if (isPresentationActivity(this.props.assignment) && this.props.userRole.isStudentInCourse) {
      const currentStage = getActiveStatus(this.props.assignment) as PresentationAssignmentStatus;
      if (!currentStage.isParticipating) {
        return null;
      }
    }
    return (
      <AssignmentDateLabel
        assignment={this.props.assignment}
        date={this.getDate()}
        submission={this.props.submission}
        className={this.props.className}
      />
    );
  }
}

export default withUserRole(connect(mapStateToProps, {})(DueDateDisplay));
