import * as GeneralUtils from '@kritik/utils/general';
import { getEnrollLink } from '@kritik/utils/general';
import Tooltip from '@material-ui/core/Tooltip';
import { updateAccountInformation } from 'actions/users';
import { SignupButtons, SignupContainer, SignupContent, SignupMainTitle } from 'components/AuthWrapper';
import { Form } from 'components/Form';
import { countryCodesOptions } from 'components/Setting/countrycodes';
import ProfileImageUpload from 'components/User/ProfileImageUpload';
import TermsOfService from 'components/auth/TermsOfService';
import Button from 'components/buttons/Button';
import Selection from 'components/core/form/SelectField';
import FormTextInput from 'components/core/form/TextField';
import { localize } from 'locales';
import * as _ from 'lodash-es';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { push } from 'router';
import isEmail from 'validator/lib/isEmail';

function UserDetails(props: any) {
  const [inputValue, setInputValue] = useState({
    firstName: props.user.authUser.profile.firstName,
    lastName: props.user.authUser.profile.lastName,
    countryCode: '',
    countryLabel: null,
    email: props.user.authUser.email || '',
  });
  const [phoneNumber, setPhoneNumber] = useState(props.user.authUser.phoneNumber || '');
  const [error, setError] = useState({});
  const [isStep1Valid, setIsStep1Valid] = useState(
    props.user?.authUser?.profile?.firstName &&
      props.user?.authUser?.profile?.lastName &&
      props.user?.authUser?.email &&
      props.user.authUser?.phoneNumber
  );

  const [uploadedImg, setUploadedImg] = useState(props.user.authUser.profile.picture);

  const handlePhoneNumberChange = (e: any) => {
    setPhoneNumber(e.target.value);
  };
  const handleInputChange = (e: any) => {
    setInputValue({ ...inputValue, [e.target.name]: e.target.value });
  };

  const handleOnSubmit = async () => {
    const data = {
      profile: {},
    };
    (data.profile as any).firstName = inputValue.firstName;
    (data.profile as any).lastName = inputValue.lastName;
    (data as any).phoneNumber = inputValue.countryCode + phoneNumber || '';
    (data.profile as any).picture = uploadedImg;
    (data as any).userId = props.user.authUser.id;
    if (!props.user.authUser.email) {
      (data as any).email = inputValue.email;
    }

    await props.updateAccountInformation(data);
    if (props.shouldRedirectToCheckout) {
      const enrollLink = await getEnrollLink({
        courseId: props.courseId,
        // @ts-expect-error TS(18004) FIXME: No value exists in scope for the shorthand propert... Remove this comment to see the full error message
        email,
      });
      return props.router.push(`${window.location.origin}${enrollLink}`);
    }
  };

  const getError = (field: any) => {
    if (error) {
      return error[field];
    }
    return null;
  };

  const handleClickContinue = () => {
    verifyStep1();
  };

  const verifyStep1 = () => {
    const errors: any = {};
    if (inputValue.firstName.length === 0) {
      errors.firstName = 'Please enter your first name';
    }
    if (inputValue.lastName.length === 0) {
      errors.lastName = 'Please enter your last name';
    }
    if (!inputValue.email || inputValue.email.length === 0 || isEmail(inputValue.email) === false) {
      errors.email = 'Please enter a valid email address';
    }

    const fullPhoneNumber = inputValue.countryCode + phoneNumber;

    if (!GeneralUtils.isValidE164PhoneNumber(fullPhoneNumber)) {
      errors.phoneNumber = localize({ message: 'WrongPhoneNumberFormat' });
    }

    if (!_.isEmpty(errors)) {
      return setError(errors);
    }

    setInputValue((prevInputValue) => ({
      ...prevInputValue,
    }));

    return setIsStep1Valid(true);
  };

  const handleCountryCodeChange = (e: any) => {
    const selection = countryCodesOptions.find((option) => {
      return option.label === e.target.value;
    });
    const countryCode = GeneralUtils.sanitizeCountryCode(selection.value);

    setInputValue({
      ...inputValue,
      countryCode,
      countryLabel: selection.label,
    });
  };

  const getCountryCodeOptions = () => {
    return countryCodesOptions.map((option) => {
      return {
        label: option.label,
        value: option.label,
      };
    });
  };

  const renderStep1 = () => {
    return (
      <SignupContainer graphic="rocket">
        <SignupContent>
          <SignupMainTitle>We just need few more details to complete your account.</SignupMainTitle>
          <Form>
            <FormTextInput
              name="firstName"
              label="First Name"
              value={inputValue.firstName}
              onChange={handleInputChange}
              error={getError('firstName')}
              autoFocus
              isRequired
            />
            <FormTextInput
              name="lastName"
              label="Last Name"
              value={inputValue.lastName}
              onChange={handleInputChange}
              error={getError('lastName')}
              testid="last-name"
              isRequired
            />
            <FormTextInput
              name="email"
              label="Email"
              value={inputValue.email}
              onChange={handleInputChange}
              error={getError('email')}
              testid="email"
              disabled={props.user.authUser.email}
              isRequired
            />
            {props.user && !props.user?.authUser?.permissions.includes('canCreateCourse') && (
              <React.Fragment>
                <Selection
                  label="Country code"
                  value={inputValue.countryLabel}
                  onChange={handleCountryCodeChange}
                  options={getCountryCodeOptions()}
                  placeholder="Select your country code"
                  testid="country-code"
                />
                <Tooltip
                  title={
                    !inputValue.countryCode
                      ? `Please select a country code to enable phone number field.`
                      : `Adding your phone number is optional.`
                  }
                  placement="left-start"
                  arrow
                >
                  <div className="phoneNumber-container">
                    <FormTextInput
                      name="phoneNumber"
                      label="Phone Number"
                      value={phoneNumber}
                      onChange={handlePhoneNumberChange}
                      error={getError('phoneNumber')}
                      helpText="For example: 1234567890"
                      testid="phone-number"
                      disabled={!inputValue.countryCode}
                    />
                  </div>
                </Tooltip>
                <p>
                  Adding your phone number is optional, and will allow you to receive SMS notifications about important
                  events and upcoming deadlines.
                </p>
              </React.Fragment>
            )}

            <SignupButtons>
              <Button type="primary" onClick={handleClickContinue} testid="continue-btn" inputType="submit">
                Continue
              </Button>
            </SignupButtons>
            <TermsOfService />
          </Form>
        </SignupContent>
      </SignupContainer>
    );
  };

  const renderStep2 = () => {
    return (
      <ProfileImageUpload
        firstName={inputValue.firstName}
        onImageUpload={(image: any) => {
          setUploadedImg(image);
        }}
        onSubmit={handleOnSubmit}
        uploadedImg={uploadedImg}
      />
    );
  };

  const renderSteps = () => {
    return isStep1Valid ? renderStep2() : renderStep1();
  };

  return renderSteps();
}

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps, { updateAccountInformation, push })(UserDetails);
