import { CardActionArea } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ActivityMenu from 'components/Assignment/Menu';

const useStylesActionArea = makeStyles({
  root: {
    height: '100%',
  },
});

const CardMenu = (props: any) => {
  const classesActionArea = useStylesActionArea();
  return (
    <CardActionArea disableRipple className={classesActionArea.root}>
      <ActivityMenu activity={props.activity} />
    </CardActionArea>
  );
};

export default CardMenu;
