import { useLayoutEffect, useState } from 'react';

export type IMediaQuery = Array<string>;

export type IMatchedMedia = Array<boolean>;

function useMatchMedia(queries: IMediaQuery, defaultValues: IMatchedMedia = []): IMatchedMedia {
  const initialValues = defaultValues.length ? defaultValues : Array(queries.length).fill(false);

  if (typeof window === 'undefined') {
    return initialValues;
  }

  const mediaQueryLists = queries.map((q) => window.matchMedia(q));
  const getValue = (): IMatchedMedia => {
    const matchedQueries = mediaQueryLists.map((mql) => mql.matches);

    return matchedQueries;
  };

  const [value, setValue] = useState(getValue);

  useLayoutEffect(() => {
    const handler = (): void => setValue(getValue);
    mediaQueryLists.forEach((mql) => mql.addEventListener?.('change', handler));
    return (): void => mediaQueryLists.forEach((mql) => mql.removeEventListener?.('change', handler));
  }, []);

  return value;
}

export type ScreenSizes = {
  isTablet: boolean;
  isDesktop: boolean;
  isMobileScoreBreakdown: boolean;
};
export function useScreenSize(): ScreenSizes {
  const [isTablet] = useMatchMedia(['(min-width: 600px)']);
  const [isMobileScoreBreakdown] = useMatchMedia(['(min-width: 800px)']);
  const [isDesktop] = useMatchMedia(['(min-width: 1200px)']);
  return {
    isTablet,
    isDesktop,
    isMobileScoreBreakdown,
  };
}

export default useMatchMedia;
