import classNames from 'classnames';

const DisplayBox = ({ className, children, header = null, style, testid, ...props }: any) => {
  const classes = classNames('display-box', className);

  return (
    <div className={classes} style={style} {...props} data-testid={testid}>
      {header}
      {children}
    </div>
  );
};

export default DisplayBox;
