import { isGroupAssignment, isGroupPresentationActivity } from '@kritik/utils/activity';
import HeaderRow from 'components/CreationList/Header';
import localConstant from 'components/CreationList/constant';
import ListContext from 'components/CreationList/context';
import localUtil from 'components/CreationList/util';
import { sortItemList } from 'containers/assignments/creations/utils';
import { Component, forwardRef, useContext, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { VariableSizeList } from 'react-window';
import {
  selectCreationTableListBy,
  selectCreationTableViewType,
  selectSortedColumn,
  selectTableSettings,
} from 'redux/creationTable/select';
import { selectCurrentActivity } from 'selectors/activity';
import { selectCurrentCourse } from 'selectors/course';
import CreationSelectors from 'selectors/creation';

// @ts-expect-error TS(2339) FIXME: Property 'children' does not exist on type '{}'.
const InnerElement = forwardRef(({ children, ...rest }, ref) => {
  return (
    // @ts-expect-error TS(2322) FIXME: Type 'ForwardedRef<unknown>' is not assignable to ... Remove this comment to see the full error message
    <table className="creation-table" ref={ref} {...rest}>
      <caption>
        <span className="visually-hidden">
          List of creations for the current activity. Column headers with buttons are sortable.
        </span>
      </caption>
      <HeaderRow />
      <tbody data-testid="creation-table">{children}</tbody>
    </table>
  );
});

function withCollapse(ItemRenderer: any) {
  return ({ style, data, index, ...props }: any) => {
    const { rowList } = data;
    const item = rowList[index];
    if (!item) {
      return <ItemRenderer {...props} index={index} data={data} style={style} />;
    }
    const { parentId, isVisible } = item;
    const newStyle = { ...style };
    if (parentId && !isVisible) {
      newStyle.display = 'none';
    }
    return <ItemRenderer {...props} index={index} data={data} style={newStyle} />;
  };
}

const List = ({ children, rowList, activeIndex, itemSize, ...rest }: any) => {
  const { stickyIndices, columns, ...other } = useContext(ListContext);
  const listRef = useRef(null);
  const [_rowList, setRowList] = useState(rowList);

  useEffect(() => {
    setRowList(rowList);
  }, [rowList]);

  useEffect(() => {
    if (!activeIndex || activeIndex === -1) {
      return;
    }
    listRef.current && (listRef.current as any).scrollToItem(activeIndex, 'center');
  }, []);

  const resetPositionCache = (i?: number) => {
    // https://react-window.vercel.app/#/api/VariableSizeList
    if (listRef.current) {
      return (listRef.current as any).resetAfterIndex(i || 0);
    }
  };

  useEffect(() => {
    resetPositionCache();
  });

  const toggleGroupMembers = (i: any) => {
    resetPositionCache(i);

    const nextItem = rowList[i + 1];
    const item = rowList[i];
    if (nextItem.parentId) {
      setRowList(
        _rowList.map((rowItem: any, index: any) => {
          if (i === index) {
            rowItem.isExpand = !rowItem.isExpand;
          }
          if (rowItem.parentId === item.group._id) {
            rowItem.isVisible = !rowItem.isVisible;
          }
          return rowItem;
        })
      );
    }
  };

  const getSize = (i: any) => {
    if (typeof itemSize === 'function') {
      return itemSize(i);
    }
    const item = _rowList[i];
    if (item && item.parentId && !item.isVisible) {
      return 0;
    }

    return itemSize;
  };

  return (
    <VariableSizeList
      ref={listRef}
      itemSize={getSize}
      itemData={{
        ItemRenderer: children,
        stickyIndices,
        columns,
        rowList: _rowList,
        toggleGroupMembers,
        ...other,
      }}
      {...rest}
    >
      {withCollapse(children)}
    </VariableSizeList>
  );
};

const isItemRow = (item: any) => {
  return item && item.student;
};

class CreationTable extends Component {
  static defaultProps = {
    displayHeader: true,
    maxRows: 10,
  };

  getStickyIndices() {
    // define header rows
    if ((this.props as any).displayHeader) {
      return [0];
    }
    return [];
  }

  generateGroupList = (list: any) => {
    let newList: any = [];

    list.forEach((item: any) => {
      if (!isItemRow(item)) {
        return newList.push(item);
      }

      const isExpand = item.creation._id === (this.props as any).selectedCreationId;

      newList.push({
        ...item,
        isExpand,
        lateEvaluations: Object.values(item.lateEvaluations || {}).flat(),
      });
      if (item && item.group) {
        let groupMembers = item.group.members.map((member: any) => {
          return {
            student: member,
            creation: item.creation,
            parentId: item.group._id,
            isVisible: isExpand,
            lateEvaluations: item.lateEvaluations?.[member._id] || [],
          };
        });
        if ((this.props as any).sortedColumn) {
          groupMembers = sortItemList({
            itemList: groupMembers,
            sortedColumn: (this.props as any).sortedColumn,
            activity: (this.props as any).activity,
            viewType: (this.props as any).viewType,
            tableSettings: (this.props as any).tableSettings,
            feedbackReceivedMap: (this.props as any).feedbackReceivedMap,
            course: (this.props as any).course,
          });
        }
        newList = newList.concat(groupMembers);
      }
    });

    return newList;
  };

  getListToRender() {
    // @ts-expect-error TS(2339) FIXME: Property 'rowList' does not exist on type 'Readonl... Remove this comment to see the full error message
    const { rowList, creationTableListBy } = this.props;
    let list = [...rowList];
    // add null item for rendering no creation match message in table
    if (list.length === 0) {
      list = [null];
    }
    // add empty item for header row
    if ((this.props as any).displayHeader) {
      list.splice(0, 0, {});
    }

    if (localUtil.isListByGroup(creationTableListBy)) {
      return this.generateGroupList(list);
    }
    return list;
  }

  getListHeight() {
    const NUM_OF_ROWS = Math.min((this.props as any).maxRows, this.getListToRender().length);
    return NUM_OF_ROWS * localConstant.ROW_HEIGHT;
  }

  getColumns = () => {
    return (this.props as any).tableColumns;
  };

  findActiveIndex = () => {
    // @ts-expect-error TS(2339) FIXME: Property 'creationTableListBy' does not exist on t... Remove this comment to see the full error message
    const { creationTableListBy, activity, selectedCreationId, selectedStudentId } = this.props;
    if (!activity) {
      return -1;
    }
    const listToRender = this.getListToRender();
    if (!listToRender.length) {
      return -1;
    }
    if (
      (isGroupAssignment(activity) || isGroupPresentationActivity(activity)) &&
      !localUtil.isListByGroup(creationTableListBy)
    ) {
      return listToRender.findIndex((item) => {
        return item && item.student && item.student._id === selectedStudentId;
      });
    }
    return listToRender.findIndex((item) => {
      return item && item.creation && item.creation._id === selectedCreationId;
    });
  };

  render() {
    const columns = this.getColumns();
    if (!columns) {
      return null;
    }
    const listToRender = this.getListToRender();
    if (!(this.props as any).creationTableListBy) {
      return null;
    }

    const contextValue = {
      stickyIndices: this.getStickyIndices(),
      columns,
      ...(this.props as any).itemData,
    };

    const activeIndex = this.findActiveIndex();

    return (
      <div
        key={(this.props as any).id}
        className="creation-table"
        style={(this.props as any).style}
        data-testid="creation-table-scrollable"
      >
        <ListContext.Provider value={contextValue}>
          <List
            height={this.getListHeight()}
            width="100%"
            innerElementType={InnerElement}
            itemCount={listToRender.length}
            rowList={listToRender}
            itemSize={localConstant.ROW_HEIGHT}
            activeIndex={activeIndex}
          >
            {(this.props as any).rowGenerator}
          </List>
        </ListContext.Provider>
      </div>
    );
  }
}

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    tableColumns: ownProps.tableColumns ? ownProps.tableColumns : selectTableSettings(state).columns,
    creationTableListBy: selectCreationTableListBy(state),
    activity: selectCurrentActivity(state),
    course: selectCurrentCourse(state),
    selectedStudentId: state.selected.studentId,
    selectedCreationId: state.selected.submissionId,
    sortedColumn: selectSortedColumn(state),
    tableSettings: selectTableSettings(state),
    feedbackReceivedMap: CreationSelectors.selectFeedbackReceivedMap(state),
    viewType: selectCreationTableViewType(state),
  };
};

export default connect(mapStateToProps)(CreationTable);
