import classNames from 'classnames';
import FormFieldLabel from 'components/core/form/FieldLabel';
import { InlineInformation } from 'components/layout';
import Select from 'react-select';

export const Form = ({ children }: any) => {
  return (
    <form
      className="kritik-form"
      method="post"
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      {children}
    </form>
  );
};

export const FormMainError = ({ message, isOpen }: any) => {
  const classes = classNames('kritik-form-error', {
    'error-hidden': !isOpen,
  });
  return (
    <div className={classes}>
      <InlineInformation type="danger" title={message} />
    </div>
  );
};

export const FormFieldNotification = ({ type, message }: any) => {
  const classes = classNames('kritik-form-field__notification', {
    'notification-hidden': !message,
  });
  return (
    <div className={classes}>
      <InlineInformation type={type}> {message} </InlineInformation>
    </div>
  );
};

export const FormButtons = ({ align, children }: any) => {
  const classes = classNames('kritik-form-button-container', {
    'center-aligned': align === 'center',
  });
  return <div className={classes}>{children}</div>;
};

export const FormSelection = ({ label, isLoading, value, onChange, options, styles, onInputChange }: any) => {
  const customControl = styles && styles.control ? styles.control : {};
  const customSingleValue = styles && styles.singleValue ? styles.singleValue : {};
  const customStyles = {
    singleValue: (base: any) => {
      return {
        ...base,
        ...customSingleValue,
      };
    },
    control: (base: any) => {
      return {
        ...base,
        height: 36,
        minHeight: 36,
        ...customControl,
      };
    },
  };
  return (
    <div className="kritik-form-field">
      <FormFieldLabel label={label} />
      <div className="kritik-form-field__select">
        <Select
          classNamePrefix="kritik-field"
          isLoading={isLoading}
          value={value}
          onChange={onChange}
          onInputChange={onInputChange}
          options={options}
          styles={customStyles}
        />
      </div>
    </div>
  );
};

export default {
  Form,
  FormMainError,
  FormFieldNotification,
  FormButtons,
  FormSelection,
};
