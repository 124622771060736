import { combineReducers } from 'redux';

import * as types from 'types';

const errors = (state = '', action: any) => {
  switch (action.type) {
    case types.TOGGLE_LOGIN_MODE:
    case types.MANUAL_LOGIN_USER:
    case types.SIGNUP_USER:
    case types.LOGOUT_USER:
    case types.LOGIN_SUCCESS_USER:
    case types.SIGNUP_USER_SUCCESS:
    case types.FORGOT_PASSWORD_SUCCESS:
    case types.SIGNUP_USER_FAILURE:
    case types.RESET_PASSWORD_SUCCESS:
    case types.RESET_PASSWORD_FAILURE:
      return '';
    case types.LOGIN_ERROR_USER:
    case types.FORGOT_PASSWORD_FAILURE:
    case types.CHANGE_PASSWORD_FAILURE:
      return action.payload;
    default:
      return state;
  }
};

const isWaiting = (state = false, action: any) => {
  switch (action.type) {
    case types.MANUAL_LOGIN_USER:
    case types.SIGNUP_USER:
    case types.LOGOUT_USER:
    case types.RESET_PASSWORD:
      return true;
    case types.LOGIN_SUCCESS_USER:
    case types.SIGNUP_USER_SUCCESS:
    case types.LOGOUT_SUCCESS_USER:
    case types.LOGIN_ERROR_USER:
    case types.SIGNUP_USER_FAILURE:
    case types.LOGOUT_ERROR_USER:
    case types.FORGOT_PASSWORD_SUCCESS:
    case types.FORGOT_PASSWORD_FAILURE:
    case types.CHANGE_PASSWORD_SUCCESS:
    case types.CHANGE_PASSWORD_FAILURE:
    case types.RESET_PASSWORD_SUCCESS:
    case types.RESET_PASSWORD_FAILURE:
      return false;
    default:
      return state;
  }
};

const authenticated = (state = false, action: any) => {
  switch (action.type) {
    case types.GET_CURRENT_USER_SUCCESS:
      if (action.payload) {
        return true;
      }
      return false;

    case types.LOGIN_SUCCESS_USER:
    case types.SIGNUP_USER_SUCCESS:
    case types.LOGOUT_ERROR_USER:
      return true;
    case types.LOGIN_ERROR_USER:
    case types.SIGNUP_USER_FAILURE:
    case types.LOGOUT_SUCCESS_USER:
      return false;
    default:
      return state;
  }
};
const initialAuthUserState = { isDifferentOffSet: false };

const authUser = (state = initialAuthUserState, action: any) => {
  switch (action.type) {
    case types.GET_CURRENT_USER_SUCCESS:
      if (action.payload) {
        return action.payload;
      }
      return {};

    case types.LOGIN_SUCCESS_USER:
    case types.SIGNUP_USER_SUCCESS:
      return action.payload.user;

    case types.LOGIN_ERROR_USER:
    case types.SIGNUP_USER_FAILURE:
    case types.LOGOUT_SUCCESS_USER:
      return {};

    case types.UPDATE_AUTH_USER:
      if (action.payload && action.payload.profile) {
        return action.payload;
      }
      return state;
    case types.UPDATE_USER_SUCCESS:
      if (action.meta && action.meta.user && action.meta.user.profile) {
        if ((state as any)._id && action.meta.user._id == (state as any)._id) {
          return { ...state, ...{ profile: action.meta.user.profile } };
        }
      }
      return state;
    case types.SET_IS_DIFFERENT_OFFSET: {
      return {
        ...state,
        isDifferentOffSet: action.payload.isDifferentOffSet,
      };
    }
    default:
      return state;
  }
};

const item = (state = {}, action: any) => {
  switch (action.type) {
    case types.UPDATE_USER_SUCCESS:
    case types.GET_USER_SUCCESS:
      return action.payload;
    case types.GET_USER_REQUEST:
      return action.payload || null;
    default:
      return state;
  }
};

const isUploadingImage = (state = false, action: any) => {
  switch (action.type) {
    case types.UPLOAD_USER_IMAGE_REQUEST:
      return true;
    case types.UPLOAD_USER_IMAGE_SUCCESS:
    case types.UPLOAD_USER_IMAGE_FAILURE:
      return false;
    default:
      return state;
  }
};

const search = (state = { results: [], isLoading: false, value: '' }, action: any) => {
  switch (action.type) {
    case types.SEARCH_USERS_REQUEST:
      if (action.payload) {
        return { ...state, isLoading: true, value: action.payload.query };
      }
      return state;
    case types.SEARCH_USERS_SUCCESS:
      if (action.payload) {
        return { ...state, results: action.payload.items, isLoading: false };
      }
      return state;
    case types.SEARCH_USERS_FAILURE:
      return { ...state, results: [], isLoading: false };

    default:
      return state;
  }
};

const student = (state = { items: {} }, action: any) => {
  switch (action.type) {
    case types.GET_STUDENT_SUCCESS:
      if (action.payload && action.payload.params) {
        return {
          ...state,
          studentId: action.payload.items,
        };
      }
      return state;

    default:
      return state;
  }
};

const userReducer = combineReducers({
  isWaiting,
  authenticated,
  authUser,
  item,
  isUploadingImage,
  errors,
  search,
  student,
});

export default userReducer;
