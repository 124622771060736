import { CURRENCY_OPTIONS } from '@kritik/constants/admin';
import { sanitizeEmails } from '@kritik/utils/general';
import { createMultipleCoupons, createSingleCoupon } from 'actions/admin';
import { FormMainError, FormSelection } from 'components/Form/FormComponents';
import { Button } from 'components/buttons';
import FormFieldLabel from 'components/core/form/FieldLabel';
import FormNumberInput from 'components/core/form/NumberField';
import FormTextInput from 'components/core/form/TextField';
import InlineInformation from 'components/layout/InlineInformation';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { selectAuthUser } from 'selectors/user';
import CloseButton from '../CloseButton';

const MAX_COUPONS = 1000;

const DISCOUNT_OPTIONS = [
  { value: 'percentage', label: 'Percentage' },
  { value: 'amount', label: 'Amount' },
];

const CATEGORY_OPTIONS = [
  { value: 'singleUser', label: 'Single User' },
  { value: 'bookstore', label: 'Book Store' },
  { value: 'multipleUsers', label: 'Multiple Users' },
];

const CreateCouponModal = (props: any) => {
  const [createdBy, setCreatedBy] = useState(props.user.email);
  const [createdFor, setCreatedFor] = useState('');
  const [discountPercentage, setDiscountPercentage] = useState(100);
  const [discountAmount, setDiscountAmount] = useState(5);
  const [daysUntilExpiry, setDaysUntilExpiry] = useState(0);
  const [courseId, setCourseId] = useState('');
  const [numCoupons, setNumCoupons] = useState(1);
  const [discountType, setDiscountType] = useState(DISCOUNT_OPTIONS[0]);
  const [category, setCategory] = useState(CATEGORY_OPTIONS[0]);
  const [currency, setCurrency] = useState(CURRENCY_OPTIONS[0]);
  const [discountError, setDiscountError] = useState(false);
  const [numCouponsError, setNumCouponsError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const resetErrors = () => {
    if (discountError) {
      setDiscountError(false);
    }
    if (numCouponsError) {
      setNumCouponsError(false);
    }
    if (error) {
      setError('');
    }
  };

  useEffect(() => {
    resetErrors();
  }, [
    createdBy,
    createdFor,
    discountPercentage,
    discountAmount,
    daysUntilExpiry,
    courseId,
    numCoupons,
    discountType,
    category,
    currency,
  ]);

  const createCouponData = (userEmail: any) => {
    const discount =
      discountType.value === 'percentage'
        ? { percent_off: discountPercentage }
        : { amount_off: discountAmount * 100, currency: currency.value };
    return {
      name: userEmail,
      duration: 'once',
      redeem_by: daysUntilExpiry ? (Math.floor(Date.now() / 1000) + daysUntilExpiry * 86400).toString() : undefined,
      metadata: {
        createdBy,
        courseId: courseId || undefined,
      },
      ...discount,
    };
  };

  const createBulkCoupons = async (userEmails: any) => {
    const couponDataList = userEmails.map((userEmail: any) => {
      return createCouponData(userEmail);
    });
    await props.createMultipleCoupons({ couponDataList });
  };

  const handleSubmit = async () => {
    // @ts-expect-error TS(2367) FIXME: This condition will always return 'false' since th... Remove this comment to see the full error message
    if (discountPercentage === '' || discountPercentage <= 0 || discountPercentage > 100) {
      return setDiscountError(true);
    }
    // @ts-expect-error TS(2367) FIXME: This condition will always return 'false' since th... Remove this comment to see the full error message
    if (discountAmount === '' || discountAmount <= 0 || discountAmount > 100) {
      return setDiscountError(true);
    }
    // @ts-expect-error TS(2367) FIXME: This condition will always return 'false' since th... Remove this comment to see the full error message
    if (numCoupons === '' || numCoupons < 1 || numCoupons > MAX_COUPONS) {
      return setNumCouponsError(true);
    }
    resetErrors();
    setLoading(true);
    try {
      switch (category.value) {
        case 'bookstore':
          const bookstoreEmailList = Array.from({ length: numCoupons }, () => {
            return createdFor;
          });
          await createBulkCoupons(bookstoreEmailList);
          break;
        case 'multipleUsers':
          const userEmailList = sanitizeEmails(createdFor);
          await createBulkCoupons(userEmailList);
          break;
        case 'singleUser':
          const couponData = createCouponData(createdFor);
          await props.createSingleCoupon({ couponData });
          break;
      }
      setError('');
      window.location.reload();
    } catch (err) {
      console.log(err);
      setError(`Unable to generate coupons: ${err}`);
    }
    setLoading(false);
  };

  const checkAllFields = () => {
    return createdBy !== '' && createdFor !== '';
  };

  return (
    <div className="admin-panel-modal">
      <section className="admin-panel-modal__main admin-panel-modal__scrollable">
        <h1>Create a Coupon</h1>
        <FormTextInput
          name="createdBy"
          label="Created By"
          value={createdBy}
          onChange={(e: any) => {
            return setCreatedBy(e.target.value);
          }}
        />
        <FormSelection
          autoFocus
          label="Created For"
          value={category}
          onChange={(value: any) => {
            return setCategory(value);
          }}
          options={CATEGORY_OPTIONS}
        />
        {category.value === 'singleUser' ? (
          <FormTextInput
            name="email"
            label="User Email"
            value={createdFor}
            onChange={(e: any) => {
              return setCreatedFor(e.target.value);
            }}
          />
        ) : category.value === 'bookstore' ? (
          <FormTextInput
            name="bookstoreEmail"
            label="Bookstore Email"
            value={createdFor}
            onChange={(e: any) => {
              return setCreatedFor(e.target.value);
            }}
          />
        ) : (
          <React.Fragment>
            <FormFieldLabel label="User Emails" />
            <textarea
              value={createdFor}
              className="main-modal__textarea"
              name="emails"
              placeholder="email1,email2,email3..."
              onChange={(e) => {
                return setCreatedFor(e.target.value);
              }}
            />
          </React.Fragment>
        )}
        <FormSelection
          label="Type"
          value={discountType}
          onChange={(value: any) => {
            return setDiscountType(value);
          }}
          options={DISCOUNT_OPTIONS}
        />
        {discountType.value === 'percentage' ? (
          <FormNumberInput
            label="Discount Percentage (%)"
            name="discountPercentage"
            value={discountPercentage}
            onChange={(e: any) => {
              return setDiscountPercentage(e.target.value);
            }}
            max={100}
            step={1}
          />
        ) : (
          <React.Fragment>
            <FormSelection
              label="Currency"
              value={currency}
              onChange={(value: any) => {
                return setCurrency(value);
              }}
              options={CURRENCY_OPTIONS}
            />
            <FormNumberInput
              label="Discount Amount"
              name="discountAmount"
              value={discountAmount}
              onChange={(e: any) => {
                return setDiscountAmount(e.target.value);
              }}
              step={1}
            />
          </React.Fragment>
        )}

        <FormMainError message="Please enter a valid discount amount" isOpen={discountError} />
        <FormNumberInput
          label="Expires in (days)"
          name="daysUntilExpiry"
          value={daysUntilExpiry}
          onChange={(e: any) => {
            return setDaysUntilExpiry(e.target.value);
          }}
          min={0}
          step={1}
        />
        <InlineInformation style={{ marginBottom: '20px' }} title="Keep as 0 for no expiry date" />
        <FormTextInput
          name="courseId"
          label="Limit to Course ID (optional)"
          value={courseId}
          onChange={(e: any) => {
            return setCourseId(e.target.value);
          }}
        />
        {category.value === 'bookstore' && (
          <React.Fragment>
            <FormNumberInput
              label={`Number of Coupons to Create (1-${MAX_COUPONS})`}
              name="numCoupons"
              value={numCoupons}
              onChange={(e: any) => {
                return setNumCoupons(e.target.value);
              }}
              min={1}
              max={MAX_COUPONS}
              step={1}
            />
            <FormMainError
              message="Please enter a valid number of coupons to generate (1 - 30)"
              isOpen={numCouponsError}
            />
          </React.Fragment>
        )}
        <FormMainError message={error} isOpen={error} />
        <Button
          unavailable={!checkAllFields()}
          disabled={!checkAllFields()}
          loading={loading}
          className="admin-panel-submit-button"
          type="primary"
          onClick={handleSubmit}
        >
          Create
        </Button>
      </section>
      <CloseButton onClose={props.handleClose} />
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: selectAuthUser(state),
  };
};

export default connect(mapStateToProps, { createSingleCoupon, createMultipleCoupons })(CreateCouponModal);
