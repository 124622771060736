import { TranslatedText } from 'components/TranslatedText';

const LmsIntegration = ({ lmsCourseName }: any) => {
  return (
    <>
      <h3 className="panel-display__title">
        <TranslatedText i18nKey="LmsIntegration.Title" />
      </h3>
      <div className="panel-display__subtitle course-details__margin-bottom-md">
        <TranslatedText i18nKey="LmsIntegration.Subtitle" />
      </div>
      <div className="scoring-options__settings">
        <div className="scoring-options__text">
          <TranslatedText i18nKey="LmsIntegration.ConnectedCourse" values={{ lmsCourseName }} />
        </div>
      </div>
    </>
  );
};

export default LmsIntegration;
