import AdminIndexContainer from 'containers/Admin/Index';
import Page from 'pages/Page';
import { Component } from 'react';
import { connect } from 'react-redux';

class AdminIndex extends Component {
  getMetaData() {
    return {
      title: this.pageTitle(),
      meta: this.pageMeta(),
      link: this.pageLink(),
      script: this.pageScript(),
    };
  }

  pageTitle = () => {
    return 'Admin';
  };

  pageMeta = () => {
    return [];
  };

  pageLink = () => {
    return [];
  };

  pageScript = () => {
    return [];
  };

  render() {
    return (
      <Page {...this.getMetaData()}>
        <AdminIndexContainer {...this.props} />
      </Page>
    );
  }
}

function mapStateToProps(state: any) {
  return {};
}

export default connect(mapStateToProps, {})(AdminIndex);
