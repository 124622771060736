import GridContainer from 'components/layout/GridContainer/index';
import { DefaultActivitySettings } from './DefaultActivitySettings';
import StudentPermissionsOption from './StudentPermissionsOption';

const CourseSettingOptions = ({ course }: any) => {
  return (
    <GridContainer
      nodes={[<DefaultActivitySettings course={course} />, <StudentPermissionsOption course={course} />]}
    />
  );
};

export default CourseSettingOptions;
