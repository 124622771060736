import { AssignmentStatus } from '@kritik/types.generated';
import * as FormatUtils from '@kritik/utils/format';
import * as ActivityStatusUtils from '@kritik/utils/stage';
import InfoLabel from 'components/layout/InfoLabel';
import DateInterval from 'components/schedule/DateIntervalPicker';
import { ArrowDown } from 'images/icons/svg';
import { useEffect, useState } from 'react';

const colors = {
  'color-purple-350': '#b9b7e1',
  'color-grey-200': '#e0e0e0',
};

const SchedulerArrow = (props: any) => {
  const shouldHideInterval = () => {
    return !props.endDate || !props.startDate;
  };

  const getIntervalFromProps = () => {
    if (shouldHideInterval()) {
      return null;
    }
    return FormatUtils.getSecondsBetweenDates(props.endDate, props.startDate);
  };

  const [selectedInterval, setInterval] = useState(getIntervalFromProps());

  const durationInSeconds = getIntervalFromProps();

  useEffect(() => {
    setInterval(getIntervalFromProps());
  }, [props.startDate, props.endDate]);

  const isPast = ActivityStatusUtils.isPastDeadline({
    endDate: props.endDate,
  } as AssignmentStatus);

  const renderIntervalSelection = () => {
    if (shouldHideInterval()) {
      return null;
    }

    return (
      <InfoLabel
        type={isPast ? 'pending' : 'information'}
        label={
          <DateInterval
            value={selectedInterval}
            customInterval={durationInSeconds}
            onSelect={(interval: any) => {
              setInterval(interval);
              props.onChange(interval);
            }}
            startDate={props.startDate}
            tooltip={props.tooltip}
            footnote={props.footnote}
            disabled={isPast}
            testid={props.testid}
          />
        }
        testid={props.testid}
      />
    );
  };

  return (
    <div className="scheduler-arrow">
      <div className={isPast ? 'scheduler-separator__arrow__disable' : 'scheduler-separator__arrow'}>
        {/* @ts-expect-error TS(2322) FIXME: Type '{ children: Element; className: string; alt:... Remove this comment to see the full error message */}
        <div className="scheduler-separator__arrow-tip" alt="stage-arrow">
          <ArrowDown fill={isPast ? colors['color-grey-200'] : colors['color-purple-350']} />
        </div>
      </div>
      {renderIntervalSelection()}
    </div>
  );
};

SchedulerArrow.defaultProps = {
  footnote: '',
  tooltip: '',
  locale: null,
};

export default SchedulerArrow;
