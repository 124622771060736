import NewTabIcon from 'images/icons/fa/NewTab';

type NewTabButtonProps = {
  testid?: string;
  title?: string;
  label: string;
  href: string;
};
function NewTabButton(props: NewTabButtonProps) {
  return (
    <a className="btn btn-secondary" data-testid={props.testid} title={props.title} href={props.href} target="_blank">
      <div className="new-tab-btn">
        <NewTabIcon />
        <span className="new-tab-btn__label">{props.label}</span>
      </div>
    </a>
  );
}

export default NewTabButton;
