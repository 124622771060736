import classNames from 'classnames';

const FormSection = ({ hideSeparator, children }: any) => {
  const classes = classNames('kritik-form-section ', {
    'kritik-form-section--seamless': hideSeparator,
  });
  return <div className={classes}>{children}</div>;
};

FormSection.defaultProps = {
  hideSeparator: false,
};

export default FormSection;
