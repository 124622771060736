import { isDisputeResolved } from '@kritik/utils/creation/status';
import DisputeResolvedView from 'components/Creation/Disputing/Resolved';

const DisputeHandler = (props: any) => {
  if (isDisputeResolved(props.creation)) {
    return <DisputeResolvedView creation={props.creation} />;
  }
  return null;
};

DisputeHandler.defaultProps = {
  isOpen: false,
};

export default DisputeHandler;
