import Typography from 'components/core/Typography';

const SectionHeader = (props: any) => {
  return (
    <div className="section__header">
      <div className="section__title">
        <div>{props.title}</div>
        <div>{props.buttonMenu}</div>
      </div>
      <Typography>{props.info}</Typography>
    </div>
  );
};

export default SectionHeader;
