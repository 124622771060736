import { assignmentStatuses } from '@kritik/constants/stage';
import * as formatUtils from '@kritik/utils/format';
import * as activityStatusUtils from '@kritik/utils/stage';
import ScheduleDateInput from 'components/schedule/DateInput';
import DateWrapper from 'containers/Schedule/DateWrapper';
import StageContainer from 'containers/Schedule/StageContainer';
import STAGE_STATUS_ICONS from 'images/status-icons';
import { localize } from 'locales';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { updateSchedule } from 'redux/schedule/actions';
import ScheduleSelectors, { selectSchedule } from 'redux/schedule/selectors';
import { selectCurrentActivity } from 'selectors/activity';
import SchedulerArrow from './ArrowSeparator';
import localUtils from './utils';

const Schedule = (props: any) => {
  const activeStatus = activityStatusUtils.getActiveStatus(props.activity);

  const handleDateChange = (evalStartDate: any, evalEndDate: any) => {
    const newInterval = formatUtils.getSecondsBetweenDates(evalStartDate, evalEndDate);

    const newSchedule = {
      startDate: evalStartDate,
      evaluationEndDate: evalEndDate,
      evaluationInterval: newInterval,
    };
    props.updateSchedule(newSchedule);
  };

  const handleCustomTimeChange = (customTimeStr: any) => {
    const date = moment(customTimeStr, 'h:mm A');
    props.updateSchedule({
      customTime: [date.hours(), date.minutes()],
    });
  };

  if (!props.schedule) {
    return null;
  }

  const changeStartDate = (newDate: any) => {
    const date = localUtils.roundSelectedDate(newDate);
    handleDateChange(date, props.schedule.evaluationEndDate);
  };

  const changeEndDate = (newDate: any) => {
    const date = localUtils.roundSelectedDate(newDate);
    handleDateChange(props.schedule.startDate, date);
  };
  return (
    <div className="status-dates-container">
      <StageContainer>
        <SchedulerArrow image={<img aria-hidden="true" src={STAGE_STATUS_ICONS.EVALUATE} />} hideArrow />
        <DateWrapper
          title={localize({ message: 'CalibrationSchedule.Evaluate.Title' })}
          description={localize({ message: 'CalibrationSchedule.Evaluate.Description' })}
          dateInput={
            <React.Fragment>
              <ScheduleDateInput
                testid="calibration-evaluate_start-date"
                label={localize({ message: 'Activity.Schedule.StartDate' })}
                value={props.schedule.startDate}
                onDateChange={changeStartDate}
                minDate={new Date()}
                disabled={
                  !(
                    [assignmentStatuses.DRAFT, assignmentStatuses.CREATE, assignmentStatuses.PROCESSING1] as string[]
                  ).includes(activeStatus.name)
                }
                error={props.pastDateError?.Evaluate?.startDate}
                customTime={props.schedule.customTime}
                onCustomTimeChange={handleCustomTimeChange}
              />
              <ScheduleDateInput
                testid="calibration-evaluate_due-date"
                label={localize({ message: 'Activity.Schedule.DueDate' })}
                value={props.schedule.evaluationEndDate}
                onDateChange={changeEndDate}
                minDate={localUtils.getMinDateFromPreviousEnd(props.schedule.startDate)}
                disabled={
                  !(
                    [
                      assignmentStatuses.DRAFT,
                      assignmentStatuses.CREATE,
                      assignmentStatuses.PROCESSING1,
                      assignmentStatuses.EVALUATE,
                    ] as string[]
                  ).includes(activeStatus.name)
                }
                customTime={props.schedule.customTime}
                onCustomTimeChange={handleCustomTimeChange}
              />
            </React.Fragment>
          }
        />
      </StageContainer>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    schedule: selectSchedule(state),
    activity: selectCurrentActivity(state),
    pastDateError: ScheduleSelectors.selectPastDateError(state),
  };
};

export default connect(mapStateToProps, {
  selectSchedule,
  updateSchedule,
})(Schedule);
