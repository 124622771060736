import InstructorCreationViewContainer from 'containers/CreationOverview';
import Page from 'pages/Page';
import { link, meta } from 'pages/assets';
import { connect } from 'react-redux';

import { Activity } from '@kritik/types.generated';
import { isGroupAssignment, isGroupPresentationActivity } from '@kritik/utils/activity';
import { localize } from 'locales';
import { getAssignment as getActivity } from 'selectors/activity';

type InstructorCreationViewProps = {
  activity: Activity;
};

const InstructorCreationView = (props: InstructorCreationViewProps) => {
  return (
    <Page
      title={localize({
        message:
          props.activity && (isGroupAssignment(props.activity) || isGroupPresentationActivity(props.activity))
            ? 'Creation.Group.PageTitle'
            : 'Creation.Student.PageTitle',
      })}
      meta={meta}
      link={link}
    >
      <InstructorCreationViewContainer {...props} />
    </Page>
  );
};

function mapStateToProps(state: any) {
  return {
    activity: getActivity(state, state.selected.assignmentId),
  };
}

export default connect(mapStateToProps, {})(InstructorCreationView);
