import * as FormatUtils from '@kritik/utils/format';
import { getEnrollLink } from '@kritik/utils/general';
import {
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Tooltip,
} from '@material-ui/core';
import { navigateToCoursePage } from 'actions/courses';
import { updateAuthUser } from 'actions/users';
import { AuthUser, CourseListReturn } from 'app-types';
import DuplicateDialog from 'components/Course/List/DuplicateDialog';
import ElipsesMenu from 'components/General/ElipsesMenu';
import ShortenText from 'components/General/ShortenText';
import LmsCoursesModal from 'components/LmsGradeSync/LmsCoursesModal';
import { TranslatedText } from 'components/TranslatedText';
import Button from 'components/buttons/Button';
import { InlineInformation } from 'components/layout';
import {
  addCollaboratorMutation,
  archiveCourseMutation,
  deleteCourseMutation,
  duplicateCourseMutation,
  unArchiveCourseMutation,
} from 'hooks/course';
import { localize } from 'locales';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { push } from 'router';
import { studentService } from 'services';
import ConfigService from 'services/config';
import { formatFullCourseBreadcrumb, formatPartialCourseBreadcrumb } from 'utils/format';
import { isCourseConnected } from 'utils/lms';
import { trackEvent } from 'utils/userEvents';
import { RouterProp, withRouter } from 'utils/withRouter';

const DropDownItems = {
  create_activity: localize({ message: 'CourseCard.Items.CreateActivity' }),
  invite_students: localize({ message: 'CourseCard.Items.InviteStudents' }),
  view_course_info: localize({ message: 'CourseCard.Items.ViewCourseInfo' }),
};

const CardMenu = ({
  onEdit,
  onCreateActivity,
  onDelete,
  onInvite,
  onDuplicate,
  onArchive,
  onUnarchive,
  isCourseOwner,
  isCourseCompleted,
  title,
  couldBeConnected,
  onConnect,
}: any) => {
  const archiveOption = isCourseCompleted
    ? {
        label: localize({ message: 'CourseCard.Items.Unarchive' }),
        action: () => onUnarchive(),
        testid: 'unarchive-course',
      }
    : {
        label: localize({ message: 'CourseCard.Items.Archive' }),
        action: () => onArchive(),
        testid: 'archive-course',
      };

  const ownerOptions = isCourseOwner
    ? [
        archiveOption,
        {
          label: localize({ message: 'CourseCard.Items.Delete' }),
          action: onDelete,
          testid: 'delete-course',
        },
      ]
    : [];

  const dropDownItemClick = (item: string) => {
    if (item === DropDownItems.create_activity) {
      onCreateActivity();
    } else if (item === DropDownItems.invite_students) {
      onInvite();
    } else if (item === DropDownItems.view_course_info) {
      onEdit();
    }
  };

  const options = [
    {
      label: DropDownItems.create_activity,
      action: () => dropDownItemClick(DropDownItems.create_activity),
      testid: 'create-activity-option',
    },
    {
      label: DropDownItems.invite_students,
      action: () => dropDownItemClick(DropDownItems.invite_students),
      testid: 'invite-student-option',
    },
    {
      label: DropDownItems.view_course_info,
      action: () => dropDownItemClick(DropDownItems.view_course_info),
      testid: 'view-course-info-option',
    },
    {
      label: 'separator',
    },
    {
      label: localize({ message: 'CourseCard.Items.Duplicate' }),
      action: () => onDuplicate(),
      testid: 'duplicate-course-option',
    },
    ...ownerOptions,
  ].concat(
    couldBeConnected
      ? [
          {
            label: 'separator',
          },
          {
            label: localize({ message: 'CourseCard.Items.Connect' }),
            action: () => onConnect(),
            testid: 'connect-course-option',
          },
        ]
      : []
  );

  return (
    <ElipsesMenu
      className="course-card-menu"
      options={options}
      testid="course-card-menu"
      labelI18nKey="CourseCard.Menu.Label"
      labelI18nOptions={{ title }}
    />
  );
};

CardMenu.defaultProps = {
  isCourseOwner: false,
  isCourseCompleted: false,
};

type CourseCardProps = {
  course: CourseListReturn & { user: { id: string } };
  user: UserWithAuthUser & { authUser: { id: string } };
  authUser: AuthUser;
  updateAuthUser: (user: any) => void;
  router: RouterProp;
};

const CourseCard = (props: CourseCardProps) => {
  const [isDeletingCourse, setIsDeletingCourse] = React.useState(false);
  const [enrollLink, setEnrollLink] = React.useState(null);
  const [showDuplicateDialog, setShowDuplicateDialog] = React.useState(false);
  const [showConnectCourseModal, setShowConnectCourseModal] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const deleteCourse = deleteCourseMutation({
    onError: () => {
      setIsDeletingCourse(false);
    },
  });
  const duplicateCourse = duplicateCourseMutation(props.authUser);
  const addCollaborator = addCollaboratorMutation(props.course._id);
  const archiveCourseMutate = archiveCourseMutation();
  const unArchiveCourseMutate = unArchiveCourseMutation();

  const isInstructorInCourse = props.course.userRole === 'instructor';
  const isStudentInCourse = props.course.userRole === 'student';

  React.useEffect(() => {
    const getEnrollLinkInit = async () => {
      const enrollLink = await getEnrollLink({
        courseId: props.course._id,
        email: props.user.authUser.email,
      });
      if (enrollLink) {
        setEnrollLink(enrollLink);
      }
    };
    void getEnrollLinkInit();
  }, []);

  const getCardLink = () => {
    if (hasJoinedCourse()) {
      return `/course/${props.course._id}/assignments`;
    }
    return `${window.location.origin}${enrollLink}`;
  };

  const toggleDuplicateDialog = () => {
    setShowDuplicateDialog(!showDuplicateDialog);
  };

  const hasJoinedCourse = () => {
    return Boolean(props.course.isUserEnrolledInCourse);
  };

  const archiveCourse = () => {
    archiveCourseMutate.mutate(props.course._id);
  };

  const unarchiveCourse = () => {
    unArchiveCourseMutate.mutate(props.course._id);
  };

  const handleDeleteCourse = () => {
    deleteCourse.mutate(props.course._id);
  };

  const handleDuplicateCourse = (data: any) => {
    duplicateCourse.mutate(data);
    toggleDuplicateDialog();
  };

  const toggleDeleteConfirmation = () => {
    setIsDeletingCourse(!isDeletingCourse);
  };

  const navigateToCoursePage = () => {
    props.router.push(`/course/${props.course._id}`);
  };

  const handleEnrollStudentInInstitutionPaidCourse = async () => {
    setIsLoading(true);
    try {
      const response = await studentService().enrollStudentInInstitutionPaidCourse(props.course._id);
      if (response.status === 200) {
        props.router.push(`/course/${props.course._id}/assignments`);
      }
    } catch (err) {
      setIsLoading(false);
      props.router.push(enrollLink);
    }
  };

  const joinAsCollaborator = () => {
    addCollaborator.mutate(props.user, {
      onSuccess: async () => {
        const {
          data: { user },
        } = await new ConfigService().get();
        props.updateAuthUser(user);
        props.router.push(`/course/${props.course._id}`);
      },
    });
  };

  const handleCardBtnClick = () => {
    const isInstitutionPaidCourse = props.course.price === 0;
    if (isInstitutionPaidCourse && isStudentInCourse) {
      return handleEnrollStudentInInstitutionPaidCourse();
    }
    if (isInstructorInCourse) {
      return joinAsCollaborator();
    }
    if (enrollLink) {
      return props.router.push(enrollLink);
    }
    return props.router.push(`/course/${props.course._id}`);
  };

  const renderInvitedOptions = () => {
    const isInstitutionPaidCourse = props.course.price === 0;
    const buttonText = isInstructorInCourse
      ? 'Collaborate on Course'
      : isInstitutionPaidCourse
        ? 'Enroll in Course'
        : 'Purchase Subscription';

    const testid = isInstructorInCourse
      ? 'collaborate-in-course'
      : isInstitutionPaidCourse
        ? 'enroll-in-course'
        : 'purchase-subscription';

    return (
      <Button
        type="primary"
        disabled={!enrollLink}
        onClick={() => handleCardBtnClick()}
        loading={isLoading}
        data-testid={testid}
      >
        {buttonText}
      </Button>
    );
  };

  const renderInstructorOptions = () => {
    if (!Boolean(isInstructorInCourse)) {
      return;
    }

    return (
      <React.Fragment>
        <span className="card-course-footer__enrolled">
          <TranslatedText
            i18nKey="CourseCard.StudentsEnrolled"
            values={{ count: props.course.enrolledStudentsCount }}
          />
        </span>
        <div className="card-course-footer__button">
          <CardMenu
            onCreateActivity={() => {
              trackEvent('Create Activity from Course Card', props.authUser, {
                courseId: props.course._id,
              });
              return props.router.push(`/course/${props.course._id}/create-activity`);
            }}
            onEdit={() => {
              trackEvent('View Course Info from Course Card', props.authUser, {
                courseId: props.course._id,
              });
              return navigateToCoursePage();
            }}
            onDelete={() => {
              return toggleDeleteConfirmation();
            }}
            onDuplicate={() => {
              return toggleDuplicateDialog();
            }}
            onInvite={() => {
              trackEvent('Invite Students from Course Card', props.authUser, {
                courseId: props.course._id,
              });
              return props.router.push(`course/${props.course._id}/roster`);
            }}
            onArchive={() => {
              return archiveCourse();
            }}
            onUnarchive={() => {
              return unarchiveCourse();
            }}
            onConnect={() => {
              setShowConnectCourseModal(true);
            }}
            isCourseOwner={props.course.user.id === props.user.authUser.id}
            isCourseCompleted={props.course.completed}
            title={formatPartialCourseBreadcrumb(props.course)}
            couldBeConnected={props.course.hasInstitutionEdlinkIntegrations && !isCourseConnected(props.course)}
          />
        </div>
        {isDeletingCourse && renderConfirmCourseDeletion()}
        {showDuplicateDialog && (
          <DuplicateDialog
            open={showDuplicateDialog}
            onClose={() => {
              return toggleDuplicateDialog();
            }}
            course={(props as any).course}
            onSubmit={handleDuplicateCourse}
          />
        )}
        {showConnectCourseModal && (
          <LmsCoursesModal
            open={showConnectCourseModal}
            course={props.course}
            authUser={props.authUser}
            close={() => {
              setShowConnectCourseModal(false);
            }}
          />
        )}
      </React.Fragment>
    );
  };

  const renderCardFooter = () => {
    if (hasJoinedCourse()) {
      if (isInstructorInCourse) {
        return renderInstructorOptions();
      }
    } else {
      return renderInvitedOptions();
    }
  };

  const renderConfirmCourseDeletion = () => {
    return (
      <Dialog
        open={isDeletingCourse}
        onClose={() => {
          return toggleDeleteConfirmation();
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Are you sure you want to delete this course?</DialogTitle>
        <DialogContent>
          <InlineInformation type="danger" title="Deleting this course cannot be undone" />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              return toggleDeleteConfirmation();
            }}
            type="secondary"
            autoFocus
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              return handleDeleteCourse();
            }}
            type="primary"
          >
            Delete Course
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderRoleLabel = () => {
    if (isInstructorInCourse && props.course.user.id !== props.user.authUser.id) {
      return (
        <div className="course-card__role-label" data-testid="course-card-role-instructor">
          <TranslatedText i18nKey="Role.Collaborator" />
        </div>
      );
    }
    if (isInstructorInCourse) {
      return (
        <div className="course-card__role-label" data-testid="course-card-role-instructor">
          <TranslatedText i18nKey="Role.Instructor" />
        </div>
      );
    }
    if (isStudentInCourse) {
      return (
        <div className="course-card__role-label" data-testid="course-card-role-student">
          <TranslatedText i18nKey="Role.Student" />
        </div>
      );
    }
  };

  const { course } = props;

  const partialCourseTitle = formatPartialCourseBreadcrumb(course);
  const fullCourseTitle = formatFullCourseBreadcrumb(course);
  const needsTitleTooltip = partialCourseTitle !== fullCourseTitle;
  const description = FormatUtils.stripHtmlTags(course.description);

  const lmsConnectedCourseId = course.lms?.connectedCourseId;
  const lmsConnectedCourseName = course.lms?.connectedCourseName;
  return (
    <Card className="course-card__card">
      <CardContent className="course-card__content">
        <span className="course-card__header">
          {hasJoinedCourse() ? (
            <Link
              to={getCardLink()}
              data-testid="course-card-link"
              title={needsTitleTooltip ? fullCourseTitle : undefined}
            >
              {partialCourseTitle}
            </Link>
          ) : (
            <span className="course-card__course-title" title={needsTitleTooltip ? fullCourseTitle : undefined}>
              {partialCourseTitle}
            </span>
          )}

          {lmsConnectedCourseId && isInstructorInCourse && (
            <Tooltip
              title={
                <>
                  <p>{`${localize({
                    message: 'CourseCard.Items.LMSLinkIcon.ToolTip1',
                  })} ${lmsConnectedCourseName}.`}</p>
                  <p>{localize({ message: 'CourseCard.Items.LMSLinkIcon.ToolTip2' })}</p>
                </>
              }
              placement="top"
            >
              <i className="fa fa-link fa-rotate-by course-card__lms-link" data-testid="link-icon" />
            </Tooltip>
          )}
        </span>
        <div className="course-card__description">
          <ShortenText label={description} maxLength={70} />
        </div>
        {renderRoleLabel()}
      </CardContent>
      <div>
        {(course.userRole === 'instructor' || !hasJoinedCourse()) && (
          <Divider flexItem className="course-card__divider" />
        )}
        <CardContent className="card-course-footer">{renderCardFooter()}</CardContent>
      </div>
    </Card>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
    authUser: state.user.authUser,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    navigateToCoursePage,
    push,
    updateAuthUser,
  })(CourseCard)
);
