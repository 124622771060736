import * as AssignmentStatusUtils from '@kritik/utils/stage';
import { TranslatedText } from 'components/TranslatedText';
import InlineInformation from 'components/layout/InlineInformation';
import moment from 'moment-timezone';

export const FinalizedStatus = ({ activity }: any) => {
  const finalizedStage = AssignmentStatusUtils.getFinalizedStage(activity);
  const date = new Date(finalizedStage.startDate);

  return (
    <div className="grading-status__body">
      {AssignmentStatusUtils.isFinalized({ assignment: activity }) && (
        <div>{moment(date).format('ddd[,] MMM D [@] h:mm A')}</div>
      )}

      <InlineInformation type={AssignmentStatusUtils.isFinalized({ assignment: activity }) ? 'success' : 'information'}>
        <div className="mark-as-complete__banner">
          <div className="mark-as-complete__text-success" data-testid="activity-marked-complete-banner">
            <TranslatedText i18nKey="FinalizedStatus.Info" />
          </div>
        </div>
      </InlineInformation>
    </div>
  );
};
