import { Paper } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Activity } from '@kritik/types.generated';
import { isEvaluateOrLater, isFeedbackOrLater } from '@kritik/utils/stage';
import LateCreationPenaltyPercentage from 'components/ActivityEdit/AdvancedOptions/Fields/LateCreationPenaltyPercentage';
import FormInputSwitch from 'components/Form/FormInputSwitch';
import { TranslatedText } from 'components/TranslatedText';
import { Button } from 'components/buttons';
import InfoPopup from 'components/core/InfoPopup';
import { InlineInformation } from 'components/layout';
import { useForm } from 'react-final-form';

type LateSubmissionSettingsProps = {
  showNoLateSubmissionsAllowedNotice: boolean;
  acceptLateCreations: boolean;
  onAcceptLateCreationsChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  autoAcceptLateCreations: boolean;
  onAutoAcceptLateCreationsChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  acceptLateEvaluations: boolean;
  onAcceptLateEvaluationsChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  autoAcceptLateEvaluations: boolean;
  onAutoAcceptLateEvaluationsChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  activity?: Activity;
  isDuplicatingActivity?: boolean;
  hideLateEvaluationsSettings?: boolean;
  isEditingCourse?: boolean;
};

export function LateSubmissionSettings(props: LateSubmissionSettingsProps) {
  const { t } = useTranslation();
  const form = useForm();
  const [isExpanded, setIsExpanded] = React.useState(false);
  const { lateCreationPenaltyPercentage } = form.getState().values;

  return (
    <div className="late-submission-settings">
      <div className="late-submission-settings__notice">
        {props.showNoLateSubmissionsAllowedNotice && (
          <InlineInformation type="warning">
            <TranslatedText i18nKey="Activity.removeStudentFromActivityAfterMissedCreation.NoLateSubmissionInfo" />
          </InlineInformation>
        )}
      </div>
      <div className="late-submission-settings__header">
        <h2 className="new-header-2 late-submission-settings__title">{t('LateSubmissionSettings.Title')}</h2>
        <div className="late-submission-settings__popup">
          <InfoPopup
            title={t('LateSubmissionSettings.InfoPopup.LateSubmission.Title')}
            description={
              <div>
                <TranslatedText i18nKey="LateSubmissionSettings.InfoPopup.LateSubmission.Description" t={t} />
              </div>
            }
          />
        </div>
        <Button
          type="secondary"
          className="late-submission-settings__expand-collapse-button"
          inputType="button"
          onClick={() => setIsExpanded(!isExpanded)}
          testid="late-submission-settings-expand-collapse-button"
        >
          {isExpanded ? t('LateSubmissionSettings.Collapse') : t('LateSubmissionSettings.Expand')}
        </Button>
      </div>

      <p className="late-submission-settings__description">{t('LateSubmissionSettings.Description')}</p>
      {!isExpanded && (
        <>
          <div className="late-submission-settings-collapsed">
            <span className="late-submission-settings-collapsed__label">
              {t('LateSubmissionSettings.AllowLateSubmissionsFor')}:
            </span>
            <div className="late-submission-settings-collapsed__list">
              {!props.acceptLateCreations && !props.acceptLateEvaluations && (
                <span>{t('LateSubmissionSettings.NoLateSubmissions')}</span>
              )}
              {props.acceptLateCreations && (
                <span>
                  {t('LateSubmissionSettings.LateCreations')}:{' '}
                  {props.autoAcceptLateCreations
                    ? t('LateSubmissionSettings.AutoAccept')
                    : t('LateSubmissionSettings.Manual')}
                </span>
              )}
              {props.acceptLateEvaluations && !props.hideLateEvaluationsSettings && (
                <span>
                  {t('LateSubmissionSettings.LateEvaluations')}:{' '}
                  {props.autoAcceptLateEvaluations
                    ? t('LateSubmissionSettings.AutoAccept')
                    : t('LateSubmissionSettings.Manual')}
                </span>
              )}
            </div>
          </div>
          {props.acceptLateCreations && lateCreationPenaltyPercentage > 0 && (
            <div className="late-submission-settings-collapsed__penalty">
              <TranslatedText
                i18nKey="Activity.LateCreationPenaltyPercentageField.HelpText"
                values={{ value: lateCreationPenaltyPercentage }}
              />
            </div>
          )}
        </>
      )}
      {isExpanded && (
        <div className="late-submission-settings-expanded">
          <div className="late-submission-settings-expanded__switch-container">
            <FormInputSwitch
              checked={props.acceptLateCreations}
              helpText={t('LateSubmissionSettings.AllowLateCreations.HelpText')}
              label={t('LateSubmissionSettings.AllowLateCreations.Label')}
              name="acceptLateCreations"
              onChange={props.onAcceptLateCreationsChange}
              disabled={isEvaluateOrLater(props.activity) && !props.isDuplicatingActivity}
              testId="switch-late-submissions-create-stage"
            />
            {props.acceptLateCreations && (
              <Paper variant="outlined" className="late-submission-settings-expanded__subitem">
                <FormInputSwitch
                  checked={props.autoAcceptLateCreations}
                  helpText={t('LateSubmissionSettings.AutoAcceptLateCreations.HelpText')}
                  label={t('LateSubmissionSettings.AutoAcceptLateCreations.Label')}
                  name="autoAcceptLateCreations"
                  onChange={props.onAutoAcceptLateCreationsChange}
                  labelPopup={{
                    title: t('LateSubmissionSettings.InfoPopup.AutoAcceptLateCreation.Title'),
                    description: (
                      <TranslatedText
                        i18nKey="LateSubmissionSettings.InfoPopup.AutoAcceptLateCreation.Description"
                        t={t}
                      />
                    ),
                  }}
                  disabled={isEvaluateOrLater(props.activity) && !props.isDuplicatingActivity}
                  testId="switch-autoAcceptLateCreations"
                />
              </Paper>
            )}
          </div>
          {!props.isEditingCourse && props.acceptLateCreations && (
            <LateCreationPenaltyPercentage isDuplicatingActivity={props.isDuplicatingActivity} />
          )}
          {!props.hideLateEvaluationsSettings && (
            <div className="late-submission-settings-expanded__switch-container">
              <FormInputSwitch
                checked={props.acceptLateEvaluations}
                helpText={t('LateSubmissionSettings.AllowLateEvaluations.HelpText')}
                label={t('LateSubmissionSettings.AllowLateEvaluations.Label')}
                name="acceptLateEvaluations"
                onChange={props.onAcceptLateEvaluationsChange}
                disabled={isFeedbackOrLater({ assignment: props.activity }) && !props.isDuplicatingActivity}
                testId="switch-acceptLateEvaluations"
              />
              {props.acceptLateEvaluations && (
                <Paper variant="outlined" className="late-submission-settings-expanded__subitem">
                  <FormInputSwitch
                    checked={props.autoAcceptLateEvaluations}
                    helpText={t('LateSubmissionSettings.AutoAcceptLateEvaluations.HelpText')}
                    label={t('LateSubmissionSettings.AutoAcceptLateEvaluations.Label')}
                    name="autoAcceptLateEvaluations"
                    onChange={props.onAutoAcceptLateEvaluationsChange}
                    disabled={isFeedbackOrLater({ assignment: props.activity }) && !props.isDuplicatingActivity}
                    testId="switch-autoAcceptLateEvaluations"
                  />
                </Paper>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
