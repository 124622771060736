import Field from 'components/core/form/Field';
import FormFieldInfo from 'components/core/form/FieldInfo';
import FieldLabel from 'components/core/form/FieldLabel';
import DateInput from 'components/core/input/DateAndTime';
import { InlineInformation } from 'components/layout';
import { connect } from 'react-redux';
import { selectTimeZone } from 'selectors/user';

function FootNote(props: { label?: string }) {
  if (!props.label) {
    return null;
  }
  return <span className="date-input__foot-note">{props.label}</span>;
}
function ScheduleDateInput(props: any) {
  return (
    <Field>
      <FieldLabel label={props.label} color={props.labelColor || 'light'} />
      <DateInput
        testid={props.testid}
        disableMinDate={props.disableMinDate}
        disabled={props.disabled}
        minDate={props.minDate}
        onDateChange={props.onDateChange}
        value={props.value || ''}
        customTime={props.customTime}
        onCustomTimeChange={props.onCustomTimeChange}
        timeZone={props.timeZone}
        label={`${props.title} ${props.label}`}
      />
      <FootNote label={props.footNote} />
      <FormFieldInfo label={props.error && <InlineInformation type="danger">{props.error}</InlineInformation>} />
    </Field>
  );
}

const mapStateToProps = (state: any) => {
  return {
    timeZone: selectTimeZone(state),
  };
};

ScheduleDateInput.defaultProps = {
  error: null,
  disableMinDate: false,
  minDate: new Date(),
  disabled: false,
  customTime: null,
};

export default connect(mapStateToProps)(ScheduleDateInput);
