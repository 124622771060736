import { Activity } from '@kritik/types.generated';
import { GradingScoreUtil } from '@kritik/utils/grade';
import { localize } from 'locales';
import { getKritikScoreLevel } from 'utils/student';

type ScoreHistory = {
  _id: string;
  assignment: string;
  criticalScore: number;
  gradingScore: number;
  motivationalScore: number;
};

const getPrevCurrentScoreHistories = (scoreHistories: ScoreHistory[], activity: Activity) => {
  const activityIndex = scoreHistories.findIndex((history: ScoreHistory) => {
    return history.assignment === activity._id;
  });
  return [scoreHistories[activityIndex - 1], scoreHistories[activityIndex]];
};

export const getRankLevelChange = (scoreHistories: any, activity: any) => {
  const historiesLength = scoreHistories.length;
  if (historiesLength === 0) {
    return 0;
  }
  if (historiesLength === 1) {
    return (
      getKritikScoreLevel(scoreHistories[0].gradingScore) - getKritikScoreLevel(GradingScoreUtil.DEFAULT_GRADING_SCORE)
    );
  }
  const [prev, current] = getPrevCurrentScoreHistories(scoreHistories, activity);
  // temporary fix for a student enroll and missed one of the activities, then he wouldn't
  // have the activity's score hitsory
  if (!current) {
    return 0;
  }
  if (!prev) {
    return getKritikScoreLevel(current.gradingScore) - getKritikScoreLevel(GradingScoreUtil.DEFAULT_GRADING_SCORE);
  }
  return getKritikScoreLevel(current.gradingScore) - getKritikScoreLevel(prev.gradingScore);
};

export const useGetRankLevelChange = (scoreHistories: ScoreHistory[], activity: Activity) => {
  const rankLevelChange = getRankLevelChange(scoreHistories, activity);

  if (rankLevelChange > 0) {
    return localize({ message: 'Student.RankLevel.RankedUp' });
  }
  if (rankLevelChange < 0) {
    return localize({ message: 'Student.RankLevel.RankedDown' });
  }
  return localize({ message: 'Student.RankLevel.NoChange' });
};
