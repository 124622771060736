import * as courseUtils from '@kritik/utils/course';
import { COURSE_FIELD_NAMES } from 'components/Course/Edit/constants';
import * as _ from 'lodash-es';

const getMarkingSchemeTotal = (markingScheme: any) => {
  // @ts-expect-error TS(2345) FIXME: Argument of type '{ markingScheme: any; }' is not ... Remove this comment to see the full error message
  const { total } = courseUtils.getMarkingSchemeBreakDowns({ markingScheme });
  return total;
};

const getInitialValues = ({ course }: any) => {
  if (course) {
    const markingScheme = _.pick(course.markingScheme, [
      'kritikScore',
      'passedSubmissions',
      'feedbackCommunicationEffectiveness',
      'participationInFof',
    ]);
    return {
      isPercentage: course.markingScheme.isPercentage,
      defaultActivityWeight: course.defaultActivityWeight,
      startingScore: course.startingScore,
      markingScheme: {
        ...markingScheme,
        totalWeight: getMarkingSchemeTotal(markingScheme),
      },
    };
  }
  return {
    isPercentage: true,
    startingScore: 0,
    defaultActivityWeight: 1,
    markingScheme: {
      kritikScore: 20,
      passedSubmissions: 50,
      feedbackCommunicationEffectiveness: 20,
      participationInFof: 10,
      totalWeight: 100,
    },
  };
};

const getDataFromForm = (values: any) => {
  const info = {
    [COURSE_FIELD_NAMES.DEFAULT_ACTIVITY_WEIGHT]: values.defaultActivityWeight,
    markingScheme: {
      isPercentage: values.isPercentage,
      kritikScore: values.markingScheme.kritikScore,
      passedSubmissions: values.markingScheme.passedSubmissions,
      feedbackCommunicationEffectiveness: values.markingScheme.feedbackCommunicationEffectiveness,
      participationInFof: values.markingScheme.participationInFof,
    },
    startingScore: Number.parseInt(values.startingScore, 10),
  };
  return info;
};

export default {
  getInitialValues,
  getDataFromForm,
  getMarkingSchemeTotal,
};
