import Button from 'components/buttons/Button';
import FormField from 'components/core/form/Field';
import SelectField from 'components/core/form/SelectField';
import ButtonContainer from 'components/core/form/SubmitButtons';
import TextField from 'components/core/form/TextField';
import { EdlinkIntegration } from 'old-common/types.generated';
import { useState } from 'react';
import { adminService } from 'services';
import * as ErrorUtils from 'utils/error';

type Props = {
  initialValues?: EdlinkIntegration;
  hideForm: () => void;
  onError: (message: string) => void;
  onSuccess: () => void;
  institutionId: string;
};

const LMSOptions = ['Canvas', 'D2L', 'Moodle', 'Blackboard', 'Coursera', 'Other'];

const CreateIntegrationForm = ({ initialValues, hideForm, onError, onSuccess, institutionId }: Props) => {
  const [integrationId, setIntegrationId] = useState(initialValues?.edlinkIntegrationId || '');
  const [accessToken, setAccessToken] = useState(initialValues?.edlinkIntegrationAccessToken || '');
  const [lms, setLMS] = useState(initialValues?.lms || 'Other');
  const [name, setName] = useState(initialValues?.name || '');
  const [lmsClientId, setLmsClientId] = useState(initialValues?.lmsClientId || '');
  const [redirectUrl, setRedirectUrl] = useState(initialValues?.redirectUrl || '');
  const [lmsSecret, setLmsSecret] = useState(initialValues?.lmsSecret || '');
  const [allowNonLmsCollaborators, setAllowNonLmsCollaborators] = useState(initialValues?.allowNonLmsCollaborators);
  const [isLoading, setIsLoading] = useState(false);

  const onSave = async (e: any) => {
    e.preventDefault();
    if (integrationId.length !== 36) {
      onError('Integration ID should always be 36 characters long.');
      return;
    }
    if (accessToken.length !== 32) {
      onError('Access Token should always be 32 characters long.');
      return;
    }
    if (!lms) {
      onError('Please select an LMS.');
      return;
    }
    if (!name) {
      onError('Please enter a name for this integration.');
      return;
    }
    setIsLoading(true);
    const data = {
      edlinkIntegrationId: integrationId,
      edlinkIntegrationAccessToken: accessToken,
      institution: institutionId,
      lms,
      name,
      allowNonLmsCollaborators,
      lmsClientId: lms === 'Canvas' ? lmsClientId || null : null,
      lmsSecret: lms === 'Canvas' ? lmsSecret || null : null,
      redirectUrl: lms === 'Canvas' ? redirectUrl || null : null,
    };

    try {
      initialValues
        ? await adminService().updateIntegration(initialValues._id, data)
        : await adminService().createIntegration(data);
      setIsLoading(false);
      onSuccess();
      hideForm();
    } catch (err) {
      setIsLoading(false);
      onError(ErrorUtils.getErrorMessageFromResponse(err));
    }
  };

  return (
    <form onSubmit={onSave}>
      {initialValues && (
        <FormField>
          <div>
            <strong>ID</strong>
          </div>
          <div>{initialValues._id}</div>
        </FormField>
      )}
      <SelectField
        value={lms}
        onChange={(e) => setLMS(e.target.value)}
        name="lms"
        label="LMS"
        options={LMSOptions.map((lms) => ({ value: lms, label: lms }))}
      />
      <TextField
        value={integrationId}
        onChange={(e) => setIntegrationId(e.target.value)}
        name="integrationId"
        label="Integration ID"
      />
      <TextField value={name} onChange={(e) => setName(e.target.value)} name="name" label="Name" />
      <TextField
        value={accessToken}
        onChange={(e) => setAccessToken(e.target.value)}
        name="accessToken"
        label="Access Token"
      />
      {lms === 'Canvas' && (
        <>
          <TextField
            value={lmsClientId}
            onChange={(e) => setLmsClientId(e.target.value)}
            name="lmsClientId"
            label="LMS Client Id"
          />
          <TextField
            value={lmsSecret}
            onChange={(e) => setLmsSecret(e.target.value)}
            name="lmsSecret"
            label="LMS Secret"
          />
          <TextField
            value={redirectUrl}
            onChange={(e) => setRedirectUrl(e.target.value)}
            name="redirectUrl"
            label="Redirect URL"
          />
        </>
      )}
      <SelectField
        value={allowNonLmsCollaborators}
        onChange={(e) => setAllowNonLmsCollaborators(e.target.value)}
        name="allowNonLmsCollaborators"
        label="Allow Non-LMS Collaborators"
        options={[
          { value: true, label: 'True' },
          { value: false, label: 'False' },
        ]}
      />
      <ButtonContainer>
        <Button type="primary" inputType="submit" loading={isLoading}>
          Save
        </Button>
        <Button onClick={hideForm}>Cancel</Button>
      </ButtonContainer>
    </form>
  );
};

export default CreateIntegrationForm;
