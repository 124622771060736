import { Creation } from '@kritik/types.generated';
import { isCalibrationActivity } from '@kritik/utils/activity';
import * as EvaluationUtils from '@kritik/utils/creation/evaluation';
import * as CreationUtil from '@kritik/utils/creation/general';
import * as creationStatusUtil from '@kritik/utils/creation/status';
import { LIST_BY_TYPES, TABLE_TYPE_OPTIONS } from 'components/CreationList/constant';
import DisputeTableConstants from './DisputeTable/constants';
import ProgressTableConstants from './ProgressTable/constants';
import ScoringTableConstants from './ScoringTable/constants';

const hasCreationWarning = (creation: any, activity: any) => {
  if (!creation || isCalibrationActivity(activity)) {
    return false;
  }
  if (
    creationStatusUtil.getNumOfCreationFlags(creation) ||
    creationStatusUtil.isDispute(creation) ||
    creationStatusUtil.isCreationNotGradedAfterEvaluation(creation, activity)
  ) {
    return true;
  }
  return false;
};

export const shouldRenderColumn = (column: any) => {
  if (!column.header) {
    return false;
  }
  return true;
};

export const isListByGroup = (listBy: string) => {
  return listBy === LIST_BY_TYPES.GROUP;
};

export const isProgressView = (viewType: string) => {
  return viewType === TABLE_TYPE_OPTIONS.PROGRESS;
};

export const isScoringView = (viewType: string) => {
  return viewType === TABLE_TYPE_OPTIONS.SCORING;
};

export const isDisputeView = (viewType: string) => {
  return viewType === TABLE_TYPE_OPTIONS.DISPUTE;
};

export const isScoreComparisonView = (viewType: string) => {
  return viewType === TABLE_TYPE_OPTIONS.SCORE_COMPARISON;
};

const generateRowListData = () => {
  return (selectors: any, state: any) => {
    const { GroupSelectors, StudentSelectors, ActivitySelectors, CreationSelectors } = selectors;

    const itemList = [];
    const activity = ActivitySelectors.selectCurrentActivity(state);
    const creations = CreationSelectors.getCreationsFromEntities(state, activity._id);
    for (const creation of creations) {
      if (!CreationUtil.isCalibrationCreation(creation as Creation)) {
        itemList.push({
          student: StudentSelectors.getStudent((creation as any).student, state.entities),
          group: (creation as any).group
            ? GroupSelectors.getGroup((creation as any).group, state.entities, true)
            : null,
          creation,
          activity,
        });
      }
    }
    return itemList;
  };
};

const isGroupItem = (item: any, listByGroup: any) => {
  return !item.parentId && isListByGroup(listByGroup);
};

const shouldShowFlagForGroupMember = (scores: any, studentId: any) => {
  let shouldShow = false;
  for (const evaluation of scores) {
    if (EvaluationUtils.isEvaluationFlaggedByStudent(evaluation, studentId)) {
      shouldShow = true;
      break;
    }
  }
  return shouldShow;
};

const getColumnsToRender = (viewType: string, listBy: any) => {
  if (isProgressView(viewType)) {
    return isListByGroup(listBy)
      ? ProgressTableConstants.GROUP_COLUMNS.GROUP
      : ProgressTableConstants.STUDENT_COLUMNS.GROUP;
  } else if (isDisputeView(viewType)) {
    return isListByGroup(listBy)
      ? DisputeTableConstants.GROUP_COLUMNS.GROUP
      : DisputeTableConstants.STUDENT_COLUMNS.GROUP;
  }
  return isListByGroup(listBy)
    ? ScoringTableConstants.GROUP_COLUMNS.GROUP
    : ScoringTableConstants.STUDENT_COLUMNS.GROUP;
};

export default {
  hasCreationWarning,
  shouldRenderColumn,
  isListByGroup,
  isProgressView,
  isScoringView,
  generateRowListData,
  isGroupItem,
  shouldShowFlagForGroupMember,
  getColumnsToRender,
};
