import { FormMainError } from 'components/Form/FormComponents';
import HtmlContent from 'components/General/HtmlContent';
import Button from 'components/buttons/Button';
import InlineInformation from 'components/layout/InlineInformation';
import React, { useContext, useEffect, useState } from 'react';
import { AssignmentContext } from '../context/AssignmentContext';
import { EvaluationContext } from '../context/EvaluationContext';

const ConfirmEvaluation = () => {
  const [evaluation, setEvaluation] = useContext(EvaluationContext);

  const [assignment] = useContext(AssignmentContext);

  const [shouldShowMessage, setShouldShowMessage] = useState(false);

  useEffect(() => {
    if (!evaluation) {
      setShouldShowMessage(false);
    } else {
      setShouldShowMessage(true);
    }
  }, [evaluation]);

  useEffect(() => {
    setShouldShowMessage(false);
    setEvaluation(null);
  }, [assignment]);

  if (shouldShowMessage && evaluation) {
    if (evaluation !== 'not found') {
      return (
        <React.Fragment>
          <div style={{ margin: '20px' }} />
          <InlineInformation title="This evaluation has the following content" />
          <div style={{ margin: '5px 0 30px 0' }}>
            <HtmlContent content={evaluation.comment.substr(0, 500)} />
          </div>
          <Button
            type="secondary"
            onClick={() => {
              const newEval = { ...evaluation, confirmed: true };
              setEvaluation(newEval);
            }}
          >
            Confirm Evaluation
          </Button>
        </React.Fragment>
      );
    }
    return (
      <FormMainError
        message="We're sorry we couldn't find a creation with the given information. Please check any typos or unexpected whitespaces."
        isOpen
      />
    );
  }
  return null;
};

export default ConfirmEvaluation;
