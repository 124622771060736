import MenuItem from '@material-ui/core/MenuItem';
import classNames from 'classnames';
import FormField from 'components/core/form/Field';
import Select from 'components/core/input/Select';
import * as _ from 'lodash-es';

export const Selection = (props: any) => {
  const getMenuItemClassNames = (value: any) => {
    const classes = classNames('kritik-form-field__menu-select', {
      'kritik-form-field__menu-select--selected': value && value === props.value,
    });

    return classes;
  };

  const getEmptyOptions = () => {
    return (
      <MenuItem key="-" value={-1} className={getMenuItemClassNames(null)} disabled>
        -
      </MenuItem>
    );
  };

  const getMenuItemFromOptions = () => {
    const menuItems = [];

    if (props.placeholder) {
      menuItems.push(
        <MenuItem
          key={props.placeholder}
          value={props.placeholder}
          className={getMenuItemClassNames(null)}
          disabled
          id={props.id}
        >
          {props.placeholder}
        </MenuItem>
      );
    }

    if (props.options.length === 0) {
      return menuItems.concat(getEmptyOptions());
    }

    props.options.forEach((option: any) => {
      menuItems.push(
        <MenuItem
          key={option.label}
          value={option.value}
          className={getMenuItemClassNames(option.value)}
          disabled={option.disabled}
          data-testid={option.testid}
          id={props.id}
        >
          <span>{option.label}</span>
          {option.description && <span>{option.description}</span>}
        </MenuItem>
      );
    });
    return menuItems;
  };

  const renderOptions = () => {
    if (props.options) {
      return getMenuItemFromOptions();
    }
    return getEmptyOptions();
  };

  return (
    <FormField error={props.error} className={props.className}>
      <Select
        label={props.label}
        value={
          !_.isNil(props.value) && (typeof props.value !== 'string' || props.value.length > 0)
            ? props.value
            : props.placeholder
        }
        open={props.open}
        onOpen={props.onOpen}
        onChange={(e: any) => {
          props.onChange(e);
        }}
        onBlur={props.onBlur}
        onClose={props.onClose}
        disabled={props.disabled}
        isPrimary={props.isPrimary}
        renderValue={props.renderValue}
        testid={props.testid}
        id={props.id}
        menuLabel={props.menuLabel}
        isRequired={props.required}
      >
        {renderOptions()}
      </Select>
    </FormField>
  );
};

Selection.defaultProps = {
  isPrimary: false,
  placeholder: null,
};

export default Selection;
