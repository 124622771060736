import { Button } from 'components/buttons';
import { TranslatedText } from 'components/TranslatedText';

export default function ClassAverageButton({ onClick, viewClassAverage, activity, ...props }: any) {
  return (
    <Button {...props} type={viewClassAverage ? 'primary' : 'secondary'} onClick={onClick}>
      <TranslatedText
        i18nKey={viewClassAverage ? 'ClassAverageButton.HideClassAverage' : 'ClassAverageButton.ViewClassAverage'}
      />
    </Button>
  );
}
