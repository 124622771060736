import { SignupContainer, SignupContent } from 'components/AuthWrapper';
import logo from 'images/logo-small.png';
import { localize } from 'locales';

const NoCourseContainer = () => {
  return (
    <div className="login-container no-course-container">
      <SignupContainer>
        <SignupContent>
          <a href="https://www.kritik.io" target="_self">
            <img src={logo} alt="Kritik's Logo" className="no-course-logo" />
          </a>
          <h3>{localize({ message: 'LtiLaunch.NoCourse.Title' })}</h3>
          <div>{localize({ message: 'LtiLaunch.NoCourse.Content' })}</div>
        </SignupContent>
      </SignupContainer>
    </div>
  );
};

export default NoCourseContainer;
