import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { TranslatedText } from 'components/TranslatedText';
import { localize } from 'locales';
import { connect } from 'react-redux';

import { ACTIVITY_TYPES } from '@kritik/constants/activity';
import FormFieldInfo from 'components/core/form/FieldInfo';
import FormFieldTitle from 'components/core/form/Title';
import OPTION_ICONS from 'images/activity-type-options';
import { resetSelectedActivities, selectAllActivities, toggleSelectedActivity } from 'redux/activityImport/actions';
import { selectActivityIdsToImport, selectActivityOptions } from 'redux/activityImport/select';

type CustomCheckboxProps = {
  id: string;
  onChange: (e: any) => void;
  isChecked: boolean;
  isIndeterminate?: boolean;
  icon?: React.ReactNode;
  testid?: string;
  label: React.ReactNode;
};

const CustomCheckbox = (props: CustomCheckboxProps) => (
  <FormControlLabel
    control={
      <Checkbox
        id={props.id}
        onChange={props.onChange}
        checked={props.isChecked}
        indeterminate={props.isIndeterminate}
        data-testid={props.testid}
        inputProps={{
          'aria-checked': props.isIndeterminate ? 'mixed' : props.isChecked,
        }}
      />
    }
    label={
      <span className="activity-import__checkbox-label">
        {props.icon}
        {props.label}
      </span>
    }
  />
);

const ActivityImportTable = (props: any) => {
  if (props.activitiesToImport.length === 0) {
    return null;
  }

  const renderIcon = (activity: any) => {
    const iconMap = {
      [ACTIVITY_TYPES.CALIBRATION]: {
        src: OPTION_ICONS.CALIBRATION,
        alt: localize({ message: 'ActivityImportTable.IconAlt.Calibration' }),
      },
      [ACTIVITY_TYPES.GROUP]: {
        src: OPTION_ICONS.GROUP,
        alt: localize({ message: 'ActivityImportTable.IconAlt.Group' }),
      },
      [ACTIVITY_TYPES.PEER]: {
        src: OPTION_ICONS.PEER,
        alt: localize({ message: 'ActivityImportTable.IconAlt.Peer' }),
      },
      [ACTIVITY_TYPES.WITHIN_GROUP]: {
        src: OPTION_ICONS.GROUP,
        alt: localize({ message: 'ActivityImportTable.IconAlt.WithinGroup' }),
      },
      [ACTIVITY_TYPES.GROUP_PRESENTATION]: {
        src: OPTION_ICONS.PRESENTATION,
        alt: localize({ message: 'ActivityImportTable.IconAlt.GroupPresentation' }),
      },
      [ACTIVITY_TYPES.INDIVIDUAL_PRESENTATION]: {
        src: OPTION_ICONS.PRESENTATION,
        alt: localize({ message: 'ActivityImportTable.IconAlt.IndividualPresentation' }),
      },
    };

    const mapped = iconMap[activity.activityType];
    if (!mapped) {
      return null;
    }

    return <img src={mapped.src} alt={mapped.alt} />;
  };

  const areAllActivitiesSelected = props.checkboxSelection.length === props.activitiesToImport.length;
  const isAtLeastOneActivitySelected = props.checkboxSelection.length > 0;

  return (
    <div className="activity-import">
      <fieldset>
        <legend>
          <FormFieldTitle size="default" label={localize({ message: 'ActivityImportTable.SelectActivities.Title' })} />
          <FormFieldInfo label={<TranslatedText i18nKey="ActivityImportTable.SelectActivities.Info" />} />
        </legend>
      </fieldset>

      <ul>
        <li>
          <CustomCheckbox
            id="select-all"
            isChecked={areAllActivitiesSelected}
            isIndeterminate={isAtLeastOneActivitySelected && !areAllActivitiesSelected}
            label={
              <strong>
                {isAtLeastOneActivitySelected ? (
                  <TranslatedText i18nKey="ActivityImportTable.DeselectAll" />
                ) : (
                  <TranslatedText i18nKey="ActivityImportTable.SelectAll" />
                )}
              </strong>
            }
            onChange={isAtLeastOneActivitySelected ? props.resetSelectedActivities : props.selectAllActivities}
          />
        </li>
        {props.activitiesToImport.map((activity: any, idx: Number) => (
          <li key={activity._id}>
            <CustomCheckbox
              id={activity._id}
              isChecked={props.checkboxSelection.includes(activity._id)}
              label={activity.title}
              icon={renderIcon(activity)}
              testid={`activity-${idx}`}
              onChange={() => props.toggleSelectedActivity(activity._id)}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    activitiesToImport: selectActivityOptions(state),
    checkboxSelection: selectActivityIdsToImport(state),
  };
};

const mapDispatchToProps = {
  toggleSelectedActivity,
  selectAllActivities,
  resetSelectedActivities,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityImportTable);
