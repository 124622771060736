import { getAssignmentSubmissions } from 'actions/activity';
import CalibrationCreationSelector from 'components/Assignment/Edit/Calibration/CreationSelector';
import { TranslatedText } from 'components/TranslatedText';
import { PageHeader } from 'components/layout';
import { useState } from 'react';
import { connect } from 'react-redux';
import { closeCreationSelector, selectCalibrationOptions, selectCreationIndex } from 'redux/activityEdit';
import { getAssignment, getPastAssignments } from 'selectors/activity';

const CreationSelector = (props: any) => {
  const formState = props.formRef.current.getState();
  const [selectedActivity, setSelectedActivity] = useState(formState.values.calibrationActivity);
  const [existingScores, setExistingScores] = useState(formState.values.calibrationCreations);
  const [rubric, setRubric] = useState(formState.values.rubric);

  function onCancel() {
    props.closeCreationSelector();
  }

  function handleSelectScore(newScore: any) {
    props.formRef.current.mutators.setCalibrationCreation({
      marks: newScore.marks,
      instructorEvaluationNotes: newScore.instructorCreationScoreExplanation,
      creationId: newScore._id,
      index: props.creationIndex,
    });
    onCancel();
  }

  return (
    <div>
      <PageHeader
        title={<TranslatedText i18nKey="Activity.Create.Calibration.Select.PageHeader" />}
        action={onCancel}
      />
      <CalibrationCreationSelector
        isGroupActivity={selectedActivity.isGroupActivity}
        creations={props.calibrationCreationOptions}
        students={props.entities.students}
        users={props.entities.users}
        groups={props.entities.groups || {}}
        assignment={selectedActivity}
        rubric={rubric}
        sourceRubric={selectedActivity.calibrationSourceRubric || selectedActivity.rubric}
        onGradeSelect={(score: any) => {
          return handleSelectScore(score);
        }}
        existingScores={existingScores}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    activity: getAssignment(state, state.selected.assignmentId),
    finalizedActivityList: getPastAssignments(state, state.selected.courseId),
    calibrationCreationOptions: selectCalibrationOptions(state),
    entities: state.entities,
    creationIndex: selectCreationIndex(state),
  };
};

export default connect(mapStateToProps, {
  getAssignmentSubmissions,
  closeCreationSelector,
})(CreationSelector);
