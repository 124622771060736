import { InlineInformation } from 'components/layout';
import { localize } from 'locales';
import { useEffect, useState } from 'react';
import ActivityService from 'services/activity';
import CourseService from 'services/course';
import { withRouter } from 'utils/withRouter';
import Scheduler from './Scheduler';

function PresentationSchedule(props: any) {
  const { courseId, assignmentId: activityId } = props.router.params;

  const [activity, setActivity] = useState(null);
  const [course, setCourse] = useState(null);
  const [error, setError] = useState('');

  async function getActivity() {
    const result = await ActivityService().get({ id: activityId });
    setActivity(result.data);
  }

  async function getCourse() {
    const result = await CourseService().get({ id: courseId });
    setCourse(result.data);
  }

  useEffect(() => {
    try {
      void getActivity();
      void getCourse();
    } catch (err) {
      setError(localize({ message: 'Activity.Schedule.LoadError' }));
    }
  }, []);

  if (!activity || !course) {
    return null;
  }

  if (error.length > 0) {
    return <InlineInformation type="danger">{error}</InlineInformation>;
  }
  return <Scheduler activity={activity} course={course} />;
}

export default withRouter(PresentationSchedule);
