import { assignmentStatuses } from '@kritik/constants/stage';
import * as activityUtils from '@kritik/utils/activity';
import * as formatUtils from '@kritik/utils/format';
import * as activityStatusUtils from '@kritik/utils/stage';
import { resetAsync } from 'actions/async';
import { GracePeriod } from 'components/Assignment/scheduler';
import ScheduleDateInput from 'components/schedule/DateInput';
import DateWrapper from 'containers/Schedule/DateWrapper';
import StageContainer from 'containers/Schedule/StageContainer';
import EvaluateSchedule from 'containers/Schedule/partials/EvaluateDate';
import STAGE_STATUS_ICONS from 'images/status-icons';
import { localize } from 'locales';
import * as _ from 'lodash-es';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { updateSchedule } from 'redux/schedule/actions';
import ScheduleSelectors from 'redux/schedule/selectors';
import ScheduleTypes from 'redux/schedule/types';
import { selectCurrentActivity } from 'selectors/activity';
import { selectTimeZone } from 'selectors/user';
import SchedulerArrow from './ArrowSeparator';
import localUtils from './utils';

const Schedule = (props: any) => {
  if (!props.activity || !props.schedule) {
    return null;
  }

  useEffect(() => {
    return function cleanUp() {
      props.resetAsync(ScheduleTypes.UPDATE_SCHEDULING_TEMPLATE);
      props.resetAsync(ScheduleTypes.CREATE_SCHEDULING_TEMPLATE);
    };
  }, []);

  const activeStatus = activityStatusUtils.getActiveStatus(props.activity);

  const getGracePeriodTooltip = () => {
    if (localUtils.isGracePeriodEnabled(props.schedule)) {
      const label = formatUtils.displayTimeBetweenDates(
        props.schedule.creationEndDate,
        props.schedule.gracePeriodEndDate
      );
      return `Includes ${label} grace`;
    }
    return null;
  };

  const getGracePeriodFootnote = () => {
    if (localUtils.isGracePeriodEnabled(props.schedule)) {
      const label = formatUtils.displayTimeBetweenDates(
        props.schedule.creationEndDate,
        props.schedule.gracePeriodEndDate
      );
      return `${label} grace will be added to the length of the stage`;
    }
    return null;
  };

  const markDSTDateWarning = (schedule: any) => {
    const { creationEndDate, startDate, gracePeriodEndDate, evaluationEndDate, feedbackEndDate } = schedule;
    if (!creationEndDate && !startDate) {
      return;
    }

    const gracePeriodEndTime = gracePeriodEndDate || creationEndDate;
    // two DST date should not in range of schedule time
    let date = moment(startDate);
    const startDateDSTStatus = date.tz(props.timeZone).isDST();
    while (date.isSameOrBefore(moment(gracePeriodEndTime))) {
      if (date.tz(props.timeZone).isDST() !== startDateDSTStatus) {
        const dstMessage = `Actual stage duration changes due to daylight saving on ${date.format('ddd, MMM Do YYYY')}`;
        setCreationDSTMessage(dstMessage);
        return;
      }
      date.add(1, 'days');
    }
    date = moment(gracePeriodEndDate);
    while (date.isSameOrBefore(moment(evaluationEndDate))) {
      if (date.tz(props.timeZone).isDST() !== startDateDSTStatus) {
        const dstMessage = `Actual stage duration changes due to daylight saving on ${date.format('ddd, MMM Do YYYY')}`;
        setEvaluationDSTMessage(dstMessage);
        return;
      }
      date.add(1, 'days');
    }
    date = moment(evaluationEndDate);
    while (date.isSameOrBefore(moment(feedbackEndDate))) {
      if (date.tz(props.timeZone).isDST() !== startDateDSTStatus) {
        const dstMessage = `Actual stage duration changes due to daylight saving on ${date.format('ddd, MMM Do YYYY')}`;
        setFeedbackDSTMessage(dstMessage);
        return;
      }
      date.add(1, 'days');
    }
  };

  const [creationDSTMessage, setCreationDSTMessage] = useState(null);
  const [evaluationDSTMessage, setEvaluationDSTMessage] = useState(null);
  const [feedbackDSTMessage, setFeedbackDSTMessage] = useState(null);

  const resetDSTMessage = () => {
    setCreationDSTMessage(null);
    setEvaluationDSTMessage(null);
    setFeedbackDSTMessage(null);
  };

  useEffect(() => {
    resetDSTMessage();
    markDSTDateWarning(props.schedule);
  }, []);

  const adjustGracePeriodEndDate = (schedule: any) => {
    // adjust grace period end date so that grace period interval is kept the same
    // when change create end date
    const creationEndDate = moment(schedule.creationEndDate).add(1, 'minutes').toDate();

    return {
      ...schedule,
      gracePeriodEndDate: moment(creationEndDate).add(schedule.gracePeriodInterval, 'seconds').toDate(),
    };
  };

  const adjustEvaluationGracePeriodEndDate = (schedule: any) => {
    // adjust grace period end date so that grace period interval is kept the same
    // when change create end date
    const evaluationEndDate = moment(schedule.evaluationEndDate).add(1, 'minutes').toDate();

    return {
      ...schedule,
      evaluationGracePeriodEndDate: moment(evaluationEndDate).add(schedule.gracePeriodInterval, 'seconds').toDate(),
    };
  };

  const handleDateChange = (date: any, name: any) => {
    props.resetErrors();
    resetDSTMessage();
    let newSchedule = { ...props.schedule };
    delete newSchedule.customTime;

    const isOnlyTimeChange = moment(date).isSame(newSchedule[name], 'day');

    newSchedule[name] = localUtils.roundSelectedDate(date);
    if (_.isEqual(newSchedule, props.schedule)) {
      return;
    }

    if (isOnlyTimeChange) {
      // if time change only, we will recompute all the
      // intervals based on the new time because we want to persist
      // user selected time
      if (name === localUtils.FIELDS.CREATION_END) {
        if (newSchedule.gracePeriodInterval) {
          newSchedule = adjustGracePeriodEndDate(newSchedule);
        }
      }

      if (name === localUtils.FIELDS.EVALUATION_END) {
        if (newSchedule.evaluationGracePeriodInterval) {
          newSchedule = adjustEvaluationGracePeriodEndDate(newSchedule);
        }
      }

      newSchedule = {
        ...newSchedule,
        ...localUtils.generateIntervalsFromSchedule(newSchedule),
      };
    } else {
      newSchedule = localUtils.generateScheduleFromDates({
        schedule: newSchedule,
        modified: name,
      });
    }

    props.updateSchedule(newSchedule);
    markDSTDateWarning(newSchedule);
  };

  const handleCustomTimeChange = (customTimeStr: any) => {
    const date = moment(customTimeStr, 'h:mm A');
    props.updateSchedule({
      customTime: [date.hours(), date.minutes()],
    });
  };

  const onGracePeriodChange = (schedule: any) => {
    return props.updateSchedule({
      ...props.schedule,
      gracePeriodInterval: schedule.gracePeriodInterval,
      gracePeriodEndDate: schedule.gracePeriodEndDate,
      evaluationInterval: schedule.evaluationInterval,
    });
  };

  const handleIntervalChange = (length: any, name: any) => {
    const generatedSchedule = localUtils.addIntervalInDaysToSchedule(
      {
        ...props.schedule,
        [name]: length,
      },
      name
    );
    if (name === localUtils.FIELDS.GRACE_INTERVAL) {
      return onGracePeriodChange(generatedSchedule);
    }
    if (name === localUtils.FIELDS.EVALUATION_GRACE_INTERVAL) {
      return onEvaluationGracePeriodChange(generatedSchedule);
    }
    props.updateSchedule(generatedSchedule);
  };

  const toggleGracePeriod = () => {
    const newSchedule = { ...props.schedule };
    const isToggledOn = newSchedule.gracePeriodInterval;
    if (isToggledOn) {
      newSchedule.gracePeriodInterval = null;
      newSchedule.gracePeriodEndDate = null;
    } else {
      newSchedule.gracePeriodInterval = 60 * 60 * 2;
    }
    const generatedSchedule = localUtils.generateScheduleFromIntervals(newSchedule);
    resetDSTMessage();
    markDSTDateWarning(generatedSchedule);
    return onGracePeriodChange(generatedSchedule);
  };

  const onEvaluationGracePeriodChange = (schedule: any) => {
    return props.updateSchedule({
      ...props.schedule,
      evaluationGracePeriodInterval: schedule.evaluationGracePeriodInterval,
      evaluationGracePeriodEndDate: schedule.evaluationGracePeriodEndDate,
    });
  };

  const toggleEvaluationGracePeriod = () => {
    const newSchedule = { ...props.schedule };
    const isToggledOn = newSchedule.evaluationGracePeriodInterval;

    if (isToggledOn) {
      newSchedule.evaluationGracePeriodInterval = null;
      newSchedule.evaluationGracePeriodEndDate = null;
    } else {
      newSchedule.evaluationGracePeriodInterval = 60 * 60 * 2;
    }
    const generatedSchedule = localUtils.generateScheduleFromIntervals(newSchedule);
    resetDSTMessage();
    markDSTDateWarning(generatedSchedule);
    return onEvaluationGracePeriodChange(generatedSchedule);
  };

  const renderCreationStage = () => {
    const startDateError = props.pastDateError?.Create?.startDate;
    const endDateError = props.pastDateError?.Create?.endDate;
    return (
      <StageContainer hasNextStage>
        <SchedulerArrow
          startDate={props.schedule.startDate}
          endDate={props.schedule.creationEndDate}
          onChange={(interval: any) => {
            return handleIntervalChange(interval, 'creationInterval');
          }}
          tooltip={getGracePeriodTooltip()}
          footnote={getGracePeriodFootnote()}
          image={<img aria-hidden="true" src={STAGE_STATUS_ICONS.CREATE} />}
          testid="scheduler-arrow-create-stage"
        />
        <DateWrapper
          title={localize({ message: 'Activity.Schedule.Create' })}
          description="During the Create stage, activities become available for students to view and submit their work."
          dateInput={
            <React.Fragment>
              <ScheduleDateInput
                testid="create_start-date"
                label={localize({ message: 'Activity.Schedule.StartDate' })}
                title={localize({ message: 'Activity.Schedule.Create' })}
                value={props.schedule.startDate}
                onDateChange={(date: any) => {
                  return handleDateChange(date, localUtils.FIELDS.START);
                }}
                minDate={moment().add(1, 'minutes').toDate()}
                disabled={activeStatus.name !== assignmentStatuses.DRAFT}
                error={startDateError}
                customTime={props.schedule.customTime}
                onCustomTimeChange={handleCustomTimeChange}
              />
              <ScheduleDateInput
                testid="create_due-date"
                label={localize({ message: 'Activity.Schedule.DueDate' })}
                title={localize({ message: 'Activity.Schedule.Create' })}
                value={props.schedule.creationEndDate}
                onDateChange={(date: any) => {
                  return handleDateChange(date, localUtils.FIELDS.CREATION_END);
                }}
                minDate={localUtils.getMinDateFromPreviousEnd(props.schedule.startDate)}
                disabled={
                  activeStatus.name !== assignmentStatuses.DRAFT && activeStatus.name !== assignmentStatuses.CREATE
                }
                error={endDateError}
                customTime={props.schedule.customTime}
                onCustomTimeChange={handleCustomTimeChange}
              />
            </React.Fragment>
          }
          attention={creationDSTMessage}
        >
          <GracePeriod
            selectedGracePeriod={props.schedule.gracePeriodInterval / 60}
            isCheckedGracePeriod={!!props.schedule.gracePeriodInterval}
            handleCheck={toggleGracePeriod}
            handleSelect={(interval: any) => {
              return handleIntervalChange(interval * 60, 'gracePeriodInterval');
            }}
            isLocked={
              !(
                [assignmentStatuses.DRAFT, assignmentStatuses.CREATE, assignmentStatuses.PROCESSING1] as string[]
              ).includes(activeStatus.name)
            }
            creationEndDate={props.schedule.creationEndDate}
          />
        </DateWrapper>
      </StageContainer>
    );
  };

  const renderEvaluationStage = () => {
    const endDateError = props.pastDateError?.Evaluate?.endDate;
    return (
      <StageContainer hasNextStage>
        <SchedulerArrow
          startDate={localUtils.getCreationStageEndDate(props.schedule)}
          endDate={props.schedule.evaluationEndDate}
          onChange={(interval: any) => {
            return handleIntervalChange(interval, 'evaluationInterval');
          }}
          image={<img aria-hidden="true" src={STAGE_STATUS_ICONS.EVALUATE} />}
          testid="scheduler-arrow-evaluate-stage"
        />
        <EvaluateSchedule
          minDate={localUtils.getMinDateFromPreviousEnd(localUtils.getCreationStageEndDate(props.schedule))}
          evaluationEndDate={props.schedule.evaluationEndDate}
          activeStatus={activeStatus}
          handleChange={(date: any) => {
            return handleDateChange(date, localUtils.FIELDS.EVALUATION_END);
          }}
          error={endDateError}
          onCustomTimeChange={handleCustomTimeChange}
          attention={evaluationDSTMessage}
          toggleGracePeriod={toggleEvaluationGracePeriod}
          handleIntervalChange={handleIntervalChange}
        />
      </StageContainer>
    );
  };

  const renderFeedbackStage = () => {
    const endDateError = props.pastDateError?.Feedback?.endDate;
    return (
      <StageContainer hasNextStage>
        <SchedulerArrow
          startDate={localUtils.getEvaluationStageEndDate(props.schedule)}
          endDate={props.schedule.feedbackEndDate}
          onChange={(interval: any) => {
            return handleIntervalChange(interval, 'feedbackInterval');
          }}
          image={<img aria-hidden="true" src={STAGE_STATUS_ICONS.FEEDBACK} />}
          testid="scheduler-arrow-feedback-stage"
        />
        <DateWrapper
          title={localize({ message: 'Activity.Schedule.Feedback' })}
          description="During the Feedback stage students can give feedback to their peer evaluators. Once this stage is complete you will have a chance to review the activity before finalizing grades."
          dateInput={
            <ScheduleDateInput
              testid="feedback_due-date"
              label={localize({ message: 'Activity.Schedule.DueDate' })}
              title={localize({ message: 'Activity.Schedule.Feedback' })}
              value={props.schedule.feedbackEndDate}
              onDateChange={(date: any) => {
                return handleDateChange(date, localUtils.FIELDS.FEEDBACK_END);
              }}
              minDate={localUtils.getMinDateFromPreviousEnd(props.schedule.evaluationEndDate)}
              customTime={props.schedule.customTime}
              onCustomTimeChange={handleCustomTimeChange}
              disabled={([assignmentStatuses.PROCESSING3, assignmentStatuses.FINALIZED] as string[]).includes(
                activeStatus.name
              )}
              error={endDateError}
            />
          }
          warning={
            activityUtils.isGroupAssignment(props.activity) &&
            `During the Feedback stage, individual students will give
              feedback for the evaluations their group received.`
          }
          attention={feedbackDSTMessage}
        />
      </StageContainer>
    );
  };

  const renderGradingStage = () => {
    return (
      <StageContainer>
        <SchedulerArrow image={<img aria-hidden="true" src={STAGE_STATUS_ICONS.GRADING} />} hideArrow />
        <DateWrapper
          title="Grading"
          description="During the Grading stage everyone reviews the activity scores and resolves any disputes or issues."
        />
      </StageContainer>
    );
  };

  return (
    <div className="status-dates-container">
      {renderCreationStage()}
      {renderEvaluationStage()}
      {renderFeedbackStage()}
      {renderGradingStage()}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    schedule: ScheduleSelectors.selectSchedule(state),
    activity: selectCurrentActivity(state),
    getSchedulingTemplateState: ScheduleSelectors.selectGetSchedulingTemplateState(state),
    pastDateError: ScheduleSelectors.selectPastDateError(state),
    timeZone: selectTimeZone(state),
  };
};

export default connect(mapStateToProps, {
  updateSchedule,
  resetAsync,
})(Schedule);
