import MenuItem from '@material-ui/core/MenuItem';
import { MenuField, MenuInput } from 'components/CreationList/Menu/Input';
import Select from 'components/core/input/Select';

export const Selection = ({ label, value, onChange, options, icon, disabled, testid }: any) => {
  const renderOptions = () => {
    return options.map((option: any) => {
      return (
        <MenuItem key={option.value} value={option.value} data-testid={option.testid}>
          {option.label}
        </MenuItem>
      );
    });
  };
  return (
    <MenuField>
      <MenuInput icon={icon}>
        <Select
          label={label}
          value={value}
          onChange={onChange}
          menuProps={{ disablePortal: true }}
          disabled={disabled}
          testid={testid}
        >
          {renderOptions()}
        </Select>
      </MenuInput>
    </MenuField>
  );
};

export default Selection;
