import ForgotPassword from 'components/auth/ForgotPassword';
import Login from 'components/auth/Login';
import Register from 'components/auth/Register';
import ResetPassword from 'components/auth/ResetPassword';
import { Component } from 'react';
import { connect } from 'react-redux';
import { RouterProp, withRouter } from 'utils/withRouter';

type LoginOrRegisterProps = {
  router: RouterProp;
};

class LoginOrRegister extends Component<LoginOrRegisterProps> {
  render() {
    const { router } = this.props;
    const pathname = router.location.pathname;
    return (
      <div className="login-container">
        {!pathname || pathname == '/login' ? <Login /> : null}
        {pathname.includes('/signup') ? <Register /> : null}
        {pathname == '/forgot' ? <ForgotPassword /> : null}
        {pathname.includes('/reset') ? <ResetPassword /> : null}
      </div>
    );
  }
}

const mapStateToProps = ({ user }: any) => {
  return {
    user,
  };
};

export default withRouter(connect(mapStateToProps, {})(LoginOrRegister));
