import TopBarWithButton from 'components/TopBarWithButton';
import { InlineInformation } from 'components/layout';
import { usePresenterMode } from 'hooks/viewAs';
import { localize } from 'locales';

function PresenterModeTopBar() {
  const { isPresenterMode, endPresenterMode } = usePresenterMode();
  if (!isPresenterMode) {
    return null;
  }

  return (
    <TopBarWithButton
      icon={
        <InlineInformation className="presenter-mode-bar__info">
          {localize({ message: 'PresenterMode.Bar.Info' })}
        </InlineInformation>
      }
      onActionButtonClick={endPresenterMode}
      actionButtonLabel={localize({ message: 'PresenterMode.Bar.Button' })}
      className="presenter-mode-bar__container"
      testid="presenter-mode-bar"
      buttonTestId="exit-presenter-mode-button"
    />
  );
}

export default PresenterModeTopBar;
