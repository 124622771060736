import classNames from 'classnames';
import React, { forwardRef } from 'react';

interface FormFieldInputProps {
  error?: any;
  warning?: boolean;
  className?: string;
  children: React.ReactNode;
}

const FormFieldInput = forwardRef<HTMLDivElement, FormFieldInputProps>(
  ({ error, warning, children, className }, ref) => {
    const classes = classNames(
      'kritik-form-field__input',
      {
        'kritik-form-field__input--warning': warning,
        'kritik-form-field__input--error': error,
      },
      className
    );
    return (
      <div ref={ref} className={classes}>
        {children}
      </div>
    );
  }
);

FormFieldInput.displayName = 'FormFieldInput';

FormFieldInput.defaultProps = {
  error: null,
  warning: false,
  className: '',
};

export default FormFieldInput;
