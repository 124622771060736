import createSvgIcon from 'images/icons/util/generateIconSVG';
import React from 'react';

export default createSvgIcon(
  <React.Fragment>
    <circle cx="31.5" cy="31.5" r="30.9375" fill="#504BB4" />
    <circle cx="31.5" cy="31.5" r="27" fill="#D7EEF4" stroke="#235C6A" />
    <path
      d="M38.4109 44.5983L38.4918 44.6792L38.5988 44.7198L44.585 46.9911L45.6934 47.4116L45.2729 46.3033L43.0016 40.317L42.961 40.21L42.8801 40.1291L23.1537 20.4028L22.7766 20.0256L22.3995 20.4028L18.6845 24.1177L18.3074 24.4948L18.6845 24.872L38.4109 44.5983Z"
      fill="#8AC8D7"
      stroke="#235C6A"
      strokeWidth="1.06667"
    />
    <path d="M44.7722 46.4943L38.7354 44.1724L42.4504 40.4575L44.7722 46.4943Z" fill="#D7EEF4" />
    <path
      d="M17.5509 16.3086L14.5902 19.2693L12.8481 17.5272C12.4315 17.1106 12.4315 16.4352 12.8481 16.0187L14.3003 14.5665C14.7169 14.1499 15.3923 14.1499 15.8088 14.5665L17.5509 16.3086Z"
      fill="#8AC8D7"
      stroke="#235C6A"
      strokeWidth="1.06667"
    />
    <rect
      x="22.7743"
      y="20.0235"
      width="6.3204"
      height="6.32041"
      transform="rotate(135 22.7743 20.0235)"
      fill="#5799A9"
      stroke="#235C6A"
      strokeWidth="1.06667"
    />
    <rect
      x="20.1624"
      y="21.881"
      width="1.31343"
      height="5.25374"
      transform="rotate(135 20.1624 21.881)"
      fill="#76B6C7"
    />
    <rect
      x="39.6657"
      y="43.2421"
      width="1.31343"
      height="27.5821"
      transform="rotate(135 39.6657 43.2421)"
      fill="#B3DDE8"
    />
    <rect
      x="42.4524"
      y="40.4558"
      width="1.31343"
      height="27.5821"
      transform="rotate(135 42.4524 40.4558)"
      fill="#76B6C7"
    />
  </React.Fragment>,
  '0 0 63 63'
);
