import * as ActivityUtil from '@kritik/utils/activity';
import { isPresentationActivity } from '@kritik/utils/activity';
import { simplePercent } from '@kritik/utils/format';
import { CreationScoreUtil } from '@kritik/utils/grade';
import { getCreationStage, studentParticipatesInStageFromInstructorView } from '@kritik/utils/stage';
import { localize } from 'locales';

const CreationScoreCell = ({ activity, creation }: any) => {
  const creationStage = getCreationStage(activity);
  if (
    isPresentationActivity(activity) &&
    !studentParticipatesInStageFromInstructorView({
      stage: creationStage,
      studentId: creation.student,
    })
  ) {
    return localize({ message: 'Activity.StudentTable.Cell.NA' });
  }

  if (!creation || !CreationScoreUtil.hasCreationScore(creation)) {
    return '-';
  }

  if (ActivityUtil.isNumericGrading(activity)) {
    const creationScore = CreationScoreUtil.calcAvgCreationScore({
      creation,
      rubric: activity.rubric,
      startingScore: activity.startingScore,
      lateCreationPenaltyPercentage: activity.lateCreationPenaltyPercentage,
    });
    return simplePercent(creationScore);
  }

  return `${CreationScoreUtil.getPassFailScore(creation)}%`;
};

export default CreationScoreCell;
