import { Dialog, DialogActions, DialogTitle } from '@material-ui/core';
import { TranslatedText } from 'components/TranslatedText';
import { Button } from 'components/buttons';
import { InlineInformation } from 'components/layout';
import NoticeBoard from 'components/layout/NoticeBoard';
import { useRemoveStudentFromPresentationActivity } from 'hooks/activity';
import { localize } from 'locales';
import { Activity, Student } from 'old-common/types.generated';
import { useState } from 'react';

type RemoveStudentFromActivityModalProps = {
  open: boolean;
  onCancelRemoveStudent: () => void;
  onSuccessfullStudentRemoval: () => void;
  student: Student & { user: { profile: { name: string } } };
  activity: Activity & { course: string };
};
export function RemoveStudentFromActivityModal({
  open,
  onSuccessfullStudentRemoval,
  onCancelRemoveStudent,
  student,
  activity,
}: RemoveStudentFromActivityModalProps) {
  const [error, setError] = useState<string | null>(null);
  const removeStudentFromPresentationActivity = useRemoveStudentFromPresentationActivity();

  const handleRemoveStudent = () => {
    removeStudentFromPresentationActivity.mutate(
      {
        activityId: activity._id,
        studentId: student._id,
      },
      {
        onError: () => setError(localize({ message: 'Error.SomethingWrongHappened.ContactSupport' })),
        onSuccess: () => {
          onSuccessfullStudentRemoval();
        },
      }
    );
  };
  return (
    <Dialog
      open={open}
      onClose={onCancelRemoveStudent}
      aria-labelledby="remove-student-dialog-title"
      aria-describedby="remove-student-description"
      className="remove-student-activity-modal"
    >
      <div className="remove-student-modal-container">
        <DialogTitle className="remove-dialog-title" data-testid="remove-student-from-activity-title">
          <TranslatedText
            i18nKey="Activity.RemoveStudent.Modal.Header"
            values={{ name: student?.user?.profile?.name || '' }}
          />
        </DialogTitle>
        <TranslatedText i18nKey="Activity.RemoveStudent.Modal.Text1" />
        <ul>
          <li>
            <TranslatedText i18nKey="Activity.RemoveStudent.Modal.Text2" />
          </li>
          <li>
            <TranslatedText i18nKey="Activity.RemoveStudent.Modal.Text3" />
          </li>
          <li>
            <TranslatedText i18nKey="Activity.RemoveStudent.Modal.Text4" />
          </li>
        </ul>
        <NoticeBoard type="danger">
          <TranslatedText i18nKey="Activity.RemoveStudent.Modal.Notice" />
        </NoticeBoard>
        <p>
          <TranslatedText i18nKey="Activity.RemoveStudent.Modal.SupportText" />
        </p>
        {error && (
          <InlineInformation type="danger" style={{ marginTop: '10px' }}>
            {error}
          </InlineInformation>
        )}
        <DialogActions className="remove-student-modal-container_button-container">
          <Button
            type="primary"
            inputType="submit"
            data-testid="remove-student-from-activity"
            onClick={handleRemoveStudent}
            label={localize({ message: 'Activity.RemoveStudent.Menu.Item' })}
          >
            <TranslatedText i18nKey="Activity.RemoveStudent.Btn.RemoveFromActivity" />
          </Button>
          <Button
            onClick={onCancelRemoveStudent}
            type="secondary"
            autoFocus
            data-testid="cancel-remove-student-from-activity"
          >
            <TranslatedText i18nKey="Cancel" />
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
}
