import { Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useEffect, useState } from 'react';
import { VariableSizeList } from 'react-window';

const LISTBOX_PADDING = 8; // this is for the padding at the very top of the list

function renderRow(props: any) {
  const { data, index, style } = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: style.top + LISTBOX_PADDING,
    },
  });
}

// to navigate through the list with keyboard
const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  // @ts-expect-error TS(2322) FIXME: Type 'ForwardedRef<unknown>' is not assignable to ... Remove this comment to see the full error message
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current != null) {
      (ref.current as any).resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

const ListboxComponent = React.forwardRef((props, ref) => {
  // @ts-expect-error TS(2339) FIXME: Property 'children' does not exist on type '{}'.
  const { children, ...other } = props;
  const itemData = React.Children.toArray(children);
  const itemCount = itemData.length;
  const itemSize = 36;

  const getChildSize = () => {
    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => {
      return a + b;
    }, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    // @ts-expect-error TS(2322) FIXME: Type 'ForwardedRef<unknown>' is not assignable to ... Remove this comment to see the full error message
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={() => {
            return itemSize;
          }}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

const useStyles = makeStyles({
  listbox: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});

export default function Virtualize(props: any) {
  const classes = useStyles();
  const [OtherInstitution, setOtherInstitution] = useState();
  const [expanded, setExpanded] = useState(false);

  const institutionsOptions = props.options.filter((option: any) => {
    if (option.name === 'Other') {
      return false;
    }
    return true;
  });

  const getOtherInstution = () => {
    const _other = props.options.filter((option: any) => {
      if (option.name === 'Other') {
        return true;
      }
      return false;
    });
    return _other[0];
  };

  useEffect(() => {
    const _other = getOtherInstution();
    setOtherInstitution(_other);
  }, [props.options]);

  const filterInstitutions = (options: any, state: any) => {
    const filteredOptions = options.filter((option: any) => {
      if (option.name.toLowerCase().includes(state.inputValue.toLowerCase())) {
        return true;
      }
      return false;
    });
    filteredOptions.push(OtherInstitution);
    return filteredOptions;
  };
  return (
    <Autocomplete
      onOpen={() => setExpanded(true)}
      onClose={() => setExpanded(false)}
      disableListWrap
      classes={classes}
      // @ts-expect-error TS(2322) FIXME: Type 'ForwardRefExoticComponent<RefAttributes<unkn... Remove this comment to see the full error message
      ListboxComponent={ListboxComponent}
      getOptionLabel={(option) => {
        return option.name;
      }}
      options={institutionsOptions}
      value={props.value}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            autoFocus
            label={params.disabled ? 'Loading institutions ...' : props.label}
            inputProps={{
              ...params.inputProps,
              role: 'combobox',
              'aria-label': 'Select Institution',
              'aria-expanded': expanded,
              'aria-controls': 'select-institution-listbox',
              'aria-haspopup': 'listbox',
            }}
          />
        );
      }}
      renderOption={(option) => {
        return <Typography noWrap>{option?.name}</Typography>;
      }}
      onChange={props.onChange}
      loading={props.isLoading}
      disabled={props.isLoading}
      filterOptions={filterInstitutions}
      data-testid={props.testid}
      // Added so that these properties are on the input and not on the parent div
      role={undefined}
      aria-label={undefined}
      aria-expanded={undefined}
      ListboxProps={{
        id: 'select-institution-listbox',
      }}
    />
  );
}
