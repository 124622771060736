import { Course } from '@kritik/types.generated';
import { useQueryClient } from '@tanstack/react-query';
import { RouteOnEnter, UserRole } from 'app-types';
import React from 'react';
import { Params, useLocation, useNavigate, useParams } from 'react-router';
import * as types from '../types';
import { createRestApiClient } from './createRestApiClient';

type MigratedRouteProps = {
  element: any;
  store: any;
  onEnter?: RouteOnEnter;
  fetchData?: (
    options: { state: any; client: any; params: Readonly<Params<string>> },
    store: { dispatch: any; getState: any }
  ) => Promise<any>;
  tab?: string;
};

const apiEndpoint = import.meta.env.VITE_API_URL;
const API_CLIENT = createRestApiClient().withConfig({ baseURL: apiEndpoint });

export function MigratedRoute({ element: Element, onEnter, fetchData, tab, store }: MigratedRouteProps) {
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const state = { tab };
  const replace = (path: string) => navigate(path, { replace: true, state });
  const [onEnterOk, setOnEnterOk] = React.useState(false);
  const [fetchDataOk, setFetchDataOk] = React.useState(false);
  const [canRender, setCanRender] = React.useState(false);

  const queryClient = useQueryClient();

  const course = queryClient.getQueryData<Course & { userRole: UserRole }>(['course', params.courseId]);

  React.useEffect(() => {
    if (!onEnter) {
      setOnEnterOk(true);
    } else {
      onEnter({
        callback: () => setOnEnterOk(true),
        location,
        params,
        replace,
        course,
      });
    }
  }, [location]);
  React.useEffect(() => {
    if (!fetchData) {
      setFetchDataOk(true);
    } else if (onEnterOk) {
      store.dispatch({ type: types.CREATE_REQUEST });
      fetchData({ client: API_CLIENT, state, params }, store)
        .then((data: any) => {
          setTimeout(() => {
            const elementId = window.location.hash.replace('#', '');
            if (elementId) {
              document.getElementById(elementId)?.scrollIntoView();
            }
          }, 0);
          store.dispatch({ type: types.REQUEST_SUCCESS, payload: data });
          setFetchDataOk(true);
        })
        .catch((err: any) => {
          store.dispatch({ type: types.REQUEST_FAILURE, payload: { err } });
          switch (err.response?.status) {
            case 404:
              replace('/404');
              break;
            case 403:
            case 401:
              // redirect to login page with force reload
              // clean application state because user is not authenticated anymore
              window.location.href = '/login';
              break;
            default:
              replace('/500');
              break;
          }
        });
    }
  }, [location, onEnterOk]);

  React.useEffect(() => {
    setCanRender(onEnterOk && fetchDataOk);
  }, [onEnterOk, fetchDataOk]);

  if (!canRender) {
    return null;
  }
  return <Element />;
}
