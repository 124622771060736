import { PRESENT_STATUS, assignmentStatuses as STATUS_LABELS } from '@kritik/constants/stage';
import * as ActivityUtils from '@kritik/utils/activity';
import * as evaluationUtil from '@kritik/utils/creation/evaluation';
import * as LateSubmissionUtils from '@kritik/utils/creation/lateCreation';
import * as creationStatus from '@kritik/utils/creation/status';
import { FeedbackScoreUtil } from '@kritik/utils/grade';
import * as activityStatus from '@kritik/utils/stage';
import classNames from 'classnames';
import ProgressTooltip from 'components/ActivityCard/Schedule/ProgressTooltip';
import StatusResult, { StageResultProps } from 'components/ActivityCard/Schedule/StatusNode/CompletionDiplay';
import StatusLabel from 'components/ActivityCard/Schedule/StatusNode/Label';
import localUtil from 'components/ActivityCard/Schedule/StatusNode/utils';
import { useUserRoleInCourse } from 'hooks/course';
import STATUS_ICONS from 'images/status-icons';
import { I18nKey, localize } from 'locales';
import { connect } from 'react-redux';
import { getCreationsFromEntities, getMySubmission } from 'selectors/creation';
import { getStudentFromUser } from 'selectors/student';
import { selectAuthUser } from 'selectors/user';

type StageInfoProps = (StageResultProps & { ariaLabel: I18nKey }) | null;

const getCreationStageResultProps = ({ activity, creation, isPastStatus }: any): StageInfoProps => {
  if (creationStatus.isCompleted(creation) && ActivityUtils.isPresentationActivity(activity) && !activity.requireFile) {
    return { ariaLabel: 'StatusNode.Completed', status: 'success' };
  }

  if (creationStatus.isCreationOverdue(activity, creation)) {
    return { ariaLabel: 'StatusNode.Overdue', status: 'fail', symbol: 'warning' };
  }
  if ((creation && creationStatus.wasCreationOnTime(creation)) || ActivityUtils.isCalibrationActivity(activity)) {
    return { ariaLabel: 'StatusNode.Completed', status: 'success' };
  }
  if (creation && creationStatus.wasCreationSubmittedInGracePeriod(creation)) {
    return { ariaLabel: 'StatusNode.SubmittedDuringGracePeriod', status: 'warning', symbol: 'success' };
  }
  if (isPastStatus) {
    if (creationStatus.wasCreationMissed(creation)) {
      return { ariaLabel: 'StatusNode.Missed', status: 'fail' };
    }
    if (!creation || creationStatus.isCreationEmpty(creation)) {
      return { ariaLabel: 'StatusNode.Empty', status: 'fail', symbol: 'warning' };
    }
    if (LateSubmissionUtils.isLateSubmissionPending(creation)) {
      return { ariaLabel: 'StatusNode.LateCreationPending', status: 'warning', symbol: 'success' };
    }
    if (LateSubmissionUtils.isLateSubmissionAccepted(creation)) {
      return { ariaLabel: 'StatusNode.LateCreationAccepted', status: 'success', symbol: 'success' };
    }
    if (LateSubmissionUtils.isLateSubmissionRejected(creation)) {
      return { ariaLabel: 'StatusNode.LateCreationRejected', status: 'fail', symbol: 'fail' };
    }
  }
  return null;
};

export const getEvaluateStageResultProps = ({ creation, student, isPastStatus, activity }: any): StageInfoProps => {
  if (!creation || !student) {
    return null;
  }
  if (evaluationUtil.isCompleted(creation, student._id)) {
    if (activity.hasGraceEvaluations) {
      return { ariaLabel: 'StatusNode.SubmittedDuringGracePeriod', status: 'warning', symbol: 'success' };
    }
    return { ariaLabel: 'StatusNode.Completed', status: 'success' };
  }
  if (isPastStatus) {
    const remainingEvaluations = evaluationUtil.getNumEvaluationRemaining(creation, student._id);
    return { ariaLabel: 'StatusNode.Missed', status: 'fail', label: remainingEvaluations.toString() };
  }
  return null;
};

export const getFeedbackStageResultProps = ({
  activity,
  creation,
  isPastStatus,
  student,
  creations,
}: any): StageInfoProps => {
  if (!creation || ActivityUtils.isCalibrationActivity(activity) || !student) {
    return null;
  }
  const missedIndividualCreation = !creation && !activity.isGroupActivity;
  if (
    (missedIndividualCreation || creationStatus.wasCreationMissed(creation)) &&
    !ActivityUtils.isPresentationActivity(activity)
  ) {
    return { ariaLabel: 'StatusNode.Missed', status: 'fail' };
  }

  let completedFOF = 0;
  let toDo = 0;

  if (ActivityUtils.isPresentationActivity(activity)) {
    const result = FeedbackScoreUtil.numFOFCompletedForPresentationActivity({
      creations,
      studentId: student._id,
    });
    completedFOF = result.completedFOF;
    toDo = result.toDo;
  } else {
    const result = FeedbackScoreUtil.numFOFCompleted(creation, activity, student._id);
    completedFOF = result.completedFOF;
    toDo = result.toDo;
  }

  const missedFOF = toDo - completedFOF;
  if (missedFOF === 0) {
    return { ariaLabel: 'StatusNode.Completed', status: 'success' };
  }
  if (isPastStatus) {
    if (missedFOF > 0) {
      return { ariaLabel: 'StatusNode.Missed', status: 'fail', label: missedFOF.toString() };
    }
  }
  return null;
};

const getStageResultProps = (props: any): StageInfoProps => {
  const { isInstructorInCourse } = useUserRoleInCourse();
  if (isInstructorInCourse && activityStatus.isPastStatus(props.status, props.activity)) {
    return { ariaLabel: 'StatusNode.Completed', status: 'success' };
  }
  const shouldRenderStatus = !activityStatus.isFutureStatus(props.status, props.activity);
  if (!shouldRenderStatus) {
    return null;
  }
  const _creation = props.creation ? props.creation : props.activity.userAssignment;
  switch (props.status.name) {
    case STATUS_LABELS.CREATE:
      return getCreationStageResultProps({
        activity: props.activity,
        creation: _creation,
        isPastStatus: activityStatus.isPastStatus(props.status, props.activity),
      });
    case STATUS_LABELS.EVALUATE:
      return getEvaluateStageResultProps({
        creation: _creation,
        student: props.student,
        isPastStatus: activityStatus.isPastStatus(props.status, props.activity),
        activity: props.activity,
      });
    case STATUS_LABELS.FEEDBACK:
      return getFeedbackStageResultProps({
        activity: props.activity,
        creation: _creation,
        isPastStatus: activityStatus.isPastStatus(props.status, props.activity),
        student: props.student,
        creations: props.creations,
      });
    case PRESENT_STATUS:
      if (activityStatus.isFeedbackOrLater({ assignment: props.activity })) {
        return { ariaLabel: 'StatusNode.Completed', status: 'success' };
      }
    default:
      return null;
  }
};

const StatusNode = (props: any) => {
  const getDisabledIcon = () => {
    switch (props.status.name) {
      case STATUS_LABELS.CREATE:
        return STATUS_ICONS.CREATE_DISABLED;
      case STATUS_LABELS.EVALUATE:
        return STATUS_ICONS.EVALUATE_DISABLED;
      case STATUS_LABELS.FEEDBACK:
        return STATUS_ICONS.FEEDBACK_DISABLED;
      case STATUS_LABELS.PROCESSING3:
        return STATUS_ICONS.GRADING_DISABLED;
      case PRESENT_STATUS:
        return STATUS_ICONS.PRESENT_DISABLED;
      default:
        return null;
    }
  };

  const getActiveIcon = () => {
    switch (props.status.name) {
      case STATUS_LABELS.CREATE:
        return STATUS_ICONS.CREATE;
      case STATUS_LABELS.EVALUATE:
        return STATUS_ICONS.EVALUATE;
      case STATUS_LABELS.FEEDBACK:
        return STATUS_ICONS.FEEDBACK;
      case STATUS_LABELS.PROCESSING3:
        return STATUS_ICONS.GRADING;
      case PRESENT_STATUS:
        return STATUS_ICONS.PRESENT;
      default:
        return null;
    }
  };
  const isPastStage = activityStatus.isPastStatus(props.status, props.activity);
  const isActiveStage = localUtil.isNodeActive(props.status, props.activity);

  const getStatusIcon = () => {
    if (isActiveStage) {
      return getActiveIcon();
    }
    return getDisabledIcon();
  };

  const classes = classNames('status-node', {
    'status-node--active': isActiveStage,
    'status-node--pending': localUtil.isNodeStartingNext(props.status, props.activity),
  });
  let statusName = props.status.name;
  if (statusName === 'Processing3') {
    statusName = 'Grading';
  }
  const resultProps = getStageResultProps({
    status: props.status,
    activity: props.activity,
    creation: props.creation,
    student: props.student,
    user: props.user,
    creations: props.creations,
  });
  let stageLabel = '';
  if (resultProps?.ariaLabel) {
    stageLabel = localize({ message: resultProps.ariaLabel });
  } else if (isPastStage) {
    stageLabel = localize({ message: 'StatusLabel.Step.Completed' });
  } else if (isActiveStage) {
    stageLabel = localize({ message: 'StatusLabel.Step.Current' });
  } else {
    stageLabel = localize({ message: 'StatusLabel.Step.Incomplete' });
  }
  return (
    <ProgressTooltip activity={props.activity} status={props.status}>
      <div className={classes} data-testid={`activity-card-status-node-${props.status.name}`}>
        <div className="status-node__icon-wrapper">
          <img
            className="status-node__icon"
            src={getStatusIcon()}
            alt={localize({
              message: 'StatusNode.StageAriaLabel',
              options: {
                statusName,
                label: stageLabel,
              },
            })}
          />
          {resultProps && (
            <div className="status-node__stage-result">
              <StatusResult {...resultProps} />
            </div>
          )}
        </div>
        {props.showStageLabel && <StatusLabel status={props.status} activity={props.activity} />}
      </div>
    </ProgressTooltip>
  );
};

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    creation: getMySubmission(state, ownProps.activity._id),
    student: getStudentFromUser(state, state.selected.courseId),
    user: selectAuthUser(state),
    creations: getCreationsFromEntities(state, ownProps.activity._id),
  };
};

export default connect(mapStateToProps)(StatusNode);
