import { getUser } from 'selectors/user';
import { RESET_ASYNC } from 'types';

const generateAsyncReducer = (asyncActions: any) => {
  const initialState = asyncActions.call.reduce((a: any, c: any) => {
    return {
      ...a,
      [c]: {
        key: '',
        busy: false,
        success: '',
        error: '',
      },
    };
  }, {});

  function asyncReducer(state = initialState, action = {}) {
    if (asyncActions.call.indexOf((action as any).type) > -1) {
      return {
        ...state,
        [(action as any).type]: { key: (action as any).key, success: '', error: '', busy: true },
      };
    }
    if (asyncActions.success.indexOf((action as any).type) > -1) {
      return {
        ...state,
        [(action as any).type.replace(/_SUCCESS$/, '')]: {
          key: (action as any).key,
          success: (action as any).success,
          error: '',
          busy: false,
        },
      };
    }
    if (asyncActions.error.indexOf((action as any).type) > -1) {
      return {
        ...state,
        [(action as any).type.replace(/_FAILURE$/, '')]: {
          key: (action as any).key,
          success: '',
          error: (action as any).error,
          busy: false,
        },
      };
    }
    if ((action as any).type === RESET_ASYNC) {
      return {
        ...state,
        [(action as any).payload]: { success: '', error: '', busy: false },
      };
    }

    return state;
  }
  return asyncReducer;
};

function getUserObjectByStringId(userObject: any, stateEntities: any) {
  let user = userObject;
  if (typeof user === 'string') {
    user = getUser(user, stateEntities);
  }
  return user;
}

class BaseStore {
  state: any;
  constructor(state: any) {
    this.state = state;
  }

  getSelectedIds() {
    return this.state.selected;
  }

  getEntities() {
    return this.state.entities || {};
  }
}

export { BaseStore, generateAsyncReducer, getUserObjectByStringId };
