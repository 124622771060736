import { Activity, Spotlight as SpotlightType } from '@kritik/types.generated';
import * as FormatUtils from '@kritik/utils/format';
import ShortenText from 'components/General/ShortenText';
import Card from 'components/Spotlight/Card';
import CardActionArea from 'components/Spotlight/Card/ActionArea';
import CardContent from 'components/Spotlight/Card/Content';
import CardFooter from 'components/Spotlight/Card/Footer';
import CardHeader from 'components/Spotlight/Card/Header';
import SpotlightCardMenu from 'components/Spotlight/Card/Menu';
import InfoLabel from 'components/layout/InfoLabel';
import { useUserRoleInCourse } from 'hooks/course';
import { localize } from 'locales';
import { connect } from 'react-redux';
import { navigateToSpotlight, navigateToSpotlightEdit } from 'redux/spotlight';
import { SpotlightTagStore } from 'redux/spotlightTag';
import { selectCurrentActivity } from 'selectors/activity';
import { Spotlight } from 'utils/spotlight';

type SpotlightCardProps = {
  spotlight: SpotlightType & { activity: Activity };
  tag: any;
  courseId: string;
  user: any;
  activity: Activity;
  navigateToSpotlight: any;
  navigateToSpotlightEdit: any;
  hideActivityLink: boolean;
  shortenText: boolean;
  isClickable: boolean;
  index: number;
};

const SpotlightCard = (props: SpotlightCardProps) => {
  const { spotlight, tag, courseId } = props;
  const _spotlight = Spotlight(spotlight);
  const { isInstructorInCourse } = useUserRoleInCourse();
  const renderInstructorMenu = () => {
    if (!isInstructorInCourse || !spotlight) {
      return null;
    }
    return (
      <CardFooter>
        {spotlight.status === 'draft' && (
          <InfoLabel
            label={localize({ message: 'SpotlightCard.Draft' })}
            type="information"
            className="spotlight-card__footer-draft"
          />
        )}
        <div className="spotlight-card__footer-menu">
          <SpotlightCardMenu spotlight={props.spotlight} />
        </div>
      </CardFooter>
    );
  };

  const renderLinkToActivity = () => {
    if (props.hideActivityLink) {
      return null;
    }

    return <div className="spotlight-card-content__ref">{spotlight.activity.title}</div>;
  };

  const cardContent = () => {
    let label = spotlight ? spotlight.comment : tag.getDefinition();
    label = FormatUtils.stripHtmlTags(label);
    let content;
    if (props.shortenText) {
      content = <ShortenText label={label} maxLength={60} hideToolTip />;
    } else {
      content = label;
    }
    return (
      <CardContent>
        {renderLinkToActivity()}
        {content}
      </CardContent>
    );
  };

  const onCardClick = () => {
    if (spotlight.status === 'draft') {
      props.navigateToSpotlightEdit({
        courseId,
        activityId: _spotlight.getActivityId(),
        spotlightId: _spotlight.getId(),
      });
    } else {
      props.navigateToSpotlight({
        courseId,
        activityId: _spotlight.getActivityId(),
        spotlightId: _spotlight.getId(),
      });
    }
  };

  return (
    <Card className="spotlight-card">
      <CardActionArea onClick={onCardClick} isClickable={props.isClickable} index={props.index}>
        <CardHeader tag={tag} />
        {cardContent()}
      </CardActionArea>
      {renderInstructorMenu()}
    </Card>
  );
};

SpotlightCard.defaultProps = {
  hideActivityLink: false,
  shortenText: false,
  isClickable: true,
};

const mapStateToProps = (state: any, ownProps: any) => {
  const spotlightTagStore = new SpotlightTagStore(state);
  return {
    courseId: state.selected.courseId,
    user: state.user,
    activity: selectCurrentActivity(state),
    tag: ownProps.tag || spotlightTagStore.getById(ownProps.spotlight.tag._id || ownProps.spotlight.tag),
  };
};

export default connect(mapStateToProps, { navigateToSpotlight, navigateToSpotlightEdit })(SpotlightCard);
