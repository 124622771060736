import { Creation, Rubric } from '@kritik/types.generated';
import { CreationScoreUtil } from '@kritik/utils/grade';

/**
 * Convert grading & feedback score change per assignment, eval, criteria to a percentage
 * This utils is created for converting old kritik score(ex. 643.232) to new evaluation score(ex. 83.xx%)
 * @return {Number} percentage
 */
export const convertScoreToPercentage = (score: number, maxScoreChange: number) => {
  // maxScoreChange can be 0 when rubric criteria weight is 0.
  if (maxScoreChange === 0) {
    return 0;
  }
  const scoreChangeDelta = maxScoreChange * 2;
  const scoreInPercent = ((score + maxScoreChange) / scoreChangeDelta) * 100;
  return scoreInPercent;
};

/**
 * Get max score change per evaluation, criteria
 * @param {number} totalMaxGradingScoreChange - ex.
 * totalMaxGradingScoreChange of gradingScoreChangePerEval is gradingScoreChangePerAssignment
 * totalMaxGradingScoreChange of gradingScoreChangePerCriteria is gradingScoreChangePerEval
 * @param {number} numsOfEvalOrCritieria
 * @return {number}
 */
export const getMaxScoreChange = (totalMaxGradingScoreChange: number, numsOfEvalOrCritieria: number) => {
  return totalMaxGradingScoreChange / numsOfEvalOrCritieria;
};

export const weightedAvg = (nums: number[], weights: number[]) => {
  if (weights) {
    if (nums.length !== weights.length) {
      throw new Error('nums length is not equal to weights length');
    }
  } else {
    weights = Array(nums.length).fill(1);
  }

  const [sum, totalWeight] = weights.reduce(
    (acc, w, i) => {
      acc[0] += nums[i] * w;
      acc[1] += w;
      return acc;
    },
    [0, 0]
  );

  if (totalWeight === 0) {
    return 0;
  }

  return sum / totalWeight;
};

/**
 * Returns an array of the modes in an array of numbers
 * @param {array} scores
 * @return {array}
 */
export function getMode(scores?: number[] | string) {
  if (!Array.isArray(scores) || !scores.length) {
    return null;
  }

  const frequency = {};
  let maxFrequency = 0;

  scores.forEach((el) => {
    if (!frequency[el]) {
      frequency[el] = 1;
    } else {
      frequency[el] += 1;
    }

    if (frequency[el] > maxFrequency) {
      maxFrequency = frequency[el];
    }
  });

  const modes = [];

  for (const key in frequency) {
    if (frequency[key] === maxFrequency) {
      modes.push(Number(key));
    }
  }

  return modes;
}

/**
 * Get average value of numbers is array
 * @param {array} scores
 * @return {float}
 */
export const getAvgValue = (scores: number[]) => {
  if (scores.length === 0) {
    return 0;
  }
  const avg =
    scores.reduce((acc, value) => {
      return acc + value;
    }) / scores.length;
  return avg;
};

export function getActivityAverageGrade({ rubric, creations }: { creations: Creation[]; rubric: Rubric }): number[] {
  const sumOfLevels = rubric.criteria.map(() => {
    return 0;
  });

  let numCreations = 0;

  creations.forEach((creation) => {
    const toIterate = CreationScoreUtil.getRawCreationScore(creation, rubric);
    if (toIterate.length) {
      numCreations += 1;
    }
    toIterate.forEach((score, i) => {
      if (sumOfLevels[i]) {
        sumOfLevels[i] += score;
      } else {
        sumOfLevels[i] = score;
      }
    });
  });
  if (numCreations === 0) {
    numCreations = 1;
  }
  return sumOfLevels.map((score) => {
    return score / numCreations;
  });
}
