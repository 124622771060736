import { TranslatedText } from 'components/TranslatedText';
import { Button } from 'components/buttons';
import { InlineInformation } from 'components/layout';
import { useState } from 'react';

export const NotConnectedWarning = ({ openConnectCourseModal }) => {
  const [showBanner, setShowBanner] = useState(true);
  if (!showBanner) return null;
  return (
    <InlineInformation className="not-connected-course-container">
      <TranslatedText i18nKey="Course.NotConnected.Warning.Title" />
      <div className="not-connected-course-actions">
        <Button type="primary" onClick={openConnectCourseModal}>
          <TranslatedText i18nKey="Course.NotConnected.Warning.Connect" />
        </Button>
        <Button type="secondary" onClick={() => setShowBanner(false)}>
          <TranslatedText i18nKey="Course.NotConnected.Warning.SetUpLater" />
        </Button>
      </div>
    </InlineInformation>
  );
};
