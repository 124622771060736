import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { TranslatedText } from 'components/TranslatedText';
import Button from 'components/buttons/Button';
import { localize } from 'locales';

function WarningModalLateSubmission({ handleOpen, handleClose, handleEdit, hoursLeft }: any) {
  return (
    <Dialog open={handleOpen} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
        <TranslatedText i18nKey="WarningModalLateSubmission.Title" />
      </DialogTitle>
      <DialogContent id="alert-dialog-description">
        <TranslatedText i18nKey="WarningModalLateSubmission.Description" />
      </DialogContent>
      <DialogContent id="alert-dialog-description" style={{ color: 'red' }}>
        <TranslatedText i18nKey="WarningModalLateSubmission.TimeWarning" values={{ hoursLeft }} />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            return handleEdit();
          }}
          type="primary"
          autoFocus
          testid="confirm-edit-creation"
        >
          {localize({ message: 'WarningModalLateSubmission.EditCreation' })}
        </Button>
        <Button
          onClick={() => {
            return handleClose();
          }}
          type="secondary"
          autoFocus
        >
          {localize({ message: 'WarningModalLateSubmission.Cancel' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default WarningModalLateSubmission;
