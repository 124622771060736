import STAGE_STATUS_ICONS from 'images/status-icons';
import { localize } from 'locales';
import StageTitle from './StageTitle';

type GradingProps = {
  disabled?: boolean;
};

function Grading(props: GradingProps) {
  return (
    <div style={{ marginTop: '20px', display: 'flex' }}>
      <div style={{ marginRight: '45px' }}>
        <img
          src={props.disabled ? STAGE_STATUS_ICONS.GRADING_DISABLED : STAGE_STATUS_ICONS.GRADING}
          aria-hidden="true"
        />
      </div>
      <div>
        <StageTitle title={localize({ message: 'Activity.Schedule.Grading' })} />
        <p>{localize({ message: 'Activity.Schedule.Grading.Note' })}</p>
      </div>
    </div>
  );
}

export default Grading;
