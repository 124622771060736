import { shortenTextWithElipsis } from '@kritik/utils/format';
import { TranslatedText } from 'components/TranslatedText';
import AutoComplete from 'components/core/form/AutoCompleteField';
import { localize } from 'locales';

const CriteriaSelector = (props: any) => {
  const getOptions = () => {
    return props.rubric.criteria.map((criterion: any, index: any) => {
      return { label: shortenTextWithElipsis(criterion.name, 15), key: index };
    });
  };

  const onSelectCriterion = (selectedCriterion: any) => {
    const _selectedCriterion = { ...selectedCriterion };
    const originalValue = props.rubric.criteria[_selectedCriterion.key];
    _selectedCriterion.label = originalValue.name;
    props.onSelect(_selectedCriterion);
  };

  return (
    <div>
      <AutoComplete
        label={localize({ message: 'CriteriaSelector.Label.Area' })}
        id="Area"
        info={<TranslatedText i18nKey="CriteriaSelector.Info.SelectCriterion" />}
        options={getOptions()}
        onChange={onSelectCriterion}
        placeholder={localize({ message: 'CriteriaSelector.Placeholder.SelectCriterion' })}
        input={{ className: 'eval-criterion-selector__input' }}
        isClearedCriteria={props.isClearedCriteria}
        icon={<i className="fas fa-search" />}
      />
    </div>
  );
};

export default CriteriaSelector;
