import { hasBeenDisputed, isDispute, isDisputeResolved } from '@kritik/utils/creation/status';
import { Card, CardActions, CardContent } from '@material-ui/core';
import { createEvaluationDispute } from 'actions/activity';
import DisputeHandler from 'components/Creation/Disputing';
import { TranslatedText } from 'components/TranslatedText';
import Button from 'components/buttons/Button';
import FormFieldLabel from 'components/core/form/FieldLabel';
import { InlineInformation, TextDisplay } from 'components/layout';
import NoticeBoard from 'components/layout/NoticeBoard';
import Confirm from 'components/modals/ConfirmModal';
import { localize } from 'locales';
import React from 'react';
import { connect } from 'react-redux';
import { getErrorMessageFromResponse } from 'utils/error';

type OwnDisputeMenuState = {
  error: string | null;
  confirm: boolean;
  disputeReason: string;
  isSubmittingDispute: boolean;
  isOpen: boolean;
};

type DisputeMenuState = OwnDisputeMenuState & typeof DisputeMenu.defaultProps;

class DisputeMenu extends React.Component<{ isOpen: boolean }, DisputeMenuState> {
  textAreaRef: React.RefObject<HTMLTextAreaElement>;
  constructor(props: any) {
    super(props);
    this.state = {
      error: null,
      confirm: false,
      disputeReason: '',
      isSubmittingDispute: false,
      isOpen: props.isOpen,
    };

    this.textAreaRef = React.createRef();
  }

  static defaultProps = {
    isOpen: false,
  };

  componentDidUpdate(prevProps, prevState): void {
    if (this.props.isOpen && !prevState.isOpen) {
      this.textAreaRef.current?.focus();
    }
  }

  reportEvaluation() {
    if (this.state.isSubmittingDispute) {
      return;
    }
    this.setState({ isSubmittingDispute: true });
    if (this.state.disputeReason.length < 5) {
      return this.setState({
        confirm: false,
        error: localize({ message: 'Creation.Dispute.Error.MoreDescriptiveReason' }),
        isSubmittingDispute: false,
      });
    }

    const data = {
      submissionId: (this.props as any).creation._id,
      dispute: {
        status: 'Dispute',
        disputedOn: new Date(Date.now()),
        reason: this.state.disputeReason,
      },
    };

    (this.props as any).createEvaluationDispute(data).catch((err: any) => {
      this.setState({
        confirm: false,
        error: getErrorMessageFromResponse(err),
        isSubmittingDispute: false,
      });
    });
  }

  isGroupLeader() {
    if (!(this.props as any).assignment.isGroupActivity) {
      return true;
    }
    const isLeader = (this.props as any).user.student.studentId === (this.props as any).creation.group.leader;
    return isLeader;
  }

  handleChange(event: any) {
    this.setState({ error: '', disputeReason: event.currentTarget.value });
  }

  renderDisputeEditor() {
    return (
      <Card>
        <CardContent>
          <Confirm
            isOpen={this.state.confirm}
            title={localize({ message: 'Creation.Dispute.Confirm.Title' })}
            description={localize({ message: 'Creation.Dispute.Confirm.Description' })}
            onCancel={() => {
              return this.setState({ confirm: false });
            }}
            cancelButton={localize({ message: 'Common.No' })}
            onConfirm={() => {
              return this.reportEvaluation();
            }}
            confirmButton={localize({ message: 'Common.Yes' })}
          />

          <FormFieldLabel label={localize({ message: 'Creation.Dispute.Label' })} id="dispute-reason" />
          <div className="dispute-form">
            {this.state.error && <InlineInformation type="danger">{this.state.error}</InlineInformation>}
            <textarea
              id="dispute-reason"
              rows={5}
              maxLength={280}
              placeholder={localize({ message: 'Creation.Dispute.Placeholder' })}
              onChange={this.handleChange.bind(this)}
              value={this.state.disputeReason}
              data-testid="dispute-reason"
              ref={this.textAreaRef}
            />
            <div>
              {!this.isGroupLeader() && (
                <NoticeBoard
                  title={localize({ message: 'Creation.Dispute.GroupLeader.Title' })}
                  type="information"
                  style={{ marginBottom: '20px' }}
                >
                  <TranslatedText i18nKey="Creation.Dispute.GroupLeader.Content" />
                </NoticeBoard>
              )}
            </div>
          </div>
        </CardContent>
        <CardActions style={{ justifyContent: 'end' }}>
          <Button
            onClick={() => {
              return this.setState({ confirm: true });
            }}
            type={this.isGroupLeader() ? 'primary' : 'secondary'}
            disabled={!this.isGroupLeader()}
            data-testid="submit-dispute"
          >
            <TranslatedText i18nKey="Creation.Dispute.SubmitButton" />
          </Button>
        </CardActions>
      </Card>
    );
  }

  renderDisputeView() {
    const { creation } = this.props as any;
    return (
      <NoticeBoard title={localize({ message: 'Creation.Dispute.Status.Title' })}>
        <div>
          {isDispute(creation)
            ? localize({ message: 'Creation.Dispute.Status.PendingReview' })
            : localize({ message: 'Creation.Dispute.Status.Resolved' })}
        </div>
        <div className="dispute-form">
          <span className="dispute-form--section-label">
            <TranslatedText i18nKey="Creation.Dispute.ReasonLabel" />
          </span>
          <TextDisplay content={creation.dispute.reason} />
          {creation.dispute.teacherComment && (
            <React.Fragment>
              <span className="dispute-form--section-label">
                <TranslatedText i18nKey="Creation.Dispute.InstructorCommentLabel" />
              </span>
              <TextDisplay content={creation.dispute.teacherComment} />
            </React.Fragment>
          )}
          {creation.dispute.status === 'Resolved' ? (
            <div className="dispute-form--disclaimer">
              <TranslatedText i18nKey="Creation.Dispute.ResolvedDisclaimer" />
            </div>
          ) : (
            <div className="dispute-form--disclaimer">
              <TranslatedText i18nKey="Creation.Dispute.PendingDisclaimer" />
            </div>
          )}
        </div>
      </NoticeBoard>
    );
  }

  render() {
    if (!(this.props as any).isOpen) {
      return null;
    }
    if (!hasBeenDisputed((this.props as any).creation)) {
      return this.renderDisputeEditor();
    }
    if (isDisputeResolved((this.props as any).creation)) {
      return <DisputeHandler creation={(this.props as any).creation} />;
    }
    return this.renderDisputeView();
  }
}

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps, {
  createEvaluationDispute,
})(DisputeMenu);
