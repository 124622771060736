import cancelation from 'services/cancelation';
import { createRestApiClient } from 'utils/createRestApiClient';

const apiEndpoint = import.meta.env.VITE_API_URL;

export default (options = {}) => {
  const client = (options as any).client || createRestApiClient().withConfig({ baseURL: apiEndpoint });
  return {
    list: (params = {}) => {
      if (cancelation.cancelListUser) {
        // @ts-expect-error TS(2349) FIXME: This expression is not callable.
        cancelation.cancelListUser();
      }

      const config = {
        method: 'GET',
        url: '/users',
        params,
      };

      if (client.CancelToken) {
        (config as any).cancelToken = new client.CancelToken((cancel: any) => {
          cancelation.cancelListUser = cancel;
        });
      }

      return client.request(config);
    },
    get: ({ id }: any) => {
      return client.request({
        method: 'GET',
        url: `/users/${id}`,
      });
    },
    getInstructorEmail: ({ id }: any) => {
      return client.request({
        method: 'GET',
        url: `/users/${id}/instructor`,
      });
    },
    getFeed: (params = {}) => {
      return client.request({
        method: 'GET',
        url: `/feed`,
        params,
      });
    },
    requestAnswer: ({ id, questionId }: any = {}) => {
      return client.request({
        method: 'POST',
        url: `/users/${id}/requests`,
        data: { questionId },
      });
    },
    updateAnswerRequest: ({ id, status }: any = {}) => {
      return client.request({
        method: 'PUT',
        url: `/user/requests/${id}`,
        data: { status },
      });
    },
    getAnswerRequests: ({} = {}) => {
      return client.request({
        method: 'GET',
        url: `/user/requests`,
      });
    },
    update: ({ id, data }: any) => {
      return client.request({
        method: 'PUT',
        url: `/users/${id}`,
        data,
      });
    },
    feature: ({ id, value }: any) => {
      const method = value == 0 ? 'DELETE' : 'POST';
      return client.request({
        method,
        url: `/users/${id}/feature`,
      });
    },
    toggleTutorials: ({ id, tutorialLabels }: any) => {
      return client.request({
        method: 'PUT',
        url: `users/${id}/tutorials`,
        data: tutorialLabels,
      });
    },
    getStudent: ({ courseId }: any) => {
      return client.request({
        method: 'GET',
        url: `/students/courses/${courseId}`,
      });
    },
    chargeStripe: (token: any) => {
      return client.request({
        method: 'POST',
        url: `/payments/stripe`,
        data: token,
      });
    },
    createCoupon: () => {
      return client.request({
        method: 'POST',
        url: `/coupons`,
      });
    },
    validateCoupon: ({ couponId, courseId }: any) => {
      return client.request({
        method: 'GET',
        url: `/coupons/validate/${couponId}/course/${courseId}`,
      });
    },
    updatePreferences: (data: any) => {
      return client.request({
        method: 'PATCH',
        url: `/students/${data.studentId}/preferences`,
        data,
      });
    },
    changePassword: (data: any) => {
      return client.request({
        method: 'PUT',
        url: `/users/${data.userId}/password`,
        data,
      });
    },
    getUserByEmail: ({ email }: any) => {
      return client.request({
        method: 'GET',
        url: `/user/get-user-by-email/${email}`,
      });
    },
    getTeacherOrAdminByEmail: ({ email }: any) => {
      return client.request({
        method: 'GET',
        url: `/users/get-teacher-admin-by-email/${email}`,
      });
    },

    updateAccountInformation: (data: any) => {
      return client.request({
        method: 'PATCH',
        url: `/users/${data.userId}/account`,
        data,
      });
    },
    activateUser: (data: any) => {
      return client.request({
        method: 'PATCH',
        url: `/user/${data.email}/activate`,
        data,
      });
    },
    registerAsDemoStudent: ({ email }: any) => {
      return client.request({
        method: 'POST',
        url: `/demo`,
        data: { email },
      });
    },
  };
};
