import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import classNames from 'classnames';

export const DefaultRadio = (props: any) => {
  const getControlClasses = (option: any) => {
    const classes = classNames('kritik-form__radio-control', {
      'kritik-form__radio-control--active': props.value === option.value,
    });
    return classes;
  };

  const getRadioKey = (option: any) => {
    return `${option.value}_${option.label}`;
  };

  const renderOptions = () => {
    return props.options.map((option: any) => {
      return (
        <FormControlLabel
          key={getRadioKey(option)}
          value={option.value}
          className={getControlClasses(option)}
          control={<Radio disableRipple className="kritik-form__radio" />}
          label={option.label}
          disabled={option.isDisabled === true}
          data-testid={option.testid}
        />
      );
    });
  };

  return (
    <RadioGroup value={props.value} onChange={props.onChange}>
      {renderOptions()}
    </RadioGroup>
  );
};

DefaultRadio.defaultProps = {
  options: [],
};

export default DefaultRadio;
