import Dialog from '@material-ui/core/Dialog';

function FullScreenModal(props: any) {
  return (
    <Dialog hideBackdrop fullScreen open={props.open} onExited={props.onExited} className="full-screen-modal">
      {props.children}
    </Dialog>
  );
}

export default FullScreenModal;
