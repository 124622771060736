import { createRestApiClient } from 'utils/createRestApiClient';

const apiEndpoint = import.meta.env.VITE_API_URL;

export default (options = {}) => {
  const client = (options as any).client || createRestApiClient().withConfig({ baseURL: apiEndpoint });
  return {
    create: (data: any) => {
      return client.request({
        method: 'POST',
        url: `/spotlights`,
        data,
      });
    },
    list: (params: any) => {
      return client.request({
        method: 'GET',
        params,
        url: `/spotlights`,
      });
    },
    get: (id: any) => {
      return client.request({
        method: 'GET',
        url: `/spotlights/${id}`,
      });
    },
    update: (data: any) => {
      return client.request({
        method: 'PATCH',
        url: `/spotlights/${data._id}`,
        data,
      });
    },
    delete: (id: any) => {
      return client.request({
        method: 'DELETE',
        url: `/spotlights/${id}`,
      });
    },
    publish: (spotlightId: string) => {
      return client.request({
        method: 'PATCH',
        url: `/spotlights/${spotlightId}/publish`,
      });
    },
  };
};
