import { TABLE_HEADERS, TABLE_TYPE_OPTIONS } from 'components/CreationList/constant';
import {
  selectCreationTableFilter,
  selectCreationTableSearch,
  selectCreationTableViewType,
  selectCurrentPage,
  selectIsShowEvalsCompleted,
  selectIsShowFeedbackCompleted,
  selectPageSize,
  selectSortedColumn,
} from 'redux/creationTable/select';
import { SORT_ORDER, SORT_TYPE } from './constants';

function getTableSortType(sortedColumn: any, state: any) {
  if (!sortedColumn) {
    return {
      sortBy: SORT_TYPE.NAME,
      sortOrder: SORT_ORDER.ASCENDING,
    };
  }
  let sortBy = '';
  const sortOrder = sortedColumn.sorting.isAscending ? SORT_ORDER.ASCENDING : SORT_ORDER.DESCENDING;
  if (sortedColumn.header === TABLE_HEADERS.NAME) {
    sortBy = SORT_TYPE.NAME;
  } else if (sortedColumn.header === TABLE_HEADERS.EVALUATE) {
    if (selectCreationTableViewType(state) === TABLE_TYPE_OPTIONS.SCORING) {
      sortBy = SORT_TYPE.EVALUATION_SCORE;
    } else if (selectIsShowEvalsCompleted(state)) {
      sortBy = SORT_TYPE.EVALUATION_GIVEN;
    } else {
      sortBy = SORT_TYPE.EVALUATION_RECEIVED;
    }
  } else if (sortedColumn.header === TABLE_HEADERS.FEEDBACK) {
    if (selectCreationTableViewType(state) === TABLE_TYPE_OPTIONS.SCORING) {
      sortBy = SORT_TYPE.FEEDBACK_SCORE;
    } else if (selectIsShowFeedbackCompleted(state)) {
      sortBy = SORT_TYPE.FEEDBACK_GIVEN;
    } else {
      sortBy = SORT_TYPE.FEEDBACK_RECEIVED;
    }
  } else if (sortedColumn.header === TABLE_HEADERS.CREATE) {
    if (selectCreationTableViewType(state) === TABLE_TYPE_OPTIONS.SCORING) {
      sortBy = SORT_TYPE.CREATION_SCORE;
    } else {
      sortBy = SORT_TYPE.CREATE;
    }
  } else if (sortedColumn.header === TABLE_HEADERS.ISSUES) {
    sortBy = SORT_TYPE.ISSUES;
  } else if (sortedColumn.header === TABLE_HEADERS.OVERALL) {
    sortBy = SORT_TYPE.OVERALL_SCORE;
  } else if (sortedColumn.header === TABLE_HEADERS.DISPUTED_DATE) {
    sortBy = SORT_TYPE.DISPUTED_DATE;
  } else if (sortedColumn.header === TABLE_HEADERS.RESOLVED_DATE) {
    sortBy = SORT_TYPE.RESOLVED_DATE;
  } else if (sortedColumn.header === TABLE_HEADERS.STATUS) {
    sortBy = SORT_TYPE.STATUS;
  }
  return { sortBy, sortOrder };
}

export default function getCreationTableQueryParams(state: any) {
  const resultsPerPage = selectPageSize(state);
  let page = selectCurrentPage(state);
  const activityId = state.selected.assignmentId;
  const filterBy = selectCreationTableFilter(state);
  const searchString = selectCreationTableSearch(state);
  const sortedColumn = selectSortedColumn(state);
  const viewType = selectCreationTableViewType(state);
  const { sortBy, sortOrder } = getTableSortType(sortedColumn, state);
  if (searchString.length > 0) {
    page = 1;
  }
  return {
    resultsPerPage,
    page,
    activityId,
    filterBy,
    searchString,
    sortBy,
    sortOrder,
    viewType,
  };
}
