import { localize } from 'locales';

export const CALIBRATION_OPTIONS = [
  { id: 'evaluations', title: localize({ message: 'Activity.StudentView.Tabs.Evaluations' }) },
];

export const FINALIZED_CALIBRATION_OPTIONS = [
  {
    id: 'kritik',
    title: localize({ message: 'Activity.StudentView.Tabs.EvaluationScore' }),
  },
];

export const GROUP_SUBMISSION_OPTION = {
  id: 'submission',
  title: localize({ message: 'Activity.StudentView.Tabs.GroupCreation' }),
};
export const GROUP_EVALUATION_OPTION = {
  id: 'evaluations',
  title: localize({ message: 'Activity.StudentView.Tabs.Evaluations' }),
};
export const GROUP_FEEDBACK_OPTION = {
  id: 'feedback',
  title: localize({ message: 'Activity.StudentView.Tabs.Feedback' }),
};
export const INDIVIDUAL_PRESENTATION_SUBMISSION_OPTION = {
  id: 'submission',
  title: localize({ message: 'Activity.StudentView.Tabs.YourCreation' }),
};
export const INDIVIDUAL_PRESENTATION_EVALUATION_OPTION = {
  id: 'evaluations',
  title: localize({ message: 'Activity.StudentView.Tabs.Evaluations' }),
};
export const INDIVIDUAL_PRESENTATION_FEEDBACK_OPTION = {
  id: 'feedback',
  title: localize({ message: 'Activity.StudentView.Tabs.Feedback' }),
};

export const GROUP_ACTIVITY_MENU_OPTIONS = [GROUP_SUBMISSION_OPTION, GROUP_EVALUATION_OPTION, GROUP_FEEDBACK_OPTION];

export const MENU_OPTIONS = [
  { id: 'submission', title: localize({ message: 'Activity.StudentView.Tabs.YourCreation' }) },
  { id: 'evaluations', title: localize({ message: 'Activity.StudentView.Tabs.Evaluations' }) },
  { id: 'feedback', title: localize({ message: 'Activity.StudentView.Tabs.Feedback' }) },
];

export const HIDE_FEEDBACK_TAB_MENU_OPTIONS = [
  { id: 'submission', title: localize({ message: 'Activity.StudentView.Tabs.YourCreation' }) },
  { id: 'evaluations', title: localize({ message: 'Activity.StudentView.Tabs.Evaluations' }) },
];

export const CREATION_TAB_MENU_OPTIONS = [
  { id: 'submission', title: localize({ message: 'Activity.StudentView.Tabs.YourCreation' }) },
];

export const FINALIZED_CREATION_OPTION = {
  id: 'creation',
  title: localize({ message: 'Activity.StudentView.Tabs.CreationScore' }),
};
export const FINALIZED_EVALUATION_OPTION = {
  id: 'kritik',
  title: localize({ message: 'Activity.StudentView.Tabs.EvaluationScore' }),
};
export const FINALIZED_FEEDBACK_OPTION = {
  id: 'feedbackSummary',
  title: localize({ message: 'Activity.StudentView.Tabs.FeedbackScore' }),
};

export const FINALIZED_MENU_OPTIONS = [
  FINALIZED_CREATION_OPTION,
  FINALIZED_EVALUATION_OPTION,
  FINALIZED_FEEDBACK_OPTION,
];
