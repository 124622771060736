import { ReadOnlyField } from 'components/ActivityEdit/AdvancedOptions/Field';
import { TranslatedText } from 'components/TranslatedText';
import { useForm } from 'react-final-form';

export function StartingScoreReadOnly() {
  const form = useForm();
  const { startingScore } = form.getState().values;

  return (
    <ReadOnlyField>
      <TranslatedText i18nKey={'Activity.StartingScoreField.ReadOnly'} /> <b>{startingScore}%</b>
    </ReadOnlyField>
  );
}
