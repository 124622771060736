import { shortenTextWithElipsis } from '@kritik/utils/format';
import Tooltip from '@material-ui/core/Tooltip';

const ShortenText = (props: any) => {
  if (!props.label) {
    return null;
  }
  const shortLabel = shortenTextWithElipsis(props.label, props.maxLength);
  if (props.label.length <= props.maxLength) {
    return <span className={props.className}>{props.label}</span>;
  }
  if (props.hideToolTip) {
    return <span className={props.className}>{shortLabel}</span>;
  }
  return (
    <Tooltip title={props.label} placement="top">
      <span className={props.className}>{shortLabel}</span>
    </Tooltip>
  );
};

ShortenText.defaultProps = {
  label: null,
  maxLength: 20,
  hideToolTip: false,
};

export default ShortenText;
