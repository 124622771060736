import { createRestApiClient } from 'utils/createRestApiClient';

const apiEndpoint = import.meta.env.VITE_API_URL;

export default (options = {}) => {
  const client =
    (options as any).client || createRestApiClient().withConfig({ baseURL: apiEndpoint, timeout: 1200000 });
  return {
    syncRoster: ({ courseId, apply = false }: any) => {
      return client.request({
        method: 'POST',
        url: `/courses/${courseId}/enrolled/lms-sync`,
        data: { apply },
      });
    },
    getCourseCurrentSyncStatus: ({ courseId }: any) => {
      return client.request({
        method: 'GET',
        url: `/courses/${courseId}/roster-sync/sync-status`,
      });
    },
    getCourseLastSync: ({ courseId }: any) => {
      return client.request({
        method: 'GET',
        url: `/courses/${courseId}/roster-sync/last-sync`,
      });
    },
    getCourseFirstSuccessfulSync: ({
      courseId,
    }: any): Promise<DataResponse<courses.$id.roster_sync.first_success.GET.Response>> => {
      return client.request({
        method: 'GET',
        url: `/courses/${courseId}/roster-sync/first-success`,
      });
    },
    disconnectCourse: (courseId: any) => {
      return client.request({
        method: 'PUT',
        url: '/lms/disconnect-course',
        data: { courseId },
      });
    },
    approvePendingConnection: (code: string) => {
      return client.request({
        method: 'POST',
        url: '/lms/approve-pending-connection',
        data: { code },
      });
    },
    resendPendingConnectionCode: () => {
      return client.request({
        method: 'POST',
        url: '/lms/resend-pending-connection-code',
      });
    },
    getCourseLastGroupSyncResults: ({
      courseId,
    }: any): Promise<DataResponse<courses.$id.group_sync.last_sync_results.GET.Response>> => {
      return client.request({
        method: 'GET',
        url: `/courses/${courseId}/group-sync/last-sync-results`,
      });
    },
  };
};
