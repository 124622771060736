import { Rubric as RubricType } from '@kritik/types.generated';
import { getPassLevelName, hasLessLevelsThanPassLevel, isMultiLevelRubric } from '@kritik/utils/rubric';
import MenuItem from '@material-ui/core/MenuItem';
import { listRubrics } from 'actions/rubrics';
import Rubric from 'components/Rubric/Edit/Grid';
import { TranslatedText } from 'components/TranslatedText';
import FormFieldInfo from 'components/core/form/FieldInfo';
import FormFieldLabel from 'components/core/form/FieldLabel';
import FormTitle from 'components/core/form/Title';
import NumberInput from 'components/core/input/Number';
import RadioInput from 'components/core/input/Radio';
import Select from 'components/core/input/Select';
import { InlineInformation } from 'components/layout';
import NoticeBoard from 'components/layout/NoticeBoard';
import { localize } from 'locales';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { selectRubrics } from 'selectors/rubric';
import { withRouter } from 'utils/withRouter';

type RubricEditState = any;

class RubricEdit extends Component<{}, RubricEditState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      usePassLevel: true,
      selectedIdx: null,
      numericGrading: (props as any).numericGrading,
      selectedRows: [],
      removingColumn: false,
      newRubricTemplateName: '',
      criteriaLevel: (props as any).criteriaLevel !== null ? (props as any).criteriaLevel : true,
      minimumPercentageValue:
        (props as any).minimumPercentageValue !== null ? (props as any).minimumPercentageValue : 0,
    };
    this.toggleConfirmModal = this.toggleConfirmModal.bind(this);
    this.onLevelChange = this.onLevelChange.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    // @ts-expect-error TS(2339) FIXME: Property 'rubrics' does not exist on type 'Readonl... Remove this comment to see the full error message
    const { rubrics } = this.props;
    if (!rubrics) {
      // Fetch rubrics only belongs to users and for templates
      (this.props as any).listRubrics();
    }
  }

  processUpdate(changedRubricProps: any) {
    if ((this.props as any).input && typeof (this.props as any).onChange === 'function') {
      (this.props as any).onChange({
        ...(this.props as any).input.value,
        ...changedRubricProps,
        name: 'Custom Rubric',
        description: 'A custom rubric made by you.',
      });
    }
  }

  onGradeLevelSelect = (e: any) => {
    const rubric = this.getRubricInput();
    const index = (rubric?.levels || []).findIndex((levelName: any) => levelName === e.target.value);

    this.processUpdate({ passLevel: `${index}` });
  };

  onGradeCriteriaSelect = (e: any) => {
    this.processUpdate({ passCriteriaRule: e.target.value });
  };

  onPassFailConidtionSelect = (isCriteria: boolean) => {
    this.processUpdate({ passFailCondition: isCriteria ? 'Criteria' : 'MinimumPercentage' });
  };

  onMinimumPercentageChange = (value: Number) => {
    this.processUpdate({ minimumPercentage: value });
  };

  getRubricInput() {
    // @ts-expect-error TS(2339) FIXME: Property 'input' does not exist on type 'Readonly<... Remove this comment to see the full error message
    const { input } = this.props;
    return input && input.value ? input.value : null;
  }

  toggleConfirmModal(e: any) {
    this.setState({ confirm: true });
  }

  sanitizePassLevel(rubric: any, isNumericGrading: any) {
    let _isNumericGrading = isNumericGrading;
    if (isMultiLevelRubric(rubric)) {
      _isNumericGrading = true;
    }
    return _isNumericGrading;
  }

  setNumericGrading(isNumericGrading: boolean, rubric?: RubricType) {
    let _rubric = rubric;
    if (!_rubric) {
      _rubric = this.getRubricInput();
    }
    const passCriteria = this.sanitizePassLevel(_rubric, isNumericGrading);
    if (passCriteria !== this.state.numericGrading) {
      this.setState({ numericGrading: passCriteria });
      (this.props as any).onNumericGradingSelect(passCriteria);
    }
  }

  setCriteriaLevel(isCriteria: boolean) {
    this.setState({ criteriaLevel: isCriteria });
    if (!isCriteria) {
      this.setMinimumPercentageValue(0);
    }
  }

  sanitizeMinimumPercentage(value: any) {
    const min = 0;
    const max = 100;
    if (Number(value) < min) {
      return min;
    }
    if (Number(value) > max) {
      return max;
    }
    return Number(value);
  }

  setMinimumPercentageValue(percentage: Number) {
    this.setState({ minimumPercentageValue: percentage });
  }

  renderPassLevel() {
    const rubric = this.getRubricInput();
    const getGradingOptions = () => {
      return [
        {
          value: true,
          label: localize({ message: 'Rubric.Edit.GradingOptions.Numeric' }),
        },
        {
          value: false,
          label: localize({ message: 'Rubric.Edit.GradingOptions.PassFail' }),
          isDisabled: isMultiLevelRubric(rubric),
        },
      ];
    };

    const getPassFailOptions = () => {
      return [
        {
          value: true,
          label: localize({ message: 'Rubric.Edit.PassFailOptions.CriteriaLevel' }),
        },
        {
          value: false,
          label: localize({ message: 'Rubric.Edit.PassFailOptions.MinimumPercentage' }),
        },
      ];
    };

    const PASS_CRITERIA = [
      { label: localize({ message: 'Rubric.Edit.PassCriteria.AllCriteria' }), value: 'all' },
      {
        label: localize({ message: 'Rubric.Edit.PassCriteria.MajorityCriteria' }),
        value: 'majority',
      },
      { label: localize({ message: 'Rubric.Edit.PassCriteria.AnyCriteria' }), value: 'any' },
    ];

    if ((this.props as any).input) {
      const { passLevel, passCriteriaRule, levels } = rubric;
      const levelGradingOptions = levels.map((levelName: any) => {
        return { label: levelName, value: levelName };
      });

      return (
        <React.Fragment>
          <FormTitle label={localize({ message: 'Rubric.Edit.RubricGrading' })} />
          <RadioInput
            value={this.state.numericGrading}
            options={getGradingOptions()}
            onChange={() => {
              return this.setNumericGrading(!this.state.numericGrading);
            }}
            type="button"
            legendText="Legend.Text.RubricGrading"
          />
          {isMultiLevelRubric(rubric) && (
            <NoticeBoard type="caution">
              <TranslatedText i18nKey="Rubric.Edit.PassFail.DisabledNotice" />
            </NoticeBoard>
          )}
          {!this.state.numericGrading && hasLessLevelsThanPassLevel(rubric) && (
            <NoticeBoard type="caution">
              <TranslatedText i18nKey="Rubric.PassLevel.Warning" />
            </NoticeBoard>
          )}
          {!this.state.numericGrading && (
            <div>
              <FormFieldLabel label={localize({ message: 'Rubric.Edit.SelectPassFailCondition' })} />
              <RadioInput
                value={this.state.criteriaLevel}
                options={getPassFailOptions()}
                onChange={() => {
                  this.onPassFailConidtionSelect(!this.state.criteriaLevel);
                  this.setCriteriaLevel(!this.state.criteriaLevel);
                }}
                type="button"
                legendText="Legend.Text.RubricGrading"
              />
            </div>
          )}
          {!this.state.numericGrading && this.state.criteriaLevel && (
            <FormFieldInfo>
              <div className="rubric-editor__pass-fail">
                <TranslatedText i18nKey="Rubric.Edit.PassFailInfo.CriteriaLevel" />
                <div className="rubric-editor__criteria-selection">
                  <Select
                    disabled={this.state.numericGrading}
                    value={getPassLevelName(rubric) ?? ''}
                    onChange={this.onGradeLevelSelect}
                  >
                    {levelGradingOptions.map((option: any) => {
                      return (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </div>
                <div className="rubric-editor__criteria-selection-separator">
                  {' '}
                  {localize({ message: 'Rubric.Edit.PassFailInfo.In' })}
                </div>
                <div className="rubric-editor__criteria-selection">
                  <Select value={passCriteriaRule} onChange={this.onGradeCriteriaSelect}>
                    {PASS_CRITERIA.map((criteria) => {
                      return (
                        <MenuItem key={criteria.value} value={criteria.value}>
                          {criteria.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </div>
              </div>
            </FormFieldInfo>
          )}
          {!this.state.numericGrading && !this.state.criteriaLevel && (
            <div className="rubric-editor__pass-fail">
              <TranslatedText i18nKey="Rubric.Edit.PassFailInfo.MinimumPercentage" />
              <div className="rubric-editor__criteria-selection">
                <NumberInput
                  value={this.state.minimumPercentageValue}
                  onChange={(e: any) => {
                    this.setMinimumPercentageValue(this.sanitizeMinimumPercentage(e.target.value));
                    this.onMinimumPercentageChange(this.sanitizeMinimumPercentage(e.target.value));
                  }}
                />
              </div>
              %
            </div>
          )}
        </React.Fragment>
      );
    }
    return null;
  }

  onLevelChange(updatedGrid: any) {
    this.setNumericGrading(this.state.numericGrading, updatedGrid);
  }

  onChange(newRubric: any) {
    if ((this.props as any).input && typeof (this.props as any).onChange === 'function') {
      (this.props as any).onChange({
        ...newRubric,
        name: 'Custom Rubric',
        description: 'A custom rubric made by you.',
      });
    }
  }

  render() {
    if (!(this.props as any).input || !(this.props as any).input.value) {
      return null;
    }

    const rubric = this.getRubricInput();

    return (
      <React.Fragment>
        <Rubric rubric={rubric} onChange={this.onChange} onLevelChange={this.onLevelChange} />
        {(this.props as any).showUnsavedWarning && (
          <InlineInformation type="information" style={{ marginTop: 10 }}>
            <TranslatedText i18nKey="Rubric.Edit.UnsavedWarning" />
          </InlineInformation>
        )}
        {this.renderPassLevel()}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    entities: state.entities,
    user: state.user,
    rubrics: selectRubrics(state),
  };
};

export default withRouter(connect(mapStateToProps, { listRubrics }, null)(RubricEdit));
