import { Group } from '@kritik/types.generated';
import { Dialog } from '@material-ui/core';
import { moveGroupMember } from 'actions/groups';
import { TranslatedText } from 'components/TranslatedText';
import { Button } from 'components/buttons';
import { InlineInformation } from 'components/layout';
import NoticeBoard from 'components/layout/NoticeBoard';
import { useState } from 'react';
import { connect } from 'react-redux';

type MoveGroupMemberArgs = {
  memberId: string;
  oldGroupId: string;
  newGroupId: string;
  groupSetId: string;
};
type MoveStudentModalProps = {
  currentGroupId: string;
  groupSetId: string;
  groups: Group[];
  memberId: string;
  moveGroupMember: ({ memberId, oldGroupId, newGroupId, groupSetId }: MoveGroupMemberArgs) => Promise<any>;
  onClose: () => void;
};
function AssignNewGroupModal({
  currentGroupId,
  groupSetId,
  groups,
  memberId,
  moveGroupMember,
  onClose,
}: MoveStudentModalProps) {
  const [error, setError] = useState('' as string | JSX.Element);
  const currentGroup = groups.find((group: Group) => group._id == currentGroupId);
  const showDeleteCreationWarning = currentGroup?.members.length === 1;

  async function moveToGroup(selectedGroupId: string) {
    if (currentGroupId === selectedGroupId) {
      setError(<TranslatedText i18nKey="RosterManager.GroupTab.AssignNewGroupModal.SelectNewGroup" />);
      return;
    }

    moveGroupMember({
      memberId,
      oldGroupId: currentGroupId,
      newGroupId: selectedGroupId,
      groupSetId,
    }).then((error: string) => {
      if (error) {
        setError(error);
      } else {
        onClose();
      }
    });
  }

  return (
    <Dialog
      open
      className="file-modal"
      onClose={onClose}
      fullWidth={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div className="assign-new-group-modal__header">
        <TranslatedText i18nKey="RosterManager.GroupTab.AssignNewGroupModal.Title" />
      </div>
      {showDeleteCreationWarning && (
        <div className="assign-new-group-modal__warning">
          <NoticeBoard
            type="danger"
            title={<TranslatedText i18nKey="RosterManager.GroupTab.AssignNewGroupModal.DeleteCreationWarning" />}
          />
        </div>
      )}

      <div className="assign-new-group-modal__list">
        {groups.map((group, idx: number) => {
          return (
            <div
              className="assign-new-group-modal__item"
              onClick={() => {
                moveToGroup(group._id);
              }}
              data-testid={`change-group-item-${idx}`}
              key={idx}
            >
              <span className="assign-new-group-modal__item-name">{group.name}</span>
              <div className="assign-new-group-modal__item-members">{group.members.length}</div>
            </div>
          );
        })}
      </div>

      {error && (
        <InlineInformation style={{ margin: '1em' }} type="danger">
          {error}
        </InlineInformation>
      )}

      <div className="assign-new-group-modal__buttons">
        <Button onClick={onClose} type="secondary">
          <TranslatedText i18nKey="Cancel" />
        </Button>
      </div>
    </Dialog>
  );
}

const mapStateToProps = (state: any) => {
  return {
    groupSetId: state.selected.groupSetId,
  };
};

export default connect(mapStateToProps, { moveGroupMember })(AssignNewGroupModal);
