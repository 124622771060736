import SYNC_ERROR from './sync-error.svg';
import SYNC_READY from './sync-sync.svg';
import SYNC_SUCCESS from './sync-synced.svg';

const SYNC_ICONS = {
  SYNC_READY,
  SYNC_SUCCESS,
  SYNC_ERROR,
};

export default SYNC_ICONS;
