export const DISMISS_MESSAGE = 'DISMISS_MESSAGE';
export const SET_ACCESSIBILITY_MESSAGE = 'SET_ACCESSIBILITY_MESSAGE';

export const CHANGE_ORDER = 'CHANGE_ORDER';
export const CHANGE_ORDER_SUCCESS = 'CHANGE_ORDER_SUCCESS';

export const GET_ASSIGNMENTS_REQUEST = 'GET_ASSIGNMENTS_REQUEST';
export const GET_ASSIGNMENTS_SUCCESS = 'GET_ASSIGNMENTS_SUCCESS';
export const GET_ASSIGNMENTS_FAILURE = 'GET_ASSIGNMENTS_FAILURE';

export const DELETE_FINALIZED_REQUEST = 'DELETE_FINALIZED_REQUEST';
export const DELETE_FINALIZED_SUCCESS = 'DELETE_FINALIZED_SUCCESS';
export const DELETE_FINALIZED_FAILURE = 'DELETE_FINALIZED_FAILURE';

export const DISABLE_LMS_GRADE_SYNC_WARNING_REQUEST = 'DISABLE_LMS_GRADE_SYNC_WARNING_REQUEST';
export const DISABLE_LMS_GRADE_SYNC_WARNING_SUCCESS = 'DISABLE_LMS_GRADE_SYNC_WARNING_SUCCESS';
export const DISABLE_LMS_GRADE_SYNC_WARNING_FAILURE = 'DISABLE_LMS_GRADE_SYNC_WARNING_FAILURE';

export const CREATE_ASSIGNMENT_REQUEST = 'CREATE_ASSIGNMENT_REQUEST';
export const CREATE_ASSIGNMENT_SUCCESS = 'CREATE_ASSIGNMENT_SUCCESS';
export const CREATE_ASSIGNMENT_FAILURE = 'CREATE_ASSIGNMENT_FAILURE';
export const UPDATE = 'UPDATE';

export const UPDATE_ASSIGNMENT_REQUEST = 'UPDATE_ASSIGNMENT_REQUEST';
export const UPDATE_ASSIGNMENT_SUCCESS = 'UPDATE_ASSIGNMENT_SUCCESS';
export const UPDATE_ASSIGNMENT_FAILURE = 'UPDATE_ASSIGNMENT_FAILURE';

export const UPDATE_ASSIGNMENT_SCHEDULE = 'UPDATE_ASSIGNMENT_SCHEDULE';
export const UPDATE_ASSIGNMENT_SCHEDULE_SUCCESS = 'UPDATE_ASSIGNMENT_SCHEDULE_SUCCESS';
export const UPDATE_ASSIGNMENT_SCHEDULE_FAILURE = 'UPDATE_ASSIGNMENT_SCHEDULE_FAILURE';

export const CREATE_ASSIGNMENT_SUBMISSION_REQUEST = 'CREATE_ASSIGNMENT_SUBMISSION_REQUEST';
export const CREATE_ASSIGNMENT_SUBMISSION_SUCCESS = 'CREATE_ASSIGNMENT_SUBMISSION_SUCCESS';
export const CREATE_ASSIGNMENT_SUBMISSION_FAILURE = 'CREATE_ASSIGNMENT_SUBMISSION_FAILURE';

export const CREATE_ASSIGNMENT_SUBMISSION_SAVE_REQUEST = 'CREATE_ASSIGNMENT_SUBMISSION_REQUEST';
export const CREATE_ASSIGNMENT_SUBMISSION_SAVE_SUCCESS = 'CREATE_ASSIGNMENT_SUBMISSION_SUCCESS';
export const CREATE_ASSIGNMENT_SUBMISSION_SAVE_FAILURE = 'CREATE_ASSIGNMENT_SUBMISSION_FAILURE';

export const CREATE_SUBMISSION_EVALUATION_REQUEST = 'CREATE_SUBMISSION_EVALUATION_REQUEST';
export const CREATE_SUBMISSION_EVALUATION_SUCCESS = 'CREATE_SUBMISSION_EVALUATION_SUCCESS';
export const CREATE_SUBMISSION_EVALUATION_FAILURE = 'CREATE_SUBMISSION_EVALUATION_FAILURE';

export const UPDATE_SUBMISSION_EVALUATION_REQUEST = 'UPDATE_SUBMISSION_EVALUATION_REQUEST';
export const UPDATE_SUBMISSION_EVALUATION_SUCCESS = 'UPDATE_SUBMISSION_EVALUATION_SUCCESS';
export const UPDATE_SUBMISSION_EVALUATION_FAILURE = 'UPDATE_SUBMISSION_EVALUATION_FAILURE';

export const APPROVE_SUBMISSION_EVALUATION_REQUEST = 'APPROVE_SUBMISSION_EVALUATION_REQUEST';
export const APPROVE_SUBMISSION_EVALUATION_SUCCESS = 'APPROVE_SUBMISSION_EVALUATION_SUCCESS';
export const APPROVE_SUBMISSION_EVALUATION_FAILURE = 'APPROVE_SUBMISSION_EVALUATION_FAILURE';

export const COMMENT_SUBMISSION_EVALUATION_REQUEST = 'COMMENT_SUBMISSION_EVALUATION_REQUEST';
export const COMMENT_SUBMISSION_EVALUATION_SUCCESS = 'COMMENT_SUBMISSION_EVALUATION_SUCCESS';
export const COMMENT_SUBMISSION_EVALUATION_FAILURE = 'COMMENT_SUBMISSION_EVALUATION_FAILURE';

export const GET_ASSIGNMENT_SUBMISSIONS_REQUEST = 'GET_ASSIGNMENT_SUBMISSIONS_REQUEST';
export const GET_ASSIGNMENT_SUBMISSIONS_SUCCESS = 'GET_ASSIGNMENT_SUBMISSIONS_SUCCESS';
export const GET_ASSIGNMENT_SUBMISSIONS_FAILURE = 'GET_ASSIGNMENT_SUBMISSIONS_FAILURE';

export const CREATE_EVALUATION_FOF_REQUEST = 'CREATE_EVALUATION_FOF_REQUEST';
export const CREATE_EVALUATION_FOF_SUCCESS = 'CREATE_EVALUATION_FOF_SUCCESS';
export const CREATE_EVALUATION_FOF_FAILURE = 'CREATE_EVALUATION_FOF_FAILURE';

export const CREATE_EVALUATION_DISPUTE_REQUEST = 'CREATE_EVALUATION_DISPUTE_REQUEST';
export const CREATE_EVALUATION_DISPUTE_SUCCESS = 'CREATE_EVALUATION_DISPUTE_SUCCESS';
export const CREATE_EVALUATION_DISPUTE_FAILURE = 'CREATE_EVALUATION_DISPUTE_FAILURE';

export const RESOLVE_DISPUTE_REQUEST = 'RESOLVE_DISPUTE_REQUEST';
export const RESOLVE_DISPUTE_SUCCESS = 'RESOLVE_DISPUTE_SUCCESS';
export const RESOLVE_DISPUTE_FAILURE = 'RESOLVE_DISPUTE_FAILURE';

export const RESOLVE_LATE_SUBMISSION_REQUEST = 'RESOLVE_LATE_SUBMISSION_REQUEST';
export const RESOLVE_LATE_SUBMISSION_SUCCESS = 'RESOLVE_LATE_SUBMISSION_SUCCESS';
export const RESOLVE_LATE_SUBMISSION_FAILURE = 'RESOLVE_LATE_SUBMISSION_FAILURE';

// course
export const GET_COURSE_REQUEST = 'GET_COURSE_REQUEST';
export const GET_COURSE_SUCCESS = 'GET_COURSE_SUCCESS';
export const GET_COURSE_FAILURE = 'GET_COURSE_FAILURE';
export const GET_COURSES_REQUEST = 'GET_COURSES_REQUEST';
export const GET_COURSES_SUCCESS = 'GET_COURSES_SUCCESS';
export const GET_COURSES_FAILURE = 'GET_COURSES_FAILURE';
export const CREATE_COURSE_REQUEST = 'CREATE_COURSE_REQUEST';
export const CREATE_COURSE_SUCCESS = 'CREATE_COURSE_SUCCESS';
export const CREATE_COURSE_FAILURE = 'CREATE_COURSE_FAILURE';
export const UPDATE_COURSE_REQUEST = 'UPDATE_COURSE_REQUEST';
export const UPDATE_COURSE_SUCCESS = 'UPDATE_COURSE_SUCCESS';
export const UPDATE_COURSE_FAILURE = 'UPDATE_COURSE_FAILURE';
export const GET_COURSE_SPOTLIGHT_REQUEST = 'GET_COURSE_SPOTLIGHT_REQUEST';
export const GET_COURSE_SPOTLIGHT_SUCCESS = 'GET_COURSE_SPOTLIGHT_SUCCESS';
export const GET_COURSE_SPOTLIGHT_FAILURE = 'GET_COURSE_SPOTLIGHT_FAILURE';

export const EXPORT_COURSE_CREATIONS_REQUEST = 'EXPORT_COURSE_CREATIONS_REQUEST';
export const EXPORT_COURSE_CREATIONS_SUCCESS = 'EXPORT_COURSE_CREATIONS_SUCCESS';
export const EXPORT_COURSE_CREATIONS_FAILURE = 'EXPORT_COURSE_CREATIONS_FAILURE';
export const EXPORT_COURSE_CREATIONS_CANCEL = 'EXPORT_COURSE_CREATIONS_CANCEL';
export const EXPORT_COURSE_CREATIONS_INIT = 'EXPORT_COURSE_CREATIONS_INIT';

export const CREATE_DUPLICATE_COURSE_REQUEST = 'CREATE_DUPLICATE_COURSE_REQUEST';
export const CREATE_DUPLICATE_COURSE_SUCCESS = 'CREATE_DUPLICATE_COURSE_SUCCESS';
export const CREATE_DUPLICATE_COURSE_FAILURE = 'CREATE_DUPLICATE_COURSE_FAILURE';
export const CLEAR_DUPLICATE_COURSE_FAILURE = 'CLEAR_DUPLICATE_COURSE_FAILURE';

export const SET_AVG_OVERALL_SCORE = 'SET_AVG_OVERALL_SCORE';
export const SET_AVG_CREATION_SCORE = 'SET_AVG_CREATION_SCORE';
export const SET_AVG_EVAL_SCORE = 'SET_AVG_EVAL_SCORE';
export const SET_AVG_FEEDBACK_SCORE = 'SET_AVG_FEEDBACK_SCORE';
export const INIT_DASHBOARD_SCORES = 'INIT_DASHBOARD_SCORES';

export const LIST_RUBRICS_REQUEST = 'LIST_RUBRICS_REQUEST';
export const LIST_RUBRICS_SUCCESS = 'LIST_RUBRICS_SUCCESS';
export const LIST_RUBRICS_FAILURE = 'LIST_RUBRICS_FAILURE';
export const LIST_RUBRIC_TEMPLATES_REQUEST = 'LIST_RUBRIC_TEMPLATES_REQUEST';
export const LIST_RUBRIC_TEMPLATES_SUCCESS = 'LIST_RUBRIC_TEMPLATES_SUCCESS';
export const LIST_RUBRIC_TEMPLATES_FAILURE = 'LIST_RUBRIC_TEMPLATES_FAILURE';
export const GET_RUBRIC_REQUEST = 'GET_RUBRIC_REQUEST';
export const GET_RUBRIC_SUCCESS = 'GET_RUBRIC_SUCCESS';
export const GET_RUBRIC_FAILURE = 'GET_RUBRIC_FAILURE';
export const GET_RUBRIC_TEMPLATE_REQUEST = 'GET_RUBRIC_TEMPLATE_REQUEST';
export const GET_RUBRIC_TEMPLATE_SUCCESS = 'GET_RUBRIC_TEMPLATE_SUCCESS';
export const GET_RUBRIC_TEMPLATE_FAILURE = 'GET_RUBRIC_TEMPLATE_FAILURE';
export const CREATE_NEW_RUBRIC_REQUEST = 'CREATE_NEW_RUBRIC_REQUEST';
export const CREATE_NEW_RUBRIC_SUCCESS = 'CREATE_NEW_RUBRIC_SUCCESS';
export const CREATE_NEW_RUBRIC_FAILURE = 'CREATE_NEW_RUBRIC_FAILURE';
export const UPDATE_RUBRIC = 'UPDATE_RUBRIC';
export const UPDATE_RUBRIC_SUCCESS = 'UPDATE_RUBRIC_SUCCESS';
export const UPDATE_RUBRIC_FAILURE = 'UPDATE_RUBRIC_FAILURE';
export const CREATE_NEW_TEMPLATE = 'CREATE_NEW_TEMPLATE';
export const CREATE_NEW_TEMPLATE_SUCCESS = 'CREATE_NEW_TEMPLATE_SUCCESS';
export const CREATE_NEW_TEMPLATE_FAILURE = 'CREATE_NEW_TEMPLATE_FAILURE';

export const HIGHLIGHTED_RUBRIC_CRITERIA = 'HIGHLIGHTED_RUBRIC_CRITERIA';
export const HIGHLIGHTED_RUBRIC_LEVEL = 'HIGHLIGHTED_RUBRIC_LEVEL';
export const RUBRIC_DROPDOWN_OPEN = 'RUBRIC_DROPDOWN_OPEN';

export const MERGE_ENTITIES = 'MERGE_ENTITIES';
export const SYNC_ENTITIES = 'SYNC_ENTITIES';
export const UPDATE_ENTITIES = 'UPDATE_ENTITIES';
export const REPLACE_ENTITIES = 'REPLACE_ENTITIES';
export const REMOVE_ENTITIES = 'REMOVE_ENTITIES';
export const REMOVE_ENTITY = 'REMOVE_ENTITY';
export const CLEAR_ENTITIES = 'CLEAR_ENTITIES';

export const CREATE_REQUEST = 'CREATE_REQUEST';
export const REQUEST_SUCCESS = 'REQUEST_SUCCESS';
export const REQUEST_FAILURE = 'REQUEST_FAILURE';

export const SEARCH_ALL_REQUEST = 'SEARCH_ALL_REQUEST';
export const SEARCH_ALL_SUCCESS = 'SEARCH_ALL_SUCCESS';
export const SEARCH_ALL_FAILURE = 'SEARCH_ALL_FAILURE';

export const TOGGLE_LOGIN_MODE = 'TOGGLE_LOGIN_MODE';
export const MANUAL_LOGIN_USER = 'MANUAL_LOGIN_USER';
export const LOGIN_SUCCESS_USER = 'MANUAL_LOGIN_USER_SUCCESS';
export const LOGIN_ERROR_USER = 'MANUAL_LOGIN_USER_FAILURE';
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNUP_USER_FAILURE = 'SIGNUP_USER_FAILURE';
export const LOGOUT_USER = 'LOGOUT_USER';
export const LOGOUT_SUCCESS_USER = 'LOGOUT_SUCCESS_USER';
export const LOGOUT_ERROR_USER = 'LOGOUT_ERROR_USER';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';

export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';
export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';
export const SEARCH_USERS_REQUEST = 'SEARCH_USERS_REQUEST';
export const SEARCH_USERS_SUCCESS = 'SEARCH_USERS_SUCCESS';
export const SEARCH_USERS_FAILURE = 'SEARCH_USERS_FAILURE';

export const UPLOAD_USER_IMAGE_REQUEST = 'UPLOAD_USER_IMAGE_REQUEST';
export const UPLOAD_USER_IMAGE_SUCCESS = 'UPLOAD_USER_IMAGE_SUCCESS';
export const UPLOAD_USER_IMAGE_FAILURE = 'UPLOAD_USER_IMAGE_FAILURE';
export const UPDATE_AUTH_USER = 'UPDATE_AUTH_USER';

export const GET_CURRENT_USER_REQUEST = 'GET_CURRENT_USER_REQUEST';
export const GET_CURRENT_USER_SUCCESS = 'GET_CURRENT_USER_SUCCESS';
export const GET_CURRENT_USER_FAILURE = 'GET_CURRENT_USER_FAILURE';

export const GET_KRITIK_SCORE_REQUEST = 'GET_KRITIK_SCORE_REQUEST';
export const GET_KRITIK_SCORE_SUCCESS = 'GET_KRITIK_SCORE_SUCCESS';
export const GET_KRITIK_SCORE_FAILURE = 'GET_KRITIK_SCORE_FAILURE';

export const GET_STUDENT = 'GET_STUDENT';
export const GET_STUDENT_SUCCESS = 'GET_STUDENT_SUCCESS';
export const GET_STUDENT_FAILURE = 'GET_STUDENT_FAILURE';

export const CHANGE_GRADE_METHOD = 'CHANGE_GRADE_METHOD';

export const SELECT_COURSE = 'SELECT::COURSE';
export const SELECT_ASSIGNMENT = 'SELECT::ASSIGNMENT';
export const SELECT_SUBMISSION = 'SELECT::SUBMISSION';
export const SELECT_GROUP_SET = 'SELECT::GROUP_SET';
export const SELECT_RESET = 'SELECT::RESET_ALL';

export const STRIPE_CHARGE_USER = 'STRIPE::CHARGE_USER';
export const STRIPE_CHARGE_USER_SUCCESS = 'STRIPE::CHARGE_USER_SUCCESS';
export const STRIPE_CHARGE_USER_FAILURE = 'STRIPE::CHARGE_USER_FAILURE';

export const GROUP_GENERATE_SET = 'GROUP::GENERATE_SET';
export const GROUP_GENERATE_SET_SUCCESS = 'GROUP::GENERATE_SET_SUCCESS';
export const GROUP_GENERATE_SET_FAILURE = 'GROUP::GENERATE_SET_FAILURE';

export const GENERATE_TEST_CREATIONS = 'GENERATE_TEST_CREATIONS';
export const GENERATE_TEST_CREATIONS_SUCCESS = 'GENERATE_TEST_CREATIONS_SUCCESS';
export const GENERATE_TEST_CREATIONS_FAILURE = 'GENERATE_TEST_CREATIONS_FAILURE';

export const GENERATE_TEST_EVALUATIONS = 'GENERATE_TEST_EVALUATIONS';
export const GENERATE_TEST_EVALUATIONS_SUCCESS = 'GENERATE_TEST_EVALUATIONS_SUCCESS';
export const GENERATE_TEST_EVALUATIONS_FAILURE = 'GENERATE_TEST_EVALUATIONS_FAILURE';

export const GENERATE_TEST_FEEDBACK = 'GENERATE_TEST_FEEDBACK';
export const GENERATE_TEST_FEEDBACK_SUCCESS = 'GENERATE_TEST_FEEDBACK_SUCCESS';
export const GENERATE_TEST_FEEDBACK_FAILURE = 'GENERATE_TEST_FEEDBACK_FAILURE';

export const GET_SNAPSHOT_OF_ALL_COURSES = 'GET_SNAPSHOT_OF_ALL_COURSES';
export const GET_SNAPSHOT_OF_ALL_COURSES_FAILURE = 'GET_SNAPSHOT_OF_ALL_COURSES_FAILURE';
export const GET_SNAPSHOT_OF_ALL_COURSES_SUCCESS = 'GET_SNAPSHOT_OF_ALL_COURSES_SUCCESS';

export const GET_ACTIVITY_STATS = 'GET_ACTIVITY_STATS';
export const GET_ACTIVITY_STATS_SUCCESS = 'GET_ACTIVITY_STATS_SUCCESS';
export const GET_ACTIVITY_STATS_FAILURE = 'GET_ACTIVITY_STATS_FAILURE';

export const GET_MISSING_GRADES = 'GET_MISSING_GRADES';
export const GET_MISSING_GRADES_SUCCESS = 'GET_MISSING_GRADES_SUCCESS';
export const GET_MISSING_GRADES_FAILURE = 'GET_MISSING_GRADES_FAILURE';

export const GET_STUDENTS_BY_COURSE = 'GET_STUDENTS_BY_COURSE';
export const GET_STUDENTS_BY_COURSE_SUCCESS = 'GET_STUDENTS_BY_COURSE_SUCCESS';
export const GET_STUDENTS_BY_COURSE_FAILURE = 'GET_STUDENTS_BY_COURSE_FAILURE';

export const UPDATE_CREATION_COMMENT_REQUEST = 'UPDATE_CREATION_COMMENT_REQUEST';
export const UPDATE_CREATION_COMMENT_SUCCESS = 'UPDATE_CREATION_COMMENT_SUCCESS';
export const UPDATE_CREATION_COMMENT_FAILURE = 'UPDATE_CREATION_COMMENT_FAILURE';

export const UPDATE_ACCOUNT_INFORMATION_REQUEST = 'UPDATE_ACCOUNT_INFORMATION_REQUEST';
export const UPDATE_ACCOUNT_INFORMATION_SUCCESS = 'UPDATE_ACCOUNT_INFORMATION_SUCCESS';
export const UPDATE_ACCOUNT_INFORMATION_FAILURE = 'UPDATE_ACCOUNT_INFORMATION_FAILURE';

export const ENROLL_STUDENT_IN_COURSE_REQUEST = 'ENROLL_STUDENT_IN_COURSE_REQUEST';
export const ENROLL_STUDENT_IN_COURSE_SUCCESS = 'ENROLL_STUDENT_IN_COURSE_SUCCESS';
export const ENROLL_STUDENT_IN_COURSE_FAILURE = 'ENROLL_STUDENT_IN_COURSE_FAILURE';

export const RESET_ASYNC = 'RESET_ASYNC';

export const asyncActions = {
  call: [
    GET_STUDENT,
    STRIPE_CHARGE_USER,
    SIGNUP_USER,
    UPDATE_ASSIGNMENT_SCHEDULE,
    GROUP_GENERATE_SET,
    FORGOT_PASSWORD,
    MANUAL_LOGIN_USER,
    RESET_PASSWORD,
    CHANGE_PASSWORD,
    GET_ACTIVITY_STATS,
    GET_MISSING_GRADES,
    GET_STUDENTS_BY_COURSE,
    UPDATE_RUBRIC,
  ],
  success: [
    GET_STUDENT_SUCCESS,
    STRIPE_CHARGE_USER_SUCCESS,
    SIGNUP_USER_SUCCESS,
    UPDATE_ASSIGNMENT_SCHEDULE_SUCCESS,
    GROUP_GENERATE_SET_SUCCESS,
    FORGOT_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_SUCCESS,
    LOGIN_SUCCESS_USER,
    RESET_PASSWORD_SUCCESS,
    GET_ACTIVITY_STATS_SUCCESS,
    GET_MISSING_GRADES_SUCCESS,
    GET_STUDENTS_BY_COURSE_SUCCESS,
    UPDATE_RUBRIC_SUCCESS,
  ],
  error: [
    GET_STUDENT_FAILURE,
    STRIPE_CHARGE_USER_FAILURE,
    SIGNUP_USER_FAILURE,
    UPDATE_ASSIGNMENT_SCHEDULE_FAILURE,
    GROUP_GENERATE_SET_FAILURE,
    FORGOT_PASSWORD_FAILURE,
    CHANGE_PASSWORD_FAILURE,
    LOGIN_ERROR_USER,
    RESET_PASSWORD_FAILURE,
    GET_ACTIVITY_STATS_FAILURE,
    GET_MISSING_GRADES_FAILURE,
    GET_STUDENTS_BY_COURSE_FAILURE,
    UPDATE_RUBRIC_FAILURE,
  ],
};

export const SET_OVERALL_GRADE_PER_ACTIVITY = 'SET_OVERALL_GRADE_PER_ACTIVITY';
export const SET_CREATION_GRADE_PER_ACTIVITY = 'SET_CREATION_GRADE_PER_ACTIVITY';
export const SET_EVALUATION_GRADE_PER_ACTIVITY = 'SET_EVALUATION_GRADE_PER_ACTIVITY';
export const SET_FEEDBACK_GRADE_PER_ACTIVITY = 'SET_FEEDBACK_GRADE_PER_ACTIVITY';

// Admin Panel

export const GET_COURSE_AND_INSTITUTION_INFO_SUCCESS_FAILURE = 'GET_COURSE_AND_INSTITUTION_INFO_SUCCESS_FAILURE';

// errors
export const SET_ERRORS = 'SET_ERRORS';
export const GET_ERRORS = 'GET_ERRORS';

// late submission
export const CREATE_LATE_SUBMISSION = 'CREATE_LATE_SUBMISSION';
export const CREATE_LATE_SUBMISSION_SUCCESS = 'CREATE_LATE_SUBMISSION_SUCCESS';
export const CREATE_LATE_SUBMISSION_FAILURE = 'CREATE_LATE_SUBMISSION_FAILURE';

export const GET_COURSES_FOR_LATE_SUBMISSION = 'GET_COURSES_FOR_LATE_SUBMISSION';
export const GET_COURSES_FOR_LATE_SUBMISSION_SUCCESS = 'GET_COURSES_FOR_LATE_SUBMISSION_SUCCESS';
export const GET_COURSES_FOR_LATE_SUBMISSION_FAILURE = 'GET_COURSES_FOR_LATE_SUBMISSION_FAILURE';

export const GET_ASSIGNMENTS_FOR_LATE_SUBMISSION = 'GET_ASSIGNMENTS_FOR_LATE_SUBMISSION';
export const GET_ASSIGNMENTS_FOR_LATE_SUBMISSION_SUCCESS = 'GET_ASSIGNMENTS_FOR_LATE_SUBMISSION_SUCCESS';
export const GET_ASSIGNMENTS_FOR_LATE_SUBMISSION_FAILURE = 'GET_ASSIGNMENTS_FOR_LATE_SUBMISSION_FAILURE';

export const GET_POTENTIAL_REVIEWERS_FOR_LATE_SUBMISSION = 'GET_POTENTIAL_REVIEWERS_FOR_LATE_SUBMISSION';
export const GET_POTENTIAL_REVIEWERS_FOR_LATE_SUBMISSION_SUCCESS =
  'GET_POTENTIAL_REVIEWERS_FOR_LATE_SUBMISSION_SUCCESS';
export const GET_POTENTIAL_REVIEWERS_FOR_LATE_SUBMISSION_FAILURE =
  'GET_POTENTIAL_REVIEWERS_FOR_LATE_SUBMISSION_FAILURE';

// late evaluation
export const FIND_CREATION_FOR_MISSED_EVALUATION = 'FIND_CREATION_FOR_MISSED_EVALUATION';
export const FIND_CREATION_FOR_MISSED_EVALUATION_SUCCESS = 'FIND_CREATION_FOR_MISSED_EVALUATION_SUCCESS';
export const FIND_CREATION_FOR_MISSED_EVALUATION_FAILURE = 'FIND_CREATION_FOR_MISSED_EVALUATION_FAILURE';

export const CREATE_MISSED_EVALUATION = 'CREATE_MISSED_EVALUATION';
export const CREATE_MISSED_EVALUATION_SUCCESS = 'CREATE_MISSED_EVALUATION_SUCCESS';
export const CREATE_MISSED_EVALUATION_FAILURE = 'CREATE_MISSED_EVALUATION_FAILURE';
export const SET_IS_DIFFERENT_OFFSET = 'SET_IS_DIFFERENT_OFFSET';

// change course price
export const GET_COURSE_AND_INSTITUTION_INFO = 'GET_COURSE_AND_INSTITUTION_INFO';
export const GET_COURSE_AND_INSTITUTION_INFO_SUCCESS = 'GET_COURSE_AND_INSTITUTION_INFO_SUCCESS';
export const GET_COURSE_AND_INSTITUTION_INFO_FAILURE = 'GET_COURSE_AND_INSTITUTION_INFO_FAILURE';

export const VERIFY_INSTITUTION = 'VERIFY_INSTITUTION';
export const VERIFY_INSTITUTION_SUCCESS = 'VERIFY_INSTITUTION_SUCCESS';
export const VERIFY_INSTITUTION_FAILURE = 'VERIFY_INSTITUTION_FAILURE';

// coupons
export const SEND_COUPON_EMAIL_REQUEST = 'SEND_COUPON_EMAIL_REQUEST';
export const SEND_COUPON_EMAIL_SUCCESS = 'SEND_COUPON_EMAIL_SUCCESS';
export const SEND_COUPON_EMAIL_FAILURE = 'SEND_COUPON_EMAIL_FAILURE';

export const CREATE_SINGLE_COUPON_REQUEST = 'CREATE_SINGLE_COUPON_REQUEST';
export const CREATE_SINGLE_COUPON_SUCCESS = 'CREATE_SINGLE_COUPON_SUCCESS';
export const CREATE_SINGLE_COUPON_FAILURE = 'CREATE_SINGLE_COUPON_FAILURE';

export const CREATE_MULTIPLE_COUPONS_REQUEST = 'CREATE_MULTIPLE_COUPONS_REQUEST';
export const CREATE_MULTIPLE_COUPONS_SUCCESS = 'CREATE_MULTIPLE_COUPONS_SUCCESS';
export const CREATE_MULTIPLE_COUPONS_FAILURE = 'CREATE_MULTIPLE_COUPONS_FAILURE';

export const DELETE_COUPONS_REQUEST = 'DELETE_COUPONS_REQUEST';
export const DELETE_COUPONS_SUCCESS = 'DELETE_COUPONS_SUCCESS';
export const DELETE_COUPONS_FAILURE = 'DELETE_COUPONS_FAILURE';

export const GET_COUPON_DATA_REQUEST = 'GET_COUPON_DATA_REQUEST';
export const GET_COUPON_DATA_SUCCESS = 'GET_COUPON_DATA_SUCCESS';
export const GET_COUPON_DATA_FAILURE = 'GET_COUPON_DATA_FAILURE';

// Hubspot
export const GET_HUBSPOT_DEALS_REQUEST = 'GET_HUBSPOT_DEALS_REQUEST';
export const GET_HUBSPOT_DEALS_SUCCESS = 'GET_HUBSPOT_DEALS_SUCCESS';
export const GET_HUBSPOT_DEALS_FAILURE = 'GET_HUBSPOT_DEALS_FAILURE';

export const UPDATE_HUBSPOT_DEAL_REQUEST = 'UPDATE_HUBSPOT_DEAL_REQUEST';
export const UPDATE_HUBSPOT_DEAL_SUCCESS = 'UPDATE_HUBSPOT_DEAL_SUCCESS';
export const UPDATE_HUBSPOT_DEAL_FAILURE = 'UPDATE_HUBSPOT_DEAL_FAILURE';

export const GET_PROF_AT_RISK_REQUEST = 'GET_PROF_AT_RISK_REQUEST';
export const GET_PROF_AT_RISK_SUCCESS = 'GET_PROF_AT_RISK_SUCCESS';
export const GET_PROF_AT_RISK_FAILURE = 'GET_PROF_AT_RISK_FAILURE';
