export const COURSE_FIELD_NAMES = {
  CODE: 'code',
  SECTION: 'section',
  COURSE_NAME: 'title',
  DEPARTMENT: 'department',
  DESCRIPTION: 'description',
  START_DATE: 'startDate',
  END_DATE: 'endDate',
  FILES: 'files',
  DEFAULT_ACTIVITY_WEIGHT: 'defaultActivityWeight',
  ACCEPT_LATE_CREATIONS: 'acceptLateSubmissions',
  AUTO_ACCEPT_LATE_CREATIONS: 'autoAccept',
  ACCEPT_LATE_EVALUATIONS: 'acceptLateEvaluations',
  AUTO_ACCEPT_LATE_EVALUATIONS: 'autoAcceptLateEvaluations',
  COURSE_SETTINGS: 'courseSettings',
  HIDE_AVERAGE_GRADE: 'courseSettings.hideAverageGrade',
  MINIMUM_WORD_COUNT: 'courseSettings.minimumWordCountForEvaluations',
  DEFAULT_STARTING_SCORE: 'startingScore',
  TERM: 'term',
  ENABLE_SIMILARITY_REPORT: 'enableSimilarityReport',
};

export default {
  COURSE_FIELD_NAMES,
};
