import * as activityUtils from '@kritik/utils/activity';
import Cell from 'components/CreationList/Body/Cell';
import TableRow from 'components/CreationList/Body/Row';
import ToggleCell from 'components/CreationList/Body/ToggleCell';
import IssuesCell from 'components/CreationList/StatusCells/Issues';
import creationTableUtil from 'components/CreationList/util';
import AvatarDisplay from 'components/layout/AvatarDisplay';
import { connect } from 'react-redux';
import { selectCreationTableListBy } from 'redux/creationTable/select';
import { getAssignment } from 'selectors/activity';
import { getStudent } from 'selectors/student';
import { getNewKS } from 'utils/student';
import { withRouter } from 'utils/withRouter';
import CreationInfo from './CreationInfo';
import Name from './Name';

const mapStateToProps = (state: any) => {
  return {
    selectedSubmissionId: state.selected.submissionId,
    selectedStudentId: state.selected.studentId,
    assignment: getAssignment(state, state.selected.assignmentId),
    creationTableListBy: selectCreationTableListBy(state),
    entities: state.entities,
  };
};

function SubmissionRow({
  selectedSubmissionId,
  selectedStudentId,
  assignment,
  entities,
  index,
  style,
  data,
  creationTableListBy,
}: any) {
  const { handleSubmissionSelect, columns, toggleGroupMembers } = data;
  const item = data.rowList[index];
  if (!item) {
    return <TableRow />;
  }

  const { student, creation, group, isExpand } = item;
  const { user } = student;

  function getKritikScore() {
    return getNewKS(getStudent(student._id, entities));
  }
  const isActive = () => {
    if (!creation) {
      return false;
    }

    if (creationTableUtil.isListByGroup(creationTableListBy)) {
      const isGroupItem = creationTableUtil.isGroupItem(item, creationTableListBy);
      const url = new URL(window.location.href);
      const params = new URLSearchParams(url.search);
      const isGroupView = params.get('groupView') === 'true';
      if (isGroupView) {
        return creation._id === selectedSubmissionId && isGroupItem;
      }
      return student._id === selectedStudentId && !isGroupItem;
    }
    return creation._id === selectedSubmissionId;
  };

  const handleRowClick = () => {
    return handleSubmissionSelect(creation._id, student._id, creationTableUtil.isGroupItem(item, creationTableListBy));
  };

  const handleClickToggle = () => {
    toggleGroupMembers(index);
  };

  const nameColumn = { ...columns[0] };
  if (
    (activityUtils.isGroupAssignment(assignment) || activityUtils.isGroupPresentationActivity(assignment)) &&
    !creationTableUtil.isGroupItem(item, creationTableListBy)
  ) {
    nameColumn.isGroupMember = true;
  }

  return (
    <TableRow
      key={student._id}
      isWarning={creationTableUtil.hasCreationWarning(creation, assignment)}
      isActive={isActive()}
      disableHover={!creation}
      style={style}
      onClick={handleRowClick}
      testid={`submissions_creation-row-${index}`}
    >
      <Cell column={nameColumn}>
        <div className="creation-table__row-name">
          <AvatarDisplay
            user={user}
            kritikScore={getKritikScore()}
            size="45px"
            group={creationTableUtil.isGroupItem(item, creationTableListBy) ? creation.group : null}
            hoverable={false}
          />
          <div className="info-container">
            <Name user={user} group={group} />
            <CreationInfo item={item} />
          </div>
        </div>
      </Cell>
      <Cell column={columns[1]}>
        <IssuesCell
          creation={creation}
          studentId={student._id}
          isGroupItem={creationTableUtil.isGroupItem(item, creationTableListBy)}
        />
      </Cell>
      {creationTableUtil.isGroupItem(item, creationTableListBy) && (
        <ToggleCell column={columns[2]} onClick={handleClickToggle} isExpand={isExpand} />
      )}
    </TableRow>
  );
}

export default withRouter(connect(mapStateToProps)(SubmissionRow));
