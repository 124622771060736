import { SignupContainer, SignupContent, SignupMainTitle, SignupText } from 'components/AuthWrapper';
import { TranslatedText } from 'components/TranslatedText';

function NoUserAccount() {
  return (
    <div className="no-account__container">
      <SignupContainer graphic="invite">
        <SignupContent>
          <SignupMainTitle>
            <TranslatedText i18nKey="NoAccount.Title" />
          </SignupMainTitle>
          <SignupText>
            <TranslatedText i18nKey="NoAccount.InstructorContact" />
          </SignupText>
          <SignupText>
            <TranslatedText i18nKey="NoAccount.SupportContact" />
          </SignupText>
        </SignupContent>
      </SignupContainer>
    </div>
  );
}

export default NoUserAccount;
