import DeleteUserContainer from 'containers/Admin/DeleteUser';
import Page from 'pages/Page';

function PromoteAdmin() {
  return (
    <Page title="Delete User" meta={[]} link={[]} script={[]}>
      <DeleteUserContainer />
    </Page>
  );
}

export default PromoteAdmin;
