import InlineInformation from 'components/layout/InlineInformation';

const ErrorItem = ({ message }: any) => {
  return (
    <div className="activity-edit__error-item">
      <InlineInformation type="danger">{message}</InlineInformation>
    </div>
  );
};

export default ErrorItem;
