import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEn from './en/en.json';
import translationFr from './fr/fr.json';
export { translationEn };

// eslint-disable-next-line @typescript-eslint/no-floating-promises
i18next.use(LanguageDetector).init({
  supportedLngs: ['en', 'fr'],
  fallbackLng: 'en',
  resources: {
    en: {
      translation: translationEn,
    },
    fr: {
      translation: translationFr,
    },
  },
});

export const i18nInstance = i18next;

type TranslateParams = {
  message: I18nKey;
  options?: any;
};
export function localize({ message, options }: TranslateParams) {
  const result: string = i18next.t(message, options);
  return result;
}

// 1. Includes all keys from en.json file
// 2. Handles plural forms by extracting base keys without '_one' or '_other' suffix
// Example: 'CourseCard.StudentsEnrolled' will work for both 'CourseCard.StudentsEnrolled_one' and 'CourseCard.StudentsEnrolled_other'
export type I18nKey =
  | keyof typeof translationEn
  | {
      [K in keyof typeof translationEn]: K extends `${infer Base}_one` | `${infer Base}_other` ? Base : K;
    }[keyof typeof translationEn];
