import { makeStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles({
  pinkColor: {
    color: '#C528B2',
  },
});

function LoadingCard() {
  const classes = useStyles();

  return (
    <div className="loading-card">
      <CircularProgress
        size={70}
        color="secondary"
        classes={{
          colorSecondary: classes.pinkColor,
        }}
      />
    </div>
  );
}

export default LoadingCard;
