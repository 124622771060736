import { schema } from 'normalizr';

import assignmentStatus from 'schemas/assignment_status';
import creation from 'schemas/creation';
import user from 'schemas/user';

const assignment = new schema.Entity(
  'assignments',
  {
    user,
    statuses: [assignmentStatus],
    creations: [creation],
  },
  { idAttribute: '_id' }
);

export default assignment;
