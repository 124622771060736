import { Activity, Creation } from '@kritik/types.generated';
import { isDraft } from '@kritik/utils/stage';
import MaterialCollapse from 'components/layout/MaterialCollapse';
import { useUserRoleInCourse } from 'hooks/course';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { selectCurrentActivity } from 'selectors/activity';
import { getMySubmission } from 'selectors/creation';
import { selectAuthUser } from 'selectors/user';

const mapStateToProps = (state: any) => {
  return {
    user: selectAuthUser(state),
    assignment: selectCurrentActivity(state),
    mySubmission: getMySubmission(state, state.selected.assignmentId),
  };
};

type AssignmentCollapsibleAreaProps = {
  isCollapsible: boolean;
  assignment: Activity;
  children: React.ReactNode;
  title: string;
  testid?: string;
  mySubmission: Creation | null;
};

const AssignmentCollapsibleArea = ({
  isCollapsible = true,
  assignment,
  children,
  title,
  testid,
  mySubmission,
}: AssignmentCollapsibleAreaProps) => {
  const { isStudentInCourse } = useUserRoleInCourse();
  const [isCreationSubmitted, setIsCreationSubmitted] = useState(false);
  const activityInDraft = isDraft({ assignment }) && !isStudentInCourse;

  useEffect(() => {
    if (mySubmission && isStudentInCourse && mySubmission.submissionStatus === 'EMPTY') {
      setIsCreationSubmitted(true);
    } else {
      setIsCreationSubmitted(false);
    }
  }, [mySubmission, isStudentInCourse]);

  if (!isCollapsible) {
    return <>{children}</>;
  }

  return (
    <MaterialCollapse
      elementKey={(activityInDraft || isCreationSubmitted) === true ? 'open' : 'closed'}
      title={title}
      isOpen={activityInDraft || isCreationSubmitted}
      testid={testid}
    >
      {children}
    </MaterialCollapse>
  );
};

export default connect(mapStateToProps)(AssignmentCollapsibleArea);
