import { assignmentStatuses } from '@kritik/constants/stage';
import { simplePercent } from '@kritik/utils/format';
import Tooltip from '@material-ui/core/Tooltip';
import { getProgress } from 'components/AssignmentLabels/utils';
import { TranslatedText } from 'components/TranslatedText';
import { useUserRoleInCourse } from 'hooks/course';
import { localize } from 'locales';
import { connect } from 'react-redux';
import { selectActivityStatsById } from 'selectors/activity';

function ProgressText({ progress, statusName }: any) {
  const namesMap = {
    Create: localize({ message: 'ProgressTooltip.Creations' }),
    Evaluate: localize({ message: 'ProgressTooltip.Evaluations' }),
    Feedback: localize({ message: 'ProgressTooltip.Feedbacks' }),
  };
  return (
    <div className="progress-text">
      <div className="progress-text__title">
        <TranslatedText i18nKey="ProgressTooltip.StageTitle" values={{ statusName }} />
      </div>
      <div className="progress-text__content">
        <TranslatedText
          i18nKey="ProgressTooltip.ProgressContent"
          values={{ progress: simplePercent(progress), statusName: namesMap[statusName] }}
        />
      </div>
    </div>
  );
}

function ProgressTooltip({ activity, stats, status, children }: any) {
  const { isStudentInCourse } = useUserRoleInCourse();
  if (!stats || status.name === assignmentStatuses.PROCESSING3 || isStudentInCourse) {
    return children;
  }
  const progress = getProgress({
    activity,
    activityStats: stats,
    statusName: status.name,
  });

  return <Tooltip title={<ProgressText progress={progress} statusName={status.name} />}>{children}</Tooltip>;
}

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    stats: selectActivityStatsById(state, ownProps.activity._id),
  };
};

export default connect(mapStateToProps)(ProgressTooltip);
