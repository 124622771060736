import StarRubricMarks from 'components/General/StarRubricMarks';
import { STAR_RUBRIC_MARKS } from 'components/General/constant';

const AverageScore = (props: any) => {
  if (!props.activity) {
    return null;
  }

  return (
    <StarRubricMarks
      rubric={props.activity.rubric}
      marks={props.activity.averageGrade}
      type="secondary"
      tableType={STAR_RUBRIC_MARKS.DEFAULT}
    />
  );
};

export default AverageScore;
