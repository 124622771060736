import { localize } from 'locales';
import * as _ from 'lodash-es';
import moment from 'moment';
import momentTZ from 'moment-timezone';

const CURRENCY_SYMBOLS = {
  cad: '$',
  usd: '$',
  eur: '€',
  sgd: '$',
  gbp: '£',
  aud: '$',
};

export const displayPrice = (value: number) => {
  const formatted = value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  return `${formatted}`;
};

export const displayCurrencyPrice = (value: number, currency: string) => {
  return `${currency.toUpperCase()} ${CURRENCY_SYMBOLS[currency]} ${displayPrice(value)}`;
};

export const displayPercent = (value: number | string) => {
  return `${value}%`;
};

export const simplePercent = (value: number, decimals = 0) => {
  return displayPercent(value.toFixed(decimals));
};

export const simplePoint = (points: string | number, decimals?: number) => {
  const pointsNumber = Number(points);
  return `${_.round(pointsNumber, decimals)} ${localize({ message: 'Activity.Weight.Points', options: { count: pointsNumber } })}`;
};

export const getInitials = (string: string) => {
  const names = string.split(' ');
  let initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};

export const timeUntilStart = (date: moment.MomentInput) => {
  const toStart = momentTZ(date).fromNow();
  const startT = momentTZ(date);
  const now = momentTZ();

  const newDate = momentTZ.duration(now.diff(startT));

  if (newDate.asSeconds() < 0.5) {
    return `Starts ${toStart}`;
  }

  return `Started ${toStart}`;
};

export const timeUntilDue = (date: moment.MomentInput) => {
  const toDue = momentTZ(date).fromNow();
  return `Due ${toDue}`;
};

export const timeUntilDate = (date: moment.MomentInput) => {
  const hoursToDate = momentTZ(date).fromNow().replace('in', '');
  return hoursToDate;
};
// This is only for late submission
export const subTimeFromCreationDue = (creationDueDate: moment.MomentInput, submissionDate: moment.MomentInput) => {
  const timeFromDue = momentTZ(submissionDate).from(creationDueDate).replace('in', '');
  return timeFromDue;
};

export const timeDisplayFromMinutes = (totalMinutes: number) => {
  if (totalMinutes === 0) {
    return '0 minutes';
  }
  const hours = totalMinutes / 60;
  const hoursRound = Math.floor(hours);
  if (hours > 48) {
    return `${Math.round(hoursRound / 24)} Days`;
  }
  let minutes = (hours - hoursRound) * 60;
  minutes = Math.round(minutes);
  let toReturn = '';
  if (hoursRound > 0) {
    toReturn += `${hoursRound} hour(s)`;
  }
  if (hoursRound > 0 && minutes > 0) {
    toReturn += ' and ';
  }
  if (minutes > 0) {
    toReturn += `${minutes} minute(s)`;
  }
  return toReturn;
};

export const getDaysBetweenDates = (dt2: Date, dt1: Date) => {
  let diff = getMinutesBetweenDates(dt1, dt2);
  diff /= 60 * 24;
  return Math.abs(Math.round(diff));
};

export const getMinutesBetweenDates = (dt2: Date, dt1: Date) => {
  let diff = (dt2.getTime() - dt1.getTime()) / 1000;
  diff /= 60;
  return Math.abs(Math.round(diff));
};

export const getSecondsBetweenDates = (dt2: Date, dt1: Date) => {
  const diff = (dt2.getTime() - dt1.getTime()) / 1000;
  return Math.abs(Math.round(diff));
};

export const readableTimeFormat = (date: moment.MomentInput) => {
  return moment(date).format('h:mm A');
};

/*
  react-datepicker only allows date objects as selected param, and does not handle timezone
  It's a non-resolved issue  https://github.com/Hacker0x01/react-datepicker/issues/1787

  Example:
  If the browser is in Europe/Paris timezone and institution is in America/Montreal timezone
  when "2022-02-09 04:32:00.000Z" is sent from server
  react-datepicker receives "Wed Feb 09 2022 05:32:00 GMT+0100"
  and displays Feb 9 where it should display Feb 8 in institution time

  If browser and institutions dates are showing different days,
  setLocalZone allows to manipulate the date by substracting the difference in hours between the two timezones.
  So the date becomes "Tue Feb 08 2022 23:32:00 GMT+0100"
  This is not the right date per se, but at least we display the right day.
  Then, since moment is set by default on the institution timezone in the scheduling page (in the getSchedulingTemplate function),
  when going through combineDateAndTime, the wrong date sent by react-datepicker is formatted back in the current institution timezone
*/

export const setLocalZone = (date: moment.MomentInput, institutionTimeZone: string) => {
  if (!date) {
    return null;
  }
  const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const browserTime = momentTZ(date).tz(browserTimeZone);
  const institutionTime = momentTZ(date).tz(institutionTimeZone);
  const hoursDifference = browserTime.utcOffset() - institutionTime.utcOffset();
  const isSameDay = browserTime.format('DD') === institutionTime.format('DD');
  if (hoursDifference === 0 || isSameDay) {
    return date;
  }
  const modifiedDate = momentTZ(date).subtract(hoursDifference / 60, 'hours');
  return modifiedDate.toDate();
};

export const exactDate = (date: moment.MomentInput, locale?: string) => {
  return locale ? momentTZ(date).tz(locale).format('MMM Do [@] h:mm A') : momentTZ(date).format('MMM Do [@] h:mm A');
};

export const getDayOfTheWeek = (date: moment.MomentInput, locale: string) => {
  return locale ? momentTZ(date).tz(locale).format('ddd') : momentTZ(date).format('ddd');
};

export const validateSheetName = (name: string) => {
  if (name.length >= 31) {
    name = `${name.slice(0, 25)}...`;
  }
  const validName = [];
  const invalidChars = {
    '/': true,
    '\\': true,
    '?': true,
    '*': true,
    '[': true,
    ']': true,
    ':': true,
  };

  for (let i = 0; i < name.length; i++) {
    const char = name[i];
    if (!invalidChars[char]) {
      validName.push(char);
    }
  }

  return validName.join('');
};

export const getPlainTextFromHTML = (input: string) => {
  let text = input.replace(/<(\/)*(h1|h2|h3|p|li|td)>/g, ' '); // replace styled tags with space
  text = text.replace(/<(?:.|\s)*?>/g, ''); // removing html tags
  text = text.replace(/\&nbsp\;/g, ' '); // converting &nbsp; to one symbol
  text = text.replace(/\&[\w\#]+;/g, '&'); // converting special characters to one symbol
  text = text.replace(/\s+/g, ' '); // converting several spaces to one
  text = text.replace(/^\s+/g, ''); // removing start spaces
  text = text.replace(/\s+$/g, ''); // removing end spaces
  return text;
};

export const shortenTextWithElipsis = (string: string, maxLength = 30) => {
  if (string.length < maxLength + 3) {
    return string;
  }
  let _string = string.substring(0, maxLength);
  _string += '...';
  return _string;
};

export const stripHtmlTags = (string: string) => {
  if (!string) {
    return '';
  }

  return string.replace(/(<([^>]+)>)/gi, '').replace(/&nbsp;/g, ' ');
};

export const timeBetweenDates = ({
  startDate,
  endDate,
}: {
  startDate: moment.MomentInput;
  endDate: moment.MomentInput;
}) => {
  const start = momentTZ(startDate);
  const end = momentTZ(endDate);
  const duration = momentTZ.duration(end.diff(start));
  const minutes = parseInt(duration.asMinutes().toString(), 10);
  const hours = parseInt(duration.asHours().toString(), 10);
  const days = parseInt(duration.asDays().toString(), 10);
  const result: { unit: string; diffValue: number } = {
    diffValue: 0,
    unit: '',
  };

  if (!minutes) {
    result.diffValue = 0;
    result.unit = 'minutes';
  } else if (minutes < 60) {
    result.diffValue = minutes;
    result.unit = 'minutes';
  } else if (hours < 24) {
    result.diffValue = hours;
    result.unit = 'hours';
  } else {
    result.diffValue = days;
    result.unit = 'days';
  }

  return result;
};

export const displayTimeBetweenDates = (startDate: moment.MomentInput, endDate: moment.MomentInput) => {
  const result = timeBetweenDates({ startDate, endDate });

  if (result.unit === 'minutes') {
    return result.diffValue === 0 ? '<1 minute' : `${result.diffValue} minute${result.diffValue !== 1 ? 's' : ''}`;
  }

  if (result.unit === 'hours') {
    return `${result.diffValue} hour${result.diffValue === 1 ? '' : 's'}`;
  }
  return `${result.diffValue} day${result.diffValue === 1 ? '' : 's'}`;
};

export const getProfEmailFromDemoEmail = (studentEmail: string) => {
  if (!studentEmail) {
    return null;
  }
  const address = studentEmail.split('+');
  const domain = studentEmail.split('@');
  if (address.length < 2 || domain.length < 2) {
    return null;
  }
  return address[0] + '@' + domain[domain.length - 1];
};

export const min0Max100 = (n: string | number) => {
  const theNumber = typeof n === 'string' ? parseFloat(n) : n;
  if (isNaN(theNumber)) {
    return n;
  }
  return Math.min(Math.max(theNumber, 0), 100);
};
