import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { connect } from 'react-redux';

function LoadingModal({ open, content, title }: any) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <div>
      <Dialog fullScreen={fullScreen} open={open}>
        <DialogTitle className="loading-modal__title">{title}</DialogTitle>
        <DialogContent>
          <div className="loading-modal__content-wrapper">
            <span className="loading-modal__content-wrapper--content">{content}</span>
            <LinearProgress className="loading-modal__content-wrapper--loading-bar" />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  const { loadingModal } = state.modal;
  return {
    ...loadingModal,
  };
};

export default connect(mapStateToProps)(LoadingModal);
