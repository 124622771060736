import { IS_MULTIPLE_TOPICS } from 'components/ActivityEdit/AdvancedOptions/Fields/Topics/constants';
import validators from 'components/ActivityEdit/Details/Fields/validators';
import { TranslatedText } from 'components/TranslatedText';
import FormRichTextEditor from 'components/core/form/RichTextEditor';
import InlineInformation from 'components/layout/InlineInformation';
import React from 'react';
import { Field, useFormState } from 'react-final-form';
import { FIELD_LABEL, FIELD_NAME } from './constants';

export const LABEL = {
  value: FIELD_NAME,
  label: FIELD_LABEL,
};

const InstructionField = () => {
  const state = useFormState();
  const renderMultiTopicWarning = () => {
    if (state.values[IS_MULTIPLE_TOPICS]) {
      return (
        <InlineInformation>
          <TranslatedText i18nKey="Multitopic.Instruction" />
        </InlineInformation>
      );
    }
    return null;
  };
  return (
    <Field required name={LABEL.value} validate={validators.doesNotContainImageAndisRequired('Instructions')}>
      {({ input }) => {
        return (
          <React.Fragment>
            <FormRichTextEditor
              testid="activity-instructions-editor"
              key="activity_edit_instructions"
              label={LABEL.label}
              {...input}
            />
            {renderMultiTopicWarning()}
          </React.Fragment>
        );
      }}
    </Field>
  );
};
export default InstructionField;
