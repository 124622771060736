import { isDisputeResolved } from '@kritik/utils/creation/status';
import * as FormatUtils from '@kritik/utils/format';
import { DisputeDescription, DisputeHeader, DisputeSection } from 'components/Creation/Disputing/Layout';
import HtmlContent from 'components/General/HtmlContent';
import { TranslatedText } from 'components/TranslatedText';
import { Button } from 'components/buttons';
import NoticeBoard from 'components/layout/NoticeBoard';
import { localize } from 'locales';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { getUser } from 'selectors/user';
import {
  getDisputeInstructorComment,
  getDisputeReason,
  getDisputedDate,
  getResolvedBy,
  getResolvedDate,
} from 'utils/creation/dispute';

const ResolvedDisputeView = (props: any) => {
  if (!isDisputeResolved(props.creation)) {
    return null;
  }

  const [showDetails, setShowDetails] = useState(false);

  const renderDates = () => {
    let disputedDate = getDisputedDate(props.creation);
    disputedDate = FormatUtils.exactDate(disputedDate);
    let resolvedDate = getResolvedDate(props.creation);
    resolvedDate = FormatUtils.exactDate(resolvedDate);
    return (
      <React.Fragment>
        <DisputeDescription>
          <TranslatedText i18nKey="Creation.Dispute.Resolved.DisputedDate" values={{ date: disputedDate }} />
        </DisputeDescription>
        <DisputeDescription>
          <TranslatedText
            i18nKey="Creation.Dispute.Resolved.ResolvedDate"
            values={{ user: props.resolvedBy, date: resolvedDate }}
          />
        </DisputeDescription>
      </React.Fragment>
    );
  };

  const renderDetails = () => {
    if (!showDetails) {
      return null;
    }
    return (
      <React.Fragment>
        <DisputeSection>
          <DisputeHeader label={localize({ message: 'Creation.Dispute.Resolved.StudentReasonHeader' })} />
          <DisputeDescription>
            <HtmlContent content={getDisputeReason(props.creation)} />
          </DisputeDescription>
        </DisputeSection>
        <DisputeSection>
          <DisputeHeader label={localize({ message: 'Creation.Dispute.Resolved.ResolutionMessageHeader' })} />
          <DisputeDescription>
            <HtmlContent content={getDisputeInstructorComment(props.creation)} />
          </DisputeDescription>
        </DisputeSection>
      </React.Fragment>
    );
  };

  const toggleShowDetails = () => {
    setShowDetails(!showDetails);
  };

  const renderButton = () => {
    let label = localize({ message: 'Creation.Dispute.Resolved.ShowDetails' });
    if (showDetails) {
      label = localize({ message: 'Creation.Dispute.Resolved.HideDetails' });
    }
    return (
      <DisputeSection>
        <Button type="secondary" onClick={toggleShowDetails} testid="toggle-dispute-details">
          {label}
        </Button>
      </DisputeSection>
    );
  };

  return (
    <NoticeBoard title={localize({ message: 'Creation.Dispute.Resolved.Title' })} type="success">
      {renderDates()}
      {renderDetails()}
      {renderButton()}
    </NoticeBoard>
  );
};

const mapStateToProps = (state: any, ownProps: any) => {
  const resolvedByUserId = getResolvedBy(ownProps.creation);
  let resolvedBy = getUser(resolvedByUserId, state.entities);
  resolvedBy = resolvedBy?.profile?.name || localize({ message: 'Creation.Dispute.Resolved.DefaultResolvedBy' });
  return {
    resolvedBy,
  };
};

export default connect(mapStateToProps, {})(ResolvedDisputeView);
