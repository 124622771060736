import * as InstructorCommentUtil from '@kritik/utils/creation/instructorComment';
import CommentEdit from 'components/CommentOnContent/Edit';
import CommentView from 'components/CommentOnContent/View';
import MarginVertical from 'components/Spotlight/View/MarginVertical';
import React, { useState } from 'react';

const EvalInstructorComment = (props: any) => {
  if (!props.resource) {
    return null;
  }
  const [isEditingComment, setIsEditingComment] = useState(false);
  const { teacherComment } = props.resource;
  function handleClose() {
    setIsEditingComment(false);
    props.onClose?.();
  }
  if (
    props.isInstructor &&
    ((!InstructorCommentUtil.resourceHasTeacherComment(props.resource) && !props.isViewOnly) || isEditingComment)
  ) {
    return (
      <React.Fragment>
        <MarginVertical size="sm" />
        <CommentEdit
          creationUserId={props.creationUserId}
          activityId={props.activityId}
          instructorComment={teacherComment}
          onClose={handleClose}
          creation={props.creation}
          onSubmit={props.onSubmit}
          contentOwner={props.contentOwner}
          isCreationComment={props.isCreationComment}
        />
        <MarginVertical size="sm" />
      </React.Fragment>
    );
  }

  if (InstructorCommentUtil.resourceHasTeacherComment(props.resource)) {
    return (
      <CommentView
        instructorComment={teacherComment}
        isInstructor={props.isInstructor}
        onEdit={() => {
          setIsEditingComment(true);
        }}
      />
    );
  }
  return null;
};

EvalInstructorComment.defaultProps = {
  isInstructor: false,
  isViewOnly: false,
  instructorComment: null,
  contentOwner: null,
  isCreationComment: false,
};

export default EvalInstructorComment;
