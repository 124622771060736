import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { connect } from 'react-redux';

const mapStateToProps = (state: any) => {
  return {};
};

const StyledExpansionPanel = withStyles({
  root: {
    border: 'none',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const StyledExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: '#f9f6f2',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

const StyledExpansionPanelDetails = withStyles((theme) => {
  return {
    root: {
      padding: theme.spacing(3),
      backgroundColor: '#f9f6f2',
      display: 'block',
    },
  };
})(MuiExpansionPanelDetails);

type ExpansionPanelState = any;

class ExpansionPanel extends React.Component<{}, ExpansionPanelState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      expanded: new Array((this.props as any).panels.length).fill(false),
    };
  }

  componentDidMount() {
    const { expanded } = this.state;
    (this.props as any).panels.forEach((panel: any, i: any) => {
      if (panel.expanded) {
        expanded[i] = true;
      }
    });
    this.setState({ expanded });
  }

  handleChange(index: any) {
    const { expanded } = this.state;
    expanded[index] = !expanded[index];
    this.setState({ expanded });
  }

  render() {
    return (
      <div>
        {(this.props as any).panels.map((panel: any, i: any) => {
          return (
            <StyledExpansionPanel
              key={panel.title}
              square
              expanded={this.state.expanded[i]}
              onChange={() => {
                return this.handleChange(i);
              }}
            >
              <StyledExpansionPanelSummary
                expandIcon={
                  this.state.expanded[i] ? (
                    <i className="fas fa-chevron-down" />
                  ) : (
                    <i className="fas fa-chevron-right" />
                  )
                }
              >
                <h2 className="new-header-2">{panel.title}</h2>
              </StyledExpansionPanelSummary>
              <StyledExpansionPanelDetails>{panel.content}</StyledExpansionPanelDetails>
            </StyledExpansionPanel>
          );
        })}
      </div>
    );
  }
}

export default connect(mapStateToProps, {})(ExpansionPanel);
