import { TranslatedText } from 'components/TranslatedText';
import Button from 'components/buttons/Button';
import InfoLabel from 'components/layout/InfoLabel';

import { Course } from '@kritik/types.generated';
import { lmsDisconnectCourseMutation } from 'hooks/lms';

type ConnectedCourseProps = {
  course: Course;
};

export function ConnectedCourse({ course }: ConnectedCourseProps) {
  const disconnectCourse = lmsDisconnectCourseMutation(course._id);

  return (
    <div className="connected-course">
      <p>
        <TranslatedText
          i18nKey="ConnectedCourse.CourseConnectedTo"
          values={{ courseName: course.lms.connectedCourseName }}
        />
      </p>
      <div style={{ display: 'flex' }}>
        <InfoLabel
          type="success"
          label={
            <>
              <i className="fas fa-check" style={{ paddingRight: 10 }} /> {course.lms.connectedCourseName}
            </>
          }
        />
        <Button
          type="secondary"
          className="course-integrations-button__disconnect"
          onClick={() => {
            return disconnectCourse.mutate(course._id);
          }}
          disabled={disconnectCourse.isLoading}
          loading={disconnectCourse.isLoading}
          data-testid="disconnect-lms-button"
        >
          <TranslatedText i18nKey="ConnectedCourse.DisconnectLMS" />
        </Button>
      </div>
    </div>
  );
}
