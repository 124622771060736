import Access from 'components/Access';
import InstructorActivityList from 'components/ActivityList/Instructor';
import StudentActivityList from 'components/ActivityList/Student';
import { I18nKey } from 'locales/index';

type ActivityListProps = {
  activityList: any[];
  renderHeader?: boolean;
  isFinalized?: boolean;
  tableCaptionI18nKey: I18nKey;
};

const ActivityList = (props: ActivityListProps) => {
  return (
    <Access
      instructor={
        <InstructorActivityList
          activityList={props.activityList}
          renderHeader={props.renderHeader}
          isFinalized={props.isFinalized}
          tableCaptionI18nKey={props.tableCaptionI18nKey}
        />
      }
      student={
        <StudentActivityList
          activityList={props.activityList}
          renderHeader={props.renderHeader}
          tableCaptionI18nKey={props.tableCaptionI18nKey}
        />
      }
    />
  );
};

ActivityList.defaultProps = {
  renderHeader: true,
  isFinalized: false,
};

export default ActivityList;
