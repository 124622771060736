import { isRevealingEvaluators } from '@kritik/utils/course';
import Reference from 'components/Spotlight/Reference';
import SpotlightView from 'components/Spotlight/View';
import MarginVertical from 'components/Spotlight/View/MarginVertical';
import { TranslatedText } from 'components/TranslatedText';
import { Button, ButtonContainer } from 'components/buttons';
import FormRichTextEditor from 'components/core/form/RichTextEditor';
import InlineInformation from 'components/layout/InlineInformation';
import NoticeBoard from 'components/layout/NoticeBoard';
import { useGetSpotlightTags, usePublishSpotlight } from 'hooks/spotlights';
import { localize } from 'locales';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  UPDATE_SPOTLIGHT,
  cancelSpotlightCreation,
  navigateToSpotlight,
  selectIsPreview,
  selectSpotlightAsyncActions,
  setIsPreview,
  updateSpotlight,
} from 'redux/spotlight';
import { selectCurrentActivity } from 'selectors/activity';
import { getCourse } from 'selectors/course';
import { Spotlight } from 'utils/spotlight';
import { SpotlightTag } from 'utils/spotlightTag';
import { withRouter } from 'utils/withRouter';

const initSpotlight = (activityId: any, courseId: any) => {
  const params = new URLSearchParams(document.location.search);
  const type = params.get('type');
  const referenceId = params.get('referenceId');

  const newSpotlight = {
    activity: activityId,
    course: courseId,
    type,
    reference: referenceId,
    tag: '',
    comment: '',
  };
  return newSpotlight;
};

function useSpotlight({ spotlight, activityId, courseId }: any) {
  const [_spotlight, setSpotlight] = useState(spotlight || initSpotlight(activityId, courseId));

  return [Spotlight(_spotlight), setSpotlight];
}

function SpotlightEdit(props: any) {
  const { activity, isPreview } = props;

  const [spotlight, setSpotlight] = useSpotlight({
    spotlight: props.spotlight,
    activityId: activity.getId(),
    courseId: activity.getCourseId(),
  });

  const publishSpotlight = usePublishSpotlight();

  const handleTagChange = (tagId: any) => {
    setSpotlight({
      ...spotlight,
      tag: tagId,
    });
  };

  const handleCommentChange = (value: any) => {
    setSpotlight({
      ...spotlight,
      comment: value,
    });
  };
  const { data: spotlightTags } = useGetSpotlightTags({ type: spotlight.getType() });
  const selectedTagId = spotlight.getTagId();
  const spotlightTag = selectedTagId
    ? spotlightTags?.find((t) => t._id === selectedTagId)
    : spotlightTags?.find((t) => t.name === 'Spotlight');

  useEffect(() => {
    // if tag is not set, set it to the default tag
    if (spotlightTag?._id && !spotlight.getTagId()) {
      handleTagChange(spotlightTag?._id);
    }
  }, [spotlightTag?._id]);
  const renderButtons = () => {
    if (spotlight.getId()) {
      // Edit
      const handleClickSave = () => {
        props.updateSpotlight(spotlight.serialize());
      };
      const handleClickCancel = () => {
        props.navigateToSpotlight({
          courseId: activity.getCourseId(),
          activityId: activity.getId(),
          spotlightId: spotlight.getId(),
        });
      };

      const handleClickPublish = () => {
        publishSpotlight.mutate(spotlight.getId(), {
          onSuccess: () => props.router.push(`/course/${activity.getCourseId()}/assignment/${activity.getId()}`),
        });
      };
      return (
        <ButtonContainer>
          <Button
            type="primary"
            onClick={handleClickSave}
            loading={props.spotlightAsyncActions[UPDATE_SPOTLIGHT].busy}
            disabled={props.spotlightAsyncActions[UPDATE_SPOTLIGHT].busy}
          >
            {spotlight.status === 'draft' ? <TranslatedText i18nKey="SaveDraft" /> : <TranslatedText i18nKey="Save" />}
          </Button>
          {spotlight.status === 'draft' && (
            <Button
              type="primary"
              onClick={handleClickPublish}
              loading={props.spotlightAsyncActions[UPDATE_SPOTLIGHT].busy}
              disabled={props.spotlightAsyncActions[UPDATE_SPOTLIGHT].busy}
            >
              <TranslatedText i18nKey="Spotlights.ShareSpotlight" />
            </Button>
          )}
          <Button type="secondary" onClick={handleClickCancel}>
            <TranslatedText i18nKey="Cancel" />
          </Button>
        </ButtonContainer>
      );
    }

    // Create
    const handleClickPreview = () => {
      props.setIsPreview(true);
    };
    const handleClickCancel = () => {
      props.cancelSpotlightCreation(spotlight);
    };

    return (
      <ButtonContainer>
        <Button
          type="primary"
          onClick={handleClickPreview}
          disabled={!selectedTagId}
          unavailable={!selectedTagId}
          data-testid="preview-spotlight"
        >
          <TranslatedText i18nKey="Preview" />
        </Button>
        <Button type="secondary" onClick={handleClickCancel} data-testid="cancel-spotlight">
          <TranslatedText i18nKey="Cancel" />
        </Button>
      </ButtonContainer>
    );
  };

  const renderDisclaimer = () => {
    const label = spotlight.isCreationType()
      ? localize({ message: 'Spotlight.Edit.CreationAuthor' })
      : localize({ message: 'Spotlight.Edit.Authors' });
    return (
      <InlineInformation>
        <TranslatedText i18nKey="Spotlight.Edit.Disclaimer" values={{ label }} />
      </InlineInformation>
    );
  };

  const renderContent = () => {
    if (isPreview && spotlightTag) {
      return <SpotlightView spotlight={spotlight} tag={SpotlightTag(spotlightTag)} />;
    }
    return (
      <React.Fragment>
        <MarginVertical size="sm" />
        <MarginVertical />
        {selectedTagId && (
          <FormRichTextEditor
            label={localize({ message: 'Spotlight.Edit.ShareRemarks' })}
            info={
              <TranslatedText i18nKey="Spotlights.Create.Info" values={{ type: spotlight.getType().toLowerCase() }} />
            }
            value={spotlight.getComment()}
            onChange={handleCommentChange}
            testid="creation-spotlight-editor"
          />
        )}
        <MarginVertical size="sm" />
        <Reference spotlight={spotlight} />
        <MarginVertical size="default" />
        {renderButtons()}
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      {spotlight.status === 'published' && !isRevealingEvaluators(props.course) && renderDisclaimer()}
      {spotlight.status === 'draft' && (
        <NoticeBoard>
          <ul style={{ margin: '0px' }}>
            <li>
              <TranslatedText i18nKey="Spotlights.Warning.Draft" />
            </li>
            <li>
              <TranslatedText i18nKey="Spotlights.Warning.AnonymousContent" />
            </li>
          </ul>
        </NoticeBoard>
      )}
      <MarginVertical size="sm" />
      {renderContent()}
    </React.Fragment>
  );
}

const mapStateToProps = (state: any) => {
  return {
    activity: selectCurrentActivity(state),
    course: getCourse(state),
    isPreview: selectIsPreview(state),
    spotlightAsyncActions: selectSpotlightAsyncActions(state),
  };
};

export default withRouter(
  connect(mapStateToProps, {
    cancelSpotlightCreation,
    getCourse,
    setIsPreview,
    updateSpotlight,
    navigateToSpotlight,
  })(SpotlightEdit)
);
