import ElipsesMenu from 'components/General/ElipsesMenu';
import { TranslatedText } from 'components/TranslatedText';
import Confirm from 'components/modals/ConfirmModal';
import { handleRemoveCollaborator, useUserRoleInCourse } from 'hooks/course';
import { localize } from 'locales';
import React from 'react';
import { useParams } from 'react-router';

function RemoveCollaboratorMenu(props: any) {
  const { courseId } = useParams();
  const { isInstructorInCourse } = useUserRoleInCourse();
  const [isConfirming, setIsConfirming] = React.useState(false);
  const handleRemoveCollaboratorMutation = handleRemoveCollaborator(courseId);

  if (!isInstructorInCourse) {
    return null;
  }

  const handleRemoveConfirm = () => {
    setIsConfirming(true);
  };

  const OPTIONS = [
    {
      label: localize({ message: 'RemoveCollaboratorMenu.Remove' }),
      action: handleRemoveConfirm,
    },
  ];

  return (
    <div>
      <ElipsesMenu
        options={OPTIONS}
        testid={`remove-${props.collaboratorEmail}`}
        labelI18nKey="RosterManager.CollaboratorsTab.MoreOptionsMenu.Label"
      />
      <Confirm
        testid={`modal-remove-${props.collaboratorEmail}`}
        isOpen={isConfirming}
        onCancel={() => {
          setIsConfirming(false);
        }}
        onConfirm={() => {
          setIsConfirming(false);
          handleRemoveCollaboratorMutation.mutate({
            courseId,
            collaboratorEmail: props.collaboratorEmail,
          });
        }}
        cancelButton={localize({ message: 'RemoveCollaboratorMenu.No' })}
        confirmButton={localize({ message: 'RemoveCollaboratorMenu.Yes' })}
        title={<TranslatedText i18nKey="RemoveCollaboratorMenu.RemoveCollaboratorQuestion" />}
      />
    </div>
  );
}

export default RemoveCollaboratorMenu;
