import UpdateUserEmailContainer from 'containers/Admin/UpdateUserEmail';
import Page from 'pages/Page';

function UpdateUserEmail() {
  return (
    <Page title="Update User Email" meta={[]} link={[]} script={[]}>
      <UpdateUserEmailContainer />
    </Page>
  );
}

export default UpdateUserEmail;
