import ErrorContainer from 'containers/Error';
import { localize } from 'locales';
import Page from 'pages/Page';

function Error() {
  return (
    <Page title={localize({ message: 'Error.PageTitle' })} meta={[]} link={[]} script={[]}>
      <ErrorContainer />
    </Page>
  );
}

export default Error;
