import { AuthUser } from 'app-types';
import PostHog from 'posthog-js-lite';

type Events =
  | 'AI Activity More Examples'
  | 'AI Created Activity'
  | 'AI Created Course'
  | 'AI Created Rubric'
  | 'AI Generated Course Displayed'
  | 'Activity Created'
  | 'Activity Instructions Updated'
  | 'Activity Objective Updated'
  | 'Activity Opened'
  | 'Activity Scheduled'
  | 'Activity has a Starting Score'
  | 'Advanced Activity Stage'
  | 'Apply AI Score'
  | 'Apply AI Score to All'
  | 'Clicked on AI-Plagiarism'
  | 'Clicked on Create Course with AI'
  | 'Clicked on Generate AI Activity'
  | 'Community SSO'
  | 'Course Connected to LMS'
  | 'Course Created'
  | 'Course Duplicated'
  | 'Create Activity from Course Card'
  | 'Creation Created'
  | 'Disliked AI Suggestion'
  | 'Enter Student Experience'
  | 'Evaluation Created'
  | 'Evaluation Flagged'
  | 'Evaluation Included'
  | 'Evaluation Removed'
  | 'File Preview Modal Opened'
  | 'Feedback Created'
  | 'Generic Error Page Shown'
  | 'Grade Disputed'
  | 'Grades Synced'
  | 'Instructor Sign-up'
  | 'Invite Students from Course Card'
  | 'More like this AI Suggestion'
  | 'Presentation Activity Saved'
  | 'Reverted a Stage'
  | 'Rubric Printed'
  | 'Rubric Template Saved'
  | 'Rubric Template Updated'
  | 'Spotlight Created'
  | 'Student Enrolled in Course'
  | 'Student Experience Sign Up'
  | 'Student Participation Page Saved'
  | 'Student Opened Spotlight'
  | 'Students Invited'
  | 'Syllabus Uploaded'
  | 'Synced Course Roster'
  | 'View Course Info from Course Card';

type EventProperties = {
  activitiesDuplicated?: boolean;
  activityId?: string;
  activityIds?: string;
  activityName?: string;
  activityStage?: string;
  activityType?: string;
  attachments?: string;
  comment?: string;
  courseId?: string;
  courseName?: string;
  createdAt?: string;
  creationAttachments?: string;
  creationToEval?: string;
  critical?: string;
  currentStage?: string;
  delta?: number;
  disputeReason?: string;
  disputeStatus?: string;
  evaluationAttachments?: string;
  evaluationId?: string;
  evaluatorEmail?: string;
  feedbackerEmail?: string;
  fileExtension?: string;
  flag?: string;
  highlightedCriteria?: string;
  institutionName?: string;
  instructorEmail?: string;
  instructorName?: string;
  isDemoCourse?: boolean;
  isMultipleTopics?: boolean;
  isSSO?: boolean;
  marks?: string;
  motivational?: string;
  nextStage?: string;
  numCriteria?: number;
  path?: string;
  rubricId?: string;
  rubricName?: string;
  spotlightNumWords?: number;
  spotlightType?: string;
  stage?: string;
  startingScore?: number;
  studentEmail?: string;
};

export function trackEvent(event: Events, authUser: AuthUser | null, properties: EventProperties = {}) {
  if (import.meta.env.DEV) {
    console.log('FAKE_EVENT', { event, properties, authUser });
  }
  if (authUser?.email?.includes('kritik.io')) {
    // filter out events from staff
    return;
  }

  // @ts-expect-error TS(2304) FIXME: Cannot find name 'App'.
  const postHogApiKey = App.config.get('postHogApiKey');
  if (postHogApiKey) {
    const posthog = new PostHog(postHogApiKey);
    if (authUser) {
      posthog.identify(authUser.id, { email: authUser.email, name: authUser.profile?.name });
    }
    posthog.capture(event, properties);
  }
}
