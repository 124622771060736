import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';

const StyledMenuItem = (props: any) => {
  return (
    <MenuItem onClick={props.onClick} className="rubric-cell-menu-item">
      <div className="rubric-cell-menu-item__icon" data-testid={props.testid}>
        {props.icon}
      </div>
      {props.label}
    </MenuItem>
  );
};

const ModifyRubricButton = (props: any) => {
  const handleClickAway = () => {
    if (props.onClickAway) {
      props.onClickAway();
    }
  };

  const isOpen = () => {
    return !!props.anchorEl;
  };

  return (
    <Popper
      anchorEl={props.anchorEl}
      open={isOpen()}
      placement={props.placement}
      transition
      disablePortal
      style={{ zIndex: '99999' }}
    >
      {({ TransitionProps, placement }) => {
        return (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClickAway} mouseEvent="onMouseDown" touchEvent="onTouchStart">
                <MenuList autoFocusItem={isOpen()} id="menu-list-grow">
                  {props.options.map((option: any) => {
                    return (
                      <StyledMenuItem
                        onClick={option.action}
                        icon={option.icon}
                        label={option.label}
                        testid={option.testid}
                      />
                    );
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        );
      }}
    </Popper>
  );
};

ModifyRubricButton.defaultProps = {
  placement: 'bottom',
};

export default ModifyRubricButton;
