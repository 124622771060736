import { REGION_OPTIONS } from '@kritik/utils/region';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FLAGS from 'images/Flags';
import { localize } from 'locales/index';
import { useEffect, useRef, useState } from 'react';

function RegionPicker(props: any) {
  const ref = useRef<HTMLDivElement>(null);
  const [expanded, setExpanded] = useState(false);
  const PLACE_HOLDER = 'Select a region';
  useEffect(() => {
    const listboxElement = ref.current?.querySelector('div[aria-haspopup="listbox"]');
    if (listboxElement) {
      listboxElement.setAttribute('aria-label', localize({ message: 'AccountActivation.Field.Region' }));
    }
  }, [ref.current]);
  const renderDisabled = () => {
    let flag;
    let name;

    if (props.value === REGION_OPTIONS.AMERICA) {
      flag = FLAGS.AMERICA;
      name = REGION_OPTIONS.AMERICA;
    } else if (props.value === REGION_OPTIONS.CANADA) {
      flag = FLAGS.CANADA;
      name = REGION_OPTIONS.CANADA;
    } else if (props.value === REGION_OPTIONS.EUROPE) {
      flag = FLAGS.EUROPE;
      name = REGION_OPTIONS.EUROPE;
    } else if (props.value === REGION_OPTIONS.OTHER) {
      flag = (FLAGS as any).OTHER;
      name = REGION_OPTIONS.OTHER;
    }

    return (
      <div className="kritik-region-picker--disabled">
        <img className="kritik-region-picker__flag" src={flag} aria-hidden="true" />
        {name}
      </div>
    );
  };
  const renderOptions = () => {
    return Object.keys(REGION_OPTIONS).map((key) => {
      const label = REGION_OPTIONS[key];
      return (
        <MenuItem key={`${label}_region`} value={label}>
          {FLAGS[key] && <img className="kritik-region-picker__flag" src={FLAGS[key]} aria-hidden="true" />}
          {label}
        </MenuItem>
      );
    });
  };

  const renderSelect = () => {
    return (
      <>
        <Select
          ref={ref}
          value={props.value}
          onChange={props.onChange}
          disableUnderline
          displayEmpty
          renderValue={(value) => {
            if (!value) {
              return PLACE_HOLDER;
            }
            return (
              <>
                {FLAGS[(value as any).toUpperCase()] && (
                  <img className="kritik-region-picker__flag" src={FLAGS[(value as any).toUpperCase()]} alt="flag" />
                )}
                {/* @ts-expect-error TS(2322) FIXME: Type 'unknown' is not assignable to type 'ReactNod... Remove this comment to see the full error message */}
                <span>{value}</span>
              </>
            );
          }}
          className="kritik-region-picker"
          inputProps={{
            id: 'region-select-field',
            'aria-expanded': expanded,
          }}
          onOpen={() => setExpanded(true)}
          onClose={() => setExpanded(false)}
        >
          {renderOptions()}
        </Select>
      </>
    );
  };

  const renderContent = () => {
    if (props.disabled) {
      return renderDisabled();
    }
    return renderSelect();
  };
  return renderContent();
}

RegionPicker.defaultProps = {
  onChange: null,
  disabled: false,
};

export default RegionPicker;
