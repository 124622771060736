import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ButtonContainer } from 'components/buttons';
import Button from 'components/buttons/Button';
import { useViewAsStudentExitUrl } from 'hooks/activity';
import { handleSetStudentViewMutation } from 'hooks/user';
import { localize } from 'locales';

type Props = {
  isOpen: boolean;
  handleClose: () => void;

  student: {
    name: string;
    id: string;
    email: string;
  };
  redirectUrl: string;
};

function ViewAsStudentModal({ isOpen, handleClose, redirectUrl, student }: Props) {
  const handleStartStudentView = handleSetStudentViewMutation();
  const { setViewAsStudentExitUrl } = useViewAsStudentExitUrl();
  return (
    <Dialog open={isOpen} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{localize({ message: 'StudentView.Modal.Title' })}</DialogTitle>
      <DialogContent id="alert-dialog-description">
        {localize({
          message: 'StudentView.Modal.Description1',
          options: {
            studentName: student.name,
            studentEmail: student.email,
          },
        })}
      </DialogContent>
      <DialogContent id="alert-dialog-description">
        {localize({ message: 'StudentView.Modal.Description2' })}
      </DialogContent>
      <DialogContent>
        <ButtonContainer>
          <Button
            onClick={() =>
              handleStartStudentView.mutate(
                { studentId: student.id },
                {
                  onSuccess: () => {
                    handleClose();
                    setViewAsStudentExitUrl(window.location.href);
                    window.location.href = redirectUrl;
                  },
                }
              )
            }
            type="primary"
            autoFocus
            testid="start-student-view"
          >
            {localize({ message: 'StudentView.Modal.ButtonConfirmation' })}
          </Button>
          <Button
            onClick={() => {
              return handleClose();
            }}
            type="secondary"
          >
            {localize({ message: 'StudentView.Modal.ButtonCancel' })}
          </Button>
        </ButtonContainer>
      </DialogContent>
    </Dialog>
  );
}

export default ViewAsStudentModal;
