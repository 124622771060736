import { isEmail } from '@kritik/utils/general';
import { updateUserEmail } from 'actions/admin';
import { Button } from 'components/buttons';
import FormTextInput from 'components/core/form/TextField';
import { InlineInformation } from 'components/layout';
import PageContainer from 'components/layout/PageContainer';
import PageHeader from 'components/layout/PageHeader';
import React, { useState } from 'react';
import { connect } from 'react-redux';

function UpdateUserEmail() {
  const [values, setValues] = useState({ currentEmail: '', newEmail: '' });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(null);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (error || isSuccess) {
      setError(null);
      setIsSuccess(null);
    }
    const key = e.target.name;
    setValues({ ...values, [key]: e.target.value });
  };

  const handleUpdateEmail = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setIsSuccess(null);
    if (!isEmail(values.currentEmail) || !isEmail(values.newEmail)) {
      setError('Please enter valid email');
      return;
    }
    const { success, message } = await updateUserEmail({
      currentEmail: values.currentEmail,
      newEmail: values.newEmail,
    });
    if (!success) {
      setError(message);
    } else {
      setIsSuccess('Email successfuly updated');
    }
    setLoading(false);
  };

  return (
    <PageContainer>
      <PageHeader title="Update User Email" />
      <form onSubmit={handleUpdateEmail}>
        <FormTextInput
          autoFocus
          name="currentEmail"
          label="Enter current email address"
          value={values.currentEmail}
          onChange={handleEmailChange}
        />
        <FormTextInput
          autoFocus
          name="newEmail"
          label="Enter new email address"
          value={values.newEmail}
          onChange={handleEmailChange}
        />
        {(error || isSuccess) && (
          <InlineInformation type={error ? 'danger' : 'success'} style={{ marginBottom: '20px' }}>
            <div>{error ? error : isSuccess}</div>
          </InlineInformation>
        )}

        <Button inputType="submit" loading={loading} type="primary" style={{ marginBottom: '20px' }}>
          Update Email
        </Button>
      </form>
    </PageContainer>
  );
}

export default connect(null, {})(UpdateUserEmail);
