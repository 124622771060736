import * as FormatUtils from '@kritik/utils/format';
import FormFieldInfo from 'components/core/form/FieldInfo';
import FormFieldTitle from 'components/core/form/Title';

const ScoreEditCard = ({ title, description, prevScore, newScore }: any) => {
  return (
    <div className="evaluation-score-edit__score-container">
      <div className="evaluation-score-edit__score-description">
        <FormFieldTitle label={title} />
        <FormFieldInfo label={description} />
      </div>
      <div className="evaluation-score-edit__score-display">
        <span className="evaluation-score-edit__prev-score">{FormatUtils.simplePercent(prevScore, 2)}</span>
        <i className="fas fa-arrow-right" />
        {newScore}
      </div>
    </div>
  );
};

export default ScoreEditCard;
