import * as LateSubmissionUtil from '@kritik/utils/creation/lateCreation';
import * as AssignmentStatusUtil from '@kritik/utils/stage';
import { resolveLateSubmission } from 'actions/activity';
import { LateCreationInstructorNotice } from 'components/Creation/CreationOverview/LateCreationInstructorNoticeBoard';
import LateCreationResolutionModal from 'components/Creation/CreationOverview/LateCreationInstructorNoticeBoard/ResolutionModal';
import { useState } from 'react';
import { connect } from 'react-redux';
import RejectionWarningModal from './RejectionWarningModal';

const LateCreation = ({ activity, creation, resolveLateSubmission }: any) => {
  const [showLateModal, setShowLateModal] = useState(false);
  const [isResolvingLateCreation, setIsResolvingLateCreation] = useState(false);
  const [isRejectingApprovedCreation, setIsRejectingApprovedCreation] = useState(false);

  const handleResolveLateCreation = (status: any, autoAssign: any) => {
    if (isRejectingApprovedCreation) {
      setIsRejectingApprovedCreation(false);
    }
    if (!isResolvingLateCreation) {
      setIsResolvingLateCreation(true);

      const data = {
        submissionId: creation._id,
        assignmentId: creation.assignment,
        status,
        resolvedDate: new Date(Date.now()),
        autoAssign,
      };

      resolveLateSubmission(data)
        .then(() => {
          setShowLateModal(false);
          setIsResolvingLateCreation(false);
        })
        .catch(() => {
          setShowLateModal(false);
          setIsResolvingLateCreation(false);
        });
    }
  };

  const scoreManually = () => {
    handleResolveLateCreation(LateSubmissionUtil.LATE_STATUS.ACCEPTED, false);
  };

  const acceptLateCreation = () => {
    if (AssignmentStatusUtil.isFeedbackOrLater({ assignment: activity })) {
      scoreManually();
    } else {
      setShowLateModal(true);
    }
  };

  const rejectLateCreation = () => {
    if (LateSubmissionUtil.isLateSubmissionAccepted(creation)) {
      return setIsRejectingApprovedCreation(true);
    }
    handleResolveLateCreation(LateSubmissionUtil.LATE_STATUS.REJECTED, null);
  };

  const cancelLateCreation = () => {
    setShowLateModal(false);
  };

  const cancelRejection = () => {
    setIsRejectingApprovedCreation(false);
  };

  return (
    <>
      <LateCreationResolutionModal
        isOpen={showLateModal}
        activityId={activity._id}
        creationId={creation._id}
        onCancel={cancelLateCreation}
        onResolveLateCreation={() => {
          handleResolveLateCreation(LateSubmissionUtil.LATE_STATUS.ACCEPTED, true);
        }}
        onScoreManually={scoreManually}
      />
      <LateCreationInstructorNotice
        activity={activity}
        creation={creation}
        onAccept={acceptLateCreation}
        onReject={rejectLateCreation}
      />
      <RejectionWarningModal
        isRejectingApprovedCreation={isRejectingApprovedCreation}
        resolveLateCreation={handleResolveLateCreation}
        cancelRejection={cancelRejection}
      />
    </>
  );
};

export default connect(() => ({}), { resolveLateSubmission })(LateCreation);
