import { resetAsync } from 'actions/async';
import { TranslatedText } from 'components/TranslatedText';
import Typography from 'components/core/Typography';
import { InlineInformation } from 'components/layout';
import ModalAction from 'components/modals/Components/Action';
import ModalContent from 'components/modals/Components/Content';
import Modal from 'components/modals/Components/Modal';
import Confirm from 'components/modals/ConfirmModal';
import { localize } from 'locales';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { GradebookActions, GradebookSelectors, GradebookTypes } from 'redux/gradebook';

let gradebookDownloadController: any;

const mapStateToProps = (state: any) => {
  return {
    downloadGradebookState: GradebookSelectors.selectDownloadGradebookState(state),
  };
};

const GradebookDownloadModal = ({
  downloadGradebook,
  course,
  downloadGradebookState,
  isOpen,
  setIsOpen,
  ...props
}: any) => {
  useEffect(() => {
    return () => {
      props.resetAsync(GradebookTypes.DOWNLOAD_GRADEBOOK);
    };
  }, []);
  const isGradebookDownloading = () => {
    return downloadGradebookState.busy;
  };
  const handleGradebookDownload = () => {
    downloadGradebook(course, (controller: any) => {
      gradebookDownloadController = controller;
    });
  };

  function cancelDownload() {
    setIsOpen(false);
    gradebookDownloadController.cancel();
  }

  return (
    <>
      <Confirm
        title={localize({ message: 'DownloadGradebookModal.Title' })}
        isOpen={isOpen}
        onCancel={() => {
          setIsOpen(false);
        }}
        onConfirm={() => {
          setIsOpen(false);
          return handleGradebookDownload();
        }}
        cancelButton={localize({ message: 'Common.No' })}
        confirmButton={localize({ message: 'Common.Yes' })}
        maxWidth="md"
      >
        <InlineInformation>
          <p>{localize({ message: 'Activity.Gradebook.Presentation.P1' })}</p>
          <p>{localize({ message: 'Activity.Gradebook.Presentation.P2' })}</p>
        </InlineInformation>
      </Confirm>
      <Modal isOpen={isGradebookDownloading()}>
        <ModalContent>
          <div className="gradebook-modal-container">
            <div className="gradebook-modal-spinner__wrapper">
              <div className="gradebook-modal-spinner" />
            </div>
            <div className="gradebook-modal-text">
              <h3>
                <TranslatedText i18nKey="DownloadGradebookModal.PreparingDownload" />
              </h3>
              <Typography color="light">
                <TranslatedText i18nKey="DownloadGradebookModal.DownloadTime" />
              </Typography>
            </div>
          </div>
        </ModalContent>
        <ModalAction
          actions={[
            {
              label: localize({ message: 'DownloadGradebookModal.CancelDownload' }),
              type: 'secondary',
              onClick: cancelDownload,
            },
          ]}
        />
      </Modal>
    </>
  );
};

export default connect(mapStateToProps, {
  downloadGradebook: GradebookActions.downloadGradebook,
  resetAsync,
})(GradebookDownloadModal);
