import PencilIcon from '@material-ui/icons/Create';
import WeightMenu from 'components/Assignment/List/WeightEditor';
import { TranslatedText } from 'components/TranslatedText';
import PropagationInterceptor from 'components/Utility/PropagationInterceptor';
import Button from 'components/buttons/Button';
import { useUserRoleInCourse } from 'hooks/course';
import { localize } from 'locales';
import { useState } from 'react';

const WeightCell = (props: any) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { isInstructorInCourse } = useUserRoleInCourse();

  const setIsEditingWeight = (e: any) => {
    setAnchorEl(e.currentTarget);
  };

  const unsetIsEditingWeight = () => {
    setAnchorEl(null);
  };

  const renderWeight = () => {
    const { weight } = props.activity;
    if (!isInstructorInCourse) {
      return (
        <span style={{ marginRight: '18px' }}>
          {weight} <TranslatedText i18nKey="Activity.Weight.Points" values={{ count: weight }} />
        </span>
      );
    }
    return (
      <PropagationInterceptor>
        <Button
          type="hidden"
          className="activity-list__weight-btn"
          onClick={setIsEditingWeight}
          aria-label={localize({ message: 'Button.Label.EditWeight' })}
        >
          <span>
            {weight} <TranslatedText i18nKey="Activity.Weight.Points" values={{ count: weight }} />
          </span>
          <PencilIcon className="activity-list__weight-btn-icon" />
        </Button>
        <WeightMenu
          activity={props.activity}
          anchorEl={anchorEl}
          isOpen={anchorEl}
          onCancel={() => {
            return unsetIsEditingWeight();
          }}
        />
      </PropagationInterceptor>
    );
  };

  return renderWeight();
};

export default WeightCell;
