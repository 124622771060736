import Creation from 'components/Spotlight/View/Creation';
import Evaluation from 'components/Spotlight/View/Evaluation';

function Reference(props: any) {
  const { spotlight } = props;
  const referenceId = spotlight.getReferenceId();

  return spotlight.isCreationType() ? <Creation creationId={referenceId} /> : <Evaluation evaluationId={referenceId} />;
}

export default Reference;
