import DemoRegisterContainer from 'containers/DemoRegister';
import { localize } from 'locales';
import Page from 'pages/Page';

function DemoRegister() {
  return (
    <Page title={localize({ message: 'DemoRegister.PageTitle' })} meta={[]} link={[]} script={[]}>
      <DemoRegisterContainer />
    </Page>
  );
}

export default DemoRegister;
