import CloseButton from '../CloseButton';
import AssignmentProvider from '../context/AssignmentContext';
import CourseProvider from '../context/CourseContext.jsx';
import EvaluationProvider from '../context/EvaluationContext.jsx';
import AddNewFeedbackContainer from './AddNewFeedback/AddNewFeedbackContainer.jsx';
import ConfirmEvaluation from './ConfirmEvaluation.jsx';
import FindEvaluation from './FindEvaluation.jsx';

const LateFofModal = (props: any) => {
  return (
    <div className="admin-panel-modal">
      <section className="admin-panel-modal__main admin-panel-modal__scrollable">
        <h1>Upload Late Feedback</h1>
        <EvaluationProvider>
          <CourseProvider>
            <AssignmentProvider>
              <FindEvaluation />
              <ConfirmEvaluation />
              <AddNewFeedbackContainer />
            </AssignmentProvider>
          </CourseProvider>
        </EvaluationProvider>
      </section>
      <CloseButton onClose={props.handleClose} />
    </div>
  );
};

export default LateFofModal;
