import classNames from 'classnames';
import { ArrowDown as ArrowTip } from 'images/icons/svg';
import React from 'react';

function Container(props: { children: React.ReactNode; className?: string }) {
  const classes = classNames('arrow-down__container', props.className);
  return <div className={classes}>{props.children}</div>;
}

function ArrowDown(props: { className?: string }) {
  return (
    <Container className={props.className}>
      <div className="arrow-down__line" />
      <div className="arrow-down__tip">
        <ArrowTip fill="#c6c4ed" />
      </div>
    </Container>
  );
}

export default ArrowDown;
