import { ACTIVITY_TYPES } from '@kritik/constants/activity';
import * as statusUtil from '@kritik/utils/stage';
import validators from 'components/ActivityEdit/Details/Fields/validators';
import FormTitle from 'components/core/form/Title';
import RadioInput from 'components/core/input/Radio';
import PanelDisplay from 'components/layout/PanelDisplay';
import Confirm from 'components/modals/ConfirmModal';
import { localize } from 'locales';
import React, { useEffect, useState } from 'react';
import { Field } from 'react-final-form';
import { connect } from 'react-redux';
import { getAssignment } from 'selectors/activity';
import { FIELD_LABEL, FIELD_NAME } from './constants';
export const LABEL = {
  value: FIELD_NAME,
  label: FIELD_LABEL,
};

const PresentationType = (props: any) => {
  const [targetPresentationType, setTargetPresentationType] = useState(null);
  const [changeTypeConfirm, setChangeTypeConfirm] = useState(null);
  const getRadioOptions = () => {
    return [
      {
        value: ACTIVITY_TYPES.INDIVIDUAL_PRESENTATION,
        label: localize({ message: 'Activity.Presentation.Individual' }),
        testid: 'individual-presentation-type',
      },
      {
        value: ACTIVITY_TYPES.GROUP_PRESENTATION,
        label: localize({ message: 'Activity.Presentation.Group' }),
        testid: 'group-presentation-type',
      },
    ];
  };

  const changePresentationType = () => {
    if (props.activity && !props.settings.isCloning && !statusUtil.isDraft({ assignment: props.activity })) {
      return;
    }
    props.mutators.setValue(LABEL.value, targetPresentationType);
    cancelTypeSwitch();
  };

  const cancelTypeSwitch = () => {
    setChangeTypeConfirm(null);
  };

  useEffect(() => {
    changePresentationType();
  }, [targetPresentationType]);

  const renderConfirm = () => {
    return (
      <Confirm
        title={localize({ message: 'Activity.Presentation.Switch.Title' })}
        description={localize({ message: 'Activity.Presentation.Switch.Description' })}
        isOpen={!!changeTypeConfirm}
        onCancel={() => {
          return cancelTypeSwitch();
        }}
        onConfirm={() => {
          return setTargetPresentationType(changeTypeConfirm);
        }}
        cancelButton={localize({ message: 'Common.No' })}
        confirmButton={localize({ message: 'Common.Yes' })}
        testid="change-presentation-type"
      />
    );
  };

  const changePresentationTypeRequest = (currentPresentationType: any, targetType: any) => {
    if (currentPresentationType !== targetType) {
      if (!props.formState.dirty) {
        setTargetPresentationType(targetType);
      } else {
        setChangeTypeConfirm(targetType);
      }
    }
  };

  return (
    <Field name={LABEL.value} validate={validators.isRequired}>
      {({ input, meta }) => {
        return (
          <React.Fragment>
            <PanelDisplay className="presentation-type__panel">
              <FormTitle label={LABEL.label} />
              <RadioInput
                value={input.value}
                options={getRadioOptions()}
                onChange={(e: any) => {
                  return changePresentationTypeRequest(input.value, e.target.value);
                }}
                type="button"
                legendText="Legend.Text.PresentationType"
              />
              {meta.error && meta.touched && <span>{meta.error}</span>}

              {renderConfirm()}
            </PanelDisplay>
          </React.Fragment>
        );
      }}
    </Field>
  );
};

const mapStateToProps = (state: any) => {
  return {
    activity: getAssignment(state, state.selected.assignmentId),
  };
};

export default connect(mapStateToProps, {})(PresentationType);
