import { Button } from 'components/buttons';
import TextInput from 'components/core/input/Text';
import { InlineInformation, PageHeader } from 'components/layout';
import PageContainer from 'components/layout/PageContainer';
import Table from 'components/layout/SimpleTable';
import TableBody from 'components/layout/SimpleTable/Body';
import TableCell from 'components/layout/SimpleTable/Cell';
import TableHead from 'components/layout/SimpleTable/Head';
import TableRow from 'components/layout/SimpleTable/Row';
import { useGetCourseData } from 'hooks/admin';
import * as _ from 'lodash-es';
import React from 'react';

function CourseData() {
  const [instructorEmail, setInstructorEmail] = React.useState('');
  const [error, setError] = React.useState('');
  const { data, isFetching } = useGetCourseData({
    instructorEmail,
    onError: (e) => setError(e.response?.data?.errors?.message || 'An error occurred'),
  });
  const dataGroupedByCourse = _.groupBy(data, 'courseId');
  const courseIds = Object.keys(dataGroupedByCourse);

  return (
    <PageContainer>
      <PageHeader title="Course Data per Instructor" />
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          setInstructorEmail(e.target['instructorEmail'].value);
        }}
      >
        <TextInput
          inputProps={{
            autoComplete: 'off',
            required: true,
          }}
          name="instructorEmail"
          testid="instructorEmail"
          autoFocus
        />
        <Button testid="submit" type="primary" inputType="submit" disabled={isFetching}>
          {isFetching ? 'Loading' : 'Get Data'}
        </Button>
        {error && (
          <InlineInformation testid="error-info" type="danger">
            {error}
          </InlineInformation>
        )}
        {data && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Course Title</TableCell>
                <TableCell>Activity Title</TableCell>
                <TableCell>Participation Ratio</TableCell>
                <TableCell>Dispute Ratio</TableCell>
                <TableCell>Evaluations Done</TableCell>
                <TableCell>Evaluations To Assign</TableCell>
                <TableCell>Evaluate Stage Duration (Days)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {courseIds.map((courseId) => {
                const courseData = dataGroupedByCourse[courseId];
                const evaluationParticipationRatioAverage = Math.round(
                  _.meanBy(courseData, (e) => e.evaluationParticipationRatio)
                );
                const disputeRatioAverage = Math.round(_.meanBy(courseData, (e) => e.disputeRatio));
                const evaluationsDoneAverage = Math.round(_.meanBy(courseData, (e) => e.evaluationsDone));
                const numEvaluationsToAssignAverage = Math.round(_.meanBy(courseData, (e) => e.numEvaluationsToAssign));
                const evaluationStageDurationInDaysAverage = Math.round(
                  _.meanBy(courseData, (e) => e.evaluationStageDurationInDays)
                );
                return (
                  <>
                    {courseData.map((d) => (
                      <TableRow key={d.activityId}>
                        <TableCell>{d.courseTitle}</TableCell>
                        <TableCell>{d.activityTitle}</TableCell>
                        <TableCell>{d.evaluationParticipationRatio}%</TableCell>
                        <TableCell>{d.disputeRatio}%</TableCell>
                        <TableCell>{d.evaluationsDone}</TableCell>
                        <TableCell>{d.numEvaluationsToAssign}</TableCell>
                        <TableCell>{d.evaluationStageDurationInDays}</TableCell>
                      </TableRow>
                    ))}
                    <TableRow isSelected>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell>{evaluationParticipationRatioAverage}%</TableCell>
                      <TableCell>{disputeRatioAverage}%</TableCell>
                      <TableCell>{evaluationsDoneAverage}</TableCell>
                      <TableCell>{numEvaluationsToAssignAverage}</TableCell>
                      <TableCell>{evaluationStageDurationInDaysAverage}</TableCell>
                    </TableRow>
                  </>
                );
              })}
            </TableBody>
          </Table>
        )}
      </form>
    </PageContainer>
  );
}

export default CourseData;
