import { TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import axios from 'axios';
import * as _ from 'lodash-es';
import React, { useState } from 'react';
import { OPTIONS } from './DefaultLocation';
import { SearchTimeZone } from './SearchTimeZone';

// @ts-expect-error TS(2345) FIXME: Argument of type '(theme: Theme) => { option: { fo... Remove this comment to see the full error message
const useStyles = makeStyles((theme) => {
  return {
    option: {
      fontWeight: '500',
    },
  };
});

let timeout: any = null;

const SearchLocation = (props: any) => {
  const classes = useStyles();
  const [options, setOptions] = useState(OPTIONS);
  const [value, setValue] = useState(null);
  const [isLoadingOptions, setLoadingOptions] = useState(false);
  const [isLoadingSelection, setLoadingSelection] = useState(false);

  const handleChange = (e: any) => {
    if (isLoadingOptions || !e || !e.target) {
      return;
    }
    const { value } = e.target;
    if (typeof value === 'number') {
      return;
    }
    if (!value) {
      setLoadingOptions(false);
      setOptions(OPTIONS);
      clearTimeout(timeout);
      return;
    }
    if (timeout !== null) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(async () => {
      setLoadingOptions(true);
      // @ts-expect-error TS(2304) FIXME: Cannot find name 'App'.
      const geonamesUsername = App.config.get('geonamesUsername');
      const url =
        `https://secure.geonames.org/searchJSON?name_startsWith=${value}` +
        '&cities=cities15000&featureCode=ADM&featureCode=ADM2&featureCode=PPLA&featureCode=PPLA2&featureCode=PPL' +
        '&countryBias=US&countryBias=CA' +
        `&username=${geonamesUsername}`;
      const result = await axios.get(url);
      const cities = result.data.geonames;
      const uniqueCities = _.uniqBy(cities, 'adminName1');
      const options = uniqueCities.map((city) => {
        return {
          title: `${(city as any).toponymName}, ${(city as any).adminName1}`,
          lat: (city as any).lat,
          lng: (city as any).lng,
        };
      });
      setOptions(options);
      setLoadingOptions(false);
    }, 400);
  };

  const handleSelect = async (event: any, value: any, reason: any) => {
    if (isLoadingSelection) {
      return;
    }
    if (value) {
      setLoadingSelection(true);
      const { lat } = value;
      const { lng } = value;
      // @ts-expect-error TS(2304) FIXME: Cannot find name 'App'.
      const geonamesUsername = App.config.get('geonamesUsername');
      const result = await axios.get(
        `https://secure.geonames.org/timezoneJSON?lat=${lat}&lng=${lng}&username=${geonamesUsername}`
      );
      props.onSelect(result.data.timezoneId);
      setValue(value);
      setLoadingSelection(false);
    } else {
      setOptions(OPTIONS);
    }
  };

  return (
    <React.Fragment>
      <Autocomplete
        value={value}
        options={options}
        getOptionLabel={(option) => {
          return option.title;
        }}
        renderInput={(params) => {
          return <TextField {...params} label="City" variant="outlined" />;
        }}
        renderOption={(option) => {
          return <Typography className={(classes as any).option}>{option.title}</Typography>;
        }}
        onChange={handleSelect}
        onInputChange={handleChange}
        loading={isLoadingOptions}
        disabled={isLoadingSelection}
        openOnFocus
        disableClearable
      />
      <SearchTimeZone
        timeZone={props.timeZone}
        setTimeZone={props.setTimeZone}
        disabled={isLoadingOptions || isLoadingSelection}
      />
    </React.Fragment>
  );
};

export default SearchLocation;
