import Cell from 'components/CreationList/Body/Cell';
import { ChevronDown, ChevronRight } from 'images/icons/fa';

function ToggleCell(props: any) {
  const { onClick, column, isExpand } = props;
  return (
    <Cell column={column} onClick={onClick}>
      {isExpand ? <ChevronDown /> : <ChevronRight />}
    </Cell>
  );
}

export default ToggleCell;
