import { TranslatedText } from 'components/TranslatedText';

const TermsOfService = () => {
  return (
    <div style={{ marginBottom: '15px', fontSize: '14px' }}>
      <TranslatedText i18nKey="TermsOfService.Agreement" />{' '}
      <a rel="noreferrer" href="https://www.kritik.io/terms-of-service" target="_blank" className="underlined-link">
        <TranslatedText i18nKey="TermsOfService.Link" />
      </a>
    </div>
  );
};

export default TermsOfService;
