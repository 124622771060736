import classNames from 'classnames';

const TextDisplay = ({ className, content, children }: any) => {
  const displayClasses = classNames('TextDisplay', className);

  return (
    <div className={displayClasses}>
      {content}
      {children}
    </div>
  );
};

export default TextDisplay;
