import { MINIMUM_WORD_COUNT } from '@kritik/constants/course';
import ErrorItem from 'components/ActivityEdit/ErrorMsg/Item';
import { localize } from 'locales';
import { FormSpy } from 'react-final-form';

const generateValidationErrMsgs = (validationErrorsObj: any) => {
  const REQUIRED = 'Required';
  const errMsgs = [];
  for (const key in validationErrorsObj) {
    const value = validationErrorsObj[key];
    switch (key) {
      case 'title': {
        if (value === REQUIRED) {
          errMsgs.push(localize({ message: 'Activity.TitleField.Required' }));
        } else {
          errMsgs.push(value);
        }
        break;
      }
      case 'objective': {
        if (value === REQUIRED) {
          errMsgs.push(localize({ message: 'Activity.ObjectiveField.Required' }));
        } else {
          errMsgs.push(value);
        }
        break;
      }
      case 'instructions': {
        if (value === REQUIRED) {
          errMsgs.push(localize({ message: 'Activity.InstructionsField.Required' }));
        } else {
          errMsgs.push(value);
        }
        break;
      }
      case 'settings': {
        if (value['minimumWordCountForEvaluations']) {
          errMsgs.push(
            localize({
              message: 'Activity.wordCount.Error',
              options: {
                minimumWordCount: MINIMUM_WORD_COUNT,
              },
            })
          );
        }

        break;
      }
      default: {
        errMsgs.push(value);
      }
    }
  }
  return errMsgs;
};

function ValidationErrorMsg({ validationErrorsObj }: any) {
  const errMsgs = generateValidationErrMsgs(validationErrorsObj);
  return (
    <div className="activity-edit__error-msg-list">
      {errMsgs.map((errMsg, index) => {
        return <ErrorItem key={`errorItem-${index}`} message={errMsg} />;
      })}
    </div>
  );
}

function ErrorMsg(props: any) {
  if (!props.submitFailed) {
    return null;
  }

  if (props.submitError) {
    return <ErrorItem message={props.submitError} />;
  }
  if (props.hasValidationErrors) {
    return <ValidationErrorMsg validationErrorsObj={props.errors} />;
  }
  return null;
}

function ErrorMsgWrapper() {
  return <FormSpy component={ErrorMsg} />;
}

export default ErrorMsgWrapper;
