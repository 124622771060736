import { navigateToActivityPage } from 'actions/activity';
import { selectAssignment, selectCourse } from 'actions/select';
import LoaderOverlay from 'components/Loaders/OverlaySpinner/index';
import SpotlightEdit from 'components/Spotlight/Edit';
import SpotlightView from 'components/Spotlight/View';
import { TranslatedText } from 'components/TranslatedText';
import PageContainer from 'components/layout/PageContainer';
import PageHeader from 'components/layout/PageHeader';
import { localize } from 'locales';
import ErrorPage from 'pages/GenericErrorPage';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getSpotlight, selectIsPreview, selectSpotlight, selectSpotlightStatus } from 'redux/spotlight';
import { selectCurrentActivity } from 'selectors/activity';
import { getCourse } from 'selectors/course';
import { withRouter } from 'utils/withRouter';

function mapStateToProps(state: any, ownProps: any) {
  const { spotlightId } = ownProps.router.params;
  return {
    course: getCourse(state),
    activity: selectCurrentActivity(state),
    spotlight: selectSpotlight(state, spotlightId),
    isPreview: selectIsPreview(state),
    fetchSpotlightStatus: selectSpotlightStatus(state),
  };
}

function SpotlightContainer(props: any) {
  const { courseId, assignmentId, spotlightId } = props.router.params;
  const [isLoadingSpotlight, setIsLoadingSpotlight] = useState(true);
  const { spotlight } = props;
  const isCreate = !spotlightId;
  const isEdit = spotlightId && props.router.location.pathname.includes('edit');

  useEffect(() => {
    props.selectCourse(courseId);
    props.selectAssignment(assignmentId);
    if (spotlightId) {
      props.getSpotlight(spotlightId).then(() => {
        setIsLoadingSpotlight(false);
      });
    }
  }, [props.router.params]);

  useEffect(() => {
    if (props.fetchSpotlightStatus.error) {
      props.navigateToActivityPage({
        courseId,
        assignmentId,
      });
    }
  }, [props.fetchSpotlightStatus.error]);

  if (!props.course || !props.activity || (isLoadingSpotlight && !isCreate)) {
    return null;
  }

  if (!spotlight && !isCreate) {
    return (
      <ErrorPage
        subtitle={localize({ message: 'Error.404Subtitle' })}
        icon="🔍"
        title={localize({ message: 'Error.404Title' })}
        buttonText={localize({ message: 'Error.ButtonText' })}
        descriptions={[localize({ message: 'Error.404Text' })]}
        iconVariant="blue"
      />
    );
  }

  if (isCreate) {
    const label = props.isPreview
      ? localize({ message: 'Spotlight.Create.PreviewLabel' })
      : localize({ message: 'Spotlight.Create.Label' });
    return (
      <PageContainer>
        <PageHeader className="page-title" title={label} />
        <SpotlightEdit />
      </PageContainer>
    );
  }

  if (isEdit) {
    return (
      <PageContainer>
        <PageHeader className="page-title" title={<TranslatedText i18nKey="Spotlight.Edit.PageHeader" />} />
        {props.fetchSpotlightStatus.busy ? <LoaderOverlay isOpen /> : <SpotlightEdit spotlight={spotlight} />}
      </PageContainer>
    );
  }
  return (
    <PageContainer>
      {props.fetchSpotlightStatus.busy ? <LoaderOverlay isOpen /> : <SpotlightView spotlight={spotlight} />}
    </PageContainer>
  );
}

SpotlightContainer.defaultProps = {
  spotlight: null,
};

export default withRouter(
  connect(mapStateToProps, {
    selectCourse,
    selectAssignment,
    getSpotlight,
    navigateToActivityPage,
  })(SpotlightContainer)
);
