import classNames from 'classnames';
import { Link, useLocation } from 'react-router-dom';

type NavigationLinkProps = {
  activeRegex?: RegExp[];
  location?: any;
  selectable?: boolean;
  className?: string;
  icon?: any;
  label?: string;
  onClick?: () => void;
  testid?: string;
  to?: string;
  router?: any;
  isExpanded?: boolean;
};

const NavigationLink = (props: NavigationLinkProps) => {
  const location = useLocation();
  let active = false;
  if (props.activeRegex) {
    props.activeRegex.forEach((ar: any) => {
      if (location.pathname.match(ar)) {
        active = true;
      }
    });
  }

  const linkClasses = classNames(
    'menu-item',
    {
      selectable: props.selectable,
      'nav-active': active,
    },
    props.className
  );

  const content = (
    <>
      <span className="menu-item__icon">{props.icon}</span>
      {props.label && <span className="menu-item__label">{props.label}</span>}
    </>
  );

  if (props.to) {
    return (
      <Link to={props.to} className={linkClasses} data-testid={props.testid} aria-current={active ? 'page' : false}>
        {content}
      </Link>
    );
  }

  return (
    <button
      className="menu-item"
      data-testid={props.testid}
      onClick={props.onClick}
      type="button"
      tabIndex={0}
      aria-label={props.label}
      aria-haspopup={props['aria-haspopup']}
      aria-expanded={props.isExpanded}
      aria-current={active ? 'page' : false}
    >
      {content}
    </button>
  );
};

NavigationLink.defaultProps = {
  activeRegex: null,
  selectable: false,
  className: '',
};

export default NavigationLink;
