import EVALUATE from './evaluate.svg';
import EVALUATE_DISABLED from './evaluate__disabled.svg';
import FEEDBACK from './feedback.svg';
import FEEDBACK_DISABLED from './feedback__disabled.svg';
import GRADING from './grading.svg';
import GRADING_DISABLED from './grading__disabled.svg';
import CREATE from './pencil.svg';
import CREATE_DISABLED from './pencil__disabled.svg';
import PRESENT from './presentation.svg';
import PRESENT_DISABLED from './presentation__disabled.svg';

export default {
  CREATE,
  CREATE_DISABLED,
  EVALUATE,
  EVALUATE_DISABLED,
  FEEDBACK,
  FEEDBACK_DISABLED,
  GRADING,
  GRADING_DISABLED,
  PRESENT,
  PRESENT_DISABLED,
};
