import { fileRestrictionStatus } from '@kritik/constants/activity';
import { ReadOnlyField } from 'components/ActivityEdit/AdvancedOptions/Field';
import { TranslatedText } from 'components/TranslatedText';
import { useForm } from 'react-final-form';

const ReadOnly = () => {
  const form = useForm();
  const { restrictFileTypes } = form.getState().values;

  switch (restrictFileTypes) {
    case fileRestrictionStatus.UNRESTRICTED:
      return (
        <ReadOnlyField>
          <TranslatedText i18nKey="Activity.FileAttachmentField.ReadOnly.Unrestricted" />
        </ReadOnlyField>
      );
    case fileRestrictionStatus.SOMEFILES:
      return (
        <ReadOnlyField>
          <TranslatedText i18nKey="Activity.FileAttachmentField.ReadOnly.SomeFiles" />
        </ReadOnlyField>
      );
    case fileRestrictionStatus.ALL:
      return (
        <ReadOnlyField>
          <TranslatedText i18nKey="Activity.FileAttachmentField.ReadOnly.AllFiles" />
        </ReadOnlyField>
      );
  }
};

export default ReadOnly;
