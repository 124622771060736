import * as ActivityUtils from '@kritik/utils/activity';
import { wasLateEvaluation } from '@kritik/utils/creation/evaluation';
import * as FeedbackUtil from '@kritik/utils/creation/feedback';
import { isUserCreationOwner } from '@kritik/utils/creation/general';
import { isScoreViewCompleted } from '@kritik/utils/creation/score';
import { FeedbackScoreUtil } from '@kritik/utils/grade';
import { isFeedback, isFeedbackOrLater, isFinalized, isGrading } from '@kritik/utils/stage';
import { MissedFeedback } from 'components/Assignment/NoticeBoards';
import { FeedbackEdit, FeedbackView } from 'components/Creation/Feedback';
import { TranslatedText } from 'components/TranslatedText';
import NoticeBoard from 'components/layout/NoticeBoard';
import { useUserRoleInCourse } from 'hooks/course';
import { localize } from 'locales';
import { useState } from 'react';
import { connect } from 'react-redux';
import StudentSelector from 'selectors/student';

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
    student: StudentSelector.getStudentFromUser(state, state.selected.courseId),
  };
};

type Props = {
  assignment: any;
  submission: any;
  evaluation: any;
  user: any;
  showBreakdown?: boolean;
  showTitle?: boolean;
  student?: any;
};

function Main({ assignment, submission, evaluation, user, showBreakdown, showTitle, student }: Props) {
  const { isInstructorInCourse, isStudentInCourse } = useUserRoleInCourse();
  const [isEditing, setIsEditing] = useState(false);
  if (isGrading({ assignment }) || isFinalized({ assignment })) {
    if (!ActivityUtils.isCalibrationActivity(assignment) && !FeedbackScoreUtil.isFOFCompleted(evaluation)) {
      if (wasLateEvaluation({ evaluation })) {
        return <NoticeBoard>{localize({ message: 'Activity.Feedback.LateEvaluation' })}</NoticeBoard>;
      }
      return <MissedFeedback />;
    }
  }

  if (!isFeedbackOrLater({ assignment })) {
    return (
      <NoticeBoard type="information" title={localize({ message: 'Creation.Feedback.Main.NoFeedbackYet.Title' })}>
        <TranslatedText i18nKey="Creation.Feedback.Main.NoFeedbackYet.Content" />
      </NoticeBoard>
    );
  }

  if (
    (isInstructorInCourse || user?.authUser?.permissions.includes('isAdmin')) &&
    isFeedback({ assignment }) &&
    !FeedbackScoreUtil.isFOFCompleted(evaluation)
  ) {
    if (evaluation.lateEvaluation) {
      return (
        <NoticeBoard
          type="information"
          title={localize({
            message: 'Activity.Feedback.LateEvaluation.FeedbackNotRequiredNotice.Instructor.Title',
          })}
        >
          <TranslatedText i18nKey="Activity.Feedback.LateEvaluation.FeedbackNotRequiredNotice.Instructor.Content" />
        </NoticeBoard>
      );
    }
    return (
      <NoticeBoard type="information" title={localize({ message: 'Creation.Feedback.Main.NoFeedbackYet.Title' })}>
        <TranslatedText i18nKey="Creation.Feedback.Main.FeedbackPending" />
      </NoticeBoard>
    );
  }

  const handleEdit = () => {
    setIsEditing(true);
  };

  const ownsCreation =
    student &&
    (isUserCreationOwner(submission, user) ||
      (ActivityUtils.isGroupAssignment(assignment) &&
        FeedbackUtil.isFeedbackAssignee(evaluation.feedbackOnFeedback, student._id)));

  const canGiveFeedbackOnPresentationEvaluation =
    student &&
    ActivityUtils.isPresentationActivity(assignment) &&
    FeedbackUtil.isFeedbackAssignee(evaluation.feedbackOnFeedback, student._id);

  const userCanEdit =
    isFeedback({ assignment }) &&
    isStudentInCourse &&
    (ownsCreation || canGiveFeedbackOnPresentationEvaluation) &&
    !isScoreViewCompleted(submission);

  if (FeedbackScoreUtil.isFOFCompleted(evaluation) && !isEditing) {
    return (
      <FeedbackView
        submission={submission}
        evaluation={evaluation}
        onEdit={handleEdit}
        isEditable={userCanEdit}
        showBreakdown={showBreakdown}
        showTitle={showTitle}
      />
    );
  }

  if (userCanEdit && (isEditing || !FeedbackScoreUtil.isFOFCompleted(evaluation))) {
    return (
      <FeedbackEdit
        submission={submission}
        evaluation={evaluation}
        onSubmit={() => {
          return setIsEditing(false);
        }}
        onCancel={() => {
          return setIsEditing(false);
        }}
      />
    );
  }

  if (isScoreViewCompleted(submission) && wasLateEvaluation({ evaluation })) {
    return <NoticeBoard>{localize({ message: 'Activity.Feedback.LateEvaluation' })}</NoticeBoard>;
  }

  return null;
}

Main.defaultProps = {
  showBreakdown: false,
  showTitle: false,
};

export default connect(mapStateToProps)(Main);
