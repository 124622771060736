const PropagationInterceptor = (props: any) => {
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      {props.children}
    </div>
  );
};

export default PropagationInterceptor;
