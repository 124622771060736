import ActivitySettingsContainer from 'containers/Admin/ActivitySettings';
import Page from 'pages/Page';

function ActivitySettings() {
  return (
    <Page title="Activity Settings" meta={[]} link={[]} script={[]}>
      <ActivitySettingsContainer />
    </Page>
  );
}

export default ActivitySettings;
