import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import classnames from 'classnames';
import Button from 'components/buttons/Button';
import { ChevronDown } from 'images/icons/fa';
import { useState } from 'react';

function ButtonWithSelect(props: any) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const classes = classnames('button-select__icon', {
    'button-select--primary': props.type === 'primary',
    'button-select--secondary': props.type === 'secondary',
  });

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    setIsExpanded(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsExpanded(false);
  };

  const handleChange = (event: any) => {
    props.onChange({ target: { value: event.currentTarget.dataset.myValue } });
    handleClose();
  };

  const renderOptions = () => {
    return props.options.map((option: any) => {
      if (option.tooltip) {
        return (
          <Tooltip title={option.tooltip} placement="left">
            <div>
              <Option option={option} disabled={option.disabled || props.disabled} handleChange={handleChange} />
            </div>
          </Tooltip>
        );
      }
      return (
        <Option
          key={option.value}
          option={option}
          disabled={option.disabled || props.disabled}
          handleChange={handleChange}
        />
      );
    });
  };

  return (
    <span className={props.className}>
      <Button
        id={props.id}
        aria-controls="simple-menu"
        aria-haspopup="menu"
        disabled={props.disabled}
        onClick={handleClick}
        type={props.type}
        data-testid={props.testid}
        aria-labelledby={props['aria-labelledby']}
        aria-expanded={isExpanded}
      >
        {props.title}
        <ChevronDown className={classes} />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: props.position,
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: props.position,
        }}
      >
        {renderOptions()}
      </Menu>
    </span>
  );
}

const Option = ({ option, disabled, handleChange }) => {
  return (
    <MenuItem
      data-my-value={option.value}
      onClick={handleChange}
      data-testid={option.testid}
      disabled={disabled}
      tabIndex={0}
      className="button-item"
      role="option"
    >
      <div className="button-select__item" role="button">
        {option.label}
      </div>
    </MenuItem>
  );
};

ButtonWithSelect.defaultProps = {
  type: 'primary',
  disabled: false,
  position: 'left',
};

export default ButtonWithSelect;
