import { EditActivityParticipation as EditActivityParticipationContainer } from 'containers/EditActivityParticipation';
import { localize } from 'locales';
import Page from 'pages/Page';

function EditActivityParticipation() {
  return (
    <Page
      title={localize({
        message: 'Activity.Presentation.EditParticipation.PageTitle',
      })}
      meta={[]}
      link={[]}
      script={[]}
    >
      <EditActivityParticipationContainer />
    </Page>
  );
}
export default EditActivityParticipation;
