import { Activity, Course, Creation, CreationScore, LateEvaluationStatus } from '@kritik/types.generated';
import { isCalibrationActivity } from '@kritik/utils/activity';
import { getNumEvaluationsCompleted } from '@kritik/utils/creation/evaluation';
import { FeedbackScoreUtil, GradingScoreUtil, WrittenEvalScoreUtil } from '@kritik/utils/grade';

export class EvaluationScoreUtil {
  static getEvaluationTotalWeight = (markingScheme: MarkingScheme) => {
    const totalWeight = markingScheme.kritikScore + markingScheme.feedbackCommunicationEffectiveness;
    return totalWeight;
  };

  static evaluatorOverallScoreSingleActivity(
    gradingScore: number,
    writtenEvalScore: number,
    course: Course,
    activity?: Activity
  ) {
    if (activity && isCalibrationActivity(activity)) {
      return gradingScore;
    }
    const { markingScheme }: { markingScheme: MarkingScheme } = course;
    const totalWeight = this.getEvaluationTotalWeight(markingScheme);
    if (totalWeight === 0) {
      return 0;
    }
    const gradingScoreWeight = markingScheme.kritikScore / totalWeight;
    const writtenEvalScoreWeight = markingScheme.feedbackCommunicationEffectiveness / totalWeight;

    return gradingScore * gradingScoreWeight + writtenEvalScore * writtenEvalScoreWeight;
  }

  // calculates initial ES to save on student gradeHistory
  // subsequent requests for ES score are not recalculated
  static calcScore({
    activity,
    course,
    studentId,
    creationsToEvaluate,
    studentCreation,
  }: {
    activity: Activity;
    course: Course;
    studentId: string;
    creationsToEvaluate: Creation[];
    studentCreation: Creation;
  }) {
    const numEvalsCompleted = getNumEvaluationsCompleted(studentCreation, studentId);
    const zeroEvalsCompleted = numEvalsCompleted === 0;
    let evaluation = 0;
    let grading = 0;
    let writtenEvaluation = 0;

    if (!zeroEvalsCompleted && creationsToEvaluate) {
      let gradingScore;
      if (GradingScoreUtil.isGradingScoreScoredByTeacher(studentCreation)) {
        gradingScore = GradingScoreUtil.getTeacherGradingScore(studentCreation);
      } else {
        const maxScoreChange = GradingScoreUtil.getMaxScoreChangePerAssignment(isCalibrationActivity(activity));
        const gradingSkillsChange = GradingScoreUtil.getActivityGradingSkillsChange({
          creationsToEvaluate,
          maxGradingScoreChangePerActivity: maxScoreChange,
          studentId,
        });
        gradingScore = GradingScoreUtil.calcScorePerActivity(gradingSkillsChange, maxScoreChange, studentCreation);
      }

      let writtenEvalScore;
      if (WrittenEvalScoreUtil.isWrittenEvalScoredByTeacher(studentCreation)) {
        writtenEvalScore = WrittenEvalScoreUtil.getTeacherWrittenEvalScore(studentCreation);
      } else {
        const { totalChange: feedbackSkillsChange } = WrittenEvalScoreUtil.getFeedbackSkillsChangeFromCreations({
          creationsToEvaluate,
          studentId,
        });

        writtenEvalScore = WrittenEvalScoreUtil.calcScore({
          feedbackSkillsChange,
          numEvalsAssigned: studentCreation.numEvalsAssigned,
          numFOFReceived: FeedbackScoreUtil.getNumFOFReceived(creationsToEvaluate, studentId),
        });
      }

      const evalScore = this.evaluatorOverallScoreSingleActivity(gradingScore, writtenEvalScore, course, activity);

      evaluation = evalScore;
      grading = gradingScore;
      writtenEvaluation = writtenEvalScore;
    }

    return {
      evaluation,
      grading,
      writtenEvaluation,
    };
  }

  static isEvaluationScoredByTeacher(creation: Creation) {
    if (
      (creation.teacherGradingScore || creation.teacherGradingScore === 0) &&
      (creation.teacherWrittenEvaluationScore || creation.teacherWrittenEvaluationScore === 0)
    ) {
      return true;
    }
    return false;
  }

  static isEvaluationRemoved(evaluation: CreationScore) {
    return Boolean(evaluation.deletedAt);
  }

  static isPendingOrRejectedLateEvaluation(evaluation: CreationScore) {
    return Boolean(evaluation.lateEvaluation) && evaluation.lateEvaluation?.status !== LateEvaluationStatus.Accepted;
  }
}
