import { userRoles } from '@kritik/constants/user';
import { redirectToEdlinkSSO } from 'actions/edlink';
import EdlinkConstants from 'actions/edlink/constants';
import {
  SignupButtons,
  SignupContainer,
  SignupContent,
  SignupHeader,
  SignupMainTitle,
  SignupText,
} from 'components/AuthWrapper';
import RegionPicker from 'components/General/RegionPicker/index';
import { TranslatedText } from 'components/TranslatedText';
import TermsOfService from 'components/auth/TermsOfService';
import Button from 'components/buttons/Button';
import FormField from 'components/core/form/Field';
import FormFieldLabel from 'components/core/form/FieldLabel';
import NoticeBoard from 'components/layout/NoticeBoard';
import { localize } from 'locales';
import { useState } from 'react';
import { connect } from 'react-redux';
import { getEdlinkUserAccount } from 'services/edlink';
import * as RegionUtils from 'utils/region';
import { trackEvent } from 'utils/userEvents';

function LtiAdvantageUserView() {
  return (
    <>
      <SignupText weight="strong">
        <TranslatedText i18nKey="SSOActivation.LtiAdvantageUserView.SingleSignOnInstructions" />
      </SignupText>
      <SignupText color="dark">
        <TranslatedText i18nKey="SSOActivation.LtiAdvantageUserView.SpecialAccessInstructions" />
      </SignupText>
      <SignupText color="dark">
        <TranslatedText i18nKey="SSOActivation.LtiAdvantageUserView.FollowInstructions" />
      </SignupText>
      <div style={{ paddingLeft: '10px', marginBottom: '50px' }}>
        <p>
          <TranslatedText i18nKey="SSOActivation.LtiAdvantageUserView.Step1" />
        </p>
        <p>
          <TranslatedText i18nKey="SSOActivation.LtiAdvantageUserView.Step2" />
        </p>
        <p>
          <TranslatedText i18nKey="SSOActivation.LtiAdvantageUserView.Step3" />
        </p>
      </div>
    </>
  );
}

function NonLtiAdvantageUserView(props: any) {
  const [userHasLmsAccount, setUserHasLmsAccount] = useState(true);
  const handleSSOTrigger = async ({ emailToRegister, role, action, courseId }: any) => {
    if (!emailToRegister) {
      return;
    }
    const response = await getEdlinkUserAccount(emailToRegister);
    if (Array.isArray(response.integrations) && response.integrations.length === 0) {
      setUserHasLmsAccount(false);
    } else {
      if (action === EdlinkConstants.SSO_ACTION_SIGN_UP && role === userRoles.TEACHER) {
        trackEvent('Instructor Sign-up', null, {
          isSSO: true,
          instructorEmail: emailToRegister,
        });
      }
      await redirectToEdlinkSSO({
        userIntegrationsList: response.integrations,
        userEmail: emailToRegister,
        role,
        action,
        courseId,
      });
    }
  };

  const renderErrorNoLmsAccount = () => {
    if (userHasLmsAccount) {
      return null;
    }
    return (
      <NoticeBoard
        type="danger"
        title={localize({ message: 'SSOActivation.NonLtiAdvantageUserView.NoLmsAccountTitle' })}
      >
        <TranslatedText i18nKey="SSOActivation.NonLtiAdvantageUserView.NoLmsAccountContent" />
      </NoticeBoard>
    );
  };

  const getSSOAction = () => {
    return props.isSignIn ? EdlinkConstants.SSO_ACTION_SIGN_IN : EdlinkConstants.SSO_ACTION_SIGN_UP;
  };

  const getSSORole = () => {
    return props.isInstructor ? userRoles.TEACHER : userRoles.STUDENT;
  };
  return (
    <>
      <SignupText weight="strong">
        <TranslatedText i18nKey="SSOActivation.NonLtiAdvantageUserView.SingleSignOn" />
      </SignupText>
      <SignupText color="dark">
        <TranslatedText
          i18nKey={
            props.isSignIn
              ? 'SSOActivation.NonLtiAdvantageUserView.SignInInstructions'
              : 'SSOActivation.NonLtiAdvantageUserView.SignUpInstructions'
          }
        />
      </SignupText>
      <SignupButtons>
        <Button
          type="primary"
          onClick={async () => {
            await handleSSOTrigger({
              emailToRegister: props.emailToRegister || props.emailToSignIn,
              role: getSSORole(),
              action: getSSOAction(),
              courseId: props.courseId,
            });
          }}
        >
          <TranslatedText i18nKey="SSOActivation.NonLtiAdvantageUserView.SingleSignOnButton" />
        </Button>
      </SignupButtons>
      {renderErrorNoLmsAccount()}
      <div style={{ height: '100px' }} />
      <SignupText color="dark">
        <TranslatedText i18nKey="SSOActivation.NonLtiAdvantageUserView.SSOEnabledInstructions" />
      </SignupText>
    </>
  );
}

function SSOActivation(props: any) {
  const renderTitle = () => {
    const title = props.isInstructor
      ? localize({ message: 'SSOActivation.InstructorTitle' })
      : localize({ message: 'SSOActivation.StudentTitle' });
    return props.isNewUser ? (
      <SignupMainTitle>
        <div>
          <TranslatedText i18nKey="SSOActivation.Greeting" />
        </div>
        <div>{title}</div>
      </SignupMainTitle>
    ) : (
      <SignupMainTitle>
        <div>
          <TranslatedText i18nKey="SSOActivation.Greeting" />
        </div>
        <div>
          <TranslatedText
            i18nKey={props.isSignIn ? 'SSOActivation.SignInTitle' : 'SSOActivation.ExistingAccountTitle'}
          />
        </div>
      </SignupMainTitle>
    );
  };

  return (
    <SignupContainer graphic="rocket">
      <SignupContent>
        {renderTitle()}
        <SignupHeader size="xl">
          <TranslatedText i18nKey={props.isSignIn ? 'SSOActivation.SignIn' : 'SSOActivation.SignUp'} />
        </SignupHeader>
        <FormField>
          <FormFieldLabel
            color="light"
            label={localize({ message: 'SSOActivation.RegionLabel' })}
            id="region-select-field"
          />
          <RegionPicker value={RegionUtils.getRegion()} disabled />
        </FormField>
        {props.isLtiAdvantageUser ? (
          <LtiAdvantageUserView />
        ) : (
          <NonLtiAdvantageUserView
            isSignIn={props.isSignIn}
            isInstructor={props.isInstructor}
            emailToRegister={props.emailToRegister}
            emailToSignIn={props.emailToSignIn}
          />
        )}
        <TermsOfService />
      </SignupContent>
    </SignupContainer>
  );
}

SSOActivation.defaultProps = {
  isInstructor: false,
  isSignIn: false,
  courseId: '',
};

export default connect(null, {})(SSOActivation);
