import * as creationStatus from '@kritik/utils/creation/status';
import FlagIcon from '@material-ui/icons/Flag';
import StatusLabel from 'components/Creation/StatusLabel';
import { TranslatedText } from 'components/TranslatedText';

const STATUSES = {
  DANGER: 'danger',
  RESOLVED: 'resolved',
};

export const FlagLabel = ({ label, status, count }: any) => {
  return (
    <StatusLabel status={status} count={count} icon={<FlagIcon fontSize="inherit" color="inherit" />} label={label} />
  );
};

const Labels = (props: any) => {
  if (creationStatus.isCommentFlagged(props.evaluation)) {
    return (
      <FlagLabel
        label={<TranslatedText i18nKey="Creation.Flag.Label.ReportedAsInappropriate" />}
        status={STATUSES.DANGER}
      />
    );
  }
  if (creationStatus.isCommentRemoved(props.evaluation)) {
    return <FlagLabel label={<TranslatedText i18nKey="Creation.Flag.Label.Resolved" />} status={STATUSES.DANGER} />;
  }
  if (creationStatus.isCommentFlagDismissed(props.evaluation)) {
    return <FlagLabel label={<TranslatedText i18nKey="Creation.Flag.Label.Resolved" />} status={STATUSES.RESOLVED} />;
  }
  return null;
};

export default Labels;
