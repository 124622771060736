import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormGroup,
  FormHelperText,
  makeStyles,
} from '@material-ui/core';
import CourseCodeField from 'components/Course/Edit/CourseDetails/CourseCodeField';
import CourseNameField from 'components/Course/Edit/CourseDetails/CourseNameField';
import CourseSectionField from 'components/Course/Edit/CourseDetails/CourseSectionField';
import { CourseTermField } from 'components/Course/Edit/CourseDetails/CourseTermField';
import CodeSectionFieldLayout from 'components/Course/Edit/CourseDetails/Layout';
import { TranslatedText } from 'components/TranslatedText';
import Button from 'components/buttons/Button';
import Select from 'components/core/form/SelectField';
import Checkbox from 'components/core/input/Checkbox';
import { localize } from 'locales';
import { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { formatPartialCourseBreadcrumb } from 'utils/format';

type CourseParams = {
  title: string;
  courseId: string;
  section: string;
  code: string;
  formattedTitle?: string;
};

type LtiDuplicateFormParams = CourseParams & { includeActivities: boolean };

const defaultValues = {
  courseId: null,
  title: '',
  code: '',
  section: '',
  includeActivities: true,
};

const getInitialValuesFromCourse = (course: CourseParams) => {
  const { courseId, title, code, section } = course;
  return {
    courseId,
    title: `${title} - Copy`,
    code: code ?? '',
    section: section ?? '',
    includeActivities: true,
  };
};

const useStyles = makeStyles({
  root: {
    justifyContent: 'flex-start',
    paddingLeft: '0',
    paddingRight: '0',
    marginTop: '1.5rem',
  },

  activityIncludeSubTitle: {
    marginLeft: '1.9rem',
  },
});

const LtiLaunchDuplicateDialog = ({
  coursesList,
  open,
  onClose,
  ltiCourseName,
  onSubmit,
}: {
  coursesList: any[];
  open: boolean;
  onClose: () => void;
  ltiCourseName: string;
  onSubmit: (data: LtiDuplicateFormParams) => void;
}) => {
  const [initialValues, setInitialValues] = useState(defaultValues);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const classes = useStyles();

  const options = coursesList.map((course, i) => {
    const courseValue: CourseParams = {
      courseId: course._id,
      formattedTitle: formatPartialCourseBreadcrumb(course),
      code: course.code,
      section: course.section,
      title: course.title,
    };
    return {
      label: formatPartialCourseBreadcrumb(course),
      value: courseValue,
      testid: `course-${i}`,
    };
  });

  const handleSelectCourse = (e: any) => {
    const selectedCourse = e.target.value;
    const courseValue: LtiDuplicateFormParams = getInitialValuesFromCourse(selectedCourse);
    setInitialValues(courseValue);
    setSelectedCourse(selectedCourse);
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        return onClose();
      }}
      aria-labelledby="duplicate-dialog-title"
      aria-describedby="duplicate-dialog-description"
    >
      <DialogTitle id="duplicate-dialog-title">
        {localize({ message: 'LtiLaunchBar.DuplicateDialog.Title' })}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="duplicate-dialog-description">
          {localize({ message: 'LtiLaunchBar.DuplicateDialog.Description' })}
        </DialogContentText>
        <DialogContentText className="banner">
          <i className="fa fa-info-circle" style={{ margin: 10, color: '#4844a3' }} />
          <p style={{ paddingBottom: 10 }}>
            <TranslatedText i18nKey="LtiLaunchBar.DuplicateDialog.Info" values={{ ltiCourseName }} />
          </p>
        </DialogContentText>
        <Select
          testid="select-course-to-duplicate"
          label={localize({ message: 'LtiLaunchBar.DuplicateDialog.SelectorLabel' })}
          onChange={(e: any) => {
            return handleSelectCourse(e);
          }}
          value={selectedCourse?.formattedTitle}
          options={options}
          placeholder={localize({ message: 'LtiLaunchBar.DuplicateDialog.SelectorDefaultValue' })}
          renderValue={() => {
            if (selectedCourse) {
              return selectedCourse?.formattedTitle;
            }
            return localize({ message: 'LtiLaunchBar.DuplicateDialog.SelectorDefaultValue' });
          }}
        />
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          mutators={{
            setValue: ([field, value], state, { changeValue }) => {
              changeValue(state, field, () => {
                return value;
              });
            },
          }}
          render={({ handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit}>
                <CourseNameField label={localize({ message: 'LtiLaunchBar.DuplicateDialog.FieldLabel.Name' })} />
                <CodeSectionFieldLayout className="course-details__code-section-field-layout">
                  <CourseCodeField />
                  <CourseSectionField />
                </CodeSectionFieldLayout>
                <CourseTermField />
                <FormGroup>
                  <Field type="checkbox" name="includeActivities">
                    {({ input }) => {
                      return (
                        <Checkbox
                          label={localize({
                            message: 'LtiLaunchBar.DuplicateDialog.IncludeActivities',
                          })}
                          isChecked={input.checked}
                          onChange={input.onChange}
                          type="primary"
                          testid="duplicate-course-include-activities-check"
                        />
                      );
                    }}
                  </Field>
                  <FormHelperText
                    classes={{
                      root: classes.activityIncludeSubTitle,
                    }}
                  >
                    {localize({ message: 'LtiLaunchBar.DuplicateDialog.HelperText' })}
                  </FormHelperText>
                </FormGroup>

                <DialogActions
                  classes={{
                    root: classes.root,
                  }}
                >
                  <Button type="primary" inputType="submit" testid="duplicate-course">
                    {localize({ message: 'LtiLaunchBar.DuplicateDialog.DuplicateBtn' })}
                  </Button>
                  <Button
                    onClick={() => {
                      return onClose();
                    }}
                    type="secondary"
                    autoFocus
                  >
                    {localize({ message: 'LtiLaunchBar.DuplicateDialog.CancelBtn' })}
                  </Button>
                </DialogActions>
              </form>
            );
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

export default LtiLaunchDuplicateDialog;
