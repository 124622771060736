import { createContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { listSpotlights, selectSpotlights } from 'redux/spotlight';

export const SpotlightContext = createContext(null);

const SpotlightProvider = (props: any) => {
  useEffect(() => {
    props.listSpotlights({ course: props.courseId, activity: props.activityId });
  }, [props.courseId, props.activityId]);

  return <SpotlightContext.Provider value={props.spotlights}>{props.children}</SpotlightContext.Provider>;
};

const mapStateToProps = (state: any) => {
  return {
    spotlights: selectSpotlights(state),
  };
};

SpotlightProvider.defaultProps = {
  courseId: null,
  activityId: null,
  spotlights: [],
};
export default connect(mapStateToProps, {
  listSpotlights,
})(SpotlightProvider);
