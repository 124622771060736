import TabMenu from 'components/layout/TabMenu';
import TabMenuItem from 'components/layout/TabMenu/Item';
import { useScreenSize } from 'hooks/useMatchMedia';
import ArrowRight from 'images/icons/svg/ArrowRight';
import { localize } from 'locales';
import React, { useState } from 'react';

const MarkingSchemeView = (props: any) => {
  const { isMobileScoreBreakdown } = useScreenSize();
  const renderSeparator = () => {
    return (
      <div className="marking-scheme-layout__separator">
        <ArrowRight />
      </div>
    );
  };

  const renderNodes = () => {
    if (isMobileScoreBreakdown) {
      return props.nodeList.map((node: any, index: any) => {
        let separator = null;
        if (index < props.nodeList.length - 1) {
          separator = renderSeparator();
        }
        return (
          <React.Fragment key={index}>
            <div className="marking-scheme-layout__node">{node}</div>
            {separator}
          </React.Fragment>
        );
      });
    }
    return <MobileMarkingSchemeView nodeList={props.nodeList} />;
  };
  return <div className="marking-scheme-layout">{renderNodes()}</div>;
};

const MobileMarkingSchemeView = ({ nodeList }) => {
  const [activeTab, setActiveTab] = useState(0);
  const LABEL = [
    {
      id: 0,
      title: localize({ message: 'MarkingSchemeLayout.Create' }),
    },
    {
      id: 1,
      title: localize({ message: 'MarkingSchemeLayout.Evaluate' }),
    },
    {
      id: 2,
      title: localize({ message: 'MarkingSchemeLayout.Feedback' }),
    },
  ];

  return (
    <>
      <div>
        <TabMenu
          labels={LABEL}
          onSelect={(idx: number) => {
            setActiveTab(idx);
          }}
          activeLabel={activeTab}
        />
        <TabMenuItem>{nodeList[activeTab]}</TabMenuItem>
      </div>
    </>
  );
};

export default MarkingSchemeView;
