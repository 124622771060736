import { resetAsync } from 'actions/async';
import { setAccessibilityMessage } from 'actions/messages';
import { openGlobalModal } from 'actions/modals';
import { selectCourse } from 'actions/select';
import { getStudentsByCourse } from 'actions/students';
import { GroupManager } from 'components/GroupManager';
import CsvUploadView from 'components/GroupManager/CsvUploadView';
import Collaborators from 'components/RosterManager/Collaborators';
import StudentList from 'components/RosterManager/Enrolled';
import PendingList from 'components/RosterManager/Pending';
import StudentInviteModal from 'components/RosterManager/StudentInviteModal';
import SyncRosterButton from 'components/RosterManager/SyncRosterPopup/SyncRosterButton';
import { TranslatedText } from 'components/TranslatedText';
import Button from 'components/buttons/Button';
import { PageHeader } from 'components/layout';
import PageContainer from 'components/layout/PageContainer';
import TabMenu from 'components/layout/TabMenu';
import TabMenuItem from 'components/layout/TabMenu/Item';
import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { selectIsShowUploadView } from 'redux/groupManager';
import { getCourse } from 'selectors/course';
import { GET_STUDENTS_BY_COURSE } from 'types';
import { isCourseConnected } from 'utils/lms';
import { RouterProp, withRouter } from 'utils/withRouter';

import Tooltip from '@material-ui/core/Tooltip';
import { getEdlinkIntegrationsByInstitution } from 'actions/edlink';
import CollaboratorInviteModal, { COLLABORATOR_MODAL_ID } from 'components/CollaboratorManager/CollaboratorInviteModal';
import { inviteCollaborators } from 'hooks/course';
import { localize } from 'locales';
import { useParams } from 'react-router';
import { getCourseFirstSuccessfulSync } from 'services/course';

const MENU_OPTIONS = [
  { id: 'students', title: localize({ message: 'RosterManager.Tab.Students' }) },
  { id: 'pending', title: localize({ message: 'RosterManager.Tab.PendingStudents' }) },
  { id: 'collaborators', title: localize({ message: 'RosterManager.Tab.Collaborators' }) },
  { id: 'groups', title: localize({ message: 'RosterManager.Tab.Groups' }) },
];

const mapStateToProps = (state: any) => {
  return {
    loadingBar: state.loadingBar,
    entities: state.entities,
    user: state.user,
    course: getCourse(state),
    getStudentsByCourseSuccess: state.async.GET_STUDENTS_BY_COURSE.success,
    isShowUploadView: selectIsShowUploadView(state),
  };
};

type StudentManagerState = {
  copied: boolean;
  dropdownText: string;
  sortAscending: boolean;
  isInviting: boolean;
  removeStudentWarning: boolean;
  emailList: string;
  confirm: boolean;
  submitting: boolean;
  selectedTab: string;
  pendingInvites: any[];
  studentList: any[];
  hasFirstSuccessfulSync: boolean;
};

type StudentManagerProps = {
  course: any;
  user: any;
  getStudentsByCourseSuccess: boolean;
  isShowUploadView: boolean;
  selectCourse: (courseId: string) => void;
  openGlobalModal: (props: any) => void;
  getStudentsByCourse: (props: any) => void;
  resetAsync: (props: any) => void;
  inviteCollaboratorsMutation: any;
  getEdlinkIntegrationsByInstitution: (props: any) => void;
  router: RouterProp;
};

class StudentManagerComponent extends Component<StudentManagerProps, StudentManagerState> {
  constructor(props: StudentManagerProps) {
    super(props);
    this.state = {
      copied: false,
      dropdownText: 'Enroll date',
      sortAscending: true,
      isInviting: false,
      removeStudentWarning: false,
      emailList: '',
      confirm: false,
      submitting: false,
      selectedTab: this.getActiveTab(),
      pendingInvites: [],
      studentList: [],
      hasFirstSuccessfulSync: false,
    };
  }

  getActiveTab() {
    const path = this.props.router.location.query.tab;
    let activeTab = '';
    switch (path) {
      case 'students':
      case 'pending':
      case 'collaborators':
      case 'groups':
        activeTab = path;
        break;
      default:
        activeTab = 'students';
    }

    return activeTab;
  }

  async componentDidMount() {
    this.props.selectCourse(this.props.router.params.courseId);
    this.props.getStudentsByCourse({
      courseId: this.props.router.params.courseId,
    });

    const data = await getCourseFirstSuccessfulSync({ course: this.props.course });
    this.setState({ hasFirstSuccessfulSync: data?.hasSuccessfulSync });
  }

  componentDidUpdate(prevProps: any) {
    if (
      this.props.router.params.courseId !== prevProps.router.params.courseId ||
      this.props.router.location.query.tab !== prevProps.router.location.query.tab
    ) {
      this.props.selectCourse(this.props.router.params.courseId);
      this.props.getStudentsByCourse({
        courseId: this.props.router.params.courseId,
      });
      this.setState({ selectedTab: this.getActiveTab() });
    }
  }

  componentWillUnmount() {
    this.props.resetAsync(GET_STUDENTS_BY_COURSE);
  }

  toggleInviteInput() {
    const props = {
      id: 'student-invite-modal',
    };
    this.props.openGlobalModal({ props });
  }

  changeView = (view: any) => {
    this.setState({ selectedTab: view });
  };

  isConnectedForGradeSync = () => {
    return isCourseConnected(this.props.course);
  };

  isLockedForInvitingCollaborators = () => {
    return isCourseConnected(this.props.course) && !this.props.course.lms.allowNonLmsCollaborators;
  };

  renderContent = () => {
    if (!this.props.getStudentsByCourseSuccess) {
      return;
    }
    switch (this.state.selectedTab) {
      case 'pending':
        return (
          <>
            <Helmet
              title={localize({
                message: 'RosterManager.PendingStudents.PageTitle',
              })}
            />
            <PendingList
              usingGradeSync={this.isConnectedForGradeSync()}
              hasFirstSuccessfulSync={this.state.hasFirstSuccessfulSync}
            />
          </>
        );
      case 'collaborators':
        return (
          <>
            <Helmet
              title={localize({
                message: 'RosterManager.Collaborators.PageTitle',
              })}
            />
            <Collaborators />
          </>
        );
      case 'groups':
        return (
          <>
            <Helmet
              title={localize({
                message: 'RosterManager.Groups.PageTitle',
              })}
            />
            <GroupManager />
          </>
        );
      case 'students':
      default:
        return (
          <>
            <Helmet
              title={localize({
                message: 'RosterManager.Students.PageTitle',
              })}
            />
            <StudentList
              usingGradeSync={this.isConnectedForGradeSync()}
              hasFirstSuccessfulSync={this.state.hasFirstSuccessfulSync}
            />
          </>
        );
    }
  };

  render() {
    const { course, inviteCollaboratorsMutation } = this.props;
    if (!course) {
      return null;
    }
    const isCourseConnected = this.isConnectedForGradeSync();

    if (course && course.user && !(course.userRole === 'instructor')) {
      this.props.router.push('/');
    }

    const inviteLocked = this.isConnectedForGradeSync();
    const inviteLockedForCollaborators = this.isLockedForInvitingCollaborators();

    const renderInviteMenu = (
      <div className="student-manager__invite-menu">
        {isCourseConnected && (
          <SyncRosterButton
            usingGradeSync={this.isConnectedForGradeSync()}
            onRosterSync={() => void this.setState({ hasFirstSuccessfulSync: true })}
          />
        )}
        <Tooltip
          placement="bottom-end"
          title={isCourseConnected ? <TranslatedText i18nKey="RosterManager.Tooltip.Text.Students" /> : ''}
          classes={{ tooltip: 'student-manager__no-invite-tooltip' }}
        >
          <Button
            data-testid="invite-students"
            type="primary"
            onClick={() => {
              return this.toggleInviteInput();
            }}
            unavailable={inviteLocked}
            style={inviteLocked ? { pointerEvents: 'none' } : {}}
            disabled={inviteLocked}
          >
            <TranslatedText i18nKey="RosterManager.InviteStudents" />
          </Button>
        </Tooltip>
        <Tooltip
          placement="bottom-end"
          title={
            inviteLockedForCollaborators ? <TranslatedText i18nKey="RosterManager.Tooltip.Text.Collaborators" /> : ''
          }
          classes={{ tooltip: 'student-manager__no-invite-tooltip' }}
        >
          <Button
            testid="invite-collaborator-btn"
            type="primary"
            onClick={() => {
              const props = {
                id: COLLABORATOR_MODAL_ID,
              };
              this.props.openGlobalModal({ props });
            }}
            unavailable={inviteLockedForCollaborators}
            style={inviteLockedForCollaborators ? { pointerEvents: 'none' } : {}}
            disabled={inviteLockedForCollaborators}
          >
            <TranslatedText i18nKey="CourseDetails.CollaboratorManager.Buttons.InviteCollaborators" />
          </Button>
        </Tooltip>
      </div>
    );

    return (
      <React.Fragment>
        <div style={{ display: this.props.isShowUploadView ? 'none' : 'block' }}>
          <PageContainer>
            <PageHeader title={<TranslatedText i18nKey="RosterManager.PageHeader" />}>{renderInviteMenu}</PageHeader>
            <TabMenu
              labels={MENU_OPTIONS}
              onSelect={(view: any) => {
                return this.changeView(view);
              }}
              activeLabel={this.state.selectedTab}
            />
            <TabMenuItem>{this.renderContent()}</TabMenuItem>
            <StudentInviteModal />
            <CollaboratorInviteModal inviteCollaborators={inviteCollaboratorsMutation} course={course} />
          </PageContainer>
        </div>
        {this.props.isShowUploadView && (
          <div className="activity-edit-rubric">
            <CsvUploadView />
          </div>
        )}
      </React.Fragment>
    );
  }
}

const StudentManager = (props: any) => {
  const { courseId } = useParams();
  const inviteCollaboratorsMutation = inviteCollaborators(courseId);

  return <StudentManagerComponent {...props} inviteCollaboratorsMutation={inviteCollaboratorsMutation} />;
};

export default withRouter(
  connect(mapStateToProps, {
    setAccessibilityMessage,
    selectCourse,
    openGlobalModal,
    getStudentsByCourse,
    resetAsync,
    getEdlinkIntegrationsByInstitution,
  })(StudentManager)
);
