import * as statusUtil from '@kritik/utils/stage';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMenu from 'components/ActivityCard/Footer/Menu';
import StatusProgress from 'components/ActivityCard/Footer/StatusProgress';
import { useUserRoleInCourse } from 'hooks/course';
import { withRouter } from 'utils/withRouter';

const CardFooter = (props: any) => {
  const { isInstructorInCourse, isStudentInCourse } = useUserRoleInCourse();
  const redirectToActivity = () => {
    props.redirectToActivity();
  };

  const redirectToSchedule = () => {
    props.router.push(`/course/${props.activity.course}/assignment/${props.activity._id}/schedule`);
  };

  const getRedirectFunction = () => {
    if (isInstructorInCourse && !statusUtil.isScheduled(props.activity)) {
      return redirectToSchedule;
    }
    return redirectToActivity;
  };

  const renderMenu = () => {
    if (isStudentInCourse) {
      return null;
    }
    return (
      <div className="activity-progress__menu">
        <CardMenu activity={props.activity} />
      </div>
    );
  };
  return (
    <div className="activity-progress">
      <CardActionArea
        disableRipple
        onClick={() => {
          return getRedirectFunction()();
        }}
      >
        <div className="activity-progress__label">
          <StatusProgress activity={props.activity} />
        </div>
      </CardActionArea>
      {renderMenu()}
    </div>
  );
};

export default withRouter(CardFooter);
