import classNames from 'classnames';
import React from 'react';

const COLOR = {
  CYAN: 'cyan',
  TAN: 'tan',
  RED: 'red',
};

type markingSchemeHeaderProps = {
  icon: React.ReactNode;
  title: string;
  description: string;
  color: string;
};

const Header = (props: markingSchemeHeaderProps) => {
  const renderIcon = () => {
    return <div className="marking-scheme__header-icon">{props.icon}</div>;
  };

  const renderTitle = () => {
    return <h4 className="marking-scheme__header-title">{props.title}</h4>;
  };

  const renderDescription = () => {
    return <div className="marking-scheme__header-description">{props.description}</div>;
  };
  const headerClasses = classNames('marking-scheme__header', {
    'marking-scheme__header--cyan': props.color === COLOR.CYAN,
    'marking-scheme__header--tan': props.color === COLOR.TAN,
    'marking-scheme__header--red': props.color === COLOR.RED,
  });

  return (
    <div className={headerClasses}>
      {renderIcon()}
      {renderTitle()}
      {renderDescription()}
    </div>
  );
};

Header.defaultProps = {
  color: COLOR.CYAN,
};

export default Header;
