import { RowSeparator } from 'components/layout/SimpleTable/Row';

export const Table = (props: any) => {
  const renderSeparator = () => {
    if (!props.renderSeparator) {
      return null;
    }
    return <RowSeparator />;
  };
  return (
    <table className="simple-table" aria-labelledby={props['aria-labelledby']}>
      {renderSeparator()}
      {props.children}
    </table>
  );
};

Table.defaultProps = {
  renderSeparator: false,
};

export default Table;
