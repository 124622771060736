import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { closeInfoModal } from 'actions/modals';
import { connect } from 'react-redux';

function ModalContent({ content }: any) {
  if (!content) {
    return null;
  }
  if (typeof content === 'string') {
    return <DialogContentText style={{ color: 'black' }}>{content}</DialogContentText>;
  }
  return content;
}

// For general info, it is possible to merge this into error modal
function InfoModal({ title, content, open, actions, messageId, onClose, closeInfoModal }: any) {
  const handleOnClose = () => {
    if (onClose) {
      onClose();
    }
    closeInfoModal();
  };
  return (
    <Dialog
      open={open}
      onClose={handleOnClose}
      id={messageId}
      maxWidth="md"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <ModalContent content={content} />
      </DialogContent>
      <DialogActions className="info-dialog-actions">
        {actions.map((action: any) => {
          return action;
        })}
      </DialogActions>
    </Dialog>
  );
}

InfoModal.defaultProps = {
  open: false,
};

const mapStateToProps = (state: any) => {
  const { infoModal } = state.modal;
  return {
    ...infoModal,
  };
};

export default connect(mapStateToProps, { closeInfoModal })(InfoModal);
