import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { updatePreferences } from 'actions/users';
import { TranslatedText } from 'components/TranslatedText';
import { InlineInformation } from 'components/layout';
import NoticeBoard from 'components/layout/NoticeBoard';
import ModalAction from 'components/modals/Components/Action';
import ModalContent from 'components/modals/Components/Content';
import ModalHeader from 'components/modals/Components/Header';
import Modal from 'components/modals/Components/Modal';
import { localize } from 'locales';
import { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getErrorMessageFromResponse } from 'utils/error';

type State = any;

class StudentPreferences extends Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {
      receiveEmails: true,
      receiveSMS: true,
      receiveSubmissionConfirmEmails: true,
      error: '',
    };
  }

  componentDidMount() {
    if ((this.props as any).student) {
      const { receiveEmails, receiveSMS, receiveSubmissionConfirmEmails } = (this.props as any).student
        .notificationSettings;
      this.setState({ receiveEmails, receiveSMS, receiveSubmissionConfirmEmails });
    }
  }

  handleNotificationChange(field: any) {
    this.setState({ [field]: !this.state[field] });
  }

  handleSubmit(e: any) {
    const data = { studentId: (this.props as any).student._id };
    const { receiveEmails, receiveSMS, receiveSubmissionConfirmEmails } = this.state;

    (data as any).notificationSettings = {
      receiveEmails,
      receiveSMS,
      receiveSubmissionConfirmEmails,
    };

    (this.props as any)
      .updatePreferences(data)
      .then(() => {
        // @ts-expect-error TS(2339) FIXME: Property 'onSubmit' does not exist on type 'Readon... Remove this comment to see the full error message
        this.props.onSubmit();
      })
      .catch((err: any) => {
        this.setState({ error: getErrorMessageFromResponse(err) });
      });
  }

  hasPhoneNumber() {
    return (this.props as any).user.phoneNumber && (this.props as any).user.phoneNumber != '';
  }

  handleCancel() {
    this.setState({ error: '' });
    (this.props as any).onSubmit();
  }

  render() {
    return (
      <Modal isOpen={(this.props as any).isOpen} onClose={(this.props as any).onClose}>
        <ModalHeader title={<TranslatedText i18nKey="Course.CoursePreferences" />} />
        <ModalContent>
          <h3 className="new-header-3">
            <TranslatedText i18nKey="StudentPreferences.Notifications" />
          </h3>
          <div className="switch-container">
            <FormControlLabel
              control={
                <Switch
                  checked={this.state.receiveEmails}
                  onChange={() => {
                    return this.handleNotificationChange('receiveEmails');
                  }}
                  color="primary"
                />
              }
              labelPlacement="start"
              label={<TranslatedText i18nKey="StudentPreferences.ReceiveEmailNotifications" />}
            />
          </div>
          <div className="switch-container">
            <FormControlLabel
              control={
                <Switch
                  checked={this.state.receiveSMS}
                  onChange={() => {
                    return this.handleNotificationChange('receiveSMS');
                  }}
                  color="primary"
                  disabled={!this.hasPhoneNumber()}
                />
              }
              labelPlacement="start"
              label={<TranslatedText i18nKey="StudentPreferences.ReceiveSMSNotifications" />}
            />
          </div>
          <div className="switch-container">
            <FormControlLabel
              control={
                <Switch
                  checked={this.state.receiveSubmissionConfirmEmails}
                  onChange={() => {
                    return this.handleNotificationChange('receiveSubmissionConfirmEmails');
                  }}
                  color="primary"
                />
              }
              labelPlacement="start"
              label={<TranslatedText i18nKey="StudentPreferences.ReceiveSubmissionConfirmEmails" />}
            />
          </div>
          {!this.hasPhoneNumber() && (
            <NoticeBoard type="information" title={localize({ message: 'StudentPreferences.AddNumberMessage.Title' })}>
              <Link to="/settings/account">
                <TranslatedText i18nKey="StudentPreferences.AddNumberMessage.Description" />
              </Link>
            </NoticeBoard>
          )}
          {this.state.error && <InlineInformation type="danger">{this.state.error}</InlineInformation>}
        </ModalContent>

        <ModalAction
          actions={[
            {
              label: localize({ message: 'Save' }),
              type: 'primary',
              onClick: this.handleSubmit.bind(this),
            },
            {
              label: localize({ message: 'Cancel' }),
              type: 'secondary',
              onClick: this.handleCancel.bind(this),
            },
          ]}
        />
      </Modal>
    );
  }
}

export default connect(null, {
  updatePreferences,
})(StudentPreferences);
