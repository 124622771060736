import { Card, CardContent } from '@material-ui/core';
import { navigateToActivityPage } from 'actions/activity';
import CardFooter from 'components/ActivityCard/Footer/Main';
import CardHeader from 'components/ActivityCard/Header/Header';
import CardNotification from 'components/ActivityCard/Notifications/List';
import StatusList from 'components/ActivityCard/Schedule/List';
import { TABLE_TYPE_OPTIONS } from 'components/CreationList/constant';
import { useUserRoleInCourse } from 'hooks/course';
import isEqual from 'lodash/isEqual';
import React from 'react';
import { connect } from 'react-redux';
import { setCurrentPage, setTableType } from 'redux/creationTable/actions';

const ActivityCard = (props: any) => {
  const { isStudentInCourse } = useUserRoleInCourse();
  const redirectToActivity = () => {
    props.setCurrentPage(1);
    props.setTableType(TABLE_TYPE_OPTIONS.PROGRESS);
    props.navigateToActivityPage({
      courseId: props.activity.course,
      assignmentId: props.activity._id,
    });
  };

  const renderHeader = () => {
    return <CardHeader activity={props.activity} />;
  };
  const renderNotification = () => {
    return (
      <div className="activity-card__notification">
        <CardNotification activity={props.activity} creation={props.activity.userAssignment} />
      </div>
    );
  };
  return (
    <Card className="activity-card" key={props.activity._id}>
      <div
        className="activity-card__action-area"
        onClick={() => {
          return redirectToActivity();
        }}
      >
        {renderNotification()}
        {renderHeader()}
        <CardContent className="activity-card__stage-list">
          <StatusList activity={props.activity} isStudent={isStudentInCourse} />
        </CardContent>
      </div>
      <CardFooter activity={props.activity} redirectToActivity={redirectToActivity} />
    </Card>
  );
};

const mapStateToProps = (state: any) => {
  return {};
};

export default connect(mapStateToProps, { navigateToActivityPage, setCurrentPage, setTableType })(
  React.memo(ActivityCard, (prev, next) => {
    return isEqual(prev, next);
  })
);
