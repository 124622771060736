import { getNumEvaluationsCompleted, getNumEvaluationsToDo } from '@kritik/utils/creation/evaluation';
import { isScoreViewCompleted } from '@kritik/utils/creation/score';
import { CreationScoreUtil } from '@kritik/utils/grade';
import { getPassLevelName } from '@kritik/utils/rubric';
import { TranslatedText } from 'components/TranslatedText';
import NoticeBoard from 'components/layout/NoticeBoard';
import { Check, Close } from 'images/icons/fa';
import { connect } from 'react-redux';
import { getMySubmission } from 'selectors/creation';

const PassCriteria = ({ assignment, rubric, mySubmission }: any) => {
  if (!assignment || !rubric || assignment.numericGrading) {
    return null;
  }
  const shouldCompleteAllEvaluation = !!assignment.user.institution.failStudentsThatDontDoAllEvaluations;
  let isAllEvaluationsCompleted = true;
  let showCheckIcons = false;
  let isCreationPass = true;
  if (mySubmission) {
    showCheckIcons = isScoreViewCompleted(mySubmission);
    const numOfEvaluationCompleted = getNumEvaluationsCompleted(mySubmission, mySubmission.student._id);
    const numOfEvaluationAssigned = getNumEvaluationsToDo(mySubmission, mySubmission.student._id);
    isAllEvaluationsCompleted = numOfEvaluationCompleted === numOfEvaluationAssigned;
    isCreationPass = CreationScoreUtil.validatePassFailCriteria(assignment.rubric, mySubmission);
  }
  let completeAllEvaluationText;
  let passCriterialist;
  if (showCheckIcons) {
    const icon = isCreationPass ? (
      <Check className="criteria-successful-icon" />
    ) : (
      <Close className="criteria-danger-icon" />
    );
    const evaluationCompleteIcon = isAllEvaluationsCompleted ? (
      <Check className="criteria-successful-icon" />
    ) : (
      <Close className="criteria-danger-icon" />
    );
    completeAllEvaluationText = shouldCompleteAllEvaluation ? (
      isAllEvaluationsCompleted ? (
        <p>
          {evaluationCompleteIcon} <TranslatedText i18nKey="PassCriteria.CompletePeerEvaluation" />
        </p>
      ) : (
        <p>
          {evaluationCompleteIcon} <TranslatedText i18nKey="PassCriteria.CompletePeerEvaluation" />
        </p>
      )
    ) : null;
    passCriterialist =
      rubric.passFailCondition === 'Criteria' ? (
        <p>
          {icon}{' '}
          <TranslatedText
            i18nKey="PassCriteria.ObtainMinimumCriteria"
            values={{ level: getPassLevelName(rubric), rule: rubric.passCriteriaRule }}
          />
        </p>
      ) : (
        <p>
          {icon}{' '}
          <TranslatedText
            i18nKey="PassCriteria.ObtainMinimumPercentage"
            values={{ percentage: rubric.minimumPercentage }}
          />
        </p>
      );
  } else {
    completeAllEvaluationText = shouldCompleteAllEvaluation ? (
      <li>
        <TranslatedText i18nKey="PassCriteria.CompletePeerEvaluation" />
      </li>
    ) : null;
    passCriterialist =
      rubric.passFailCondition === 'Criteria' ? (
        <li>
          <TranslatedText
            i18nKey="PassCriteria.ObtainMinimumCriteria"
            values={{ level: getPassLevelName(rubric), rule: rubric.passCriteriaRule }}
          />
        </li>
      ) : (
        <li>
          <TranslatedText
            i18nKey="PassCriteria.ObtainMinimumPercentage"
            values={{ percentage: rubric.minimumPercentage }}
          />
        </li>
      );
  }
  return (
    <NoticeBoard type="information">
      <p>
        <TranslatedText i18nKey="PassCriteria.RequirementsToPass" />
      </p>
      {passCriterialist}
      {completeAllEvaluationText}
    </NoticeBoard>
  );
};

const mapStateToProps = (state: any) => {
  return {
    mySubmission: getMySubmission(state, state.selected.assignmentId),
  };
};

export default connect(mapStateToProps, {})(PassCriteria);
