import { Card } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { changeCoursePriceAndCurrency, updateHubspotDeal } from 'actions/admin';
import axios from 'axios';
import { FormSelection } from 'components/Form/FormComponents';
import { Button } from 'components/buttons';
import FormNumberInput from 'components/core/form/NumberField';
import { InlineInformation } from 'components/layout';
import fx from 'money';
import React, { useState } from 'react';
import { connect } from 'react-redux';

const HUBSPOT_TO_KRITIK = 0;
const KRITIK_TO_HUBSPOT = 1;

const PriceSyncCard = ({
  deal,
  course,
  courseCurrency,
  coursePrice,
  updateHubspotDeal,
  changeCoursePriceAndCurrency,
}: any) => {
  const [syncProperty, setSyncProperty] = useState(null);
  const [syncValue, setSyncValue] = useState(0);
  const [kritikSyncValue, setKritikSyncValue] = useState(0);
  const [dir, setDir] = useState(HUBSPOT_TO_KRITIK);
  const [loadingSync, setLoadingSync] = useState(false);
  const [syncSuccess, setSyncSuccess] = useState(false);
  const [fieldOptions, setFieldOptions] = useState([]);
  const [selectedDeal, setSelectedDeal] = useState(null);
  const [matchCurrency, setMatchCurrency] = useState(true);

  const convert = async (from: any, to: any, amount: any, match = true) => {
    if (!courseCurrency || !coursePrice) {
      return 0;
    }
    if (!match) {
      return amount;
    }
    fx.base = 'EUR';
    // @ts-expect-error TS(2304) FIXME: Cannot find name 'App'.
    const exchangeRatesAccessKey = App.config.get('exchangeRatesAccessKey');
    const res = await axios.get(`http://api.exchangeratesapi.io/v1/latest?access_key=${exchangeRatesAccessKey}`);
    fx.rates = res.data.rates;
    const rate = fx.convert(1, { from: 'USD', to: 'EUR' });
    return Number(fx.convert(amount, { from, to })).toFixed(2);
  };

  const fetchFieldOptions = () => {
    if (!deal) {
      return null;
    }
    const options = [];
    for (const key of Object.keys(deal.properties)) {
      if (key.includes('$') || key.toLowerCase().includes('price') || key.toLowerCase().includes('pay')) {
        options.push({ value: deal.properties[key].value, label: key });
      }
    }
    return options;
  };

  if (deal !== selectedDeal) {
    setFieldOptions(fetchFieldOptions());
    setSelectedDeal(deal);
    setSyncProperty(null);
    setSyncValue(0);
  }

  if (!deal) {
    return (
      <Card className="price-edit-card">
        <h4 className="price-edit-card-header">Write Price To/From Deal</h4>
        <hr />
        <p>Select a hubspot deal first.</p>
      </Card>
    );
  }

  const fetchCourseCurrency = () => {
    if (!courseCurrency) {
      return 'Verify course first';
    }
    return courseCurrency.toUpperCase();
  };

  const checkSyncFields = () => {
    if (!syncProperty || !syncValue) {
      return false;
    }
    return true;
  };

  const calcValues = async ({ newSyncProperty, newDir, match }: any) => {
    if (newDir === HUBSPOT_TO_KRITIK && newSyncProperty) {
      setSyncValue(newSyncProperty.value);
      setKritikSyncValue(await convert('USD', courseCurrency.toUpperCase(), newSyncProperty.value, match));
    }
    if (newDir === KRITIK_TO_HUBSPOT && coursePrice) {
      setKritikSyncValue(coursePrice / 100);
      setSyncValue(await convert(courseCurrency.toUpperCase(), 'USD', coursePrice / 100, match));
    }
  };

  const changeSyncProperty = async (e: any) => {
    setSyncProperty(e);
    await calcValues({ newSyncProperty: e, newDir: dir, match: matchCurrency });
  };

  const changeDir = async () => {
    if (!deal || !coursePrice) {
      return;
    }
    await calcValues({
      newDir: (dir + 1) % 2,
      newSyncProperty: syncProperty,
      match: matchCurrency,
    });
    setDir((dir + 1) % 2);
  };

  const syncPrice = async () => {
    if (loadingSync) {
      return;
    }
    setLoadingSync(true);

    if (dir === HUBSPOT_TO_KRITIK) {
      await changeCoursePriceAndCurrency({
        courseId: course.value,
        price: kritikSyncValue * 100,
        currency: courseCurrency,
      });
    } else {
      const properties = [
        {
          name: syncProperty.label,
          value: syncValue,
        },
      ];
      const { dealId } = deal;
      const params = { dealId, properties };
      await updateHubspotDeal(params);
    }
    setLoadingSync(false);
    setSyncSuccess(true);
  };

  const renderArrow = () => {
    if (dir === HUBSPOT_TO_KRITIK) {
      return <ArrowDownwardIcon />;
    }
    return <ArrowUpwardIcon />;
  };

  const setCurrencyMatch = async (e: any) => {
    await calcValues({ newSyncProperty: syncProperty, newDir: dir, match: e.target.checked });
    setMatchCurrency(e.target.checked);
  };

  const renderCurrencyWarning = () => {
    if (!courseCurrency) {
      return null;
    }
    if (courseCurrency !== 'usd') {
      return (
        <React.Fragment>
          <InlineInformation type="warning">
            {`Hubspot values in USD, which does not match this course's currency of ${courseCurrency.toUpperCase()}. Values are currently`}
            <b>{`${matchCurrency ? '' : ' not'}`}</b>
            {` being converted.`}
          </InlineInformation>
          Convert Currency
          <Checkbox checked={matchCurrency} onChange={setCurrencyMatch} color="secondary" />
          <Divider />
        </React.Fragment>
      );
    }
    return null;
  };

  return (
    <Card className="price-edit-card">
      <h4>Write Price To/From Deal</h4>

      <hr />
      <FormSelection label="Hubspot Field" value={syncProperty} onChange={changeSyncProperty} options={fieldOptions} />
      <FormNumberInput label="" value={syncValue} disabled />
      <div className="card-arrow-container" onClick={changeDir}>
        {renderArrow()}
      </div>
      <FormNumberInput label={`Kritik Price (${fetchCourseCurrency()})`} value={kritikSyncValue} disabled />
      {renderCurrencyWarning()}
      <Button
        unavailable={!checkSyncFields()}
        loading={loadingSync}
        className="admin-panel-submit-button"
        type="primary"
        onClick={syncPrice}
        success={syncSuccess}
      >
        Update
      </Button>
    </Card>
  );
};

const mapStateToProps = (state: any) => {
  return {
    state,
  };
};
export default connect(mapStateToProps, {
  changeCoursePriceAndCurrency,
  updateHubspotDeal,
})(PriceSyncCard);
