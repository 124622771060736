import CircularProgress from '@material-ui/core/CircularProgress';
import { resetAsync } from 'actions/async';
import { cancelExportCourseCreations, exportCourseCreations } from 'actions/courses';
import { closeInfoModal, openInfoModal } from 'actions/modals';
import { TranslatedText } from 'components/TranslatedText';
import Button from 'components/buttons/Button';
import { localize } from 'locales';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { GradebookActions } from 'redux/gradebook';
import { getStudentFromUser } from 'selectors/student';

const DownloadCreationsModal = ({ course, isOpen, setIsOpen, ...props }: any) => {
  const handleClickNo = () => {
    setIsOpen(false);
    props.closeInfoModal();
  };

  const handleExportCourseCreations = () => {
    function ModalContent() {
      return (
        <div className="export-creations-modal-content-wrapper">
          <span style={{ marginRight: 20 }}>
            <CircularProgress />
          </span>
          <span>
            <TranslatedText i18nKey="DownloadCreationsModal.DownloadingCreations" />
          </span>
        </div>
      );
    }

    const actions = [
      <Button type="secondary" key="info-modal-cancel" onClick={props.cancelExportCourseCreations}>
        <TranslatedText i18nKey="Cancel" />
      </Button>,
    ];
    props.exportCourseCreations(course, {
      onSuccess: () => {
        setIsOpen(false);
      },
    });
    props.openInfoModal({
      content: <ModalContent />,
      actions,
    });
  };

  const handleClickYes = () => {
    handleExportCourseCreations();
  };

  useEffect(() => {
    if (isOpen) {
      props.openInfoModal({
        title: localize({ message: 'DownloadCreationsModal.Title' }),
        actions: [
          <Button key="info-modal-yes" type="primary" onClick={handleClickYes}>
            <TranslatedText i18nKey="Common.Yes" />
          </Button>,
          <Button key="info-modal-no" onClick={handleClickNo} type="secondary">
            <TranslatedText i18nKey="Common.No" />
          </Button>,
        ],
        onClose: () => setIsOpen(false),
      });
    }
  }, [isOpen]);

  return null;
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user.authUser,
    currentStudent: getStudentFromUser(state, state.selected.courseId),
  };
};

export default connect(mapStateToProps, {
  openInfoModal,
  closeInfoModal,
  cancelExportCourseCreations,
  exportCourseCreations,
  downloadGradebook: GradebookActions.downloadGradebook,
  resetAsync,
})(DownloadCreationsModal);
