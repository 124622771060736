import * as rubricUtils from '@kritik/utils/rubric';
import { resetAsync } from 'actions/async';
import { createRubricTemplate } from 'actions/rubrics';
import TextField from 'components/core/form/TextField';
import InlineInformation from 'components/layout/InlineInformation';
import ConfirmModal from 'components/modals/ConfirmModal';
import { localize } from 'locales';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { selectCreateTemplateState } from 'selectors/rubric';
import { selectAuthUser } from 'selectors/user';
import * as types from 'types';
import { trackEvent } from 'utils/userEvents';

const CreateTemplateModal = (props: any) => {
  const [templateName, setTemplateName] = useState('My Rubric');
  const [error, setError] = useState(null);

  useEffect(() => {
    return function cleanUp() {
      props.resetAsync(types.CREATE_NEW_TEMPLATE);
    };
  }, []);

  const handleNameChange = (e: any) => {
    setError(null);

    const capitalizedName = rubricUtils.capitalizeName(e.target.value);
    setTemplateName(capitalizedName);
  };

  const handleClickSubmit = () => {
    const validationError =
      rubricUtils.validateName(templateName) || rubricUtils.validateCriteriaName(props.rubric.criteria);

    if (validationError) {
      setError(validationError);
      return;
    }

    props.createRubricTemplate({
      rubric: {
        ...props.rubric,
        criteria: props.rubric.criteria.map((c: any) => {
          return {
            ...c,
            weight: Number(c.weight),
          };
        }),
        name: templateName,
      },
      userId: props.authUser.id,
    });
    trackEvent('Rubric Template Saved', props.authUser, {
      activityId: props.activityId,
      courseId: props.courseId,
    });
  };

  useEffect(() => {
    if (props.createTemplateState.success) {
      resetAsync(types.CREATE_NEW_TEMPLATE);
      props.onComplete();
    }
  }, [props.createTemplateState.success]);

  return (
    <ConfirmModal
      isOpen={props.isOpen}
      title={localize({ message: 'Activity.Rubric.CreateTemplateModal.Title' })}
      description={localize({ message: 'Activity.Rubric.CreateTemplateModal.Description' })}
      onConfirm={handleClickSubmit}
      confirmButton="Save"
      isConfirmDisabled={error}
      onCancel={props.onCancel}
      cancelButton="Cancel"
    >
      {error && <InlineInformation type="danger">{error}</InlineInformation>}
      <TextField
        label={localize({ message: 'Activity.Rubric.CreateTemplateModal.Name.Label' })}
        error={props.createTemplateState.error}
        onChange={handleNameChange}
        value={templateName}
        testid="rubric-template-name"
      />
    </ConfirmModal>
  );
};

const mapStateToProps = (state: any) => {
  return {
    authUser: selectAuthUser(state),
    createTemplateState: selectCreateTemplateState(state),
    activityId: state.selected.assignmentId,
    courseId: state.selected.courseId,
  };
};

export default connect(mapStateToProps, { createRubricTemplate, resetAsync })(CreateTemplateModal);
