import { mergeEntities } from 'actions/entities';
import { normalize } from 'normalizr';
import { studentSchema } from 'schemas';
import { courseService, studentService } from 'services';
import * as types from 'types';

export function getStudentsByCourse({ courseId }: any) {
  return (dispatch: any) => {
    dispatch({ type: types.GET_STUDENTS_BY_COURSE });

    return courseService()
      .getStudentsByCourse({ courseId })
      .then((res: any) => {
        if (res.status === 200) {
          const normalizedStudents = normalize(res.data, [studentSchema]);
          dispatch(mergeEntities(normalizedStudents.entities));
          dispatch({ type: types.GET_STUDENTS_BY_COURSE_SUCCESS, success: true });
          return res.data;
        }
      })
      .catch((err: any) => {
        dispatch({ type: types.GET_STUDENTS_BY_COURSE_FAILURE, payload: err });
      });
  };
}

export function enrollStudentInCourse(courseId: any) {
  return async (dispatch: any) => {
    dispatch({ type: types.ENROLL_STUDENT_IN_COURSE_REQUEST, payload: courseId });
    try {
      const response = await studentService().enrollStudentInCourse(courseId);
      if (response.status === 200) {
        const normalized = normalize(response.data, studentSchema);
        dispatch(mergeEntities(normalized.entities));
      }
      dispatch({ type: types.ENROLL_STUDENT_IN_COURSE_SUCCESS });
    } catch (error) {
      dispatch({ type: types.ENROLL_STUDENT_IN_COURSE_FAILURE });
      return error;
    }
  };
}

export default {
  getStudentsByCourse,
};
