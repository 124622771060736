import StatusLabels from 'components/CreationList/StatusLabels';
import LocalUtils from './utils';

const CreationCell = ({ creation, activity }: any) => {
  // @ts-expect-error TS(2339) FIXME: Property 'status' does not exist on type '{ label:... Remove this comment to see the full error message
  const { status, label, icon } = LocalUtils.generateCellLabel(creation, activity);
  return <StatusLabels icon={icon} label={label} status={status} />;
};

export default CreationCell;
