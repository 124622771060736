import Grid from '@material-ui/core/Grid';
import { Button } from 'components/buttons';
import { localize } from 'locales';
import { withRouter } from 'utils/withRouter';

function StudentsSection({
  enrolledStudents,
  pendingStudents,
  courseId,
  router,
}: {
  enrolledStudents: number;
  pendingStudents: number;
  courseId: string;
  router: any;
}) {
  return (
    <div className="students-section">
      <div className="students-section-title">{localize({ message: 'CourseDetails.StudentSection.Title' })}</div>
      <Grid container className="student-section-content">
        <Grid item xs={6} className="student-section-content__item">
          <p>{localize({ message: 'CourseDetails.StudentSection.Subtitle.Enrolled' })}</p>
          <p>{enrolledStudents}</p>
        </Grid>
        <Grid item xs={6} className="student-section-content__item">
          <p>{localize({ message: 'CourseDetails.StudentSection.Subtitle.Pending' })}</p>
          <p>{pendingStudents}</p>
        </Grid>
      </Grid>
      <div className="student-section-content__button">
        <Button
          type="secondary"
          onClick={() => {
            router.push(`/course/${courseId}/roster`);
          }}
        >
          {localize({ message: 'CourseDetails.StudentSection.Button.Text' })}
          <i className="fa fa-arrow-right spacing" />
        </Button>
      </div>
    </div>
  );
}

export default withRouter(StudentsSection);
