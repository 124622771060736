const PageContainer = (props: any) => {
  return (
    <div className="layout-side-menu">
      <div className="layout-side-menu__nav">{props.menu}</div>
      <div className="layout-side-menu__content">{props.content}</div>
    </div>
  );
};

export default PageContainer;
