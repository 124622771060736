import NoticeBoard from 'components/layout/NoticeBoard';
import { localize } from 'locales';

function AutoAssignEvaluatorNotice(props: any) {
  const { numEvaluatorsAssigned, numEvaluatorsExpected } = props;
  const text = {
    notice: localize({ message: 'AutoAssignEvaluatorNotice.Notice' }),
    title_no_eligible: localize({ message: 'AutoAssignEvaluatorNotice.TitleNoEligible' }),
    title_one_eligible: localize({
      message: 'AutoAssignEvaluatorNotice.TitleOneEligible',
      options: { numEvaluatorsAssigned },
    }),
    title_some_eligible: localize({
      message: 'AutoAssignEvaluatorNotice.TitleSomeEligible',
      options: { numEvaluatorsAssigned },
    }),
    title_all_eligible: localize({ message: 'AutoAssignEvaluatorNotice.TitleAllEligible' }),
  };
  if (numEvaluatorsAssigned === 0) {
    return (
      <NoticeBoard type="danger" title={text.title_no_eligible}>
        {text.notice}
      </NoticeBoard>
    );
  }
  if (numEvaluatorsAssigned < numEvaluatorsExpected) {
    return (
      <NoticeBoard
        type="caution"
        title={numEvaluatorsAssigned === 1 ? text.title_one_eligible : text.title_some_eligible}
      >
        {text.notice}
      </NoticeBoard>
    );
  }
  return (
    <NoticeBoard type="information" title={text.title_all_eligible}>
      {text.notice}
    </NoticeBoard>
  );
}

export default AutoAssignEvaluatorNotice;
