import DownloadCreationsModal from 'components/DownloadCreationsModal';
import DownloadGradebookModal from 'components/DownloadGradebookModal';
import { TranslatedText } from 'components/TranslatedText';
import Button from 'components/buttons/Button';
import ButtonContainer from 'components/buttons/ButtonContainer';
import { useState } from 'react';
import { connect } from 'react-redux';
import GradebookActions from 'redux/gradebook/actions';
import { selectCurrentCourse } from 'selectors/course';

const mapStateToProps = (state: any) => {
  return {
    course: selectCurrentCourse(state),
  };
};

function GradesDownloadActions({ course }: any) {
  const [isDownloadGradebookOpen, setIsDownloadGradebookOpen] = useState(false);
  const [isDownloadCreationsOpen, setIsDownloadCreationsOpen] = useState(false);
  const onClickDownloadGradebook = () => {
    setIsDownloadGradebookOpen(true);
  };
  const onClickDownloadCreations = () => {
    setIsDownloadCreationsOpen(true);
  };
  return (
    <div>
      <h3>
        <TranslatedText i18nKey="GradesDownloadActions.Grades" />
      </h3>
      <p>
        <TranslatedText i18nKey="GradesDownloadActions.SelectDataToDownload" />
      </p>
      <ButtonContainer>
        <Button onClick={onClickDownloadGradebook} type="primary">
          <TranslatedText i18nKey="GradesDownloadActions.DownloadGradebook" />
        </Button>
        <Button onClick={onClickDownloadCreations} type="secondary">
          <TranslatedText i18nKey="GradesDownloadActions.DownloadCreations" />
        </Button>
      </ButtonContainer>
      <DownloadGradebookModal course={course} isOpen={isDownloadGradebookOpen} setIsOpen={setIsDownloadGradebookOpen} />
      <DownloadCreationsModal isOpen={isDownloadCreationsOpen} setIsOpen={setIsDownloadCreationsOpen} course={course} />
    </div>
  );
}

export default connect(mapStateToProps, {
  downloadGradebook: GradebookActions.downloadGradebook,
})(GradesDownloadActions);
