import { ReadOnlyField } from 'components/ActivityEdit/AdvancedOptions/Field';
import { localize } from 'locales';
import { useForm } from 'react-final-form';

const ReadOnly = () => {
  const form = useForm();
  const { weight } = form.getState().values;

  return (
    <ReadOnlyField>
      {localize({ message: 'Activity.WeightField.ReadOnly' })} <b>{weight}</b>
    </ReadOnlyField>
  );
};

export default ReadOnly;
