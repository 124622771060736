import { localize } from 'locales';

export const STUDENT_COLUMNS = {
  PEER: [
    {
      header: localize({ message: 'ScoringTable.Constants.Name' }),
      align: 'left',
      isSortable: true,
      width: 'lg',
    },
    {
      header: localize({ message: 'ScoringTable.Constants.Create' }),
      align: 'center',
      isSortable: true,
    },
    {
      header: localize({ message: 'ScoringTable.Constants.Evaluate' }),
      align: 'center',
      isSortable: true,
    },
    {
      header: localize({ message: 'ScoringTable.Constants.Feedback' }),
      align: 'center',
      isSortable: true,
    },
    {
      header: localize({ message: 'ScoringTable.Constants.Overall' }),
      align: 'center',
      isSortable: true,
    },
    {
      header: localize({ message: 'ScoringTable.Constants.Issues' }),
      align: 'center',
      isSortable: true,
    },
  ],
  GROUP: [
    {
      header: localize({ message: 'ScoringTable.Constants.Name' }),
      align: 'left',
      isSortable: true,
      width: 'lg',
    },
    {
      header: localize({ message: 'ScoringTable.Constants.Create' }),
      align: 'center',
      isSortable: true,
    },
    {
      header: localize({ message: 'ScoringTable.Constants.Evaluate' }),
      align: 'center',
      isSortable: true,
    },
    {
      header: localize({ message: 'ScoringTable.Constants.Feedback' }),
      align: 'center',
      isSortable: true,
    },
    {
      header: localize({ message: 'ScoringTable.Constants.Overall' }),
      align: 'center',
      isSortable: true,
    },
    {
      header: localize({ message: 'ScoringTable.Constants.Issues' }),
      align: 'center',
      isSortable: true,
    },
  ],
  CALIBRATION: [
    {
      header: localize({ message: 'ScoringTable.Constants.Name' }),
      align: 'left',
      isSortable: true,
      width: 'lg',
    },
    {
      header: localize({ message: 'ScoringTable.Constants.Evaluate' }),
      align: 'center',
      isSortable: true,
      info: localize({ message: 'ScoringTable.Constants.CalibrationEvaluateInfo' }),
    },
    { header: '' },
    { header: '' },
    { header: '' },
    {
      header: localize({ message: 'ScoringTable.Constants.Issues' }),
      align: 'center',
      isSortable: true,
    },
  ],
};

export const GROUP_COLUMNS = {
  GROUP: [
    {
      header: localize({ message: 'ScoringTable.Constants.Name' }),
      align: 'left',
      isSortable: true,
      width: 'lg',
    },
    {
      header: localize({ message: 'ScoringTable.Constants.Create' }),
      align: 'center',
      isSortable: true,
    },
    {
      header: localize({ message: 'ScoringTable.Constants.Evaluate' }),
      align: 'center',
      isSortable: true,
      info: localize({ message: 'ScoringTable.Constants.GroupEvaluateInfo' }),
    },
    {
      header: localize({ message: 'ScoringTable.Constants.Feedback' }),
      align: 'center',
      isSortable: true,
      info: localize({ message: 'ScoringTable.Constants.GroupFeedbackInfo' }),
    },
    { header: '' },
    {
      header: localize({ message: 'ScoringTable.Constants.Issues' }),
      align: 'center',
      isSortable: true,
    },
    { header: ' ', align: 'right', isSortable: false, width: 'sm' },
  ],
};

export default {
  STUDENT_COLUMNS,
  GROUP_COLUMNS,
};
