import LMSConnectionPendingContainer from 'containers/LMSConnectionPendingContainer';
import Page from 'pages/Page';

function LMSConnectionPending() {
  return (
    <Page title="LMS Connection Pending" meta={[]} link={[]} script={[]}>
      <LMSConnectionPendingContainer />
    </Page>
  );
}

export default LMSConnectionPending;
