import { Component } from 'react';

import UsersContainer from 'containers/Admin/Users';
import Page from 'pages/Page';

class Users extends Component {
  getMetaData() {
    return {
      title: this.pageTitle(),
      meta: this.pageMeta(),
      link: this.pageLink(),
      script: this.pageScript(),
    };
  }

  pageTitle = () => {
    return 'Users Admin';
  };

  pageMeta = () => {
    return [];
  };

  pageLink = () => {
    return [];
  };

  pageScript = () => {
    return [];
  };

  render() {
    return (
      <Page {...this.getMetaData()}>
        <UsersContainer />
      </Page>
    );
  }
}

export default Users;
