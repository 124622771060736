import { ROUNDS } from '@kritik/constants/bcrypt';
import { userRoles } from '@kritik/constants/user';
import bcrypt from 'bcryptjs';
import { courseService } from 'services';
import EdlinkService from 'services/edlink';
import { getBase64StringFromObject } from 'utils/general';
import constants from './constants';
import types from './types';

export function getEdlinkActivities(edlinkCourseId: any) {
  return (dispatch: any) => {
    dispatch({ type: types.GET_EDLINK_ACTIVITIES_REQUEST });
    return EdlinkService()
      .getEdlinkActivities(edlinkCourseId)
      .then((response: any) => {
        if (response.status === 200) {
          dispatch({ type: types.GET_EDLINK_ACTIVITIES_SUCCESS, payload: response.data });
          return response.data;
        }
      })
      .catch((err: any) => {
        dispatch({ type: types.GET_EDLINK_ACTIVITIES_FAILURE, payload: err });
      });
  };
}

export async function redirectToEdlinkSSO({ userIntegrationsList, userEmail, action, role, courseId }: any) {
  // @ts-expect-error TS(2304) FIXME: Cannot find name 'App'.
  const clientId = App.config.get('edlinkApplicationId');
  // @ts-expect-error TS(2304) FIXME: Cannot find name 'App'.
  const redirectUri = App.config.get('ssoRedirectUri');
  const salt = await bcrypt.genSalt(ROUNDS);
  const emailHash = await bcrypt.hash(userEmail, salt);
  let _courseId = '';
  if (
    (action === constants.SSO_ACTION_SIGN_UP && role === userRoles.STUDENT) ||
    (action === constants.SSO_ACTION_CONNECT_COURSE && role === userRoles.TEACHER)
  ) {
    _courseId = courseId;
  }
  const data = {
    emailHash,
    action,
    role,
    courseId: _courseId,
  };

  const encodedData = getBase64StringFromObject(data);

  if (userIntegrationsList.length > 1) {
    // @ts-expect-error TS(2304) FIXME: Cannot find name 'App'.
    const edlinkBaseUrlSso = App.config.get('edlinkBaseUrlSso');
    window.location.href = `${edlinkBaseUrlSso}?client_id=${clientId}&redirect_uri=${redirectUri}&state=${encodedData}&response_type=code`;
  } else if (userIntegrationsList.length === 1) {
    const integrationId = userIntegrationsList[0].edlinkIntegrationId;
    window.location.href = `https://ed.link/api/authentication/integrations/${integrationId}?client_id=${clientId}&redirect_uri=${redirectUri}&state=${encodedData}&response_type=code`;
  }
}

export function getEdlinkCourses(params: { userId: string; edlinkIntegrationId: string; courseId: string }) {
  return () => {
    return EdlinkService()
      .getEdlinkCourses(params)
      .then((res: any) => {
        if (res.status === 200) {
          return res.data;
        }
      })
      .catch((err: any) => {
        return { $error: err, code: err?.response?.status || null };
      });
  };
}

export function getEdlinkIntegrationsByInstitution({
  institutionId,
  courseId,
}: {
  institutionId: string;
  courseId: string;
}) {
  return (dispatch: any) => {
    dispatch({ type: types.GET_EDLINK_INTEGRATIONS_REQUEST });
    return EdlinkService()
      .getEdlinkIntegrationsByInstitution({ institutionId, courseId })
      .then((res: any) => {
        if (res.status === 200) {
          dispatch({ type: types.GET_EDLINK_INTEGRATIONS_SUCCESS, payload: res.data });
          return { success: true };
        }
      })
      .catch((err: any) => {
        dispatch({ type: types.GET_EDLINK_INTEGRATIONS_FAILURE, err });
        return { success: false };
      });
  };
}

export function syncGrades({ edlinkCourseId, data }: any) {
  return (dispatch: any) => {
    dispatch({ type: types.EDLINK_SYNC_GRADES_REQUEST });
    return EdlinkService()
      .syncGrades({ edlinkCourseId, data })
      .then((response: any) => {
        if (response.status === 200) {
          dispatch({ type: types.EDLINK_SYNC_GRADES_SUCCESS, payload: response.data });
          return response.data;
        }
      })
      .catch((err: any) => {
        dispatch({ type: types.EDLINK_SYNC_GRADES_FAILURE, payload: err });
        return err?.response?.data;
      });
  };
}

export function updateAutoSyncSetting({ courseId, autoRosterSync }: any) {
  return (dispatch: any) => {
    return courseService()
      .update({
        id: courseId,
        data: {
          lms: {
            autoRosterSync,
          },
        },
      })
      .then((response: any) => {
        if (response.status === 200) {
          dispatch({
            type: types.EDLINK_AUTO_SYNC_SUCCESS,
            payload: response.data?.lms?.autoRosterSync,
          });
        }
      })
      .catch((err: any) => {
        dispatch({ type: types.EDLINK_AUTO_SYNC_FAILURE, payload: err });
      });
  };
}

export default {
  getEdlinkCourses,
  getEdlinkIntegrationsByInstitution,
  redirectToEdlinkSSO,
  getEdlinkActivities,
  syncGrades,
  updateAutoSyncSetting,
};
