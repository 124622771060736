import Tooltip from '@material-ui/core/Tooltip';
import LmsColumnCell from 'components/LmsGradeSync/LmsTable/ActivityTable/LmsColumnCell';
import RowMenu from 'components/LmsGradeSync/LmsTable/ActivityTable/RowMenu';
import SyncStatusIcon from 'components/LmsGradeSync/LmsTable/ActivityTable/SyncStatusIcon';
import SyncStatusLabel from 'components/LmsGradeSync/LmsTable/ActivityTable/SyncStatusLabel';
import { TranslatedText } from 'components/TranslatedText';
import Table from 'components/layout/SimpleTable';
import TableBody from 'components/layout/SimpleTable/Body';
import TableCell from 'components/layout/SimpleTable/Cell';
import TableHead from 'components/layout/SimpleTable/Head';
import TableRow from 'components/layout/SimpleTable/Row';
import HelpIcon from 'images/lms-grade-sync/help.svg';
import { localize } from 'locales';
import { connect } from 'react-redux';
import CourseSelectors from 'selectors/course';

function LmsActivityTable(props: any) {
  const handleSyncStatusChange = (currentStatus: any, activityId: any) => {
    const newActivitiesSyncStatus: any = [];
    props.activities.forEach((activity: any) => {
      const newActivityStatus = { ...activity };
      if (newActivityStatus._id === activityId) {
        if (activity.status === 'synced' || activity.status === 'not-synced' || activity.status === 'error') {
          newActivityStatus.status = 'ready-to-sync';
        }
        if (activity.status === 'ready-to-sync') {
          newActivityStatus.status = 'not-synced';
        }
      }
      newActivitiesSyncStatus.push(newActivityStatus);
    });
    props.onChangeActivities(newActivitiesSyncStatus);
  };

  const handleColumnChangeForActivity = ({ lineItem, activityId }: any) => {
    const updatedActivities: any = [];
    props.activities.forEach((activity: any) => {
      const _activity = { ...activity };
      if (_activity._id === activityId) {
        _activity.column = lineItem;
      }
      updatedActivities.push(_activity);
    });
    props.onChangeActivities(updatedActivities);
  };

  const handleOnChangeRadioSelection = ({ activityId, radioValue }: any) => {
    const updatedActivities: any = [];
    props.activities.forEach((activity: any) => {
      const _activity = { ...activity };
      if (_activity._id === activityId) {
        _activity.status = radioValue;
      }
      updatedActivities.push(_activity);
    });
    props.onChangeActivities(updatedActivities);
  };

  const renderRow = ({ title, activityId, lmsGradeSyncInformation, status, column }: any) => {
    const syncDate = lmsGradeSyncInformation.lastSyncAt || null;
    return (
      <TableRow>
        <TableCell>{title}</TableCell>
        <TableCell>
          <SyncStatusIcon
            status={status}
            activityId={activityId}
            handleSyncStatusChange={(currentStatus: any, activityId: any) => {
              handleSyncStatusChange(currentStatus, activityId);
            }}
          />
        </TableCell>
        <TableCell>
          <LmsColumnCell
            lmsColumns={props.lmsColumns}
            lmsGradeSyncInformation={lmsGradeSyncInformation}
            selectedColumn={column}
            setSelectedColumn={handleColumnChangeForActivity}
            activityId={activityId}
          />
        </TableCell>
        <TableCell width="">
          <SyncStatusLabel status={status} lastSyncAt={syncDate} />
        </TableCell>
        <TableCell>
          <RowMenu activityId={activityId} onChangeRadioSelection={handleOnChangeRadioSelection} syncStatus={status} />
        </TableCell>
      </TableRow>
    );
  };

  const TableHeadCell = ({ children }: any) => {
    return <div className="activity-table-cell__head">{children}</div>;
  };

  return (
    <div>
      <Table>
        <TableHead>
          <tr>
            <TableCell width="35%">
              <TableHeadCell>
                <TranslatedText i18nKey="LmsGradeSync.ActivityTable.KritikScores" />
              </TableHeadCell>
            </TableCell>
            <TableCell></TableCell>
            <TableCell>
              <TableHeadCell>
                <TranslatedText i18nKey="LmsGradeSync.ActivityTable.LmsColumns" />
              </TableHeadCell>
            </TableCell>
            <TableCell width="20%">
              <TableHeadCell>
                <TranslatedText i18nKey="LmsGradeSync.ActivityTable.Status" />
              </TableHeadCell>
            </TableCell>
            <TableCell width="5%">
              <Tooltip
                title={localize({ message: 'LmsGradeSync.ActivityTable.SyncActivitiesHelpText' })}
                placement="top"
              >
                <div className="activity-table-cell__head-help-icon">
                  <img src={HelpIcon} aria-hidden="true" />
                </div>
              </Tooltip>
            </TableCell>
          </tr>
        </TableHead>
        <TableBody>
          {props.activities.map((item: any) => {
            return renderRow({
              title: item.title,
              activityId: item._id,
              lmsGradeSyncInformation: item.lmsGradeSyncInformation,
              status: item.status,
              column: item.column,
            });
          })}
        </TableBody>
      </Table>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    course: CourseSelectors.getCourse(state, state.selected.courseId),
  };
};

export default connect(mapStateToProps)(LmsActivityTable);
