import { useMutation, useQuery } from '@tanstack/react-query';

import { Activity } from '@kritik/types.generated';
import { isGroupPresentationActivity } from '@kritik/utils/activity';
import ActivityService from 'services/activity';
import { groupService } from 'services/index';

export function useFetchGroupSet(activityId: string, activity: Activity) {
  return useQuery(
    ['getActivityGroupSet'],
    async () => {
      const response = await ActivityService().getActivityGroupSet({ activityId });
      return response.data;
    },
    {
      enabled: Boolean(activity) && isGroupPresentationActivity(activity),
    }
  );
}

type SyncGroupsMutationArgs = {
  onSuccess: () => void;
};

export function syncGroupsMutation({ onSuccess }: SyncGroupsMutationArgs) {
  return useMutation({
    mutationFn: async ({ courseId }: { courseId: string }) => {
      const result = await groupService().syncGroups({ courseId });
      return result.data;
    },
    onSuccess: () => {
      onSuccess();
    },
  });
}
