import { isGroupPresentationActivity } from '@kritik/utils/activity';
import * as statusUtil from '@kritik/utils/stage';
import StatusLabels from 'components/CreationList/StatusLabels';
import { localize } from 'locales';
import { Activity, Creation, Group, Student } from 'old-common/types.generated';
import { connect } from 'react-redux';
import { selectIsShowFeedbackCompleted } from 'redux/creationTable/select';
import { getCreationsFromEntities } from 'selectors/creation';
import { generateFeedbackCellLabel } from './utils';

type FeedbackCellProps = {
  creation: Creation;
  activity: Activity;
  isShowFeedbackCompleted: boolean;
  feedbackReceivedMap: any;
  student: Student;
  group: Group;
  creations: Creation[];
};

const FeedbackCell = ({
  creation,
  activity,
  isShowFeedbackCompleted,
  feedbackReceivedMap,
  student,
  group,
  creations,
}: FeedbackCellProps) => {
  const studentId = student ? student._id : '';
  const isGroupView = Boolean(creation.group && !studentId);
  if (
    isGroupPresentationActivity(activity) &&
    isGroupView &&
    activity.participationSettings?.evaluatorType === 'specificGroups' &&
    (statusUtil.isCreate({ assignment: activity }) || statusUtil.isInCreationGracePeriod(activity))
  ) {
    const isGroupEvaluating = (activity.participationSettings.evaluatorGroups || []).includes(creation.group);
    if (isGroupEvaluating) {
      return <StatusLabels label={localize({ message: 'Activity.StudentTable.Cell.NotStarted' })} status="no-bg" />;
    } else {
      return <StatusLabels label={localize({ message: 'Activity.StudentTable.Cell.NotRequired' })} status="no-bg" />;
    }
  }
  // @ts-expect-error TS(2339) FIXME: Property 'status' does not exist on type '{ label:... Remove this comment to see the full error message
  const { status, label } = generateFeedbackCellLabel(
    creation,
    activity,
    isShowFeedbackCompleted,
    feedbackReceivedMap,
    student,
    group,
    creations
  );
  return <StatusLabels label={label} status={status} />;
};

FeedbackCell.defaultProps = {
  student: null,
};

const mapStateToProps = (state: any) => {
  return {
    isShowFeedbackCompleted: selectIsShowFeedbackCompleted(state),
    feedbackReceivedMap: state.creation.studentFeedbackReceivedMap,
    creations: getCreationsFromEntities(state, state.selected.assignmentId),
  };
};

FeedbackCell.defaultProps = {};

export default connect(mapStateToProps)(FeedbackCell);
