import Select, { SelectProps } from '@material-ui/core/Select';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import classNames from 'classnames';
import FormFieldInput from 'components/core/form/FieldInput';
import FormFieldLabel from 'components/core/form/FieldLabel';
import { useEffect, useId, useRef } from 'react';

type SelectionProps = {
  id?: string;
  isPrimary?: boolean;
  disabled?: boolean;
  warning?: boolean;
  label?: string;
  menuLabel?: string;
  isRequired?: boolean;
  testid?: string;
  menuProps?: SelectProps['MenuProps'];
  value?: SelectProps['value'];
  open?: SelectProps['open'];
  onOpen?: SelectProps['onOpen'];
  onClose?: SelectProps['onClose'];
  onChange?: SelectProps['onChange'];
  onBlur?: SelectProps['onBlur'];
  renderValue?: SelectProps['renderValue'];
  children?: SelectProps['children'];
};

const Selection = (props: SelectionProps) => {
  const id = useId();
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (ref.current) {
      const listboxElement = ref.current.querySelector('div[aria-haspopup="listbox"]');
      if (listboxElement) {
        listboxElement.setAttribute('aria-label', props.label);
      }
    }
  }, [props.label]);

  const classes = classNames('kritik-form-select', {
    'kritik-form-select__primary': props.isPrimary === true,
    'kritik-form-select__disabled': props.disabled === true,
  });
  return (
    <FormFieldInput warning={props.warning} ref={ref}>
      <FormFieldLabel
        label={props.label}
        menuLabel={props.menuLabel}
        isRequired={props.isRequired}
        id={props.id || id}
      />
      <Select
        labelId={props.id || id}
        SelectDisplayProps={{
          'aria-labelledby': null,
        }}
        MenuProps={props.menuProps}
        className={classes}
        value={props.value}
        open={props.open}
        onOpen={props.onOpen}
        onClose={props.onClose}
        onChange={props.onChange}
        onBlur={props.onBlur}
        disableUnderline
        IconComponent={KeyboardArrowDownIcon}
        disabled={props.disabled}
        renderValue={props.renderValue}
        data-testid={props.testid}
        inputProps={{
          id: props.id || id,
        }}
        required={props.isRequired}
      >
        {props.children}
      </Select>
    </FormFieldInput>
  );
};

Selection.defaultProps = {
  disabled: false,
};

export default Selection;
