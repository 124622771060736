import Button from 'components/buttons/Button';

function SectionHeader(props: any) {
  const renderActionButton = () => {
    if (!props.action) {
      return null;
    }
    return (
      <Button type={props.type ? props.type : 'secondary'} onClick={props.action.action} testid={props.testid}>
        {props.action.label}
      </Button>
    );
  };
  return (
    <div className="section-header">
      <h2 className="section-header__label new-header-2">{props.label}</h2>
      {renderActionButton()}
    </div>
  );
}

SectionHeader.defaultProps = {
  action: null,
};

export default SectionHeader;
