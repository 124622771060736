import { useQueryClient } from '@tanstack/react-query';
import { commentAssignmentSubmissionEvaluation } from 'actions/activity';
import * as _ from 'lodash-es';
import { connect } from 'react-redux';
import { selectAuthUser } from 'selectors/user';
import CommentOnContentHandler from '../index';

const EvaluationComment = (props: any) => {
  const queryClient = useQueryClient();

  function onSubmit(data: any) {
    let _data = _.pick(data, ['userId', 'comment', 'files']);
    const evaluationData = {
      assignmentId: props.activityId,
      submitterId: props.creation.user._id || props.creation.user,
      evaluatorId: props.evaluation.user._id || props.evaluation.user,
    };
    _data = { ..._data, ...evaluationData };

    return props
      .commentAssignmentSubmissionEvaluation(_data)
      .then(async () => await queryClient.invalidateQueries(['getCreationsToEvaluate']));
  }

  return (
    <CommentOnContentHandler
      creationUserId={props.creationUserId}
      activityId={props.activityId}
      resource={props.evaluation}
      isInstructor={props.isInstructor}
      creation={props.creation}
      onSubmit={onSubmit}
      contentOwner={props.evaluation.user}
      onClose={props.onClose}
      isViewOnly={props.isViewOnly}
    />
  );
};

function mapStateToProps(state: any) {
  return {
    entities: state.entities,
    user: selectAuthUser(state),
  };
}

EvaluationComment.defaultProps = {
  isInstructor: false,
  isViewOnly: false,
  onClose: null,
};

export default connect(mapStateToProps, { commentAssignmentSubmissionEvaluation })(EvaluationComment);
