import { activitySortOrderType } from '@kritik/constants/sortOrder';
import { assignmentStatuses as STATUSES } from '@kritik/constants/stage';
import * as statusUtil from '@kritik/utils/stage';
import ActivityCard from 'components/ActivityCard/Card';
import { TranslatedText } from 'components/TranslatedText';

const CardList = (props: any) => {
  const getOrderedList = (sortByType: string, isAscending: boolean) => {
    const activityMap = {
      [STATUSES.PROCESSING3]: [],
      [STATUSES.FEEDBACK]: [],
      [STATUSES.EVALUATE]: [],
      [STATUSES.CREATE]: [],
      scheduled: [],
      [STATUSES.DRAFT]: [],
    };
    props.activityList.forEach((activity: any) => {
      if (statusUtil.isGrading({ assignment: activity })) {
        activityMap[STATUSES.PROCESSING3].push(activity);
      } else if (statusUtil.isScheduled(activity)) {
        if (statusUtil.isDraft({ assignment: activity })) {
          activityMap.scheduled.push(activity);
        } else if (statusUtil.isCreate({ assignment: activity }) || statusUtil.isProcessing({ assignment: activity })) {
          activityMap[STATUSES.CREATE].push(activity);
        } else if (
          statusUtil.isEvaluate({ assignment: activity }) ||
          statusUtil.isProcessing1({ assignment: activity })
        ) {
          activityMap[STATUSES.EVALUATE].push(activity);
        } else if (statusUtil.isFeedback({ assignment: activity })) {
          activityMap[STATUSES.FEEDBACK].push(activity);
        }
      } else if (statusUtil.isDraft({ assignment: activity })) {
        activityMap[STATUSES.DRAFT].push(activity);
      }
    });

    switch (sortByType) {
      case activitySortOrderType.DueDate: {
        const activityList = Object.values(activityMap).reduce((acc, list) => {
          return acc.concat(list);
        }, []);
        const orderedList = activityList.sort((a, b) => {
          const nextDateA = statusUtil.getNextDate(a);
          const nextDateB = statusUtil.getNextDate(b);
          return nextDateA > nextDateB ? 1 : -1;
        });
        if (!isAscending) {
          orderedList.reverse();
        }
        return orderedList;
      }
      case activitySortOrderType.CreatedDate: {
        const activityList = Object.values(activityMap).reduce((acc, list) => {
          return acc.concat(list);
        }, []);
        const orderedList = activityList.sort((a, b) => {
          const createDateA = statusUtil.getActiveStatus(a).createdAt;
          const createDateB = statusUtil.getActiveStatus(b).createdAt;
          return createDateA > createDateB ? 1 : -1;
        });
        if (!isAscending) {
          orderedList.reverse();
        }
        return orderedList;
      }
      case activitySortOrderType.ActivityStage:
        if (isAscending) {
          return Object.values(activityMap).reduce((acc, list) => {
            const sorted = list.sort((a, b) => {
              const createDateA = statusUtil.getActiveStatus(a).createdAt;
              const createDateB = statusUtil.getActiveStatus(b).createdAt;
              return createDateA > createDateB ? 1 : -1;
            });
            return sorted.concat(acc);
          }, []);
        } else {
          return Object.values(activityMap).reduce((acc, list) => {
            const sorted = list.sort((a, b) => {
              const createDateA = statusUtil.getActiveStatus(a).createdAt;
              const createDateB = statusUtil.getActiveStatus(b).createdAt;
              return createDateA > createDateB ? 1 : -1;
            });
            return acc.concat(sorted);
          }, []);
        }
      case activitySortOrderType.Alphabetical: {
        const activityList = Object.values(activityMap).reduce((acc, list) => {
          return acc.concat(list);
        }, []);
        const orderedList = activityList.sort((a, b) => {
          return a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1;
        });
        if (!isAscending) {
          orderedList.reverse();
        }
        return orderedList;
      }
    }
  };

  return (
    <div className="activity-card-list">
      <h3 className="visually-hidden">
        <TranslatedText i18nKey="ActivityCard.List.ActivitiesCardsList" />
      </h3>
      {getOrderedList(props.sortByType, props.isAscending).map((activity, idx) => {
        return (
          <div className="activity-card-list-item" key={`activity-card-${idx}`} data-testid={`activity-card-${idx}`}>
            <ActivityCard activity={activity} />
          </div>
        );
      })}
    </div>
  );
};

export default CardList;
