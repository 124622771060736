import PromoteAdminContainer from 'containers/Admin/PromoteAdmin';
import Page from 'pages/Page';

function PromoteAdmin() {
  return (
    <Page title="Promote to Admin" meta={[]} link={[]} script={[]}>
      <PromoteAdminContainer />
    </Page>
  );
}

export default PromoteAdmin;
