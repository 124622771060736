import RubricConstants from '@kritik/constants/rubric';
import { Rubric } from '@kritik/types.generated';
import * as rubricUtils from '@kritik/utils/rubric';

const generateNewLevelDescription = (criterion: any, level: any) => {
  return `Meaning of ${criterion.name} at ${level}`;
};

const getDefaultLevelLabels = (levels: any) => {
  return levels.map((level: any, i: any) => {
    return `Level ${i}`;
  });
};

const sanitizeBinaryLevels = ({ levels, grid }: any) => {
  let _levels = [...levels];
  if (rubricUtils.isMultiLevelRubric({ grid } as Rubric)) {
    _levels = getDefaultLevelLabels(levels);
  }
  return _levels;
};

const addNewCriterion = ({ criteria, grid, levels, atIndex }: any) => {
  const _criteria = [...criteria];
  const _grid = [...grid];
  const _atIndex = typeof atIndex === 'number' ? atIndex : _criteria.length + 1;
  const newCriterion = {
    name: `Criteria ${criteria.length + 1}`,
    weight: 1,
    useMode: false,
  };
  _criteria.splice(_atIndex, 0, newCriterion);
  const levelDescriptions = levels.map((level: any) => {
    return generateNewLevelDescription(newCriterion, level);
  });
  _grid.splice(_atIndex, 0, levelDescriptions);
  return { criteria: _criteria, grid: _grid };
};

const pruneLevels = ({ levels, grid }: any) => {
  const _grid = [...grid];
  let _levels = [...levels];

  let greatestRowLength = 0;
  _grid.forEach((row) => {
    const _row = [...row];
    if (_row.length > greatestRowLength) {
      greatestRowLength = _row.length;
    }
  });

  if (greatestRowLength < _levels.length) {
    _levels.pop();
  }
  return _levels;
};

const removeSelectedCriteria = ({ levels, criteria, grid, selectedRowIndexes }: any) => {
  let _criteria = [...criteria];
  let _grid = [...grid];
  let _levels = [...levels];
  if (_criteria.length > RubricConstants.MIN_CRITERIA) {
    _grid = _grid.filter((item, index) => {
      return !selectedRowIndexes.includes(index);
    });
    _criteria = _criteria.filter((item, index) => {
      return !selectedRowIndexes.includes(index);
    });
  }

  _levels = pruneLevels({ levels: _levels, grid: _grid });

  return { levels: _levels, criteria: _criteria, grid: _grid };
};

const removeLastCriterion = ({ levels, criteria, grid }: any) => {
  const _criteria = [...criteria];
  const _grid = [...grid];
  let _levels = [...levels];
  if (_criteria.length > RubricConstants.MIN_CRITERIA) {
    _criteria.pop();
    _grid.pop();
  }

  _levels = pruneLevels({ levels: _levels, grid: _grid });

  return { levels: _levels, criteria: _criteria, grid: _grid };
};

const addLevels = ({ levels, grid, criteria, selectedRowIndexes }: any) => {
  if (levels.length >= RubricConstants.MAX_LEVELS) {
    return;
  }
  let _grid = [];
  let _levels = [...levels];
  let greatestRowLength = 0;

  _grid = grid.map((row: any, i: any) => {
    const _row = [...row];
    if (selectedRowIndexes.includes(i) || selectedRowIndexes.length === 0) {
      const newLevelDesc = generateNewLevelDescription(criteria[i], `Level ${_row.length}`);
      _row.push(newLevelDesc);
    }
    if (_row.length > greatestRowLength) {
      greatestRowLength = _row.length;
    }
    return _row;
  });
  if (greatestRowLength > _levels.length) {
    _levels.push(`Level ${levels.length}`);
  }
  _levels = sanitizeBinaryLevels({ levels: _levels, grid: _grid });
  return { levels: _levels, grid: _grid };
};

const removeLevels = ({ levels, grid, selectedRowIndexes }: any) => {
  let _grid = [];
  let _levels = [...levels];

  let greatestRowLength = 0;
  _grid = grid.map((row: any, i: any) => {
    const _row = [...row];
    if (selectedRowIndexes.includes(i) || (selectedRowIndexes.length === 0 && _row.length === _levels.length)) {
      if (_row.length > RubricConstants.MIN_LEVELS) {
        _row.pop();
      }
    }
    if (_row.length > greatestRowLength) {
      greatestRowLength = _row.length;
    }
    return _row;
  });
  if (greatestRowLength < _levels.length) {
    _levels.pop();
  }
  _levels = sanitizeBinaryLevels({ levels: _levels, grid: _grid });
  return { levels: _levels, grid: _grid };
};

function toggleModeScoringForCriteria({
  criteria,
  selectedRowIndexes,
}: {
  criteria: any[];
  selectedRowIndexes: number[];
}) {
  return criteria.map((criterion, index) => {
    if (selectedRowIndexes.includes(index) || selectedRowIndexes.length === 0) {
      return {
        ...criterion,
        useMode: !criterion.useMode,
      };
    }
    return criterion;
  });
}

export default {
  generateNewLevelDescription,
  sanitizeBinaryLevels,
  getDefaultLevelLabels,
  addNewCriterion,
  removeLastCriterion,
  removeSelectedCriteria,
  addLevels,
  removeLevels,
  pruneLevels,
  toggleModeScoringForCriteria,
};
