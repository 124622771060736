import * as _ from 'lodash-es';
import React, { useContext, useState } from 'react';

import { EvaluationEdit } from 'components/Creation/Evaluate';
import Button from 'components/buttons/Button';
import FormField from 'components/core/form/Field';
import FormCheckbox from 'components/core/input/Checkbox';
import NoticeBoard from 'components/layout/NoticeBoard';
import { connect } from 'react-redux';
import { AssignmentContext } from '../../context/AssignmentContext';
import { CreationContext } from '../../context/CreationContext';

const mapStateToProps = (state: any) => {
  return {
    evaluator: state.admin.student,
    instructor: state.admin.prof,
    student: state.admin.creation.user,
    creation: state.admin.creation,
  };
};

export const NOT_FOUND = 'not found';

const AddNewEvaluation = (props: any) => {
  const { evaluator, instructor } = props;
  const [notifications, setNotications] = useState({
    evaluator: false,
    instructor: false,
  });

  const { creation } = useContext(CreationContext);

  const [assignment] = useContext(AssignmentContext);

  const [uploadLoading, setUploadLoading] = useState(false);
  const [isSubmissionSuccess, setIsSubmissionSucces] = useState(false);

  const handleSubmit = async () => {
    setUploadLoading(true);

    window.location.reload();
  };

  const getScore = () => {
    const userScore = _.filter(creation.scores, (score) => {
      return score.user._id == evaluator.user;
    });
    return userScore;
  };

  const renderEvalEditor = () => {
    if (isSubmissionSuccess) {
      return <NoticeBoard type="success">Late Evaluation submitted successfully!</NoticeBoard>;
    }
    let userScore = getScore();
    userScore = userScore.length ? userScore[0] : false;
    return (
      <EvaluationEdit
        key={`evaluation_edit_${creation.user._id}`}
        assignment={assignment.value}
        rubric={creation.assignment.rubric}
        submissionUser={creation.user._id}
        submission={creation}
        evalUser={evaluator.user.toString()}
        onSubmit={() => {
          setIsSubmissionSucces(true);
        }}
        userScore={userScore}
        isAdminPanel
        notifications={notifications}
      />
    );
  };

  return (
    creation !== NOT_FOUND && (
      <React.Fragment>
        <h3>Add a new evaluation:</h3>
        {renderEvalEditor()}
        <FormField>
          <FormCheckbox
            label={`Notify the evaluator (${evaluator.email})`}
            isChecked={notifications.evaluator}
            onChange={() => {
              return setNotications((notifications) => ({
                ...notifications,
                evaluator: !notifications.evaluator,
              }));
            }}
          />
        </FormField>
        <FormField>
          <FormCheckbox
            label={`Notify the instructor (${instructor.email})`}
            isChecked={notifications.instructor}
            onChange={() => {
              return setNotications((notifications) => ({
                ...notifications,
                instructor: !notifications.instructor,
              }));
            }}
          />
        </FormField>

        <div style={{ margin: '50px 0 20px' }}>
          <Button loading={uploadLoading} type="primary" onClick={handleSubmit}>
            Close
          </Button>
        </div>
      </React.Fragment>
    )
  );
};

export default connect(mapStateToProps, {})(AddNewEvaluation);
