import { MAX_WEIGHT, MIN_WEIGHT } from '@kritik/constants/activity';
import validators from 'components/ActivityEdit/Details/Fields/validators';
import ErrorMsg from 'components/Course/Edit/ErrorMsg';
import MarkingSchemeComponent from 'components/Course/Edit/Scoring/MarkingSchemeComponent';
import ScoringTypeField from 'components/Course/Edit/Scoring/ScoringTypeField';
import { COURSE_FIELD_NAMES } from 'components/Course/Edit/constants';
import { TranslatedText } from 'components/TranslatedText';
import Button from 'components/buttons/Button';
import InfoPopup from 'components/core/InfoPopup';
import FormField from 'components/core/form/Field';
import FormFieldInfo from 'components/core/form/FieldInfo';
import FormFieldInput from 'components/core/form/FieldInput';
import FormSubmitButtons from 'components/core/form/SubmitButtons';
import FormFieldTitle from 'components/core/form/Title';
import NumberInput from 'components/core/input/Number';
import { InlineInformation } from 'components/layout';
import PageContainer from 'components/layout/PageContainer/index';
import PageHeader from 'components/layout/PageHeader';
import OnLeaveModal from 'components/modals/OnLeaveModal';
import { updateCourseMutation, useGetCachedCourse } from 'hooks/course';
import { localize } from 'locales';
import { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { RouterProp, withRouter } from 'utils/withRouter';
import localUtils from './utils';

type CourseScoringProps = {
  form: any;
  onCancel: () => void;
  values: {
    defaultActivityWeight: number;
    isPercentage: boolean;
    markingScheme: MarkingScheme;
  };
  error: string;
};
function CourseScoring(props: CourseScoringProps) {
  return (
    <PageContainer>
      <PageHeader title={<TranslatedText i18nKey="Course.Scoring.Edit.PageHeader" />} />
      <ScoringTypeField />

      <FormField>
        <FormFieldTitle label={localize({ message: 'CourseDetails.ScoringOptions.Title.DefaultWeight' })} />
        <FormFieldInput>
          <Field
            name={COURSE_FIELD_NAMES.DEFAULT_ACTIVITY_WEIGHT}
            validate={validators.composeValidators(
              validators.isRequired,
              validators.minValue(0),
              validators.maxValue(9999)
            )}
          >
            {({ input }) => {
              return (
                <>
                  <NumberInput
                    name="activityWeight"
                    value={input.value}
                    onChange={input.onChange}
                    min={MIN_WEIGHT}
                    max={MAX_WEIGHT}
                    testid="default-activity-weight-input"
                    id="default-activity-weight-input"
                    aria-label={localize({
                      message: 'CourseDetails.ScoringOptions.Title.DefaultWeight',
                    })}
                  />
                  <span className="input_unit">
                    <TranslatedText i18nKey="Points" />
                  </span>
                </>
              );
            }}
          </Field>
        </FormFieldInput>
        <FormFieldInfo label={localize({ message: 'Course.Scoring.Edit.DefaultWeight.HelpText' })} />
      </FormField>

      {props.values.isPercentage && (
        <FormField>
          <FormFieldTitle label={localize({ message: 'Course.Scoring.Edit.DefaultStartingScore.Label' })}>
            <InfoPopup
              popoverContentClass="course-scoring-starting-score-popup"
              title={localize({
                message: 'Course.Scoring.Edit.DefaultStartingScore.InfoPopup.Label',
              })}
              description={<TranslatedText i18nKey="Course.Scoring.Edit.DefaultStartingScore.InfoPopup.Description" />}
              learnMoreUrl={localize({ message: 'Course.Scoring.Edit.DefaultStartingScore.Link' })}
            />
          </FormFieldTitle>
          <FormFieldInput>
            <Field
              name={COURSE_FIELD_NAMES.DEFAULT_STARTING_SCORE}
              validate={validators.composeValidators(validators.minValue(0), validators.maxValue(99))}
            >
              {({ input }) => {
                return (
                  <>
                    <NumberInput
                      name="startingScore"
                      value={input.value}
                      onChange={input.onChange}
                      min="0"
                      max="99"
                      testid="default-activity-starting-score"
                      id="default-activity-starting-score-input"
                      aria-label={localize({
                        message: 'Course.Scoring.Edit.DefaultStartingScore.Label',
                      })}
                    />
                    <span className="input_unit">%</span>
                  </>
                );
              }}
            </Field>
          </FormFieldInput>
          <FormFieldInfo label={localize({ message: 'Course.Scoring.Edit.DefaultStartingScore.HelpText' })} />
        </FormField>
      )}

      <MarkingSchemeComponent
        markingScheme={props.values.markingScheme}
        isPercentage={props.values.isPercentage}
        mutators={props.form.mutators}
      />

      {props.error && (
        <InlineInformation className="backend-error" type="danger">
          {props.error}
        </InlineInformation>
      )}

      <FormSubmitButtons errors={<ErrorMsg />}>
        <Button type="primary" inputType="submit" testid="update-course-scoring-button">
          Update
        </Button>
        <Button type="secondary" onClick={props.onCancel}>
          Cancel
        </Button>
      </FormSubmitButtons>
    </PageContainer>
  );
}

type FormWrapperProps = {
  router: RouterProp;
};
function FormWrapper(props: FormWrapperProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  let formRef: any;
  const updateCourse = updateCourseMutation();
  const course = useGetCachedCourse(props.router.params.courseId);

  if (updateCourse.isSuccess) {
    props.router.push(`/course/${props.router.params.courseId}`);
  }

  const handleCancelEdit = () => {
    props.router.push(`/course/${props.router.params.courseId}`);
  };

  if (!course) {
    return null;
  }

  const getInitialFormValues = () => {
    return localUtils.getInitialValues({ course });
  };

  const [formValues, setFormValues] = useState(getInitialFormValues());

  useEffect(() => {
    setFormValues(getInitialFormValues());
  }, [props.router.params.courseId]);

  const onSubmit = (values: any) => {
    const formData = localUtils.getDataFromForm(values);

    const courseData = {
      ...course,
      ...formData,
    };
    setIsSubmitting(true);
    updateCourse.mutate(courseData);
  };

  useEffect(() => {
    setError((updateCourse.error as any)?.response?.data?.errors?.message);
  }, [updateCourse.isError]);

  return (
    <>
      <Form
        onSubmit={onSubmit}
        initialValues={formValues}
        mutators={{
          setValue: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => {
              return value;
            });
          },
        }}
        render={({ handleSubmit, form, values }) => {
          formRef = form;
          return (
            <form onSubmit={handleSubmit}>
              <CourseScoring form={form} values={values} onCancel={handleCancelEdit} error={error} />
            </form>
          );
        }}
      />
      {!isSubmitting && <OnLeaveModal formRef={formRef} />}
    </>
  );
}

export default withRouter(FormWrapper);
