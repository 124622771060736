import { Button } from 'components/buttons';
import Select from 'components/core/form/SelectField';
import { localize } from 'locales';
import { useEffect, useState } from 'react';
import { Field } from 'react-final-form';
import { groupService } from 'services';
import { withRouter } from 'utils/withRouter';

const GroupSetField = ({ router }: any) => {
  const [groupSets, setGroupSets] = useState([]);
  const { courseId } = router.params;

  useEffect(() => {
    const getCourseGroupSets = async () => {
      try {
        const response = await groupService().getAllGroupSets({
          courseId,
        });
        if (response.data) {
          setGroupSets(response.data.filter((groupSet) => !groupSet.isInstance));
        }
      } catch (ignore) {}
    };
    void getCourseGroupSets();
  }, []);

  const groupSetsOptions = groupSets.map((groupSet: any, index: number) => {
    return {
      label: groupSet.name,
      value: groupSet._id,
      testid: `group-set-${index}`,
    };
  });

  return (
    <>
      <div className="groupSet-select-title">
        {localize({ message: 'Activity.EditStudentParticipation.Group.GroupSetField.Title' })}
      </div>
      <div className="groupSet-select-info">
        {localize({ message: 'Activity.EditStudentParticipation.Group.GroupSetField.Info' })}
      </div>
      <Field name="groupSetId">
        {({ input }) => (
          <div className="groupSet-select-container">
            <Select
              value={input.value}
              name={input.name}
              onChange={input.onChange}
              placeholder="Group Sets"
              options={groupSetsOptions}
              testid="presentation-select-group-sets"
            />
            <Button
              type="secondary"
              className="groupSet-manage-group"
              chevron="right"
              onClick={() => router.push(`/course/${courseId}/roster?tab=groups`)}
            >
              {localize({
                message: 'Activity.EditStudentParticipation.Group.GroupSetField.ManageGroups',
              })}
            </Button>
          </div>
        )}
      </Field>
    </>
  );
};

export default withRouter(GroupSetField);
