export const DisputeHeader = ({ label }: any) => {
  return <div className="creation-dispute__header">{label}</div>;
};
export const DisputeDescription = ({ children }: any) => {
  return <div className="creation-dispute__description">{children}</div>;
};

export const DisputeSection = ({ children }: any) => {
  return <div className="creation-dispute__item">{children}</div>;
};
