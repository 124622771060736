import {
  isCalibrationActivity,
  isGroupAssignment,
  isPresentationActivity,
  isWithinGroupActivity,
} from '@kritik/utils/activity';
import Tooltip from '@material-ui/core/Tooltip';
import TableCell from 'components/layout/SimpleTable/Cell';
import ACTIVITY_TYPE_ICONS from 'images/activity-types';

const TitleCell = (props: any) => {
  const renderActivityTitle = () => {
    let activityIcon = null;
    if (isCalibrationActivity(props.activity)) {
      activityIcon = ACTIVITY_TYPE_ICONS.CALIBRATION;
    } else if (isWithinGroupActivity(props.activity)) {
      activityIcon = ACTIVITY_TYPE_ICONS.WITHIN_GROUP;
    } else if (isGroupAssignment(props.activity)) {
      activityIcon = ACTIVITY_TYPE_ICONS.GROUP;
    } else if (isPresentationActivity(props.activity)) {
      activityIcon = ACTIVITY_TYPE_ICONS.PRESENTATION;
    }
    return (
      <Tooltip title={props.activity.title} placement="top">
        <a className="activity-list__title" data-testid={props.testid} tabIndex={0}>
          {activityIcon && <img className="activity-list__title-icon" aria-hidden="true" src={activityIcon} />}
          {props.activity.title}
        </a>
      </Tooltip>
    );
  };

  return (
    <TableCell width="100%" headers={props.headers}>
      {renderActivityTitle()}
    </TableCell>
  );
};

export default TitleCell;
