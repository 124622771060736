import HtmlContent from 'components/General/HtmlContent';
import FileList from 'components/layout/AttachmentManager/FileList';
import React from 'react';

function WrittenEvaluation(props: any) {
  const { evaluation } = props;
  return (
    <React.Fragment>
      <HtmlContent content={evaluation.comment} />
      <FileList files={evaluation.files} />
    </React.Fragment>
  );
}

export default WrittenEvaluation;
