import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import { getCourses } from 'actions/admin';
import { updateCourseMutation } from 'hooks/course';
import { Course } from 'old-common/types.generated';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getCourseList } from 'selectors/course';

function SearchCourseWrapper({ children }: any) {
  return <div className="search-course-wrapper">{children}</div>;
}

function SearchCoursesByInstructor({ getCourses }: any) {
  const [email, setEmail] = useState('');
  const fetchCoursesByProfEmail = () => {
    getCourses({ email });
  };
  const handleProfIdOrEmailChange = (e: any) => {
    setEmail(e.target.value);
  };
  const handlClickSearchCourses = () => {
    fetchCoursesByProfEmail();
  };
  const placeholder = 'Please enter a prof email';
  const label = 'Email';
  return (
    <SearchCourseWrapper>
      <TextField placeholder={placeholder} label={label} value={email} onChange={handleProfIdOrEmailChange} />
      <div>
        <Button variant="contained" size="medium" color="primary" onClick={handlClickSearchCourses}>
          Search
        </Button>
      </div>
    </SearchCourseWrapper>
  );
}

function CourseRow({ course, handleSwitchHideAverageGrade, handleSwitchRevealEvaluatorIdentities }: any) {
  const _handleSwitchRevealEvaluatorIdentities = (e: any) => {
    handleSwitchRevealEvaluatorIdentities(course._id, e.target.checked);
  };

  const _handleSwitchHideAverageGrade = (e: any) => {
    handleSwitchHideAverageGrade(course._id, e.target.checked);
  };

  return (
    <TableRow key={course._id}>
      <TableCell component="th" scope="row">
        {course.title}
      </TableCell>
      <TableCell align="right">
        <Switch onChange={_handleSwitchHideAverageGrade} checked={course.courseSettings?.hideAverageGrade} />
      </TableCell>
      <TableCell align="right">
        <Switch
          edge="end"
          onChange={_handleSwitchRevealEvaluatorIdentities}
          checked={course.courseSettings?.revealEvaluatorIdentities}
        />
      </TableCell>
    </TableRow>
  );
}

function CourseTable({ courses }: any) {
  const [updatedCourses, setUpdatedCourses] = useState(courses);
  const isCoursesEqual = courses.every((course: any, i: any) => {
    return updatedCourses[i] && course._id === updatedCourses[i]._id;
  });
  useEffect(() => {
    setUpdatedCourses(courses);
  }, [courses.length, isCoursesEqual]);

  const updateCourse = updateCourseMutation();

  const handleSwitchHideAverageGrade = (courseId: string, checked: boolean) => {
    const _updatedCourses = updatedCourses.map((course: Course) => {
      if (course._id === courseId) {
        course.courseSettings.hideAverageGrade = checked;
        updateCourse.mutate(course);
      }
      return course;
    });
    setUpdatedCourses(_updatedCourses);
  };

  const handleSwitchRevealEvaluatorIdentities = (courseId: string, checked: boolean) => {
    const _updatedCourses = updatedCourses.map((course: Course) => {
      if (course._id === courseId) {
        course.courseSettings.revealEvaluatorIdentities = checked;
        updateCourse.mutate(course);
      }
      return course;
    });
    setUpdatedCourses(_updatedCourses);
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width="70%">Course</TableCell>
            <TableCell align="right">Hide Average Grade</TableCell>
            <TableCell align="right">Reveal Evaluator Identities</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {updatedCourses.map((course: any) => {
            return (
              <CourseRow
                course={course}
                handleSwitchRevealEvaluatorIdentities={handleSwitchRevealEvaluatorIdentities}
                handleSwitchHideAverageGrade={handleSwitchHideAverageGrade}
              />
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function CourseSettings({ courses, getCourses, user }: any) {
  useEffect(() => {
    getCourses({ email: user.email });
  }, []);
  return (
    <div>
      <SearchCoursesByInstructor getCourses={getCourses} />
      <CourseTable courses={courses} />
    </div>
  );
}

const mapStateToProp = (state: any) => {
  return {
    courses: getCourseList(state),
    user: state.user.authUser,
  };
};

export default connect(mapStateToProp, {
  getCourses,
})(CourseSettings);
