import { createRestApiClient } from 'utils/createRestApiClient';

const apiEndpoint = import.meta.env.VITE_API_URL;

export default (options = {}) => {
  const client = (options as any).client || createRestApiClient().withConfig({ baseURL: apiEndpoint });
  return {
    get: ({ id }: any) => {
      return client.request({
        method: 'GET',
        url: `/activities/${id}`,
      });
    },
    delete: ({ id }: any) => {
      return client.request({
        method: 'DELETE',
        url: `/activities/${id}`,
      });
    },
    deleteFinalized: ({ id, courseId }: any) => {
      return client.request({
        method: 'DELETE',
        url: `/activities/${id}/finalize`,
        params: { courseId },
      });
    },
    list: (params = {}) => {
      let url = '/activities';
      if ((params as any).courseId) {
        url = `/courses/${(params as any).courseId}${url}`;
      }

      return client.request({
        method: 'GET',
        url,
        params,
      });
    },
    finalize: (assignmentId: any) => {
      return client.request({
        method: 'GET',
        url: `/activities/${assignmentId}/finalize`,
      });
    },
    updateSchedule: ({ data }: any) => {
      return client.request({
        method: 'PUT',
        url: `/assignment-statuses`,
        data,
      });
    },
    update: ({ id, data }: any) => {
      return client.request({
        method: 'PUT',
        url: `/activities/${id}`,
        data,
      });
    },
    create: ({ data }: any) => {
      return client.request({
        method: 'POST',
        url: `/activities`,
        data,
      });
    },
    createSubmission: ({ assignmentId, data }: any) => {
      return client.request({
        method: 'POST',
        url: `/activities/${assignmentId}/submissions`,
        data,
      });
    },
    approveSubmissionEvaluation: ({ assignmentId, evaluatorId, submitterId, data }: any) => {
      return client.request({
        method: 'POST',
        url: `/activities/${assignmentId}/evaluations/${submitterId}/${evaluatorId}/approve`,
        data,
      });
    },
    commentSubmissionEvaluation: ({ assignmentId, submitterId, evaluatorId, data }: any) => {
      return client.request({
        method: 'POST',
        url: `/activities/${assignmentId}/evaluations/${submitterId}/${evaluatorId}/comments`,
        data,
      });
    },
    createEvaluationFof: ({ submissionId, scoreId, data }: any) => {
      return client.request({
        method: 'POST',
        url: `/submissions/${submissionId}/scores/${scoreId}/feedback`,
        data,
      });
    },
    createEvaluationDispute: ({ submissionId, data }: any) => {
      return client.request({
        method: 'POST',
        url: `/submissions/${submissionId}/scores/dispute`,
        data,
      });
    },
    createEvaluationFlag: ({ submissionId, scoreId, data }: any) => {
      return client.request({
        method: 'POST',
        url: `/submissions/${submissionId}/scores/${scoreId}/flag`,
        data,
      });
    },
    getSubmissions: (params = {}) => {
      params = { ...params };
      // @ts-expect-error TS(2339) FIXME: Property 'assignmentId' does not exist on type '{}... Remove this comment to see the full error message
      const { assignmentId } = params;
      delete (params as any).assignmentId;

      return client.request({
        method: 'GET',
        url: `/activities/${assignmentId}/submissions`,
        params,
      });
    },
    resolveDispute: (data: any) => {
      return client.request({
        method: 'PATCH',
        url: `/submissions/${data.submissionId}/resolve-dispute`,
        data,
      });
    },
    resolveLateSubmission: (data: any) => {
      return client.request({
        method: 'PATCH',
        url: `/submissions/${data.submissionId}/resolve-late-submission`,
        data,
      });
    },
    createGroupActivitySubmissions: (data: any) => {
      return client.request({
        method: 'POST',
        url: `/group-activities/initializeSubmissions`,
        data,
      });
    },
    getActivityStats: (data: any) => {
      return client.request({
        method: 'POST',
        url: `/activities/stats`,
        data,
      });
    },
    getActivityScores: (activityId: any) => {
      return client.request({
        method: 'GET',
        url: `/activities/${activityId}/stats/scores`,
      });
    },
    getAvgGroupScoresByCreationId: ({ activityId, creationId }: any) => {
      return client.request({
        method: 'GET',
        url: `/activities/${activityId}/stats/scores/group/${creationId}`,
      });
    },
    getNumEligibleEvaluatorsByActivityId: ({ activityId, creationId }: any) => {
      return client.request({
        method: 'GET',
        url: `/activities/${activityId}/creation/${creationId}/eligible-evaluators`,
      });
    },
    updateWeight: (data: any) => {
      return client.request({
        method: 'PUT',
        url: `/activities/update-weight`,
        data,
      });
    },
    importActivity: (data: any) => {
      return client.request({
        method: 'POST',
        url: `/activities/import`,
        data,
      });
    },
    listCreationsByActivity: (params: PaginationQueryParams) => {
      const selectedParams: (keyof PaginationQueryParams)[] = [
        'page',
        'resultsPerPage',
        'searchString',
        'filterBy',
        'sortBy',
        'sortOrder',
        'viewType',
      ];
      if (params.creationId) {
        selectedParams.push('creationId');
      }

      const queryString = Object.keys(params)
        .filter((k: keyof PaginationQueryParams) => selectedParams.includes(k) && Boolean(params[k]))
        .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
        .join('&');

      return client.request({
        method: 'GET',
        url: `/activities/${encodeURIComponent(params.activityId)}/creations?${queryString}`,
      });
    },
    getDisputesByActivity: ({ activityId }: { activityId: string }) => {
      return client.request({
        method: 'GET',
        url: `/activities/${encodeURIComponent(activityId)}/disputes/export`,
      });
    },
    getActivityValidRevertStage: ({ activityId }: any) => {
      return client.request({
        method: 'GET',
        url: `/activities/${encodeURIComponent(activityId)}/valid-revert-stage`,
      });
    },
    revertActivity: (data: any) => {
      return client.request({
        method: 'POST',
        url: `/activities/revert`,
        data,
      });
    },
    advanceActivityStageById: (activityId: string) => {
      return client.request({
        method: 'POST',
        url: `/activities/${activityId}/advance-stage`,
      });
    },
    editStudentParticipation: ({ activityId, ...data }) => {
      return client.request({
        method: 'POST',
        url: `/activities/${encodeURIComponent(activityId)}/edit-student-participation`,
        data,
      });
    },
    getActivityGroupSet: ({ activityId }) => {
      return client.request({
        method: 'GET',
        url: `/activities/${activityId}/group-set`,
      });
    },
    removeStudentFromPresentationActivity: (data: { activityId: string; studentId: string }) => {
      return client.request({
        method: 'POST',
        url: '/activities/remove-student-from-activity',
        data,
      });
    },
  };
};
