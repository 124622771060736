import { isScoreViewReady } from '@kritik/utils/creation/score';
import Toast from 'components/General/Toast';
import { TranslatedText } from 'components/TranslatedText';
import Button from 'components/buttons/Button';
import ButtonContainer from 'components/buttons/ButtonContainer';
import Banner from 'components/core/Banner';
import CELEBRATION_TAG from 'images/spotlight-icons/badge-celebration.svg';
import { localize } from 'locales';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { dismissScoreViewNotification } from 'redux/creation';

const ScoreToast = (props: any) => {
  useEffect(() => {
    if (props.creation && isScoreViewReady(props.creation)) {
      window.scrollTo(0, 0);
    }
  }, [props.creation]);

  if (!props.creation) {
    return null;
  }

  const isOpen = () => {
    return isScoreViewReady(props.creation);
  };

  const onClose = () => {
    props.dismissScoreViewNotification(props.creation._id);
  };

  return (
    <Toast isOpen={isOpen()} onClose={onClose}>
      <Banner
        image={<img src={CELEBRATION_TAG} aria-hidden="true" />}
        title={localize({ message: 'ScoreToast.Title' })}
        description={localize({ message: 'ScoreToast.Description' })}
      >
        <ButtonContainer>
          <Button data-testid="view-creation-score" type="secondary" onClick={onClose}>
            <TranslatedText i18nKey="ScoreToast.ViewCreationScore" />
          </Button>
        </ButtonContainer>
      </Banner>
    </Toast>
  );
};

export default connect(null, { dismissScoreViewNotification })(ScoreToast);
