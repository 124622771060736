import { navigateToActivityPage } from 'actions/activity';
import getPaginatedResults from 'actions/pagination/actions';
import classNames from 'classnames';
import HeaderCell from 'components/CreationList/Header/Cell';
import { localize } from 'locales';
import { connect } from 'react-redux';
import { setCurrentPage, setSortedColumn } from 'redux/creationTable/actions';
import { selectCreationTableViewType } from 'redux/creationTable/select';
import { ROW_HEIGHT } from '../constant';
import ListContext from '../context';

const HeaderRow = (props: any) => {
  const headerClasses = classNames('creation-table-header');
  const getStyle = (index: any) => {
    return {
      top: index * ROW_HEIGHT - 1,
      left: 0,
      width: '100%',
      height: ROW_HEIGHT,
    };
  };

  const handleSelect = (columns: any, index: any) => {
    props.setSortedColumn(columns, index);
    props.setCurrentPage(1);
    props.navigateToActivityPage({ courseId: props.courseId, assignmentId: props.activityId });
    props.getPaginatedResults();
  };

  const createHeaders = (columns: any, viewType: any) => {
    return columns.map((col: any, index: any) => {
      return (
        <HeaderCell
          key={index}
          column={col}
          viewType={viewType}
          onSelect={() => {
            return handleSelect(columns, index);
          }}
        />
      );
    });
  };

  return (
    <ListContext.Consumer>
      {({ stickyIndices, columns, viewType }) => {
        return (
          <>
            {stickyIndices.length === 0 ? (
              <thead className="visually-hidden">
                <tr>
                  <th scope="col">{localize({ message: 'Column.Header.UserAvatar' })}</th>
                  <th scope="col">{localize({ message: 'Column.Header.UserName' })}</th>
                </tr>
              </thead>
            ) : (
              <thead>
                {stickyIndices.map((index: any) => {
                  return (
                    <tr key={index} className={headerClasses} style={getStyle(index)}>
                      {createHeaders(columns, viewType)}
                    </tr>
                  );
                })}
              </thead>
            )}
          </>
        );
      }}
    </ListContext.Consumer>
  );
};

const mapStateToProps = (state: any) => {
  return {
    viewType: selectCreationTableViewType(state),
    courseId: state.selected.courseId,
    activityId: state.selected.assignmentId,
  };
};

export default connect(mapStateToProps, {
  setSortedColumn,
  getPaginatedResults,
  navigateToActivityPage,
  setCurrentPage,
})(HeaderRow);
