import { ACTIVITY_TYPES } from '@kritik/constants/activity';
import { isEvaluateOrLater } from '@kritik/utils/stage';
import FormInputSwitch from 'components/Form/FormInputSwitch';
import { ACCEPT_LATE_CREATIONS, ACCEPT_LATE_EVALUATIONS } from 'components/LateCreation/constants';
import { localize } from 'locales';
import { Field, FormSpy } from 'react-final-form';
import { connect } from 'react-redux';
import { getAssignment } from 'selectors/activity';
import { FIELD_NAME } from './constants';

function MissedCreationOption(props: any) {
  if (props.values.activityType !== ACTIVITY_TYPES.PEER && props.values.activityType !== ACTIVITY_TYPES.GROUP) {
    return null;
  }

  const handleSwitchChange = (currentValue: boolean, onChange: (value: boolean) => void) => {
    props.form.mutators.setValue(ACCEPT_LATE_CREATIONS, currentValue);
    props.form.mutators.setValue(ACCEPT_LATE_EVALUATIONS, currentValue);
    return onChange(!currentValue);
  };
  return (
    <Field name={FIELD_NAME}>
      {({ input }) => {
        return (
          <div className="removeStudentFromActivityAfterMissedCreation-container">
            <FormInputSwitch
              name={FIELD_NAME}
              label={localize({
                message: 'Activity.removeStudentFromActivityAfterMissedCreation.Title',
              })}
              checked={input.value}
              onChange={() => handleSwitchChange(input.value, input.onChange)}
              disabled={isEvaluateOrLater(props.activity)}
              testId="missed-creation-switch"
              helpText={localize({
                message: 'Activity.removeStudentFromActivityAfterMissedCreation.Title.Under',
              })}
            />
          </div>
        );
      }}
    </Field>
  );
}

const mapStateToProps = (state: any) => ({
  activity: getAssignment(state),
});

const withConnect = connect(mapStateToProps, {})(MissedCreationOption);

export default function Spy(props: any) {
  return <FormSpy component={withConnect} />;
}
