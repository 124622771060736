import { Card } from '@material-ui/core';
import { localize } from 'locales';

const CardContent = (props: any) => {
  return (
    <div className="spotlight-card__wrapper" aria-label={localize({ message: 'Activity.SpotlightCard' })}>
      <Card className="spotlight-card">{props.children}</Card>
    </div>
  );
};

export default CardContent;
