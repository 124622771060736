import { Creation } from '@kritik/types.generated';
import * as _ from 'lodash-es';

export const LATE_STATUS = {
  PENDING: 'pending',
  ACCEPTED: 'accepted',
  REJECTED: 'rejected',
};

export function isLateSubmission(creation: Creation) {
  return !_.isEmpty(creation.lateSubmission);
}

export function getReasonForLateSubmission(creation: Creation) {
  if (!isLateSubmission(creation)) {
    return null;
  }
  return creation.lateSubmission.reason;
}

export function isLateSubmissionPending(creation: Creation) {
  if (!isLateSubmission(creation)) {
    return null;
  }
  return creation.lateSubmission.status === LATE_STATUS.PENDING;
}

export function isLateSubmissionContentLocked(creation: Creation) {
  if (!isLateSubmission(creation)) {
    return null;
  }
  return creation.lateSubmission.isContentLocked;
}

export function isLateSubmissionAccepted(creation: Creation) {
  if (!isLateSubmission(creation)) {
    return null;
  }
  return creation.lateSubmission.status === LATE_STATUS.ACCEPTED;
}

export function isLateSubmissionRejected(creation: Creation) {
  if (!isLateSubmission(creation)) {
    return null;
  }
  return creation.lateSubmission.status === LATE_STATUS.REJECTED;
}

export function getResolvedDate(creation: Creation) {
  if (!isLateSubmission(creation)) {
    return null;
  }
  return creation.lateSubmission ? creation.lateSubmission.resolvedDate : null;
}
