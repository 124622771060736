import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { forgotPassword } from 'actions/users';
import { useState } from 'react';
import { connect } from 'react-redux';

const useForm = (defaultValues = {}) => {
  const [values, setFormValues] = useState({
    email: (defaultValues as any).email || '',
  });

  const setValues = (field: any, value: any) => {
    setFormValues({
      ...values,
      [field]: value,
    });
  };
  return {
    values,
    setValues,
  };
};

function Users({ forgotPassword }: any) {
  const { values, setValues } = useForm();
  const [resetUrl, setResetUrl] = useState('');
  return (
    <div>
      <TextField
        fullWidth
        label="User email"
        value={values.email}
        onChange={(e) => {
          setValues('email', e.target.value);
        }}
      />

      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          forgotPassword(
            { email: values.email },
            {
              onSuccess: setResetUrl,
            }
          );
        }}
      >
        Get forget password reset url
      </Button>
      <div>{resetUrl}</div>
    </div>
  );
}

const mapStateToProps = () => {};

export default connect(mapStateToProps, {
  forgotPassword,
})(Users);
