import { isCreateOrLater } from '@kritik/utils/stage';
import { AdvancedOptionField } from 'components/ActivityEdit/AdvancedOptions/Field';
import validators from 'components/ActivityEdit/Details/Fields/validators';
import { TranslatedText } from 'components/TranslatedText';
import FormFieldInfo from 'components/core/form/FieldInfo';
import NumberField from 'components/core/form/NumberField';
import FormRadio from 'components/core/form/RadioField';
import FormTitle from 'components/core/form/Title';
import NoticeBoard from 'components/layout/NoticeBoard';
import { FormApi } from 'final-form';
import { localize } from 'locales';
import * as _ from 'lodash-es';
import { Activity } from 'old-common/types.generated';
import { useEffect } from 'react';
import { Field, FormSpy } from 'react-final-form';
import { IS_MULTIPLE_TOPICS, NUMBER_OF_TOPICS } from './constants';

const letters = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
];

const ActivityTopic = (props: { activity: Activity; values: any; form: FormApi }) => {
  const isMultiTopicsEnabled = Boolean(props.values[IS_MULTIPLE_TOPICS]);
  const numberOfTopics = props.values[NUMBER_OF_TOPICS];
  const topicsToLetters = _.range(numberOfTopics)
    .map((i) => letters[i])
    .join(', ');
  const canUpdate = !isCreateOrLater(props.activity);

  useEffect(() => {
    /**
     * If the activity has no topics and it's a multitopic activity, it means it was created before KR-4808 was released.
     * To make sure the instructor doesn't see "0 Topics" in the UI, we update the number of topics to 2.
     * This can be removed after some time.
     */
    if (props.values[IS_MULTIPLE_TOPICS] && props.values[NUMBER_OF_TOPICS] < 2) {
      setTimeout(() => {
        props.form.change(NUMBER_OF_TOPICS, 2);
      }, 100);
    }
  }, []);

  return (
    <AdvancedOptionField>
      <FormTitle label={localize({ message: 'ActivityTopic.MultipleTopics' })} />
      <Field name={IS_MULTIPLE_TOPICS} validate={validators.isRequired}>
        {({ input }) => {
          return (
            <FormRadio
              options={[
                {
                  value: false,
                  label: localize({ message: 'ActivityTopic.Disabled' }),
                  testid: 'activity-multitopics-disable-btn',
                  isDisabled: isMultiTopicsEnabled && !canUpdate,
                },
                {
                  value: true,
                  label: localize({ message: 'ActivityTopic.Enabled' }),
                  testid: 'activity-multitopics-enable-btn',
                  isDisabled: !isMultiTopicsEnabled && !canUpdate,
                },
              ]}
              onChange={(e: any) => {
                const shouldEnable = e.target.value === 'true';
                if (shouldEnable && numberOfTopics < 2) {
                  /**
                   * we're updating the activity to use multiple topics.
                   * because the activity was already created without any topics,
                   * we need to update the number of topics to 2
                   */
                  props.form.change(NUMBER_OF_TOPICS, 2);
                }
                return input.onChange(shouldEnable);
              }}
              value={input.value}
              disabled
              type="button"
              info={localize({ message: 'ActivityTopic.MultipleTopicsInfo' })}
              legendText="Legend.Text.MultipleTopics"
            />
          );
        }}
      </Field>
      {isMultiTopicsEnabled && (
        <>
          {canUpdate && (
            <Field name={NUMBER_OF_TOPICS} validate={validators.isRequired} defaultValue={2}>
              {({ input }) => (
                <NumberField
                  label={localize({ message: 'ActivityTopic.NumberOfTopics' })}
                  value={input.value}
                  onChange={(e: any) => {
                    return input.onChange(Number(e.target.value));
                  }}
                  min={2}
                  max={26}
                  testid="number-of-topics"
                />
              )}
            </Field>
          )}
          <div className="activity-edit-topic__info">
            <NoticeBoard title={localize({ message: 'ActivityTopic.HowItWorks' })}>
              <div className="activity-edit-topic__info-section">
                <FormFieldInfo>
                  <FormTitle size="md" label={localize({ message: 'ActivityTopic.Create' })} />
                  <TranslatedText i18nKey="ActivityTopic.CreateInfo" />
                </FormFieldInfo>
                <FormFieldInfo type="attention">
                  <TranslatedText
                    i18nKey="ActivityTopic.TopicsInfo"
                    values={{ topics: numberOfTopics, letters: topicsToLetters }}
                  />
                </FormFieldInfo>
              </div>
              <div className="activity-edit-topic__info-section">
                <FormFieldInfo>
                  <FormTitle size="md" label={localize({ message: 'ActivityTopic.Evaluate' })} />
                  <TranslatedText i18nKey="ActivityTopic.EvaluateInfo" />
                </FormFieldInfo>
                <FormFieldInfo type="attention">
                  <TranslatedText i18nKey="ActivityTopic.EvaluateDifferentTopics" />
                </FormFieldInfo>
              </div>
              <div className="activity-edit-topic__info-section">
                <FormFieldInfo>
                  <FormTitle size="md" label={localize({ message: 'ActivityTopic.Feedback' })} />
                  <TranslatedText i18nKey="ActivityTopic.FeedbackInfo" />
                </FormFieldInfo>
              </div>
            </NoticeBoard>
          </div>
        </>
      )}
    </AdvancedOptionField>
  );
};

const Spy = (props: any) => {
  return <FormSpy component={ActivityTopic} {...props} />;
};

export default Spy;
