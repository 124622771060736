import { InlineInformation } from 'components/layout';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';

const ShowMessage = ({ domain, institutionExists, warningMessage }: any) => {
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    setShowMessage(false);
  }, [domain]);

  useEffect(() => {
    if (institutionExists !== null && domain !== '') {
      setShowMessage(true);
    }
  }, [institutionExists]);

  if (showMessage || warningMessage) {
    if (warningMessage) {
      return <InlineInformation type="danger" style={{ marginTop: '10px' }} title={warningMessage} />;
    }
    if (institutionExists) {
      return (
        <InlineInformation
          type="danger"
          style={{ marginTop: '10px' }}
          title={warningMessage || `This institution already exists with an id of ${institutionExists._id}`}
        />
      );
    }
    return <InlineInformation type="success" style={{ marginTop: '10px' }} title="This institution is unique" />;
  }
  return null;
};

const mapStateToProps = (state: any) => {
  return {
    institutionExists: state.admin.institutionExists,
  };
};

export default connect(mapStateToProps)(ShowMessage);
