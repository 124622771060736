import { mergeEntities, removeEntities, syncEntities } from 'actions/entities';
import { normalize } from 'normalizr';
import { SubmissionError } from 'redux-form';
import { setSelectedRubricId } from 'redux/rubricManager/actions';
import { rubricSchema } from 'schemas';
import { rubricService } from 'services';
import * as types from 'types';
import {
  HIGHLIGHTED_RUBRIC_CRITERIA,
  HIGHLIGHTED_RUBRIC_LEVEL,
  RUBRIC_DROPDOWN_OPEN,
  UPDATE_RUBRIC,
  UPDATE_RUBRIC_FAILURE,
  UPDATE_RUBRIC_SUCCESS,
} from 'types';
import * as ErrorUtils from 'utils/error';

import rubricManagerTypes from 'redux/rubricManager/types';

export function listRubrics(params = {}, { callback, client }: any = {}) {
  return (dispatch: any, getState: any) => {
    dispatch({ type: types.LIST_RUBRICS_REQUEST, payload: { params } });
    return rubricService({ client })
      .listRubrics(params)
      .then((res: any) => {
        if (res.status === 200) {
          const normalized = normalize(res.data, [rubricSchema]);
          dispatch(mergeEntities(normalized.entities));
          dispatch({
            type: 'LIST_RUBRICS_SUCCESS',
            payload: { params, items: normalized.result },
          });
          if (typeof callback === 'function') {
            callback(res.data);
          }
        }
      })
      .catch((err: any) => {
        dispatch({ type: types.LIST_RUBRICS_FAILURE, payload: err });
        if (typeof callback === 'function') {
          callback([]);
        }
      });
  };
}

export function getRubric(params = {}, { client, callback, reject }: any = {}) {
  return (dispatch: any, getState: any) => {
    dispatch({ type: 'GET_RUBRIC_REQUEST', payload: (params as any).id });

    return rubricService({ client })
      .getRubric(params)
      .then((res: any) => {
        const normalized = normalize(res.data, rubricSchema);
        dispatch(mergeEntities(normalized.entities));
        dispatch({ type: 'GET_RUBRIC_SUCCESS', payload: normalized.result });
        if (typeof callback === 'function') {
          callback(res.data);
        }
      })
      .catch((err: any) => {
        dispatch({ type: 'GET_RUBRIC_FAILURE', payload: err, error: true });
        reject(err);
      });
  };
}

export function updateRubric(rubric: any, actionId: any) {
  return async (dispatch: any) => {
    dispatch({
      type: UPDATE_RUBRIC,
    });
    try {
      const res = await rubricService().updateRubric(rubric);
      const updatedRubric = res.data;
      const normalized = normalize(updatedRubric, rubricSchema);
      dispatch(syncEntities(Object.values(normalized.entities.rubrics)[0], 'rubrics'));
      dispatch({
        type: UPDATE_RUBRIC_SUCCESS,
        success: actionId,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_RUBRIC_FAILURE,
        error: (error as any).response.data.errors,
      });
      throw new SubmissionError((error as any).response.data.errors);
    }
  };
}

export function updateRubricHighlightedCriteria(level: any) {
  return (dispatch: any) => {
    dispatch({
      type: HIGHLIGHTED_RUBRIC_CRITERIA,
      payload: { level },
    });
  };
}

export function updateRubricHighlightedLevel(level: any) {
  return (dispatch: any) => {
    dispatch({
      type: HIGHLIGHTED_RUBRIC_LEVEL,
      payload: { level },
    });
  };
}

export function openRubricDropdown(open: any) {
  return (dispatch: any) => {
    dispatch({
      type: RUBRIC_DROPDOWN_OPEN,
      payload: { open },
    });
  };
}

export function deleteRubric(rubric: any) {
  return async (dispatch: any) => {
    dispatch({
      type: rubricManagerTypes.DELETE_RUBRIC,
    });
    try {
      const res = await rubricService().delete(rubric);
      const updatedRubric = res.data;
      const normalized = normalize(updatedRubric, rubricSchema);
      dispatch(removeEntities(normalized.entities));
      dispatch({
        type: rubricManagerTypes.DELETE_RUBRIC_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: rubricManagerTypes.DELETE_RUBRIC_FAILURE,
        error,
      });
    }
  };
}

export function copyRubricTemplate(rubric: any, user: any) {
  return async (dispatch: any) => {
    dispatch({
      type: rubricManagerTypes.COPY_RUBRIC,
    });
    try {
      const res = await rubricService().copyRubricTemplate({ rubric, user });
      const newRubric = res.data;
      const normalized = normalize(newRubric, rubricSchema);
      dispatch(mergeEntities(normalized.entities));
      dispatch(setSelectedRubricId(newRubric._id));
      dispatch({ type: rubricManagerTypes.COPY_RUBRIC_SUCCESS, payload: res.data });
    } catch (error) {
      dispatch({
        type: rubricManagerTypes.COPY_RUBRIC_FAILURE,
        error,
      });
      throw new SubmissionError((error as any).response.data.errors);
    }
  };
}

export function createRubricTemplate({ rubric, userId }: any) {
  return async (dispatch: any) => {
    dispatch({
      type: types.CREATE_NEW_TEMPLATE,
    });
    try {
      const res = await rubricService().createTemplate({ rubric, userId });
      const newRubric = res.data;
      const normalized = normalize(newRubric, rubricSchema);
      dispatch(mergeEntities(normalized.entities));
      dispatch(setSelectedRubricId(newRubric._id));
      dispatch({ type: types.CREATE_NEW_TEMPLATE_SUCCESS, success: true });
    } catch (error) {
      dispatch({
        type: types.CREATE_NEW_TEMPLATE_FAILURE,
        error: ErrorUtils.getErrorMessageFromResponse(error),
      });
      throw new Error(ErrorUtils.getErrorMessageFromResponse(error));
    }
  };
}
