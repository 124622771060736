import { InlineInformation } from 'components/layout';
import { localize } from 'locales';
import { isCourseConnected } from 'utils/lms';

export function EmptyStudentListPlaceholder({ course, hasFirstSuccessfulSync }: any) {
  if (!hasFirstSuccessfulSync && isCourseConnected(course)) {
    return (
      <div className="roster-container" data-testid="connected-course-no-student-placeholder">
        <h3>{localize({ message: 'RosterManager.ConnectedCourse.StudentsTab.Title' })}</h3>
        <div>{localize({ message: 'RosterManager.ConnectedCourse.StudentsTab.Content' })}</div>
        <InlineInformation type="information">
          <b>{localize({ message: 'RosterManager.StudentsTab.Info.Title' })}</b>
          <p>{localize({ message: 'RosterManager.StudentsTab.Info.Content' })}</p>
        </InlineInformation>
      </div>
    );
  }

  return (
    <div className="roster-container" data-testid="no-student-placeholder">
      <h3>{localize({ message: 'RosterManager.StudentsTab.Title' })}</h3>
      <div>{localize({ message: 'RosterManager.StudentsTab.Content' })}</div>
      <InlineInformation type="information">
        <b>{localize({ message: 'RosterManager.StudentsTab.Info.Title' })}</b>
        <p>{localize({ message: 'RosterManager.StudentsTab.Info.Content' })}</p>
      </InlineInformation>
    </div>
  );
}

export default EmptyStudentListPlaceholder;
