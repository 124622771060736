import { AiScore, Rubric } from '@kritik/types.generated';
import { CreationScoreUtil } from '@kritik/utils/grade';
import { getLevelsInCriteria, isRowBinary } from '@kritik/utils/rubric';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import GradeRoundedIcon from '@material-ui/icons/GradeRounded';
import { Rating } from '@material-ui/lab';
import { TranslatedText } from 'components/TranslatedText';

type AiEvaluationTabProps = {
  aiScores: AiScore[];
  rubric: Rubric;
};

function Row({ rubric, aiScore }: { rubric: Rubric; aiScore: AiScore }) {
  const criterion = rubric.criteria[aiScore.rubricCriterionIndex];
  const levels = getLevelsInCriteria(rubric, aiScore.rubricCriterionIndex);
  return (
    <>
      <tr>
        <td className="criterion-cell">{criterion.name}</td>
        <td className="weight-cell">{criterion.weight}</td>
        <td>
          <ScoreRating rubric={rubric} mark={aiScore.score} criterionIndex={aiScore.rubricCriterionIndex} />
        </td>
        <td className="percentage-cell">
          {CreationScoreUtil.calcCriterionScoreInPercent(aiScore.score, levels).toFixed()}%
        </td>
      </tr>
      <tr>
        <td colSpan={4} className="row-separation">
          <div className="explanation-container">
            <TranslatedText i18nKey="AiEvaluationTab.Reason" /> - {aiScore.explanation}
          </div>
        </td>
      </tr>
    </>
  );
}

const RatingIcon = ({ level }: { level: string[] }) => {
  if (isRowBinary(level)) {
    return <CheckRoundedIcon />;
  }
  return <GradeRoundedIcon />;
};

type ScoreRatingProps = {
  mark: number;
  criterionIndex: number;
  rubric: Rubric;
};
function ScoreRating({ mark, criterionIndex, rubric }: ScoreRatingProps) {
  return (
    <div className="score-display__star-column">
      <div className="score-display__score-description"></div>
      <span style={{ marginRight: '1rem' }}>
        <Rating max={1} icon={<ClearRoundedIcon />} value={mark === 0 ? 1 : 0} readOnly className="rating" />
      </span>
      <Rating
        name={`primary-grade-${criterionIndex}`}
        max={rubric.grid[criterionIndex].length - 1}
        value={mark === 0 ? 0 : mark}
        precision={0.1}
        className="rating"
        readOnly
        icon={<RatingIcon level={rubric.grid[criterionIndex]} />}
      />
    </div>
  );
}

function Header() {
  return (
    <tr>
      <th className="criterion-header">
        <TranslatedText i18nKey="AiEvaluationTab.Header.Criterion" />
      </th>
      <th className="weight-header">
        <TranslatedText i18nKey="AiEvaluationTab.Header.Weight" />
      </th>
      <th className="score-header">
        <TranslatedText i18nKey="AiEvaluationTab.Header.Score" />
      </th>
      <th className="percentage-header">
        <TranslatedText i18nKey="AiEvaluationTab.Header.Percentage" />
      </th>
    </tr>
  );
}

function AiEvaluationTab({ aiScores, rubric }: AiEvaluationTabProps) {
  return (
    <div className="ai-evaluation-tab-container">
      <h4>
        <TranslatedText i18nKey="AIEvaluationTab.Title" />
      </h4>
      <table style={{ width: '100%' }}>
        <Header />
        {rubric.criteria.map((_, index) => {
          const aiScore = aiScores.find((aiScore) => aiScore.rubricCriterionIndex === index);
          return <Row aiScore={aiScore} rubric={rubric} />;
        })}
      </table>
    </div>
  );
}

export default AiEvaluationTab;
