import { Card } from '@material-ui/core';
import { TranslatedText } from 'components/TranslatedText';
import Button from 'components/buttons/Button';
import { localize } from 'locales';

const OptionCard = (props: any) => {
  const renderIcon = () => {
    return (
      <div className="activity-type-option-card__icon">
        <img src={props.icon} title={props.title} />
      </div>
    );
  };

  const renderHeader = () => {
    return <h6>{props.title}</h6>;
  };

  const renderSelectButton = () => {
    return (
      <Button
        type="secondary"
        isActive={props.selected}
        data-testid={`select-${props.title.toLowerCase()}-activity`}
        label={`${props.selected ? '' : localize({ message: 'Button.Label.Select' })} ${localize({
          message: 'Button.Label.ActivityType',
          options: { activityType: props.title.toLowerCase() },
        })} ${props.selected ? localize({ message: 'Button.Label.Selected' }) : ''}`}
      >
        <TranslatedText
          i18nKey={
            props.selected
              ? 'ActivityEdit.TypeSelection.OptionCard.Selected'
              : 'ActivityEdit.TypeSelection.OptionCard.Select'
          }
        />
      </Button>
    );
  };

  const renderDescription = () => {
    return <p className="activity-type-option-card__description">{props.description}</p>;
  };

  return (
    <Card
      className="activity-type-option-card"
      onClick={() => {
        return props.selectCard();
      }}
    >
      <div className="activity-option-card-content">
        {renderIcon()}
        {renderHeader()}
        {renderDescription()}
      </div>
      {renderSelectButton()}
    </Card>
  );
};

export default OptionCard;
