import { Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from '@material-ui/core';
import CourseCodeField from 'components/Course/Edit/CourseDetails/CourseCodeField';
import CourseNameField from 'components/Course/Edit/CourseDetails/CourseNameField';
import CourseSectionField from 'components/Course/Edit/CourseDetails/CourseSectionField';
import { FieldLayout } from 'components/Course/Edit/CourseDetails/Layout';
import { TranslatedText } from 'components/TranslatedText';
import Button from 'components/buttons/Button';
import ButtonContainer from 'components/buttons/ButtonContainer';
import { useIsFeatureFlagEnabled } from 'context/growthbookContext';
import { localize } from 'locales';
import { Form } from 'react-final-form';
import { CourseTermField } from '../Edit/CourseDetails/CourseTermField';

const useStyles = makeStyles({
  root: {
    justifyContent: 'flex-start',
    paddingLeft: '0',
    paddingRight: '0',
    marginTop: '1.5rem',
  },

  activityIncludeSubTitle: {
    marginLeft: '1.9rem',
  },
});

function CreateCourse(props: any) {
  const classes = useStyles();
  const isAiActivityCreationEnabled = useIsFeatureFlagEnabled('create_activity_ai');

  return (
    <Dialog
      open={props.open}
      onClose={() => {
        return props.onClose();
      }}
      aria-labelledby="create-dialog-title"
      aria-describedby="create-dialog-description"
      data-testid="create-course-dialog"
    >
      <DialogTitle id="create-dialog-title">
        <TranslatedText i18nKey="Course.Create.DialogTitle" />
      </DialogTitle>
      <DialogContent>
        <Form
          onSubmit={props.onSubmit}
          mutators={{
            setValue: ([field, value], state, { changeValue }) => {
              changeValue(state, field, () => {
                return value;
              });
            },
          }}
          render={({ handleSubmit, form, values }) => {
            return (
              <form onSubmit={handleSubmit}>
                <CourseNameField label={localize({ message: 'Course.Create.CourseNameField' })} />
                <FieldLayout className="course-details__code-section-field-layout">
                  <CourseCodeField />
                  <CourseSectionField />
                </FieldLayout>
                <FieldLayout>
                  <CourseTermField />
                </FieldLayout>
                <DialogActions
                  classes={{
                    root: classes.root,
                  }}
                >
                  <ButtonContainer>
                    <Button type="primary" inputType="submit" data-testid="create-course">
                      {isAiActivityCreationEnabled
                        ? localize({ message: 'Course.Create.AiActivity' })
                        : localize({ message: 'Course.Create' })}
                    </Button>
                    <Button
                      onClick={() => {
                        return props.onClose();
                      }}
                      type="secondary"
                      autoFocus
                      data-testid="cancel-create-course"
                    >
                      <TranslatedText i18nKey="Cancel" />
                    </Button>
                  </ButtonContainer>
                </DialogActions>
              </form>
            );
          }}
        />
      </DialogContent>
    </Dialog>
  );
}

export default CreateCourse;
