import validators from 'components/ActivityEdit/Details/Fields/validators';
import FormRichTextEditor from 'components/core/form/RichTextEditor';
import { Field, useFormState } from 'react-final-form';
import { FIELD_LABEL, FIELD_NAME } from './constants';

export const LABEL = {
  value: FIELD_NAME,
  label: FIELD_LABEL,
};

const ObjectiveField = () => {
  const state = useFormState();
  return (
    <Field required name={LABEL.value} validate={validators.doesNotContainImageAndisRequired('Objective')}>
      {({ input }) => {
        return (
          <FormRichTextEditor
            testid="activity-objective-editor"
            key="activity_edit_objective"
            label={LABEL.label}
            {...input}
          />
        );
      }}
    </Field>
  );
};
export default ObjectiveField;
