import validators from 'components/ActivityEdit/Details/Fields/validators';
import FormField from 'components/core/form/Field';
import FieldInfo from 'components/core/form/FieldInfo';
import FormRichTextEditor from 'components/core/form/RichTextEditor';
import RadioInput from 'components/core/input/Radio';
import AttachmentManager from 'components/layout/AttachmentManager';
import { localize } from 'locales';
import React from 'react';
import { Field, FormSpy, useFormState } from 'react-final-form';
import { connect } from 'react-redux';
import { EVALUATOR_NOTES_FILES, FIELD_LABEL, FIELD_NAME, HAS_EVALUATOR_NOTES, OPTIONS } from './constants';

export const LABEL = {
  value: FIELD_NAME,
  label: FIELD_LABEL,
};
const NotesField = (props: any) => {
  const state = useFormState();

  const setFieldValue = (e: any, onChange: any) => {
    const isEnabled = e.target.value === String(OPTIONS.ENABLED.value);
    props.form.mutators.setValue(HAS_EVALUATOR_NOTES, isEnabled);
    return onChange(isEnabled);
  };

  const getOptions = () => {
    return Object.values(OPTIONS);
  };

  const renderOptions = () => {
    return (
      <Field name={HAS_EVALUATOR_NOTES} validate={validators.isRequired}>
        {({ input }) => {
          return (
            <RadioInput
              options={getOptions()}
              onChange={(e: any) => {
                return setFieldValue(e, input.onChange);
              }}
              value={input.value}
              disabled
              type="button"
              legendText="Legend.Text.EvaluatorNotes"
            />
          );
        }}
      </Field>
    );
  };

  const renderEditor = () => {
    if (!state.values.hasEvaluatorNotes) {
      return null;
    }
    return (
      <React.Fragment>
        <Field name={LABEL.value}>
          {({ input }) => {
            return (
              <React.Fragment>
                <FormRichTextEditor key="activity_edit_notes" testid="note-editor" {...input} />
              </React.Fragment>
            );
          }}
        </Field>
      </React.Fragment>
    );
  };

  const renderFileManager = () => {
    if (!state.values.hasEvaluatorNotes) {
      return null;
    }
    return (
      <Field name={EVALUATOR_NOTES_FILES}>
        {({ input }) => {
          return (
            <FormField>
              <AttachmentManager
                onFileChange={input.onChange}
                fileList={input.value}
                testid="evaluator-note-attachment"
              />
            </FormField>
          );
        }}
      </Field>
    );
  };

  return (
    <div className="evaluator-notes__wrapper">
      <FieldInfo label={localize({ message: 'Activity.NotesField.Label' })} />
      {renderOptions()}
      {renderEditor()}
      {renderFileManager()}
    </div>
  );
};

const Spy = () => {
  return <FormSpy component={NotesField} />;
};

const mapStateToProps = (state: any) => {
  return {};
};

export default connect(mapStateToProps, {})(Spy);
