import * as ActivityUtils from '@kritik/utils/activity';
import { FeedbackScoreUtil } from '@kritik/utils/grade';
import { FeedbackScoreGroupScoreNotice } from 'components/Assignment/NoticeBoards';
import DisplayBoxItem from 'components/DisplayBox/Item';
import ScoreItem from 'components/DisplayBox/ScoreItem';
import { TranslatedText } from 'components/TranslatedText';
import { Button, ButtonContainer } from 'components/buttons';
import { DisplayBox } from 'components/layout';
import InlineInformation from 'components/layout/InlineInformation';
import NoticeBoard from 'components/layout/NoticeBoard';
import { localize } from 'locales';
import { connect } from 'react-redux';
import { selectAvgGroupFeedbackScore } from 'redux/activityScores/select';
import { getCreationsFromEntities } from 'selectors/creation';
import StudentSelectors from 'selectors/student';
import * as StudentUtils from 'utils/student';

const FeedbackScore = (props: any) => {
  const getCurrentFeedbackScore = () => {
    if (props.groupView) {
      return props.avgGroupFeedbackScore;
    }
    return FeedbackScoreUtil.calcScore({
      creation: props.creation,
      activity: props.activity,
      studentId: props.student._id,
      creations: props.creations,
    });
  };

  const renderBoxHeader = () => {
    return (
      <ScoreItem
        title={localize({ message: 'FeedbackScore.View.Title' })}
        subtitle={props.groupView ? localize({ message: 'FeedbackScore.View.GroupAverageSubtitle' }) : ''}
        description={localize({ message: 'FeedbackScore.View.Description' })}
        value={getCurrentFeedbackScore()}
        size="lg"
      />
    );
  };

  const renderProfGraded = () => {
    if (!FeedbackScoreUtil.isFeedbackScoredByTeacher(props.creation)) {
      return null;
    }
    return (
      <DisplayBoxItem>
        <InlineInformation type="prof-graded">
          <TranslatedText i18nKey="FeedbackScore.View.InstructorGraded" />
        </InlineInformation>
      </DisplayBoxItem>
    );
  };

  const renderEditBtn = () => {
    if (ActivityUtils.isGroupAssignment(props.activity) || ActivityUtils.isGroupPresentationActivity(props.activity)) {
      return null;
    }
    const scoreHistory = StudentUtils.getStudentActivityScoreHistory(props.scoreHistories, props.activity._id);

    const renderNotice = () => {
      if (ActivityUtils.isGroupAssignment(props.activity)) {
        if (props.groupView) {
          return <FeedbackScoreGroupScoreNotice />;
        }
        return null;
      }
      return (
        <NoticeBoard
          type="information"
          title={localize({ message: 'FeedbackScore.View.ScoreChangeUnavailable.Title' })}
        >
          <TranslatedText i18nKey="FeedbackScore.View.ScoreChangeUnavailable.Content" />
        </NoticeBoard>
      );
    };
    if (scoreHistory) {
      return (
        <ButtonContainer>
          <Button type="secondary" onClick={props.onEdit}>
            <TranslatedText i18nKey="FeedbackScore.View.EditScore" />
          </Button>
        </ButtonContainer>
      );
    }
    return renderNotice();
  };

  return (
    <DisplayBox>
      {renderBoxHeader()}
      {renderProfGraded()}
      {renderEditBtn()}
    </DisplayBox>
  );
};

FeedbackScore.defaultProps = {
  avgGroupFeedbackScore: 0,
};

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    avgGroupFeedbackScore: selectAvgGroupFeedbackScore(state, ownProps.creation._id),
    scoreHistories: StudentSelectors.selectStudentScoreHistories(
      state,
      ownProps.creation.student._id || ownProps.creation.student
    ),
    student: StudentSelectors.selectCurrentStudent(state),
    creations: getCreationsFromEntities(state, state.selected.assignmentId),
  };
};

export default connect(mapStateToProps, {})(FeedbackScore);
