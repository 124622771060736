import { createRestApiClient } from 'utils/createRestApiClient';
const apiEndpoint = import.meta.env.VITE_API_URL;

export default (options = {}) => {
  const client = (options as any).client || createRestApiClient().withConfig({ baseURL: apiEndpoint });
  return {
    enrollStudentInCourse: (courseId: any) => {
      return client.request({ method: 'POST', url: '/students/enroll', data: { courseId } });
    },
    enrollStudentInInstitutionPaidCourse: (courseId: any) => {
      return client.request({
        method: 'POST',
        url: '/students/enroll-institution-paid-course',
        data: { courseId },
      });
    },
  };
};
