import * as formatUtils from '@kritik/utils/format';
import { EvaluationScoreUtil } from '@kritik/utils/grade/evaluation';
import validators from 'components/ActivityEdit/Details/Fields/validators';
import localUtils from 'components/Course/Edit/Scoring/utils';
import MarkingScheme from 'components/Course/MarkingScheme';
import markingSchemeCopy from 'components/Course/MarkingScheme/copy';
import MarkingSchemeLayout from 'components/Course/MarkingSchemeLayout';
import Typography from 'components/core/Typography';
import FormField from 'components/core/form/Field';
import FormFieldCompact from 'components/core/form/FieldCompact';
import FormFieldInfo from 'components/core/form/FieldInfo';
import FormFieldTitle from 'components/core/form/Title';
import InlineInformation from 'components/layout/InlineInformation';
import { localize } from 'locales';
import React, { useEffect, useState } from 'react';
import { Field } from 'react-final-form';
import { connect } from 'react-redux';
import COPY from './copy';

const MarkingSchemeComponent = (props: any) => {
  const getTotalEvaluationWeight = () => {
    return EvaluationScoreUtil.getEvaluationTotalWeight(props.markingScheme);
  };

  const [evaluationWeight, setEvaluationWeight] = useState(getTotalEvaluationWeight());

  const getRelativeEvalWeight = (value: any) => {
    const totalWeight = evaluationWeight;
    return (value / totalWeight) * 100;
  };

  const [kritikScoreWeight, setKritikScore] = useState(getRelativeEvalWeight(props.markingScheme.kritikScore));
  const [feedbackCommunicationEffectivenessWeight, setFeedbackCommunicationWeight] = useState(
    getRelativeEvalWeight(props.markingScheme.feedbackCommunicationEffectiveness)
  );

  const setFieldValue = (e: any, onChange: any) => {
    onChange(parseFloat(e.target.value));
  };

  useEffect(() => {
    const newWeight = (kritikScoreWeight / 100) * evaluationWeight;
    props.mutators.setValue('markingScheme.kritikScore', newWeight);
  }, [kritikScoreWeight]);

  useEffect(() => {
    const newWeight = (feedbackCommunicationEffectivenessWeight / 100) * evaluationWeight;
    props.mutators.setValue('markingScheme.feedbackCommunicationEffectiveness', newWeight);
  }, [feedbackCommunicationEffectivenessWeight]);

  useEffect(() => {
    const total = localUtils.getMarkingSchemeTotal(props.markingScheme);
    props.mutators.setValue('markingScheme.totalWeight', total);
  }, [
    props.markingScheme.passedSubmissions,
    props.markingScheme.kritikScore,
    props.markingScheme.feedbackCommunicationEffectiveness,
    props.markingScheme.participationInFof,
  ]);

  const changeKritikScore = (e: any) => {
    const value = parseFloat(e.target.value);
    if (value <= 100) {
      setKritikScore(value);
      setFeedbackCommunicationWeight(100 - value);
    }
  };

  const changeFeedbackCommunicationScore = (e: any) => {
    const value = parseFloat(e.target.value);
    if (value <= 100) {
      setFeedbackCommunicationWeight(value);
      setKritikScore(100 - value);
    }
  };

  const onChangeEvalWeight = (e: any) => {
    const evalWeight = parseFloat(e.target.value);
    setEvaluationWeight(evalWeight);
    let newWeight = (kritikScoreWeight / 100) * evalWeight;
    props.mutators.setValue('markingScheme.kritikScore', newWeight);
    newWeight = (feedbackCommunicationEffectivenessWeight / 100) * evalWeight;
    props.mutators.setValue('markingScheme.feedbackCommunicationEffectiveness', newWeight);
  };

  const renderTotalWeight = (total: any) => {
    let label;
    if (props.isPercentage) {
      label = formatUtils.simplePercent(total);
    } else {
      label = formatUtils.simplePoint(total, 2);
    }
    if (props.isPercentage && total > 100) {
      return (
        <Typography weight="600" fontSize="18px" color="danger">
          {label}
        </Typography>
      );
    }
    return (
      <Typography weight="600" fontSize="18px" color="accent">
        {label}
      </Typography>
    );
  };

  const renderCreationScore = () => {
    const copy = markingSchemeCopy.CREATION;
    const scoreCopy = markingSchemeCopy.CREATION_SCORE;

    return (
      <Field name="markingScheme.passedSubmissions" required>
        {({ input }) => {
          return (
            <MarkingScheme
              title={copy.title}
              description={copy.description}
              icon={copy.icon}
              color="cyan"
              label={localize({ message: 'Label.FormControl.GradingWeight.Create' })}
              isEdit
              isPercentage={props.isPercentage}
              category={{
                name: 'creationScore',
                label: scoreCopy.title,
                description: scoreCopy.description,
                value: props.markingScheme.passedSubmissions,
                onChange: (e: any) => {
                  return setFieldValue(e, input.onChange);
                },
                popover: scoreCopy.popover,
                testid: 'marking-scheme-creation-score-input',
              }}
            />
          );
        }}
      </Field>
    );
  };

  const renderEvaluationScore = () => {
    const copy = markingSchemeCopy.EVALUATION;
    const scoreCopy = markingSchemeCopy.EVALUATION_SCORE;
    const gradingCopy = markingSchemeCopy.GRADING_SCORE;
    const writtenCopy = markingSchemeCopy.WRITTEN_EVAL_SCORE;

    return (
      <MarkingScheme
        title={copy.title}
        description={copy.description}
        icon={copy.icon}
        label={localize({ message: 'Label.FormControl.GradingWeight.Evaluate' })}
        color="tan"
        isEdit
        isPercentage={props.isPercentage}
        category={{
          label: scoreCopy.title,
          name: 'evaluationScore',
          description: scoreCopy.description,
          value: evaluationWeight,
          onChange: (e: any) => {
            onChangeEvalWeight(e);
          },
          testid: 'marking-scheme-evaluate-score-input',
        }}
        subCategoryList={[
          {
            title: gradingCopy.title,
            label: localize({ message: 'Label.FormControl.GradingWeight.GradingScore' }),
            name: 'gradingScore',
            description: gradingCopy.description,
            value: kritikScoreWeight,
            popover: gradingCopy.popover,
            weight: props.markingScheme.kritikScore,
            onChange: (e: any) => {
              return changeKritikScore(e);
            },
            testid: 'marking-scheme-grading-score-input',
          },
          {
            title: writtenCopy.title,
            label: localize({ message: 'Label.FormControl.GradingWeight.WrittenEvaluationScore' }),
            name: 'writtenEvalScore',
            description: writtenCopy.description,
            popover: writtenCopy.popover,
            value: feedbackCommunicationEffectivenessWeight,
            weight: props.markingScheme.feedbackCommunicationEffectiveness,
            onChange: (e: any) => {
              return changeFeedbackCommunicationScore(e);
            },
            testid: 'marking-scheme-written-eval-score-input',
          },
        ]}
      />
    );
  };

  const renderFeedbackScore = () => {
    const copy = markingSchemeCopy.FEEDBACK;
    const scoreCopy = markingSchemeCopy.FEEDBACK_SCORE;
    return (
      <Field name="markingScheme.participationInFof" required>
        {({ input }) => {
          return (
            <MarkingScheme
              title={copy.title}
              description={copy.description}
              icon={copy.icon}
              label={localize({ message: 'Label.FormControl.GradingWeight.Feedback' })}
              color="red"
              isEdit
              isPercentage={props.isPercentage}
              category={{
                label: scoreCopy.title,
                name: 'feedbackScore',
                description: scoreCopy.description,
                value: props.markingScheme.participationInFof,
                onChange: (e: any) => {
                  return setFieldValue(e, input.onChange);
                },
                testid: 'marking-scheme-feedback-score-input',
              }}
            />
          );
        }}
      </Field>
    );
  };

  const getTotalWeightValidators = () => {
    return (value: any, allValues: any) => {
      if (allValues.isPercentage) {
        return validators.composeValidators(validators.minValue(100), validators.maxValue(100))(value);
      }
      return validators.minValue(0)(value);
    };
  };

  return (
    <React.Fragment>
      <Field name="markingScheme.totalWeight" validate={getTotalWeightValidators()} required>
        {({ input, meta }) => {
          return (
            <FormField>
              <FormFieldCompact
                label={
                  <React.Fragment>
                    <FormFieldTitle label={COPY.title} />
                    <FormFieldInfo label={COPY.description} />
                  </React.Fragment>
                }
                input={<FormFieldInfo label={renderTotalWeight(input.value)} />}
              />
              {meta.error && meta.touched && (
                <InlineInformation type="danger" testid="scoring-schema-error">
                  {COPY.total_error}
                </InlineInformation>
              )}
            </FormField>
          );
        }}
      </Field>
      <FormField>
        <MarkingSchemeLayout nodeList={[renderCreationScore(), renderEvaluationScore(), renderFeedbackScore()]} />
      </FormField>
    </React.Fragment>
  );
};

MarkingSchemeComponent.defaultProps = {
  isPercentage: false,
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(MarkingSchemeComponent);
