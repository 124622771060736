import { combineReducers } from 'redux';
import * as XLSX from 'xlsx-republish';

import { validateSheetName } from '@kritik/utils/format';
import { closeLoadingModal, openLoadingModal } from 'actions/modals';
import { selectCurrentActivity } from 'selectors/activity';
import topicService from 'services/topic';

const EXPORT_STUDENT_TOPICS_REQUEST = 'EXPORT_STUDENT_TOPICS_REQUEST';
const EXPORT_STUDENT_TOPICS_SUCCESS = 'EXPORT_STUDENT_TOPICS_SUCCESS';
const EXPORT_STUDENT_TOPICS_FAILED = 'EXPORT_STUDENT_TOPICS_FAILED';

export function exportStudentTopics(activity: any) {
  return async (dispatch: any) => {
    dispatch({
      type: EXPORT_STUDENT_TOPICS_REQUEST,
    });
    try {
      dispatch(
        openLoadingModal({
          title: 'Please Wait',
          content: 'Downloading the student topic assignment list...',
        })
      );
      const wb = XLSX.utils.book_new();
      wb.Props = {
        Title: activity.title,
        Author: 'Kritik',
        CreatedDate: new Date(Date.now()),
      };
      const sheetName = validateSheetName(activity.title);
      const res = await topicService().getStudentTopics(activity._id);
      const studentTopicMap = res.data;
      wb.SheetNames.push(sheetName);

      const ws = XLSX.utils.json_to_sheet(
        Object.entries(studentTopicMap).map(([email, topic]) => {
          return {
            email,
            topic,
          };
        })
      );
      wb.Sheets[sheetName] = ws;
      XLSX.writeFile(wb, `${activity.title}-student topics.csv`, { bookType: 'csv' });
      return dispatch({
        type: EXPORT_STUDENT_TOPICS_SUCCESS,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: EXPORT_STUDENT_TOPICS_FAILED,
      });
    } finally {
      dispatch(closeLoadingModal());
    }
  };
}
const exportStudentTopicState = {
  status: '',
  message: '',
};
export function exportStudentTopicReducer(state = exportStudentTopicState, action: any) {
  switch (action.type) {
    case EXPORT_STUDENT_TOPICS_REQUEST: {
      return {
        ...state,
        status: 'pending',
      };
    }
    case EXPORT_STUDENT_TOPICS_SUCCESS: {
      return {
        ...state,
        status: 'success',
      };
    }
    case EXPORT_STUDENT_TOPICS_FAILED: {
      return {
        ...state,
        status: 'failed',
      };
    }
    default: {
      return state;
    }
  }
}

export const selectActivityTopics = (state: any) => {
  const activity = selectCurrentActivity(state);
  return activity.topics;
};

export default combineReducers({
  exportStudentTopic: exportStudentTopicReducer,
});
