import * as activityUtil from '@kritik/utils/activity';
import { simplePercent } from '@kritik/utils/format';
import { EvaluationScoreUtil } from '@kritik/utils/grade';
import { updateAssignmentSubmission } from 'actions/activity';
import { ScoreEditCard } from 'components/Creation/GradeView';
import { TranslatedText } from 'components/TranslatedText';
import Button from 'components/buttons/Button';
import FormContainer from 'components/core/form/Container';
import FormField from 'components/core/form/Field';
import FormSection from 'components/core/form/Section';
import FormSubmitButtons from 'components/core/form/SubmitButtons';
import FormTitle from 'components/core/form/Title';
import PercentageInput from 'components/core/input/Percentage';
import { localize } from 'locales';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { connect } from 'react-redux';

const mapStateToProps = (state: any) => {
  return {
    creationId: state.selected.submissionId,
  };
};

const EvaluationScoreView = ({
  creationId,
  labels,
  markingScheme,
  prevScores,
  returnToScoreView,
  updateAssignmentSubmission,
  activity,
}: any) => {
  const [evaluationScore, setEvaluationScore] = useState(prevScores.evaluationScore);
  const [gradingScore, setGradingScore] = useState(prevScores.gradingScore.toString());
  const [writtenEvalScore, setWrittenEvalScore] = useState(prevScores.writtenEvalScore.toString());
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setEvaluationScore(
      simplePercent(
        EvaluationScoreUtil.evaluatorOverallScoreSingleActivity(
          gradingScore,
          writtenEvalScore,
          {
            markingScheme,
          } as CommonCourse,
          activity
        )
      )
    );
  }, [gradingScore, writtenEvalScore]);

  function handleChange(e: ChangeEvent) {
    const eventTarget = e.target as HTMLInputElement;
    const scoreType = eventTarget.name;
    if (scoreType === 'gradingScore') {
      setGradingScore(eventTarget.value);
    } else {
      setWrittenEvalScore(eventTarget.value);
    }
  }

  const scoreCardProps = {
    evalScore: {
      title: localize({ message: 'EvaluationScoreEdit.EvalScore.Title' }),
      description: localize({ message: 'EvaluationScoreEdit.EvalScore.Description' }),
      prevScore: prevScores.evaluationScore,
      newScore: evaluationScore,
    },
    gradingScore: {
      title: labels.gradingScore,
      description: localize({ message: 'EvaluationScoreEdit.GradingScore.Description' }),
      prevScore: prevScores.gradingScore,
      newScore: (
        <PercentageInput
          name="gradingScore"
          value={gradingScore}
          min={0}
          max={100}
          onChange={(e: any) => {
            return handleChange(e);
          }}
        />
      ),
    },
    writtenEvalScore: {
      title: labels.writtenEvalScore,
      description: localize({ message: 'EvaluationScoreEdit.WrittenEvalScore.Description' }),
      prevScore: prevScores.writtenEvalScore,
      newScore: (
        <PercentageInput
          name="writtenEvalScore"
          value={writtenEvalScore}
          min={0}
          max={100}
          onChange={(e: any) => {
            return handleChange(e);
          }}
          disabled={activityUtil.isCalibrationActivity(activity)}
        />
      ),
    },
  };

  function renderForm() {
    return (
      <React.Fragment>
        <FormField>
          <ScoreEditCard {...scoreCardProps.evalScore} />
        </FormField>
        <FormField>
          <ScoreEditCard {...scoreCardProps.gradingScore} />
        </FormField>
        <FormField>
          <ScoreEditCard {...scoreCardProps.writtenEvalScore} />
        </FormField>
      </React.Fragment>
    );
  }

  function onSubmit() {
    const data = {
      submissionId: creationId,
      teacherGradingScore: Number(gradingScore),
      teacherWrittenEvaluationScore: Number(writtenEvalScore),
    };
    setIsLoading(true);
    updateAssignmentSubmission(data).then(() => {
      setIsLoading(false);
      returnToScoreView();
    });
  }

  return (
    <FormContainer>
      <FormTitle label={localize({ message: 'EvaluationScoreEdit.FormTitle' })} size="xl" />
      <FormSection>{renderForm()}</FormSection>
      <FormSubmitButtons>
        <Button
          type="primary"
          onClick={() => {
            return onSubmit();
          }}
          loading={isLoading}
          disabled={isLoading}
        >
          <TranslatedText i18nKey="Submit" />
        </Button>
        <Button
          type="secondary"
          onClick={() => {
            return returnToScoreView();
          }}
        >
          <TranslatedText i18nKey="Cancel" />
        </Button>
      </FormSubmitButtons>
    </FormContainer>
  );
};

export default connect(mapStateToProps, {
  updateAssignmentSubmission,
})(EvaluationScoreView);
