import { ACTIVITY_TYPES } from '@kritik/constants/activity';
import OPTION_ICONS from 'images/activity-type-options';

const getIcon = (type: any) => {
  switch (type) {
    case ACTIVITY_TYPES.PEER:
      return OPTION_ICONS.PEER;
    case ACTIVITY_TYPES.GROUP:
      return OPTION_ICONS.GROUP;
    case ACTIVITY_TYPES.WITHIN_GROUP:
      return OPTION_ICONS.WITHIN_GROUP;
    case ACTIVITY_TYPES.CALIBRATION:
      return OPTION_ICONS.CALIBRATION;
    default:
      return null;
  }
};

const getText = (type: any) => {
  switch (type) {
    case ACTIVITY_TYPES.PEER:
      return 'Assign an activity to each student';
    case ACTIVITY_TYPES.GROUP:
      return 'Assign an activity to be completed as a group';
    case ACTIVITY_TYPES.WITHIN_GROUP:
      return 'Members within the group evaluate each other’s contribution to the group’s work';
    case ACTIVITY_TYPES.CALIBRATION:
      return 'Align student grading with how you would grade';
    default:
      return null;
  }
};

const getType = (type: any) => {
  if (type === ACTIVITY_TYPES.PEER) {
    return ACTIVITY_TYPES.INDIVIDUAL;
  }
  return type;
};

const ActivityTypeLargeDisplay = (props: any) => {
  const { type } = props;
  const text = getText(type);
  if (!text) {
    return null;
  }
  return (
    <div className="activity-type-large-display">
      <img className="activity-type-large-display__icon" src={getIcon(type)} aria-hidden="true" />
      <div className="activity-type-large-display__text">
        <div className="activity-type-large-display__header">{getType(type)}</div>
        <div className="activity-type-large-display__description">{text}</div>
      </div>
    </div>
  );
};

export default ActivityTypeLargeDisplay;
