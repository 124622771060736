import { UserRole } from 'app-types';
import * as _ from 'lodash-es';
import { denormalize } from 'normalizr';
import { Course, User } from 'old-common/types.generated';
import { courseSchema, userSchema } from 'schemas';

export const getCourse = (state: any, courseId?: string): Course & { userRole: UserRole; user: User } => {
  return denormalize(courseId || state.selected.courseId, courseSchema, state.entities);
};

export const getCourseRecord = (state: any, currentCourseId: string = null) => {
  if (currentCourseId) {
    return state.entities.courses[currentCourseId];
  }
  return state.entities.courses[state.selected.courseId];
};

export const getCourseList = (state: any) => {
  const { courses } = state.entities;
  if (_.isEmpty(courses)) {
    [];
  }

  const courseIds = Object.keys(courses);
  return denormalize(courseIds, [courseSchema], state.entities);
};

export const getCourseCollaborators = (state: any, currentCourseId: string = null) => {
  const course = getCourseRecord(state, currentCourseId);
  if (!course.collaborators) {
    return [];
  }

  let collaboratorList = denormalize(course.collaborators, [userSchema], state.entities);

  return (collaboratorList = collaboratorList.filter((user: any) => {
    return !!user;
  }));
};

export const selectCurrentCourse = (state: any) => {
  return getCourse(state);
};

export default {
  getCourseCollaborators,
  getCourseList,
  getCourse,
  getCourseRecord,
  selectCurrentCourse,
};
