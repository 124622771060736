import { verifyInstitution } from 'actions/admin';
import { FormSelection } from 'components/Form/FormComponents';
import { FindTimeZone } from 'components/auth/ProfsWithOtherInstitutions';
import Button from 'components/buttons/Button';
import FormField from 'components/core/form/Field';
import FormNumberInput from 'components/core/form/NumberField';
import FormTextInput from 'components/core/form/TextField';
import FormCheckbox from 'components/core/input/Checkbox';
import InlineInformation from 'components/layout/InlineInformation';
import { localize } from 'locales';
import { useState } from 'react';
import { connect } from 'react-redux';
import { adminService } from 'services/index';
import CloseButton from '../CloseButton';
import ShowMessage from './ShowMessage';

const currencyOptions = [
  { value: 'usd', label: 'USD' },
  { value: 'cad', label: 'CAD' },
  { value: 'eur', label: 'EUR' },
  { value: 'sgd', label: 'SGD' },
  { value: 'gbp', label: 'GBP' },
  { value: 'aud', label: 'AUD' },
];

type AddInstitutionModalProps = {
  verifyInstitution: any;
  handleClose: () => void;
  institutionExists: boolean;
};

const AddInstitutionModal = (props: AddInstitutionModalProps) => {
  const [name, setInstitionName] = useState('');
  const [domain, setDomain] = useState('');
  const [timeZone, setTimeZone] = useState({ value: '', suggestion: '' });
  const [salesTax, setSalesTax] = useState('');
  const [currency, setCurrency] = useState(currencyOptions[0]);
  const [confirm, setConfirm] = useState(false);
  const [isSandbox, setIsSandbox] = useState(false);
  const [geoNamesErrorMessage, setGeoNamesErrorMessage] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [warningMessage, setWarningMessage] = useState('');

  const handleSetInstitutioName = (e: any) => {
    setInstitionName(e.target.value);
  };

  const handleSubmit = async () => {
    await verifyDomain();
    if (props.institutionExists) {
      return setWarningMessage('This institution already exists');
    }
    setIsLoading(true);
    try {
      const res = await adminService().addInstitution({
        name,
        domain,
        timeZone: timeZone.value,
        salesTax,
        currency: currency.value,
        isSandbox,
      });
      if (res && res.status === 200) {
        setIsLoading(false);
        props.handleClose();
      }
    } catch (NotValidError) {
      setWarningMessage('This institution name is already in use');
      setIsLoading(false);
    }
  };

  const checkAllFields = () => {
    return name !== '' && domain !== '' && timeZone.value !== '' && salesTax !== '' && confirm;
  };
  const verifyDomain = async () => {
    const sanitizedData = domain.replace(/\s/g, '').toLowerCase();
    if (sanitizedData && sanitizedData.includes('.')) {
      setWarningMessage('');
      await props.verifyInstitution(sanitizedData);
    } else {
      setWarningMessage('Please enter a valid domain name');
    }
  };

  return (
    <div className="admin-panel-modal">
      <section className="admin-panel-modal__main admin-panel-modal__scrollable">
        <h1>Add an Institution</h1>
        <FormTextInput
          autoFocus
          name="domain"
          placeholder="ex) mcmaster.ca"
          label="Domain Name"
          value={domain}
          onChange={(e: any) => {
            setDomain(e.target.value);
            setWarningMessage('');
          }}
        />
        <InlineInformation style={{ marginBottom: '20px' }} title="Do not include 'https://www.' or extra subdomain" />
        <Button type="secondary" onClick={verifyDomain}>
          Verify Domain
        </Button>

        <ShowMessage domain={domain} warningMessage={warningMessage} />
        <div style={{ marginBottom: '30px' }} />

        <FindTimeZone
          timeZone={timeZone}
          setTimeZone={setTimeZone}
          setNewInstitution={handleSetInstitutioName}
          newInstitution={name}
          setGeoNamesErrorMessage={(err: any) => {
            return setGeoNamesErrorMessage(err);
          }}
        />
        {geoNamesErrorMessage && <InlineInformation title={geoNamesErrorMessage} />}
        <FormNumberInput
          label={
            <div>
              Sales Tax&nbsp;
              <a target="_blank" href="https://www.taxjar.com/sales-tax-calculator/">
                (calculator)
              </a>
            </div>
          }
          name="salesTax"
          value={salesTax}
          onChange={(e: any) => {
            return setSalesTax(e.target.value);
          }}
        />
        <FormSelection
          label="Currency"
          value={currency}
          onChange={(newCurrency: any) => {
            return setCurrency(newCurrency);
          }}
          options={currencyOptions}
        />
        <FormField>
          <FormCheckbox
            label={localize({ message: 'Institution.Add.Sandbox' })}
            isChecked={isSandbox}
            onChange={() => {
              setIsSandbox((prevIsSandbox) => !prevIsSandbox);
            }}
          />
        </FormField>
        <FormField>
          <FormCheckbox
            label={localize({ message: 'Institution.Add.Notice' })}
            isChecked={confirm}
            onChange={() => {
              setConfirm(!confirm);
            }}
          />
        </FormField>
        <Button
          disabled={!checkAllFields()}
          unavailable={!checkAllFields()}
          style={{ marginTop: '30px' }}
          loading={isLoading}
          type="primary"
          onClick={handleSubmit}
        >
          Add
        </Button>
      </section>
      <CloseButton onClose={props.handleClose} />
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    institutionExists: state.admin.institutionExists,
  };
};

export default connect(mapStateToProps, { verifyInstitution })(AddInstitutionModal);
