import * as ActivityUtil from '@kritik/utils/activity';
import * as LateSubmissionUtil from '@kritik/utils/creation/lateCreation';
import * as CreationStatusUtil from '@kritik/utils/creation/status';
import * as AssignmentStatusUtil from '@kritik/utils/stage';
import { Divider } from '@material-ui/core';
import { InstructorEvaluationsOverview } from 'components/Creation';
import { TranslatedText } from 'components/TranslatedText';
import NoticeBoard from 'components/layout/NoticeBoard';
import { localize } from 'locales';

const CreationEvaluations = ({ creation, assignment }: any) => {
  const isPending = LateSubmissionUtil.isLateSubmissionPending(creation);
  const isRejected = LateSubmissionUtil.isLateSubmissionRejected(creation);
  if (isPending && AssignmentStatusUtil.isEvaluate({ assignment })) {
    return (
      <>
        <Divider /> <TranslatedText i18nKey="CreationEvaluations.NoEvaluatorsAssignedYet" />
      </>
    );
  }
  if (isPending || isRejected) {
    return (
      <>
        <Divider /> <TranslatedText i18nKey="CreationEvaluations.NoEvaluatorsAssigned" />
      </>
    );
  }
  if (CreationStatusUtil.wasCreationMissed(creation)) {
    if (
      ActivityUtil.acceptsLateSubmissions(assignment) &&
      ActivityUtil.autoAcceptsLateSubmissions(assignment) &&
      AssignmentStatusUtil.isEvaluate({ assignment })
    ) {
      return (
        <>
          <Divider />
          <NoticeBoard
            type="information"
            title={localize({ message: 'CreationEvaluations.EvaluatorsAutoAssignedTitle' })}
          >
            <TranslatedText i18nKey="CreationEvaluations.EvaluatorsAutoAssignedContent" />
          </NoticeBoard>
        </>
      );
    }
    return (
      <>
        <NoticeBoard type="danger" title={localize({ message: 'CreationEvaluations.EvaluatorsNotAssignedTitle' })}>
          <TranslatedText i18nKey="CreationEvaluations.EvaluatorsNotAssignedContent" />
        </NoticeBoard>
        {!assignment.isGroupActivity && !ActivityUtil.isPresentationActivity(assignment) && (
          <NoticeBoard type="danger" title={localize({ message: 'CreationEvaluations.StudentCannotParticipateTitle' })}>
            <TranslatedText i18nKey="CreationEvaluations.StudentCannotParticipateContent" />
          </NoticeBoard>
        )}
      </>
    );
  }
  return <InstructorEvaluationsOverview activity={assignment} creation={creation} />;
};

export default CreationEvaluations;
