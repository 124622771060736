import classNames from 'classnames';
import localUtil from 'components/CreationList/util';

const SIZES = {
  MEDIUM: 'md',
  LARGE: 'lg',
  SMALL: 'sm',
};

const Cell = (props: any) => {
  const cellClasses = classNames('creation-table__cell', {
    'creation-table__cell--active': props.column.isActive,
    md: props.column.width === SIZES.MEDIUM,
    lg: props.column.width === SIZES.LARGE,
    sm: props.column.width === SIZES.SMALL,
    isGroupMember: props.column.isGroupMember,
    isMenu: props.isMenu,
  });
  const getAlignment = () => {
    switch (props.column.align) {
      case 'right':
        return 'flex-end';
      default:
        return props.column.align;
    }
  };
  const style = {
    textAlign: props.column.align,
    justifyContent: getAlignment(),
  };
  const renderChildren = () => {
    if (!localUtil.shouldRenderColumn(props.column)) {
      return null;
    }
    return props.children;
  };

  const handleOnClick = (e: any) => {
    if (props.onClick) {
      e.stopPropagation();
      return props.onClick();
    }
  };
  return (
    <td className={cellClasses} onClick={handleOnClick}>
      <div className="creation-table__cell-content" style={style}>
        {renderChildren()}
      </div>
    </td>
  );
};

export default Cell;
