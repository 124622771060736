import * as ActivityUtils from '@kritik/utils/activity';
import * as StatusUtils from '@kritik/utils/stage';
import {
  CALIBRATION_OPTIONS,
  FINALIZED_CALIBRATION_OPTIONS,
  FINALIZED_CREATION_OPTION,
  FINALIZED_EVALUATION_OPTION,
  FINALIZED_FEEDBACK_OPTION,
  FINALIZED_MENU_OPTIONS,
  GROUP_ACTIVITY_MENU_OPTIONS,
  GROUP_EVALUATION_OPTION,
  GROUP_FEEDBACK_OPTION,
  GROUP_SUBMISSION_OPTION,
  INDIVIDUAL_PRESENTATION_EVALUATION_OPTION,
  INDIVIDUAL_PRESENTATION_FEEDBACK_OPTION,
  INDIVIDUAL_PRESENTATION_SUBMISSION_OPTION,
  MENU_OPTIONS,
} from './constants';

type MenuOption = {
  id: string;
  title: string;
};

export function getFinalizedMenuOptions(assignment: any): MenuOption[] {
  if (ActivityUtils.isCalibrationActivity(assignment)) {
    return FINALIZED_CALIBRATION_OPTIONS;
  }

  if (ActivityUtils.isPresentationActivity(assignment)) {
    const presentationMenuOptions = [];
    const creationStage = StatusUtils.getCreationStage(assignment) as PresentationAssignmentStatus;
    const evaluationStage = StatusUtils.getEvaluationStage(assignment) as PresentationAssignmentStatus;
    const feedbackStage = StatusUtils.getFeedbackStage(assignment) as PresentationAssignmentStatus;
    if (creationStage.isParticipating) {
      presentationMenuOptions.push(FINALIZED_CREATION_OPTION);
    }
    if (evaluationStage.isParticipating) {
      presentationMenuOptions.push(FINALIZED_EVALUATION_OPTION);
    }
    if (feedbackStage.isParticipating) {
      presentationMenuOptions.push(FINALIZED_FEEDBACK_OPTION);
    }
    return presentationMenuOptions;
  }

  return FINALIZED_MENU_OPTIONS;
}

export function getMenuOptions(assignment: any): MenuOption[] {
  if (ActivityUtils.isCalibrationActivity(assignment)) {
    return CALIBRATION_OPTIONS;
  }

  if (ActivityUtils.isGroupAssignment(assignment)) {
    return GROUP_ACTIVITY_MENU_OPTIONS;
  }

  if (ActivityUtils.isPresentationActivity(assignment)) {
    const isGroupPresentationActivity = ActivityUtils.isGroupPresentationActivity(assignment);
    const presentationMenuOptions = [];
    const creationStage = StatusUtils.getCreationStage(assignment) as PresentationAssignmentStatus;
    const evaluationStage = StatusUtils.getEvaluationStage(assignment) as PresentationAssignmentStatus;
    const feedbackStage = StatusUtils.getFeedbackStage(assignment) as PresentationAssignmentStatus;
    if (creationStage.isParticipating) {
      if (isGroupPresentationActivity) {
        presentationMenuOptions.push(GROUP_SUBMISSION_OPTION);
      } else {
        presentationMenuOptions.push(INDIVIDUAL_PRESENTATION_SUBMISSION_OPTION);
      }
    }
    if (evaluationStage.isParticipating) {
      if (isGroupPresentationActivity) {
        presentationMenuOptions.push(GROUP_EVALUATION_OPTION);
      } else {
        presentationMenuOptions.push(INDIVIDUAL_PRESENTATION_EVALUATION_OPTION);
      }
    }
    if (feedbackStage.isParticipating) {
      if (isGroupPresentationActivity) {
        presentationMenuOptions.push(GROUP_FEEDBACK_OPTION);
      } else {
        presentationMenuOptions.push(INDIVIDUAL_PRESENTATION_FEEDBACK_OPTION);
      }
    }
    return presentationMenuOptions;
  }

  return MENU_OPTIONS;
}

type ActiveTab = 'kritik' | 'evaluations' | 'feedback' | 'submission' | 'creation' | 'feedbackSummary';

export const getActiveTabBasedOnActivityType = ({
  activity,
  isCreationSummaryShown,
}: {
  activity: any;
  isCreationSummaryShown: boolean;
}): ActiveTab => {
  if (ActivityUtils.isCalibrationActivity(activity)) {
    if (StatusUtils.isFinalized({ assignment: activity })) {
      return 'kritik';
    }
    return 'evaluations';
  }
  const creationStage = StatusUtils.getCreationStage(activity) as PresentationAssignmentStatus;
  const evaluationStage = StatusUtils.getEvaluationStage(activity) as PresentationAssignmentStatus;
  const feedbackStage = StatusUtils.getFeedbackStage(activity) as PresentationAssignmentStatus;

  if (StatusUtils.isFeedback({ assignment: activity })) {
    if (ActivityUtils.isPresentationActivity(activity)) {
      if (feedbackStage.isParticipating) {
        return 'feedback';
      }
      if (evaluationStage.isParticipating) {
        return 'evaluations';
      }
      return 'submission';
    }
    return 'feedback';
  }

  if (StatusUtils.isEvaluate({ assignment: activity }) || StatusUtils.isInEvaluationGracePeriod(activity)) {
    if (ActivityUtils.isPresentationActivity(activity)) {
      if (evaluationStage.isParticipating) {
        return 'evaluations';
      }
      return 'submission';
    }
    return 'evaluations';
  }

  if (ActivityUtils.isPresentationActivity(activity)) {
    if (creationStage.isParticipating) {
      return isCreationSummaryShown ? 'creation' : 'submission';
    }
    if (evaluationStage.isParticipating) {
      return isCreationSummaryShown ? 'kritik' : 'evaluations';
    }
    return isCreationSummaryShown ? 'feedbackSummary' : 'feedback';
  }

  return isCreationSummaryShown ? 'creation' : 'submission';
};
