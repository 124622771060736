import RubricConstants from '@kritik/constants/rubric';
import { simplePoint } from '@kritik/utils/format';
import { getTotalCriteriaWeight } from '@kritik/utils/rubric';
import Grid from '@material-ui/core/Grid';
import { listRubrics } from 'actions/rubrics';
import Rubric from 'components/Rubric/Component';
import localUtils from 'components/Rubric/Edit/utils';
import { Button } from 'components/buttons';
import Typography from 'components/core/Typography';
import Confirm from 'components/modals/ConfirmModal';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { selectRubrics } from 'selectors/rubric';
import { withRouter } from 'utils/withRouter';

function TotalWeightWarning() {
  return <Typography color="danger"> (Warning: must be greater than 0) </Typography>;
}

function TotalWeight({ totalWeight }: any) {
  return (
    <div className="criteria-total-weight" data-testid="criteria-total-weight">
      <Typography color="default">{`Total weight: ${simplePoint(totalWeight)}`}</Typography>
      {totalWeight <= 0 && <TotalWeightWarning />}
    </div>
  );
}

type RubricEditState = any;

class RubricEdit extends Component<{}, RubricEditState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      selectedRows: [],
      removingColumn: false,
    };
    this.toggleConfirmModal = this.toggleConfirmModal.bind(this);
    this.handleAddLevels = this.handleAddLevels.bind(this);
    this.handleRemoveLevels = this.handleRemoveLevels.bind(this);
    this.handleRowSelection = this.handleRowSelection.bind(this);
    this.handleAddCriteria = this.handleAddCriteria.bind(this);
    this.handleRemoveCriteria = this.handleRemoveCriteria.bind(this);
    this.handleToggleModeScoring = this.handleToggleModeScoring.bind(this);
  }

  processUpdate(changedRubricProps: any) {
    if ((this.props as any).rubric && typeof (this.props as any).onChange === 'function') {
      (this.props as any).onChange({
        ...(this.props as any).rubric,
        ...changedRubricProps,
      });
    }
  }

  onRubricCellEdited = (changedRubric: any) => {
    const levels = localUtils.sanitizeBinaryLevels(changedRubric);
    const grid = this.sanitizeGrid(changedRubric);
    const _changedRubric = { ...changedRubric, levels, grid };
    this.processUpdate(_changedRubric);
  };

  getRubricInput() {
    return (this.props as any).rubric;
  }

  getIndexOfSelectedRows(): number[] {
    const indexes = this.state.selectedRows.map((row: any) => {
      return row.index;
    });
    return indexes;
  }

  getNewRows(levels: any, criteria: any, grid: any) {
    const selectedRowIndex = this.getIndexOfSelectedRows();

    const newRows = [];
    const newCriteria: any = [];
    const newGrid: any = [];
    let newLevels = [...levels];

    criteria.forEach((criterion: any, i: any) => {
      if (!selectedRowIndex.includes(i)) {
        newCriteria.push(criterion);
      }
    });

    grid.forEach((gridItem: any, i: any) => {
      if (!selectedRowIndex.includes(i)) {
        newGrid.push(gridItem);
      }
    });

    newLevels = localUtils.pruneLevels({ levels: newLevels, grid: newGrid });

    newRows.push(newCriteria);
    newRows.push(newGrid);
    newRows.push(newLevels);

    if (newRows.length === 0) {
      const { criteria, grid } = this.getRubricInput();
      return [criteria, grid];
    }

    return newRows;
  }

  toggleConfirmModal(e: any) {
    this.setState({ confirm: true });
  }

  handleAddCriteria(e: any, atIndex: any) {
    const { levels, criteria, grid } = this.getRubricInput();
    const _criteria = [...criteria];
    const _grid = [...grid];
    const updated = localUtils.addNewCriterion({
      criteria: _criteria,
      grid: _grid,
      levels,
      atIndex,
    });
    this.processUpdate({ criteria: updated.criteria, grid: updated.grid });
  }

  handleRemoveCriteria(e: any, rowIndexes: any) {
    const { levels, criteria, grid } = this.getRubricInput();
    const _criteria = [...criteria];
    const _grid = [...grid];
    const _levels = [...levels];
    if (rowIndexes) {
      const updated = localUtils.removeSelectedCriteria({
        levels: _levels,
        criteria: _criteria,
        grid: _grid,
        selectedRowIndexes: rowIndexes,
      });
      this.processUpdate({
        levels: updated.levels,
        criteria: updated.criteria,
        grid: updated.grid,
      });
      return;
    }
    const selectedRowIndexes = this.getIndexOfSelectedRows();
    if (selectedRowIndexes.length === 0) {
      const updated = localUtils.removeLastCriterion({
        levels: _levels,
        criteria: _criteria,
        grid: _grid,
      });
      this.processUpdate({
        levels: updated.levels,
        criteria: updated.criteria,
        grid: updated.grid,
      });
    } else {
      return this.removeCriteria();
    }
  }

  removeCriteria() {
    const { levels, criteria, grid } = this.getRubricInput();
    const [newCriteria, newGrid, newLevels] = this.getNewRows(levels, criteria, grid);

    if (newCriteria.length < RubricConstants.MIN_CRITERIA) {
      return;
    }

    this.processUpdate({ criteria: newCriteria, grid: newGrid, levels: newLevels });
  }

  handleRowSelection(selectedRows: any) {
    this.setState({ selectedRows });
  }

  sanitizeGrid({ grid }: any) {
    const _grid: any = [];
    grid.forEach((row: any) => {
      const _row = row.map((cell: any) => {
        if (cell == '') {
          return 'Empty';
        }
        return cell;
      });
      _grid.push(_row);
    });
    return _grid;
  }

  onLevelChange(grid: any) {
    if ((this.props as any).onLevelChange) {
      (this.props as any).onLevelChange(grid);
    }
  }

  handleAddLevels(e: any, rowIndexes: any) {
    const rubric = this.getRubricInput();
    const { levels, criteria, grid } = rubric;
    let selectedRowIndexes = rowIndexes;
    if (!selectedRowIndexes) {
      selectedRowIndexes = this.getIndexOfSelectedRows();
    }
    const updated = localUtils.addLevels({ levels, grid, criteria, selectedRowIndexes });
    this.onLevelChange({ grid: updated.grid });
    this.processUpdate({ levels: updated.levels, grid: updated.grid });
  }

  handleRemoveLevels() {
    const selectedRowIndexes = this.getIndexOfSelectedRows();

    if (selectedRowIndexes.length === 0) {
      return this.setState({ removingColumn: true });
    }
    return this.removeLevels(selectedRowIndexes);
  }

  removeLevels(selectedRowIndexes: any) {
    const rubric = this.getRubricInput();
    const { levels, grid } = rubric;
    const updated = localUtils.removeLevels({ levels, grid, selectedRowIndexes });
    this.onLevelChange({ grid: updated.grid });
    this.processUpdate({ levels: updated.levels, grid: updated.grid });
    this.setState({ removingColumn: false });
  }

  handleToggleModeScoring(e: any, rowIndexes: any) {
    const rubric = this.getRubricInput();
    const { criteria }: { criteria: any[] } = rubric;
    let selectedRowIndexes = rowIndexes;
    if (!selectedRowIndexes) {
      selectedRowIndexes = this.getIndexOfSelectedRows();
    }
    const updatedCriteria = localUtils.toggleModeScoringForCriteria({
      criteria,
      selectedRowIndexes,
    });
    this.processUpdate({ criteria: updatedCriteria });
  }

  renderSelectedCriteriaCounter() {
    if (this.state.selectedRows.length === 0) {
      return;
    }
    return (
      <div className="rubric-edit__criteria-counter">
        <div className="rubric-edit__criteria-counter__number">{this.state.selectedRows.length}</div>
        <div className="rubric-edit__criteria-counter__description">Selected Criterion</div>
      </div>
    );
  }

  renderEditButtons() {
    if (this.state.selectedRows.length !== 0) {
      return null;
    }
    return (
      <Button type="secondary" onClick={this.toggleConfirmModal} testid="clear-rubric-btn">
        Clear Rubric
      </Button>
    );
  }

  handleConfirmClearCells = () => {
    this.setState({ confirm: false });
    const init = {
      levels: ['Level 0', 'Level 1'],
      grid: [['Meaning of Criteria 1 at Level 0', 'Meaning of Criteria 1 at Level 0']],
      criteria: [{ name: 'Criteria 1', weight: 1 }],
    };
    this.processUpdate(init);
  };

  render() {
    if (!(this.props as any).rubric) {
      return null;
    }

    const rubric = this.getRubricInput();
    const totalWeight = getTotalCriteriaWeight(rubric);

    return (
      <React.Fragment>
        <Confirm
          isOpen={this.state.confirm}
          onCancel={() => {
            return this.setState({ confirm: false });
          }}
          onConfirm={this.handleConfirmClearCells}
          cancelButton="No"
          confirmButton="Yes"
          title="Are you sure you want to clear all cells?"
        />
        <Confirm
          isOpen={this.state.removingColumn}
          onCancel={() => {
            return this.setState({ removingColumn: false });
          }}
          onConfirm={() => {
            return this.removeLevels([]);
          }}
          cancelButton="No"
          confirmButton="Yes"
          title="Remove the last level of all criteria?"
          description="You have not selected any criteria."
        />

        <Rubric
          rubric={rubric}
          isEditable
          // @ts-expect-error TS(2322) FIXME
          onChangeCell={(changedRubric: any) => {
            return this.onRubricCellEdited(changedRubric);
          }}
          onSelectRow={this.handleRowSelection}
          handleAddCriteria={this.handleAddCriteria}
          handleRemoveCriteria={this.handleRemoveCriteria}
          handleAddLevels={this.handleAddLevels}
          handleRemoveLevels={this.handleRemoveLevels}
          handleToggleModeScoring={this.handleToggleModeScoring}
        />
        <div className="rubric-editor__footer">
          <Grid container justifyContent="space-between">
            <Grid item xs={2}>
              {this.renderSelectedCriteriaCounter()}
            </Grid>
            <Grid container item xs={2} spacing={1} justifyContent="flex-end" style={{ textAlign: 'right' }}>
              <Grid item xs={12}>
                <TotalWeight totalWeight={totalWeight} />
              </Grid>
              <Grid item xs={12}>
                {this.renderEditButtons()}
              </Grid>
            </Grid>
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    entities: state.entities,
    user: state.user,
    rubrics: selectRubrics(state),
  };
};

export default withRouter(connect(mapStateToProps, { listRubrics }, null)(RubricEdit));
