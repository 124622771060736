import type { Rubric } from '@kritik/types.generated';
import { Button } from 'components/buttons';
import { createAiRubricMutation } from 'hooks/ai';
import { useAuthUserFromRedux } from 'hooks/user';
import { localize } from 'locales';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

type Props = {
  form: any;
  onSelectRubric: (rubric: any) => void;
  setLoading: (on: boolean) => void;
};
function CreateRubricAI(props: Props) {
  const { courseId } = useParams();
  const formState = props.form.getState();
  const authUser = useAuthUserFromRedux();
  const [error, setError] = useState(null);
  const createRubric = createAiRubricMutation({
    authUser,
    courseId,
    onSuccess: (data: Rubric) => {
      props.setLoading(false);
      props.onSelectRubric(data);
    },
    onError: () => {
      setError(localize({ message: 'Activity.CreateEdit.RubricSection.GeneateAI.Error.Generic' }));
      props.setLoading(false);
    },
  });

  useEffect(() => {
    if (createRubric.isLoading) {
      props.setLoading(true);
    }
  }, [createRubric.isLoading]);

  function handleCreateRubricAi() {
    if (createRubric.isLoading) {
      return;
    }
    setError(null);
    createRubric.mutate({
      courseId,
      activityTitle: formState.values.title,
      learningObjectives: formState.values.objective,
      instructions: formState.values.instructions,
    });
  }

  return (
    <div className="kritik-form-field">
      <label className="kritik-form-field__label">
        <span>{localize({ message: 'Activity.CreateEdit.RubricSection.GenerateAI.Title' })}</span>
      </label>
      <Button type="secondary" onClick={handleCreateRubricAi} unavailable={createRubric.isLoading}>
        <i className="fas fa-magic"></i> {localize({ message: 'Activity.CreateEdit.RubricSection.GenerateAI.Create' })}
      </Button>
      <div className="ai-create-rubric__error">{error}</div>
    </div>
  );
}

export default CreateRubricAI;
