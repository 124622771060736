import Tooltip from '@material-ui/core/Tooltip';
import { useTranslation } from 'react-i18next';

import { Activity, Creation, Evaluation, Rubric } from '@kritik/types.generated';
import { displayPercent, exactDate } from '@kritik/utils/format';
import { CreationScoreUtil } from '@kritik/utils/grade';
import { getTotalCriteriaWeight } from '@kritik/utils/rubric';
import { TranslatedText } from 'components/TranslatedText';
import PassFailGrade from './PassFailGrade';

type NumericGradeViewProps = {
  creation: Creation & { scores: Evaluation[] };
  rubric: Rubric;
  numericGrading?: boolean;
  activity: Activity;
  viewAIScore: boolean;
};
const NumericGradeView = ({
  creation,
  rubric,
  numericGrading = true,
  activity,
  viewAIScore,
}: NumericGradeViewProps) => {
  const { t } = useTranslation();
  if (!CreationScoreUtil.hasCreationScore(creation)) {
    return (
      <div className="numeric-grade-display">
        <h3 className="numeric-grade-display__item numeric-grade-display__fraction">
          <TranslatedText i18nKey="Creation.NotGraded" />
        </h3>
      </div>
    );
  }
  const wasGradedByPeer = CreationScoreUtil.isPeerGraded(creation);
  const avgSubmissionScore = CreationScoreUtil.calcAvgCreationScore({
    creation,
    rubric,
    startingScore: activity.startingScore,
    lateCreationPenaltyPercentage: activity.lateCreationPenaltyPercentage,
  });
  const percentageScore = displayPercent(avgSubmissionScore);
  const totalWeight = getTotalCriteriaWeight(rubric);
  const fractionScore = CreationScoreUtil.calcFractionCreationScore(creation, rubric);
  const wasScoreUpdatedByInstructor = Boolean(creation.scoreLastUpdatedAt);
  const roundedAISCore = Math.round(creation.aiScore);
  const absoluteScoreDifference = Math.abs(avgSubmissionScore - roundedAISCore);
  const differenceSign = roundedAISCore > avgSubmissionScore ? '+' : '-';
  const showAiScoreAlert = absoluteScoreDifference >= 20;

  return (
    <div className="numeric-grade-display">
      {numericGrading ? (
        <Tooltip
          title={
            wasScoreUpdatedByInstructor ? (
              <div data-testid="creation-score-updated-tooltip">
                <p>
                  <TranslatedText
                    i18nKey="Creation.LastUpdatedBy.Score"
                    values={{
                      score: !wasGradedByPeer
                        ? t('Creation.NotGraded')
                        : displayPercent(
                            CreationScoreUtil.calcAvgCreationScore({
                              creation: { ...creation, teacherScore: [], revisedTeacherScore: [] },
                              rubric,
                              startingScore: activity.startingScore,
                              lateCreationPenaltyPercentage: activity.lateCreationPenaltyPercentage,
                            })
                          ),
                    }}
                  />
                </p>
                <p>
                  <TranslatedText
                    i18nKey="Creation.LastUpdatedBy.UserAndDate"
                    values={{
                      date: exactDate(creation.scoreLastUpdatedAt),
                      user: creation.scoreLastUpdatedByUserName,
                    }}
                  />
                </p>
              </div>
            ) : (
              ''
            )
          }
        >
          <div className="numeric-grade-display__wrapper">
            <h3 className="numeric-grade-display numeric-grade-display__percentage" data-testid="percentage-score">
              {wasScoreUpdatedByInstructor ? '*' : ''}
              {percentageScore}
            </h3>
            <span className="numeric-grade-display__item">
              {fractionScore}/{totalWeight}
            </span>
            {roundedAISCore && viewAIScore && (
              <span className="numeric-grade-display__item">
                {showAiScoreAlert && <i className="fa fa-exclamation-circle numeric-grade-display__alert-icon" />}
                <TranslatedText i18nKey="Creation.AiScore" />: {roundedAISCore}%{' '}
                <small>
                  ({differenceSign}
                  {absoluteScoreDifference}%)
                </small>
              </span>
            )}
          </div>
        </Tooltip>
      ) : (
        <PassFailGrade creation={creation} />
      )}
    </div>
  );
};

export default NumericGradeView;
