import { useQueryClient } from '@tanstack/react-query';
import { CollapsibleStep } from 'components/CollapsibleStep';
import { Button, ButtonContainer } from 'components/buttons';
import { InlineInformation } from 'components/layout';
import { localize } from 'locales';
import { Form } from 'react-final-form';

import { ALL_STUDENTS, EXCLUDE_CREATORS, SPECIFIC_STUDENTS } from '@kritik/constants/activity';
import { Activity, Student } from '@kritik/types.generated';
import { TranslatedText } from 'components/TranslatedText';
import { useEditIndividualParticipationSettings } from 'hooks/activity';
import { useAuthUserFromRedux } from 'hooks/user';
import { trackEvent } from 'utils/userEvents';
import { withRouter } from 'utils/withRouter';
import StudentCreatorsField from './Fields/IndividualPresentation/StudentCreatorsField';
import StudentEvaluatorsField from './Fields/IndividualPresentation/StudentEvaluatorsField';
import StudentEvaluatorsOptionsField from './Fields/IndividualPresentation/StudentEvaluatorsOptionsField';

type FormValues = {
  creatorStudents: string[];
  evaluatorStudents: string[];
  evaluatorType: string;
};

export function getEvaluatorsIdsForExcludeCreators(creatorStudents: string[], allStudents: Student[]) {
  const studentIdsWithtoutCreators = allStudents
    .filter((student) => {
      return !creatorStudents.includes(student._id);
    })
    .map((student) => student._id);

  return studentIdsWithtoutCreators;
}

export function getEvaluatorsIdsForAllStudents(allStudents: Student[]) {
  return allStudents.map((student) => student._id);
}

type getevaluatorStudentsArgs = {
  creatorStudents: string[];
  evaluatorStudents: string[];
  students: Student[];
  evaluatorType: string;
};
function getEvaluatorStudents({
  creatorStudents,
  evaluatorStudents,
  students,
  evaluatorType,
}: getevaluatorStudentsArgs) {
  switch (evaluatorType) {
    case ALL_STUDENTS:
      return getEvaluatorsIdsForAllStudents(students);
    case EXCLUDE_CREATORS:
      return getEvaluatorsIdsForExcludeCreators(creatorStudents, students);
    case SPECIFIC_STUDENTS:
      return evaluatorStudents;
    default:
      return [];
  }
}

export const IndividualPresentationActivityStudentParticipation = withRouter(
  ({ router, activity, students }: { router: any; activity: Activity; students: Student[] }) => {
    const { assignmentId, courseId } = router.params;
    const authUser = useAuthUserFromRedux();
    const queryClient = useQueryClient();

    const getInitialFormValues = (): FormValues => {
      return {
        creatorStudents: (activity.participationSettings.creatorStudents as unknown as string[]) || [],
        evaluatorStudents: (activity.participationSettings.evaluatorStudents as unknown as string[]) || [],
        evaluatorType: activity.participationSettings.evaluatorType || EXCLUDE_CREATORS,
      };
    };

    const participationSettingsMutation = useEditIndividualParticipationSettings({
      onSuccess: () => {
        queryClient.invalidateQueries(['activity', activity._id]);
        trackEvent('Student Participation Page Saved', authUser, {
          activityId: assignmentId,
          instructorEmail: authUser.email,
        });
        router.push(`/course/${courseId}/assignment/${assignmentId}`);
      },
    });

    const handleOnSubmit = (values: FormValues) => {
      const { creatorStudents, evaluatorType, evaluatorStudents } = values;

      if (creatorStudents?.length === 0) {
        return { error: 'Activity.EditStudentParticipation.Form.Error.Students' };
      }
      if (evaluatorType === SPECIFIC_STUDENTS && evaluatorStudents.length === 0) {
        return { error: 'Activity.EditStudentParticipation.Form.Error.Evaluators' };
      }

      participationSettingsMutation.mutate({
        creatorStudents,
        evaluatorStudents: getEvaluatorStudents({
          creatorStudents,
          evaluatorStudents,
          students,
          evaluatorType,
        }),
        activityId: assignmentId,
        evaluatorType,
      });
    };

    if (!activity || !students) {
      return null;
    }

    return (
      <Form
        initialValues={getInitialFormValues()}
        onSubmit={handleOnSubmit}
        render={({ handleSubmit, values, submitErrors, submitting }) => {
          const { evaluatorType } = values;
          const disableNextSteps = values.creatorStudents.length === 0;
          return (
            <form onSubmit={handleSubmit}>
              <CollapsibleStep
                stepIndex={1}
                title={localize({ message: 'Activity.EditStudentParticipation.Individual.Step1' })}
              >
                <StudentCreatorsField students={students} />
              </CollapsibleStep>
              <CollapsibleStep
                stepIndex={2}
                title={localize({ message: 'Activity.EditStudentParticipation.Individual.Step2' })}
                disabled={disableNextSteps}
              >
                <StudentEvaluatorsOptionsField students={students} />
                {evaluatorType === 'specificStudents' && <StudentEvaluatorsField students={students} />}
              </CollapsibleStep>
              <CollapsibleStep
                stepIndex={3}
                title={localize({ message: 'Activity.EditStudentParticipation.Individual.Step3' })}
                disabled={disableNextSteps}
              >
                <InlineInformation
                  title={localize({
                    message: 'Activity.EditStudentParticipation.Individual.FeedbackStage.Information.Title',
                  })}
                >
                  {localize({
                    message: 'Activity.EditStudentParticipation.Individual.FeedbackStage.Information.Content',
                  })}
                </InlineInformation>
                <div className="participation-feedback-stage-inline">
                  <TranslatedText
                    i18nKey={
                      values.evaluatorType === ALL_STUDENTS
                        ? 'Activity.EditStudentParticipation.Individual.FeedbackStage.All'
                        : values.evaluatorType === EXCLUDE_CREATORS
                          ? 'Activity.EditStudentParticipation.Individual.FeedbackStage.ExcludeCreators'
                          : 'Activity.EditStudentParticipation.Individual.FeedbackStage.Specific'
                    }
                  />
                </div>
              </CollapsibleStep>
              {submitErrors?.error && (
                <InlineInformation type="danger" className="submit-participation-error">
                  {localize({
                    message: submitErrors.error,
                  })}
                </InlineInformation>
              )}
              <ButtonContainer className="participation-buttons">
                <Button
                  type="primary"
                  inputType="submit"
                  loading={submitting}
                  testid="save-participation-settings"
                  label={localize({ message: 'Button.Label.SaveStudentParticipation' })}
                >
                  {localize({
                    message: 'Activity.EditStudentParticipation.Individual.Form.Save',
                  })}
                </Button>
                <Button
                  type="secondary"
                  onClick={() => {
                    router.push(`/course/${courseId}/assignment/${assignmentId}`);
                  }}
                >
                  {localize({
                    message: 'Activity.EditStudentParticipation.Individual.Form.Cancel',
                  })}
                </Button>
              </ButtonContainer>
            </form>
          );
        }}
      />
    );
  }
);
