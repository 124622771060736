import { getWordCountFromHTML } from '@kritik/utils/general';
import { TranslatedText } from 'components/TranslatedText';
import FormRichTextEditor from 'components/core/form/RichTextEditor';
import AttachmentManager from 'components/layout/AttachmentManager';
import { connect } from 'react-redux';
import { uploadFiles } from 'redux/upload';

function CreationEditor({ content, files, onFileChange, onContentChange, testid }: any) {
  const handleFileChange = (newFileList: any) => {
    onFileChange(newFileList);
  };

  const handleContentChange = (value: any) => {
    if (typeof onContentChange === 'function') {
      onContentChange(value);
    }
  };

  return (
    <div>
      <FormRichTextEditor value={content} onChange={handleContentChange} testid={testid} />
      <p>
        <TranslatedText i18nKey="CreationEditor.WordCount" /> {getWordCountFromHTML(content)}
      </p>
      <AttachmentManager onFileChange={handleFileChange} fileList={files} />
    </div>
  );
}

CreationEditor.defaultProps = {
  onFileChange: null,
  onContentChange: null,
  content: '',
};

export default connect(null, {
  uploadFiles,
})(CreationEditor);
