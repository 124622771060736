import { getRubric } from 'actions/rubrics';
import { selectAssignment } from 'actions/select';
import RubricViewContainer from 'containers/Rubric';
import { localize } from 'locales';
import Page from 'pages/Page';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from '../utils/withRouter';

function Rubric(props: any) {
  useEffect(() => {
    props.getRubric({ id: props.router.params.rubricId });
    props.selectAssignment(props.router.params.assignmentId);
  }, []);

  const pageTitle = () => {
    return localize({ message: 'Rubric.PageTitle' });
  };

  const pageMeta = () => {
    return [];
  };

  const pageLink = () => {
    return [];
  };

  const pageScript = () => {
    return [];
  };

  function getMetaData() {
    return {
      title: pageTitle(),
      meta: pageMeta(),
      link: pageLink(),
      script: pageScript(),
    };
  }

  return (
    <Page {...getMetaData()}>
      <RubricViewContainer {...props} />
    </Page>
  );
}

export default withRouter(connect(null, { getRubric, selectAssignment })(Rubric));
