import { RubricView } from 'components/Rubric';
import { TranslatedText } from 'components/TranslatedText';
import Button from 'components/buttons/Button';
import PageContainer from 'components/layout/PageContainer';
import PageHeader from 'components/layout/PageHeader';
import { localize } from 'locales';
import { useState } from 'react';
import { connect } from 'react-redux';
import { selectRubricById } from 'redux/rubric';
import { getAssignment } from 'selectors/activity';
import { selectAuthUser } from 'selectors/user';
import { trackEvent } from 'utils/userEvents';

const RubricViewContainer = (props: any) => {
  if (!props.activity || !props.rubric) {
    return null;
  }
  const [printFriendly, setPrintFriendly] = useState(false);
  const pdfName = `${props.activity.title}_${localize({ message: 'Rubric' })}.pdf`;

  const printPDF = () => {
    window.document.title = pdfName;
    setPrintFriendly(true);
    window.print();
    setPrintFriendly(false);
  };

  return (
    <PageContainer>
      <PageHeader
        title={<TranslatedText i18nKey="Rubric.WithTitle.PageHeader" values={{ title: props.activity.title }} />}
      >
        <Button
          title={localize({ message: 'Rubric.View.PrintButton.Tooltip' })}
          type="primary"
          data-testid="create-activity"
          onClick={() => {
            printPDF();
            trackEvent('Rubric Printed', props.authUser, {
              rubricId: props.rubric._id,
              rubricName: props.rubric.name,
              courseName: props.rubric.course.name,
              activityId: props.activity._id,
            });
          }}
          label={localize({ message: 'Button.Label.PrintRubric' })}
        >
          <TranslatedText i18nKey="Rubric.View.PrintButton.Text" />
        </Button>
      </PageHeader>
      <RubricView rubric={props.rubric} printFriendly={printFriendly} />
    </PageContainer>
  );
};

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    rubric: selectRubricById(state, ownProps.router.params.rubricId),
    activity: getAssignment(state, state.selected.assignmentId),
    authUser: selectAuthUser(state),
  };
};

export default connect(mapStateToProps)(RubricViewContainer);
