import classNames from 'classnames';
import { localize } from 'locales';
import React, { forwardRef, useState } from 'react';

export const FormPasswordInput = forwardRef((props: any, ref: React.RefObject<HTMLInputElement>) => {
  const [visible, setVisible] = useState(false);
  const iconClasses = classNames('kritik-form-field__icon', 'fas', {
    'fa-eye': visible === false,
    'fa-eye-slash': visible === true,
  });
  const getFieldType = () => {
    if (visible) {
      return 'text';
    }
    return 'password';
  };

  return (
    <div className="kritik-form-field__pwd-wrapper">
      <input
        data-testid={props.testid}
        id={props.id || 'password-sign-in-input'}
        className="kritik-form-field__pwd-input"
        type={getFieldType()}
        value={props.value}
        name={props.name}
        aria-label={props['aria-label']}
        onChange={props.onChange}
        autoFocus={props.autoFocus}
        ref={ref}
        aria-describedby={props['aria-describedby']}
      />
      <button
        type="button"
        onClick={() => setVisible(!visible)}
        aria-label={
          visible ? localize({ message: 'Password.HidePassword' }) : localize({ message: 'Password.ShowPassword' })
        }
        aria-hidden={false}
        className="show-password-button"
      >
        <i aria-hidden="true" className={iconClasses} />
      </button>
    </div>
  );
});

FormPasswordInput.defaultProps = {
  autoFocus: false,
};

export default FormPasswordInput;
