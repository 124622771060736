import * as ActivityUtils from '@kritik/utils/activity';
import { CreationScoreUtil } from '@kritik/utils/grade';
import * as RubricUtils from '@kritik/utils/rubric';
import { AssignmentPassCriteria } from 'components/Assignment';
import ResolvedDisputeView from 'components/Creation/Disputing/Resolved';
import PassFailItem from 'components/DisplayBox/PassFailItem';
import ScoreItem from 'components/DisplayBox/ScoreItem';
import { TranslatedText } from 'components/TranslatedText';
import { DisplayBox } from 'components/layout';
import { StudentGradeView } from 'containers/assignments/overview';
import { localize } from 'locales';

function CreationSummary({ myCreation, rubric, activity }: any) {
  const totalWeights = RubricUtils.getTotalCriteriaWeight(rubric);
  const pointsEarned = CreationScoreUtil.calcFractionCreationScore(myCreation, rubric);

  return (
    <DisplayBox>
      <ScoreDisplay creation={myCreation} rubric={rubric} activity={activity} />
      <div className="creation-summary-subtitle">
        <span className="creation-summary-subtitle__text">
          <p>
            <TranslatedText i18nKey="CreationSummary.CreationScoreExplanation" />
          </p>
          {CreationScoreUtil.getAppliedLateCreationPenaltyPercentage({
            creation: myCreation,
            lateCreationPenaltyPercentage: activity.lateCreationPenaltyPercentage,
          }) > 0 && (
            <p>
              <TranslatedText
                i18nKey="StudentView.StudentLateSubmissionNoticeBoard.Creation.Accepted.LatePenalty"
                values={{ value: activity.lateCreationPenaltyPercentage }}
              />
            </p>
          )}
        </span>
        {ActivityUtils.isNumericGrading(activity) && (
          <span className="creation-summary-subtitle__score">{`${pointsEarned}/${totalWeights}`}</span>
        )}
      </div>
      <ResolvedDisputeView creation={myCreation} />
      <AssignmentPassCriteria assignment={activity} rubric={rubric} />
      <StudentGradeView activity={activity} rubric={rubric} showHeader={false} />
    </DisplayBox>
  );
}

const ScoreDisplay = ({ creation, rubric, activity }: any) => {
  if (ActivityUtils.isNumericGrading(activity)) {
    const creationScore = CreationScoreUtil.calcAvgCreationScore({
      creation,
      rubric,
      startingScore: activity.startingScore,
      lateCreationPenaltyPercentage: activity.lateCreationPenaltyPercentage,
    });
    return (
      <ScoreItem
        title={localize({ message: 'CreationSummary.CreationScore' })}
        value={creationScore}
        size="lg"
        testid="creation-eval-score"
      />
    );
  }
  return <PassFailItem title={localize({ message: 'CreationSummary.CreationScore' })} creation={creation} />;
};

export default CreationSummary;
