import CALIBRATION from './calibration_icon.svg';
import GROUP from './group_icon.svg';
import PEER from './peer_icon.svg';
import PRESENTATION from './presentation_icon.svg';
import PRESENTATION_BIG from './presentation_icon_big.svg';
import WITHIN_GROUP from './within_group_icon.svg';

export default {
  PEER,
  GROUP,
  WITHIN_GROUP,
  CALIBRATION,
  PRESENTATION,
  PRESENTATION_BIG,
};
