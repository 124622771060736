import { ACTIVITY_TYPES } from '@kritik/constants/activity';
import { Activity } from '@kritik/types.generated';
import { TranslatedText } from 'components/TranslatedText';

export const RevertInformation = ({ activity }: { activity: Activity }) => {
  return (
    <div className="revert-information">
      {activity.activityType === ACTIVITY_TYPES.PEER && (
        <>
          <p>
            <TranslatedText i18nKey="Activity.Revert.Peer.Title" />
          </p>
          <ul>
            <li>
              <TranslatedText i18nKey="Activity.Revert.Info.EvaluateToCreate" />
            </li>
            <li>
              <TranslatedText i18nKey="Activity.Revert.Info.EvaluateGracePeriodToEvaluate" />
            </li>
            <li>
              <TranslatedText i18nKey="Activity.Revert.Info.FeedbackToEvaluate" />
            </li>
            <li>
              <TranslatedText i18nKey="Activity.Revert.Info.GradingToFeedback" />
            </li>
            <li>
              <TranslatedText i18nKey="Activity.Revert.Info.FinalizedToGrading" />
            </li>
          </ul>
        </>
      )}
      {activity.activityType === ACTIVITY_TYPES.GROUP && (
        <>
          <p>
            <TranslatedText i18nKey="Activity.Revert.BetweenGroup.Title" />
          </p>
          <ul>
            <li>
              <TranslatedText i18nKey="Activity.Revert.Info.EvaluateToCreate" />
            </li>
            <li>
              <TranslatedText i18nKey="Activity.Revert.Info.EvaluateGracePeriodToEvaluate" />
            </li>
            <li>
              <TranslatedText i18nKey="Activity.Revert.Info.FeedbackToEvaluate" />
            </li>
            <li>
              <TranslatedText i18nKey="Activity.Revert.Info.GradingToFeedback" />
            </li>
          </ul>
        </>
      )}

      {activity.activityType === ACTIVITY_TYPES.WITHIN_GROUP && (
        <>
          <p>
            <TranslatedText i18nKey="Activity.Revert.WithinGroup.Title" />
          </p>
          <ul>
            <li>
              <TranslatedText i18nKey="Activity.Revert.Info.EvaluateToCreate" />
            </li>
            <li>
              <TranslatedText i18nKey="Activity.Revert.Info.EvaluateGracePeriodToEvaluate" />
            </li>
            <li>
              <TranslatedText i18nKey="Activity.Revert.Info.FeedbackToEvaluate" />
            </li>
            <li>
              <TranslatedText i18nKey="Activity.Revert.Info.GradingToFeedback" />
            </li>
            <li>
              <TranslatedText i18nKey="Activity.Revert.Info.FinalizedToGrading" />
            </li>
          </ul>
        </>
      )}

      {activity.activityType === ACTIVITY_TYPES.GROUP_PRESENTATION && (
        <>
          <p>
            <TranslatedText i18nKey="Activity.Revert.GroupPresentation.Title" />
          </p>
          <ul>
            <li>
              <TranslatedText i18nKey="Activity.Revert.Info.EvaluateToDraft" />
            </li>
            <li>
              <TranslatedText i18nKey="Activity.Revert.Info.EvaluateGracePeriodToEvaluate" />
            </li>
            <li>
              <TranslatedText i18nKey="Activity.Revert.Info.FeedbackToPresentEvaluate" />
            </li>
          </ul>
        </>
      )}
      {activity.activityType === ACTIVITY_TYPES.INDIVIDUAL_PRESENTATION && (
        <>
          <p>
            <TranslatedText i18nKey="Activity.Revert.IndividualPresentation.Title" />
          </p>
          <ul>
            <li>
              <TranslatedText i18nKey="Activity.Revert.Info.EvaluateGracePeriodToEvaluate" />
            </li>
            <li>
              <TranslatedText i18nKey="Activity.Revert.Info.FeedbackToPresentEvaluate" />
            </li>
          </ul>
        </>
      )}
      {activity.activityType === ACTIVITY_TYPES.CALIBRATION && (
        <div>
          <TranslatedText i18nKey="Activity.Revert.Calibration.Title" />
        </div>
      )}
    </div>
  );
};

export default {
  RevertInformation,
};
