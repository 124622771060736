import { getCoursesForLateSubmission } from 'actions/admin';
import { FormMainError } from 'components/Form/FormComponents';
import Button from 'components/buttons/Button';
import FormTextInput from 'components/core/form/TextField';
import React, { useState } from 'react';
import { connect } from 'react-redux';

const DEFAULT_LABEL = 'Student Email';

const mapStateToProps = (state: any) => {
  return {
    courses: state.admin.courses,
  };
};

const FindStudentWithEmail = (props: any) => {
  const [showMessage, setShowMessage] = useState(false);
  const { email, changeEmail, changeCourse, changeCourseOptions, changeAssignment, label = DEFAULT_LABEL } = props;

  const verifyEmail = async () => {
    const data = await props.getCoursesForLateSubmission({ email });
    const courseOptions = data.map((course: any) => {
      return {
        value: course._id,
        label: course.title,
        department: course.department,
      };
    });
    if (courseOptions.length === 0) {
      setShowMessage(true);
    } else {
      setShowMessage(false);
    }

    changeCourseOptions(courseOptions);

    // if admin suddenly changes email, erase all the previous records
    changeCourse(null);
    changeAssignment && changeAssignment(null);
  };

  return (
    <React.Fragment>
      <FormTextInput autoFocus name="email" label={label} value={email} onChange={changeEmail} />

      <Button type="secondary" onClick={verifyEmail}>
        Verify Email
      </Button>

      <FormMainError
        message="We're sorry we couldn't find an account under that email. Please enter a valid email."
        isOpen={showMessage}
      />
    </React.Fragment>
  );
};

export default connect(mapStateToProps, {
  getCoursesForLateSubmission,
})(FindStudentWithEmail);
