import { FormButtons, FormFieldNotification } from 'components/Form';
import Button from 'components/buttons/Button';
import FormField from 'components/core/form/Field';
import AcceptedCreditCards from 'components/payment/AcceptedCreditCards';
import { Component } from 'react';
import { CardElement, injectStripe } from 'react-stripe-elements';

type State = any;

class CheckoutForm extends Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {
      disabled: false,
      generateTokenError: null,
    };
    this.submit = this.submit.bind(this);
  }

  componentDidUpdate(prevProps: {}, prevState: State) {
    if ((this.props as any).error && !(prevProps as any).error) {
      this.setState({ disabled: false });
    }
    if (this.state.token != prevState.token) {
      (this.props as any).onSuccess(this.state.token);
    }
  }

  async submit(ev: any) {
    if (!(this.props as any).loading && !this.state.disabled) {
      this.setState({ disabled: true });
      const { token, error } = await (this.props as any).stripe.createToken({
        name: 'Name',
      });
      if (error) {
        this.setState({
          disabled: false,
          generateTokenError: error.message,
        });
      } else {
        this.setState({
          generateTokenError: null,
          token,
        });
      }
    }
  }

  renderCreditCardError = () => {
    let message = null;
    const type = 'danger';
    if (this.state.generateTokenError) {
      message = this.state.generateTokenError;
    }
    if ((this.props as any).error) {
      message = (this.props as any).error;
    }
    return <FormFieldNotification message={message} type={type} />;
  };

  render() {
    return (
      <div>
        <AcceptedCreditCards />
        <FormField>
          <div className="checkout-card-element">
            <CardElement />
          </div>
        </FormField>
        {this.renderCreditCardError()}
        <FormButtons>
          <Button
            type="primary"
            loading={(this.props as any).loading}
            success={(this.props as any).success}
            disabled={(this.props as any).loading}
            onClick={this.submit}
            testid="purchase"
          >
            Purchase
          </Button>
          <Button type="secondary" disabled={(this.props as any).loading} onClick={(this.props as any).onCancel}>
            Cancel
          </Button>
        </FormButtons>
      </div>
    );
  }
}

export default injectStripe(CheckoutForm);
