import { Course, User } from '@kritik/types.generated';

export function isAdmin(user: Partial<User>) {
  if (!user || !user._id || !user.permissions) {
    return false;
  }
  return user.permissions.includes('isAdmin');
}

export function canJoinCourse({ user, course }: { user: User; course: Course }) {
  return hasCourseInviteAsStudent({ user, course }) || hasCourseInviteAsCollaborator({ user, course });
}

export function hasCourseInviteAsStudent({ user, course }: { user: User; course: Course }) {
  const isInvited = course.approvedStudents.find((item) => {
    return item.email === user.email;
  });
  return typeof isInvited !== 'undefined';
}

export function hasCourseInviteAsCollaborator({ user, course }: { user: User; course: Course }) {
  return course.approvedCollaborators.includes(user.email);
}
