import {
  isCalibrationActivity,
  isGroupAssignment,
  isGroupPresentationActivity,
  isIndividualPresentationActivity,
  isPeerActivity,
  isWithinGroupActivity,
} from '@kritik/utils/activity';
import * as formatUtil from '@kritik/utils/format';
import GroupLabel from 'components/ActivityCard/Header/GroupLabel';
import DueDateDisplay from 'components/Assignment/DueDateDisplay';
import ACTIVITY_TYPE_ICONS from 'images/activity-types';

const CardTitle = (props: any) => {
  const _title = formatUtil.shortenTextWithElipsis(props.activity.title, 45);
  const renderIcon = () => {
    if (!props.icon) {
      return null;
    }
    return <div className="activity-card-head__icon">{props.icon}</div>;
  };
  const renderSubtitle = () => {
    return <div className="activity-card-head__subtitle">{props.subtitle}</div>;
  };
  return (
    <div className="activity-card-head" role="button" tabIndex={0}>
      <div className="activity-card-head__header">
        <div className="title_icon">
          {renderIcon()}
          <h4 className="activity-card-head__title">{_title}</h4>
        </div>
        <DueDateDisplay className="activity-card__head-date" assignment={props.activity} />
      </div>
      {renderSubtitle()}
    </div>
  );
};

const CalibrationHeader = (props: any) => {
  return (
    <CardTitle
      icon={<img className="activity-card-head__icon" aria-hidden="true" src={ACTIVITY_TYPE_ICONS.CALIBRATION} />}
      activity={props.activity}
    />
  );
};

const GroupHeader = (props: any) => {
  const iconSrc = isWithinGroupActivity(props.activity)
    ? ACTIVITY_TYPE_ICONS.WITHIN_GROUP
    : isGroupPresentationActivity(props.activity)
      ? ACTIVITY_TYPE_ICONS.PRESENTATION
      : ACTIVITY_TYPE_ICONS.GROUP;
  return (
    <CardTitle
      icon={
        <img className="activity-card-head__icon" data-testid="group-activity-icon" aria-hidden="true" src={iconSrc} />
      }
      subtitle={<GroupLabel activity={props.activity} />}
      activity={props.activity}
    />
  );
};
const PeerReviewHeader = (props: any) => {
  return <CardTitle activity={props.activity} />;
};

const IndividualPresentationHeader = (props: any) => {
  return (
    <CardTitle
      icon={<img className="activity-card-head__icon" aria-hidden="true" src={ACTIVITY_TYPE_ICONS.PRESENTATION} />}
      activity={props.activity}
    />
  );
};

const ActivityCardHeader = (props: any) => {
  const { activity } = props;
  const renderActivityTitle = () => {
    if (isPeerActivity(activity)) {
      return <PeerReviewHeader activity={activity} />;
    }
    if (isCalibrationActivity(activity)) {
      return <CalibrationHeader activity={activity} />;
    }
    if (isGroupAssignment(activity) || isWithinGroupActivity(activity)) {
      return <GroupHeader activity={activity} />;
    }
    if (isWithinGroupActivity(activity)) {
      return <GroupHeader activity={activity} />;
    }
    if (isGroupPresentationActivity(activity)) {
      return <GroupHeader activity={activity} />;
    }
    if (isIndividualPresentationActivity(activity)) {
      return <IndividualPresentationHeader activity={activity} />;
    }
    return null;
  };

  return <div className="activity-card__header">{renderActivityTitle()}</div>;
};

export default ActivityCardHeader;
