import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { creationService } from 'services/index';

export function useGetCreation({ id, canUse }: { id: string; canUse?: boolean }) {
  return useQuery(
    ['getCreation', id],
    async () => {
      const params = { id };
      const response = await creationService().get(params);
      return response.data;
    },
    { enabled: Boolean(canUse), staleTime: 1000 * 60 * 60 * 24 } // Stale for 24 hours
  );
}

export function useGetCreationsToEvaluate({
  activityId,
  courseId,
  studentId,
}: {
  activityId: string;
  courseId: string;
  studentId: string;
}) {
  const queryClient = useQueryClient();
  return useQuery(
    ['getCreationsToEvaluate', studentId],
    async () => {
      const response = await creationService().getCreationsToEvaluate({
        activityId,
        courseId,
        studentId,
      });
      return response.data;
    },
    {
      onSuccess: async () => {
        // When fetching the evaluations, we also need to re-fetch the creation
        // to make sure the aggregate fields like numOfEvalsCompleted are also up-to-date
        await queryClient.invalidateQueries(['getCreation']);
      },
      staleTime: 0,
    }
  );
}

export function useUpdateCreation({ onSuccess }: { onSuccess: (data: any) => void }) {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ creationId, data }: { data: any; creationId: string }) => {
      const result = await creationService().update({ creationId, ...data });
      return result.data;
    },
    {
      mutationKey: ['updateCreation'],
      onSuccess: async (data) => {
        await queryClient.invalidateQueries(['getCreation', data.creation._id]);
        onSuccess(data.creation);
      },
    }
  );
}

export function useCountPlagiarismCreationMatchesAndResolutionStatus({ creationId }: { creationId: string }) {
  return useQuery(
    ['countPlagiarismCreationMatches', creationId],
    async () => {
      const response = await creationService().countPlagiarismCreationMatches({
        creationId,
      });
      return response.data;
    },
    { enabled: Boolean(creationId) }
  );
}
