import FormField from 'components/core/form/Field';
import FieldHelpText from 'components/core/form/FieldHelpText';
import FormFieldInput from 'components/core/form/FieldInput';
import FormFieldLabel from 'components/core/form/FieldLabel';
import PasswordInput from 'components/core/input/Password';
import React, { forwardRef } from 'react';

export const FormPasswordInput = forwardRef((props: any, ref: React.RefObject<HTMLInputElement>) => {
  const helpTextId = React.useId();
  return (
    <FormField id={props.id} error={props.error} className={props.className}>
      <FormFieldLabel label={props.label} isRequired={props.isRequired} error={props.error} id={props.id} />
      <FormFieldInput error={props.error}>
        <PasswordInput
          testid={props.testid}
          value={props.value}
          name={props.name}
          aria-label={props['aria-label']}
          id={props.id}
          onChange={props.onChange}
          autoFocus={props.autoFocus}
          ref={ref}
          aria-describedby={props.error ? props.id + '_error' : props.helpText ? helpTextId : undefined}
        />
        {!props.error && <FieldHelpText helpText={props.helpText} id={props.helpText ? helpTextId : undefined} />}
      </FormFieldInput>
    </FormField>
  );
});

FormPasswordInput.defaultProps = {
  autoFocus: false,
  label: '',
  error: null,
  isRequired: false,
  className: '',
  name: '',
  value: '',
  onChange: '',
};

export default FormPasswordInput;
