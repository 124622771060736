import { Creation, Student } from '@kritik/types.generated';

export class WrittenEvalScoreUtil {
  static MAX_FEEDBACK_SCORE_CHANGE = 750;

  static MIN_FEEDBACK_SCORE = 250;

  static MAX_POINTS_PER_FOF = 4;

  static calcScorePerActivity = (scoreChange: number, maxScoreChange: number) => {
    return (scoreChange / maxScoreChange) * 100;
  };

  static getMaxFeedbackScorePerEval(numEvalsAssigned: number) {
    return this.MAX_FEEDBACK_SCORE_CHANGE / numEvalsAssigned;
  }

  static getMaxFeedbackScorePerCategory(numEvalsAssigned: number) {
    const maxScorePerEval = this.getMaxFeedbackScorePerEval(numEvalsAssigned);
    return maxScorePerEval / 2;
  }

  static calcScorePerCategory({
    numEvalsAssigned,
    feedbackSkillsChange,
  }: {
    numEvalsAssigned: number;
    feedbackSkillsChange: number;
  }) {
    const maxScorePerCategory = this.getMaxFeedbackScorePerCategory(numEvalsAssigned);
    return (feedbackSkillsChange / maxScorePerCategory) * 100;
  }

  static calcScorePerEval(numEvalsAssigned: number, feedbackSkillsChange: number | null) {
    if (feedbackSkillsChange === null || feedbackSkillsChange === undefined) {
      return 100;
    }
    const maxScorePerEval = this.getMaxFeedbackScorePerEval(numEvalsAssigned);
    return (feedbackSkillsChange / maxScorePerEval) * 100;
  }

  /*
   * return: writtenEvaluation score for a student in a specific activity
   * might need to group score = 100
   */
  static getFeedbackSkillsChange({
    creation,
    student,
    activityId,
  }: {
    creation: Creation;
    student: Student;
    activityId: string;
  }) {
    if (creation && this.isWrittenEvalScoredByTeacher(creation)) {
      return this.getTeacherWrittenEvalScore(creation);
    }
    const gradeHistory = student.gradeHistories.find((history) => {
      return history.assignment.toString() === activityId.toString();
    });

    return gradeHistory ? gradeHistory.writtenEvaluation : 0;
  }

  static getCreationFeedbackSkillsChange(submission: Creation, studentId: string) {
    const change = {
      motivational: 0,
      critical: 0,
      totalChange: 0,
    };
    if (!submission || !submission.scores) {
      return change;
    }
    const score = submission.scores.find((_score) => {
      const id = (_score.student as Student)._id || (_score.student as string);
      return id === studentId;
    });
    if (score && score.feedbackSkillsChange.motivational !== 0) {
      change.motivational = score.feedbackSkillsChange.motivational;
    }
    if (score && score.feedbackSkillsChange.critical !== 0) {
      change.critical = score.feedbackSkillsChange.critical;
    }
    change.totalChange = change.motivational + change.critical;
    return change;
  }

  static getFeedbackSkillsChangeFromCreations({
    creationsToEvaluate,
    studentId,
  }: {
    creationsToEvaluate: Creation[];
    studentId: string;
  }) {
    return creationsToEvaluate.reduce(
      (acc, sub) => {
        const change = this.getCreationFeedbackSkillsChange(sub, studentId);
        acc.motivational += change.motivational;
        acc.critical += change.critical;
        acc.totalChange += change.critical + change.motivational;
        return acc;
      },
      {
        motivational: 0,
        critical: 0,
        totalChange: 0,
      }
    );
  }

  static isWrittenEvalScoredByTeacher(creation: Creation) {
    return creation.teacherWrittenEvaluationScore !== undefined && creation.teacherWrittenEvaluationScore !== null;
  }

  static getTeacherWrittenEvalScore(creation: Creation) {
    return creation.teacherWrittenEvaluationScore;
  }

  // calculates writtenEvalScore to save on student.gradeHistory
  static calcScore({
    feedbackSkillsChange,
    numEvalsAssigned,
    numFOFReceived,
  }: {
    feedbackSkillsChange: number;
    numEvalsAssigned: number;
    numFOFReceived: number;
  }) {
    const maxFeedbackScoreChange = this.MAX_FEEDBACK_SCORE_CHANGE;
    if (numEvalsAssigned === 0 && numFOFReceived === 0) {
      return this.calcScorePerActivity(maxFeedbackScoreChange, maxFeedbackScoreChange);
    }
    return this.calcScorePerActivity(feedbackSkillsChange, maxFeedbackScoreChange);
  }
}
