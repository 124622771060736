import * as ActivityUtil from '@kritik/utils/activity';
import * as LateSubmissionUtil from '@kritik/utils/creation/lateCreation';
import * as CreationStatusUtil from '@kritik/utils/creation/status';
import { getCreationStage, studentParticipatesInStageFromInstructorView } from '@kritik/utils/stage';
import { AssignmentPassCriteria } from 'components/Assignment';
import { SubtitleDisplay } from 'components/AssignmentLabels/InfoLabels';
import DisputeHandler from 'components/Creation/Disputing';
import SubmissionSpotlight from 'components/Spotlight/Creation';
import { localize } from 'locales';
import { connect } from 'react-redux';
import { selectSpotlightByReference } from 'redux/spotlight';
import AutoAssignEvaluatorNotice from './AutoAssignEvaluatorNotice';
import CreationContent from './CreationContent';
import CreationEvaluations from './CreationEvaluations';
import CreationHeader from './CreationHeader';
import CreationScoring from './CreationScoring';
import CreationTimeStamp from './CreationTimeStamp';
import LateCreation from './LateCreation';

const CreationSummary = ({ submission, assignment, creationSpotlight }: any) => {
  if (ActivityUtil.isPresentationActivity(assignment)) {
    const creationStage = getCreationStage(assignment);
    const message = localize({
      message: ActivityUtil.isGroupPresentationActivity(assignment)
        ? 'Activity.GroupPresentation.Overview.Creation.Group.NotRequired'
        : 'Activity.IndividualPresentation.Overview.Creation.Individual.NotRequired',
    });

    if (
      !studentParticipatesInStageFromInstructorView({
        stage: creationStage,
        studentId: submission.student._id,
      })
    ) {
      return (
        <div className="kritik-form-container">
          <div className="submission-view-header">
            <h3>{localize({ message: 'Activity.Creation' })}</h3>
          </div>
          {message}
        </div>
      );
    }
  }

  const showAutoAssignEvaluatorsNotice =
    ActivityUtil.autoAcceptsLateSubmissions(assignment) && LateSubmissionUtil.isLateSubmissionAccepted(submission);

  const displayTimeStamp =
    !CreationStatusUtil.wasCreationMissed(submission) && !CreationStatusUtil.isCreationEmpty(submission);

  const topic = ActivityUtil.getStudentTopicWithSubmission(assignment, submission);

  return (
    <div className="assignment-submission-container" key={submission._id} data-testid="assignment-submission-container">
      <LateCreation activity={assignment} creation={submission} />
      <div className="kritik-form-container">
        <CreationContent creation={submission} activity={assignment}>
          <CreationHeader>
            {displayTimeStamp && (
              <CreationTimeStamp createdAt={CreationStatusUtil.getSubmissionLastEditedDate(submission)} />
            )}
            {topic && <SubtitleDisplay text={`Assigned Topic: ${(topic as any).name}`} testid="creation-topic" />}
          </CreationHeader>
        </CreationContent>
      </div>
      {creationSpotlight && <SubmissionSpotlight spotlight={creationSpotlight} />}
      <DisputeHandler creation={submission} />
      <CreationScoring creation={submission} activity={assignment} />
      <AssignmentPassCriteria assignment={assignment} rubric={assignment.rubric} />
      <h3 className="header-3" style={{ marginBottom: '10px' }}>
        Evaluations Received
      </h3>
      {showAutoAssignEvaluatorsNotice && (
        <AutoAssignEvaluatorNotice
          numEvaluatorsAssigned={submission.reviewers.length}
          numEvaluatorsExpected={ActivityUtil.getNumEvaluationsToAssign(assignment)}
        />
      )}
      {!assignment.isCalibrationActivity ? <CreationEvaluations assignment={assignment} creation={submission} /> : null}
    </div>
  );
};

export default connect((state: any, ownProps: any) => {
  return {
    creationSpotlight: selectSpotlightByReference(ownProps.submission._id, state),
  };
}, {})(CreationSummary);
