import FormField from 'components/core/form/Field';
import FormFieldInput from 'components/core/form/FieldInput';
import React, { useId } from 'react';

const FormWrapper = React.forwardRef(({ Component, ...props }: any, ref) => {
  const id = useId();
  return (
    <FormField id={props.id || id} error={props.error} className={props.className}>
      <FormFieldInput error={props.error}>
        <Component {...props} id={props.id || id} ref={ref} />
      </FormFieldInput>
    </FormField>
  );
});

FormWrapper.defaultProps = {
  autoFocus: false,
  label: '',
  isRequired: false,
  placeholder: '',
  error: null,
  className: '',
};

export function withForm(Child: any) {
  const Component = (props: any, ref: any) => {
    return <FormWrapper {...props} Component={Child} ref={ref} />;
  };

  return React.forwardRef(Component);
}
