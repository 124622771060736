import { Institution } from '@kritik/types.generated';
import { getInstitutions } from 'actions/admin';
import { resetAsync } from 'actions/async';
import { activateUser, signUp } from 'actions/users';
import {
  SignupButtons,
  SignupContainer,
  SignupContent,
  SignupFooter,
  SignupHeader,
  SignupMainHeader,
  SignupMainTitle,
  SignupText,
} from 'components/AuthWrapper';
import { Form, FormMainError } from 'components/Form';
import RegionPicker from 'components/General/RegionPicker/index';
import SSOActivation from 'components/auth/SSOActivation';
import SignUpOrSignInStep1 from 'components/auth/SignUpOrSignInStep1';
import TermsOfService from 'components/auth/TermsOfService';
import Button from 'components/buttons/Button';
import FormField from 'components/core/form/Field';
import FormFieldLabel from 'components/core/form/FieldLabel';
import FormPassword from 'components/core/form/PasswordField';
import FormTextInput from 'components/core/form/TextField';
import { localize } from 'locales';
import { Component, RefObject, createRef } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { push } from 'router';
import { SIGNUP_USER } from 'types';
import * as RegionUtils from 'utils/region';
import { trackEvent } from 'utils/userEvents';
import { withRouter } from 'utils/withRouter';
import { FindTimeZone } from './ProfsWithOtherInstitutions/index';
import VirtualizedAutoComplete from './VirtualizedAutoComplete';

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
    signupBusy: state.async[SIGNUP_USER].busy,
    signupSuccess: state.async[SIGNUP_USER].success,
    signupError: state.async[SIGNUP_USER].error,
    institutions: state.entities.institutions,
  };
};

type OwnRegisterState = any;

type RegisterState = OwnRegisterState & typeof Register.defaultProps;

class Register extends Component<{}, RegisterState> {
  static defaultProps = {
    signupSuccess: false,
  };

  emailInputRef: RefObject<HTMLInputElement> = createRef();
  firstNameInputRef: RefObject<HTMLInputElement> = createRef();
  lastNameInputRef: RefObject<HTMLInputElement> = createRef();
  passwordInputRef: RefObject<HTMLInputElement> = createRef();

  constructor(props: {}) {
    super(props);
    this.state = {
      password: '',
      email: '',
      role: 'teacher',
      firstName: '',
      lastName: '',
      institution: null,
      isInstitutionSelected: false,
      shouldAddNewInstitution: false,
      timeZone: null,
      newInstitution: '',
      geoNamesErrorMessage: '',
      isLMSInstructor: null,
      isLtiAdvantageUser: false,
    };
  }

  componentDidMount() {
    (this.props as any).getInstitutions();
  }

  componentWillUnmount() {
    (this.props as any).resetAsync(SIGNUP_USER);
  }

  componentDidUpdate(prevProps: {}) {
    if ((this.props as any).signupSuccess && !(prevProps as any).signupSuccess) {
      setTimeout(() => {
        (this.props as any).router.push('/?create-course-modal=true');
      }, 2000);
    }
    if (!(prevProps as any).signupError && Boolean((this.props as any).signupError)) {
      const firstError = Object.keys((this.props as any).signupError)[0];
      switch (firstError) {
        case 'email':
          this.emailInputRef.current.focus();
          break;
        case 'firstName':
          this.firstNameInputRef.current.focus();
          break;
        case 'lastName':
          this.lastNameInputRef.current.focus();
          break;
        case 'password':
          this.passwordInputRef.current.focus();
          break;
        default:
          break;
      }
    }
  }

  handleOnSubmit = () => {
    const {
      firstName,
      lastName,
      role,
      institution,
      email,
      password,
      shouldAddNewInstitution,
      timeZone,
      newInstitution,
      geoNamesErrorMessage,
    } = this.state;

    (this.props as any)
      .signUp({
        institution,
        email,
        password,
        confirmPassword: password,
        firstName,
        lastName,
        role,
        shouldAddNewInstitution,
        timeZone,
        newInstitution,
        geoNamesErrorMessage,
      })
      .then((user: any) => {
        trackEvent('Instructor Sign-up', user, {
          isSSO: false,
        });

        this.props
          // @ts-expect-error TS(2339) FIXME: Property 'activateUser' does not exist on type 'Re... Remove this comment to see the full error message
          .activateUser({
            email,
            password,
            confirmPassword: password,
            firstName,
            lastName,
            institution,
            shouldAddNewInstitution,
            newInstitution,
            timeZone,
          })
          .catch((err: any) => {
            this.setState({ error: Object.values(err.errors)[0] });
          });
      });
  };

  handleInputChange = (e: any) => {
    if ((this.props as any).signupError) {
      (this.props as any).resetAsync(SIGNUP_USER);
    }
    const { target } = e;
    const { value } = target;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  renderAreYouStudent() {
    return (
      <SignupMainHeader>
        <SignupHeader color="attention" size="sm" as="span">
          Are you a student?{' '}
          <Link to="/signup/student" title="Student sign-up info" className="underlined-link">
            Learn more
          </Link>
        </SignupHeader>
      </SignupMainHeader>
    );
  }

  setInstitutionSelected = () => {
    if (!this.state.institution) {
      return this.setState({ error: 'Please select an institution.' });
    }
    this.setState({ isInstitutionSelected: true });
  };

  getError(field: any) {
    if ((this.props as any).signupError) {
      return (this.props as any).signupError[field];
    }
    return null;
  }

  getMainError() {
    return this.state.error;
  }

  getInstitutionOptions() {
    return Object.values((this.props as any).institutions).filter((institution: Institution) => {
      return !institution.isSandbox;
    });
  }

  handleInstitutionChange(event: any, value: any) {
    const institution = value ? value._id : null;
    let shouldAddNewInstitution = false;
    if (institution && value.name === 'Other') {
      shouldAddNewInstitution = true;
    }
    this.setState({
      institution,
      institutionName: value.name,
      shouldAddNewInstitution,
    });
  }

  isValidInstitution() {
    if (!RegionUtils.getRegion()) {
      return false;
    }

    if (this.state.institution && this.state.institutionName !== 'Other') {
      return true;
    }
    if (this.state.shouldAddNewInstitution && this.state.timeZone && this.state.newInstitution) {
      return true;
    }
    return false;
  }

  redirectToRegion = (e: any) => {
    const region = e.target.value;
    RegionUtils.redirectToRegion({ region, path: '/signup/instructor' });
  };

  renderInstitutionSelection = () => {
    const institutionOptions = (this.props as any).institutions ? this.getInstitutionOptions() : [];
    return (
      <SignupContainer graphic="rocket">
        {this.renderAreYouStudent()}
        <SignupContent>
          <SignupMainTitle>Welcome Instructor, what institution do you teach at?</SignupMainTitle>
          <FormField>
            <FormFieldLabel color="light" label="Region" id="region-select-field" />
            <RegionPicker onChange={this.redirectToRegion} value={RegionUtils.getRegion()} />
          </FormField>
          <VirtualizedAutoComplete
            id="institution"
            name="institution"
            options={institutionOptions}
            onChange={(event: any, value: any) => {
              return this.handleInstitutionChange(event, value);
            }}
            isLoading={!(this.props as any).institutions}
            testid="institution-dropdown"
          />
          {this.state.shouldAddNewInstitution ? (
            <FindTimeZone
              setTimeZone={(tz: any) => {
                this.setState({
                  timeZone: tz,
                });
              }}
              timeZone={this.state.timeZone}
              newInstitution={this.state.newInstitution}
              setNewInstitution={(e: any) => {
                return this.setState({ newInstitution: e.target.value });
              }}
              setGeoNamesErrorMessage={(err: any) => {
                return this.setState({ geoNamesErrorMessage: err });
              }}
            />
          ) : (
            <SignupText>This will help us set the time zone for your courses and optimize student invites.</SignupText>
          )}
          <SignupButtons>
            <Button
              type="primary"
              onClick={this.setInstitutionSelected}
              disabled={!this.isValidInstitution()}
              unavailable={!this.isValidInstitution()}
              testid="continue-to-last-step"
            >
              Continue to last step
            </Button>
          </SignupButtons>
          <SignupHeader color="attention" size="sm">
            Can’t find your Institution?
          </SignupHeader>
          <SignupText>
            If you can’t find your institution, you can select Other for now, and we will be in contact with you to add
            your institution.
          </SignupText>
          <SignupText weight="strong">
            Already have an account?{' '}
            <Link className="underlined-link" to="/login">
              Sign-in
            </Link>
          </SignupText>
        </SignupContent>
      </SignupContainer>
    );
  };

  renderInstructorSignupStep1 = () => {
    return (
      <SignupContainer graphic="rocket">
        {this.renderAreYouStudent()}
        <SignupContent>
          <SignupMainTitle>Lastly, just a few more details</SignupMainTitle>
          <Form>
            <FormTextInput
              id="email"
              name="email"
              label="Email"
              value={this.state.email}
              onChange={this.handleInputChange}
              error={this.getError('email')}
              isRequired
              ref={this.emailInputRef}
            />
            <FormTextInput
              id="firstName"
              name="firstName"
              label="First Name"
              value={this.state.firstName}
              onChange={this.handleInputChange}
              error={this.getError('firstName')}
              testid="instructor-signup-first-name"
              autoFocus
              ref={this.firstNameInputRef}
              isRequired
            />
            <FormTextInput
              id="lastName"
              name="lastName"
              label="Last Name"
              value={this.state.lastName}
              onChange={this.handleInputChange}
              error={this.getError('lastName')}
              testid="last-name"
              ref={this.lastNameInputRef}
              isRequired
            />
            <FormPassword
              id="password"
              name="password"
              label="Password"
              value={this.state.password}
              onChange={this.handleInputChange}
              error={this.getError('password')}
              helpText="Password must be at least 10 characters long"
              testid="instructor-signup-password"
              ref={this.passwordInputRef}
              isRequired
            />
            <FormMainError message={this.getMainError()} isOpen={this.getMainError()} />
            <SignupButtons>
              <Button
                type="primary"
                onClick={this.handleOnSubmit}
                loading={(this.props as any).signupBusy}
                success={(this.props as any).signupSuccess}
                disabled={(this.props as any).signupBusy || (this.props as any).signupSuccess}
                inputType="submit"
                label={localize({ message: 'Button.Label.FinishRedirectToMainPage' })}
                testid="confirm-signup"
              >
                Get started
              </Button>
            </SignupButtons>
            <TermsOfService />
          </Form>
        </SignupContent>
        <SignupFooter>
          <SignupText weight="strong">
            Already have an account?{' '}
            <Link className="underlined-link" to="/login">
              Sign-in
            </Link>
          </SignupText>
        </SignupFooter>
      </SignupContainer>
    );
  };

  renderInstructorSignup = () => {
    if (this.state.isInstitutionSelected) {
      return this.renderInstructorSignupStep1();
    }
    if (this.state.isLMSInstructor === true) {
      return (
        <SSOActivation
          emailToRegister={this.state.email}
          isNewUser
          isInstructor
          isLtiAdvantageUser={this.state.isLtiAdvantageUser}
        />
      );
    }
    if (this.state.isLMSInstructor === false) {
      return this.renderInstitutionSelection();
    }
    return (
      <SignUpOrSignInStep1
        onNextStep={(isLMSInstructor: any, email: any, isLtiAdvantageUser: any) => {
          let _isLtiAdvantageUser = isLtiAdvantageUser;
          let _isLMSInstructor = isLMSInstructor;
          if ((this.props as any).allowLtiUserSignUp) {
            _isLtiAdvantageUser = false;
            _isLMSInstructor = false;
          }
          this.setState({
            isLMSInstructor: _isLMSInstructor,
            email,
            isLtiAdvantageUser: _isLtiAdvantageUser,
          });
        }}
      />
    );
  };

  render() {
    return this.renderInstructorSignup();
  }
}

export default withRouter(
  connect(mapStateToProps, {
    activateUser,
    signUp,
    getInstitutions,
    push,
    resetAsync,
  })(Register)
);
