import { combineReducers } from 'redux';
import lmsServices from 'services/lms';
import types from './types';

const rosterSyncInitState = {
  status: '',
  message: '',
  syncRosterResult: null,
};

function rosterSyncReducer(state = rosterSyncInitState, action: any) {
  switch (action.type) {
    case types.SYNC_ROSTER_REQUEST:
      return {
        ...state,
        status: 'pending',
      };
    case types.SYNC_ROSTER_SUCCESS:
      return {
        ...state,
        status: 'success',
        result: action.payload,
      };
    case types.SYNC_ROSTER_FAILURE:
      return {
        ...state,
        status: 'fail',
      };
    default:
      return state;
  }
}

export function getRosterSyncChanges({ courseId }: any) {
  return async (dispatch: any) => {
    dispatch({ type: types.SYNC_ROSTER_REQUEST });
    try {
      const response = await lmsServices().syncRoster({ courseId, apply: false });
      if (response.status === 200) {
        dispatch({
          type: types.SYNC_ROSTER_SUCCESS,
          payload: response.data,
        });
      }
    } catch (error) {
      dispatch({ type: types.SYNC_ROSTER_FAILURE, error });
    }
  };
}

const autoSyncInitState = {
  status: '',
  message: '',
};

export const autoSyncReducer = (state = autoSyncInitState, action: any) => {
  switch (action.type) {
    case types.EDLINK_AUTO_SYNC_SUCCESS:
      return {
        ...state,
        status: 'success',
        result: action.payload,
      };
    case types.EDLINK_AUTO_SYNC_FAILURE:
      return {
        ...state,
        status: 'fail',
      };
    default:
      return state;
  }
};

export const selectRosterSyncStatus = (state: any) => {
  return state.edlink.rosterSync.status;
};

export const selectRosterSyncResult = (state: any) => {
  return state.edlink.rosterSync.result;
};

export const selectAutoSyncStatus = (state: any) => {
  return state.edlink.autoSync;
};

export default combineReducers({
  rosterSync: rosterSyncReducer,
  autoSync: autoSyncReducer,
});
