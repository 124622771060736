import { localize } from 'locales';

export const GOAL_TYPES = {
  QUESTION: 'question',
  ESSAY: 'essay',
  PEER_CONTENT: 'peerContent',
  MULTIMEDIA: 'multimedia ',
  WITHIN_GROUP: 'groupAssessment',
  GROUP_PRESENTATION: 'groupPresentation',
  INDIVIDUAL_PRESENTATION: 'individualPresentation',
};

export const ACTIVITY_GOALS = [
  {
    label: localize({ message: 'ActivityEdit.Details.Constants.Goals.CreateQuestion' }),
    value: GOAL_TYPES.QUESTION,
  },
  {
    label: localize({ message: 'ActivityEdit.Details.Constants.Goals.CreateEssay' }),
    value: GOAL_TYPES.ESSAY,
  },
  {
    label: localize({ message: 'ActivityEdit.Details.Constants.Goals.CreateContentToTeachPeers' }),
    value: GOAL_TYPES.PEER_CONTENT,
  },
  {
    label: localize({ message: 'ActivityEdit.Details.Constants.Goals.CreativeCommunication' }),
    value: GOAL_TYPES.MULTIMEDIA,
  },
  {
    label: localize({ message: 'ActivityEdit.Details.Constants.Goals.EvaluateGroupMembers' }),
    value: GOAL_TYPES.WITHIN_GROUP,
  },
];

export const NOTES_PLACEHOLDER = localize({
  message: 'ActivityEdit.Details.Constants.NotesPlaceholder',
});

const passLevel = '0';
const passCriteriaRule = 'any';
const levels = [
  localize({ message: 'ActivityEdit.Details.Constants.Levels.Novice' }),
  localize({ message: 'ActivityEdit.Details.Constants.Levels.Intermediate' }),
  localize({ message: 'ActivityEdit.Details.Constants.Levels.Proficient' }),
  localize({ message: 'ActivityEdit.Details.Constants.Levels.Advanced' }),
];

export const ACTIVITY_TEMPLATES = {
  [GOAL_TYPES.QUESTION]: {
    type: 'question',
    objective: `<p>${localize({ message: 'ActivityEdit.Details.Constants.Question.Objective' })} <a href="https://www.kritik.io/resources/blooms-taxonomy" target="_blank">${localize({ message: 'ActivityEdit.Details.Constants.Question.ObjectiveLink' })}</a>.</p>`,
    instructions:
      `<p>${localize({ message: 'ActivityEdit.Details.Constants.Question.Instructions.Paragraph1' })}</p>` +
      '<br/>' +
      `<p>${localize({ message: 'ActivityEdit.Details.Constants.Question.Instructions.Paragraph2' })}</p>` +
      '<br/>' +
      `<i>${localize({ message: 'ActivityEdit.Details.Constants.Question.Instructions.Paragraph3' })}</i>`,
    rubric: {
      levels,
      criteria: [
        {
          name: localize({
            message: 'ActivityEdit.Details.Constants.Question.Criteria.RichInformation',
          }),
          weight: 1,
        },
        {
          name: localize({
            message: 'ActivityEdit.Details.Constants.Question.Criteria.Complexity',
          }),
          weight: 1,
        },
        {
          name: localize({ message: 'ActivityEdit.Details.Constants.Question.Criteria.Scope' }),
          weight: 1,
        },
        {
          name: localize({ message: 'ActivityEdit.Details.Constants.Question.Criteria.Relevance' }),
          weight: 1,
        },
        {
          name: localize({
            message: 'ActivityEdit.Details.Constants.Question.Criteria.Creativity',
          }),
          weight: 1,
        },
      ],
      grid: [
        [
          localize({ message: 'ActivityEdit.Details.Constants.Question.Grid.1.1' }),
          localize({ message: 'ActivityEdit.Details.Constants.Question.Grid.1.2' }),
          localize({ message: 'ActivityEdit.Details.Constants.Question.Grid.1.3' }),
          localize({ message: 'ActivityEdit.Details.Constants.Question.Grid.1.4' }),
        ],
        [
          localize({ message: 'ActivityEdit.Details.Constants.Question.Grid.2.1' }),
          localize({ message: 'ActivityEdit.Details.Constants.Question.Grid.2.2' }),
          localize({ message: 'ActivityEdit.Details.Constants.Question.Grid.2.3' }),
          localize({ message: 'ActivityEdit.Details.Constants.Question.Grid.2.4' }),
        ],
        [
          localize({ message: 'ActivityEdit.Details.Constants.Question.Grid.3.1' }),
          localize({ message: 'ActivityEdit.Details.Constants.Question.Grid.3.2' }),
          localize({ message: 'ActivityEdit.Details.Constants.Question.Grid.3.3' }),
          localize({ message: 'ActivityEdit.Details.Constants.Question.Grid.3.4' }),
        ],
        [
          localize({ message: 'ActivityEdit.Details.Constants.Question.Grid.4.1' }),
          localize({ message: 'ActivityEdit.Details.Constants.Question.Grid.4.2' }),
          localize({ message: 'ActivityEdit.Details.Constants.Question.Grid.4.3' }),
          localize({ message: 'ActivityEdit.Details.Constants.Question.Grid.4.4' }),
        ],
        [
          localize({ message: 'ActivityEdit.Details.Constants.Question.Grid.5.1' }),
          localize({ message: 'ActivityEdit.Details.Constants.Question.Grid.5.2' }),
          localize({ message: 'ActivityEdit.Details.Constants.Question.Grid.5.3' }),
          localize({ message: 'ActivityEdit.Details.Constants.Question.Grid.5.4' }),
        ],
      ],
      passLevel,
      passCriteriaRule,
    },
  },
  [GOAL_TYPES.ESSAY]: {
    type: 'essay',
    objective: `<p>${localize({ message: 'ActivityEdit.Details.Constants.Essay.Objective' })}</p>`,
    instructions: `<p>${localize({ message: 'ActivityEdit.Details.Constants.Essay.Instructions' })}</p>`,
    rubric: {
      levels,
      criteria: [
        {
          name: localize({
            message: 'ActivityEdit.Details.Constants.Essay.Criteria.ClarityOfThoughts',
          }),
          weight: 1,
        },
        {
          name: localize({ message: 'ActivityEdit.Details.Constants.Essay.Criteria.Accuracy' }),
          weight: 1,
        },
        {
          name: localize({
            message: 'ActivityEdit.Details.Constants.Essay.Criteria.CreativeAndCriticalThinking',
          }),
          weight: 1,
        },
        {
          name: localize({
            message: 'ActivityEdit.Details.Constants.Essay.Criteria.SourceAndEvidence',
          }),
          weight: 1,
        },
      ],
      grid: [
        [
          localize({ message: 'ActivityEdit.Details.Constants.Essay.Grid.1.1' }),
          localize({ message: 'ActivityEdit.Details.Constants.Essay.Grid.1.2' }),
          localize({ message: 'ActivityEdit.Details.Constants.Essay.Grid.1.3' }),
          localize({ message: 'ActivityEdit.Details.Constants.Essay.Grid.1.4' }),
        ],
        [
          localize({ message: 'ActivityEdit.Details.Constants.Essay.Grid.2.1' }),
          localize({ message: 'ActivityEdit.Details.Constants.Essay.Grid.2.2' }),
          localize({ message: 'ActivityEdit.Details.Constants.Essay.Grid.2.3' }),
          localize({ message: 'ActivityEdit.Details.Constants.Essay.Grid.2.4' }),
        ],
        [
          localize({ message: 'ActivityEdit.Details.Constants.Essay.Grid.3.1' }),
          localize({ message: 'ActivityEdit.Details.Constants.Essay.Grid.3.2' }),
          localize({ message: 'ActivityEdit.Details.Constants.Essay.Grid.3.3' }),
          localize({ message: 'ActivityEdit.Details.Constants.Essay.Grid.3.4' }),
        ],
        [
          localize({ message: 'ActivityEdit.Details.Constants.Essay.Grid.4.1' }),
          localize({ message: 'ActivityEdit.Details.Constants.Essay.Grid.4.2' }),
          localize({ message: 'ActivityEdit.Details.Constants.Essay.Grid.4.3' }),
          localize({ message: 'ActivityEdit.Details.Constants.Essay.Grid.4.4' }),
        ],
      ],
      passLevel,
      passCriteriaRule,
    },
  },
  [GOAL_TYPES.PEER_CONTENT]: {
    type: 'peerContent',
    objective: `<p>${localize({ message: 'ActivityEdit.Details.Constants.PeerContent.Objective' })}</p>`,
    instructions: `<p>${localize({ message: 'ActivityEdit.Details.Constants.PeerContent.Instructions' })}</p>`,
    rubric: {
      levels,
      criteria: [
        {
          name: localize({
            message: 'ActivityEdit.Details.Constants.PeerContent.Criteria.Organization',
          }),
          weight: 1,
        },
        {
          name: localize({
            message: 'ActivityEdit.Details.Constants.PeerContent.Criteria.Relevance',
          }),
          weight: 1,
        },
        {
          name: localize({
            message: 'ActivityEdit.Details.Constants.PeerContent.Criteria.Clarity',
          }),
          weight: 1,
        },
        {
          name: localize({
            message: 'ActivityEdit.Details.Constants.PeerContent.Criteria.KnowledgeOfContent',
          }),
          weight: 1,
        },
      ],
      grid: [
        [
          localize({ message: 'ActivityEdit.Details.Constants.PeerContent.Grid.1.1' }),
          localize({ message: 'ActivityEdit.Details.Constants.PeerContent.Grid.1.2' }),
          localize({ message: 'ActivityEdit.Details.Constants.PeerContent.Grid.1.3' }),
          localize({ message: 'ActivityEdit.Details.Constants.PeerContent.Grid.1.4' }),
        ],
        [
          localize({ message: 'ActivityEdit.Details.Constants.PeerContent.Grid.2.1' }),
          localize({ message: 'ActivityEdit.Details.Constants.PeerContent.Grid.2.2' }),
          localize({ message: 'ActivityEdit.Details.Constants.PeerContent.Grid.2.3' }),
          localize({ message: 'ActivityEdit.Details.Constants.PeerContent.Grid.2.4' }),
        ],
        [
          localize({ message: 'ActivityEdit.Details.Constants.PeerContent.Grid.3.1' }),
          localize({ message: 'ActivityEdit.Details.Constants.PeerContent.Grid.3.2' }),
          localize({ message: 'ActivityEdit.Details.Constants.PeerContent.Grid.3.3' }),
          localize({ message: 'ActivityEdit.Details.Constants.PeerContent.Grid.3.4' }),
        ],
        [
          localize({ message: 'ActivityEdit.Details.Constants.PeerContent.Grid.4.1' }),
          localize({ message: 'ActivityEdit.Details.Constants.PeerContent.Grid.4.2' }),
          localize({ message: 'ActivityEdit.Details.Constants.PeerContent.Grid.4.3' }),
          localize({ message: 'ActivityEdit.Details.Constants.PeerContent.Grid.4.4' }),
        ],
      ],
      passLevel,
      passCriteriaRule,
    },
  },
  [GOAL_TYPES.MULTIMEDIA]: {
    type: 'multimedia',
    objective: `<p>${localize({ message: 'ActivityEdit.Details.Constants.Multimedia.Objective' })}</p>`,
    instructions: `<p>${localize({ message: 'ActivityEdit.Details.Constants.Multimedia.Instructions' })}</p>`,
    rubric: {
      levels,
      criteria: [
        {
          name: localize({
            message: 'ActivityEdit.Details.Constants.Multimedia.Criteria.Organization',
          }),
          weight: 1,
        },
        {
          name: localize({
            message: 'ActivityEdit.Details.Constants.Multimedia.Criteria.Knowledge',
          }),
          weight: 1,
        },
        {
          name: localize({
            message: 'ActivityEdit.Details.Constants.Multimedia.Criteria.TextAndReadability',
          }),
          weight: 1,
        },
        {
          name: localize({
            message: 'ActivityEdit.Details.Constants.Multimedia.Criteria.Creativity',
          }),
          weight: 1,
        },
        {
          name: localize({
            message: 'ActivityEdit.Details.Constants.Multimedia.Criteria.VisualAid',
          }),
          weight: 1,
        },
      ],
      grid: [
        [
          localize({ message: 'ActivityEdit.Details.Constants.Multimedia.Grid.1.1' }),
          localize({ message: 'ActivityEdit.Details.Constants.Multimedia.Grid.1.2' }),
          localize({ message: 'ActivityEdit.Details.Constants.Multimedia.Grid.1.3' }),
          localize({ message: 'ActivityEdit.Details.Constants.Multimedia.Grid.1.4' }),
        ],
        [
          localize({ message: 'ActivityEdit.Details.Constants.Multimedia.Grid.2.1' }),
          localize({ message: 'ActivityEdit.Details.Constants.Multimedia.Grid.2.2' }),
          localize({ message: 'ActivityEdit.Details.Constants.Multimedia.Grid.2.3' }),
          localize({ message: 'ActivityEdit.Details.Constants.Multimedia.Grid.2.4' }),
        ],
        [
          localize({ message: 'ActivityEdit.Details.Constants.Multimedia.Grid.3.1' }),
          localize({ message: 'ActivityEdit.Details.Constants.Multimedia.Grid.3.2' }),
          localize({ message: 'ActivityEdit.Details.Constants.Multimedia.Grid.3.3' }),
          localize({ message: 'ActivityEdit.Details.Constants.Multimedia.Grid.3.4' }),
        ],
        [
          localize({ message: 'ActivityEdit.Details.Constants.Multimedia.Grid.4.1' }),
          localize({ message: 'ActivityEdit.Details.Constants.Multimedia.Grid.4.2' }),
          localize({ message: 'ActivityEdit.Details.Constants.Multimedia.Grid.4.3' }),
          localize({ message: 'ActivityEdit.Details.Constants.Multimedia.Grid.4.4' }),
        ],
        [
          localize({ message: 'ActivityEdit.Details.Constants.Multimedia.Grid.5.1' }),
          localize({ message: 'ActivityEdit.Details.Constants.Multimedia.Grid.5.2' }),
          localize({ message: 'ActivityEdit.Details.Constants.Multimedia.Grid.5.3' }),
          localize({ message: 'ActivityEdit.Details.Constants.Multimedia.Grid.5.4' }),
        ],
      ],
      passLevel,
      passCriteriaRule,
    },
  },
  [GOAL_TYPES.WITHIN_GROUP]: {
    type: 'question',
    objective: `<p>${localize({ message: 'ActivityEdit.Details.Constants.WithinGroup.Objective.Paragraph1' })}</p><p>${localize({ message: 'ActivityEdit.Details.Constants.WithinGroup.Objective.Paragraph2' })}</p>`,
    instructions: `<p>${localize({ message: 'ActivityEdit.Details.Constants.WithinGroup.Instructions.Paragraph1' })}</p><ul><li>${localize({ message: 'ActivityEdit.Details.Constants.WithinGroup.Instructions.ListItem1' })}</li><li>${localize({ message: 'ActivityEdit.Details.Constants.WithinGroup.Instructions.ListItem2' })}</li><li>${localize({ message: 'ActivityEdit.Details.Constants.WithinGroup.Instructions.ListItem3' })}</li><li>${localize({ message: 'ActivityEdit.Details.Constants.WithinGroup.Instructions.ListItem4' })}</li></ul><p>${localize({ message: 'ActivityEdit.Details.Constants.WithinGroup.Instructions.Paragraph2' })}</p><ul><li>${localize({ message: 'ActivityEdit.Details.Constants.WithinGroup.Instructions.ListItem5' })}</li><li>${localize({ message: 'ActivityEdit.Details.Constants.WithinGroup.Instructions.ListItem6' })}</li><li>${localize({ message: 'ActivityEdit.Details.Constants.WithinGroup.Instructions.ListItem7' })}</li><li>${localize({ message: 'ActivityEdit.Details.Constants.WithinGroup.Instructions.ListItem8' })}</li></ul><p>${localize({ message: 'ActivityEdit.Details.Constants.WithinGroup.Instructions.Paragraph3' })}</p>`,
    rubric: {
      levels,
      criteria: [
        {
          name: localize({
            message: 'ActivityEdit.Details.Constants.WithinGroup.Criteria.ContributionsAndAttitude',
          }),
          weight: 1,
        },
        {
          name: localize({
            message: 'ActivityEdit.Details.Constants.WithinGroup.Criteria.CooperationWithOthers',
          }),
          weight: 1,
        },
        {
          name: localize({
            message: 'ActivityEdit.Details.Constants.WithinGroup.Criteria.FocusCommitments',
          }),
          weight: 1,
        },
        {
          name: localize({
            message: 'ActivityEdit.Details.Constants.WithinGroup.Criteria.TeamRoleFulfillment',
          }),
          weight: 1,
        },
        {
          name: localize({
            message: 'ActivityEdit.Details.Constants.WithinGroup.Criteria.AbilitytoCommunicate',
          }),
          weight: 1,
        },
        {
          name: localize({
            message: 'ActivityEdit.Details.Constants.WithinGroup.Criteria.Accuracy',
          }),
          weight: 1,
        },
      ],
      grid: [
        [
          localize({ message: 'ActivityEdit.Details.Constants.WithinGroup.Grid.1.1' }),
          localize({ message: 'ActivityEdit.Details.Constants.WithinGroup.Grid.1.2' }),
          localize({ message: 'ActivityEdit.Details.Constants.WithinGroup.Grid.1.3' }),
          localize({ message: 'ActivityEdit.Details.Constants.WithinGroup.Grid.1.4' }),
        ],
        [
          localize({ message: 'ActivityEdit.Details.Constants.WithinGroup.Grid.2.1' }),
          localize({ message: 'ActivityEdit.Details.Constants.WithinGroup.Grid.2.2' }),
          localize({ message: 'ActivityEdit.Details.Constants.WithinGroup.Grid.2.3' }),
          localize({ message: 'ActivityEdit.Details.Constants.WithinGroup.Grid.2.4' }),
        ],
        [
          localize({ message: 'ActivityEdit.Details.Constants.WithinGroup.Grid.3.1' }),
          localize({ message: 'ActivityEdit.Details.Constants.WithinGroup.Grid.3.2' }),
          localize({ message: 'ActivityEdit.Details.Constants.WithinGroup.Grid.3.3' }),
          localize({ message: 'ActivityEdit.Details.Constants.WithinGroup.Grid.3.4' }),
        ],
        [
          localize({ message: 'ActivityEdit.Details.Constants.WithinGroup.Grid.4.1' }),
          localize({ message: 'ActivityEdit.Details.Constants.WithinGroup.Grid.4.2' }),
          localize({ message: 'ActivityEdit.Details.Constants.WithinGroup.Grid.4.3' }),
          localize({ message: 'ActivityEdit.Details.Constants.WithinGroup.Grid.4.4' }),
        ],
        [
          localize({ message: 'ActivityEdit.Details.Constants.WithinGroup.Grid.5.1' }),
          localize({ message: 'ActivityEdit.Details.Constants.WithinGroup.Grid.5.2' }),
          localize({ message: 'ActivityEdit.Details.Constants.WithinGroup.Grid.5.3' }),
          localize({ message: 'ActivityEdit.Details.Constants.WithinGroup.Grid.5.4' }),
        ],
        [
          localize({ message: 'ActivityEdit.Details.Constants.WithinGroup.Grid.6.1' }),
          localize({ message: 'ActivityEdit.Details.Constants.WithinGroup.Grid.6.2' }),
          localize({ message: 'ActivityEdit.Details.Constants.WithinGroup.Grid.6.3' }),
          localize({ message: 'ActivityEdit.Details.Constants.WithinGroup.Grid.6.4' }),
        ],
      ],
      passLevel,
      passCriteriaRule,
    },
  },
  [GOAL_TYPES.GROUP_PRESENTATION]: {
    type: 'question',
    objective: `<p>${localize({ message: 'Rubric.Presentation.Group.Objective' })}</p>`,
    instructions: `<p>${localize({ message: 'Rubric.Presentation.Group.Instructions' })}</p>`,
    rubric: {
      levels,
      criteria: [
        {
          name: localize({ message: 'Rubric.Presentation.Group.Crit1' }),
          weight: 1,
        },
        {
          name: localize({ message: 'Rubric.Presentation.Group.Crit2' }),
          weight: 1,
        },
        {
          name: localize({ message: 'Rubric.Presentation.Group.Crit3' }),
          weight: 1,
        },
      ],
      grid: [
        [
          localize({ message: 'Rubric.Presentation.Group.Crit1.L1' }),
          localize({ message: 'Rubric.Presentation.Group.Crit1.L2' }),
          localize({ message: 'Rubric.Presentation.Group.Crit1.L3' }),
          localize({ message: 'Rubric.Presentation.Group.Crit1.L4' }),
        ],
        [
          localize({ message: 'Rubric.Presentation.Group.Crit2.L1' }),
          localize({ message: 'Rubric.Presentation.Group.Crit2.L2' }),
          localize({ message: 'Rubric.Presentation.Group.Crit2.L3' }),
          localize({ message: 'Rubric.Presentation.Group.Crit2.L4' }),
        ],
        [
          localize({ message: 'Rubric.Presentation.Group.Crit3.L1' }),
          localize({ message: 'Rubric.Presentation.Group.Crit3.L2' }),
          localize({ message: 'Rubric.Presentation.Group.Crit3.L3' }),
          localize({ message: 'Rubric.Presentation.Group.Crit3.L4' }),
        ],
      ],
      passLevel,
      passCriteriaRule,
    },
  },
  [GOAL_TYPES.INDIVIDUAL_PRESENTATION]: {
    type: 'question',
    objective: `<p>${localize({ message: 'Rubric.Presentation.Individual.Objective' })}</p>`,
    instructions: `<p>${localize({ message: 'Rubric.Presentation.Individual.Instructions' })}</p>`,
    rubric: {
      levels,
      criteria: [
        {
          name: localize({ message: 'Rubric.Presentation.Individual.Crit1' }),
          weight: 1,
        },
        {
          name: localize({ message: 'Rubric.Presentation.Individual.Crit2' }),
          weight: 1,
        },
        {
          name: localize({ message: 'Rubric.Presentation.Individual.Crit3' }),
          weight: 1,
        },
      ],
      grid: [
        [
          localize({ message: 'Rubric.Presentation.Individual.Crit1.L1' }),
          localize({ message: 'Rubric.Presentation.Individual.Crit1.L2' }),
          localize({ message: 'Rubric.Presentation.Individual.Crit1.L3' }),
          localize({ message: 'Rubric.Presentation.Individual.Crit1.L4' }),
        ],
        [
          localize({ message: 'Rubric.Presentation.Individual.Crit2.L1' }),
          localize({ message: 'Rubric.Presentation.Individual.Crit2.L2' }),
          localize({ message: 'Rubric.Presentation.Individual.Crit2.L3' }),
          localize({ message: 'Rubric.Presentation.Individual.Crit2.L4' }),
        ],
        [
          localize({ message: 'Rubric.Presentation.Individual.Crit3.L1' }),
          localize({ message: 'Rubric.Presentation.Individual.Crit3.L2' }),
          localize({ message: 'Rubric.Presentation.Individual.Crit3.L3' }),
          localize({ message: 'Rubric.Presentation.Individual.Crit3.L4' }),
        ],
      ],
      passLevel,
      passCriteriaRule,
    },
  },
};
