import { Spotlight as SpotlightType } from '@kritik/types.generated';
import { isPresentationActivity } from '@kritik/utils/activity';
import { studentParticipatesInActivityFromStudentView } from '@kritik/utils/stage';
import Grid from '@material-ui/core/Grid';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { navigateToActivityPage } from 'actions/activity';
import { navigateToCourseActivitiesPage } from 'actions/courses';
import { openConfirmModal } from 'actions/modals';
import HtmlContent from 'components/General/HtmlContent';
import Reference from 'components/Spotlight/Reference';
import SpotlightCardView from 'components/Spotlight/View/Card';
import MarginVertical from 'components/Spotlight/View/MarginVertical';
import { TranslatedText } from 'components/TranslatedText';
import { Button, ButtonContainer } from 'components/buttons';
import AvatarDisplay from 'components/layout/AvatarDisplay';
import NoticeBoard from 'components/layout/NoticeBoard';
import { useUserRoleInCourse } from 'hooks/course';
import NewTabIcon from 'images/icons/fa/NewTab';
import { localize } from 'locales';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  CREATE_SPOTLIGHT,
  createSpotlight,
  deleteSpotlight,
  isMyContent,
  navigateToSpotlightEdit,
  selectIsPreview,
  selectSpotlightAsyncActions,
  setIsPreview,
} from 'redux/spotlight';
import { SpotlightTagStore } from 'redux/spotlightTag';
import { selectCurrentActivity } from 'selectors/activity';
import { selectCurrentInstructor } from 'selectors/user';
import { Spotlight } from 'utils/spotlight';
import { getActivityPage } from 'utils/url';
import { trackEvent } from 'utils/userEvents';

type SpotlightViewProps = {
  spotlight: SpotlightType;
  activity: any;
  instructor: any;
  authUser: any;
  spotlightAsyncActions: any;
  tag: any;
  isPreview: boolean;
  isMyContent: boolean;
  createSpotlight: any;
  setIsPreview: any;
  navigateToSpotlightEdit: any;
  deleteSpotlight: any;
  openConfirmModal: any;
  navigateToActivityPage: any;
  navigateToCourseActivitiesPage: any;
};

function SpotlightView(props: SpotlightViewProps) {
  const { spotlight, activity, instructor, authUser, spotlightAsyncActions, tag } = props;
  const { isInstructorInCourse, isStudentInCourse } = useUserRoleInCourse();

  const canNavigateToActivity =
    activity &&
    (isInstructorInCourse ||
      !isPresentationActivity(activity) ||
      studentParticipatesInActivityFromStudentView({ activity }));

  useEffect(() => {
    if (isStudentInCourse) {
      trackEvent('Student Opened Spotlight', authUser);
    }
  }, []);

  const _spotlight = Spotlight(spotlight);

  const handleBackButtonClick = () => {
    if (canNavigateToActivity) {
      props.navigateToCourseActivitiesPage({
        courseId: activity.getCourseId(),
      });
    }

    props.navigateToActivityPage({
      courseId: activity.getCourseId(),
      assignmentId: activity.getId(),
    });
  };

  const renderBackBtn = () => {
    if (props.isPreview) {
      return null;
    }
    return (
      <>
        <Button type="secondary" className="spotlight-view__back-btn" onClick={handleBackButtonClick}>
          <ArrowBackIcon fontSize="small" />
        </Button>
        <MarginVertical size="default" />
      </>
    );
  };

  const renderTitle = () => {
    return (
      <div className="spotlight-view__tag-title">
        {tag.getName()}&nbsp;
        {_spotlight.getType()}
      </div>
    );
  };

  const renderFrom = () => {
    const activityPage = getActivityPage(activity.getCourseId(), activity.getId());
    const { isPreview } = props;
    return (
      <div className="spotlight-view__from">
        <TranslatedText i18nKey="Spotlights.View.From" />
        <a
          href={canNavigateToActivity ? activityPage : null}
          target={isPreview ? 'blank' : ''}
          className="spotlight-view__from-activity"
        >
          {isPreview && <NewTabIcon />}
          {activity.getTitle()}
        </a>
      </div>
    );
  };
  const renderAnonymousBanner = () => {
    if (!props.isMyContent) {
      return null;
    }
    return (
      <>
        <MarginVertical size="sm" />
        <NoticeBoard type="information">
          <TranslatedText
            i18nKey="Spotlights.Warning.AnonymousIdentity"
            values={{ name: authUser.profile.firstName }}
          />
        </NoticeBoard>
      </>
    );
  };

  const renderInstructorComment = () => {
    const comment = _spotlight.getComment();
    if (!comment) {
      return null;
    }
    return (
      <>
        <div className="spotlight-view__instructor-comment">
          <span className="spotlight-view__instructor-quote">“</span>
          <div className="spotlight-view__instructor-comment-text">
            <HtmlContent content={comment} />
          </div>
        </div>
        <MarginVertical size="sm" />
      </>
    );
  };

  const renderInstructorContent = () => {
    return (
      <div className="spotlight-view__comment">
        {renderInstructorComment()}
        <div className="spotlight-view__comment-avatar">
          <AvatarDisplay user={instructor} size="48px" anonymous={false} showBorder={false} />
          <span className="spotlight-view__comment-avatar__name">{instructor.profile.name}</span>
        </div>
      </div>
    );
  };

  const handleClickShare = () => {
    props.createSpotlight(_spotlight.serialize());
  };

  const renderButtons = () => {
    if (!isInstructorInCourse) {
      return null;
    }
    if (_spotlight.getId()) {
      // Edit
      const handleClickEdit = () => {
        props.navigateToSpotlightEdit({
          courseId: activity.getCourseId(),
          activityId: activity.getId(),
          spotlightId: _spotlight.getId(),
        });
      };

      const handleClickRemove = () => {
        props.openConfirmModal({
          title: localize({ message: 'Spotlights.View.RemoveConfirm.Title' }),
          description: localize({ message: 'Spotlights.View.RemoveConfirm.Description' }),
          confirmButton: localize({ message: 'Spotlights.View.RemoveConfirm.ConfirmButton' }),
          cancelButton: localize({ message: 'Spotlights.View.RemoveConfirm.CancelButton' }),
          onConfirm: () => {
            return props.deleteSpotlight(_spotlight.getId(), true);
          },
        });
      };
      return (
        <ButtonContainer>
          <Button type="primary" onClick={handleClickEdit}>
            <TranslatedText i18nKey="Spotlights.View.EditButton" />
          </Button>
          <Button type="secondary" onClick={handleClickRemove} data-testid="remove-spotlight">
            <TranslatedText i18nKey="Spotlights.View.RemoveButton" />
          </Button>
        </ButtonContainer>
      );
    }

    const handleClickBack = () => {
      props.setIsPreview(false);
    };
    return (
      <ButtonContainer>
        <Button
          type="primary"
          onClick={handleClickShare}
          loading={spotlightAsyncActions[CREATE_SPOTLIGHT].busy}
          success={spotlightAsyncActions[CREATE_SPOTLIGHT].success}
          disabled={spotlightAsyncActions[CREATE_SPOTLIGHT].busy}
          data-testid="share-spotlight"
        >
          <TranslatedText i18nKey="Spotlights.View.ShareButton" />
        </Button>
        <Button type="secondary" onClick={handleClickBack}>
          <TranslatedText i18nKey="Spotlights.View.BackButton" />
        </Button>
      </ButtonContainer>
    );
  };

  if (!activity) {
    return null;
  }
  const isSpotlightTag = tag.getName() === 'Spotlight';

  return (
    <div className="spotlight-view">
      {renderBackBtn()}
      {renderTitle()}
      {spotlight.status === 'draft' && (
        <NoticeBoard>
          <ul style={{ margin: '0px' }}>
            <li>
              <TranslatedText i18nKey="Spotlights.Warning.Draft" />
            </li>
            <li>
              <TranslatedText i18nKey="Spotlights.Warning.AnonymousContent" />
            </li>
          </ul>
        </NoticeBoard>
      )}
      <MarginVertical size="sm" />
      {renderFrom()}
      {!canNavigateToActivity && (
        <NoticeBoard>
          <TranslatedText i18nKey="Spotlights.Warning.CannotNavigateToActivity" />
        </NoticeBoard>
      )}
      {renderAnonymousBanner()}
      <MarginVertical size="default" />
      <Grid container spacing={3}>
        <Grid item md={isSpotlightTag ? 12 : 8}>
          {renderInstructorContent()}
          <MarginVertical size="default" />
          <Reference spotlight={_spotlight} />
          <MarginVertical />
          {renderButtons()}
        </Grid>
        {!isSpotlightTag && (
          <Grid item md={4} style={{ maxWidth: 350, maxHeight: 280 }}>
            <SpotlightCardView tag={tag} hideActivityLink isClickable={false} />
          </Grid>
        )}
      </Grid>
    </div>
  );
}

const mapStateToProps = (state: any, ownProps: any) => {
  const spotlightTagStore = new SpotlightTagStore(state);
  return {
    activity: selectCurrentActivity(state),
    instructor: selectCurrentInstructor(state),
    authUser: state.user.authUser,
    isPreview: selectIsPreview(state),
    spotlightAsyncActions: selectSpotlightAsyncActions(state),
    isMyContent: isMyContent(state, state.user, ownProps.spotlight),
    tag: ownProps.tag || spotlightTagStore.getById(ownProps.spotlight?.tag._id || ownProps.spotlight?.tag),
  };
};

export default connect(mapStateToProps, {
  createSpotlight,
  setIsPreview,
  navigateToSpotlightEdit,
  deleteSpotlight,
  openConfirmModal,
  navigateToActivityPage,
  navigateToCourseActivitiesPage,
})(SpotlightView);
