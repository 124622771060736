import { createContext, useState } from 'react';

interface AppContextInterface {
  creation: any;
  setCreation: (data: any | null) => void;
}

export const CreationContext = createContext<AppContextInterface | null>(null);

const CreationProvider = (props: any) => {
  const [creation, setCreation] = useState(null);

  return <CreationContext.Provider value={{ creation, setCreation }}>{props.children}</CreationContext.Provider>;
};

export default CreationProvider;
