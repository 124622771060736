export const RADIO_VALUES = {
  DEFAULT: 'default',
  CUSTOM: 'custom',
};
import { localize } from 'locales';

export const CALIBRATION_OPTIONS = [
  {
    value: RADIO_VALUES.DEFAULT,
    label: localize({ message: 'Activity.Weight.Points', options: { count: 0 } }),
  },
  {
    value: RADIO_VALUES.CUSTOM,
    label: localize({ message: 'Activity.Weight.Custom' }),
    isDisabled: true,
  },
];

export default {
  RADIO_VALUES,
  CALIBRATION_OPTIONS,
};
