import { navigateToCourseDetailsEditPage } from 'actions/courses';
import { TranslatedText } from 'components/TranslatedText';
import Button from 'components/buttons/Button';
import { localize } from 'locales';
import { connect } from 'react-redux';
import { getCourse } from 'selectors/course';

const NoLmsCourseConnected = ({ course, navigateToCourseDetailsEditPage }: any) => {
  function navigateToCourseSettings() {
    navigateToCourseDetailsEditPage(course._id, '?scroll-to-lms=true');
  }
  return (
    <div className="sync-roster-modal__no-course">
      <div className="sync-roster-modal__title">
        <TranslatedText i18nKey="RosterManager.SyncRosterPopup.NoLmsCourseConnected.Title" />
      </div>
      <div className="sync-roster-modal__description">
        <TranslatedText i18nKey="RosterManager.SyncRosterPopup.NoLmsCourseConnected.Description" />
      </div>
      <Button type="secondary" onClick={() => navigateToCourseSettings()}>
        {localize({ message: 'RosterManager.SyncRosterPopup.NoLmsCourseConnected.ButtonText' })}
      </Button>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    course: getCourse(state),
  };
};
export default connect(mapStateToProps, { navigateToCourseDetailsEditPage })(NoLmsCourseConnected);
