import { resetAsync } from 'actions/async';
import CreationDisplay from 'components/Creation/Create/CreationDisplay';
import WrittenEvaluation from 'components/Creation/Evaluate/WrittenEvaluation';
import LoaderOverlay from 'components/Loaders/OverlaySpinner';
import MarginVertical from 'components/Spotlight/View/MarginVertical';
import ReferenceContentWrapper from 'components/Spotlight/View/ReferenceContentWrapper';
import { TextDisplay } from 'components/layout';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { LIST_CREATIONS, listCreations } from 'redux/creation';
import { GET_EVALUATION, getEvaluation, selectEvaluation } from 'redux/evaluation';
import { getSubmission } from 'selectors/creation';

function Evaluation(props: any) {
  const { evaluation, creation, evaluationId } = props;
  useEffect(() => {
    if (!creation) {
      props.listCreations({
        evaluation: evaluationId,
      });
    }
    if (!evaluation) {
      props.getEvaluation({
        id: evaluationId,
      });
    }
    return function cleanup() {
      resetAsync(LIST_CREATIONS);
      resetAsync(GET_EVALUATION);
    };
  }, []);

  if (!creation || !evaluation) {
    return <LoaderOverlay isOpen />;
  }

  return (
    <React.Fragment>
      <ReferenceContentWrapper title="Spotlighted Written Evaluation">
        <TextDisplay>
          <WrittenEvaluation evaluation={evaluation} />
        </TextDisplay>
      </ReferenceContentWrapper>
      <MarginVertical size="md" />
      <ReferenceContentWrapper title="Creation that was evaluated">
        <CreationDisplay creation={creation} />
      </ReferenceContentWrapper>
    </React.Fragment>
  );
}

const mapStateToProps = (state: any, ownProps: any) => {
  const evaluation = selectEvaluation(state, ownProps.evaluationId);
  const creation = evaluation ? getSubmission(state, evaluation.getCreationId()) : null;
  return {
    evaluation,
    creation,
  };
};

export default connect(mapStateToProps, {
  listCreations,
  getEvaluation,
})(Evaluation);
