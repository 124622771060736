import EmailList from 'components/RosterManager/SyncRosterPopup/EmailList';
import { TranslatedText } from 'components/TranslatedText';
import InlineInformation from 'components/layout/InlineInformation';
import NoticeBoard from 'components/layout/NoticeBoard';
import { localize } from 'locales';

export const UpdatedCourseRoster = ({ rosterChanges }) => {
  const {
    studentsAdded,
    studentsRemoved,
    studentsUpdated,
    collaboratorsAdded,
    collaboratorsRemoved,
    collaboratorsUpdated,
  } = rosterChanges;
  const hasAddedStudents = Boolean(studentsAdded.length);
  const hasRemovedStudents = Boolean(studentsRemoved.length);
  const hasUpdatedStudents = Boolean(studentsUpdated.length);
  const hasAddedCollaborators = Boolean(collaboratorsAdded.length);
  const hasRemovedCollaborators = Boolean(collaboratorsRemoved.length);
  const hasUpdatedCollaborators = Boolean(collaboratorsUpdated.length);

  if (!hasAddedStudents && !hasRemovedStudents && !hasAddedCollaborators && !hasRemovedCollaborators) {
    const title =
      hasUpdatedStudents || hasUpdatedCollaborators
        ? localize({
            message: 'RosterManager.SyncRosterPopup.UpdatedCourseRoster.NoChanges.UpdatedOnly',
          })
        : localize({ message: 'RosterManager.SyncRosterPopup.UpdatedCourseRoster.NoChanges' });
    return <InlineInformation type="information" title={title} />;
  }
  return (
    <NoticeBoard
      type="caution"
      title={<TranslatedText i18nKey="RosterManager.SyncRosterPopup.UpdatedCourseRoster.NoticeTitle" />}
    >
      <ul className="sync-rostal-modal__student-count">
        {hasAddedStudents && (
          <li className="sync-rostal-modal__student-count-item">
            <TranslatedText
              i18nKey="RosterManager.SyncRosterPopup.UpdatedCourseRoster.AddStudents"
              values={{ count: studentsAdded.length }}
            />
          </li>
        )}
        {hasRemovedStudents && (
          <li className="sync-rostal-modal__student-count-item">
            <TranslatedText
              i18nKey="RosterManager.SyncRosterPopup.UpdatedCourseRoster.RemoveStudents"
              values={{ count: studentsRemoved.length }}
            />
          </li>
        )}
      </ul>
      <EmailList added={studentsAdded} removed={studentsRemoved} />
      <ul className="sync-rostal-modal__student-count">
        {hasAddedCollaborators && (
          <li className="sync-rostal-modal__student-count-item">
            <TranslatedText
              i18nKey="RosterManager.SyncRosterPopup.UpdatedCourseRoster.AddCollaborators"
              values={{ count: collaboratorsAdded.length }}
            />
          </li>
        )}
        {hasRemovedCollaborators && (
          <li className="sync-rostal-modal__student-count-item">
            <TranslatedText
              i18nKey="RosterManager.SyncRosterPopup.UpdatedCourseRoster.RemoveCollaborators"
              values={{ count: collaboratorsRemoved.length }}
            />
          </li>
        )}
      </ul>
      <EmailList added={collaboratorsAdded} removed={collaboratorsRemoved} />
    </NoticeBoard>
  );
};

export default UpdatedCourseRoster;
