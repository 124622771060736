import type { Course } from '@kritik/types.generated';
import lmsServices from 'services/lms';
import { createRestApiClient } from 'utils/createRestApiClient';

const apiEndpoint = import.meta.env.VITE_API_URL;

export default (options = {}) => {
  const client = (options as any).client || createRestApiClient().withConfig({ baseURL: apiEndpoint });
  return {
    get: ({ id }: any): Promise<DataResponse<Course>> => {
      return client.request({
        method: 'GET',
        url: `/courses/${id}`,
      });
    },
    getFromEnrollLink: ({ courseId, joinCode }: any): Promise<DataResponse<$id.enroll.$joinCode.GET.Response>> => {
      return client.request({
        method: 'GET',
        url: `/${courseId}/enroll/${joinCode}`,
      });
    },
    getStudents: ({ courseId }: any): Promise<DataResponse<courses.$id.students.GET.Response>> => {
      return client.request({
        method: 'GET',
        url: `/courses/${courseId}/roster`,
      });
    },
    getActiveStudents: ({ courseId }: any): Promise<DataResponse<courses.$id.active_students.GET.Response>> => {
      return client.request({
        method: 'GET',
        url: `/courses/${courseId}/active-students`,
      });
    },
    delete: ({ id }: any): Promise<DataResponse<courses.$id.DELETE.Response>> => {
      return client.request({
        method: 'DELETE',
        url: `/courses/${id}`,
      });
    },
    list: (params = {}): Promise<DataResponse<courses.GET.Response>> => {
      return client.request({
        method: 'GET',
        url: `/courses`,
        params,
      });
    },
    update: ({ id, data }: any): Promise<DataResponse<courses.$id.PATCH.Response>> => {
      return client.request({
        method: 'PATCH',
        url: `/courses/${id}`,
        data,
      });
    },
    create: (data: courses.POST.Request): Promise<DataResponse<courses.POST.Response>> => {
      return client.request({
        method: 'POST',
        url: `/courses`,
        data,
      });
    },
    createDuplicate: ({ courseId, data }: any): Promise<DataResponse<courses.$id.duplicate.POST.Response>> => {
      return client.request({
        method: 'POST',
        url: `/courses/${courseId}/duplicate`,
        data,
      });
    },
    archive: ({ courseId }: any): Promise<DataResponse<courses.$id.archive.PATCH.Response>> => {
      return client.request({
        method: 'PATCH',
        url: `/courses/${courseId}/archive`,
        data: { isCompleted: true },
      });
    },
    unArchive: ({ courseId }: any): Promise<DataResponse<courses.$id.archive.PATCH.Response>> => {
      return client.request({
        method: 'PATCH',
        url: `/courses/${courseId}/archive`,
        data: { isCompleted: false },
      });
    },
    removeStudent: (data: any): Promise<DataResponse<courses.$id.$id.DELETE.Response>> => {
      return client.request({
        method: 'DELETE',
        url: `/courses/${data.courseId}/${data.user._id}`,
        data,
      });
    },
    reEnrollStudentInCourse: ({ studentId, courseId }: { studentId: string; courseId: string }) => {
      return client.request({
        method: 'PUT',
        url: `/courses/${courseId}/re-enroll-student`,
        data: {
          studentId,
          courseId,
        },
      });
    },
    inviteStudents: (data: any): Promise<DataResponse<courses.$id.invite.PUT.Response>> => {
      return client.request({
        method: 'PUT',
        url: `/courses/${data.courseId}/invite`,
        data,
      });
    },
    removeStudentInvite: (data: any): Promise<DataResponse<courses.$id.remove_student_invite.PUT.Response>> => {
      return client.request({
        method: 'PUT',
        url: `/courses/${data.courseId}/remove-student-invite`,
        data,
      });
    },
    inviteCollaborators: (data: any): Promise<DataResponse<EmptyResponse>> => {
      return client.request({
        method: 'PUT',
        url: `/courses/${data.courseId}/invite-collaborators`,
        data,
      });
    },
    reinviteCollaborators: (data: any): Promise<DataResponse<EmptyResponse>> => {
      return client.request({
        method: 'PUT',
        url: `/courses/${data.courseId}/reinvite-collaborator`,
        data,
      });
    },
    addCollaborator: ({ courseId, data }: any): Promise<DataResponse<courses.$id.collaborator.PUT.Response>> => {
      return client.request({
        method: 'PUT',
        url: `/courses/${courseId}/collaborator`,
        data,
      });
    },
    removeCollaborator: (data: any): Promise<DataResponse<courses.$id.collaborator.$email.DELETE.Response>> => {
      return client.request({
        method: 'DELETE',
        url: `/courses/${data.courseId}/collaborator/${data.collaboratorEmail}`,
        data,
      });
    },
    getGroups: ({ courseId }: any): Promise<DataResponse<courses.$id.groups.GET.Response>> => {
      return client.request({
        method: 'GET',
        url: `/courses/${courseId}/groups`,
      });
    },
    exportCourseCreations: (courseId: any) => {
      return client.request({
        method: 'GET',
        url: `/courses/${courseId}/overview/creations`,
      });
    },
    getStudentsByCourse: ({ courseId }: any): Promise<DataResponse<students.course.$id.GET.Response>> => {
      return client.request({
        method: 'GET',
        url: `/students/course/${courseId}`,
      });
    },
    getSpotlights: ({ courseId }: any): Promise<DataResponse<spotlights.GET.Response>> => {
      return client.request({
        method: 'GET',
        url: `/courses/${courseId}/spotlights`,
      });
    },
    getCourseCollaborators: ({ courseId }: any) => {
      return client.request({
        method: 'GET',
        url: `/courses/${courseId}/collaborators`,
      });
    },
    attachFileId: ({
      courseId,
      fileId,
    }: {
      courseId: string;
      fileId: string;
    }): Promise<DataResponse<EmptyResponse>> => {
      return client.request({
        method: 'POST',
        url: `/courses/${courseId}/add-file-id`,
        data: {
          fileId,
        },
      });
    },
    listActionItems: ({ courseId, query }) => {
      return client.request({
        method: 'GET',
        url: `/courses/${courseId}/action-items${query}`,
      });
    },
  };
};

export async function getCourseFirstSuccessfulSync({ course }: any) {
  try {
    const response = await lmsServices().getCourseFirstSuccessfulSync({ courseId: course._id });
    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    return { hasSuccessfulSync: false };
  }
}
