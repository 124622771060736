import { EvaluationEdit } from 'components/Creation/Evaluate';
import StarRubricMarks from 'components/General/StarRubricMarks';
import { STAR_RUBRIC_MARKS } from 'components/General/constant';
import Button from 'components/buttons/Button';
import { DisplayBox, DisplayBoxDivider } from 'components/layout';
import React from 'react';
import { connect } from 'react-redux';

const mapStateToProps = (state: any) => {
  return {};
};

type CalibrationGradeViewState = any;
type CalibrationGradeViewProps = any;

class CalibrationGradeView extends React.Component<CalibrationGradeViewProps, CalibrationGradeViewState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      editingGrade: false,
      newGrade: [],
    };
  }

  componentDidMount() {
    this.setInitialState();
  }

  componentDidUpdate(prevProps: {}, prevState: CalibrationGradeViewState) {
    if ((this.props as any).creation._id !== (prevProps as any).creation._id) {
      this.setInitialState();
    }
  }

  setInitialState() {
    this.setState({
      editingGrade: false,
      newGrade: (this.props as any).marks,
    });
    this.updateCreationGrades((this.props as any).marks);
  }

  handleGradeSubmit(scoreArray: any) {
    const marks = scoreArray.marks.map((score: any) => {
      return score;
    });
    this.setState({
      editingGrade: false,
      newGrade: marks,
      instructorCreationScoreExplanation: scoreArray.instructorCreationScoreExplanation,
    });
    this.updateCreationGrades(marks);
    return (this.props as any).onGradeSelect(scoreArray);
  }

  updateCreationGrades(marks: any) {
    const _creation = { ...(this.props as any).creation };
    _creation.teacherScore = marks;
    this.setState({ creation: _creation });
  }

  closeScoreEditor() {
    return this.setState({ editingGrade: false });
  }

  renderEvaluationEditor() {
    if (this.isEditingGrade()) {
      let onCancelScoreEdit = this.closeScoreEditor.bind(this);
      if (!this.creationHasGrade()) {
        onCancelScoreEdit = null;
      }
      return (
        <EvaluationEdit
          assignment={(this.props as any).assignment}
          submission={this.state.creation}
          rubric={(this.props as any).rubric}
          isInstructorGradingCalibrationCreation
          gradeOnly
          calibrationGradeSubmit={(score: any) => {
            return this.handleGradeSubmit(score);
          }}
          onCancel={onCancelScoreEdit}
          title="Edit Score"
        />
      );
    }
    return (
      <StarRubricMarks
        type="primary"
        rubric={(this.props as any).rubric}
        marks={this.state.newGrade}
        tableType={STAR_RUBRIC_MARKS.DEFAULT}
      />
    );
  }

  creationHasGrade() {
    return this.state.newGrade.length !== 0;
  }

  isEditingGrade() {
    return this.state.editingGrade || !this.creationHasGrade();
  }

  render() {
    return (
      <div>
        <DisplayBox style={{ marginBottom: 0 }}>
          <h3>Calibrate Score</h3>
          <p>Provide a score you feel the Creation should have received.</p>
          <DisplayBoxDivider />
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
            <h3>Breakdown</h3>
            <div>
              <Button style={{ marginRight: '10px' }} type="secondary" onClick={(this.props as any).showRubric}>
                View Rubric
              </Button>
              <Button
                style={{ marginRight: '10px' }}
                type="secondary"
                onClick={() => {
                  return this.setState({ editingGrade: true });
                }}
                disabled={this.isEditingGrade()}
                unavailable={this.isEditingGrade()}
              >
                Edit Score
              </Button>
            </div>
          </div>
          {this.renderEvaluationEditor()}
        </DisplayBox>
      </div>
    );
  }
}

export default connect(mapStateToProps, {})(CalibrationGradeView);
