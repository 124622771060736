import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useEffect, useState } from 'react';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      backgroundColor: 'transparent',

      '& .MuiIconButton-root': {
        padding: '0px',
      },
    },
    accordion: {
      backgroundColor: 'transparent',
    },

    details: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: 'transparent',
    },

    icon: {
      transition: 'transform 0.3s ease',
    },
  })
);

type MaterialCollapseProps = {
  title: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  testid?: string;
  isOpen?: boolean;
  elementKey: string;
};

export default function MaterialCollapse({
  title,
  children,
  className,
  testid,
  isOpen = false,
  elementKey,
}: MaterialCollapseProps) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setExpanded(true);
    }
  }, [isOpen]);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  return (
    <div className={`material-collapsible ${classes.root} ${className ? className : ''}`}>
      <Accordion
        key={elementKey}
        className={`${classes.accordion}  ${expanded ? '' : 'expanded-close'}`}
        expanded={expanded}
        onChange={handleToggle}
        elevation={0}
      >
        <AccordionSummary
          aria-expanded={expanded}
          data-testid={testid}
          expandIcon={<ExpandMoreIcon className={`expand-icon icon-closed ${expanded ? 'icon-open' : ''}`} />}
          aria-controls="panel-content"
          id="panel-header"
          className="accordion-summary accordion-title"
        >
          {title}
        </AccordionSummary>
        <AccordionDetails className={classes.details}>{children}</AccordionDetails>
      </Accordion>
    </div>
  );
}
