import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import ActivityActionItemsService from 'services/activityActionItems';
import { creationService } from 'services/index';

type UseFetchActivityActionItemsParams = {
  courseId: string;
  activityId: string;
};

export function useFetchActivityActionItems({ courseId, activityId }: UseFetchActivityActionItemsParams) {
  return useQuery(['activityActionItems', activityId], async () => {
    const result = await ActivityActionItemsService().getCount({ activityId, courseId });

    return result.data;
  });
}

export function useGetCreationFlags({ creationId }: { creationId: string }) {
  return useQuery(
    ['creationFlags', creationId],
    async () => {
      const response = await creationService().getCreationFlags({ creationId });
      return response.data;
    },
    { enabled: Boolean(creationId) }
  );
}

type FlagCreationParams = {
  creationId: string;
} & creations.$id.flag.POST.Request;
export function useFlagCreation() {
  const queryClient = useQueryClient();
  return useMutation(
    async (data: FlagCreationParams) => {
      const result = await creationService().flagCreation(data);
      return result.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['getCreationsToEvaluate']);
      },
    }
  );
}

type UseResolveActivityActionItemParams = {
  activityActionItemId: string;
} & action_items.$id.resolve.POST.Request;

export function useResolveActivityActionItem() {
  const queryClient = useQueryClient();

  return useMutation(
    async (data: UseResolveActivityActionItemParams) => {
      const result = await ActivityActionItemsService().resolve(data);
      return result.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['activityActionItems']);
        queryClient.invalidateQueries(['creationFlags']);
      },
    }
  );
}

type UseUnresolveActivityActionItemParams = {
  activityActionItemId: string;
};

export function useUnresolveActivityActionItem() {
  const queryClient = useQueryClient();

  return useMutation(
    async (data: UseUnresolveActivityActionItemParams) => {
      const result = await ActivityActionItemsService().unresolve(data);
      return result.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['activityActionItems']);
        queryClient.invalidateQueries(['creationFlags']);
      },
    }
  );
}
