import * as FormatUtils from '@kritik/utils/format';
import { localize } from 'locales';

const CreationTimeStamp = ({ createdAt }: any) => (
  <span>
    {localize({
      message: 'CreationTimeStamp.Submitted',
      options: { date: FormatUtils.exactDate(createdAt) },
    })}
  </span>
);

export default CreationTimeStamp;
