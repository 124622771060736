import FormField from 'components/core/form/Field';
import FieldInfo from 'components/core/form/FieldInfo';
import FormFieldInput from 'components/core/form/FieldInput';
import FormFieldLabel from 'components/core/form/FieldLabel';
import RichTextEditorInput from 'components/core/input/RichTextEditor';

const RichTextEditor = (props: any) => {
  return (
    <FormField error={props.error} className={props.className}>
      {props.label && <FormFieldLabel isRequired={props.isRequired} label={props.label} id={props.id} />}
      <FieldInfo label={props.info} />
      <FormFieldInput error={props.error}>
        <RichTextEditorInput
          input={{
            value: props.value,
            onChange: props.onChange,
          }}
          testid={props.testid}
          id={props.label}
        />
      </FormFieldInput>
    </FormField>
  );
};

RichTextEditor.defaultProps = {
  autoFocus: false,
};

export default RichTextEditor;
