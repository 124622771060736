import { Course } from '@kritik/types.generated';
import Grid from '@material-ui/core/Grid';
import { UserRole } from 'app-types';
import { TranslatedText } from 'components/TranslatedText';
import { useScreenSize } from 'hooks/useMatchMedia';
import { localize } from 'locales';

type ScoringOptionsProps = {
  course: Course & { userRole: UserRole };
};
const ScoringOptions = ({ course }: ScoringOptionsProps) => {
  const { markingScheme, defaultActivityWeight, startingScore } = course;
  const { isTablet } = useScreenSize();

  return (
    <div className="course-details__section-margin-top">
      <Grid container>
        <Grid item xs={isTablet ? 4 : 12} className="course-details__info-node">
          <p className="course-details__subtitle">
            <TranslatedText i18nKey="CourseDetails.ScoringOptions.Title.Type" />
          </p>
          <p>
            {markingScheme.isPercentage
              ? localize({ message: 'CourseDetails.ScoringOptions.Percentage' })
              : localize({ message: 'CourseDetails.ScoringOptions.Point' })}
          </p>
        </Grid>
        <Grid item xs={isTablet ? 4 : 12} className="course-details__info-node">
          <p className="course-details__subtitle">
            <TranslatedText i18nKey="CourseDetails.ScoringOptions.Title.DefaultWeight" />
          </p>
          <p data-testid="default-activity-weight">{defaultActivityWeight}</p>
        </Grid>
        {markingScheme.isPercentage && course.userRole === 'instructor' && (
          <Grid item xs={isTablet ? 4 : 12} className="course-details__info-node">
            <p className="course-details__subtitle">
              <TranslatedText i18nKey="CourseDetails.ScoringOptions.Title.DefaultStartingScore" />
            </p>
            <p data-testid="default-starting-score">{startingScore}%</p>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default ScoringOptions;
