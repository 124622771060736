import RubricOverview from 'components/RubricManager/Overview';
import SideMenu from 'components/RubricManager/SideMenu';
import TemplateEdit from 'components/RubricManager/TemplateEdit';
import SideMenuLayout from 'components/layout/WithSideMenu';
import React from 'react';
import { connect } from 'react-redux';
import { setIsEditingTemplate, setSelectedRubricId } from 'redux/rubricManager/actions';
import { selectIsEditingTemplate } from 'redux/rubricManager/selectors';
import { selectRubrics } from 'selectors/rubric';

const RubricViewContainer = (props: any) => {
  return (
    <React.Fragment>
      <SideMenuLayout
        menu={<SideMenu />}
        content={<RubricOverview backAction={props.goBackAction} coreAction={props.coreAction} />}
      />
      <TemplateEdit isOpen={props.isEditingTemplate} onClose={() => props.setIsEditingTemplate(false)} />
    </React.Fragment>
  );
};

const mapStateToProps = (state: any) => {
  return {
    rubricList: selectRubrics(state),
    isEditingTemplate: selectIsEditingTemplate(state),
  };
};

RubricViewContainer.defaultProps = {
  coreAction: null,
};

export default connect(mapStateToProps, { setSelectedRubricId, setIsEditingTemplate })(RubricViewContainer);
