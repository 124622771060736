import { searchUserList } from '@kritik/utils/filter';
import { Tooltip } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { openConfirmModal } from 'actions/modals';
import { InviteCallout } from 'components/RosterManager/Banners';
import Menu from 'components/RosterManager/Pending/Menu';
import EmptyStudentListPlaceholder from 'components/RosterManager/Placeholders';
import { TranslatedText } from 'components/TranslatedText';
import Button from 'components/buttons/Button';
import Search from 'components/core/input/Search';
import Table from 'components/layout/SimpleTable';
import TableBody from 'components/layout/SimpleTable/Body';
import TableCell from 'components/layout/SimpleTable/Cell';
import TableRow from 'components/layout/SimpleTable/Row';
import { inviteStudentsMutation, useFetchCourse } from 'hooks/course';
import { localize } from 'locales';
import moment from 'moment';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { isCourseConnected } from 'utils/lms';
import { trackEvent } from 'utils/userEvents';
import Calendar from '../../../images/icons/fa/Calendar';

const PendingList = (props: any) => {
  const { usingGradeSync, hasFirstSuccessfulSync } = props;
  const [searchParam, setSearchParam] = useState('');
  const { courseId } = useParams();
  const { data: course } = useFetchCourse(courseId);
  const inviteStudents = inviteStudentsMutation(courseId);
  const [emailSuccess, setEmailSuccess] = useState(null);

  // TODO - next line contains any[] type that should be fixed due to the searchUserList function requiring a User or Student Object and pendingInviteList not getting a full User or Student object.
  const pendingInviteList: any[] = course ? course.approvedStudents : [];
  const hasPendingStudents = !!pendingInviteList.length;

  if (course?.students.length === 0 && !hasPendingStudents) {
    return (
      <EmptyStudentListPlaceholder
        usingGradeSync={usingGradeSync}
        course={course}
        hasFirstSuccessfulSync={hasFirstSuccessfulSync}
      />
    );
  }

  const isSendSuccess = (actionId: string) => {
    return emailSuccess == actionId || emailSuccess === 'noEmailer';
  };

  const reInviteStudents = (emails: string[]) => {
    inviteStudents.mutate(
      {
        courseId,
        emails: emails,
        reinvite: true,
      },
      {
        onSuccess: () => {
          trackEvent('Students Invited', props.user.authUser, {
            courseId,
            courseName: course.title,
          });

          setTimeout(() => {
            inviteStudents.reset();
            setEmailSuccess(null);
          }, 2000);
        },
      }
    );
  };

  const renderDateTooltip = (date: any) => {
    return (
      <div className="last-date-tooltip">
        <Calendar />
        <p>{new Date(date).toDateString()}</p>
      </div>
    );
  };
  const handleAllStudentReInvite = () => {
    const emails = course.approvedStudents.map((student: any) => {
      return student.email;
    });
    const payload = {
      title: localize({ message: 'RosterManager.Pending.ReInviteAllModal.Title' }),
      confirmButton: localize({ message: 'RosterManager.Pending.ReInviteAllModal.ConfirmButton' }),
      cancelButton: localize({ message: 'Cancel' }),
      description: localize({ message: 'RosterManager.Pending.ReInviteAllModal.Description' }),
      onConfirm: () => {
        setEmailSuccess(`re-invite-all`);
        reInviteStudents(emails);
      },
    };
    props.openConfirmModal(payload);
  };

  if (hasPendingStudents) {
    const filteredStudents = searchUserList(pendingInviteList, searchParam);
    const numLabel =
      filteredStudents.length === 1 ? (
        <p data-testid="pending-invites">
          <TranslatedText
            i18nKey="RosterManager.Pending.PendingInvites.One"
            values={{ count: filteredStudents.length }}
          />
        </p>
      ) : (
        <p data-testid="pending-invites">
          <TranslatedText
            i18nKey="RosterManager.Pending.PendingInvites.Other"
            values={{ count: filteredStudents.length }}
          />
        </p>
      );

    return (
      <React.Fragment>
        <Grid container style={{ marginTop: 8, marginBottom: 16 }}>
          <Grid item sm={3} lg={3}>
            <Search
              onSearch={setSearchParam}
              placeholder={localize({ message: 'RosterManager.Pending.Search.Placeholder' })}
              label={localize({ message: 'RosterManager.Pending.Search.Label' })}
            />
          </Grid>
          <Grid item style={{ marginLeft: 'auto' }}>
            {numLabel}
          </Grid>
        </Grid>
        <Table renderSeparator={false}>
          <TableBody>
            <TableRow className="pending-student-list__header">
              <TableCell className="title">
                <h3>
                  <TranslatedText i18nKey="RosterManager.Pending.Table.Header.InvitedStudents" />
                </h3>
              </TableCell>
              <TableCell width="160px">
                <b>
                  <TranslatedText i18nKey="RosterManager.Pending.Table.Header.LastInviteSent" />
                </b>
              </TableCell>
              <TableCell width="125px" align="right" colSpan={2} className="invite-all-button">
                <Button
                  id="re-invite-all"
                  type="secondary"
                  onClick={handleAllStudentReInvite}
                  disabled={emailSuccess == 're-invite-all' && inviteStudents.isLoading}
                  success={isSendSuccess('re-invite-all') && !inviteStudents.isLoading}
                >
                  <TranslatedText i18nKey="RosterManager.Pending.Table.Button.ReInviteAll" />
                </Button>
              </TableCell>
            </TableRow>
            {filteredStudents.map((item: any) => {
              return (
                <TableRow testid={`pending-students-row-${item.email}`} key={item.email}>
                  <TableCell>{item.email}</TableCell>
                  <TableCell width="160px">
                    <Tooltip title={renderDateTooltip(item.lastInvitedAt)} placement="bottom-start">
                      <p>
                        {item.lastInvitedAt
                          ? localize({
                              message: 'RosterManager.Pending.Table.LastInvited',
                              options: { lastInvitedAt: moment(item.lastInvitedAt).fromNow() },
                            })
                          : null}
                      </p>
                    </Tooltip>
                  </TableCell>
                  <TableCell width="125px">
                    <Button
                      key={`invite-${item.email}`}
                      type="secondary"
                      onClick={() => {
                        setEmailSuccess(`invite-${item.email}`);
                        reInviteStudents([item.email]);
                      }}
                      disabled={inviteStudents.isSuccess === false && emailSuccess == `invite-${item.email}`}
                      success={isSendSuccess(`invite-${item.email}`) && !inviteStudents.isLoading}
                      className="pending-student-list__button"
                      testid="re-invite"
                    >
                      <TranslatedText i18nKey="RosterManager.Pending.Table.Button.ReInvite" />
                    </Button>
                  </TableCell>
                  {!isCourseConnected(course) && (
                    <TableCell width="50px">
                      <Menu courseId={course._id} studentEmail={item.email} />
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      {!isCourseConnected(course) && <InviteCallout />}
      <h3 className="header-3">
        <TranslatedText i18nKey="RosterManager.Pending.InviteStudents" />
      </h3>
      <div className="empty-pending">
        <TranslatedText i18nKey="RosterManager.Pending.NoPendingInvitations" />
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps, { openConfirmModal })(PendingList);
