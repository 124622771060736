import Typography from '@material-ui/core/Typography';
import PopoverTab from 'components/Navigation/PopoverTab';
import { TranslatedText } from 'components/TranslatedText';
import { localize } from 'locales';

const PopoverContainerStyle = {
  padding: '15px',
  width: '250px',
};
const PopoverListStyle = {
  margin: '0',
  paddingLeft: '25px',
};

const StudentsTab = (props: any) => {
  const content =
    props.enrolledStudents + props.pendingStudents + props.removedStudents === 0 ? (
      <Typography style={PopoverContainerStyle}>
        <TranslatedText i18nKey="Navigation.Tabs.Students.Tip.Empty" />
      </Typography>
    ) : (
      <Typography style={PopoverContainerStyle} component="div">
        <p>
          <TranslatedText i18nKey="Navigation.Tabs.Students.Tip.Populated" />
        </p>
        <ul style={PopoverListStyle}>
          {props.enrolledStudents > 0 && (
            <li>
              {props.enrolledStudents} <TranslatedText i18nKey="Navigation.Tabs.Students.Tip.Enrolled" />
            </li>
          )}
          {props.pendingStudents > 0 && (
            <li>
              {props.pendingStudents} <TranslatedText i18nKey="Navigation.Tabs.Students.Tip.Pending" />
            </li>
          )}
          {props.removedStudents > 0 && (
            <li>
              {props.removedStudents} <TranslatedText i18nKey="Navigation.Tabs.Students.Tip.Removed" />
            </li>
          )}
        </ul>
      </Typography>
    );

  return (
    <PopoverTab
      destination={props.destination}
      icon={<i className="fa fa-user-graduate" />}
      activeRegex={props.activeRegex}
      label={localize({ message: 'Navigation.Tabs.CourseRoster.Label' })}
      content={content}
      testid="students-tab"
    />
  );
};
export default StudentsTab;
