import useMatchMedia from 'hooks/useMatchMedia';
import React, { useState } from 'react';

interface CardProps {
  title: string;
  imageSrc: string;
  children: React.ReactNode;
}

const Card: React.FC<CardProps> = ({ title, imageSrc, children }) => {
  const [toggleCssClass, setToggleCssClass] = useState(false);
  const [isMobile] = useMatchMedia(['(max-width: 768px)']);

  return (
    <article
      className={`card ${toggleCssClass ? 'card--active' : ''}`}
      aria-labelledby="cardTitle"
      tabIndex={0}
      onMouseLeave={() => {
        if (!isMobile) {
          setToggleCssClass(false);
        }
      }}
      onMouseEnter={() => {
        if (!isMobile) {
          setToggleCssClass(true);
        }
      }}
      onClick={() => {
        if (isMobile) {
          setToggleCssClass(!toggleCssClass);
        }
      }}
    >
      <div className="card-image">
        <img src={imageSrc} alt={title} />
      </div>
      <div className="card-content">
        <div className="card-content__inner">
          <h2 id="cardTitle" className="card-title">
            {title}
          </h2>
          <div className="card-body">{children}</div>
        </div>
      </div>
    </article>
  );
};

export default Card;
