import { TranslatedText } from 'components/TranslatedText';
import Select from 'components/core/form/SelectField';
import NoticeBoard from 'components/layout/NoticeBoard';
import { localize } from 'locales';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  getActivitiesToImportFromCourse,
  getActivityImportCourses,
  resetActivityOptions,
  resetSelectedActivities,
} from 'redux/activityImport/actions';
import { selectActivityImportCourses } from 'redux/activityImport/select';
import { formatPartialCourseBreadcrumb } from 'utils/format';

const CourseSelector = (props: any) => {
  const [selectedCourse, setSelectedCourse] = useState(null);

  const setInitialState = () => {
    props.resetActivityOptions();
    props.resetSelectedActivities();
    props.getActivityImportCourses();
  };
  useEffect(() => {
    setInitialState();
  }, []);

  useEffect(() => {
    if (selectedCourse) {
      props.getActivitiesToImportFromCourse({ courseId: selectedCourse });
    }
  }, [selectedCourse]);

  const getCourseSelectionOptions = () => {
    const options: any = [];
    props.courseList.forEach((course: any, index: number) => {
      options.push({
        label: formatPartialCourseBreadcrumb(course),
        value: course._id,
        testid: `import-course-option-${index}`,
      });
    });
    return options;
  };

  const handleSelectCourse = (e: any) => {
    setSelectedCourse(e.target.value);
    props.resetActivityOptions();
    props.resetSelectedActivities();
  };

  const renderNoContentWarning = () => {
    if (props.courseList.length > 0) {
      return null;
    }
    return (
      <NoticeBoard type="information" title={localize({ message: 'CourseSelector.NoContent.Title' })}>
        <TranslatedText i18nKey="CourseSelector.NoContent.Description" />
      </NoticeBoard>
    );
  };

  return (
    <React.Fragment>
      <div className="course-selector">
        <Select
          label={localize({ message: 'CourseSelector.SelectCourse.Label' })}
          options={getCourseSelectionOptions()}
          value={selectedCourse}
          onChange={handleSelectCourse}
          disabled={props.courseList.length === 0}
          placeholder={localize({ message: 'CourseSelector.SelectCourse.Placeholder' })}
          testid="select-course-to-import"
        />
      </div>
      {renderNoContentWarning()}
    </React.Fragment>
  );
};

const mapStateToProps = (state: any) => {
  return {
    courseList: selectActivityImportCourses(state),
  };
};

const mapDispatchToProps = {
  getActivityImportCourses,
  getActivitiesToImportFromCourse,
  resetActivityOptions,
  resetSelectedActivities,
};

CourseSelector.defaultProps = {
  courseList: [],
};

export default connect(mapStateToProps, mapDispatchToProps)(CourseSelector);
