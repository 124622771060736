import NoticeBoard from 'components/layout/NoticeBoard';
import { CustomContentProps, SnackbarContent, VariantType, useSnackbar } from 'notistack';
import React from 'react';

declare module 'notistack' {
  interface VariantOverrides {
    // removes the `warning` variant
    default: false;
    // adds SnackbarOnlyProps to all variants for type-safety
    warning: SnackbarOnlyProps;
    error: SnackbarOnlyProps;
    info: SnackbarOnlyProps;
    success: SnackbarOnlyProps;
  }
}
const NOTICE_BOARD_VARIANT_MAPPING: Record<VariantType, string> = {
  warning: 'caution',
  error: 'danger',
  info: 'information',
  success: 'success',
};
type SnackbarOnlyProps = {
  title: string | React.ReactNode;
  testid?: string;
};
type SnackbarProps = SnackbarOnlyProps & CustomContentProps;

export const Snackbar = React.forwardRef<HTMLDivElement, SnackbarProps>((props, ref) => {
  const { closeSnackbar } = useSnackbar();
  const { id, title, message, className, style, testid } = props;

  return (
    <SnackbarContent ref={ref} role="alert" className={className} style={style} data-testid={testid} aria-live="polite">
      <NoticeBoard
        type={NOTICE_BOARD_VARIANT_MAPPING[props.variant]}
        title={title}
        closeable
        onClose={() => closeSnackbar(id)}
      >
        {message}
      </NoticeBoard>
    </SnackbarContent>
  );
});
