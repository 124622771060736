import { ALL_STUDENTS, EXCLUDE_CREATORS, SPECIFIC_GROUPS, SPECIFIC_STUDENTS } from '@kritik/constants/activity';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import { localize } from 'locales';
import { Field, useForm } from 'react-final-form';

const EvaluatorsField = () => {
  const form = useForm();
  const { creatorGroups, allGroupsSelected } = form.getState().values;

  const hasPickedOnlyOneGroup = creatorGroups?.length === 1;

  const options = [
    {
      label: localize({
        message: 'Activity.EditStudentParticipation.Group.EvaluatorField.Option.ExcludeCreators',
      }),
      value: EXCLUDE_CREATORS,
      disabled: allGroupsSelected,
    },
    {
      label: localize({
        message: 'Activity.EditStudentParticipation.Group.EvaluatorField.Option.All',
      }),
      value: ALL_STUDENTS,
      disabled: hasPickedOnlyOneGroup,
    },
    {
      label: localize({
        message: 'Activity.EditStudentParticipation.Individual.StudentEvaluatorsField.Option.SpecificStudents',
      }),
      value: SPECIFIC_STUDENTS,
    },
    {
      label: localize({
        message: 'Activity.EditStudentParticipation.Group.StudentEvaluatorsField.Option.SpecificGroups',
      }),
      value: SPECIFIC_GROUPS,
    },
  ];

  return (
    <>
      <div className="evaluators-select-title">
        {localize({ message: 'Activity.EditStudentParticipation.Group.EvaluatorField.Title' })}
      </div>
      <div className="evaluators-select-container">
        {options.map((option) => {
          return (
            <Field name="evaluatorType" type="radio" value={option.value} key={option.value}>
              {({ input }) => {
                return (
                  <div className="evaluators-select-container">
                    <FormControlLabel
                      className="kritik-form__radio-control"
                      disabled={option.disabled}
                      name={input.name}
                      label={option.label}
                      value={option.value}
                      checked={input.checked}
                      onChange={input.onChange}
                      control={<Radio disableRipple className="kritik-form__radio" />}
                    />
                  </div>
                );
              }}
            </Field>
          );
        })}
      </div>
    </>
  );
};

export default EvaluatorsField;
