import * as FormatUtils from '@kritik/utils/format';
import HtmlContent from 'components/General/HtmlContent';
import { TranslatedText } from 'components/TranslatedText';
import Button from 'components/buttons/Button';
import ButtonContainer from 'components/buttons/ButtonContainer';
import Banner from 'components/core/Banner';
import { TextDisplay } from 'components/layout';
import FileList from 'components/layout/AttachmentManager/FileList';
import AvatarDisplay from 'components/layout/AvatarDisplay';
import { localize } from 'locales';
import { connect } from 'react-redux';
import { getUser } from 'selectors/user';

const EvalInstructorCommentView = (props: any) => {
  const renderInstructorOptions = () => {
    if (!props.isInstructor) {
      return null;
    }
    return (
      <ButtonContainer>
        <Button
          type="secondary"
          onClick={props.onEdit}
          data-testid="edit-evaluation-comment"
          label={localize({ message: 'Button.Label.EditInstructorComment' })}
        >
          <TranslatedText i18nKey="Button.Label.EditComment" />
        </Button>
      </ButtonContainer>
    );
  };

  const instructorUserId = props.instructorComment.user;

  return (
    <Banner
      image={
        <AvatarDisplay
          user={instructorUserId}
          myUser={instructorUserId}
          showName={false}
          showBorder={false}
          size="80px"
          anonymous={false}
        />
      }
      title={localize({
        message: 'InstructorComment.Title',
        options: { name: props?.instructorUser?.profile?.name || '' },
      })}
      status={`${FormatUtils.exactDate(props.instructorComment.submittedAt)} (${localize({ message: 'InstructorComment.Status' })})`}
      description={
        <TextDisplay>
          <HtmlContent content={props.instructorComment.text} />
        </TextDisplay>
      }
    >
      <FileList files={props.instructorComment.files} />
      {renderInstructorOptions()}
    </Banner>
  );
};

EvalInstructorCommentView.defaultProps = {
  isInstructor: false,
};

const mapStateToProps = (state: any, ownProps: any) => {
  const instructorUserId = ownProps.instructorComment.user;
  const instructorUser = getUser(instructorUserId, state.entities);
  return {
    instructorUser,
  };
};

export default connect(mapStateToProps, {})(EvalInstructorCommentView);
