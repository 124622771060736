import ErrorPageTemplate from 'components/ErrorPage/ErrorPageTemplate';
import { localize } from 'locales';
import Page from 'pages/Page';
import { Component } from 'react';

type ErrorPageProps = {
  icon: string;
  title: string;
  buttonText: string;
  subtitle: string;
  descriptions: string[];
  iconVariant: 'red' | 'blue';
};
class ErrorPage extends Component<ErrorPageProps> {
  getMetaData() {
    return {
      title: this.pageTitle(),
      meta: this.pageMeta(),
      link: this.pageLink(),
    };
  }

  pageTitle = () => {
    return localize({ message: 'Error.PageTitle' });
  };

  pageMeta = () => {
    return [];
  };

  pageLink = () => {
    return [];
  };

  render() {
    return (
      <Page {...this.getMetaData()}>
        <ErrorPageTemplate
          subtitle={this.props.subtitle}
          icon={this.props.icon}
          buttonText={this.props.buttonText}
          title={this.props.title}
          descriptions={this.props.descriptions}
          iconVariant={this.props.iconVariant}
        />
      </Page>
    );
  }
}

export default ErrorPage;
