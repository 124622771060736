import { combineReducers } from 'redux';

import * as types from 'types';

const submissions = (state = {}, action: any) => {
  switch (action.type) {
    case types.GET_ASSIGNMENT_SUBMISSIONS_REQUEST:
      if (action.payload && action.payload.params) {
        if (![action.payload.params.assignmentId]) {
          return {
            ...state,
            [action.payload.params.assignmentId]: { items: [] },
          };
        }
      }
      return state;
    case types.GET_ASSIGNMENT_SUBMISSIONS_SUCCESS:
      if (action.payload && action.payload.params) {
        return {
          ...state,
          [action.payload.params.assignmentId]: { items: action.payload.items },
        };
      }
      return state;
    case types.CREATE_SUBMISSION_EVALUATION_SUCCESS:
      if (action.payload && action.payload.params) {
        return {
          ...state,
          [action.payload.params.assignmentId]: { items: action.payload.items },
        };
      }
      return state;
    default:
      return state;
  }
};

const initialStatState = {};

const stats = (state = initialStatState, action: any) => {
  switch (action.type) {
    case types.GET_ACTIVITY_STATS:
      return initialStatState;
    case types.GET_ACTIVITY_STATS_SUCCESS:
      return {
        ...action.payload,
      };
    default:
      return state;
  }
};

const assignmentReducer = combineReducers({
  submissions,
  stats,
});

export default assignmentReducer;
