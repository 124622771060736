import { Card, CardActions, CardContent } from '@material-ui/core';
import { resolveDispute } from 'actions/activity';
import { TranslatedText } from 'components/TranslatedText';
import { Button } from 'components/buttons';
import { InlineInformation, TextDisplay } from 'components/layout';
import ConfirmModal from 'components/modals/ConfirmModal';
import { localize } from 'locales';
import { useState } from 'react';
import { connect } from 'react-redux';

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
  };
};

const CreationDispute = ({ creation, handleIsResolvingDispute, user, resolveDispute }: any) => {
  const [instructorComment, setInstructorComment] = useState('');
  const [error, setError] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(null);
  const [isSubmittingDisputeResolution, setIsSubmittingDisputeResolution] = useState(false);

  const resetDisputeEditor = () => {
    setShowConfirmModal(false);
    setInstructorComment('');
    setError(null);
    setIsSubmittingDisputeResolution(false);
    handleIsResolvingDispute();
  };

  const handleResolve = async () => {
    if (!isSubmittingDisputeResolution) {
      setIsSubmittingDisputeResolution(true);
      const disputeData = {
        submissionId: creation._id,
        assignmentId: creation.assignment,
        dispute: {
          status: 'Resolved',
          resolvedOn: new Date(Date.now()),
          resolvedBy: user.authUser.id,
          teacherComment: instructorComment,
        },
      };
      try {
        await resolveDispute(disputeData);
        resetDisputeEditor();
      } catch (err) {
        setError(err);
        setShowConfirmModal(false);
        setIsSubmittingDisputeResolution(false);
      }
    }
  };
  return (
    <Card>
      <ConfirmModal
        isOpen={showConfirmModal}
        onCancel={() => {
          setShowConfirmModal(false);
        }}
        onConfirm={async () => {
          await handleResolve();
        }}
        cancelButton={localize({ message: 'Common.No' })}
        confirmButton={localize({ message: 'Common.Yes' })}
        title={localize({ message: 'CreationDispute.ConfirmModal.Title' })}
        description={localize({ message: 'CreationDispute.ConfirmModal.Description' })}
        testid="confirm-resolve-dispute-modal"
      />
      <CardContent className="resolve-dispute-container">
        <h3 className="header-3">
          <TranslatedText i18nKey="CreationDispute.ManageDispute" />
        </h3>
        <p>
          <b>{creation.user.profile.name}</b> <TranslatedText i18nKey="CreationDispute.DisputedGrade" />
        </p>
        <p>
          <TranslatedText i18nKey="CreationDispute.EditGrade" />
        </p>
        <p>
          <b>
            <TranslatedText i18nKey="CreationDispute.ReasonForDispute" />
          </b>{' '}
          <TextDisplay content={creation.dispute.reason} />
        </p>
        <textarea
          id="prof-dispute-comment"
          rows={5}
          name="instructorComment"
          placeholder={localize({ message: 'CreationDispute.Placeholder' })}
          onChange={(e) => {
            setInstructorComment(e.target.value);
          }}
          value={instructorComment}
          data-testid="instructor-dispute-comment"
        />
        {error && <InlineInformation type="danger">{error}</InlineInformation>}
      </CardContent>
      <CardActions className="resolve-button-container">
        <Button
          type="primary"
          onClick={() => {
            setShowConfirmModal(true);
          }}
          testid="resolve-dispute"
        >
          <TranslatedText i18nKey="CreationDispute.ResolveDisputeButton" />
        </Button>
        <Button
          type="secondary"
          onClick={() => {
            setShowConfirmModal(false);
            handleIsResolvingDispute();
          }}
        >
          <TranslatedText i18nKey="Cancel" />
        </Button>
      </CardActions>
    </Card>
  );
};

export default connect(mapStateToProps, {
  resolveDispute,
})(CreationDispute);
