import Button from 'components/buttons/Button';

export const DefaultRadio = (props: any) => {
  const isDisabled = (option: any) => {
    return option.isDisabled === true || props.isDisabled;
  };

  const isUnavailable = (option: any) => {
    return option.isDisabled === true || (props.isDisabled && option.value !== props.value);
  };

  const renderOptions = () => {
    return props.options.map((option: any) => {
      return (
        <Button
          key={option.value}
          value={option.value}
          onClick={props.onChange}
          type="secondary"
          isActive={option.value === props.value}
          disabled={isDisabled(option)}
          unavailable={isUnavailable(option)}
          data-testid={option.testid}
          aria-pressed={option['aria-pressed']}
        >
          {option.label}
        </Button>
      );
    });
  };

  return <div className="kritik-form-field__radio">{renderOptions()}</div>;
};

DefaultRadio.defaultProps = {
  isDisabled: false,
  options: [],
};

export default DefaultRadio;
