import ActivityMenu from 'components/Assignment/Menu';
import PropagationInterceptor from 'components/Utility/PropagationInterceptor';
import { useUserRoleInCourse } from 'hooks/course';

type MenuCellProps = {
  activity: any;
  testid?: string;
};

const MenuCell = (props: MenuCellProps) => {
  const { isInstructorInCourse } = useUserRoleInCourse();
  if (!isInstructorInCourse) {
    return null;
  }
  return (
    <PropagationInterceptor>
      <ActivityMenu activity={props.activity} testid={props.testid} />
    </PropagationInterceptor>
  );
};

export default MenuCell;
