import classnames from 'classnames';
import Button from 'components/buttons/Button';
import TextInput from 'components/core/input/Text';
import { localize } from 'locales';
import { useEffect, useState } from 'react';

const Search = (props: any) => {
  const { onSearch, className, placeholder, onClearSearch } = props;
  const [searchParam, setSearchParam] = useState(props.initialValue || '');

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      onSearch?.(searchParam);
    }, 200);
    return () => {
      return clearTimeout(timeOutId);
    };
  }, [searchParam]);

  const onSearchChange = (e: any) => {
    setSearchParam(e.target.value);
  };

  const clearSearch = () => {
    setSearchParam('');
    if (onSearch) {
      onSearch('');
    }
    if (onClearSearch) {
      onClearSearch();
    }
  };

  const renderClearSearchIcon = () => {
    if (!searchParam) {
      return <i className="fas fa-search" />;
    }
    return (
      <Button
        type="hidden"
        label={localize({ message: 'Search.ClearSearch' })}
        className="kritik-search__clear-btn"
        onClick={() => {
          return clearSearch();
        }}
        data-testid="clear-search"
      >
        <i aria-hidden="true" className="fa fa-close" />
      </Button>
    );
  };

  return (
    <div className={classnames('kritik-search', className)} data-testid="kritik-search">
      <TextInput
        useDefaultStyles={false}
        className="kritik-search"
        inputProps={{
          className: 'kritik-search__input',
        }}
        name={props.name}
        placeholder={placeholder}
        value={searchParam}
        onChange={onSearchChange}
        icon={renderClearSearchIcon()}
        onEnterKey={props.onEnterKey}
        testid="kritik-search-input"
        id="kritik-search-input"
      />
    </div>
  );
};

Search.defaultProps = {
  onSearch: null,
  className: '',
  placeholder: '',
  onClearSearch: null,
};

export default Search;
