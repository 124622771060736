function CreateCard({ text, onClick }: any) {
  const handleOnClick = () => {
    onClick && onClick();
  };
  return (
    <div className="create-card" data-testid="create-card" onClick={handleOnClick}>
      <div className="course-create-card__label">
        <i className="fas fa-plus" /> {text}
      </div>
    </div>
  );
}

export default CreateCard;
