import { localize } from 'locales';

export const mimeTypes = {
  allowImages: [
    'image/bmp',
    'image/gif',
    'image/jpeg',
    'image/png',
    'image/webp',
    'image/svg+xml',
    'image/heic',
    'image/heif',
  ],
  allowVideo: [
    'video/x-msvideo',
    'video/mpeg',
    'video/webm',
    'video/mp4',
    'video/x-flv',
    'video/quicktime',
    'video/x-ms-wmv',
  ],
  allowAudio: ['audio/midi', 'audio/x-midi', 'audio/mpeg', 'audio/wav', 'audio/webm', 'audio/mp4'],
  allowPDF: ['application/pdf'],
  allowWord: ['application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
  allowExcel: [
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'text/csv',
  ],
  allowPowerpoint: [
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  ],
  allowText: ['text/plain'],
  allowZip: ['application/x-rar-compressed', 'application/zip'],
};

export const labels = {
  Images: localize({ message: 'Assignment.Constants.Labels.Images' }),
  Video: localize({ message: 'Assignment.Constants.Labels.Video' }),
  Audio: localize({ message: 'Assignment.Constants.Labels.Audio' }),
  PDF: localize({ message: 'Assignment.Constants.Labels.PDF' }),
  Word: localize({ message: 'Assignment.Constants.Labels.Word' }),
  Excel: localize({ message: 'Assignment.Constants.Labels.Excel' }),
  Powerpoint: localize({ message: 'Assignment.Constants.Labels.Powerpoint' }),
  Text: localize({ message: 'Assignment.Constants.Labels.Text' }),
  Zip: localize({ message: 'Assignment.Constants.Labels.Zip' }),
};
