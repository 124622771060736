import ABOVE_AND_BEYOND from 'images/spotlight-icons/badge-beyond.svg';
import CRITICAL from 'images/spotlight-icons/badge-critical.svg';
import GENUINE from 'images/spotlight-icons/badge-genuine.svg';
import INNOVATIVE from 'images/spotlight-icons/badge-innovative.svg';
import MOTIVATIONAL_AND_CRITICAL from 'images/spotlight-icons/badge-motivational-and-critical.svg';
import MOTIVATIONAL from 'images/spotlight-icons/badge-motivational.svg';
import SPOTLIGHT from 'images/spotlight-icons/badge-spotlight.svg';
import PATTERN_BEYOND from 'images/spotlight-icons/pattern-beyond.png';
import PATTERN_CRITICAL from 'images/spotlight-icons/pattern-critical.png';
import PATTERN_GENUINE from 'images/spotlight-icons/pattern-genuine.png';
import PATTERN_INNOVATIVE from 'images/spotlight-icons/pattern-innovative.png';
import PATTERN_MOTIVATIONAL from 'images/spotlight-icons/pattern-motivational.png';

import { tags } from '@kritik/constants/tags';

export function getBackgroundUrl(tag: any) {
  switch (tag) {
    case tags.ABOVE_AND_BEYOND:
      return `url(${PATTERN_BEYOND})`;
    case tags.INNOVATIVE:
      return `url(${PATTERN_INNOVATIVE})`;
    case tags.GENUINE:
      return `url(${PATTERN_GENUINE})`;
    case tags.MOTIVATIONAL_AND_CRITICAL:
      return `url(${PATTERN_MOTIVATIONAL})`;
    case tags.CRITICAL:
      return `url(${PATTERN_CRITICAL})`;
    case tags.MOTIVATIONAL:
      return `url(${PATTERN_MOTIVATIONAL})`;
    case tags.SPOTLIGHT:
      return `url(${PATTERN_BEYOND})`;
    default:
      return `url(${PATTERN_CRITICAL})`;
  }
}

export function getBadgeUrl(tag: any) {
  switch (tag) {
    case tags.ABOVE_AND_BEYOND:
      return ABOVE_AND_BEYOND;
    case tags.INNOVATIVE:
      return INNOVATIVE;
    case tags.GENUINE:
      return GENUINE;
    case tags.MOTIVATIONAL_AND_CRITICAL:
      return MOTIVATIONAL_AND_CRITICAL;
    case tags.MOTIVATIONAL:
      return MOTIVATIONAL;
    case tags.CRITICAL:
      return CRITICAL;
    case tags.SPOTLIGHT:
      return SPOTLIGHT;
    default:
      return MOTIVATIONAL;
  }
}
