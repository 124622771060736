import SpotlightBadge from 'components/Spotlight/Badge';
import { getBackgroundUrl } from 'utils/tags';

const CardHeader = (props: any) => {
  const renderCardIcon = () => {
    return (
      <div className="spotlight-card__badge">
        <SpotlightBadge tag={props.tag} />
      </div>
    );
  };
  return (
    <div
      data-testid="spotlight-card-header"
      className="spotlight-card__header"
      style={{ backgroundImage: getBackgroundUrl(props.tag.getName()) }}
    >
      {renderCardIcon()}
      <div className="spotlight-card__header-tag">{props.tag.getName()}</div>
    </div>
  );
};

export default CardHeader;
