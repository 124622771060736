import {
  isCommentFlagDismissed,
  isCommentFlagged,
  isCommentNotFlagged,
  isCommentRemoved,
} from '@kritik/utils/creation/status';
import { EvaluationScoreUtil } from '@kritik/utils/grade';
import NoticeBoard from 'components/layout/NoticeBoard';
import { localize } from 'locales';

export const FlagBanner = ({
  isInstructor,
  isEvaluationOwner,
  evaluation,
}: {
  isInstructor?: boolean;
  isEvaluationOwner?: boolean;
  evaluation: any;
}) => {
  if (isEvaluationOwner && isCommentFlagged(evaluation)) {
    return (
      <NoticeBoard
        type="caution"
        title={localize({
          message: 'Creation.Evaluation.CommentFlag.Banner.CommentFlagged.Title',
        })}
      >
        {isInstructor
          ? localize({
              message: 'Creation.Evaluation.CommentFlag.Banner.CommentFlagged.Content.Instructor',
            })
          : localize({
              message: 'Creation.Evaluation.CommentFlag.Banner.CommentFlagged.Content.Student',
            })}
      </NoticeBoard>
    );
  }
  if (isCommentRemoved(evaluation)) {
    return (
      <NoticeBoard
        type="danger"
        title={localize({
          message: 'Creation.Evaluation.CommentFlag.Banner.CommentFlaggedRemoved.Title',
        })}
      >
        {isInstructor
          ? localize({
              message: 'Creation.Evaluation.CommentFlag.Banner.CommentFlaggedRemoved.Content.Instructor',
            })
          : localize({
              message: 'Creation.Evaluation.CommentFlag.Banner.CommentFlaggedRemoved.Content.Student',
            })}
      </NoticeBoard>
    );
  }
  if (isCommentFlagDismissed(evaluation)) {
    return (
      <NoticeBoard
        type="success"
        title={localize({
          message: 'Creation.Evaluation.CommentFlag.Banner.CommentFlagDismissed.Title',
        })}
      >
        {isInstructor
          ? localize({
              message: 'Creation.Evaluation.CommentFlag.Banner.CommentFlagDismissed.Content.Instructor',
            })
          : localize({
              message: 'Creation.Evaluation.CommentFlag.Banner.CommentFlagDismissed.Content.Student',
            })}
      </NoticeBoard>
    );
  }

  if (EvaluationScoreUtil.isEvaluationRemoved(evaluation) && isCommentNotFlagged(evaluation)) {
    return (
      <NoticeBoard
        title={localize({ message: 'Creation.Evaluation.CommentFlag.Banner.CommentRemoved.Title' })}
        type="caution"
        data-testid="notice-evaluation-removed"
      >
        {isInstructor
          ? localize({
              message: 'Creation.Evaluation.CommentFlag.Banner.CommentRemoved.Content.Instructor',
            })
          : localize({
              message: 'Creation.Evaluation.CommentFlag.Banner.CommentRemoved.Content.Student',
            })}
      </NoticeBoard>
    );
  }

  return null;
};
