import { navigateToActivityPage } from 'actions/activity';
import ScheduleCell from 'components/ActivityList/Cells/Schedule';
import StatusCell from 'components/ActivityList/Cells/Status';
import TitleCell from 'components/ActivityList/Cells/Title';
import WeightCell from 'components/ActivityList/Cells/Weight';
import { TranslatedText } from 'components/TranslatedText';
import Table from 'components/layout/SimpleTable';
import TableBody from 'components/layout/SimpleTable/Body';
import TableCell from 'components/layout/SimpleTable/Cell';
import TableHead from 'components/layout/SimpleTable/Head';
import TableRow from 'components/layout/SimpleTable/Row';
import { I18nKey } from 'locales/index';
import { useState } from 'react';
import { connect } from 'react-redux';
import StudentSelectors from 'selectors/student';

type StudentActivityListProps = {
  activityList: any[];
  renderHeader?: boolean;
  tableCaptionI18nKey: I18nKey;
  navigateToActivityPage: (params: { courseId: string; assignmentId: string }) => void;
  activities: any;
  student: any;
};

const StudentActivityList = (props: StudentActivityListProps) => {
  const [hoveredActivityId, setHoveredActivityId] = useState(null);
  const redirectTo = (activity: any) => {
    props.navigateToActivityPage({ courseId: activity.course, assignmentId: activity._id });
  };

  const renderTableHeader = () => {
    if (props.renderHeader) {
      return (
        <TableHead>
          <tr>
            <TableCell color="light">
              <span>
                <TranslatedText i18nKey="StudentActivityList.Name" />
              </span>
            </TableCell>
            <TableCell color="light" align="right">
              <span style={{ marginRight: '18px' }}>
                <TranslatedText i18nKey="StudentActivityList.Weight" />
              </span>
            </TableCell>
            <TableCell color="light" align="left">
              <span>
                <TranslatedText i18nKey="StudentActivityList.Completed" />
              </span>
            </TableCell>
            <TableCell minWidth="220px">
              <span>
                <TranslatedText i18nKey="StudentActivityList.Issues" />
              </span>
            </TableCell>
          </tr>
        </TableHead>
      );
    }
  };

  return (
    <Table>
      <caption className="visually-hidden">
        <TranslatedText i18nKey={props.tableCaptionI18nKey} />
      </caption>
      {renderTableHeader()}
      <TableBody>
        {props.activityList.map((activity: any) => {
          const creation = props.activities[activity._id].userAssignment;
          return (
            <TableRow
              key={`unscheduled-activity-${activity._id}`}
              isSelectable
              onSelectRow={() => {
                redirectTo(activity);
              }}
              onMouseOver={() => {
                setHoveredActivityId(activity._id);
              }}
              onMouseLeave={() => {
                setHoveredActivityId(null);
              }}
            >
              <TitleCell activity={activity} />
              <TableCell align="right" whiteSpace="nowrap" color="light">
                <WeightCell activity={activity} />
              </TableCell>
              <TableCell whiteSpace="nowrap" align="left" color="light">
                <span>
                  <ScheduleCell isRowHovered={hoveredActivityId === activity._id} activity={activity} />
                </span>
              </TableCell>
              <TableCell>
                <StatusCell activity={activity} creation={creation} student={props.student} />
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

StudentActivityList.defaultProps = {
  renderHeader: true,
};

const mapStateToProps = (state: any) => {
  return {
    activities: state.entities.assignments,
    student: StudentSelectors.getStudentFromUser(state, state.selected.courseId),
  };
};

export default connect(mapStateToProps, { navigateToActivityPage })(StudentActivityList);
