import ElipsesMenu from 'components/General/ElipsesMenu';
import Confirm from 'components/modals/ConfirmModal';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { deleteSpotlight, listSpotlights, navigateToSpotlightEdit } from 'redux/spotlight';
import { Spotlight } from 'utils/spotlight';

const SpotlightCardMenu = (props: any) => {
  const { spotlight, course } = props;
  const [confirm, setConfirm] = useState(false);
  const _spotlight = Spotlight(spotlight);

  const removeSpotlight = () => {
    props.deleteSpotlight(_spotlight.getId());
    props.listSpotlights({ course: course._id || course });
  };

  const editSpotlight = () => {
    props.navigateToSpotlightEdit({
      courseId: course,
      activityId: _spotlight.activity._id,
      spotlightId: _spotlight.getId(),
    });
  };

  const handleDeleteSpotlight = () => {
    setConfirm(true);
  };

  const OPTIONS = [
    {
      label: 'Edit',
      action: editSpotlight,
    },
    {
      label: 'Remove',
      action: handleDeleteSpotlight,
    },
  ];

  return (
    <React.Fragment>
      <Confirm
        isOpen={confirm}
        onCancel={() => {
          setConfirm(false);
        }}
        onConfirm={() => {
          setConfirm(false);
          removeSpotlight();
        }}
        cancelButton="No"
        confirmButton="Yes"
        title="Remove this spotlight?"
        description="You will not be able to undo this action."
      />

      <ElipsesMenu options={OPTIONS} labelI18nKey="SpotlightCard.Menu.Label" />
    </React.Fragment>
  );
};

const mapStateToProps = (state: any) => {
  return {
    course: state.selected.courseId,
  };
};

export default connect(mapStateToProps, {
  deleteSpotlight,
  navigateToSpotlightEdit,
  listSpotlights,
})(SpotlightCardMenu);
