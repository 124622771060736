import { Rubric } from '@kritik/types.generated';
import { getWeightOfCriteria } from '@kritik/utils/rubric';

type SanitizeWeightedScoreArgs = {
  score: number;
  index: number;
  rubric: Rubric;
};
export const sanitizeWeightedScore = ({ score, index, rubric }: SanitizeWeightedScoreArgs) => {
  const criteriaWeight = getWeightOfCriteria(index, rubric);
  if (isNaN(score)) {
    score = 0;
  } else if (score > criteriaWeight) {
    score = criteriaWeight;
  }
  return score;
};
