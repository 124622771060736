import * as _ from 'lodash-es';
import { combineReducers } from 'redux';
import { generateAsyncReducer } from 'redux/utils';

import {
  CREATE_NEW_RUBRIC_SUCCESS,
  CREATE_NEW_TEMPLATE,
  CREATE_NEW_TEMPLATE_FAILURE,
  CREATE_NEW_TEMPLATE_SUCCESS,
  HIGHLIGHTED_RUBRIC_CRITERIA,
  HIGHLIGHTED_RUBRIC_LEVEL,
  LIST_RUBRICS_SUCCESS,
  LIST_RUBRIC_TEMPLATES_SUCCESS,
  RUBRIC_DROPDOWN_OPEN,
  UPDATE_RUBRIC,
  UPDATE_RUBRIC_FAILURE,
  UPDATE_RUBRIC_SUCCESS,
} from 'types';

const rubrics = (
  state = {
    items: {},
  },
  action: any
) => {
  switch (action.type) {
    case LIST_RUBRICS_SUCCESS:
      if (action.payload) {
        return { items: action.payload };
      }
      return state;
    case LIST_RUBRIC_TEMPLATES_SUCCESS:
      if (action.payload) {
        return { items: action.payload.items };
      }
      return state;
    case CREATE_NEW_RUBRIC_SUCCESS:
      if (action.payload) {
        return {
          ...state,
          ..._.keyBy(action.payload, ({ _id }) => {
            return _id;
          }),
        };
      }
      return state;
    case HIGHLIGHTED_RUBRIC_CRITERIA: {
      if (action.payload) {
        return { ...state, highlightedRubricCriteria: action.payload.level };
      }
      return state;
    }
    case HIGHLIGHTED_RUBRIC_LEVEL: {
      if (action.payload) {
        return { ...state, highlightedRubricLevel: action.payload.level };
      }
      return state;
    }
    case RUBRIC_DROPDOWN_OPEN: {
      if (action.payload) {
        return { ...state, rubricDropdownOpen: action.payload.open };
      }
      return state;
    }
    default:
      return state;
  }
};

const asyncActions = {
  call: [CREATE_NEW_TEMPLATE, UPDATE_RUBRIC],
  success: [CREATE_NEW_TEMPLATE_SUCCESS, UPDATE_RUBRIC_SUCCESS],
  error: [CREATE_NEW_TEMPLATE_FAILURE, UPDATE_RUBRIC_FAILURE],
};

const rubricReducer = combineReducers({
  rubrics,
  async: generateAsyncReducer(asyncActions),
});

export default rubricReducer;
