import { forgotPassword } from 'actions/users';
import {
  SignupButtons,
  SignupContainer,
  SignupContent,
  SignupHeader,
  SignupMainTitle,
  SignupText,
} from 'components/AuthWrapper';
import { Form, FormButtons, FormMainError } from 'components/Form';
import Button from 'components/buttons/Button';
import FormTextInput from 'components/core/form/TextField';
import { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FORGOT_PASSWORD } from 'types';
import { RouterProp, withRouter } from 'utils/withRouter';
import isEmail from 'validator/lib/isEmail';

const mapStateToProps = (state: any) => {
  return {
    actionBusy: state.async[FORGOT_PASSWORD].busy,
    actionSuccess: state.async[FORGOT_PASSWORD].success,
    actionError: state.async[FORGOT_PASSWORD].error,
  };
};

type ForgotPasswordState = any;

type ForgotPasswordProps = {
  router: RouterProp;
  actionSuccess: boolean;
  actionBusy: boolean;
  actionError: boolean;
  forgotPassword: ({ email }: { email: string }) => void;
};

class ForgotPassword extends Component<ForgotPasswordProps, ForgotPasswordState> {
  constructor(props: ForgotPasswordProps) {
    super(props);
    this.state = {
      email: this.props.router.location.query?.email || '',
      emailError: '',
      isEmailSent: false,
    };
  }

  componentDidUpdate(prevProps: ForgotPasswordProps) {
    if (this.props.actionSuccess && !prevProps.actionSuccess) {
      this.setState({ isEmailSent: true });
    }
  }

  isValidEmail() {
    return isEmail(this.state.email);
  }

  onSubmit() {
    if (this.props.actionBusy) {
      return;
    }
    if (!this.isValidEmail()) {
      this.setState({ emailError: 'Invalid email' });
      return;
    }
    this.props.forgotPassword({ email: this.state.email });
  }

  handleInputChange = (e: any) => {
    const { target } = e;
    const { value } = target;
    const { name } = target;

    this.setState({
      [name]: value,
      emailError: '',
    });
  };

  renderResetSuccess() {
    return (
      <SignupContent>
        <SignupMainTitle>Thank you, we have sent you a link to reset your password.</SignupMainTitle>
        <SignupText>
          It may take a few minutes before you see the email in your inbox. If you are still having issues feel free to
          chat with us at anytime.
        </SignupText>
        <SignupButtons>
          <Link to="/login">
            <Button type="primary">Back to sign-in</Button>
          </Link>
        </SignupButtons>
      </SignupContent>
    );
  }

  renderForgotPasswordForm() {
    return (
      <SignupContent>
        <SignupMainTitle>We will get you signed in right away.</SignupMainTitle>
        <SignupHeader size="xl"> Password Reset </SignupHeader>
        <Form>
          <FormTextInput
            name="email"
            label="Email"
            value={this.state.email}
            onChange={this.handleInputChange}
            error={this.state.emailError}
            autoFocus
          />
          <FormButtons>
            <Button
              type="primary"
              onClick={() => {
                return this.onSubmit();
              }}
              loading={this.props.actionBusy}
              disabled={this.props.actionBusy}
              success={this.props.actionSuccess}
              inputType="submit"
            >
              Send Password Reset Email
            </Button>
          </FormButtons>
          <FormMainError message={this.props.actionError} isOpen={this.props.actionError} />
        </Form>
        <SignupText color="dark">
          Remember your password?{' '}
          <Link className="underlined-link" to="/login">
            Sign-in
          </Link>
        </SignupText>
      </SignupContent>
    );
  }

  renderContent() {
    if (this.state.isEmailSent) {
      return this.renderResetSuccess();
    }
    return this.renderForgotPasswordForm();
  }

  render() {
    return <SignupContainer>{this.renderContent()}</SignupContainer>;
  }
}

export default withRouter(connect(mapStateToProps, { forgotPassword })(ForgotPassword));
