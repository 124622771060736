import { listCreationsByActivity } from 'actions/activity';
import { setCreationTableIsLoadingData, setCurrentPage, setPageSize } from 'redux/creationTable/actions';
import types from './types';

function getDefaultParams(): PaginationQueryParams {
  return {
    page: '1',
    resultsPerPage: '50',
    searchString: '',
    sortBy: 'NAME',
    sortOrder: '1',
    viewType: 'Progress',
    filterBy: 'SHOW_ALL',
  };
}

export default function getPaginatedResults(activityId = null, creationId = null) {
  return async (dispatch: any, getState: any) => {
    const state = getState();
    let params = getDefaultParams();
    if (window?.location?.search) {
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.forEach((value, key) => {
        params[key] = value;
      });
    }
    if (params.page) {
      dispatch(setCurrentPage(Number((params as any).page)));
    }
    if (params.resultsPerPage) {
      dispatch(setPageSize(Number((params as any).resultsPerPage)));
    }
    if (state?.selected?.assignmentId) {
      params.activityId = state.selected.assignmentId;
    } else if (!params.activityId && activityId) {
      params.activityId = activityId;
    }
    if (creationId) {
      params.creationId = creationId;
    }
    dispatch({ type: types.GET_PAGINATION_RESULTS_REQUEST, payload: params });

    try {
      dispatch(setCreationTableIsLoadingData(true));
      await dispatch(listCreationsByActivity(params));
      dispatch({ type: types.GET_PAGINATION_RESULTS_SUCCESS, payload: params });
      dispatch(setCreationTableIsLoadingData(false));
    } catch (err) {
      dispatch({ type: types.GET_PAGINATION_RESULTS_FAILURE, payload: params });
      dispatch(setCreationTableIsLoadingData(false));
    }
  };
}
