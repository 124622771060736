import { ButtonContainer } from 'components/buttons';

const FormSubmitButtons = (props: any) => {
  const { errors, children } = props;
  const renderError = () => {
    if (!errors) {
      return null;
    }
    return <div className="kritik-form-submit__error">{errors}</div>;
  };
  return (
    <div className="kritik-form-submit__buttons">
      {renderError()}
      <ButtonContainer>{children}</ButtonContainer>
    </div>
  );
};

export default FormSubmitButtons;
