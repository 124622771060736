import { Button } from 'components/buttons';
import FormTextInput from 'components/core/form/TextField';
import { InlineInformation } from 'components/layout';
import PageContainer from 'components/layout/PageContainer';
import PageHeader from 'components/layout/PageHeader';
import { useState } from 'react';
import { connect } from 'react-redux';

import { promoteUserToAdmin } from 'actions/admin';
import { getTeacherOrAdminByEmail } from 'actions/users';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const useForm = (defaultValues = {}) => {
  const [values, setFormValues] = useState({
    email: (defaultValues as any).email || '',
  });

  const setValues = (field: any, value: any) => {
    setFormValues({
      ...values,
      [field]: value,
    });
  };
  return {
    values,
    setValues,
  };
};

function createData(email: any, role: any) {
  return { email, role };
}

function emailIsValid(email: any) {
  return email.endsWith('@kritik.io');
}

function PromoteAdmin({ getTeacherOrAdminByEmail, promoteUserToAdmin }: any) {
  const { values, setValues } = useForm();
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [canSearch, setCanSearch] = useState(false);
  const [isSuccess, setIsSuccess] = useState(null);
  let rows;

  const handleSearchEmail = (e: any) => {
    if (error) {
      setError('Please provide an email address');
      setCanSearch(false);
    }

    setValues('email', e.target.value);
  };

  const handleGetEmail = async (e: any) => {
    e.preventDefault();
    if (values.email.trim() === '') {
      setError('Please provide an email address');
      setCanSearch(false);
      return;
    }

    if (!emailIsValid(values.email)) {
      setError(`${values.email} is not @kritik.io email`);
      setCanSearch(false);
      return;
    }

    setLoading(true);

    const response = await getTeacherOrAdminByEmail({ email: values.email });
    if (response?.error) {
      setError(`Need a valid instructor email address`);
      setLoading(false);
      setCanSearch(false);
      setIsSuccess(null);
      return;
    }

    if (response.permissions.includes('isAdmin')) {
      setError(`${values.email} is already an admin`);
      setLoading(false);
      setCanSearch(false);
      setIsSuccess(null);
      return;
    }

    setCanSearch(true);
    setLoading(false);
    setUser(response);
    setError(null);
    setIsSuccess(null);
  };

  if (user) {
    rows = [createData((user as any).email, (user as any).role)];
  }
  const isAdmin = user?.permissions.includes('isAdmin');

  return (
    <PageContainer>
      <PageHeader title="Promote user to Admin" />
      <form onSubmit={handleGetEmail}>
        <FormTextInput
          autoFocus
          name="promoteId"
          label="Enter an email address"
          value={values.email}
          onChange={handleSearchEmail}
        />
        {error && (
          <InlineInformation type="danger" style={{ marginBottom: '20px' }}>
            <div>{error}</div>
          </InlineInformation>
        )}

        <Button inputType="submit" loading={loading} type="primary" style={{ marginBottom: '20px' }}>
          Search User
        </Button>
      </form>

      {isSuccess && (
        <InlineInformation type="success" style={{ marginBottom: '20px' }}>
          <div>{isSuccess}</div>
        </InlineInformation>
      )}

      {canSearch && isSuccess === null && (
        <TableContainer style={{ maxWidth: '900px', margin: '0 auto' }} component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell>Role</TableCell>
                <TableCell align="right">Promote</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows &&
                rows.map((row) => (
                  // @ts-expect-error TS(2769) FIXME: No overload matches this call.
                  <TableRow key={(row as any).name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      <div className="promote-avatar_container">
                        <img
                          className="profile-img profile-img__instructor"
                          src={user.profile.picture}
                          alt={user.name}
                        />
                        {row.email}
                      </div>
                    </TableCell>
                    <TableCell>{row.role}</TableCell>
                    <TableCell align="right">
                      <Button
                        disabled={isAdmin}
                        loading={loading}
                        type="primary"
                        onClick={() => {
                          promoteUserToAdmin(user);
                          setIsSuccess('User Promoted');
                        }}
                      >
                        {isAdmin ? 'Admin' : 'Promote'}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </PageContainer>
  );
}

export default connect(null, {
  getTeacherOrAdminByEmail,
  promoteUserToAdmin,
})(PromoteAdmin);
