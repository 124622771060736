import { fileRestrictionStatus } from '@kritik/constants/activity';
import { AdvancedOptionField } from 'components/ActivityEdit/AdvancedOptions/Field';
import { TranslatedText } from 'components/TranslatedText';
import FormRadio from 'components/core/form/RadioField';
import FormTitle from 'components/core/form/Title';
import { InlineInformation } from 'components/layout';
import { localize } from 'locales';
import { Field, FormSpy } from 'react-final-form';
import { FIELD_LABEL, FIELD_NAME } from './constants';

type RequireAttachmentProps = {
  form: any;
  disabled: boolean;
};

const RequireAttachment = ({ form, disabled }: RequireAttachmentProps) => {
  const setFieldValue = (e: any, field: any, onChange: any) => {
    const isTrue = e.target.value === 'true';
    form.mutators.setValue(field, isTrue);
    return onChange(isTrue);
  };

  const { values } = form.getState();

  if (values.restrictFileTypes === fileRestrictionStatus.ALL) {
    return null;
  }

  return (
    <AdvancedOptionField>
      <FormTitle label={FIELD_LABEL} />
      <Field name={FIELD_NAME}>
        {({ input }) => {
          return (
            <FormRadio
              options={[
                {
                  label: localize({ message: 'Activity.RequireAttachmentField.Optional' }),
                  value: false,
                },
                {
                  label: localize({ message: 'Activity.RequireAttachmentField.Required' }),
                  value: true,
                  testid: 'require-attachment',
                },
              ]}
              onChange={(e: any) => {
                setFieldValue(e, FIELD_NAME, input.onChange);
              }}
              value={input.value}
              isDisabled={disabled}
              type="button"
              legendText="Legend.Text.RequiredAttachments"
            />
          );
        }}
      </Field>
      {values.requireFile && (
        <InlineInformation type="information">
          <TranslatedText i18nKey="Activity.RequireAttachmentField.Notice" />
        </InlineInformation>
      )}
    </AdvancedOptionField>
  );
};

const Spy = (props) => {
  return <FormSpy component={RequireAttachment} {...props} />;
};

export default Spy;
