import { isEdlinkLmsSetup } from '@kritik/utils/course';
import CourseSettingOptions from 'components/Course/CourseSettingOptions';
import CourseDetails from 'components/Course/Details';
import LmsIntegration from 'components/Course/LmsIntegration';
import LmsCoursesModal from 'components/LmsGradeSync/LmsCoursesModal';
import PageContainer from 'components/layout/PageContainer';
import VerticalList from 'components/layout/VerticalList';
import CourseHeader from 'containers/Course/partials/Header';
import CourseScoring from 'containers/Course/partials/ScoringView';
import { useFetchCourse, useStudentInfo, useUserRoleInCourse } from 'hooks/course';
import { useEffect, useState } from 'react';
import { isCourseConnected } from 'utils/lms';
import { withRouter } from 'utils/withRouter';

const Course = ({ router, user }: any) => {
  const { data: courseQuery, isSuccess: courseFetchSuccess } = useFetchCourse(router.params.courseId);
  const { isInstructorInCourse, isStudentInCourse } = useUserRoleInCourse();

  const {
    data: studentQuery,
    isSuccess: isStudentInfoSuccess,
    isError: studentInfoError,
  } = useStudentInfo({ courseId: router.params.courseId, isStudent: isStudentInCourse });

  const [isCoursesModalOpen, setIsCoursesModalOpen] = useState(false);

  const loadLMSModal = () => {
    const paramsString = window.location.search;
    const urlParams = new URLSearchParams(paramsString);
    const isSuccess = urlParams.get('lmsAuthSuccess');
    if (isSuccess && !isCourseConnected(courseQuery)) {
      setIsCoursesModalOpen(true);
    }
  };

  useEffect(() => {
    if (courseFetchSuccess) {
      loadLMSModal();
    }

    if (isStudentInfoSuccess) {
      if (studentQuery.removedOn != null) {
        router.push('/');
      }
    }

    if (studentInfoError) {
      router.push('/');
    }
  }, [courseFetchSuccess, isStudentInfoSuccess, studentInfoError]);

  if (courseQuery && !courseQuery.user) {
    return null;
  }

  if (isStudentInCourse && (!studentQuery || studentQuery.removedOn != null)) {
    return null;
  }

  if (courseQuery && !isStudentInCourse && !isInstructorInCourse) {
    router.push('/');
  }

  const getVerticalItemList = () => {
    const verticalItemList = [
      <CourseDetails course={courseQuery} isStudent={isStudentInCourse} user={user.authUser} />,
    ];
    if (isInstructorInCourse) {
      verticalItemList.push(<CourseSettingOptions course={courseQuery} />);
    }
    if (isEdlinkLmsSetup(courseQuery)) {
      verticalItemList.push(<LmsIntegration lmsCourseName={courseQuery.lms.connectedCourseName} />);
    }
    return verticalItemList;
  };

  return (
    <PageContainer>
      {courseQuery && (
        <>
          <div className="course-details__course-info-section">
            <CourseHeader course={courseQuery} />
            <LmsCoursesModal
              course={courseQuery}
              open={isCoursesModalOpen}
              close={() => {
                return setIsCoursesModalOpen(false);
              }}
              authUser={user.authUser}
            />
            <VerticalList space="md" itemList={getVerticalItemList()} />
          </div>
          <div className="course-details__course-info-section course-details__section-margin-top">
            <CourseScoring course={courseQuery} user={user} />
          </div>
        </>
      )}
    </PageContainer>
  );
};

export default withRouter(Course);
