import { denormalize } from 'normalizr';
import { rubricSchema } from 'schemas';

export const selectRubricById = (state: any, rubricId: any) => {
  const { rubrics } = state.entities;
  if (!rubrics) {
    return null;
  }
  const denormalizedRubric = denormalize(rubricId, rubricSchema, state.entities);
  return denormalizedRubric;
};
