import * as FormatUtils from '@kritik/utils/format';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Tooltip from '@material-ui/core/Tooltip';
import classNames from 'classnames';
import Button from 'components/buttons/Button';
import ChevronDown from 'images/icons/fa/ChevronDown';
import ChevronUp from 'images/icons/fa/ChevronUp';
import moment from 'moment';
import React from 'react';
import localUtils from './utils';

const DAY = 60 * 60 * 24;
const DEFAULT_OPTIONS = [DAY, DAY * 2, DAY * 3, DAY * 4, DAY * 5, DAY * 6, DAY * 7];

const DateInterval = (props: any) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleButtonClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (option: any) => {
    props.onSelect(option);
    handleClose();
  };

  function handleListKeyDown(event: any) {
    if (event.key === 'Tab') {
      event.preventDefault();
      handleClose();
    }
  }

  const generateOptions = (intervalList: any[], isCustom?: boolean) => {
    return intervalList.map((interval: any, index: number) => {
      if (interval) {
        const classes = classNames('date-interval__option', {
          'date-interval__option--custom': isCustom,
        });
        const targetDate = moment(props.startDate).add(interval, 'seconds');
        return (
          <MenuItem
            key={`${index}-${interval}`}
            onClick={() => {
              handleSelect(interval);
            }}
            data-testid={`${props.testid}-interval-option-${index}`}
          >
            <div className={classes}>
              <span className="date-interval__option-label">{localUtils.getDateLabel(props.startDate, interval)}</span>
              <span className="date-interval__option-day">{FormatUtils.getDayOfTheWeek(targetDate, props.locale)}</span>
            </div>
          </MenuItem>
        );
      }
      return null;
    });
  };

  const renderOptions = () => {
    const defaultOptions = generateOptions(DEFAULT_OPTIONS);
    const customOptions = generateOptions([props.customInterval], true);
    return [...defaultOptions, ...customOptions];
  };

  const renderFootnote = () => {
    if (!props.footnote) {
      return null;
    }
    return <div className="date-interval__footnote">{props.footnote}</div>;
  };

  const renderChevron = () => {
    if (props.disabled) {
      return null;
    }
    return <span className="date-interval__chevron">{open ? <ChevronUp /> : <ChevronDown />}</span>;
  };

  return (
    <div className={props.className}>
      <Tooltip title={props.tooltip || ''} disableHoverListener={open} placement="top" enterDelay={500}>
        <Button
          aria-controls={open ? 'date-interval-options' : undefined}
          aria-haspopup="true"
          onClick={handleButtonClick}
          type="hidden"
          disabled={props.disabled}
        >
          {localUtils.getDateLabel(props.startDate, props.value)}
          {renderChevron()}
        </Button>
      </Tooltip>
      <Popper open={open} anchorEl={anchorEl} role={undefined} transition>
        {({ TransitionProps, placement }) => {
          return (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem={open} id="date-interval-options" onKeyDown={handleListKeyDown}>
                    {renderOptions()}
                  </MenuList>
                </ClickAwayListener>
                {renderFootnote()}
              </Paper>
            </Grow>
          );
        }}
      </Popper>
    </div>
  );
};

DateInterval.defaultProps = {
  value: DEFAULT_OPTIONS[0],
  startDate: Date.now(),
  customInterval: '',
  footnote: null,
  tooltip: '',
  locale: null,
  className: '',
  disabled: false,
};

export default DateInterval;
