import { localize } from 'locales';
import { TABLE_HEADERS } from '../constant';

export const STUDENT_COLUMNS = {
  PEER: [
    { header: TABLE_HEADERS.NAME, align: 'left', isSortable: true, width: 'lg' },
    { header: TABLE_HEADERS.CREATE, align: 'center', isSortable: true, width: 'md' },
    { header: TABLE_HEADERS.EVALUATE, align: 'center', isSortable: true },
    { header: TABLE_HEADERS.FEEDBACK, align: 'center', isSortable: true },
    { header: TABLE_HEADERS.ISSUES, align: 'center', isSortable: true },
    { header: ' ', align: 'center', isSortable: false, width: 'sm', type: 'menu' },
  ],
  GROUP: [
    { header: TABLE_HEADERS.NAME, align: 'left', isSortable: true, width: 'lg' },
    { header: TABLE_HEADERS.CREATE, align: 'center', isSortable: true, width: 'md' },
    { header: TABLE_HEADERS.EVALUATE, align: 'center', isSortable: true },
    { header: TABLE_HEADERS.FEEDBACK, align: 'center', isSortable: true },
    { header: TABLE_HEADERS.ISSUES, align: 'center', isSortable: true },
  ],
  CALIBRATION: [
    { header: TABLE_HEADERS.NAME, align: 'left', isSortable: true, width: 'lg' },
    { header: TABLE_HEADERS.EVALUATE, align: 'center', isSortable: true },
    { header: '' },
    { header: '' },
    { header: TABLE_HEADERS.ISSUES, align: 'center', isSortable: true },
    { header: ' ', align: 'center', isSortable: false },
  ],
};

export const GROUP_COLUMNS = {
  GROUP: [
    {
      header: TABLE_HEADERS.NAME,
      align: 'left',
      isSortable: true,
      width: 'lg',
      testid: 'group-header-name',
    },
    {
      header: TABLE_HEADERS.CREATE,
      align: 'center',
      isSortable: true,
      width: 'md',
      testid: 'group-header-create',
    },
    {
      header: TABLE_HEADERS.EVALUATE,
      align: 'center',
      isSortable: true,
      info: '',
      testid: 'group-header-evaluate',
    },
    {
      header: TABLE_HEADERS.FEEDBACK,
      align: 'center',
      isSortable: true,
      info: localize({ message: 'ProgressTable.Constants.FeedbackInfo' }),
      testid: 'group-header-feedback',
    },
    {
      header: TABLE_HEADERS.ISSUES,
      align: 'center',
      isSortable: true,
      testid: 'group-header-issues',
    },
    { header: ' ', align: 'right', isSortable: false, width: 'sm' },
  ],
};

export default {
  STUDENT_COLUMNS,
  GROUP_COLUMNS,
};
