import { schema } from 'normalizr';

import assignment from 'schemas/activity';
import course from 'schemas/course';
import user from 'schemas/user';

const rubric = new schema.Entity(
  'rubrics',
  {
    user,
    course,
    assignment,
  },
  { idAttribute: '_id' }
);

export default rubric;
