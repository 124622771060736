import classnames from 'classnames';

const CardActionArea = (props: any) => {
  const classes = classnames('spotlight-card__body', {
    'spotlight-card__body--clickable': props.isClickable,
  });
  return (
    <button onClick={props.onClick} className={classes} data-testid={`spotlight-action-area-${props.index}`}>
      {props.children}
    </button>
  );
};

CardActionArea.defaultProps = {
  onClick: null,
  isClickable: true,
};

export default CardActionArea;
