import Typography from '@material-ui/core/Typography';
import PopoverTab from 'components/Navigation/PopoverTab';
import { localize } from 'locales';

const PopoverMessageStyle = {
  padding: '15px',
  width: '250px',
};

const content = (
  <Typography style={PopoverMessageStyle}>{localize({ message: 'Navigation.Tabs.Activities.Tip' })}</Typography>
);

const ActivitiesTab = (props: any) => (
  <PopoverTab
    destination={props.destination}
    icon={<i className="fa fa-file-alt" />}
    activeRegex={props.activeRegex}
    label={localize({ message: 'Navigation.Tabs.Activities.Label' })}
    content={content}
    testid="activities-tab"
  />
);

export default ActivitiesTab;
