import { COOKIES } from '@kritik/constants/cookie';
import Cookies from 'js-cookie';
import React from 'react';

export function usePresenterMode(courseId?: string) {
  const [isPresenterMode, setIsPresenterMode] = React.useState(false);

  React.useEffect(() => {
    const isPresenterMode = Cookies.get(COOKIES.PRESENTER_MODE);
    setIsPresenterMode(isPresenterMode === 'true');
  }, []);

  return {
    isPresenterMode,
    startPresenterMode: () => {
      window.location.href = `/api/v1/view-as/start-anonymized-session?courseId=${courseId}`;
    },
    endPresenterMode: () => {
      window.location.href = `/api/v1/view-as/end-anonymized-session`;
    },
  };
}
