import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const COLOR = {
  PRIMARY: 'primary',
  BTNPRIMARY: 'btnprimary',
  SECONDARY: 'secondary',
  LIGHT: 'light',
  DEFAULT: 'default',
  DARK: 'dark',
  ACCENT: 'accent',
  DANGER: 'danger',
  INHERIT: 'inherit',
};

const useStyles = makeStyles({
  root: (props) => ({
    fontWeight: (props as any).weight,
    fontSize: (props as any).fontSize,
    fontStyle: (props as any).fontStyle,
  }),
});

const Typography = (props: any) => {
  const customClasses = useStyles(props);
  const classes = classNames(
    'typography',
    {
      'typography--primary': COLOR.PRIMARY === props.color,
      'typography--btnprimary': COLOR.BTNPRIMARY === props.color,
      'typography--accent': COLOR.ACCENT === props.color,
      'typography--danger': COLOR.DANGER === props.color,
      'typography--dark': COLOR.DARK === props.color,
      'typography--light': COLOR.LIGHT === props.color,
    },
    customClasses.root
  );
  return <span className={classes}>{props.children}</span>;
};

Typography.defaultProps = {
  color: COLOR.INHERIT,
};

export default Typography;
