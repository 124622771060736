import { resetAsync } from 'actions/async';
import { updateRubric } from 'actions/rubrics';
import { AuthUser } from 'app-types';
import RadioField from 'components/core/form/RadioField';
import ConfirmModal from 'components/modals/ConfirmModal';
import * as _ from 'lodash-es';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { selectMyTemplates } from 'selectors/rubric';
import { UPDATE_RUBRIC } from 'types';
import { trackEvent } from 'utils/userEvents';

type Props = {
  activityId: string;
  courseId: string;
  authUser: AuthUser;
  isRubricUpdateSuccess: string | boolean;
  resetAsync: (val: string) => void;
  onComplete: (val: string) => void;
  rubricTemplateList: any[];
  isRubricUpdating: boolean;
  updateRubric: (data: any, selectedRubricId: string) => void;
  rubric: any;
  isOpen: boolean;
  onCancel: () => void;
};

const UpdateRubricModal = (props: Props) => {
  const [selectedRubricId, setSelectedRubricId] = useState(null);

  useEffect(() => {
    if (props.isRubricUpdateSuccess === selectedRubricId) {
      props.resetAsync(UPDATE_RUBRIC);
      props.onComplete(selectedRubricId);
    }
  }, [props.isRubricUpdateSuccess]);

  const getOptions = () => {
    return props.rubricTemplateList.map((rubric: any) => {
      return {
        label: rubric.name,
        value: rubric._id,
      };
    });
  };

  const onConfirm = () => {
    if (props.isRubricUpdating) {
      return;
    }
    const data = _.pick(props.rubric, ['criteria', 'grid', 'levels', 'passCriteriaRule']);
    props.updateRubric(
      {
        _id: selectedRubricId,
        ...data,
        criteria: data.criteria.map((c: any) => {
          return {
            ...c,
            weight: Number(c.weight),
          };
        }),
      },
      selectedRubricId
    );
    trackEvent('Rubric Template Updated', props.authUser, {
      activityId: props.activityId,
      courseId: props.courseId,
      instructorEmail: props.authUser.email,
    });
  };

  return (
    <ConfirmModal
      isOpen={props.isOpen}
      title="Update Rubric Template"
      description="Update a rubric template with your current changes"
      importantInfo="Other activities currently using this template will NOT be affected"
      onConfirm={onConfirm}
      confirmButton="Update Template"
      isConfirmDisabled={!selectedRubricId}
      onCancel={props.onCancel}
      cancelButton="Cancel"
    >
      <RadioField
        label="Choose a template to update"
        options={getOptions()}
        onChange={(e: any) => {
          setSelectedRubricId(e.target.value);
        }}
        legendText="Legend.Text.UpdateRubricTemplate"
      />
    </ConfirmModal>
  );
};

const mapStateToProps = (state: any) => {
  return {
    rubricTemplateList: selectMyTemplates(state),
    isRubricUpdating: state.async[UPDATE_RUBRIC].busy,
    isRubricUpdateSuccess: state.async[UPDATE_RUBRIC].success,
    activityId: state.selected.assignmentId,
    courseId: state.selected.courseId,
    authUser: state.user.authUser,
  };
};

UpdateRubricModal.defaultProps = {
  rubricTemplateList: [],
};

export default connect(mapStateToProps, {
  updateRubric,
  resetAsync,
})(UpdateRubricModal);
