import { isCalibrationActivity } from '@kritik/utils/activity';
import * as activityUtils from '@kritik/utils/stage';
import { mergeEntities, updateEntities } from 'actions/entities';
import { normalize } from 'normalizr';
import { combineReducers } from 'redux';
import * as types from 'redux/activityEdit/types';
import { creationSchema } from 'schemas/index';
import { assignmentService, courseService, creationService } from 'services';
import { generateAsyncReducer } from '../utils';

const SHOW_RUBRIC_EDIT = 'SHOW_RUBRIC_EDIT';
const CLOSE_RUBRIC_EDIT = 'CLOSE_RUBRIC_EDIT';
const SHOW_RUBRIC_VIEW = 'SHOW_RUBRIC_VIEW';
const CLOSE_RUBRIC_VIEW = 'CLOSE_RUBRIC_VIEW';
const SELECT_RUBRIC = 'SELECT_RUBRIC';
const SHOW_CREATION_SELECTOR = 'SHOW_CREATION_SELECTOR';
const CLOSE_CREATION_SELECTOR = 'CLOSE_CREATION_SELECTOR';
const SET_CALIBRATION_CREATION_OPTIONS = 'SET_CALIBRATION_CREATION_OPTIONS';
const RESET_ACTIVITY_EDIT = 'RESET_ACTIVITY_EDIT ';
const GET_CALIBRATION_ACTIVITIES_REQUEST = 'GET_CALIBRATION_ACTIVITY_REQUEST';
const GET_CALIBRATION_ACTIVITIES_SUCCESS = 'GET_CALIBRATION_ACTIVITY_SUCCESS';
const GET_CALIBRATION_ACTIVITIES_FAILURE = 'GET_CALIBRATION_ACTIVITY_FAILURE';
const GET_CALIBRATION_COURSES_REQUEST = 'GET_CALIBRATION_COURSES_REQUEST';
const GET_CALIBRATION_COURSES_SUCCESS = 'GET_CALIBRATION_COURSES_SUCCESS';
const GET_CALIBRATION_COURSES_FAILURE = 'GET_CALIBRATION_COURSES_FAILURE';
const SET_CALIBRATION_CREATION_EDITOR_VISIBILITY = 'SET_CALIBRATION_CREATION_EDITOR_VISIBILITY';

const initialState = {
  isRubricEditShow: false,
  isRubricViewShow: false,
  selectedRubricId: null,
};

function rubricReducer(state = initialState, action: any) {
  switch (action.type) {
    case SHOW_RUBRIC_EDIT:
      return {
        ...state,
        isRubricEditShow: true,
      };
    case CLOSE_RUBRIC_EDIT:
      return {
        ...state,
        isRubricEditShow: false,
      };
    case SHOW_RUBRIC_VIEW:
      return {
        ...state,
        isRubricViewShow: true,
      };
    case CLOSE_RUBRIC_VIEW:
      return {
        ...state,
        isRubricViewShow: false,
      };
    case SELECT_RUBRIC:
      return {
        ...state,
        selectedRubricId: action.payload.selectedRubricId,
      };
    case RESET_ACTIVITY_EDIT:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}

const asyncActions = {
  call: [types.CREATE_CALIBRATION_CREATION, types.UPDATE_CALIBRATION_CREATION],
  success: [types.CREATE_CALIBRATION_CREATION_SUCCESS, types.UPDATE_CALIBRATION_CREATION_SUCCESS],
  error: [types.CREATE_CALIBRATION_CREATION_FAILURE, types.UPDATE_CALIBRATION_CREATION_FAILURE],
};

const calibrationState = {
  isSelectingCreation: false,
  calibrationCreationOptions: [],
  calibrationCreationEditorVisibility: false,
  creationIndex: null,
  activities: [],
  courses: [],
  creations: [],
};

function calibrationReducer(state = calibrationState, action: any) {
  switch (action.type) {
    case SHOW_CREATION_SELECTOR:
      return {
        ...state,
        creationIndex: action.payload,
        isSelectingCreation: true,
      };
    case CLOSE_CREATION_SELECTOR:
      return {
        ...state,
        creationIndex: null,
        isSelectingCreation: false,
      };
    case SET_CALIBRATION_CREATION_OPTIONS:
      return {
        ...state,
        calibrationCreationOptions: action.payload,
      };
    case SET_CALIBRATION_CREATION_EDITOR_VISIBILITY:
      return {
        ...state,
        calibrationCreationEditorVisibility: action.payload,
      };
    case RESET_ACTIVITY_EDIT:
      return {
        ...calibrationState,
      };
    case GET_CALIBRATION_ACTIVITIES_SUCCESS:
      return {
        ...state,
        activities: [...action.payload],
      };
    case GET_CALIBRATION_COURSES_SUCCESS:
      return {
        ...state,
        courses: [...action.payload],
      };
    default:
      return state;
  }
}

// action
export const setCalibrationCreationEditorVisibility = (isShow: any) => {
  return {
    type: SET_CALIBRATION_CREATION_EDITOR_VISIBILITY,
    payload: isShow,
  };
};

export const createCalibrationCreation = (data: any, updateCalibrationCreations: any) => {
  return async (dispatch: any) => {
    dispatch({
      type: types.CREATE_CALIBRATION_CREATION,
    });
    try {
      const res = await creationService().create(data);
      if (res.data) {
        const creation = res.data;
        dispatch(mergeEntities(normalize(creation, creationSchema).entities));
        // update calibration creations in final form
        updateCalibrationCreations(null, creation);
        dispatch({
          type: types.CREATE_CALIBRATION_CREATION_SUCCESS,
          success: creation,
        });
        dispatch(setCalibrationCreationEditorVisibility(false));
      }
    } catch (error) {
      updateCalibrationCreations(error?.response?.data);
      dispatch({
        type: types.CREATE_CALIBRATION_CREATION_FAILURE,
        error,
      });
    }
  };
};

export const updateCalibrationCreation = (data: any, updateCalibrationCreations: any) => {
  return async (dispatch: any) => {
    dispatch({
      type: types.UPDATE_CALIBRATION_CREATION,
    });
    try {
      const res = await creationService().newUpdate(data);
      if (res.data) {
        const creation = res.data;
        dispatch(updateEntities(normalize(creation, creationSchema).entities));
        // update calibration creations in final form
        updateCalibrationCreations(null, creation);
        dispatch({
          type: types.UPDATE_CALIBRATION_CREATION_SUCCESS,
          success: creation,
        });
        dispatch(setCalibrationCreationEditorVisibility(false));
      }
    } catch (error) {
      updateCalibrationCreations(error?.response?.data);
      dispatch({
        type: types.UPDATE_CALIBRATION_CREATION_FAILURE,
        error,
      });
    }
  };
};

export const getCalibrationActivity = (params = {}, { client }: any = {}) => {
  return (dispatch: any) => {
    dispatch({ type: GET_CALIBRATION_ACTIVITIES_REQUEST, payload: { params } });
    return assignmentService({ client })
      .list(params)
      .then((res: any) => {
        if (res.status === 200) {
          dispatch({
            type: GET_CALIBRATION_ACTIVITIES_SUCCESS,
            payload: res.data,
          });
        }
      })
      .catch((err: any) => {
        dispatch({ type: GET_CALIBRATION_ACTIVITIES_FAILURE, payload: err });
      });
  };
};

const getFilteredCourseList = (courses: any) => {
  return courses.filter((course: any) => {
    return course.userRole === 'instructor' || course.isUserEnrolledInCourse;
  });
};

export const getCalibrationCourses = (params = {}, { client }: any = {}) => {
  return (dispatch: any, getState: any) => {
    dispatch({ type: GET_CALIBRATION_COURSES_REQUEST, payload: { params } });
    return courseService({ client })
      .list({ activityCount: true })
      .then((res: any) => {
        if (res.status === 200) {
          const courses = getFilteredCourseList(res.data);
          dispatch({
            type: GET_CALIBRATION_COURSES_SUCCESS,
            payload: courses,
          });
        }
      })
      .catch((err: any) => {
        dispatch({ type: GET_CALIBRATION_COURSES_FAILURE, payload: err });
      });
  };
};

export const showRubricEdit = () => {
  return (dispatch: any, getState: any) => {
    dispatch({
      type: SHOW_RUBRIC_EDIT,
    });
  };
};

export const closeRubricEdit = () => {
  return (dispatch: any, getState: any) => {
    dispatch({
      type: CLOSE_RUBRIC_EDIT,
    });
  };
};

export const showRubricView = () => {
  return (dispatch: any, getState: any) => {
    dispatch({
      type: SHOW_RUBRIC_VIEW,
    });
  };
};

export const resetActivityEditor = () => {
  return (dispatch: any, getState: any) => {
    dispatch({
      type: RESET_ACTIVITY_EDIT,
    });
  };
};

export const showCreationSelector = (creationIndex: any) => {
  return (dispatch: any, getState: any) => {
    dispatch({
      payload: creationIndex,
      type: SHOW_CREATION_SELECTOR,
    });
  };
};

export const closeRubricView = () => {
  return (dispatch: any, getState: any) => {
    dispatch({
      type: CLOSE_RUBRIC_VIEW,
    });
  };
};

export const closeCreationSelector = () => {
  return (dispatch: any, getState: any) => {
    dispatch({
      type: CLOSE_CREATION_SELECTOR,
    });
  };
};

export const setCalibrationCreationOptions = (creationOptions: any) => {
  return (dispatch: any, getState: any) => {
    dispatch({
      payload: creationOptions,
      type: SET_CALIBRATION_CREATION_OPTIONS,
    });
  };
};

export const selectRubricTemplate = (selectedRubricId: any) => {
  return (dispatch: any, getState: any) => {
    dispatch({
      type: SELECT_RUBRIC,
      payload: {
        selectedRubricId,
      },
    });
  };
};

// selectors

export const selectIsRubricEditShow = (state: any) => {
  return state.activityEdit.rubric.isRubricEditShow;
};

export const selectSelectedRubricTemplate = (state: any) => {
  return state.activityEdit.rubric.selectedRubricId;
};

export const selectIsRubricViewShow = (state: any) => {
  return state.activityEdit.rubric.isRubricViewShow;
};

export const selectIsSelectingCreation = (state: any) => {
  return state.activityEdit.calibration.isSelectingCreation;
};

export const selectCalibrationOptions = (state: any) => {
  return state.activityEdit.calibration.calibrationCreationOptions;
};

export const selectCreationIndex = (state: any) => {
  return state.activityEdit.calibration.creationIndex;
};

export const getFinalizedActivitiesWithoutCalibrations = (state: any) => {
  return state.activityEdit.calibration.activities.filter((activity: any) => {
    return (
      (activityUtils.isGrading({ assignment: activity }) || activityUtils.isFinalized({ assignment: activity })) &&
      !isCalibrationActivity(activity)
    );
  });
};

export const selectCalibrationActivityCourseList = (state: any) => {
  return state.activityEdit.calibration.courses;
};

export const selectCalibrationCreationEditorVisibility = (state: any) => {
  return state.activityEdit.calibration.calibrationCreationEditorVisibility;
};

export const selectActivityEditAsyncState = (state: any) => {
  return state.activityEdit.async;
};

export default combineReducers({
  rubric: rubricReducer,
  calibration: calibrationReducer,
  async: generateAsyncReducer(asyncActions),
});
