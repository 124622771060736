import TableCell from '@material-ui/core/TableCell';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

export const Cell = (props: any) => {
  const useStyles = makeStyles({
    root: {
      borderBottom: 'none',
      width: props.width,
      minWidth: props.minWidth,
      fontSize: '14px',
      whiteSpace: props.whiteSpace,
      height: props.height,
    },
  });

  const muiClasses = useStyles();
  const classes = classNames(
    muiClasses.root,
    'simple-table-cell',
    {
      'simple-table-cell--dark': props.color === 'dark',
      'simple-table-cell--light': props.color === 'light',
    },
    props.className
  );

  return (
    <TableCell
      className={classes}
      align={props.align}
      colSpan={props.colSpan}
      id={props.id}
      headers={props.headers}
      data-testid={props.testid}
    >
      {props.children}
    </TableCell>
  );
};

Cell.defaultProps = {
  width: 'auto',
  height: 'auto',
  minWidth: '',
  align: 'left',
  whiteSpace: 'normal',
  color: 'default',
  className: '',
};

export default Cell;
