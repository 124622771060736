import ImpersonateUser from 'components/Admin/ImpersonateUser';
import PageContainer from 'components/layout/PageContainer';
import Page from 'pages/Page';

function Impersonate() {
  return (
    <Page title="Impersonate User" meta={[]} link={[]} script={[]}>
      <PageContainer>
        <ImpersonateUser />
      </PageContainer>
    </Page>
  );
}

export default Impersonate;
