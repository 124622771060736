import { PROFILE_IMAGE_MAX_SIZE_IN_BYTES, PROFILE_IMAGE_MAX_SIZE_IN_MB } from '@kritik/constants/activity';
import axios from 'axios';
import { SignupButtons, SignupContainer, SignupContent, SignupMainTitle } from 'components/AuthWrapper';
import { Form } from 'components/Form';
import { TranslatedText } from 'components/TranslatedText';
import TermsOfService from 'components/auth/TermsOfService';
import Button from 'components/buttons/Button';
import InlineInformation from 'components/layout/InlineInformation';
import placeholderProfile from 'images/anon-profile.png';
import { localize } from 'locales';
import { useState } from 'react';
import Dropzone from 'react-dropzone';

function ProfileImageUpload(props: any) {
  const [validationError, setValidationError] = useState(null);
  const handleImageUpload = (files: any) => {
    const file = files[0];
    if (!file) {
      return;
    }
    if (file.size > PROFILE_IMAGE_MAX_SIZE_IN_BYTES) {
      setValidationError(
        localize({
          message: 'ProfileImageUpload.ValidationError.ImageTooLarge',
          options: { size: PROFILE_IMAGE_MAX_SIZE_IN_MB },
        })
      );
      return;
    }
    const formData = new FormData();
    formData.set('file', file, file.name);

    axios
      .post(`/api/v1/upload-profile-image`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        props.onImageUpload(response.data[0].Location);
      })
      .catch((err) => {
        throw err;
      });
  };
  return (
    <SignupContainer graphic="rocket">
      <SignupContent>
        <SignupMainTitle>
          <TranslatedText i18nKey="ProfileImageUpload.Title" values={{ firstName: props.firstName }} />
        </SignupMainTitle>
        <Form>
          {validationError && <InlineInformation type="danger">{validationError}</InlineInformation>}
          <div className="image-upload-container">
            <Dropzone
              className="ImageUploader"
              name="image-uploader"
              onDrop={(files: any) => {
                return handleImageUpload(files);
              }}
              accept="image/jpeg,image/png"
              role="button"
              aria-label={localize({ message: 'Account.UploadProfileImage' })}
              tabindex={0}
            >
              <div>
                <div>
                  <img
                    src={props.uploadedImg || placeholderProfile}
                    alt={localize({ message: 'ProfileImageUpload.AltText.ProfileImage' })}
                  />
                </div>
                <p className={props.uploadedImg ? 'hidden' : ''}>
                  <TranslatedText i18nKey="ProfileImageUpload.DropzoneText" />
                </p>
              </div>
            </Dropzone>
          </div>
          <SignupButtons>
            <Button
              type="primary"
              onClick={() => {
                props.onSubmit();
              }}
              testid="finish-button"
              inputType="submit"
            >
              <TranslatedText i18nKey="ProfileImageUpload.FinishButton" />
            </Button>
          </SignupButtons>
          <TermsOfService />
        </Form>
      </SignupContent>
    </SignupContainer>
  );
}

export default ProfileImageUpload;
